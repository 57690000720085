import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import resendConfirmationCode from "../../../services/user/ResendConfirmation";
import config from "../../../config/config";
import { withAppConfig } from "../../../context/ConfigContext";

const ConfirmationMessage = props => {
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    setLoading(true);
    resendConfirmationCode(
      (res, err) => {
        let [type, message] = ["showErrorAlert", ""];
        if (err) {
          message = err;
        } else {
          const {
            ApplicationName = config.applicationName
          } = props.appSettings;
          type = "showSuccessAlert";
          message = `Please Visit your Email Address to verify your ${ApplicationName} account!`;
          if (props.location.pathname !== "/login") {
            props.history.push("/login", { showConfirmation  : true, email : props.email });
          }
        }
        props.showAlert(type, message);
        setLoading(false);
      },
      { email: props.email }
    );
  };

  if (props.showWarning) {
    return (
      <Fragment>
        <Loader isLoading={loading} />
        <div className="alert alert-success" role="alert">
          <p>
            You need to confirm your account before resetting password. &nbsp;
            <a onClick={onClick} className="text-primary">
              Click here
            </a>{" "}
            to get confirmation email.
          </p>
        </div>
      </Fragment>
    );
  }

  return null;
};

const Loader = ({ isLoading }) => {
  if (isLoading) {
    return (
      <span className="loader-spiral mn-white mn-transparent">
        <span className="spiral-inn" />
      </span>
    );
  }

  return null;
};
export default withRouter(withAppConfig(ConfirmationMessage));
