import React, { Component } from "react";
import PropTypes from "prop-types";
import _map from "lodash/map";
import { SuggestionsSlider } from "./components/MovieDetailSuggestions";
import PlayerSuggestions from "./components/PlayerSuggestions";
import RenderScreen from "../../components/RenderScreen/RenderScreen";
import { getDataFromLocalStorage, isEmpty, storeDataInLocalStore, ping, getCurrentUserId, importMoment } from "../../helpers/GeneralHelpers";
import { STORED_MY_RELATED_MOVIES, NO_EXPIRY, FETCH_RELATED_MOVIES } from "../../constants/constants";
import { fetchMoviesData } from "../../helpers/APIHelpers/MoviesAPI";
import { getPurchaseData, decideLikePurchasedStatus } from "../../helpers/MoviesHelper";
import config from "../../config/config";

class RelatedMovies extends Component {
    USER_ID = "";
    moment = null;
    isCompMounted = true;
    SERVERTIME = null;
    purchaseList = [];
    MAPING_STATUS_TYPE = "";
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dataList: [],
            isError: "",
            showRelatedTags: false
        }
        this.fetchRelatedMovies = fetchMoviesData.bind(this);
        this.getPurchaseData = getPurchaseData.bind(this);
        this.importMoment = importMoment.bind(this);
        this.decideLikePurchasedStatus = decideLikePurchasedStatus.bind(this);
    }

    componentDidMount() {
        if (this.props.movieId) {
            this.getRelatedMovies(this.props.movieId);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.movieId !== prevProps.movieId && this.props.viewType === "SUGGESTIONS_SLIDER") {
            this.setState({
                isLoading: true
            }, () => {
                this.setInitialState();
                this.getRelatedMovies(this.props.movieId);
            })
        }
    }

    getRelatedMovies = async (movieID, limit = 25) => {
        let relatedMovies = getDataFromLocalStorage(`${STORED_MY_RELATED_MOVIES}${movieID}`);
        if (!relatedMovies) {
            let header = { "MovieID": movieID, "Limit": limit };
            relatedMovies = await this.fetchRelatedMovies("MoviesAPI", "/getRelatedMovies/", this.state.dataList, "isLoading", "errorRelatedMovies", "dataList", FETCH_RELATED_MOVIES, "ID", "", header).then(async (res) => {
                if (!isEmpty(res)) {
                    storeDataInLocalStore(`${STORED_MY_RELATED_MOVIES}${movieID}`, res, null, NO_EXPIRY);
                }
                return res;
            });
            if (!relatedMovies) {
                relatedMovies = []
            }
        } else {
            this.setState({
                isLoading: false,
                dataList: relatedMovies,
                errorRelatedMovies: ""
            });
        }
        if (this.props.isAuthenticated) {
            if (!this.moment) {
                await this.importMoment()
            }
            if (!this.SERVERTIME) {
                this.SERVERTIME = await ping();
            }
            if (!this.USER_ID) {
                this.USER_ID = await getCurrentUserId();
            }
            this.MAPING_STATUS_TYPE = FETCH_RELATED_MOVIES;
            this.purchaseList = await this.getPurchaseData(this.USER_ID);
            let updatedRelatedList = _map(relatedMovies, this.decideLikePurchasedStatus);
            this.setState({
                dataList: updatedRelatedList,
                showRelatedTags: true,
            })
        }
    }

    setInitialState = () => {
        this.setState({
            isLoading: true,
            dataList: [],
            isError: "",
            showRelatedTags: false
        })
    }

    render() {
        const { movieId, viewType, showMovieSuggestions } = this.props;
        if (viewType === "PLAYER_SUGGESTIONS") {
            return (
                showMovieSuggestions ?
                    <PlayerSuggestions
                        movieId={movieId}
                        showRelatedTags={this.state.showRelatedTags}
                        dataList={this.state.dataList}
                        handleMovieClick={this.props.handleMovieClick}
                        {...this.props}
                    />
                    :
                    null
            )
        }
        return (
            <RenderScreen
                isLoading={this.state.isLoading}
                isError={this.state.isError}
                stuffpixLoader={config.customSettings.isStuffpixEnabled}
            >
                <SuggestionsSlider
                    movieId={movieId}
                    showRelatedTags={this.state.showRelatedTags}
                    dataList={this.state.dataList}
                    handleMovieClick={this.props.handleMovieClick}
                    {...this.props}
                />
            </RenderScreen>
        )

    }
}

export default RelatedMovies;
RelatedMovies.defaultProps = {
    viewType: "SUGGESTIONS_SLIDER",
    showMovieSuggestions: false
}
RelatedMovies.propTypes = {
    movieId: PropTypes.string.isRequired,
    showMovieSuggestions: PropTypes.bool,
    viewType: PropTypes.string,
    handleMovieClick: PropTypes.func
}
