import { HOME, MOVIES, LIBRARY } from "./routes";//sensitive data
export const bitmovin_player_ui = "//cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.js";
export const bitmovin_player_ui_css = "//cdn.bitmovin.com/player/web/8/bitmovinplayer-ui.css";
export const certificateURL = "https://dev-ingestion-lamda-testing.s3.amazonaws.com/certificate/fairplay.cer";
export const APP_CONFIG = "appConfiguration";
export const APP_TRANSLATIONS = "appTranslations";
export const DEVICES_LIMIT = 4;
export const purchaseLimit = 50;
export const watchedLimit = 50;
export const ExpiringStripTime = 7200; //2hours
export const YearsBack = 12;
export const OFFERMOVIES_TYPE = "rewardplusfree";
export const PROMOTIONALMOVIES_TYPE = "rewardplusdiscounted";
export const DEFAULTPARENTALLOCKSETTINGS = "G"; //incase nothing selected!
export const DEVICE_LIMIT = "deviceLimitReached";
export const PLAYER_CRITICAL_ERROR = 2;
export const TEMPORARY_ORDER_ID = "temporaryOrderID";
export const START_PLAYER_SESSION = "START";
export const PAUSE_PLAYER_SESSION = "PAUSE";
export const RESUME_PLAYER_SESSION = "RESUME";
export const STOP_PLAYER_SESSION = "END";
export const CANCEL_PLAYER_SESSION = "CANCEL";
export const TRAILER_VIDEO = "trailer";
export const MOVIE_VIDEO = "movie";
export const COMMENT = "comment";
export const STATUS = "status" || "Status";
export const PRIVACY_POLICY = "PRIVACY_POLICY";
export const TERMS_CONDITIONS = "TERMS_CONDITIONS";
export const CONTACT_US = "CONTACT_US";
export const ABOUT_US = "ABOUT_US";
export const HOW_TO_WATCH = "HOW_TO_WATCH"
export const CALLBACK = "callback";
export const SUCCESS = "success";
export const ERROR = "error";
export const WARNING = "warning";
export const RESUME = "continue watching";
export const WATCHED = "watched";
export const OWNED = "owned";
export const RENTED = "rented";
export const EXPIRED = "expired";
export const FETCH_GENERE = "genere";
export const PURCHASE_HISTORY = "purchasehistory";
export const REMOVE_MYWATCHED_MOVIE = "removeWatched";
export const FETCH_RELATED_MOVIES = "FETCH_RELATED_MOVIES";
export const UPDATE_MOVIE_RATING = "UPDATE_MOVIE_RATING";
export const FETCH_BANNER = "FETCH_BANNER";
export const FETCH_WATCHLIST = "FETCH_WATCHLIST";
export const FETCH_PURCHASELIST = "FETCH_PURCHASELIST";
export const SECTIONS = "SECTIONS";
export const GENRE = [{ value: "action", label: "Action" }, { value: "adventure", label: "Adventure" }, { value: "animation", label: "Animation" }, { value: "classics", label: "Classics" },
{ value: "comedy", label: "Comedy" }, { value: "crime and thrillers", label: "Crime & Thrillers" }, { value: "documentary", label: "Documentary" }]
export const RELEASE_YEAR = [{ Value: "2010", Name: "2010's" }, { Value: "2000", Name: "2000's" }, { Value: "1990", Name: "1990's" }, { Value: "1980", Name: "1980's" }, { Value: "1970", Name: "1970's" }, { Value: "1960", Name: "1960's" }];//, ]; //, { Value: "1950", Name: "1950's & Earlier" }
export const STUDIO = [{ value: "20th Century Fox", label: "20th Century Fox" }, { value: "Lionsgate", label: "Lionsgate" }, { value: "MGM", label: "MGM" }, { value: "Paramount Pictures", label: "Paramount Pictures" }, { value: "Sony Pictures", label: "Sony Pictures" },
{ value: "Universal Studios", label: "Universal Studios" }, { value: "Walt Disney Studios", label: "Walt Disney Studios" }, { value: "Warner Bros", label: "Warner Bros" }, { value: "Other", label: "Other Studios" }]
export const NOTVALIDPARTNERMESSAGE = "Sorry! You are not Partner User";
export const UPDATE_PROFILE_PHOTO = "UPDATE_PROFILE_PHOTO";
export const REMOVE_MYLIST_MOVIE = "REMOVE_MYLIST_MOVIE";
export const VERIFY_VOUCHER = "VERIFY_VOUCHER";
export const CHECKOUTMOVIEDATAONCHANGEQUALITY = "CHECKOUTMOVIEDATAONCHANGEQUALITY";
export const FETCH_MOVIE_PLAY_VIDEO = "FETCH_MOVIE_PLAY_VIDEO";
export const FETCH_BITMOVIN_DATA = "FETCH_BITMOVIN_DATA";
export const SUBMIT_PAYMENT_FORM = "submitted_for_settlement";
export const VOUCHER_SUCCESS = "Congrats! Voucher Applied Successfully!";
export const VOUCHER_FAILED = "Sorry! Voucher cannot be Redeemed!";
export const WATCHED_REMOVE_MOVIE_SUCCES = "Movie Removed from Watch list Successfully";
export const WATCHED_REMOVE_MOVIE_ERROR = "Movie Couldn't Removed from Watch list. Try Again!";
export const ORDER_NOT_FOUND = "Please Purchase the movie to Enjoy Watching!";
export const RESPONSE_BODY = "res.body";
export const FETCH_MOVIE_DATA = "FETCH_MOVIE";
export const FETCH_SEARCH_DATA = "FETCH_SEARCH_DATA";
export const RESPONSE_ITEMS = "res.body.Items";
export const RESPONSE_MYLIST = "MyList";
export const FETCH_SORTED_MOVIES = "FETCH_SORTED_MOVIES";
export const CLIENT_TOKEN = "clientToken";
export const UPDATE_MYLIST_MOVIE = "UPDATE_MYLIST_MOVIE";
export const MOVIE_STREAM = "MOVIE_STREAM";
export const MOVIE_STREAM_RELATED = "MOVIE_STREAM_RELATED";
export const ALLMOVIES = "ALL_MOVIES";
export const CHECKOUTMOVIEDATA = "CHECKOUTMOVIEDATA";
export const EST = "est";
export const TVOD = "tvod";
export const QUERYTIERID = "teirId";
export const QUERYFREEWATCH = "f";
export const PURCHASE = "purchase";
export const PAYMENT_COMPLETED_STATUS = "ORDER_COMPLETED";
export const HOSTED_FIELDS_INVALID = "HOSTED_FIELDS_FIELDS_INVALID";
export const HOSTED_FIELD_EMPTY = "HOSTED_FIELDS_FIELDS_EMPTY";
export const INSTANTIATION_OPTION_REQUIRED = "INSTANTIATION_OPTION_REQUIRED";
export const CLIENT_INVALID_AUTHORIZATION = "CLIENT_INVALID_AUTHORIZATION";
export const OPERA = "OPERA";
export const SAFARI = "SAFARI";
export const EXPLORER = "EXPLORER";
export const CHROME = "CHROME";
export const EDGE = "EDGE";
export const FIREFOX = "FIREFOX";
export const activeTabMovies = { topPicks: "1", mostWatched: "2", recentlyAdded: "3" };// releaseDate: "4"
export const LOGIN = "login";
export const LOGOUT = "logout";
export const FACEBOOK = "facebook";
export const GOOGLE = "google";
export const NO_EXPIRY = "No expiry";
export const PARTNER_ACCOUNT = "PARTNER_ACCOUNT";
export const FETCH_ALL_NOTIFICATIONS = "FETCH_ALL_NOTIFICATIONS";
export const PARTNER = "PARTNER";
export const REWARD = "plus-rewards-offer";
export const PARTNER_SECTIONS = "PARTNER_SECTIONS";
export const NOTIFICATIONS_LIST = "NOTIFICATIONS";
export const GETCARDSLIST = "paymentcards";
export const SAVECARDSLIST = "savecards"

///*******storage constants: */
export const STORED_MOVIES_DETAIL = "moviesDetail-";
export const STORED_VOUCHERS = "storedVouchers";
export const STORED_BANNER_DATA = "banners";
export const STORED_SECTIONS_DATA = "sections";
export const STORED_PARTNER_SECTIONS = "sectionsPartners";//implement later
export const STORED_HEADER_SECTIONS = "sectionsHeaderCustom";
export const STORED_MY_LIST = "myList";
export const STORED_MY_RELATED_MOVIES = "moviesrelated-"
export const STORED_MOVIES_TYPES = "moviesfiltered"; //not to expose
export const STORED_MY_PURCHASES = "myPurchases";
export const STORED_SEARCH_HISTORY = "searchHistory";
export const STORED_MY_WATCHED_LIST = "myWatchList";
export const STORED_MY_PGRATING_VALUE = "myP";
export const STORED_NOTIFICATIONS_STATUS = "notifications-status"
export const STORED_NOTIFICATIONS_TOKEN = "notifications-token";
export const STORED_MY_NOTIFICATIONS = "myNotifications";
export const STORED_MY_UNREAD_NOTIFICATIONS = "myUnReadNotifications";
export const STORED_MY_PARTNER_NAME = "pr"; //not to expose
export const STORED_MY_DEVICE = "myDeviceKey";
export const STORED_CURRENT_USER = "currentUser";
export const STORED_COGNITO_USER_DATA = "userData";
export const STORED_USER_SESSION_ID = "userSessionID";
export const STORED_SERVER_TIME = "fetched"; //not to expose
export const STORED_EMPTY_MYLIST_FLAG = "nomyList"; //not to expose
export const STORED_REFRESH_NOTIFICATION_STATUS = "refreshnotification-"; //not to expose
export const STORED_REWARD_PROMO_MOVIES = "promo-movies";
export const STORED_REWARD_MOVIES = "reward-movies";
export const STORED_PAYMENT_CARDS = "clist"; // credit card list
export const STORED_DEVICE_LIMIT = "deviceLimitReached";
export const STORED_LIST_SESSIONS = "list-sessions";
export const SOCIAL_LOGIN_TYPE = 'social-login-type';

//************ Document Titles */
export const ABOUT_US_PAGE = "About us";
export const HOW_TO_WATCH_PAGE = "How to Watch";
export const CONTACT_US_PAGE = "Contact us";
export const CHEKCOUT_PAGE = "Checkout";
export const DEVICE_PAGE = "Device Manager";
export const FORGOT_PASSWORD_PAGE = "Forgot Password";
export const GENRE_PAGE = "Genre";
export const HOME_PAGE = "Home";
export const LIBRARY_PAGE = "My Library";
export const LOGIN_PAGE = "Login";
export const MOVIES_PAGE = "Movies";
export const POLICY_PAGE = "Privacy Policy";
export const SEARCH_PAGE = "Search";
export const TERMS_PAGE = "Terms & Conditions";
export const SIGN_UP_PAGE = "Sign Up Page";
export const PARTNER_SIGNUP_PAGE = "Partner Sign Up";
export const NOTIFICATIONS_PAGE = "Notifications";
export const WATCH_PAGE = "Watch";
export const PROFILE_PAGE = "Profile";
export const PURCHASE_LISTING = "My Purchase Listing";
export const SETTINGS_PAGE = "Settings";

/****** NOTIFICATONS CONSTANTS******* */
export const NOTICATIONS_NEW_MOVIE = "new_movie";
export const NOTICATIONS_EXPIRING = "expired_movie";
export const NOTICATIONS_EXPIRY = "expiring_movie";
export const NOTICATIONS_REFRESH = "refresh";
export const NOTICATIONS_REFRESH_PURCHASELIST = "orders";
export const NOTICATIONS_REFRESH_MYLIST = "mylist";
export const NOTICATIONS_REFRESH_WATCHLIST = "watchhistory";
export const NOTICATIONS_REFRESH_SUBSCRIPTIONS = 'subscriptions';
export const NOTICATIONS_REFRESH_COLLECTIONS = 'collections';
export const NOTIFICATIONS_REFRESH_FORGET_SESSION = "userSessions";
export const NOTICATIONS_REFRESH_CONFIG = "config";
export const NOTICATIONS_REFRESH_ALL = "all";
export const NOTICATIONS_REFRESH_MOVIE = "movie";
export const NOTICATIONS_REFRESH_SECTIONS = "sections";
export const NOTICATIONS_REFRESH_BANNERS = "banners";

/****** IMAGE SIZE CONSTANTS******* */
export const MOVIE_CARD_SIZE = "moviecard";
export const WATCH_CARD_SIZE = "watchcard";
export const GENERE_SMALL_SLIDER_SIZE = "genereslider";
export const MOVIE_PREVIEW_SIZE = "moviepreview";
export const BANNER_PREVIEW_SIZE = "bannerpreview";
export const LOGIN_BACKGROUND_SIZE = "loginbackground";
export const NOTIFICATIONS_IMAGE_SIZE = "watchhistory";
export const CARD_IMAGE_SIZE = `/0x660/`;//`/0x330/`
export const NOTIFICATION_IMAGE_SIEZ = `/0x120/`;//`/0x60/`;
export const LIBRARY_IMAGE_SIZE = `/288x0/`;//`/0x162/`
export const GENRE_IMAGE_SIZE = `/0x180/`;//`0x90`
export const PREVIEW_BG_IMAGE_SIZE = `/1600x700/`;//`1600 x 700`

