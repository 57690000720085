import React, { Component } from "react";
import PropTypes from "prop-types";
import Loader from "../../components/Loader/Loader"
import _includes from "lodash/includes";
import Auth from "@aws-amplify/auth";
import {LOGIN, SOCIAL_LOGIN_TYPE} from "../../constants/constants";
import {
    querystring,
    clearBrowserCache,
    getDataFromLocalStorage,
    storeDataInLocalStore
} from "../../helpers/GeneralHelpers";
import { Hub } from '@aws-amplify/core';
import config from "../../config/config";
import { withFirebase } from "../../context/FirebaseContext";
import withUserSession from "../../hoc/UserSession";
import { withAppConfig } from "../../context/ConfigContext";
import { withAuth } from "../../context/AuthContext";
const SOCIAL_LOGIN_FAILED_MESSAGE = "Couldn't login! Try Again";

class SocialRedirect extends Component {
    isCompMounted = true;
    constructor(props) {
        super(props);
        Hub.listen('auth', this);
    }

    componentDidMount() {
        this.checkIsLoginCancelled();
    }

    componentWillUnmount() {
        this.isCompMounted = false;
    }

    checkIsLoginCancelled = async () => {
        let error = querystring("error_description");
        let isError = false;
        let sessionSuccessfull = false;
        try {
            if (error !== null && error !== "") {
                let isException = _includes(error, "AliasExistsException");
                if(_includes(error, "AliasExistsException")){
                    isException = true;
                }
                if(_includes(error, "Exception processing authorization code")){
                    isException = true;
                }
                let isGoogle = _includes(error, "Google") || _includes(getDataFromLocalStorage(SOCIAL_LOGIN_TYPE),"Google");
                let isFacebook = _includes(error, "Facebook") || _includes(getDataFromLocalStorage(SOCIAL_LOGIN_TYPE),"Facebook");
                if (isException) {
                    if (isGoogle) {
                        clearBrowserCache();
                        await storeDataInLocalStore(SOCIAL_LOGIN_TYPE,"Google");
                        const {
                            domain,
                            redirectSignIn,
                            responseType } = config.oauth;
                        const clientId = Auth.configure().userPoolWebClientId;
                        const url_to_google = 'https://' + domain + '/oauth2/authorize?redirect_uri=' + redirectSignIn + '&response_type=' + responseType + '&client_id=' + clientId + '&identity_provider=Google';
                        window.location.assign(url_to_google);
                        return;  // isError = false; //replacement
                    } else if (isFacebook) {
                        clearBrowserCache();
                        await storeDataInLocalStore(SOCIAL_LOGIN_TYPE,"Facebook");

                        const {
                            domain,
                            redirectSignIn,
                            responseType } = config.oauth;
                        const clientId = Auth.configure().userPoolWebClientId;
                        const url_to_facebook = 'https://' + domain + '/oauth2/authorize?redirect_uri=' + redirectSignIn + '&response_type=' + responseType + '&client_id=' + clientId + '&identity_provider=Facebook';
                        window.location.assign(url_to_facebook);
                        return;// isError = false;
                    } else {
                      //  isError = true
                    }
                } else {
                    //isError = true
                }
                isError = true;
            } else if (await Auth.currentSession()) {
                sessionSuccessfull = true;
                this.props.updateAuthStatus(true);
                this.props.updateUserData();
            }
        } catch (e) {
            isError = true;
        }
        if (isError || sessionSuccessfull) { // move to login in case of sessionsuccessful as well.
            if(isError){
             this.props.showAlert("showErrorAlert", SOCIAL_LOGIN_FAILED_MESSAGE);
            }
             setTimeout(() => {
                this.props.history.push(LOGIN);
            }, 5000);
        }
    }

    onHubCapsule(capsule) {
        const { channel, payload, source } = capsule;
        if (channel === 'auth' && (payload.event === 'configured' || payload.event === 'signIn')) {
            this.checkUser();
        } else if (channel === 'auth' && (payload.event === 'configured' || payload.event === 'signOut')) {
            this.onLogoutUser();
        }
    }

    checkUser = () => {
        Auth.currentAuthenticatedUser()
            .then(() => {
                this.props.createSession(true); //true -> redirect to home
            }).catch(() => { });
    }

    onLogoutUser = () => {
        //"logout user"
    }

    render() {
        // console.log("this.p",this.props)
        return (
            <main>
                <Loader />
            </main>
        )
    }
}
SocialRedirect.propTypes = {
    createSession: PropTypes.func.isRequired,
    showAlert: PropTypes.func
}
export default withFirebase(withUserSession(withAppConfig(withAuth(SocialRedirect))));