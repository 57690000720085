import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import _sortBy from "lodash/sortBy";
import _find from "lodash/find";
import { withAuth } from "../../../../context/AuthContext";
import PurchaseModal from "../../../../components/RoomCollection/PurchaseModal";
import { withAppConfig } from "../../../../context/ConfigContext";
import { SERVICE_UNAVAILABLE } from "../../../../constants/routes";

const Subscribe = props => {
  let lowestPrice = "";
  let sorted = props.Price;
  if (sorted) {
    sorted = _sortBy(props.Price, ["Price"]);
    if (sorted && sorted.length > 0) {
      lowestPrice = `$${sorted[0].Price}`;
    }
  }
  const [isOpen, toggleModal] = useState(false);

  const onClick = e => {
    e.preventDefault();
    if (!props.isAuthenticated) {
      props.history.push({
        pathname: `/checkout-sc/${props.ID}`,
        search: `?type=${sorted[sorted.length - 1].Name}&method=collection`
      });
      return; //autoselect highest quality
    }
    let geoBlock = _find(props.configurations, ["KEY", "REGION_POLICY"]);
    if (geoBlock && geoBlock.VALUE) {
      geoBlock = geoBlock.VALUE[0];
    }
    if (geoBlock.isGeoBlocked && props.isAuthenticated) {
      props.history.push(`${SERVICE_UNAVAILABLE}`);
    } else {
      toggleModal(!isOpen);
    }
  };

  if (props.isCPurchased_) {
    return (
      <div className="mb-4">
        <a className="btn btn-success btn-large font-weight-bold mr-3 mb-3">
          Rented
        </a>
      </div>
    );
  }

  return (
    <div className="mb-4">
      <a
        className="btn btn-primary btn-large font-weight-bold mr-3 mb-3"
        onClick={onClick}
      >
        Rent {lowestPrice}
      </a>
      <PurchaseModal
        isOpen={isOpen}
        toggleModal={onClick}
        ID={props.ID}
        Price={props.Price}
        Type="collection"
        Duration={props.Duration}
      />
    </div>
  );
};

export default withAuth(withRouter(withAppConfig(Subscribe)));
