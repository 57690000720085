import React, { Component } from "react";
import PropTypes from "prop-types";
import { Navbar, NavbarBrand, Nav, NavItem, Dropdown, UncontrolledCollapse, Button } from 'reactstrap';
import { NavLink } from 'react-router-dom'
import Logo from "../../../../assets/images/muvinow-logo.png";
import config from "../../../../config/config";
import NotificationsBell from "./NotificationsBell";
import SearchBox from "./SearchBox";
import Cache from '@aws-amplify/cache'
import { withAppConfig } from "../../../../context/ConfigContext";
import MuviNowProfileDropDown from "./MuviNowProfileDropDown";
import * as routes from "../../../../constants/routes";
import { getDataFromLocalStorage, getCurrentUserId } from "../../../../helpers/GeneralHelpers";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


class MuviNowHeaderView extends Component {
    constructor() {
        super();
        this.state = {
            dropdownOpen: false,
            nav: true,
            USER_ID: ""
        };
    }

    toggle = () => this.setState({ dropdownOpen: !this.state.dropdownOpen });

    handleCustomSectionItemClick = (event, section) => {
        this.props.history.push(`${routes.GENRE}${section.Type}/${section.ID}?name=${section.Name}&filtered=${true}`);
    }

    // ========= This Method Loads Login Button
    renderRightMenu = () => {
        return (
            <div className="mn-header-right col col-xl-3">
                <Nav className="navbar-nav secondary-nav align-items-center flex-row">
                    {/*<li className="nav-item">/!* dropdown hd-dropdown not-dropdown*!/*/}
                    {/*<NotificationsBell {...this.props} />*/}
                    {/*</li>*/}
                    <li className="d-xl-none">
                        <button className="btn btn-link" id="toggler">
                            <i className="las la-search font-20"></i>
                        </button>
                    </li>
                    {<MuviNowProfileDropDown {...this.props} />}
                </Nav>
            </div>
        )
    }

    // =========== This Method Loads Search Box ===========
    renderSearchBox = () => {
        return (
            <div className="mn-header-center col-12 col-xl-6">
                <UncontrolledCollapse toggler="#toggler">
                    <SearchBox {...this.props} />
                </UncontrolledCollapse>
            </div>
        )
    }

    // ============ This Method loads Logo and DropDown Button with Links ============

    renderHeaderBrand = () => {
        const { appTranslations: tr, appSettings = {} } = this.props;
        // Main Links Array
        let menuLinks = [
            { to: routes.MOVIES, name: tr.movies || "Movies" },
            { to: routes.MYLIST, name: tr.mylist || "My List" },
            // { to: routes.WATCHHISTORY, name: tr.watchhistory || "Watch History" },
            { to: routes.PURCHASE_LISTING, name: tr.purchase_listing || "Purchase History" },
            { to: routes.MYRENTALS, name: tr.myrentals || "My Rentals" },
            { to: routes.MYSUBSCRIPTIONS, name: tr.mysubscriptions || "My Subscriptions" }];
        if (appSettings.EnableBundlesMenu === true || appSettings.EnableBundlesMenu === "true") {
            menuLinks.splice(2, 0, { to: routes.ALLCOLLECTIONS, name: tr.collections || "Bundles" });
        }

        // if (appSettings.EnableChannelsMenu === true || appSettings.EnableChannelsMenu === 'true') {
        //     menuLinks.splice(3, 0, { to: routes.ALLROOMS, name: tr.rooms || "Channels" });
        // }
        // const { appSettings } = this.props;

        // ====== Main Logo ======
        let [BrandAlt, BrandLogo] = [config.applicationName, Logo];
        if (appSettings) {
            BrandAlt = appSettings.ApplicationName && appSettings.ApplicationName
            BrandLogo = appSettings.Logo && appSettings.Logo
        }
        return (
            <div className="mn-header-left d-flex align-items-center col col-xl-3">
                <NavbarBrand className="navbar-brand" href={routes.HOME}>
                    {BrandLogo ?
                        <img alt={BrandAlt} id="myAppLogo" src={BrandLogo} />
                        :
                        <h3>{BrandAlt}</h3>
                    }
                </NavbarBrand>


                <Dropdown className="ml-5" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                    <DropdownToggle nav className="no-caret d-flex align-items-center dropdown-toggle">
                        <i className="las la-bars font-20 mr-2"></i>
                        <span>Browse</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-dark has-arrow-left">
                        <div className="row no-gutters align-items-stretch">
                            <div className="browse-left col-md-6 py-2">
                                <div className="dropdown-header">Browse</div>
                                {menuLinks.map((link, index) => (
                                    <DropdownItem key={index} onClick={() => { this.props.history.push(link.to) }} >{link.name}</DropdownItem>))}
                            </div>
                            {this.props.sections !== null || this.props.sections !== undefined ?
                                <div className="browse-right col-md-6 py-2 bg-gray-700">
                                    <div className="dropdown-header text-primary"><span className="font-weight-bold">Premium </span> Packs</div>
                                    {this.props.sections.map((room, index) => (
                                        <DropdownItem key={index} onClick={() => {this.props.history.push(`/room-detail/${room.id}`) }} >{room.title}</DropdownItem>))}
                                </div>
                                : null
                            }
                        </div>
                    </DropdownMenu>
                </Dropdown>
            </div>
        )
    }           /* ============ Drop Down Design END ============  */

    render() {
        return (
            <header className="mn-header fixed-top shadow-lg">
                <div className="mn-header-top">
                    <div className="container-fluid">
                        <div className="row gutter-5 align-items-center">
                            {this.renderHeaderBrand()}
                            {this.renderSearchBox()}
                            {this.renderRightMenu()}
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default withAppConfig(MuviNowHeaderView);
MuviNowHeaderView.defaultProps = {
    appSettings: {},
}
MuviNowHeaderView.propTypes = {
    appSettings: PropTypes.object.isRequired,
}
