import React from "react";
import _find from "lodash/find";
import { withAppConfig } from "../../../context/ConfigContext";

const AppliedVoucher = props => {
  const { appTranslations: tr } = props;

  const onClick = e => {
    e.preventDefault();
    let checkoutData = {};
    let x = _find(props.data.Price, ["Name", props.selectedQuality]);
    if (x) {
      checkoutData["Price"] = x.Price;
      checkoutData['isAutoRedempted'] = false;
    }
    props.onRemove(checkoutData);
  };

  return (
    <div className="mn-cart-row row align-items-center voucher-code-row">
      <div className="mn-cart-cell col-12 col-md-6 text-left text-md-right">
        {tr.reward_code_used || "Reward Code Used"}
      </div>
      <div className="mn-cart-cell col-12 col-md-4">
        {props.checkoutData.Code}
      </div>
      <div className="mn-cart-cell col-12 col-md-2">
        <div className="d-flex justify-content-between align-items-center">
          <span>{props.appSettings.CurrencySymbol}{props.checkoutData.Discount}</span>
          <span className="ml-3">
            <button
              title={tr.remove_your_code || "Remove Your Code"}
              onClick={onClick}
              className="btn btn-sm btn-circle btn-secondary"
            >
              <i className="la la-times" />
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default withAppConfig(AppliedVoucher);
