import React, { Component, Fragment } from "react";
import MyInput from "../../components/MyInput";
import Formsy from 'formsy-react';
import { Link } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import { showError, setDocumentTitle } from "../../helpers/GeneralHelpers";
import { handleChange, enableButton, disableButton, disableAlert } from "../../helpers/StateHelper";
import MySocialLogin from "../../components/MySocialLogin/MySocialLogin";
import { LOGIN, FACEBOOK, SIGN_UP_PAGE } from "../../constants/constants";
import { getInputFields } from "./constants";
import withUserSession from "../../hoc/UserSession";
import { withFirebase } from "../../context/FirebaseContext";
import _includes from "lodash/includes";
import { withAppConfig } from "../../context/ConfigContext";
import { TERMS_CONDITIONS } from "../../constants/routes";
import AlreadyFoundException from "./components.jsx/AlreadyFoundException";
import loginVector from "../../assets/images/svg/login-boy.svg";
import { auth } from "firebase";


class Signup extends Component {
    isCompMounted = true;
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            email: "",
            password: "",
            confirmPassword: "",
            phone_number: "",
            canSubmit: false,
            inputValue: "Use Mobile",
            errorMessage: "",
            showErrorAlert: false,
            isChecked: false,
            isPhoneNumberSignup: false,
        };
        this.disableAlert = disableAlert.bind(this);
        this.showError = showError.bind(this);
        this.handleChange = handleChange.bind(this);
        this.enableButton = enableButton.bind(this);
        this.disableButton = disableButton.bind(this);
    }

    componentDidMount() {
        setDocumentTitle(SIGN_UP_PAGE);
        if (this.props.location.state && this.props.location.state.email || this.props.location.state && this.props.location.state.phone_number && this.props.location.state && this.props.location.state.password) {
            this.setState({ email: this.props.location.state.email, phone_number: this.props.phone_number, password: this.props.location.state.password });
        }
    }

    componentWillUnmount() {
        this.isCompMounted = false;
    }

    handleSubmit = async event => {
        const { appTranslations: tr } = this.props;
        const { error_invalid_password } = this.props.appTranslations;
        this.setState({ isLoading: true });
        if (this.state.email.indexOf('@') > -1) {
            await this.setState({ isPhoneNumberSignup: false });
        } else {
            await this.setState({ isPhoneNumberSignup: true });
        }
        try {
            await Auth.signUp({
                username: this.state.isPhoneNumberSignup ? '+' + this.state.phone_number : this.state.email.toLowerCase(),
                password: this.state.password
            });
            if (this.state.isPhoneNumberSignup === false && this.props.appSettings && this.props.appSettings.EmailLinkVerification === "true" || this.props.appSettings.EmailLinkVerification === true) {
                this.onSignupSuccess();
            } else if (this.state.isPhoneNumberSignup) {
                this.onPhoneNumberSignupSuccess();
            } else {
                await Auth.signIn(this.state.isPhoneNumberSignup ? this.state.phone_number : this.state.email.toLowerCase(),  this.state.password);
                this.props.updateAuthStatus(true, false);
                this.props.updateUserData();
                this.props.createSession();
                this.setState({ isLoading: false });
                this.props.history.push('/');
            }
        } catch (error) {
            let message = error.message;
            if (error.code === 'UserNotConfirmedException') {
                this.props.history.push('/confirm', { email: this.state.email, phone_number: this.state.phone_number,  password: this.state.password });
            } else {
                if (error.code === "InvalidParameterException") {
                    message = error_invalid_password;
                }
                if (error.code === "UsernameExistsException" && error.message === "An account with the given email already exists.") {
                    message = tr.error_an_account_with_the_given_email_al || "Email address is invalid";
                }
                if (error.code === 'UserLambdaValidationException') {
                    if (_includes(error.message, 'PreSignUp failed with error')) {
                        message = error.message.replace(`PreSignUp failed with error`, ``);
                    }
                }
                if (error.code === 'UsernameExistsException') {
                    this.setState({
                        userAlreadyExists: true
                    })
                }
                this.setState({
                    isLoading: false,
                    showErrorAlert: true,
                    errorMessage: message
                });
                this.disableAlert("showErrorAlert");
            }
        }
    }

    onSignupSuccess = () => {
        this.props.showAlert("showSuccessAlert", "Verification Email has been sent to your Email Address. Please Verify your account by clicking the link.")
        this.props.history.push('/login', { showConfirmation: true, email: this.state.email});
    }

    onPhoneNumberSignupSuccess = () => {
        this.props.showAlert("showSuccessAlert", "Please Verify your phone number by providing code.")
        this.props.history.push('/confirm', { phone_number: this.state.phone_number,  password: this.state.password })
    }

    handleCheckbox = () => {
        this.setState((prevState) => {
            return {
                isChecked: !prevState.isChecked
            }
        });
    }
    changeLabelText = () => {
        this.setState({email:'',phone_number:''});
        this.setState({
            inputValue: this.state.inputValue === "Use Mobile" ? "Use Email" : "Use Mobile"
        })
    }


    renderForm() {
        const { sign_in_with_facebook, sign_in_with_google, phone_number, email_address, sign_up, signing_up } = this.props.appTranslations;
        let InputFields = getInputFields(this.props.appTranslations, this.state.inputValue);
        let termsText = this.props.appTranslations.agree_to_our_terms_conditions || `Agree to our $ Terms &amp; Conditions.`;
        termsText = termsText.split('$');
        let username = {
            phone_number: this.state.phone_number,
            email: this.state.email
        }
        return (
            <Fragment>
                <h4 className="text-uppercase mb-4 text-center">{sign_up}</h4>
                {this.state.showErrorAlert &&
                    <div className="errorContainer">
                        {this.showError(null, this.state.errorMessage)}
                    </div>
                }
                <AlreadyFoundException
                    showWarning={this.state.userAlreadyExists}
                    username={username}
                />
                <div className="text-right">
                    <a onClick={this.changeLabelText} className="join-type">
                        {this.state.inputValue}
                    </a>
                </div>
                {this.state.isLoading && <span className="loader-spiral mn-white mn-white-transparent"><span className="spiral-inn"></span></span>}
                <Formsy onValidSubmit={this.handleSubmit} onValid={this.enableButton} onInvalid={this.disableButton}>
                    {InputFields && InputFields.map((input, index) => {
                        const { Name, Type, Placeholder, ValidationError, Validations } = input;
                        return (
                            <div>
                                <MyInput
                                    key={index}
                                    id={Name}
                                    name={Name}
                                    placeholder={Placeholder}
                                    validations={Validations}
                                    validationError={ValidationError}
                                    type={Type}
                                    required
                                    outerClassName="mb-4"
                                    handleChange={this.handleChange}
                                />
                            </div>

                        )
                    })}
                    <div className="form-group font-12 font-weight-normal">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" onClick={this.handleCheckbox} className="custom-control-input" id="switch1" value={this.state.isChecked} />
                            <label className="custom-control-label" htmlFor="switch1">{termsText[0]} </label>
                            <Link to={TERMS_CONDITIONS}>{termsText[1] ? termsText[1] : ''}</Link>
                        </div>
                    </div>
                   
                    <div className="mb-4">
                        <LoaderButton
                            disabled={!this.state.canSubmit || !this.state.isChecked}
                            type="submit"
                            className="btn btn-primary btn-block text-uppercase"
                            isLoading={this.state.isLoading}
                            text={sign_up}
                            loadingText={`${signing_up || "Signing up"} …`}
                        />
                    </div>
                    <div className="mb-4 or-divider"></div>
                    <div className="mb-4 d-flex justify-content-between font-12 text-white-50">
                        <div>Continue with social media</div>
                        <div><a href="#" className="link-white-50">Need Help?</a></div>
                    </div>
                    <div className="row gutter-10">
                        <div className="col">
                            <MySocialLogin
                                loginText="Facebook"
                                className="btn-blue btn-lg"
                                action={LOGIN}
                                type={FACEBOOK}
                            />
                        </div>
                        <div className="col">
                            <MySocialLogin
                                loginText="Google"
                                className="btn-red btn-lg"
                                action={LOGIN}
                            />
                        </div>
                    </div>
                </Formsy>
            </Fragment>
        );
    }

    render() {
        const { already_have_an_account, sign_in_now } = this.props.appTranslations;

        return (
            <main className="mn-main">
                <section className="mn-login-section py-5">
                    <div className="container">
                        <div className="mn-login-content">
                            <div className="mn-login-body row no-gutters align-items-stretch">
                                <div className="mn-login-left d-flex flex-column justify-content-center bg-gray-800 px-3 py-4 p-md-5 col-12 col-md-6 ">

                                    {this.renderForm()}
                                </div>
                                <div className="mn-login-right d-flex flex-column text-center p-5 col-12 col-md-6">
                                    <div className="mb-4 mt-auto">
                                        <img src={loginVector} style={{ maxWidth: "250px" }} />
                                    </div>
                                    <div className="mt-auto">
                                        <div className="mb-3">{already_have_an_account}</div>
                                        <div className="">
                                            <Link to="/login" className="btn btn-white btn-lg btn-large">{sign_in_now}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}
export default withFirebase(withUserSession(withAppConfig(Signup)));

