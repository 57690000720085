import React from "react";
import config from "./config/config";
import { storeDataInLocalStore } from "./helpers/GeneralHelpers";
import { MOVIE, WATCH } from "./constants/routes";
import { STORED_NOTIFICATIONS_STATUS, NOTICATIONS_NEW_MOVIE, NOTICATIONS_EXPIRY, NOTICATIONS_EXPIRING, MOVIE_VIDEO } from "./constants/constants";
import 'firebase/messaging';
import { updateCacheOnRefreshNotification } from "./helpers/CacheHelpers";

export const askForPermissioToReceiveNotifications = async (messaging) => { //IMPLEMENT IN Firebase
  try {
    await messaging.requestPermission();
    const token = await messaging.getToken();
    return token;
  } catch (error) {
    if (error.code) {
      let notificationStatus;
      if (error.code === "messaging/permission-blocked") {
        notificationStatus = "BLOCKED";
      } else if (error.code === "messaging/permission-default") {
        notificationStatus = "DEFAULT";
      }
      storeDataInLocalStore(STORED_NOTIFICATIONS_STATUS, notificationStatus);
    }
  }
}

export async function getMessagesStatus(messaging) {
  try {
    messaging.onMessage((recievedMessage) => {
      this.updateNotificationCount();
      if (recievedMessage) {
        let moviePoster = false;
        if (recievedMessage.data.PayloadType === NOTICATIONS_NEW_MOVIE) {
          if (recievedMessage.data.MoviePoster) {
            moviePoster = recievedMessage.data.MoviePoster
          }
          setAlertProperties(recievedMessage.data.MovieName, recievedMessage.data.MovieID, NOTICATIONS_NEW_MOVIE, moviePoster);
          updateCacheOnRefreshNotification(recievedMessage.data.PayloadType);
        } else if (recievedMessage.data.PayloadType === NOTICATIONS_EXPIRY) {
          if (window.location.location === `${WATCH}${MOVIE_VIDEO}/${recievedMessage.data.MovieID}`) {
            setAlertProperties(recievedMessage.data.MovieName, recievedMessage.data.MovieID, NOTICATIONS_EXPIRY, moviePoster)
            window.location.href = `${MOVIE}${recievedMessage.data.MovieID}`;
          } else {
            setAlertProperties(recievedMessage.data.MovieName, recievedMessage.data.MovieID, NOTICATIONS_EXPIRY, moviePoster)
          }
        } else if (recievedMessage.data.PayloadType === NOTICATIONS_EXPIRING) {
          setAlertProperties(recievedMessage.data.MovieName, recievedMessage.data.MovieID, NOTICATIONS_EXPIRING, moviePoster)
        }
      }
      return recievedMessage;
    });
  } catch (error) {
    //console.log("error:", error);
  }
}

export const handleOnAlertClick = () => {
  let movieId = document.getElementById("my-notification-id").innerHTML;
  let movieName = document.getElementById("my-notification-message").innerHTML;
  let notificationStatus = document.getElementById("my-notification-status").innerHTML;
  if (movieId) {
    if (notificationStatus === NOTICATIONS_NEW_MOVIE || notificationStatus === NOTICATIONS_EXPIRING) {
      window.location.href = `${MOVIE}${movieId}`;
    }
  }
  //handleSeenNotification();//i need createAt of notification here!
}

export const PushNotificationsAlert = () => {//movieName, movieId, type
  return ( //change this id name
    <div id="snackbar" className="">
      <img id="my-notification-icon" className="mr-2" src={config.applicationFavicon} style={{ width: "24px" }} />
      <span className="text-success font-weight-bold mr-2">New Notification!</span>
      <span id="my-notification-message"></span>
      <span id="my-notification-id" style={{ display: "none" }}></span>
      <span id="my-notification-status" style={{ display: "none" }}></span>
    </div>
  )
}

export const setAlertProperties = (movieName, movieId, status, Icon = false) => { //changeIdName
  let snackbar = document.getElementById("snackbar");
  let notificationMessage = document.getElementById("my-notification-message");
  let notificationId = document.getElementById("my-notification-id");
  let notificationStatus = document.getElementById("my-notification-status");
  let notificationIcon = document.getElementById("my-notification-icon");
  snackbar.className = "show";
  snackbar.addEventListener("click", handleOnAlertClick);
  notificationMessage.innerHTML = movieName;
  notificationId.innerHTML = movieId;
  notificationStatus.innerHTML = status;
  if (Icon) {
    notificationIcon.src = Icon
  }
  setTimeout(() => {
    snackbar.className = "hide";
    notificationMessage.innerHTML = "";
    notificationId.innerHTML = "";
    notificationStatus.innerHTML = "";
  }, 10000);
}