import React from "react";
import MyRadioButton from "../../../MyRadioButton/MyRadioButton";

const Qualities = ({ selectedQuality, Price, onChange, Type, Duration }) => {
  if (Price && Price.length < 1) {
    return null;
  }
  return (
    <ul className="rent-list">
      {Price.map((quality, index) => {
        let { Price = ``, Name = `` } = quality;
        let labelName = `${Name.toUpperCase()} - $${Price}`;
        if (Type === "screening_room") {
          labelName = `${Name.toUpperCase()} - $${Price} / ${
            Duration === "yearly"
              ? "yr"
              : Duration === "monthly"
              ? "mon"
              : Duration === "quarterly"
              ? "qtr"
              : ``
          }`;
        }

        return (
          <li key={index}>
            <MyRadioButton
              name={Price}
              outerClassName="form-check-inline"
              innerClassName="form-check-input"
              label={labelName}
              id={Name}
              selectedOption={selectedQuality}
              handleOptionChange={onChange}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default Qualities;
