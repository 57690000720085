import React, { useContext } from "react";
import PropTypes from "prop-types";
import { getLowestQulaity, checkIsMovieSubscribed } from "../../../helpers/MoviesHelper";
import { withAppConfig } from "../../../context/ConfigContext";
import { AuthContext } from "../../../context/AuthContext";
const RentalLocks = ({
  isAuthenticated,
  movie,
  listing,
  handleMoviePlayIconClick,
  handleMovieSubscribeIconClick,
  appTranslations: tr
}) => {
  const UserContext = useContext(AuthContext);

  const { RentParams = [], isPurchased_, isExpired_, BuyParams = [] } = movie;
  if (
    isAuthenticated &&
    ((isPurchased_ && !isExpired_) || checkIsMovieSubscribed(UserContext.subscriptions, movie) ||
      movie.isSubscribed_ ||
      movie.isCPurchased_)
  ) {
    return (
      <div className="mb-3" onClick={handleMoviePlayIconClick}>
        <button className="btn btn-primary btn-medium font-weight-bold">
          {tr.play_movie || "Play Movie"}
        </button>
      </div>
    );
  }
  if (movie.isAvailableForSubscription && listing !== true) {
    return (
      <div className="mb-3">
        <button className="btn btn-primary btn-medium font-weight-bold" onClick={handleMovieSubscribeIconClick}>
        Subscribe
        </button>
      </div>
    );
  }

  else if (movie.isAvailableForBuy && BuyParams && BuyParams.length) {
    return (
      <div className="mb-3">
        <button className="btn btn-primary btn-medium font-weight-bold">
          {tr.buy || "Buy"} {getLowestQulaity(BuyParams)}
        </button>
      </div>
    );
  }

  else if (movie.isAvailableForRent && RentParams.length) {
    let rentText = `${tr.rent || "Rent"} ${getLowestQulaity(RentParams)}`;
    if (rentText === `${tr.rent || "Rent"} ₱0`) {
      rentText = tr.free_watch || "Free Watch";
    }
    return (
      <div className="mb-3">
        <button className="btn btn-primary btn-medium font-weight-bold">
          {rentText}
        </button>
      </div>
    );

  }

  return null;
};

export default withAppConfig(RentalLocks);

RentalLocks.propTypes = {
  movie: PropTypes.object,
  isAuthenticated: PropTypes.bool
};
