import React, { useState } from "react";
import _find from "lodash/find";
import { withRouter } from "react-router-dom";
import LoaderButton from "../../../LoaderButton/LoaderButton";
import VoucherContainer from "./Voucher";
import { canRedeemCollectionRoom } from "../../../../services/checkout/canRedeem";
import config from "../../../../config/config";
import { withAppConfig } from "../../../../context/ConfigContext";

const PurchaseContainer = props => {
  const [verifyingVoucher, setLoading] = useState(false);
  const [voucher, setVoucher] = useState("");

  const handleChange = (name, value) => {
    setVoucher(value);
  };

  const onSubmitVoucher = e => {
    e.preventDefault();

    let price = _find(props.Price, ["Name", props.selectedQuality]);
    let redeemParams = {
      Voucher: voucher,
      TotalPrice: price ? price.Price : null,
      Quality: props.selectedQuality,
      Path: props.Type
    };
    if (props.Type === "collection" || props.Type === "screening_room") {
      redeemParams["RoomID"] = props.ID;
    } else {
      redeemParams["MovieID"] = props.ID;
    }
    setLoading(true);
    if (voucher) {
      canRedeemCollectionRoom(onResponse, redeemParams);
    } else {
      redirect({});
    }
  };

  const onResponse = (response, err) => {
    if (!err) {
      const { ApplicationName = config.applicationName } = props.appSettings;
      props.showAlert(
        "showSuccessAlert",
        `Your ${ApplicationName} Reward Code was applied`
      );
      redirect(response);
      return;
    } else {
      props.showAlert("showErrorAlert", err);
    }
    setLoading(false);
  };

  const redirect = (response = {}) => {
    props.history.push({
      pathname: `/checkout-sc/${props.ID}`,
      search: `?type=${props.selectedQuality}&method=${props.Type}`
    },{ Voucher: response.Code, VoucherData: response });
  };

  return (
    <div>
      <Loader isLoading={verifyingVoucher} />
      <VoucherContainer handleChange={handleChange} voucher={voucher} />
      <div className="">
        <Rent
          onClick={onSubmitVoucher}
          Text={props.Type === "screening_room" ? "Subscribe" : "Rent"}
        />
      </div>
    </div>
  );
};

const Rent = props => {
  return (
    <LoaderButton
      className="btn btn-primary font-weight-bold btn-block"
      onClick={props.onClick}
      text={props.Text}
    />
  );
};

const Loader = props => {
  if (props.isLoading) {
    return (
      <span className="loader-spiral mn-white mn-transparent">
        <span className="spiral-inn" />
      </span>
    );
  }
  return null;
};

export default withRouter(withAppConfig(PurchaseContainer));
