import React, { useState, useContext, useEffect, memo } from "react";
import Loader from "../../../components/Loader/Loader";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";
import { AuthContext, withAuth } from "../../../context/AuthContext";
import Section from "../components/Section";
import { listMovieBySection } from "../../../services/movies/ListMovieBySection";
import { withAppConfig } from "../../../context/ConfigContext";
import { GetWatchList } from "../../../services/user/GetWatchList";
import { validSection } from "../helpers";
import ScreeningSection from "../components/ScreeningSection";
import CollectionsSection from "../components/CollectionsSection";

const Sections = ({ myList, serverTime, HostName, ...props }) => {
  const UserContext = useContext(AuthContext);
  const [sections, setSections] = useState([]);
  const [watched, setWatched] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    initialize();
    return () => { };
  }, [myList, props.subscriptions, props.mycollections]);

  const initialize = async () => {
    let watchList = watched;
    if (UserContext.isAuthenticated) {
      let params = { CdnUrl: HostName, UserId: UserContext.UserID };
      watchList = await GetWatchList(params);
      setWatched(watchList);
    }
    let params = {
      HostName: HostName,
      purchases: UserContext.purchases,
      subscriptions: UserContext.subscriptions,
      mycollections: UserContext.mycollections,
      myList: myList,
      watchList: watchList,
      serverTime: serverTime,
      userID: UserContext.UserID,
      isAuthenticated: UserContext.isAuthenticated
    };
    //fetchFullWatchList();
    listMovieBySection((response, err) => {
      if (!err) {
        setSections(response);
      }
      setLoading(false);
    }, params);
  };

  if (isLoading) {
    return (
      <section>
        <Loader />
      </section>
    );
  }

  if (!isLoading && sections.length < 1) {
    return (
      <section className="mn-movies py-4">
        <DataNotFound />
      </section>
    );
  }
  return sections.map((section, index) => {

    if (validSection(section) && section.Type !== 'watched') {

      return section.Type === "screening_room" ? (
        <ScreeningSection serverTime={serverTime} key={index} {...section} />
      ) : section.Type === "movie_collections" ? <CollectionsSection serverTime={serverTime} key={index} {...section} /> : (
        <Section serverTime={serverTime} key={index} {...section} />
      )
    }

  });
};

export default withAppConfig(memo(withAuth(Sections)));
