import React, { Component } from "react";
import RewardPlus from "./RewardPlus/RewardPlus";
import { REWARD } from "../../constants/constants";

class PartnerLanding extends Component {
    isCompMounted = true;
    constructor(props) {
        super(props);
        this.state = {
            partner: ""
        }
    }

    componentDidMount() {
        this.props.history.push("/");
        let partner = this.props.history.location.pathname.slice(1);
        this.setState({
            partner: partner
        })
    }

    componentWillUnmount() {
        this.isCompMounted = false;
    }

    renderView() {
        if (this.state.partner === REWARD) {
            return (
                <RewardPlus
                    {...this.props}
                />
            )
        }
    }

    render() {
        return (
            <main className="mn-main">
                {this.renderView()}
            </main>
        )
    }
}
export default PartnerLanding;