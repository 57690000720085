import React, { useState, useEffect, memo } from "react";
import Loader from "../../../components/Loader/Loader";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";
import getCollections from "../../../services/movies/GetCollections";
import Header from "./components/Header";
import Listing from "./components/Listing";
import { withAuth } from "../../../context/AuthContext";

const AllCollections = memo(({ HostName, ...props }) => {
  const [Data, setData] = useState({});
  const [loading, setLoading] = useState({
    isLoading: true,
    isLoadingMore: false
  });

  useEffect(() => {
    initialize();
    return () => {};
  }, [props.mycollections]);

  const initialize = async () => {
    let params = {
      mycollections: props.mycollections,
      isAuthenticated: props.isAuthenticated,
      HostName: HostName,
      Items: Data.Items ? Data.Items : [],
      Limit: 10,
      LastEvaluatedKey: Data.LastEvaluatedKey ? Data.LastEvaluatedKey : ``
    };
    getCollections((response, err) => {
      if (!err) {
        setData(response);
      }
      setLoading({
        isLoading: false,
        isLoadingMore: false
      });
    }, params);
  };

  const fetchMoreData = () => {
    setLoading({
      ...loading,
      isLoadingMore: true
    });
    initialize();
  };

  return (
    <main className="mn-main">
      <Header />
      {loading.isLoading ? (
        <Loader />
      ) : Data && Data.Items && Data.Items.length > 1 ? (
        <Listing Data={Data} loading={loading} fetchMoreData={fetchMoreData} />
      ) : (
        <DataNotFound text="No Bundles Available" />
      )}
    </main>
  );
});

export default withAuth(AllCollections);
