import React, { Component } from "react";
import PropTypes from "prop-types"
import RenderScreen from "../../../components/RenderScreen/RenderScreen";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";
import { DeviceItem } from "./DeviceItem";
import ProceedPlayer from "./ProceedPlayer";
import ProceedPlayerMessage from "./ProceedPlayerMessage";
import {getPlayerSessionsLimit} from "../helpers";
import { withAppConfig } from "../../../context/ConfigContext";

class DeviceManager extends Component {

    renderDevicesMessage() {
        const { appTranslations :tr } = this.props;

        const { devicesList, deviceLimit, redirectString } = this.props;
        if (redirectString !== null && devicesList.length > deviceLimit) {
            return (
                <div className="alert alert-dark">
                    <div ><i className="la la-exclamation-triangle font-18 mr-3"></i>{tr.you_have_exceeded_your_device_limit_plea} {tr.maximum_devices_allowed} : {deviceLimit}</div>
                </div>
            )
        }
        return null;
    }

    renderProceedButton = () => {
        const { appTranslations :tr } = this.props;
        return (
            this.props.redirectString !== null &&
            <div className="my-4 text-center">
                {!this.props.isProceeding ?
                    <a
                        onClick={this.props.handleProceedClick}
                        href="javascript:void(0)"
                        className={(this.props.devicesList.length >
                            this.props.deviceLimit) ? "btn btn-primary btn-large disabled" : "btn btn-primary btn-large"}
                    >
                        {tr.proceed}
                    </a>
                    :
                    <button
                        type="button"
                        className={(this.props.devicesList.length > this.props.deviceLimit) ? "btn btn-primary btn-large disabled" : "btn btn-primary btn-large"}>
                        {tr.proceeding || "Proceeding..."}  <i className="la la-spin fa-spinner"></i>
                    </button>
                }
            </div>
        )
    }

    renderDeviceManagerView() {
        const { appTranslations :tr } = this.props;
        let concurrentSessions = getPlayerSessionsLimit(this.props.configurations);
        return (
            <section className="mn-notifications py-4">
                <div className="container">
                    <div className="mn-not-list">
                        {this.renderDevicesMessage()}
                        {<ProceedPlayerMessage
                        concurrentSessions={concurrentSessions}
                        />}
                        <div className="list-group">
                            {
                                this.props.devicesList.map((device, index) => {
                                    return (
                                        <DeviceItem
                                            device={device}
                                            key={index}
                                            deviceId={device.DeviceKey}
                                            currentDeviceKey={this.props.currentDeviceKey}
                                            onConfirmDeviceRemoveClick={this.props.onConfirmDeviceRemoveClick}
                                            handleProceedClick={this.props.handleProceedClick}
                                        />
                                    )
                                })
                            }
                            {this.props.devicesList.length < 1 &&
                                <DataNotFound
                                    text={tr.no_device_found}
                                    height="300px"
                                />
                            }
                        </div>
                        {this.renderProceedButton()}
                        <ProceedPlayer />
                    </div>
                </div>
            </section>
        )
    }

    render() {
        const { appTranslations :tr } = this.props;
        return (
            <main className="mn-main">
                <RenderScreen
                    isLoading={this.props.isLoading}
                    isError={this.props.isError}
                >
                    <section className="mn-page-head py-4">
                        <div className="container">
                            <h3 className="mb-0 text-center">{tr.device_manager || "Device Manager"} </h3>
                        </div>
                    </section>
                    {this.renderDeviceManagerView()}
                </RenderScreen>
            </main>
        );
    }
}
const wrapped = withAppConfig(DeviceManager);
export { wrapped as DeviceManagerView };
DeviceManager.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isError: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.array
    ]).isRequired,
    devicesList: PropTypes.array.isRequired,
    redirectString: PropTypes.string,
    currentDeviceKey: PropTypes.string.isRequired,
    deviceLimit: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    onConfirmDeviceRemoveClick: PropTypes.func.isRequired,
    handleProceedClick: PropTypes.func.isRequired,
}