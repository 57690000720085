import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { convertSecToDuration } from "../../../helpers/GeneralHelpers";
import { EST } from "../../../constants/constants";
import moment from "moment";
import CancelSubscription from "./CancelSubscription";
import { ROOMDETAIL } from "../../../constants/routes";

const Detail = props => {
  return (
    <div className="media-body">
      <Heading {...props} />
      <TotalMovies {...props} />
      {/* <Duration {...props}/>
           {/* <Actors {...props}/>
            <RentalTag {...props}/>*/}
      <RentalDetail {...props} />
      <CancelSubscription {...props} />      
    </div>
  );
};

export default withRouter(Detail);

const Heading = props => {
  const onClick = () => {
    props.history.push(`${ROOMDETAIL}/${props.ScreeningRoomID}`);
  };

  return (
    <Fragment>
      <div className="text-white-50 mb-2">
        {props.appTranslations.order_id || "Order ID"} # {props.ID}
      </div>
      <h5 className="text-white">
        <a onClick={onClick} className="link-white" href="javascript:void(0)">
          {props.ScreeningRoom && props.ScreeningRoom.Title}
        </a>
        <span className="badge px-2 py-1 mx-2 badge-light font-10">
          {props.ManifestType && props.ManifestType.toUpperCase()}
        </span>
      </h5>
    </Fragment>
  );
};

const TotalMovies = props => {
  return (
    <div className="mn-points mb-3">
      {props.ScreeningRoom.MovieCount ? (
        <span>{props.ScreeningRoom.MovieCount} Movies</span>
      ) : null}
    </div>
  );
};

const Duration = props => {
  return (
    <div className="mn-points mb-3">
      {props.MoviePlayDuration ? (
        <span>
          {convertSecToDuration(props.MoviePlayDuration, false, false, true)}
        </span>
      ) : null}
      {props.MovieGenre && (
        <span>{props.MovieGenre ? props.MovieGenre.join(", ") : ""}</span>
      )}
    </div>
  );
};

const Actors = props => {
  return (
    props.Meta &&
    props.Meta.Actors && (
      <div className="mb-4">
        <strong className="font-weight-bold mr-2">
          {props.appTranslations.starring || "Starring"} :{" "}
        </strong>
        {props.Meta.Actors.join(", ")}
      </div>
    )
  );
};

const RentalTag = props => {
  return (
    <div>
      {!props.isExpired_ ? (
        <span className="badge badge-outline-primary px-2 font-12 font-weight-normal">
          {props.PurchaseType === EST
            ? props.appTranslations.owned
            : props.appTranslations.rented}
        </span>
      ) : (
        <span className="badge badge-outline-primary px-2 font-12 font-weight-normal ml-1">
          {props.ValidUntil === "N-A" && !props.isExpired_
            ? ""
            : props.isExpired_
            ? props.appTranslations.expired
            : ""}
        </span>
      )}
      {/*(item.ValidUntil === "N-A") && "New"*/}
    </div>
  );
};

const RentalDetail = props => {
  console.log("expires", props)
  return (
    <div className="d-md-flex justify-content-between">
      <div className="text-success">
        {" "}
        {/* mt-3*/}
        {moment &&
          props.CreatedAt &&
          `${props.appTranslations.purchased_on || "Purchased On"} : 
                ${moment(props.CreatedAt).format("YYYY MMMM Do, h:mm:ss a")}`}
      </div>
      <div className="text-danger">
        {" "}
        {/* mt-3*/}
        {props.AutoRenew &&
          moment &&
          props.ExpiresAt &&
          `${props.appTranslations.next_renewal || "Next Renewal"} :
                         ${moment(props.ExpiresAt).format(
                           "YYYY MMMM Do, h:mm:ss a"
                         )}`}
      </div>
    </div>
  );
};
