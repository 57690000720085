import React, { PureComponent } from "react";
import { withAppConfig } from "../../../context/ConfigContext";
import { withAuth } from "../../../context/AuthContext";
import { withFirebase } from "../../../context/FirebaseContext";
import { withRouter } from "react-router-dom";
import { getPlayerSessionsLimit } from "../helpers";

class ProceedPlayer extends PureComponent {
  state = {
    enableButton: false
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.limitexceeded) {
      let concurrentDevices = getPlayerSessionsLimit(this.props.configurations);
      let key = `userPlayerSessions/${this.props.UserID}/concurrentCount/`;
      if (this.props.firebase.db) {
        this.props.firebase.db.ref(key).on("value", snapshot => {
          let snapshotval = snapshot.val();
          if (snapshotval < concurrentDevices) {
            this.setState({
              enableButton: true
            });
          }
        });
      }
    }
  }

  onProceedClick = () => {
    this.props.history.goBack();
  };

  render() {
    const { appTranslations : tr } = this.props;
    let showButton = this.props.location.state && this.props.location.state.limitexceeded;
    return !showButton ? null : (
      <div className="my-4 text-center">
        <a
          id="proceedplayer"
          onClick={this.onProceedClick}
          href="javascript:void(0)"
          className={
            this.state.enableButton
              ? "btn btn-primary btn-large"
              : "btn btn-primary btn-large disabled"
          }
        >
          {tr.watch_your_movie || "Watch Your Movie"}
        </a>
      </div>
    );
  }
}

const WrapperProceedPlayer = withAppConfig(
  withFirebase(withAuth(withRouter(ProceedPlayer)))
);
export default WrapperProceedPlayer;
