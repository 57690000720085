
import React, { Fragment, Component } from "react";
import PropTypes from 'prop-types';
import BrainTree from "../../components/BrainTree";
import { Collapse } from "reactstrap";
import Adyen from "../../components/Adyen";
import { withAppConfig } from "../../../../context/ConfigContext";
import Stripe from "../../components/Stripe";

class PaymentCardPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isCheckingout !== this.props.isCheckingout) {
            this.setState({
                isSubmitting: this.props.isCheckingout
            })
        }
    }

    onClickPaymentForm = (checkoutType, header) => {
        this.setState({
            isSubmitting: true
        })
        this.props.onClickPaymentForm(checkoutType, header);
    }

    renderTabPanes = () => {
        const { your_payable_amount_is = `Your Payable amount is ` , pay_with_card = `Pay with Card`  } = this.props.appTranslations;
        return (
            <div className="">
                {this.props.paymentGateway && this.props.paymentGateway.map((gateway, index) => {
                    return (
                        <div key={index} className="text-center" style={{ "minHeight": "200px" }}>
                            {(gateway.Type && gateway.Type.toLowerCase() === "adyen") ?
                              //  this.props.clientToken !== "" ?
                                    <div className="bt-block">
                                        {this.state.isSubmitting && <span className="loader-spiral mn-transparent"><span className="spiral-inn"></span></span>}{/*isCheckingout */}
                                        <div className="payment-head">
                                            <div className="mb-3">{your_payable_amount_is}
                                            <span className="text-white font-weight-bold">{this.props.price}</span>
                                            </div>
                                        </div>
                                        <hr/>
                                        <Adyen
                                          {...this.props}
                                         showSaveCardOption={true}
                                         price={this.props.price}
                                         onClickPaymentForm={this.onClickPaymentForm}
                                         transactionSubmitted={this.props.transactionSubmitted}
                                         showFooterText={true}
                                         checkoutParams={this.props.getPaymentParams(this.props.purchaseType)}
                                         buttonText={pay_with_card}
                                          />
                                        { /*<BrainTree
                                            NoToken = {true}
                                            showSaveCardOption={true}
                                            price={this.props.price} //update currency later
                                            // clientToken={this.props.clientToken}
                                            checkoutParams={this.props.getPaymentParams(this.props.purchaseType)}
                                            transactionSubmitted={this.props.transactionSubmitted}
                                            onClickPaymentForm={this.onClickPaymentForm}
                                            showFooterText={true}
                                            buttonText={pay_with_card}
                                            {...this.props}
                                        /> */}
                                    </div>
                                    // :
                                    // <p>Check your Internet Connection</p>
                                :
                                this.renderOtherMethod(gateway,index) //should not come in this condition
                            }
                        </div>
                    )
                })
                }
            </div>
        )
    }

    renderOtherMethod = (gateway,index) => {
        if(gateway.Type.toLowerCase() === "stripe"){
            return <Stripe
                checkoutParams={this.props.getPaymentParams(this.props.purchaseType)}
                onClickPaymentForm={this.props.onClickPaymentForm}
            />
        }
        return null
        return (
            this.props.clientToken !== "" ? //TEMPORARIRLY show Braintree as default method also
                <BrainTree
                    {...this.props}
                    price={this.props.price}
                    clientToken={this.props.clientToken}
                    checkoutParams={this.props.getPaymentParams(this.props.purchaseType)}
                    transactionSubmitted={this.props.transactionSubmitted}
                    onClickPaymentForm={this.props.onClickPaymentForm}
                />
                :
                <Fragment>
                    <p>Login with Method 2 account to make payment</p>
                    <p><button className="btn btn-primary">Login with Method {index}</button></p>
                </Fragment>
        )
    }

    togglePaymentPanel = () => {
        this.props.onPanelToggle("formpanel");
    }

    render() {
        return (
            <div className="">
                <div className="card card-dark mb-3" >
                    <div onClick={this.togglePaymentPanel} className={`card-header pointer text-white ${this.props.isPanelSelected ? `` : `collapsed`} `} >
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <span><i className="la font-18 la-circle-o pay-select mr-3"></i></span>
                                <span>{}</span>
                            </div>
                            <div className="">
                                <i className="las la-angle-down"></i>
                            </div>
                        </div>
                    </div>
                    <Collapse isOpen={this.props.isPanelSelected}>
                        <div className="card-body position-relative">
                        {this.renderTabPanes()}
                        </div>
                    </Collapse>
                </div>
            </div>
        )
    }
}

PaymentCardPanel.propTypes = {
    isPanelSelected: PropTypes.bool.isRequired,
    onPanelToggle: PropTypes.func.isRequired,
    price: PropTypes.string.isRequired // --> price and currency
};
export default withAppConfig(PaymentCardPanel);
