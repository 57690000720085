import React from "react";

const Quality = ({ isSubscribed_, isCPurchased_, SubscribedManifestType,qualityArray, CPurchasedManifestType, Manifests }) => {
  return (
    <div className="mb-3">
      <div className="mb-3"><span className="align-middle mr-1 font-weight-bold">
        Video Quality :
      </span></div>
      {isSubscribed_ ?
        <div className="mb-3">
        <span className="badge px-2 py-1 mx-1 badge-light">
          {SubscribedManifestType.toUpperCase()}
        </span>
        </div>
        :
        !isSubscribed_ ?
          <div>
            {qualityArray.map((quality, index) => (<span className="badge px-2 py-1 mx-1 badge-light">
              {quality.toUpperCase()}  </span>))}
          </div>
          :
          isCPurchased_ ?
          <div className="mb-3">
            <span className="badge px-2 py-1 mx-1 badge-light">
              {CPurchasedManifestType.toUpperCase()}
            </span>
            </div>
            :
            <List Manifests={Manifests} />
      }
    </div>
  );
};

export default Quality;

const List = ({ Manifests }) => {
  if (!Manifests) {
    return null;
  }
  return (
    Manifests &&
    Manifests.length > 0 &&
    Manifests.map((item, index) => {
      return (
        <div className="mb-3"><span key={index} className="badge px-2 py-1 mx-1 badge-light">
          {item.toUpperCase()}
        </span></div>
      );
    })
  );
};
