import React, { Component, createRef } from "react";
import PropTypes from 'prop-types';
import { setDocumentTitle } from "../../helpers/GeneralHelpers";
import { CONTACT_US_PAGE } from "../../constants/constants";
import { handleChange, enableButton, disableButton } from "../../helpers/StateHelper";
import { Contact as ContactView } from "./components/Contact";
import { withAppConfig } from "../../context/ConfigContext";
import { PostAPI } from "../../helpers/APIHelpers/APIHelpers";

class Contact extends Component {
    isCompMounted = true;
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            data: [],
            isError: "",
            userName: "",
            userEmail: "",
            userSubject: "",
            userComment: "",
            canSubmit: false,
            commentErrorMessage: ""
        }
        this.handleChange = handleChange.bind(this);
        this.enableButton = enableButton.bind(this);
        this.disableButton = disableButton.bind(this);
        this.ContactViewRef = createRef();
    }

    componentDidMount() {
        setDocumentTitle(CONTACT_US_PAGE);
    }

    componentWillUnmount() {
        this.isCompMounted = false;
    }

    handleFormSubmit = () => {
        const { appTranslations : tr } = this.props;
        if (this.state.userComment !== "" && this.state.userName !== "" && this.state.userEmail !== "") {
            this.setState({
                isLoading: true,
            });
            let headerParams = { "Comment": this.state.userComment, "Email": this.state.userEmail, "Name": this.state.userName };
            if (this.state.userSubject) {
                headerParams["Subject"] = this.state.userSubject
            } else {
                headerParams["Subject"] = "Help!";
            }
            let apiParams = {
                apiname: "TicketsAPI",
                apipath: "/createZendeskTicket",
                postParams: headerParams
            }
            let otherParams = {
                SuccessMessage: tr.ticket_created_successfully || "Ticket Created Successfully!",
                FailedMessage: tr.couldnot_create_ticket || "Couldn't Create Ticket! Try again"
            }
            PostAPI(apiParams, "ContactUs", otherParams, this.handleFetchResponse, this.props.showAlert);
        } else {
            this.setState({
                commentErrorMessage: tr.please_enter_required_fields || "Please Enter All Required Fields"
            })
        }
    }

    handleFetchResponse = (err, response) => {
        const { appTranslations : tr } = this.props;

        this.setState({
            isLoading: false
        });
        if (err) {
            this.setState({
                isLoading: false,
                isError: tr.data_not_found || "No Data Found"
            })
        } else {
            this.setInitialState();
        }
    }

    setInitialState = () => {
        this.setState({
            userComment: "",
            userEmail: "",
            userName: "",
            userSubject: "",
            commentErrorMessage: ""
        });
        Object.keys(this.ContactViewRef.current.inputs).forEach((key) => {
            if (key !== "userComment") {
                this.ContactViewRef.current.inputs[key].clearInput()
            }
        });
        this.ContactViewRef.current.inputs["userComment"].value = ""; //MyInput not used!!
    }

    changeValue = (event) => {
        if (this.state.commentErrorMessage !== "") {
            this.setState({
                commentErrorMessage: ""
            })
        }
        this.handleChange(event.currentTarget.name, event.currentTarget.value)
    }

    render() {
        return (
            <ContactView
                ref={this.ContactViewRef}
                canSubmit={this.state.canSubmit}
                commentErrorMessage={this.state.commentErrorMessage}
                userComment={this.state.userComment}
                isLoading={this.state.isLoading}
                handleSubmit={this.handleSubmit}
                enableButton={this.enableButton}
                disableButton={this.disableButton}
                handleFormSubmit={this.handleFormSubmit}
                handleChange={this.handleChange}
                changeValue={this.changeValue}
                appTranslations = {this.props.appTranslations}
                appSettings={this.props.appSettings}
            />
        )
    }
}
Contact.defaultProps = {
    appSettings: {},
}

Contact.propTypes = {
    appSettings: PropTypes.object,
    showAlert: PropTypes.func
};
export { Contact };

export default withAppConfig(Contact);