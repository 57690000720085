import React, {Component} from "react";
import CardsList from "../../components/CardsList/CardList";
import _map from "lodash/map";
import {disableAlert} from "../../helpers/StateHelper";
import {FETCH_SEARCH_DATA, SEARCH_PAGE} from "../../constants/constants";
import {querystring, setDocumentTitle} from "../../helpers/GeneralHelpers";
import {getCompressedCdnImageUrl} from "../../helpers/CDNhelpers";
import {HOME} from "../../constants/routes";
import {fetchMoviesData} from "../../helpers/APIHelpers/MoviesAPI";
import {getactiveCDNfromConfig} from "../../helpers/ConfigHelpers";
import {withAppConfig} from "../../context/ConfigContext";

const SearchLimit = 40;

class Search extends Component {
    isCompMounted = true;
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            moviesList: [],
            isError: "",
            lastEvaluatedKey: 0,
            isLoadingMore: false,
            searchString: "",
            HostName: null
        }
        this.fetchMovieData = fetchMoviesData.bind(this);
        this.disableAlert = disableAlert.bind(this);
    }

    componentDidMount() {
        this.initialize();
        setDocumentTitle(SEARCH_PAGE);
        this.props.clearSearchValue(true);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.searchTerm !== this.props.searchTerm) {
            this.setState({
                isLoading: true
            },
                () => this.initialize()
            )
        }
    }

    componentWillUnmount() {
        this.props.clearSearchValue();
        this.isCompMounted = false;
    }

    initialize = async () => {
        let searchString = querystring("q");
        if (searchString === "" || searchString === null) {
            this.props.history.push(HOME);
        } else {
            let HostName = await getactiveCDNfromConfig();
            this.setState({
                HostName: HostName
            })
            this.fetchData(searchString, SearchLimit, 0, false, HostName);
        }
    }

    fetchData = (searchString, size = SearchLimit, lastFetchedItemsIndex = 0, fetchMore = false, HostName) => { //`/_search?size=${size}&from=${lastFetchedItemsIndex}`
        let previousList = { ...this.state.moviesList };
        let requestBody ={
            "from":lastFetchedItemsIndex,
            "size":size,
            "query": {
                "query_string": {
                    "query":"*"+searchString+"*",
                    "fields": ["Title^4", "Genre^2","Meta.*"]
                }
            }
        };
        this.fetchMovieData("SearchAPI", `/movies/_search`, this.state.moviesList, "isLoading", "isError", "moviesList", "", "_id", "lastEvaluatedKey", requestBody,fetchMore,FETCH_SEARCH_DATA).then((res) => {//unique by _id
            if (res && res.hits) {
                if (res.hits.hits) {
                    let searchMovies = res.hits.hits;
                    searchMovies = _map(searchMovies, (item) => {
                        let isPosterURL = item._source && item._source.PosterURL;
                        if (isPosterURL) {
                            item._source["PosterURL"] = getCompressedCdnImageUrl(isPosterURL, FETCH_SEARCH_DATA, HostName);
                        }
                        return item
                    });
                    res.hits.hits = searchMovies;
                }
                if (fetchMore) {
                    if (res.hits.hits) {
                        let newHits = res.hits.hits;
                        let previousHits = previousList.hits.hits;
                        res.hits["hits"] = previousHits.concat(newHits);
                    }
                }
                let totalFetchedResults = this.state.lastEvaluatedKey + SearchLimit;
                if (res.hits.total > totalFetchedResults) {
                    this.setState((prevState => {
                        return {
                            lastEvaluatedKey: prevState.lastEvaluatedKey + SearchLimit
                        }
                    }
                    ));
                } else {
                    this.setState({
                        lastEvaluatedKey: ""
                    });
                }
            }
        })
    }

    handleMovieClick = () => {// this.props.history.push(`/ movie / ${ movieID }`);
    }

    handleFetchMoreClick = async () => {
        this.setState({
            isLoadingMore: true
        });
        let searchString =decodeURIComponent( querystring("q"));//this.props.searchString;
        let HostName = this.state.HostName;
        if (!HostName) {
            HostName = await getactiveCDNfromConfig();
            this.setState({
                HostName: HostName
            })
        }
        this.fetchData(searchString, SearchLimit, this.state.lastEvaluatedKey, true, HostName);
    }

    renderSearchResultsBar = () => {
        const { appTranslations : tr } = this.props;
        let searchString = decodeURIComponent(querystring("q"));
        return (

            <section className="">
                <div>
                    <div className="collapse fade" id="search-dropdown" data-toggle="collapse" data-target="#search-dropdown"></div>
                </div>
                <div className="mn-page-head mn-search-head py-4 bg-black">
                    <div className="container-fluid">
                        <div className="d-md-flex">
                            <div className="text-white-50 font-weight-bold mr-3 mb-2 mb-md-0">
                                {tr.explore_related_to} :
                            </div>
                            <div className="mn-points">
                                <span><a href="javascript:void(0)" className="link-white">{searchString}</a></span>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        )
    }

    render() {
        return (
            <main className="mn-main">
                {this.renderSearchResultsBar()}
                <CardsList
                    {...this.props}
                    isLoading={this.state.isLoading}
                    isError={this.state.isError}
                    data={this.state.moviesList && this.state.moviesList.hits && this.state.moviesList.hits.hits ? this.state.moviesList.hits.hits : []}
                    isLoadingMore={this.state.isLoadingMore}
                    lastEvaluatedKey={this.state.lastEvaluatedKey}
                    fetchMoreData={this.handleFetchMoreClick}
                    handleMovieClick={this.handleMovieClick}
                    screen={"SEARCH"}
                    sectionClassName="mn-movies pt-5"
                />
            </main>
        )
    }
}

export default withAppConfig(Search);