import React, { Component } from "react";
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import _find from "lodash/find";
import RenderScreen from "../../../../components/RenderScreen/RenderScreen";
import { getReusableVouchers, convertSecToDuration, isEmpty } from "../../../../helpers/GeneralHelpers";
import { showPartnerCheckoutVoucherMessage } from "../../../../helpers/ViewHelpers";
import { EST } from "../../../../constants/constants";
import MySelect from "../../../../components/MySelect";
import VoucherInput from "./VoucherInput";
import _round from "lodash/round";
import { withAppConfig } from "../../../../context/ConfigContext";

class PurchaseDescription extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputFocus: false
        }
    }
    getVideoQualityOptions = (data) => {
        const videoQulaity = [];
        if (data.length > 0) {
            data.forEach((obj) => {
                let title = `${obj.DisplayName ? obj.DisplayName : ""} - ${obj.CurrencySymbol ? obj.CurrencySymbol : ""}${(obj.Price || obj.Price === 0) ? obj.Price : ""}`;
                let newobj = { value: obj.ID, title: title }
                videoQulaity.push(newobj);
            });
        }
        return videoQulaity;
    };


    renderPartnerCheckoutStrip() {
        // if (this.props.showPromotionDiscountMessage) {
        //     return showPartnerCheckoutVoucherMessage()
        // }
        return null
    }

    handlePreviousVoucherSelection = (voucherCode) => {
        this.props.autoVoucherRedemption(voucherCode);
    };

    handleVoucherDropDown = () => {
        const { appTranslations: tr } = this.props;
        if (!isEmpty(this.props.reusableVouchers)) {
            let voucherList = this.props.reusableVouchers.map((voucher, index) => {
                return (
                    voucher.BalanceAmount > 0 &&
                    <a key={index} className="dropdown-item" onClick={() => this.handlePreviousVoucherSelection(voucher.Voucher)}>
                        <div className="d-flex justify-content-between">
                            <div>
                                {voucher.Voucher}
                            </div>
                            <div>
                                ${_round(voucher.BalanceAmount, 2)}
                            </div>
                        </div>
                    </a>
                )
            });

            return (
                voucherList.length > 0 &&
                <div className="dropdown-menu dropdown-menu-dark reward-dropdown" style={{ display: "block" }}>
                    <div className="px-3 py-2" href="#">
                        <small>{tr.use_your_saved_reward_codes || "Use your Saved Reward Codes"}</small>
                    </div>
                    {voucherList}
                </div>
            )
        }
    };

    handleVoucherDropDownClick = () => {
        this.setState({
            inputFocus: !this.state.inputFocus
        });
    };

    renderMovieDetails() { //calulateTotalPrice = > change it later
        const { appTranslations: tr } = this.props;
        const { movieData, purchaseType, isVoucherAutoRedempted, appliedVoucherCode, showVoucher, selectedQuality } = this.props;
        let payment = this.props.calulateTotalPrice(movieData); //movieData is being updated in getCheckoutMovieData as per requirement
        let videoQualityOptions = !isEmpty(movieData) && this.getVideoQualityOptions(movieData.Params);
        let redemptedValue = "";
        if (isVoucherAutoRedempted) {
            let tier = _find(videoQualityOptions, ["value", selectedQuality]);
            if (tier) {
                redemptedValue = tier.title;
            }
        }
        return (
            !isEmpty(movieData) ?

                <section className="mn-checkout">
                    <div className="container">
                        <section className="mn-order pb-4">
                            {/* {this.renderPartnerCheckoutStrip()} */}

                            <div className="mn-cart-table px-3">
                                <div className="mn-cart-body text-white-50">
                                    <div className="mn-cart-row row align-items-center item-added-row">
                                        <div className="mn-cart-cell col-12 col-md-5">
                                            <a href="javascript:void(0)" className="link-white" onClick={this.props.handleTitleClick}>
                                                <div className="media">
                                                    <div className="checkout-thumb">
                                                        <div className="mn-figure">
                                                            {movieData.PosterURL &&
                                                                <div className="mn-thumb img-bg img-1x15 img-fluid" style={{ backgroundImage: `url(${movieData.PosterURL})` }}></div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="media-body pl-4 align-self-center">
                                                        <div className="mb-3">
                                                            {movieData.Title ? movieData.Title : ""}
                                                        </div>
                                                        <div className="text-white-50 font-12">
                                                            {movieData.ReleaseYear} -
                                                            {movieData.Genre.map((gen, index) => {
                                                                
                                                                if (gen && index < 2) {
                                                                    return gen.charAt(0).toUpperCase() + gen.slice(1) + `${(index === 0 && movieData.Genre.length > 1) ? ", " : ``}`;
                                                                }
                                                            })}
                                                           -  {convertSecToDuration(movieData.PlayDuration)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="mn-cart-cell col-12 col-md-2">
                                            <div className="mb-3 text-white">
                                                {purchaseType === EST ? tr.buy_duration || "Buy Duration" : tr.rent_duration || "Rent Duration"}
                                            </div>
                                            <div>
                                                {purchaseType === EST ? tr.available_life_time || "Available life time" : movieData.RentTvodValidity}
                                            </div>
                                        </div>
                                        <div className="mn-cart-cell col-12 col-md-3">
                                            <div className="mb-1 text-white">
                                                {tr.video_quality || "Video Quality"}
                                            </div>
                                            <div>
                                                {isVoucherAutoRedempted ?
                                                    redemptedValue
                                                    :
                                                    videoQualityOptions.length > 1 ?
                                                        <Formsy >
                                                            <MySelect
                                                                id="selectedQuality"
                                                                name="selectedQuality"
                                                                outerDiv={false}
                                                                disabled={this.props.disableVoucherChange}
                                                                innerClassName="form-control-dark form-control-lg"
                                                                value={selectedQuality}
                                                                handleChange={this.props.handleQualityChange}
                                                                options={videoQualityOptions}
                                                            />
                                                        </Formsy>
                                                        :
                                                        (videoQualityOptions[0]) && (videoQualityOptions[0]).title
                                                }
                                            </div>
                                        </div>
                                        <div className="mn-cart-cell col-12 col-md-2">
                                            <div className="mb-3 text-white">
                                                {tr.price || "Price"}
                                            </div>
                                            <div className="">
                                                {movieData.CurrencySymbol}{movieData.Price}
                                            </div>
                                        </div>
                                    </div>

                                    {showVoucher &&
                                        <div className="mn-cart-row row voucher-code-row">
                                            <div className="mn-cart-cell col-12 col-md-6">
                                                {tr.reward_code_used || "Reward Code Used"}
                                            </div>
                                            <div className="mn-cart-cell col-12 col-md-4">
                                                {appliedVoucherCode}{/*appliedVoucherCode*/}
                                            </div>
                                            <div className="mn-cart-cell col-12 col-md-2">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <span> {this.props.appSettings.CurrencySymbol}{payment.discount}</span>
                                                    {(isVoucherAutoRedempted && !this.props.storedVoucherFlag) ?
                                                        null
                                                        :
                                                        <span className="ml-3"><button title={tr.remove_your_code || "Remove Your Code"} onClick={this.props.handleRemoveVoucherClick} className="btn btn-sm btn-circle btn-secondary"><i className="la la-times"></i></button></span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {!this.props.disableVoucherChange &&
                                        <div className="mn-cart-row row align-items-center voucher-input-row">
                                            <div className="mn-cart-cell col-12 col-md-6 text-left text-md-right">
                                                Using a {this.props.ApplicationName} Reward code ?
                                        </div>
                                            <div className="mn-cart-cell col-12 col-md-4">
                                                <div className="dropdown">
                                                    <VoucherInput
                                                        forwardedRef={this.props.forwardedRef}
                                                        isLoadingVoucher={this.props.isLoadingVoucher}
                                                        handleChange={this.props.handleChange}
                                                        handleVoucherClick={this.props.handleVoucherClick}
                                                        autoVoucherRedemption={this.props.autoVoucherRedemption}
                                                        isAuthenticated={this.props.isAuthenticated}
                                                        onVoucherInputClick={this.handleVoucherDropDownClick.bind(this)}
                                                        movieID={this.props.movieID}
                                                        appliedVoucherCode={this.props.appliedVoucherCode}
                                                        autoRedemptionUnSuccessful={this.props.autoRedemptionUnSuccessful}
                                                        location={this.props.location}
                                                        appTranslations={this.props.appTranslations}
                                                    />
                                                    {this.state.inputFocus && !isEmpty(this.props.reusableVouchers) &&
                                                        this.handleVoucherDropDown()}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {this.props.storedVoucherFlag && this.props.reusableVoucherBalance > 0 &&
                                        <div className="mn-cart-row row">
                                            <div className="mn-cart-cell col-10 col-md-7 offset-md-3">
                                                <div className="font-weight-bold">{tr.balance_of_reward_code || "Balance of Reward Code"}</div>
                                                <div>
                                                    <div>The amount will be added to your <span className="text-white-70">{tr.saved_reward_codes || "Saved Reward Codes"}</span></div>
                                                    <div>as soon as you continue.</div>
                                                </div>
                                            </div>
                                            <div className="mn-cart-cell col-2 col-md-2">
                                                {this.props.appSettings.CurrencySymbol}{this.props.reusableVoucherBalance}
                                            </div>
                                        </div>
                                    }
                                    <div className="mn-cart-row row text-white font-weight-bold">
                                        <div className="mn-cart-cell col-6 col-md-4 offset-md-6">
                                            {tr.payable_amount || "Payable Amount"}
                                        </div>
                                        <div className="mn-cart-cell col-6 col-md-2 text-right text-md-left">
                                        {this.props.appSettings.CurrencySymbol}{payment.price}
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                :
                null
        )
    }

    render() {
        return (
            <RenderScreen
                isLoading={this.props.isLoadingMovieData}
                isError={this.props.dataErrorMovie}>
                {this.renderMovieDetails()}
            </RenderScreen>
        );
    }
}

PurchaseDescription.propTypes = {
    disableVoucherChange: PropTypes.bool.isRequired,
    isLoadingMovieData: PropTypes.bool.isRequired,
    dataErrorMovie: PropTypes.string,
    movieID: PropTypes.string.isRequired,
    movieData: PropTypes.object.isRequired,
    calulateTotalPrice: PropTypes.func.isRequired,
    handleTitleClick: PropTypes.func.isRequired,
    handleQualityChange: PropTypes.func.isRequired,
    handleRemoveVoucherClick: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    autoVoucherRedemption: PropTypes.func.isRequired,
    handleVoucherClick: PropTypes.func.isRequired,
    reusableVouchers: PropTypes.array.isRequired,
    reusableVoucherBalance: PropTypes.number.isRequired,
    storedVoucherFlag: PropTypes.bool.isRequired
};
export default withAppConfig(PurchaseDescription);
