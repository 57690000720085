import React from "react";

const Wrapper = ({ PreviewImage, PosterUrl, children }) => {
  return (
    <section
      className="mn-room-detail img-bg"
      style={{ backgroundImage: `url(${PreviewImage})` }}
    >
      <div className="mn-mDetail-caption py-5">
        <div className="container-fluid">
          <div className="row align-items-center">
            {children}
            <div className="col-xl-6 text-right">
              <div className="mn-card-item mn-room">
                <div className="mn-card-item mn-collection-item collection-detail-thumb">
                  <div className="mn-figure">
                    <div className="mn-collection-figure">
                      <div
                        className="mn-thumb img-bg img-1x15"
                        style={{ backgroundImage: `url(${PosterUrl})` }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Wrapper;
