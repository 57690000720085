import React from 'react';
import { withRouter } from "react-router-dom";

class Splash extends React.Component {
    render() {
        return (
            <div className="main-wrap global-wrap splash-wrap">
                <main className="mn-main">
                    <section className="mn-splash py-5">
                        <div className="container">
                            <div className="mn-splash-body">
                                <div className="sp-loader"></div>
                                <div className="sp-logo">
                                    <div className="pix-splash-loader"></div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        )
    }
}

export default withRouter(Splash);