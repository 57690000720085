import React, { Component } from "react";
import SaveCardModal from "./components/SaveCardModal"
import CardListing from "./components/CardListing";
import { CLIENT_TOKEN } from "../../constants/constants";
import { getApiData } from "../../helpers/APIHelpers/MoviesAPI";
import { withPaymentCard } from "../../context/PaymentCardContext";
import { withAppConfig } from "../../context/ConfigContext";
import { getClientToken } from "../../services/checkout/GetBrainTreeToken";

class PaymentSettings extends Component {
    isCompMounted = true;
    constructor(props) {
        super(props);
        this.state = {
            showCardModal: false,
            // isLoadingToken: true,
            clientToken: "",
            errorToken: ""
        };
        // this.getClientToken = getApiData.bind(this);
    }

    componentDidMount() {
        // getClientToken((res,err)=>{
        //     if(!err){
        //         this.setState({
        //             clientToken : res
        //         })
        //     }
        //     this.setState({
        //         isLoadingToken : false
        //     })
        // })
    }

    onAddPaymentClick = (id) => {
        this.setState({
                showCardModal: !this.state.showCardModal
            
        });
    }

    renderLeftView = () => {
        const { add_new_credit_card, payment_options, default_credit_card_option_will_be_used_for_purchases = `` } = this.props.appTranslations;
        return (
            <div className="mb-4">
                {/* <h4 className="font-weight-normal mb-2">{payment_options}</h4> */}
                {/* <div className="mb-4 text-white-50 font-12">{default_credit_card_option_will_be_used_for_purchases}</div> */}
                <div className="mb-4">
                    <a 
                        className="btn btn-primary btn-lg"
                        onClick={this.onAddPaymentClick} >
                        <i className="la la-plus mr-2"></i><b>{add_new_credit_card}</b>
                    </a>
                    {/* {this.state.isLoadingToken ?
                        <a
                            className="btn btn-sm btn-primary"
                        >
                            <i className="la la-spin fa-spinner mr-2"></i> {add_new_credit_card}
                        </a>
                        :
                       
                    
                    } */}
                </div>
            </div>
        )
    }

    render() {
        return (
            <main className="mn-main">
            <section className="mn-page-head py-4">
                <div className="container">
                    <h3 className="mb-0 text-center text-uppercase">Payment Options</h3>
                </div>
            </section>
                <section className="mn-ac-settings py-5">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                {this.renderLeftView()}
                                <CardListing />
                            </div>
                        </div>
                    </div>
                </section>
                {this.state.showCardModal &&
                    <SaveCardModal
                        clientToken={true}
                    />
                }
            </main>
        )
    }
}

export default withPaymentCard(withAppConfig(PaymentSettings));