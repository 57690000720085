import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { isValueFoundInData } from "../../../helpers/GeneralHelpers";
import { HOSTED_FIELDS_INVALID, HOSTED_FIELD_EMPTY } from "../../../constants/constants";
import { TERMS_CONDITIONS } from "../../../constants/routes";
import { withAppConfig } from "../../../context/ConfigContext";
import { getClientToken } from "../../../services/checkout/GetBrainTreeToken";

class BrainTree extends React.Component {
    isCompMounted = true;
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false,
            isLoadingHostedFields: true,
            showSuccessAlert: false,
            showErrorAlert: false,
            alertMessage: "",
            transaction: {},
            cardholderName: "",
            errorCardNumber: false,
            errorMonth: false,
            errorYear: false,
            errorSecurityCode: false,
            errorZipCode: false,
            client: null,
            hostedFields: null,
            saveCard: true,
            makeDefault: false,
            purchaseHistoryLastEvaluatedKey: ""
        }
        this.cardholderName = "";
    }

    componentDidMount() {
        if (this.props.NoToken) { //this condition is written to fetch token here instead of expecting from parent
            getClientToken((res, err) => {
                if (!err) {
                    if (this.isCompMounted) {
                        this.initClient(res);
                    }
                }
            });
        } else { //remove it later and update it with above!!
            this.initClient(this.props.clientToken);
        }
    }

    componentWillUnmount() {
        this.isCompMounted = false;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.transactionSubmitted !== this.props.transactionSubmitted) {
            this.onTransactionDone();
        }
    }

    initClient = (clientToken) => {
        const { appTranslations: tr } = this.props;
        let button = document.querySelector('#submit');
        let cardNumber = document.getElementById('card-number');
        let expirationMonth = document.getElementById('expiration-month');
        let expirationYear = document.getElementById('expiration-year');
        let cvv = document.getElementById('cvv');
        import("braintree-web/client").then((client) => {
            return client.create({
                authorization: clientToken
            }).then((clientInstance) => {
                let options = {
                    client: clientInstance,
                    styles: {
                        'input': {
                            'color': '#cecece'
                        },
                        ':focus': {
                            '-webkit-box-shadow': '0 0 0 0.2rem rgba(234, 6, 9, .25)',
                            'box-shadow': '0 0 0 0.2rem rgba(234, 6, 9, .25)'
                        },
                    },
                    ':focus': {
                        'color': 'black'
                    },
                    fields: {
                        number: {
                            selector: '#card-number',
                            placeholder: '4111 1111 1111 1111'
                        },
                        cvv: {
                            selector: '#cvv',
                            placeholder: '123'
                        },
                        expirationMonth: {
                            selector: '#expiration-month',
                            placeholder: 'MM'
                        },
                        expirationYear: {
                            selector: '#expiration-year',
                            placeholder: 'YY'
                        }
                    }
                };
                if (this.isCompMounted) {
                    return import("braintree-web/hosted-fields").then((hostedFields) => {
                        return hostedFields.create(options);
                    });
                }
            }).then((hostedFieldsInstance) => {
                this.setState({
                    isLoadingHostedFields: false
                })
                button.removeAttribute('disabled');
                button.addEventListener('click', (e) => {
                    e.preventDefault();
                    if (cardNumber.value !== "" && expirationMonth.value !== "" && expirationYear.value !== "" && cvv.value !== "" && this.state.cardholderName !== "") {
                        button.setAttribute('disabled', 'disabled');
                        hostedFieldsInstance.tokenize({
                            cardholderName: this.state.cardholderName
                        }).then((payload) => {
                            this.onformClickedSuccessfully(payload);
                        }).catch((err) => {
                            let code = err.code ? err.code : "";
                            let message = err.message ? err.message : code === "" ? "Some Error Occurred, Try Again!" : "";
                            if (code === HOSTED_FIELDS_INVALID) {
                                let invalidFields = err.details.invalidFieldKeys;
                                if (invalidFields.length > 0) {
                                    this.setState({
                                        errorCardName: isValueFoundInData(invalidFields, "Name"),
                                        errorCardNumber: isValueFoundInData(invalidFields, "number"),
                                        errorMonth: isValueFoundInData(invalidFields, "expirationMonth"),
                                        errorYear: isValueFoundInData(invalidFields, "expirationYear"),
                                        errorSecurityCode: isValueFoundInData(invalidFields, "cvv")
                                    })
                                }
                            } else if (code === HOSTED_FIELD_EMPTY) {
                                this.setState({
                                    errorCardName: true,
                                    errorCardNumber: true,
                                    errorMonth: true,
                                    errorYear: true,
                                    errorSecurityCode: true,
                                    errorZipCode: true,
                                })
                            }
                            this.props.showAlert("showErrorAlert", message);
                            button.removeAttribute('disabled');
                        })
                    } else {
                        this.props.showAlert("showErrorAlert", tr.please_fill_all_the_required_fields || "Please fill all the required Fields!");
                    }
                })
            }).catch((err) => {
                let code = err.code ? err.code : "";
                let message = err.message ? err.message : code === "" ? "Some Error Occurred, Try Again!" : "";
                this.props.showAlert("showErrorAlert", message);
                button.removeAttribute('disabled');
            });
        })
            .catch((err) => {
                this.props.showAlert("showErrorAlert", "couldn't load braitree client!");
            })
    }

    onformClickedSuccessfully = (payload) => {
        if (this.props.saveCard) {
            this.savePaymentClicked(payload);
        } else {
            this.submitPayment(payload)
        }
    }

    submitPayment = (payload) => {
        let header = this.props.checkoutParams;
        header["PaymentNonce"] = payload.nonce;
        if (this.state.saveCard) {
            header["SaveCard"] = true;
        }
        this.setState({
            isSubmitting: true
        });
        this.props.str("formPayment", header);
    }

    savePaymentClicked = (payload) => {
        let params = {};
        params["PaymentNonce"] = payload.nonce;
        if (this.state.makeDefault) {
            params["MakeDefault"] = this.state.makeDefault
        }
        this.props.savePaymentClicked(params);
    }

    onTransactionDone = (isError) => {
        this.setState({
            isSubmitting: false
        });
        //if (isError) {
        let button = document.querySelector('#submit');
        button.removeAttribute('disabled');
        //}
    }

    handleChange = (event) => {
        this.setState({
            cardholderName: event.currentTarget.value
        })
    }

    showMessageAlert = (error, message) => {
        this.setState({
            showErrorAlert: true,
            alertMessage: message,
        });
        this.disableAlert("showErrorAlert");
    }

    onToggleSaveCard = () => {
        this.setState((prevState) => {
            return {
                saveCard: !prevState.saveCard
            }
        })
    }

    onToggleDefaultCard = () => {
        this.setState((prevState) => {
            return {
                makeDefault: !prevState.makeDefault
            }
        })
    }

    renderCheckbox = () => {
        const { appTranslations: tr } = this.props;
        return (
            <div className="form-group">
                <div className="row gutter-7">
                    {this.props.showSaveCardOption &&
                        <div className="col-12">
                            <label className="mn-switch">
                                <input type="checkbox" checked={this.state.saveCard} onChange={this.onToggleSaveCard} />
                                <span className="mn-switch-slider round"></span>
                            </label>
                            <span className="ml-2 font-12">{tr.save_my_card_for_next_payment || "Save my card for next payment"}.</span>
                        </div>
                    }
                    {this.props.defaultCardOption &&
                        <div className="col-12">
                            <label className="mn-switch">
                                <input type="checkbox" checked={this.state.makeDefault} onChange={this.onToggleDefaultCard} />
                                <span className="mn-switch-slider round"></span>
                            </label>
                            <span className="ml-2">{tr.make_default_payment_options || "Make default payment options"}.</span>
                        </div>
                    }
                </div>
            </div>
        )
    }

    renderOptionalText = () => {
        const { appTranslations: tr } = this.props;
        let text = tr.by_placing_your_order_you_agree_to_our_t || "By placing your order, you agree to our $ terms of use.";
        text = text ? text.split('$') : [];

        if (this.props.showFooterText && text && text.length > 0)
            return (
                <div className="mb-3 font-12">
                    {text[0]}<Link to={TERMS_CONDITIONS}> {text[1] ? text[1] : ''}</Link>
                </div>
            )
        return null
    }

    renderSubmitButton = () => {
        const { appTranslations: tr } = this.props;
        let text = this.props.buttonText

        if (!text) {
            text = tr.submit || "Submit"
        }
        if (!this.props.saveCard) {
            return (
                <div className="text-center mb-2">
                    <button
                        value="submit"
                        disabled
                        id="submit"
                        className="btn btn-primary btn-xlarge">
                        {text}
                    </button>
                </div>
            )
        }
        return (
            <button
                value="submit"
                disabled
                id="submit"
                className="btn btn-block btn-primary">
                {text}
            </button>
        )
    }

    render() {
        const { appTranslations: tr } = this.props;
        return (
            <form className="panel-body text-white-50 text-left">
                <div className="row justify-content-center">
                    <div className={this.props.customContainerClass}>
                        {this.state.showErrorAlert &&
                            <div className="alert alert-dark "> {/* alert-dismissible" */}
                                {/* <button type="button" className="close" data-dismiss="alert">&times;</button> */}
                                <strong>{tr.error || "Error"}!</strong> {this.state.alertMessage}
                            </div>
                        }
                        <div className="form-group">
                            <label className="control-label">{tr.name_on_card || "Card Holder Name"}</label>
                            <input
                                id="cardholder-name"
                                name="cardholderName"
                                placeholder={tr.name_on_card || "Card Holder Name"}
                                type="text"
                                className="form-control form-control-dark bt-form-control"
                                required
                                value={this.state.cardholderName}
                                ref={input => { this.cardholderName = input; }}
                                onChange={this.handleChange}
                            />
                            {this.state.errorCardName && <div className="text-danger">{tr.invalid_field || "Invalid Field"}</div>}
                        </div>
                        <div className="form-group">
                            <label className="control-label">{tr.card_number || "Card Number"}</label>
                            <div className="form-control form-control-dark bt-form-control" id="card-number"></div>
                            {this.state.errorCardNumber && <div className="text-danger">{tr.invalid_field || "Invalid Field"}</div>}
                        </div>
                        <div className="form-group">
                            <div className="row gutter-7">
                                <div className="col-4 col-sm-3">
                                    <label className="control-label">{tr.card_expires || "Expiry"}</label>
                                    <div className="form-control form-control-dark bt-form-control" id="expiration-month"></div>
                                    {this.state.errorMonth && <div className="text-danger">{tr.invalid_field || "Invalid Field"}</div>}
                                </div>
                                <div className="col-4 col-sm-3">
                                    <label className="control-label">&nbsp;</label>
                                    <div className="form-control form-control-dark bt-form-control" id="expiration-year"></div>
                                    {this.state.errorYear && <div className="text-danger">{tr.invalid_field || "Invalid Field"}</div>}
                                </div>
                                <div className="col-4 col-sm-3 offset-sm-3">
                                    <label className="control-label">{tr.cvvcvc || "CVV/CVC"}</label>
                                    <div className="form-control form-control-dark bt-form-control" id="cvv"></div>
                                    {this.state.errorSecurityCode && <div className="text-danger">{tr.invalid_field || "Invalid Field"}</div>}
                                </div>
                            </div>
                        </div>
                        {this.renderCheckbox()}
                        {this.renderOptionalText()}
                        {this.renderSubmitButton()}
                    </div>
                </div>
            </form>
        )
    }
}
export default withAppConfig(BrainTree);
BrainTree.defaultProps = {
    showSaveCardOption: false,
    saveCard: false,
    defaultCardOption: false,
    showFooterText: false,
    customContainerClass: "col-lg-6",
    NoToken: false
}
BrainTree.propTypes = {
    showSaveCardOption: PropTypes.bool,
    defaultCardOption: PropTypes.bool,
    showFooterText: PropTypes.bool,
    saveCard: PropTypes.bool,
    buttonText: PropTypes.string,
    customContainerClass: PropTypes.string,
    savePaymentClicked: PropTypes.func
};
