import React, { Component } from "react";
import { disableAlert } from "../../helpers/StateHelper";
import _find from "lodash/find";
import { getActiveMovieOrderObject, checkIsMovieExpired, handleBackDropClick, handlePurchaseBannerClick, handleSubscribeClick, checkIsMovieSubscribed } from "../../helpers/MoviesHelper";
import { WATCH } from "../../constants/routes";
import { TRAILER_VIDEO, RESPONSE_MYLIST, STORED_MY_LIST, MOVIE_VIDEO, STORED_MOVIES_DETAIL, FETCH_MOVIE_DATA, UPDATE_MYLIST_MOVIE, NO_EXPIRY, STORED_EMPTY_MYLIST_FLAG } from "../../constants/constants";
import { updateApiWithResponse, deleteApiRequest } from './../../helpers/APIHelpers/MoviesAPI';
import { getApiData } from "../../helpers/APIHelpers/MoviesAPI";
import { checkIsMovieLikedPurchased, showExpiredAlert, checkMoviePurchaseType, getPurchaseData, decideLikePurchasedStatus } from "../../helpers/MoviesHelper";
import { getCurrentUserId, convertSecToDuration, ping, isEmpty, getDataFromLocalStorage, storeDataInLocalStore, setDocumentTitle, importMoment } from "../../helpers/GeneralHelpers";
import { renderNotificationMessage } from "../../helpers/ViewHelpers";
import { getSettingsFromConfig, addMovieMetaDescription, removeMovieMetaDescription } from "../../helpers/ConfigHelpers";
import { MovieDetailView } from "./components/MovieDetail";
import RelatedMovies from "../RelatedMovies/RelatedMovies";
import PurchaseModal from "../../components/PurchaseModal/PurchaseModal";
import TransactionModal from "../../components/TransactionModal/TransactionModal";
import { withAppConfig } from "../../context/ConfigContext";
import { withAuth } from "../../context/AuthContext";
import SubscribeModal from "../../components/SubscribeModal/SubscribeModal";
import CancelSubscriptionModal from "../../components/CancelSubscriptionModal/CancelSubscriptionModal";

class MovieDetail extends Component {
    USER_ID = "";
    moment = null;
    isExpired = true;
    expiringTime = "";
    purchaseType = "";
    showPurchaseModel = false;
    isCompMounted = true;
    SERVERTIME = null;
    purchaseList = []; //used for related movies
    purchaseTierQuality = "";
    MAPING_STATUS_TYPE = "";
    constructor(props) {
        super(props);
        this.state = {
            isLoadingMovieData: true,
            movieRating: null,
            movieData: {},
            dataErrorMovie: "",
            isAddingMovieRating: false,
            isAddingMovieLike: false,
            showSuccessAlert: false,
            showErrorAlert: false,
            movieLiked: false,
            isLoadingMyListMovies: false,
            errorMyListMovies: "",
            alertMessage: "",
            canWatchMovie: false,
            showPurchaseModel: false,
            selectedPurchaseTierId: "",
            selectedPurchaseMovieId: "",
            selectedPurchaseMovieType: "",
            purchaseHistoryLastEvaluatedKey: "",
            isMovieLockDecided: false,
            showRelatedTags: false,
            isCheckingOut: false,
            paymentWasSuccessfull: false,
            screeningRoom: null
        }
        this.MOVIE_ID = props.match.params.id;
        this.importMoment = importMoment.bind(this);
        this.getCurrentUserId = getCurrentUserId.bind(this);
        this.getPurchaseData = getPurchaseData.bind(this);
        this.disableAlert = disableAlert.bind(this);
        this.fetchMovieData = getApiData.bind(this);
        this.updateMovieLike = updateApiWithResponse.bind(this);
        this.updateMovieRating = updateApiWithResponse.bind(this);
        this.updateMovieDelete = deleteApiRequest.bind(this);
        this.handleBackDropClick = handleBackDropClick.bind(this);
        this.fetchMyListMovies = getApiData.bind(this);
        this.checkIsMovieExpired = checkIsMovieExpired.bind(this);
        this.showExpiredAlert = showExpiredAlert.bind(this);
        this.checkMoviePurchaseType = checkMoviePurchaseType.bind(this);
        this.decideLikePurchasedStatus = decideLikePurchasedStatus.bind(this);
        this.renderNotificationMessage = renderNotificationMessage.bind(this);
    }

    componentDidMount() {
        setDocumentTitle();
        this.handlePurchaseClick = this.state.movieData.isAvailableForSubscription ? handleSubscribeClick.bind(this) : handlePurchaseBannerClick.bind(this);

        this.initialize(this.props.match.params.id);
        this.importMoment();
    }

    componentDidUpdate(prevProps) {
        if (this.state.movieData) {
            const { Title = ``, Description = `` } = this.state.movieData;
            setDocumentTitle(Title);
            if (Description && !this.Descriptionset) {
                this.Descriptionset = true
                let desc = Description.substring(0, 150);
                addMovieMetaDescription(desc);
            }
        }
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.setInitialState();
            this.handleBackDropClick();
            this.initialize(this.props.match.params.id);
            this.MOVIE_ID = this.props.match.params.id;
        }
    }

    componentWillUnmount() {
        if (this.state.showPurchaseModel) {
            this.handleBackDropClick();
        }
        this.isCompMounted = false;
        removeMovieMetaDescription();
    }

    initialize = async (movieID) => {
        let movie = getDataFromLocalStorage(`${STORED_MOVIES_DETAIL}${movieID}`);
        if (!isEmpty(movie)) {
            if (this.isCompMounted) {
                this.setState((prevState) => {
                    return {
                        isLoadingMovieData: false,
                        dataErrorMovie: "",
                        movieData: movie
                    }
                }, () => {
                    this.decideShowComingSoonStatus();
                });
            }
        } else {
            movie = await this.fetchMovieData("MoviesAPI", `/get/${movieID}`, this.state.movieData, "isLoadingMovieData", "dataErrorMovie", "movieData", FETCH_MOVIE_DATA);
            storeDataInLocalStore(`${STORED_MOVIES_DETAIL}${movieID}`, movie, null, NO_EXPIRY);
            this.decideShowComingSoonStatus();
        }

        if (movie.ScreeningRooms) {

            movie.ScreeningRooms.forEach((sr) => {
                if (sr.Status === 'active') {
                    this.setState({ screeningRoom: sr })
                }
            })
        }

        if (this.props.isAuthenticated) {
            if (!this.moment) {
                await this.importMoment()
            }
            if (!this.SERVERTIME) {
                this.SERVERTIME = await ping();
            }
            if (!this.USER_ID) {
                this.USER_ID = await this.getCurrentUserId();
            }

           
            let isSubscribed = checkIsMovieSubscribed(this.props.subscriptions, movie);
            this.setState({
                isSubscribed_: isSubscribed
            })
            this.getLikedPurchasedData(this.USER_ID, this.SERVERTIME);
        } else {
            this.setMovieLockedStatusDecided();
        }
    }

    getPGRatingIcon = async (pgRatingValue) => {
        let pgRating = await getSettingsFromConfig("APP_PG_RATING");
        if (pgRating) {
            let selectedItem = _find(pgRating, ['ID', pgRatingValue]);
            return selectedItem;
        }
        return null
    }

    setMovieLockedStatusDecided = () => {
        this.setState({
            isMovieLockDecided: true
        })
    }

    decideShowComingSoonStatus = async () => {
        const { appTranslations: tr } = this.props;
        if (this.state.movieData) {
            if (!this.state.movieData.isAvailableForRent && !this.state.movieData.isAvailableForBuy) {
                if (this.state.movieData.HVSD) {
                    if (!this.moment) {
                        await this.importMoment();
                    }
                    if (!this.SERVERTIME) {
                        this.SERVERTIME = await ping();
                    }
                    let isComingSoon = this.moment(this.state.movieData.HVSD).isAfter(this.moment(this.SERVERTIME));
                    if (isComingSoon) {
                        this.setState({
                            comingSoonStatus: `${tr.available_on} ${this.moment(this.state.movieData.HVSD).format('Do MMMM')}`
                        })
                    }
                }
            }
        }
    }

    getLikedPurchasedData = async (userId, currentTime) => {
        let myList = getDataFromLocalStorage(STORED_MY_LIST, userId);
        let myListEmptyFlag = getDataFromLocalStorage(STORED_EMPTY_MYLIST_FLAG, userId);
        let purchaseList = await this.getPurchaseData(userId, true); // --> update later
        if (isEmpty(myList) && !myListEmptyFlag) {
            myList = await this.fetchMyListMovies("UserAPI", "/myList", "", "", "", "", RESPONSE_MYLIST, "ID", null, false, false)
            storeDataInLocalStore(STORED_MY_LIST, myList, userId, NO_EXPIRY);
        }
        let isLiked = checkIsMovieLikedPurchased(myList, this.MOVIE_ID, "ID", true);
        this.setState({
            movieLiked: isLiked
        });
        let canWatchMovie = checkIsMovieLikedPurchased(purchaseList, this.MOVIE_ID, "MovieID");
        if (canWatchMovie) {
            this.setMoviePurchaseStatus(purchaseList, currentTime);
        } else {
            this.setMovieLockedStatusDecided();
        }
    }

    setMoviePurchaseStatus = async (purchaseList, currentTime) => {
        let movie = getActiveMovieOrderObject(purchaseList, "MovieID", this.MOVIE_ID);// getObjectFromArray(purchaseList, "MovieID", this.MOVIE_ID); //**** PIN POINT ******/
        this.isExpired = this.checkIsMovieExpired(this.moment, movie, currentTime);
        this.expiringTime = this.isExpired ? movie.ValidUntil === "N-A" ? this.moment(movie.RentMaxValidity).format('YYYY MMMM Do, h:mm:ss a') : this.moment(movie.ValidUntil).format('YYYY MMMM Do, h:mm:ss a') : this.checkIsMovieExpired(this.moment, movie, currentTime, true);//172800===sec in 48hrs
        this.expiringTime = (!this.isExpired) ? (this.expiringTime !== "---" && this.expiringTime !== "New") ? (movie.Validity === 48) ? (this.expiringTime > 172800) ? convertSecToDuration(172800) : convertSecToDuration(this.expiringTime) : convertSecToDuration(this.expiringTime) : this.expiringTime : this.expiringTime;
        this.purchaseType = this.checkMoviePurchaseType(movie); //purchaseList not required
        this.purchaseTierQuality = (!this.isExpired) ? (movie.ContractTier) ? (movie.ContractTier.constructor === Object) ? movie.ContractTier.DisplayName : "" : "" : "";
        if (this.isExpired) {
            this.showExpiredAlert(this.moment, movie, currentTime, this.props.appTranslations);
        }
        this.setState({
            canWatchMovie: true
        }, () => this.setMovieLockedStatusDecided());
    }

    handleMovieLike = (id) => {
        const { appTranslations: tr } = this.props;
        if (this.props.isAuthenticated) {
            this.setState({
                isAddingMovieLike: true,
            });
            if (this.state.movieLiked) {
                let header = { "MovieID": id };
                this.updateMovieDelete("UserAPI", "/myList", header, this.state.movieLiked, "showSuccessAlert", "showErrorAlert", "alertMessage", tr.removed_from_my_list, tr.movie_couldnt_removed_from_my_list, UPDATE_MYLIST_MOVIE, "isAddingMovieLike", "movieLiked").then((res) => {
                    storeDataInLocalStore(STORED_MY_LIST, res, this.USER_ID, NO_EXPIRY);
                });
            } else {
                let header = { "MovieID": id };
                this.updateMovieLike("UserAPI", "/myList", header, this.state.movieLiked, "showSuccessAlert", "showErrorAlert", "alertMessage", tr.added_to_my_list, tr.movie_couldnt_be_added_to_my_list_, UPDATE_MYLIST_MOVIE, "isAddingMovieLike", "movieLiked").then((res) => {
                    storeDataInLocalStore(STORED_MY_LIST, res, this.USER_ID, NO_EXPIRY);
                })
            }
        } else {
            this.props.history.push(`/login?redirect=${this.props.location.pathname}${this.props.location.search}`);
        }
    }

    handleTrailerClick = (e, movieId, index = 0) => {
        e.preventDefault();
        if (index === 0) {
            this.props.history.push(`${WATCH}${TRAILER_VIDEO}/${movieId}`);

        } else {
            this.props.history.push(`${WATCH}${TRAILER_VIDEO}/${movieId}?t=${index}`);
        }
    }

    handleWatchMovieClick = async (e, movieId) => {
        e.preventDefault();
        if (this.state.paymentWasSuccessfull) {
            this.setState({
                paymentWasSuccessfull: false
            })
        }
        if (this.props.isAuthenticated) {
            this.props.history.push(`${WATCH}${MOVIE_VIDEO}/${movieId}`);
        } else {
            this.props.history.push(`/login?redirect=${this.props.location.pathname}${this.props.location.search}`);
        }
    }

    setInitialState = () => {
        this.isExpired = true;
        this.setState({
            isLoadingMovieData: true,
            isLoadingRelatedMovies: true,
            canWatchMovie: false,
            movieLiked: false,
            isMovieLockDecided: false,
            showRelatedTags: false,
            comingSoonStatus: ``,
        })
    }

    handleMovieClick = (e, movieID) => { //remove e
        if (this.state.paymentWasSuccessfull) {
            this.setState({
                paymentWasSuccessfull: false,
                isMovieLockDecided: false
            });
            this.getLikedPurchasedData(this.USER_ID, this.SERVERTIME);
        } else {
            movieID = e; //temporaryfix
            if (movieID !== this.MOVIE_ID) {
                this.setState({
                    isLoadingMovieData: true,
                    isLoadingRelatedMovies: true,
                });
                this.initialize(movieID);
                this.MOVIE_ID = movieID;
                this.setInitialState();
            }
        }
    }

    handleSelectedQualityVideo = (value) => {
        this.setState({
            selectedPurchaseTierId: value
        })
    }

    dismissPurchaseModal = (paymentSucess = true) => {
        this.setState({
            paymentWasSuccessfull: paymentSucess,
            showPurchaseModel: false,
        })
    }

    // =================== PURCHASE MODAL ===================
    renderPurchaseModal = () => {
        return (
            <PurchaseModal
                showPurchaseModel={this.state.showPurchaseModel}
                dismissPurchaseModal={this.dismissPurchaseModal}
                isAuthenticated={this.props.isAuthenticated}
                handleSelectedQualityVideo={this.handleSelectedQualityVideo}
                data={this.state.movieData}
                movieId={this.state.selectedPurchaseMovieId}
                type={this.state.selectedPurchaseMovieType}
                handleBackDropClick={this.handleBackDropClick}
                showLoader={this.state.isCheckingOut}
            />)
    }
 
    // =================== SUBSCRIBE MODAL ===================
    renderSubscribeModal = () => {
        return (
            <SubscribeModal
                showSubscribeModal={this.state.showPurchaseModel}
                dismissPurchaseModal={this.dismissPurchaseModal}
                isAuthenticated={this.props.isAuthenticated}
                handleSelectedQualityVideo={this.handleSelectedQualityVideo}
                data={this.state.screeningRoom}
                handleBackDropClick={this.handleBackDropClick}
                showLoader={this.state.isCheckingOut}
            />)
    }

    // =================== CANCEL SUBSCRIPTION MODAL ===================
    renderCancelSubscriptionModal = () => {
        return (
            <CancelSubscriptionModal showCancelSubscriptionModal={this.state.showPurchaseModel}
                dismissPurchaseModal={this.dismissPurchaseModal}
                isAuthenticated={this.props.isAuthenticated}
                MovieID={this.props.match.params.id}
                data={this.state.screeningRoom}
                handleBackDropClick={this.handleBackDropClick}
                handleMovieClick={this.handleMovieClick}
                />
        )
    }
    // =================== TRANSACTION MODAL ===================
    renderTransactionSuccessModal = () => {
        const { appTranslations: tr } = this.props;
        if (this.state.movieData && this.state.paymentWasSuccessfull) {
            const { Title, RentMaxValidity, RentTvodValidity = `` } = this.state.movieData;
            return (
                <TransactionModal
                    Title={Title}
                    ModalHeaderTitle={tr.free_watch_successfull || "Free Watch Successfull"}
                    RentMaxValidity={`${RentMaxValidity} ${tr.days} `}
                    MovieID={this.props.match.params.id}
                    handleWatchMovieClick={this.handleWatchMovieClick}
                    handleMovieClick={this.handleMovieClick}
                    RentTvodValidity={`${RentTvodValidity} ${RentTvodValidity > 1 ? tr.hours : tr.hour}`}
                />
            )
        }
        return null;
    }

    render() {
        return (
            <main className="mn-main">
                {this.renderNotificationMessage()}
                {(this.state.isCheckingOut && !this.state.showPurchaseModel) &&
                    <span className="loader-spiral mn-transparent"><span className="spiral-inn"></span></span>}
                <MovieDetailView
                    {...this.props}
                    {...this.state}
                    {...this}
                />

<br></br>
                <br></br>
                <br></br>
                {/* <RelatedMovies
                    movieId={this.props.match.params.id}
                    handleMovieClick={this.handleMovieClick}
                    {...this.props}
                /> */}
                {this.state.movieData.isAvailableForSubscription ? this.renderSubscribeModal() : this.renderPurchaseModal()}

                {this.renderTransactionSuccessModal()}
            </main>

        );
    }
}

export default withAppConfig(withAuth(MovieDetail));
