import { FETCH_BANNER, ALLMOVIES, PARTNER_SECTIONS, FETCH_GENERE, GETCARDSLIST, UPDATE_MOVIE_RATING, CHECKOUTMOVIEDATAONCHANGEQUALITY, CHECKOUTMOVIEDATA, GENRE, FETCH_WATCHLIST, FETCH_SORTED_MOVIES, FETCH_MOVIE_PLAY_VIDEO, FETCH_SEARCH_DATA, MOVIE_STREAM_RELATED, MOVIE_STREAM, FETCH_RELATED_MOVIES, CLIENT_TOKEN, EST, FETCH_MOVIE_DATA, REMOVE_MYLIST_MOVIE, VERIFY_VOUCHER, UPDATE_MYLIST_MOVIE, UPDATE_PROFILE_PHOTO, SUBMIT_PAYMENT_FORM, RESPONSE_MYLIST, PURCHASE_HISTORY, PURCHASE, QUERYTIERID, TVOD, QUERYFREEWATCH } from "../constants/constants";
import { checkIsMovieFreeWatch } from "./MoviesHelper";
import _find from "lodash/find";
import { updateMyListCacheFlag } from "./CacheHelpers";
import { uniqueBy } from "./GeneralHelpers";

export function getResponse(response, data, status, uniqBy, userId = null) {
    let result; let time;
    switch (status) {
        case FETCH_GENERE:
            result = response.body.Items
            break;
        case UPDATE_MYLIST_MOVIE:
        case "UPDATE_MYLIST_MOVIE_BANNER":
        case REMOVE_MYLIST_MOVIE:
            updateMyListCacheFlag(response.body, userId);
            result = response.body;//!data;
            break;
        case UPDATE_PROFILE_PHOTO:
            time = new Date();
            result = `${response.body.FilePath}?rnd=${time.getTime()}`
            break;
        case SUBMIT_PAYMENT_FORM:
        case VERIFY_VOUCHER:
        case "VERIFY_VOUCHER_PARTNER":
        case MOVIE_STREAM:
        case FETCH_RELATED_MOVIES:
        case FETCH_MOVIE_DATA:
        case "SUBMIT_FREE_PAYMENT_FORM_PARTNER":
        case FETCH_MOVIE_PLAY_VIDEO:
        case MOVIE_STREAM_RELATED:
        case UPDATE_MOVIE_RATING: // UPDATE_MOVIE_RATING == setMovieData
            result = response.body;
            break;
        case CHECKOUTMOVIEDATA:
        case CHECKOUTMOVIEDATAONCHANGEQUALITY:
            result = getCheckoutMovieData(response.body, data);
            break;
        case FETCH_BANNER:
            result = uniqueBy(response.body, uniqBy);
            break;
        case GENRE:
        case FETCH_WATCHLIST:
        case FETCH_SORTED_MOVIES:
            result = uniqueBy(response.body.Items, uniqBy);
            break;
        case PURCHASE_HISTORY:
            result = SortRentPurchaseData(response.body.Items);
            break;
        case "PURCHASE_HISTORY_BANNER":
            result = response.body.Items;
            break;
        case CLIENT_TOKEN:
            result = response.body.token
            break;
        case RESPONSE_MYLIST: //also used in GENRE/mylist
            updateMyListCacheFlag(response.body.MyList, userId);
            result = uniqueBy(response.body.MyList, uniqBy);
            break;
        case FETCH_SEARCH_DATA:
            result = response;
            break;
        case ALLMOVIES:
            result = response.body.Items;
            break;
        case PARTNER_SECTIONS:
            result = response.body
            break;
        case GETCARDSLIST:
            result = response.body;
            break;
        case "StaticPages":
            result = response.body.VALUE;
            break;
        default:
            result = uniqBy ? uniqueBy(response.body, uniqBy) : response.body; //UPDATE_MYLIST_MOVIE_BANNER
            break;
    }
    return result;
}

export const getCheckoutMovieData = (response, data) => {
    console.log("response", response)
    let movieData = {}; let updatedTeirID = data[QUERYTIERID];
    let Params = data[PURCHASE] === EST ? response.BuyParams ? response.BuyParams : [] : response.RentParams ? response.RentParams : [];
    let selectedTier = {};
    if (data[QUERYFREEWATCH]) {
        selectedTier = checkIsMovieFreeWatch(response, true);
    } else {
        selectedTier = _find(Params, ["ID", data[QUERYTIERID]]);
    }
    if (!selectedTier) {
        selectedTier = Params[0];
        if (selectedTier) {
            updatedTeirID = selectedTier.ID
        }
    }
    if (selectedTier) {
        movieData["SelectedTeirID"] = updatedTeirID;
        movieData["Price"] = (selectedTier.Price || selectedTier.Price === 0) ? selectedTier.Price : 100; //null
        movieData["CurrencySymbol"] = selectedTier.CurrencySymbol ? selectedTier.CurrencySymbol : ""
        movieData["Params"] = Params;
        movieData["RentTvodValidity"] = (data[PURCHASE] === TVOD) ? (response.RentTvodValidity && response.RentTvodValidity !== 0) ? `${response.RentTvodValidity}  ${response.RentTvodValidity > 1 ? "Hours" : "Hour"} ` : "" : ""; //convertSecToDuration(response.RentTvodValidity) : "" : "";
        movieData["Title"] = response.Title ? response.Title : "";
        movieData["PlayDuration"] = selectedTier.PlayDuration ? selectedTier.PlayDuration : "";
        movieData["RentMaxValidity"] = response.RentMaxValidity ? response.RentMaxValidity : "";
        movieData["Genre"] = response.Genre ? response.Genre : "";
        movieData["PlayDuration"] = response.PlayDuration ? response.PlayDuration : "";
        movieData["ReleaseYear"] = response.ReleaseYear ? response.ReleaseYear : "";
        movieData["ManifestDetails"] = selectedTier.ManifestDetails ? selectedTier.ManifestDetails[0] : "";
        movieData["PosterURL"] = response.PosterURL;
    }
    return movieData;
}

export function SortRentPurchaseData(response) {
    let data = []; let rented = []; let purchased = [];
    if(response){
        response.forEach((obj) => {
            obj.PurchaseType === EST ? purchased.push(obj) : rented.push(obj);
        });
    }
    //let sortedRent = uniqueBy(rented, "MovieID");
    //let sortedPurchased = uniqueBy(purchased, "MovieID");
    data.push(rented);
    data.push(purchased);
    return data;
}
