import React from "react";
import Formsy from "formsy-react";
import MySelect from "../../../../components/MySelect";

const Quality = props => {
  if (!props.data.Plans) {
    return null;
  }
  let qualityList = [];
  let qualitySet = new Set();
  props.data.Plans.forEach((item) => {
    ['sd', 'hd', '4k'].forEach((q) => {
      if(item.pricelist[q] && item.pricelist[q].price !== null){
        qualitySet.add(q);
      }
    });
  });
  for (let item of qualitySet) {
    qualityList.push({
      title: item.toUpperCase(),
      value: item.toLowerCase()
    })
  }
  let firstName = qualityList[0].title;

  return (
    <div className="mn-cart-cell col-12 col-md-2">
      <div className="mb-1 text-white">
        {props.appTranslations.video_quality || "Video Quality"}
      </div>
      <div>
        {qualityList.length > 1 ? (
          <Formsy>
            <MySelect
              id="selectedQuality"
              name="selectedQuality"
              outerDiv={false}
              disabled={
                props.checkoutData.isAutoRedempted || props.checkoutData.Code
              }
              innerClassName="form-control-dark"
              value={props.selectedQuality}
              handleChange={props.onQChange}
              options={qualityList}
            />
          </Formsy>
        ) : (
            firstName
          )}
      </div>
    </div>
  );
};

export default Quality;
