import React, { Component } from "react";
import { HOME } from "../../constants/routes";
import Loader from "../../components/Loader/Loader";

class SignOut extends Component {

    componentDidMount() {
        let path = this.props.location.pathname;
        if (path !== "/" && path !== HOME) {
            this.props.history.push(HOME);
        }
    }

    render() {
        return (
            <main className="mn-main">
                <Loader />
            </main>
        )
    }
}
export default SignOut;