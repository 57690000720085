import {getPageLayout} from "../../helpers/GeneralHelpers";
import {getLayoutClass, getBackGroundImageStyle} from "./helpers";
import config from "../../config/config";
import RestrictedLogin from "../../containers/Login/RestrictedLogin";
import React from "react";
import Routes from "../../Routes";

const index = ({geoBlockStyle, pixScrolled, isAuthenticated, render,history, childProps }) => {
    let loginLayout = getPageLayout();
    let className = getLayoutClass(loginLayout, pixScrolled, geoBlockStyle, isAuthenticated);
    let isNoContainerRoute = window.location.pathname === "/unsupported";
    let style = getBackGroundImageStyle(loginLayout, geoBlockStyle);
    if ((config.applicationName === "PebbleFlix" || config.applicationName === "Roadshow") && !childProps.isAuthenticated) {
        if(history && history.location && history.location.pathname === "/reset-password"){
            return(
                <div id="my-page-outerContainer" className="main-wrap global-wrap login-wrap"> {/*  style={style} only enable Route for reset-password */}
                    <Routes childProps={childProps} />
                </div>
            )
        }
        return (
            <div id="my-page-outerContainer" className="main-wrap global-wrap login-wrap">
                <RestrictedLogin {...childProps} />
            </div>
           )
    } else {
        return (render(loginLayout, className, style, isNoContainerRoute));
    }
};

export default index;
