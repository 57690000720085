import React from "react";
import { Modal} from "reactstrap";
import UniversalLogin from "../components/UniversalLogin";

const RestrictedLogin =(props) => {
    return (
        <Modal isOpen={!props.isAuthenticated}
               className="modal-dark modal-dialog-centered" backdrop={false} data-keyboard="false">
            <div className="modal-content">
                <div className="modal-body p-4 p-sm-5">
                    <UniversalLogin {...props}/>
                </div>
            </div>
        </Modal>
    )
}

export default RestrictedLogin