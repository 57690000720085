import React from "react";
import { Route, Switch } from "react-router-dom";
import DeviceManager from "./containers/DeviceManager/DeviceManager.jsx";
import Home from "./containers/Home"
import Movies from "./containers/Movies";
import MovieDetail from "./containers/MovieDetail/MovieDetail";
// import Library from "./containers/Library";
import MySubscriptions from "./containers/Library/Subscriptions";
import MyList from "./containers/Library/MyList";
import MyRentals from "./containers/Library/MyRentals";
import WatchHistory from "./containers/Library/WatchHistory";
import Library2 from "./containers/Library/Library";
import Genere from "./containers/Genere";
import NotFound from "./containers/NotFound/NotFound";
import ConfirmUser from "./containers/ConfirmUser/ConfirmUser";
import ForgotPassword from "./containers/ForgotPassword/ForgotPassword";
import Profile from "./containers/Profile/Profile";
import ChangePassword from "./containers/ChangePassword/ChangePassword";
import PlayVideo from "./containers/PlayVideo/PlayVideo";
import UnSupportedBrowser from "./containers/UnSupportedBrowser/UnSupportedBrowser";
import ServiceUnavailable from "./containers/ServiceUnavaible/ServiceUnavaible"; //direct component
import Terms from "./containers/Terms/Terms";
import Policy from "./containers/Policy/Policy";
import Contact from "./containers/Contact/Contact";
import About from "./containers/About/About";
import HowToWatch from "./containers/HowToWatch/HowToWatch";
import MovieCheckout from "./containers/Checkout/Movie";
import RCCheckout from "./containers/Checkout/RoomsCollection/"
import Search from "./containers/Search/Search";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import MyRoute from "./components/MyRoute";
import SocialRedirect from "./containers/SocialRedirect/";
import SignOut from "./containers/SocialRedirect/Signout";
import PartnerLanding from "./containers/PartnerLanding/PartnerLanding";
import PurchaseListing from "./containers/PurchaseListing/PurchaseListing";
import PaymentSettings from "./containers/PaymentSettings/PaymentSettings";
import Notifications from "./containers/Notifications/Notifications";
import Loader from "./components/Loader/Loader";
import DynamicImport from "./components/DynamicImport/DynamicImport";
import GeoBlock from "./containers/GeoBlock/GeoBlock";
import StoredVouchers from "./containers/StoredVouchers/StoredVouchers";
import * as routes from "./constants/routes";
import Rooms from "./containers/Rooms/DetailContainer";
import Collections from "./containers/Collections/DetailContainer";
import AllRooms from "./containers/Rooms/AllRooms";
import AllCollections from "./containers/Collections/AllCollections";
import Subscriptions from "./containers/ManageRentals/Subscriptions/index.jsx";
import Maccass from "./containers/Maccass/index.jsx";

const Settings = (props) => (
    <DynamicImport load={() => import("./containers/Settings")}>
        {(Component) => Component === null
            ? <Loader />
            : <Component {...props} />}
    </DynamicImport>
)
const Routes = ({ childProps }) =>
    <Switch>
        <MyRoute path="/" exact component={Home} props={childProps} />
        <MyRoute path={routes.HOME} exact component={Home} props={childProps} />
        <MyRoute path={`${routes.ROOMDETAIL}:id`} component={Rooms} props={childProps} />
        <MyRoute path={`${routes.COLLECTIONDETAIL}/:id`} component={Collections} props={childProps} />
        <MyRoute path={routes.ALLROOMS} exact component={AllRooms} props={childProps} />
        <MyRoute path={routes.ALLCOLLECTIONS} exact component={AllCollections} props={childProps} />
        <MyRoute path={routes.MOVIES} exact component={Movies} props={childProps} />
        <MyRoute path={`${routes.MOVIE}:id`} component={MovieDetail} props={childProps} />
        <MyRoute path={`${routes.GENRE}:type/:id`} component={Genere} props={childProps} />
        <MyRoute path={`${routes.WATCH}:type/:id/`} component={PlayVideo} props={childProps} />
        <MyRoute path={routes.UNSUPPORTED_BROWSER} component={UnSupportedBrowser} props={childProps} />
        <MyRoute path={routes.PRIVACY_POLICY} exact component={Policy} props={childProps} />
        <MyRoute path={routes.TERMS_CONDITIONS} component={Terms} props={childProps} />
        <MyRoute path={routes.CONTACT_US} component={Contact} props={childProps} />
        <MyRoute path={routes.ABOUT_US} component={About} props={childProps} />
        <MyRoute path={routes.HOW_TO_WATCH} component={HowToWatch} props={childProps} />
        <MyRoute path={routes.SEARCH} component={Search} props={childProps} />
        <MyRoute path={routes.PARTNERURL} component={PartnerLanding} props={childProps} />
        <MyRoute path={routes.SERVICE_UNAVAILABLE} component={ServiceUnavailable} props={childProps} />
        <MyRoute path={routes.MACCASS} component={Maccass} props={childProps} />

        <UnauthenticatedRoute path={routes.LOGIN} exact component={GeoBlock} props={childProps} /> {/*actually => component={Login}*/}
        <UnauthenticatedRoute path={routes.SIGNUP} exact component={GeoBlock} props={childProps} />{/*actually => component={Signup}*/}
        <UnauthenticatedRoute path={routes.PARTNERSIGNUP} component={GeoBlock} props={childProps} />{/*actually => component={PartnerSignup}*/}
        <UnauthenticatedRoute path={routes.CONFIRM_USER} exact component={ConfirmUser} props={childProps} />
        <UnauthenticatedRoute path={routes.FORGOT_PASSWORD} exact component={ForgotPassword} props={childProps} />
        <UnauthenticatedRoute path={routes.RESET_PASSWORD} exact component={ForgotPassword} props={childProps} />

        {/* <AuthenticatedRoute path={routes.LIBRARY} exact component={Library} props={childProps} /> */}
        <AuthenticatedRoute path={routes.MYLIST} exact component={MyList} props={childProps} />
        <AuthenticatedRoute path={routes.MYRENTALS} exact component={MyRentals} props={childProps} />
        <AuthenticatedRoute path={routes.MYSUBSCRIPTIONS} exact component={MySubscriptions} props={childProps} />
        <AuthenticatedRoute path={routes.WATCHHISTORY} exact component={WatchHistory} props={childProps} />
        <AuthenticatedRoute path="/library2" exact component={Library2} props={childProps} />
        <AuthenticatedRoute path={routes.PAYMENT_SETTINGS} exact component={PaymentSettings} props={childProps} />
        <AuthenticatedRoute path={routes.PURCHASE_LISTING} exact component={PurchaseListing} props={childProps} />
        <AuthenticatedRoute path={routes.SUBSCRIPTION_HISTORY} exact component={Subscriptions} props={childProps} />
        <AuthenticatedRoute path={`${routes.CHECKOUT}:id`} exact component={MovieCheckout} props={childProps} />
        <AuthenticatedRoute path={`/checkout-sc/:id`} exact component={RCCheckout} props={childProps} />
        <AuthenticatedRoute path={routes.DEVICES} exact component={DeviceManager} props={childProps} />
        <AuthenticatedRoute path={routes.PROFILE} exact component={Profile} props={childProps} />
        <AuthenticatedRoute path={routes.SETTINGS} exact component={Settings} props={childProps} />
        <AuthenticatedRoute path={routes.NOTIFICATIONS} exact component={Notifications} props={childProps} />
        <AuthenticatedRoute path={routes.STOREDVOUCHERS} exact component={StoredVouchers} props={childProps} />
        <AuthenticatedRoute path={routes.CHANGE_PASSWORD} exact component={ChangePassword} props={childProps} />
        <AuthenticatedRoute path={routes.SOCIALSIGNIN} exact component={SocialRedirect} props={childProps} />
        <AuthenticatedRoute path={routes.SIGNOUT} exact component={SignOut} props={childProps} />

        <Route component={NotFound} />
    </Switch>;
export default Routes;