import React, { useState, useEffect, Fragment } from "react";
import getRelatedRooms from "../../../services/movies/GetRelatedRooms";
import Loader from "../../../components/Loader/Loader";
import _isEmpty from "lodash/isEmpty";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";
import RoomsCard from "../../../components/RoomsCard";

const RelatedRooms = props => {
  const [isLoading, setLoading] = useState(true);
  const [List, setList] = useState([]);

  useEffect(() => {
    let params = {
      ID: props.match.params.id,
      subscriptions: props.subscriptions,
      isAuthenticated: props.isAuthenticated,
      HostName: props.HostName
    };
    getRelatedRooms((err, res) => {
      if (!err) {
        setList(res);
      }
      setLoading(false);
    }, params);
    return () => {};
  }, []);

  if (isLoading) {
    return (
      <section>
        <Loader />
      </section>
    );
  }

  return (
    <section className="mn-movies py-3">
      <div className="container-fluid">
        {_isEmpty(List) ? (
          <DataNotFound text="No Related Screening rooms" height="300px" />
        ) : (
          <Fragment>
            {/* <div className="d-flex w-100 justify-content-between">
              <div className="">
                <h4 className="text-white-70 mb-3">
                  Related Movies in this Room
                </h4>
              </div>
            </div> */}

            <div className="ov-hidden">
              <div className="row gutter-10 mn-movies-grid ">
                {List.map((item, index) => {
                  let room = item._source
                  return (
                    <RoomsCard
                      key={index}
                      onRelatedClick={props.onRelatedClick}
                      classname="mb-4"
                      {...room}
                    />
                  );
                })}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </section>
  );
};

export default RelatedRooms;
