import React from "react";

const Buttons = ({ isSubscribed_, onClick, ID, history }) => {
  let className = "btn-medium";
  const onMoreInfoClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`room-detail/${ID}`);
  }
  return (
    <div className="">
      {isSubscribed_ ?
        <button onClick={onMoreInfoClick} className={`btn btn-outline-primary ${className} `}>
          <i className="la la-info-circle"> </i> <span className="ml-1">Details</span>
        </button>
        : null}
      {!isSubscribed_ ?
        <span>
          <button onClick={onClick} className={`btn btn-primary ${className} `}>
            Subscribe
        </button>

          <button onClick={onMoreInfoClick} className={`btn btn-outline-primary ml-3 ${className}`}>
          <i className="la la-info-circle mr-1"> </i> More
        </button>
        </span>
        : null
      }
    </div>
  );
};

export default Buttons;
