import React, { Component } from "react";
import _map from "lodash/map";
import _find from "lodash/find";
import { fetchMoviesData, getApiData } from "../../helpers/APIHelpers/MoviesAPI";
import { RESUME, WATCHED, GENRE_PAGE, RESPONSE_MYLIST, FETCH_GENERE, PURCHASE_HISTORY, STORED_MY_LIST, STORED_MY_WATCHED_LIST, STORED_MY_PURCHASES, FETCH_PURCHASELIST, GENERE_SMALL_SLIDER_SIZE } from "../../constants/constants";
import { querystring, setDocumentTitle, importMoment, getCurrentUserId, getDataFromLocalStorage, uniqueBy, isEmpty } from "../../helpers/GeneralHelpers";
import { getPurchaseData, decideLikePurchasedStatus, checkPurchaseStatusRequirements } from "../../helpers/MoviesHelper";
import { getCompressedCdnImageUrl } from "../../helpers/CDNhelpers";
import config from "../../config/config";
import * as ROUTES from "../../constants/routes";
import { default as GenereView } from "./components/Genere";
import { getSortedData, getFilteredDataByGenre, applySorting } from "./helper";
const MOVIESLIMIT = 40;
const DEFAULTSORTVALUE = "year";
const DEFAULTGENREVALUE = "all";
const SortList = [{ name: "Release Date", value: "year" }, { name: "Recently Added", value: "recently-added" },{ name: "A - Z", value: "az" }, { name: "Z - A", value: "za" }];

class Genere extends Component {
    isCompMounted = true;
    USER_ID = ""
    purchaseList = []; // only used for checking purchased Status 
    MAPING_STATUS_TYPE = "" //used for tracking between ID & MovieID
    moment = null;
    SERVERTIME = null;
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            moviesList: [],
            allmoviesList: [],
            isError: "",
            lastEvaluatedKey: "",
            isLoadingMore: false,
            showGenreMovieTags: false,
            genreFilters: [],
            showGenereFiltersSlider: false,
            selectedSort: "",
            selectedGenreValue : ""
        }
        this.fetchMoviesData = fetchMoviesData.bind(this);
        // this.getPurchaseData = getPurchaseData.bind(this);
        this.importMoment = importMoment.bind(this);
        // this.getCurrentUserId = getCurrentUserId.bind(this);
        this.decideLikePurchasedStatus = decideLikePurchasedStatus.bind(this);
        this.checkPurchaseStatusRequirements = checkPurchaseStatusRequirements.bind(this);
    }

    async componentDidMount() {
        let id = this.props.match.params.id ? this.props.match.params.id : "";
        this.setState({
            name:querystring("name"),
            filtered: querystring("filtered"),
            sortGenre:querystring("genre"),
            selectedSort:querystring("sort")
        });
        this.fetchData(id, false);
        setDocumentTitle(`${GENRE_PAGE} - ${querystring("name")}`);
        this.setInitialSortType();
        if (this.props.isAuthenticated) {
            this.USER_ID = this.props.UserID;
            await this.checkPurchaseStatusRequirements();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setInitialState();
            this.fetchData(this.props.match.params.id, false);
            //update genre and selected sort here as well!
        }
    }

    componentWillUnmount() {
        this.isCompMounted = false;
    }

    setInitialSortType = () => {
        if(config.customSettings.isStuffpixEnabled && this.props.match.params.type ==="custom"){
        let sortType = querystring("sort");
            this.setState({
                selectedSort : sortType ? sortType : DEFAULTSORTVALUE
            });
        }
    }

    setInitialGenre = (GenreList) => {
        if(config.customSettings.isStuffpixEnabled && this.props.match.params.type ==="custom"){
            let genre = querystring("genre");
            if(genre){
                let selectedItem = _find(GenreList, ["Value", genre]);
                this.setState({
                   selectedGenre : selectedItem ? selectedItem.Value : DEFAULTGENREVALUE
                });
            }else{
                this.setState({
                    selectedGenre : DEFAULTGENREVALUE
                })
            }
        }
    }

    setInitialState() {
        this.setState({
            isLoading: true,
            moviesList: [],
            allmoviesList: [],
            isError: "",
            lastEvaluatedKey: "",
            isLoadingMore: false,
            showGenreMovieTags: false,
            genreFilters: [],
            sortGenre: "",
            showGenereFiltersSlider: false
        })
    }

    fetchData = async (id, loadMore) => {
        let isNotFound = false;
        let header = { "SectionID": id, "ExclusiveStartKey": this.state.lastEvaluatedKey, "Limit": MOVIESLIMIT };
        let sectionType = this.props.match.params.type ? this.props.match.params.type : "";
        let uniqueByKey = "ID", status = FETCH_GENERE;
        if (sectionType) {
            uniqueByKey = (sectionType.toLowerCase() === RESUME || sectionType.toLowerCase() === WATCHED) ? "MovieID" : "ID";
            status = (sectionType.toLowerCase() === "mylist") ? RESPONSE_MYLIST : FETCH_GENERE;
        }
        let response;
        if (this.props.isAuthenticated && !loadMore && (sectionType.toLowerCase() === "mylist" || sectionType === PURCHASE_HISTORY || sectionType.toLowerCase() === WATCHED)) {
            response = await this.checkDataFromCache(sectionType, uniqueByKey);
            if (!response) {
                isNotFound = true
            }
        } else {
            response = await this.fetchMoviesData("MoviesAPI", "/listSectionMovies", this.state.moviesList, "isLoading", "isError", "moviesList", status, uniqueByKey, "lastEvaluatedKey", header, loadMore);//.then(async (res) => {
            if (response) {
                if(loadMore){
                    response.Items = this.state.moviesList;
                }
                this.setState({
                    allmoviesList: (status === FETCH_GENERE) ? response.Items : response.MyList
                })
                await this.getSliderDataSettings(response, sectionType); // need full response here!! thats why below shortcut
                response = (status === FETCH_GENERE) ? response.Items : response.MyList;
            }
        }
        if (isNotFound) {
            response = await this.fetchMoviesData("MoviesAPI", "/listSectionMovies", this.state.moviesList, "isLoading", "isError", "moviesList", status, uniqueByKey, "lastEvaluatedKey", header, loadMore);//.then(async (res) => {
            if (response) {
                if(loadMore){
                    response.Items = this.state.moviesList;
                }
                this.setState({
                    allmoviesList: (status === FETCH_GENERE) ? response.Items : response.MyList
                })
                await this.getSliderDataSettings(response, sectionType);
                response = (status === FETCH_GENERE) ? response.Items : response.MyList;
            }
        }
        response = applySorting(response, sectionType, this.state.selectedSort);
        let filtereddata = response;
        if(this.state.selectedGenre){
            filtereddata = getFilteredDataByGenre(this.state.allmoviesList,this.state.selectedGenre,response);
        }
        this.updatePurchaseStatus(sectionType, filtereddata,response);
    }

    getSliderDataSettings = async (data, sectionType) => {
        if (data && (sectionType === "custom") && data.InNavigation) {
            let isfiltered = querystring("filtered");
            if (isfiltered && config.customSettings.isStuffpixEnabled) {
                let sectionsGenre = data.Genres ? data.Genres : [];
                let genreFilters = _find(this.props.configurations,["KEY","APP_GENRE"]);
                let HostName = this.props.HostName;
                genreFilters = genreFilters ? genreFilters.VALUE : []
                let GenereSliderData = [];
                _map(sectionsGenre, (item) => {
                    let found = _find(genreFilters, ["Value", item.toLowerCase()]);
                    if (found) {
                        GenereSliderData.push(found);
                    }
                });
                let all = _find(genreFilters, ["Value", "all"]);
                if (all) {
                    GenereSliderData.unshift(all);
                }
                if(!this.state.selectedGenre){ //first time
                   this.setInitialGenre(genreFilters); 
                }
                _map(GenereSliderData, (item) => {
                    let isPoster = item.BackgroudImage;
                    if (isPoster) {
                        item["BackgroudImage"] = getCompressedCdnImageUrl(isPoster, GENERE_SMALL_SLIDER_SIZE, HostName);
                    }
                    return item
                });
                this.setState({
                    genreFilters: GenereSliderData,
                    showGenereFiltersSlider: true
                })
            }
        }
    }

    updatePurchaseStatus = async (sectionType, response,actualResponse) => {
        if (this.props.isAuthenticated) {
            await this.checkPurchaseStatusRequirements();
            this.MAPING_STATUS_TYPE = (sectionType === PURCHASE_HISTORY) ? FETCH_PURCHASELIST : sectionType; //imp!
            this.purchaseList = await getPurchaseData(this.USER_ID);
            let updatedMoviesList = _map(response, this.decideLikePurchasedStatus);
            let updatedAllMoviesList = _map(actualResponse, this.decideLikePurchasedStatus);
            this.setState({
                moviesList: updatedMoviesList,
                allmoviesList: updatedAllMoviesList,
                showGenreMovieTags: true
            })
        } else {
            this.setState({
                moviesList: response,
                allmoviesList: actualResponse,
            })
        }
    }

    checkDataFromCache = async (sectionType, uni, uniqueByKey) => {
        this.USER_ID = this.props.UserID;
        let response;
        let status = (sectionType === PURCHASE_HISTORY) ? STORED_MY_PURCHASES : (sectionType === WATCHED) ? STORED_MY_WATCHED_LIST : STORED_MY_LIST;
        if (status === PURCHASE_HISTORY) {
            response = await getPurchaseData(this.USER_ID);// response = uniqueBy(response, uniqueByKey);
            this.purchaseList = response;
        } else {
            response = getDataFromLocalStorage(status, this.USER_ID);
            response = uniqueBy(response, uniqueByKey);
        }
        if (response) {
            response = applySorting(response, sectionType,this.state.selectedSort)
            this.setState({
                isLoading: false,
                moviesList: response,
                allmoviesList: response,
                isError: ""
            })
        }
        return response;
    }

    handleMovieClick = (movieID) => { } //required

    handleFetchMoreClick = () => {
        this.setState({
            isLoadingMore: true
        });
        this.fetchData(this.props.match.params.id, true);
    }

    handleFilterItemClick = (Name, Value) => {
        let filteredResult = getFilteredDataByGenre(this.state.allmoviesList, Value);
        if (this.state.selectedSort) {
            filteredResult = getSortedData(filteredResult, this.state.selectedSort);
        }
        this.setState({
            moviesList: filteredResult,
            selectedGenre : Value
        });
        this.updateUrl(this.state.selectedSort,Value);
    }

    handleOnSortClick = (name, value) => {
        let sortedData = getSortedData(this.state.moviesList, value);
        this.setState({
            moviesList: sortedData,
            selectedSort: value
            });
        this.updateUrl(value,this.state.selectedGenre);
    }

    updateUrl = (sort,genre)=>{
        let sectionname = querystring("name");
        let id = this.props.match.params.id;
        let type = this.props.match.params.type; 
        let filteredtext  = querystring("filtered") ? `&filtered=${true}` : ``;
        let sortTypeText = sort ? `&sort=${sort}` : ``;
        let selectedGenreText = genre ? `&genre=${genre}` : ``;
        this.props.history.replace(`${ROUTES.GENRE}${type}/${id}?name=${sectionname}${filteredtext}${selectedGenreText}${sortTypeText}`);
    }

    render() {
        return (
            <GenereView
                handleFilterItemClick={this.handleFilterItemClick}
                handleFetchMoreClick={this.handleFetchMoreClick}
                handleMovieClick={this.handleMovieClick}
                handleOnSortClick={this.handleOnSortClick}
                sortList = {SortList}
                {...this.props}
                {...this.state}
            />
        )
    }
}

export default Genere;