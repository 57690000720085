import React from 'react';
import { Modal } from "reactstrap";
import { getVideoQualityData } from "../../helpers/MoviesHelper";
import { EST } from "../../constants/constants";
import MyRadioButton from "../MyRadioButton/MyRadioButton";
import PropTypes from 'prop-types';
import _reverse from "lodash/reverse";
import config from '../../config/config';
import PurchaseButton from "./components/PurchaseButton";
import { withAppConfig } from '../../context/ConfigContext';
import { withAuth } from '../../context/AuthContext';
import { withRouter } from "react-router-dom";

const PRICE = "price"; const TIERID = "teirId"; const CURRENCYCODE = "currencyCode"; const DISPLAYNAME = "displayName";

class PurchaseModal extends React.Component { //this is later version of PurchaseModal ,, deleted that later!!
    isCompMounted = true;
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: this.getInitialTeirId(),
            modalBackDrop: true
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.movieId !== prevProps.movieId) {//this.getInitialTeirId()
            let data = getVideoQualityData(this.props.data, this.props.movieId, this.props.type);
            data = _reverse(data);
            let selected = data[0].teirId;
            this.setState({
                selectedOption: selected
            });
            this.props.handleSelectedQualityVideo(selected);
        }
    }

    getInitialTeirId = () => { //same used in compdidUpdate
        if (this.props.movieId !== "") {
            let selected = this.props.type === EST ? this.props.data.BuyParams ? this.props.data.BuyParams[0].ID ? this.props.data.BuyParams[0].ID : "" : "" : this.props.data.RentParams ? this.props.data.RentParams[0].ID ? this.props.data.RentParams[0].ID : "" : "";
            this.props.handleSelectedQualityVideo(selected);
            return selected;
        }
        return "";
    };

    componentWillUnmount() {
        this.isCompMounted = false;
    }

    handleOptionChange = (value) => {
        this.setState({
            selectedOption: value
        });
        this.props.handleSelectedQualityVideo(value);
    };

    toggleModalbackDrop = () => {
        this.setState((prevState) => {
            return {
                modalBackDrop: !prevState.modalBackDrop
            }
        })
    }

    getSubtitle = () => {
        const { type, appTranslations: tr } = this.props;
        if (type === EST) {
            return tr.buy_movie_for_lifetime || "Buy Movie For lifetime"
        }
        let text = tr.viewing_period_available_for || "viewing period available for";
        const { RentTvodValidity = ``, RentMaxValidity = `` } = this.props.data;
        let RentTvodValidityText = `${RentTvodValidity} ${RentTvodValidity > 1 ? tr.Hours || "Hours" : tr.Hour || "Hour"} `;
        if (config.customSettings.isStuffpixEnabled) {
            let RentMaxValidityText = `${RentMaxValidity} ${tr.days || "days"}`;
            return `${RentTvodValidityText} ${text} ${RentMaxValidityText}`;
        }
        return `${RentTvodValidityText} ${tr.rental || "Rental"}`
    }

    renderQualityItems = () => {
        let qualityArray = getVideoQualityData(this.props.data, this.props.movieId, this.props.type);
        qualityArray = _reverse(qualityArray);
        if (qualityArray.length !== 0) {
            return (
                qualityArray.map((quality) => {
                    return (
                        <li key={quality[TIERID]}>
                            <MyRadioButton
                                name={quality[TIERID]}
                                outerClassName="custom-control custom-radio"
                                innerClassName="custom-control-input"
                                label={`${quality[DISPLAYNAME]} - ${quality[CURRENCYCODE]}${quality[PRICE]}`}
                                id={quality[TIERID]}
                                selectedOption={this.state.selectedOption}
                                handleOptionChange={this.handleOptionChange}
                            />
                        </li>)
                })
            )
        }
        return null;
    }

    renderPurchaseModal() {
        const { movieId, history, type, data, showLoader, showAlert, isAuthenticated, dismissPurchaseModal, appTranslations: tr } = this.props;
        return (
            <div className="mn-purchase-modal">
                {showLoader ? <span className="loader-spiral mn-white mn-transparent"><span className="spiral-inn"></span></span> : null}
                <div className="modal-header">
                    <h5 className="modal-title font-16 text-uppercase">Rent <span className="font-weight-light">Movie</span></h5>
                    <span className="mn-close" onClick={this.props.handleBackDropClick}></span>
                </div>
                <div className="modal-body py-5">
                    {/* <div className="my-5">{tr.please_select_the_video_quality || "Please Select the Video Quality"}</div> */}
                    <div className="mb-5 text-center font-16">Choose your <span className="font-weight-bold">Video Quality</span> to continue to checkout</div>
                    <div className="row">
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <div className="row justify-content-center">
                                <div className="col-7">
                                    <div className="mn-figure">
                                        <div className="mn-thumb img-bg img-1x15 img-fluid" style={{ backgroundImage: `url(${this.props.data.MoviePosterURL || this.props.data.PosterURL})` }}></div>
                                        <div className="mn-premium"><i className="las la-crown"></i></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-6">
                            <div className="text-white-70 mb-3">{this.getSubtitle()}</div>
                            <ul className="rent-list text-white-70">
                                {this.renderQualityItems()}
                            </ul>
                            <PurchaseButton
                                isAuthenticated={isAuthenticated}
                                configurations={this.props.configurations}
                                fetchPurchases={this.props.fetchPurchases}
                                dismissPurchaseModal={dismissPurchaseModal}
                                movieId={movieId}
                                teirId={this.state.selectedOption}
                                type={type}
                                data={data}
                                purchaseParams={type === EST ? data.BuyParams : data.RentParams}
                                toggleModalbackDrop={this.toggleModalbackDrop}
                                showAlert={showAlert}
                                appSettings={this.props.appSettings}
                                history={this.props.history}
                                appTranslations={tr}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <Modal
                isOpen={this.props.showPurchaseModel}
                backdrop={this.state.modalBackDrop}
                toggle={this.props.handleBackDropClick}
                size="lg"
                centered modalClassName="modal-dark" >
                {this.renderPurchaseModal()}
            </Modal>
        )
    }
}

PurchaseModal.defaultProps = {
    data: [],
    movieId: "",
    showLoader: false,
}

PurchaseModal.propTypes = {
    handleSelectedQualityVideo: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    showAlert: PropTypes.func,
    data: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]).isRequired,
    appSettings: PropTypes.object,
    movieId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    purchaseParams: PropTypes.array,
    showPurchaseModel: PropTypes.bool.isRequired,
    handleBackDropClick: PropTypes.func.isRequired,
    dismissPurchaseModal: PropTypes.func.isRequired
};
export default withRouter(withAppConfig(withAuth(PurchaseModal)));
