import { GET } from "../../api";
import _isEmpty from "lodash/isEmpty";
import _concat from "lodash/concat";
import _map from "lodash/map";
import * as constants from "../../constants/constants";
import { storeDataInLocalStore, getDataFromLocalStorage, ping } from "../../helpers/GeneralHelpers";
import { handleImageResizing } from "../../helpers/CDNhelpers";
const purchaseLimit =   50;

export async function getPurchaseList(callback, { CdnUrl , UserId , bypassCache = false }) {
  let purchaseList = [];
  if (!bypassCache) {
    purchaseList = getDataFromLocalStorage("myPurchases", UserId);
    if (purchaseList) {
      callback(null, purchaseList);
      return;
    }
  }
  purchaseList = await fetchFullPurchaseList(UserId);
  purchaseList = await handleImageResizing(purchaseList, "PurchaseList", CdnUrl);
  callback(null, purchaseList);
}

export function fetchFullPurchaseList(UserId) {
  let path = `/purchaseHistory?ExclusiveStartKey=&Limit=${purchaseLimit}`;
  return fetchDataRecursively("OrdersAPI", "purchaseHistory", path, [], constants.FETCH_PURCHASELIST, "").then(async(finalApiData) => {
      if (UserId) {
      if (!_isEmpty(finalApiData)) {
        let moment = await import("moment").then((moment) => {
          moment= moment.default;
          return moment;
      });
      let currentTime = await ping();
      finalApiData = _map(finalApiData, (movie) => {
          movie["HideExpiredRental_"] = checkExpiredRentalStatus(movie, currentTime, moment);
          return movie;
      })
        storeDataInLocalStore("myPurchases", finalApiData, UserId, constants.NO_EXPIRY);
      }
    }
    return finalApiData;
  })
}

export function fetchDataRecursively(apiname, pathName, apipath, DATA, status, LASTEVALUATEDKEY) {
  let Limit = 50;
  return fetchAPIData(apiname, apipath, DATA, status, LASTEVALUATEDKEY, true).then((DATALIST) => {
      let responseLastEvaluatedKey = ((!_isEmpty(DATALIST.response)) && DATALIST.response.body && DATALIST.response.body.LastEvaluatedKey ) ? DATALIST.response.body.LastEvaluatedKey : null;
      if (!responseLastEvaluatedKey) {
          return DATALIST.data
      }
      let path = `/${pathName}?ExclusiveStartKey=${responseLastEvaluatedKey ? responseLastEvaluatedKey : ``}&Limit=${Limit}`;
      return fetchDataRecursively(apiname, pathName, path, DATALIST.data, status, responseLastEvaluatedKey)
  })
}

export function fetchAPIData(apiname, apipath, data) {
  return GET(apiname, apipath).then( (response) => {
    let concated = _concat(data, response.body.Items);
    return { data: concated, response: response };
  }).catch((err) => {
      return { data: [], response: [] };
  });
}

function checkExpiredRentalStatus(movie, currentTime, moment) {
  let isRentalExpired = false; let movieMaxValid;
  if (moment && movie && currentTime) {
      if (movie.ValidUntil !== "N-A") {
          movieMaxValid = movie.ValidUntil
      } else {
          movieMaxValid = movie.RentMaxValidity
      }
      if (movieMaxValid) {
          let hoursDiff = moment(currentTime).diff(moment(movieMaxValid), 'hours');
          if (hoursDiff && hoursDiff > 0) {
              isRentalExpired = true;
          }
      }
  }
  return isRentalExpired;
}