import React from 'react'

const Voucher = (props) => {
    if(!props.Voucher){
        return null;
    }
    return (
        <div className="pt-3 mt-3 border-top border-dark">
            <div className="row gutter-5">
                <div className="col-6 col-sm-3">
                    <div className="font-weight-normal">{props.appTranslations.promotion || "Promotion"}</div>
                </div>
                <div className="col-sm-6 text-center d-none d-sm-block">
                    <span>{props.appTranslations.reward_code} : </span>
                    <label className="badge badge-secondary m-0">{props.Voucher}</label>
                </div>
                <div className="col-6 col-sm-3 text-right">
                    ${(props.Discount || props.Discount === 0) && props.Discount}
                </div>
            </div>
        </div>
    )
}

export default Voucher
