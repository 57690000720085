import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';

class CreditCardNumber extends Component {
    state = {
        trimmedCardNumber: "****",
        cardType: ""
    };

    componentDidMount() {
        const { cardNumber, cardType } = this.props;
        if (cardNumber) {
            let lastFour = cardNumber.substring(cardNumber.length - 4, cardNumber.length)
            this.setState({
                trimmedCardNumber: lastFour
            })
        }
        this.getCardType(cardType);
    }

    getCardType = (cardType) => {
        let cardtype = ""
        switch (cardType) {
            case "Visa":
                cardtype = "la la-cc-visa";
                break;
            case "Mastercard":
                cardtype = "la la-cc-mastercard";
                break;
            case "Amex":
            case "American Express":
                cardtype = "la la-cc-amex";
                break;
            case "Discover":
                cardtype = "la la-cc-discover";
                break;
            case "JCB":
                cardtype = "la la-cc-jcb";
                break;
            case "Diners":
                cardtype = "la la-cc-diners-club";
                break;
            case "Maestro":
                cardtype = "la la-cc-maestro";
                break;
            case "UnionPay":
                cardtype = "la la-cc-unionpay";
                break;
            default:
                cardtype = "la la-credit-card"
                break;
        }
        this.setState({
            cardType: cardtype
        })
    }

    renderCardType = () => {
        return (
            <span className="font-20"><i className={`${this.state.cardType} ${this.props.cardTypefont}`} ></i></span>
        )
    }

    renderCardStars = () => {
        return (
            <Fragment>
                <span className="mx-2 ls-4">
                    &bull;
                    &bull;
                    &bull;
                    &bull;
                </span>
                <span className="mx-2 ls-4">
                    &bull;
                    &bull;
                    &bull;
                    &bull;
                </span>
                <span className="mx-2 ls-4">
                    &bull;
                    &bull;
                    &bull;
                    &bull;
                </span>
            </Fragment>
        )
    }

    renderCardNumber = () => {
        return (
            <span className="mx-2 font-14">{this.state.trimmedCardNumber}</span>
        )
    }

    render() {
        return (
            <div className="d-flex align-items-center font-30 lh-1">
                {this.renderCardType()}
                {this.renderCardStars()}
                {this.renderCardNumber()}
            </div>
        )
    }
}
export default CreditCardNumber;

CreditCardNumber.defaultProps = {
    cardTypefont: ""
}

CreditCardNumber.propTypes = {
    cardNumber: PropTypes.string.isRequired,
    cardType: PropTypes.string.isRequired,
    cardTypefont: PropTypes.string
}