import React, { useState } from "react";
import { RemoveMovieFromMyList } from "../../../services/user/RemoveMyList";
import { withAppConfig } from "../../../context/ConfigContext";
import { withAuth } from "../../../context/AuthContext";

const DeleteLike = props => {
  const [isRemoving, setDelete] = useState(false);

  const onClick = e => {
    e.preventDefault();
    e.stopPropagation();
    setDelete(true);
    let params = {
      ID: props.ID,
      UserID: props.UserID,
      cdnUrl: props.HostName
    };
    RemoveMovieFromMyList(onAPIresponse, params);
  };

  const onAPIresponse = (err, response) => {
    if (!err) {
      let { appTranslations: tr } = props;
      let removed = tr.removed_from_list || "Removed from My List!";
      props.showAlert("showSuccessAlert", removed);
      props.onDelete(response);
    }
    setDelete(false);
  };

  return (
    <div className="mn-thumb-overlay top">
      {isRemoving ? (
        <i className="la la-spin fa-spinner" />
      ) : (
          <span onClick={onClick} className="btn-del ">
            <i className="la la-trash-o" />
          </span>
      )}
    </div>
  );
};

export default withAppConfig(withAuth(DeleteLike));