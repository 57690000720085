import React from "react";

const TotalPrice = (props) => {
  let price = 0;
  let quality = props.selectedQuality;
  let duration = props.selectedDuration;

  props.data.Plans.forEach((item) => {
    Object.keys(item.pricelist).forEach((q) => {
      if (quality == q && item.duration == duration) {
        price = item.pricelist[q].price;
      }
    });
  });
  return (
    <div className="mn-cart-row row text-white font-weight-bold">
      <div className="mn-cart-cell col-6 col-md-4 offset-md-6">
        {props.appTranslations.payable_amount  || "Payable Amount"}
      </div>
      <div className="mn-cart-cell col-6 col-md-2 text-right text-md-left">{props.appSettings.CurrencySymbol}{price}</div>
    </div>
  );
};

export default TotalPrice;
