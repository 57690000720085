import React, { Component } from "react";
import PropTypes from 'prop-types';
import RenderScreen from "../../../../components/RenderScreen/RenderScreen";
import { isEmpty } from "../../../../helpers/GeneralHelpers";
import AcceptedCreditCards from "../../components/AcceptedCreditCards";
import config from "../../../../config/config";
import SavedCardPanel from "../../components/SavedCardPanel";
import PaymentCardPanel from "./PaymentCardPanel";
import { withPaymentCard } from "../../../../context/PaymentCardContext";
import { withAppConfig } from "../../../../context/ConfigContext";

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

class PaymentMethod extends Component {
    state = {
        selectedPanelId: "formpanel",
        activeTab: '1'
    }
    componentDidMount() {
        this.props.getCardsList(true);
        if (this.props.defaultCardID !== this.state.selectedPanelId) {
            this.setState({
                selectedPanelId: this.props.defaultCardID
            })
        }
    }

    onPanelToggle = (id) => {
        if (id !== this.state.selectedPanelId) {
            this.setState({
                selectedPanelId: id
            })
        }
    }

    renderPaymentMethod() {
        const { acceptable_credit_card_types = ``, pay_with_card = `` } = this.props.appTranslations;
        let active = false;
        let currencySymbol = (this.props.movieData && this.props.movieData.Params && this.props.movieData.Params.length > 0 && this.props.movieData.Params[0].CurrencySymbol) ? this.props.movieData.Params[0].CurrencySymbol : " ";

        return (
            <section className="mn-pay-method pb-5">
                <div className="container">
                    {(!isEmpty(this.props.paymentGateway)) &&
                        this.props.paymentGateway.map((gateway, index) => {
                            if (active === false) {
                                if (gateway.Status.toLowerCase() === "active") {
                                    active = true;
                                    return (
                                        <div key={index} className="text-center mb-3">
                                            {/* {pay_with_card || "Pay with Card"} */}
                                            <h3 className="mb-4">Payment Method</h3>
                                            {/* <div className="text-white-50 mb-3">{acceptable_credit_card_types || "Accepted Credit Card Types"}</div>
                                            <AcceptedCreditCards
                                                showAllCards={!config.customSettings.isStuffpixEnabled}
                                            /> */}
                                        </div>
                                    )
                                }
                            }
                        })
                    }
                    {/* <div className="pay-method-body"> */}
                        {/* <div className="row no-gutters" style={{ minHeight: "450px" }}>
                            <div className="col-md-3 bg-gray-700"> */}
                                {/* <Nav pills className="flex-column vertical-pills"> */}
                                    {/* <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '1' })}
                                            onClick={() => { this.toggle('1'); }}
                                        >
                                            <i className="las la-credit-card mr-2 font-18"></i> Credit Card
          </NavLink>
                                    </NavItem> */}
                                    {/* <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === '2' })}
                                            onClick={() => { this.toggle('2'); }}
                                        >
                                            <i className="las la-credit-card mr-2 font-18"></i> G Cash
          </NavLink>
                                    </NavItem> */}
                                {/* </Nav> */}
                            {/* </div> */}
                            <div className="">
                                {/* <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1"> */}
                                        <div id="paymentOptionGroup">
                                            {(this.props.cardsList && this.props.cardsList.length > 0) &&
                                                this.props.cardsList.map((paymentcard, index) => {
                                                    return (
                                                        <SavedCardPanel
                                                            key={index}
                                                            isPanelSelected={this.state.selectedPanelId === paymentcard.ID}
                                                            isDefault={paymentcard.Default}
                                                            card={paymentcard}
                                                            cardId={paymentcard.ID}
                                                            showLoader={false}
                                                            purchaseType={this.props.purchaseType}
                                                            getPaymentParams={this.props.getPaymentParams}
                                                            price={`${currencySymbol}${this.props.price}`}
                                                            onClickPaywithCard={this.props.handleCheckout}
                                                            onPanelToggle={this.onPanelToggle}
                                                            disablePayButton={this.props.isCheckingout}
                                                            transactionSubmitted={this.props.transactionSubmitted}
                                                        />
                                                    )
                                                })
                                            }
                                            <PaymentCardPanel
                                                price={`${currencySymbol}${this.props.price}`}
                                                onClickPaymentForm={this.props.handleCheckout}
                                                isCheckingout={this.props.isCheckingout}
                                                // clientToken={this.props.clientToken}
                                                purchaseType={this.props.purchaseType}
                                                isPanelSelected={this.state.selectedPanelId === "formpanel"}
                                                paymentGateway={this.props.paymentGateway}
                                                getPaymentParams={this.props.getPaymentParams}
                                                transactionSubmitted={this.props.transactionSubmitted}
                                                onPanelToggle={this.onPanelToggle}
                                            />
                                        </div>
                                    {/* </TabPane> */}
                                    {/* <TabPane tabId="2">
                                        G Cash Payment Forms
                                    </TabPane> */}
                                {/* </TabContent> */}
                            {/* </div> */}
                        {/* </div> */}
                    </div>
                </div>
            </section>
        )
    }

    toggle = tab => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            })
        }
    }

    render() {
        return (
            this.renderPaymentMethod()
            // <RenderScreen
            //     isLoading={false}//this.props.isLoadingToken}
            //     isError={this.props.errorToken}>
            //     {this.renderPaymentMethod()}
            // </RenderScreen>
        );
    }
}

PaymentMethod.propTypes = {
    // isLoadingToken: PropTypes.bool.isRequired,
    isCheckingout: PropTypes.bool.isRequired,
    errorToken: PropTypes.string,
    movieID: PropTypes.string.isRequired,
    movieData: PropTypes.object.isRequired,
    handleCheckout: PropTypes.func.isRequired
};
export default withPaymentCard(withAppConfig(PaymentMethod));