import React from "react";
import { Route, Redirect } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
import { DEVICES, DEVICES_REDIRECT, REDIRECT_URL, SOCIALSIGNIN, SIGNOUT } from "../constants/routes";
import { querystring } from "../helpers/GeneralHelpers";
const AuthenticatedRoute = ({ component: C, props: cProps, ...rest }) => {
    let redirect = querystring("redirect");
    return (
        <Route
            {...rest}
            defaultParams={{page: 1}}
            render={props => {
                if (props.location.pathname === SOCIALSIGNIN || props.location.pathname === SIGNOUT) { //remove this if after aith problem solves
                    return (<ErrorBoundary><C {...props} {...cProps} /></ErrorBoundary>)
                } else if (cProps.isAuthenticated && !cProps.isDeviceLimitReached && redirect !== null) {
                    return (<Redirect to={redirect === "" || redirect === null ? "/home" : redirect} />)
                } else if (cProps.isAuthenticated && !cProps.isDeviceLimitReached) {
                    return (<ErrorBoundary><C {...props} {...cProps} /></ErrorBoundary>)
                } else if (cProps.isAuthenticated && cProps.isDeviceLimitReached && props.location.pathname === DEVICES) {
                    return (<ErrorBoundary><C {...props} {...cProps} /></ErrorBoundary>)
                } else if (cProps.isAuthenticated && cProps.isDeviceLimitReached) {
                    return <Redirect to={`${DEVICES_REDIRECT}${props.location.pathname}${props.location.search}`} />
                } else {
                    return <Redirect to={`${REDIRECT_URL}${props.location.pathname}${props.location.search}`} />
                }
            }
            }
        />
    )
}

export default AuthenticatedRoute; 