import React, { useState, useEffect, memo } from "react";
import Loader from "../../../components/Loader/Loader";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";
import getScreeningRooms from "../../../services/movies/GetScreeningRooms";
import Header from "./components/Header";
import Listing from "./components/Listing";
import { withAuth } from "../../../context/AuthContext";

const AllRooms = memo(({ HostName, ...props }) => {
  const [Data, setData] = useState({});
  const [loading, setLoading] = useState({
    isLoading: true,
    isLoadingMore: false
  });

  useEffect(() => {
    initialize();
    return () => {};
  }, [props.subscriptions]);

  const initialize = async () => {
    let params = {
      HostName: HostName,
      subscriptions: props.subscriptions,
      isAuthenticated: props.isAuthenticated,
      Items: Data.Items ? Data.Items : [],
      Limit: 10,
      LastEvaluatedKey: Data.LastEvaluatedKey ? Data.LastEvaluatedKey : ``
    };
    getScreeningRooms((response, err) => {
      if (!err) {
        setData(response);
      }
      setLoading({
        isLoading: false,
        isLoadingMore: false
      });
    }, params);
  };

  const fetchMoreData = () => {
    setLoading({
      ...loading,
      isLoadingMore: true
    });
    initialize();
  };

  return (
    <main className="mn-main">
      <Header />
      {loading.isLoading ? (
        <Loader />
      ) : Data && Data.Items && Data.Items.length > 1 ? (
        <Listing Data={Data} loading={loading} fetchMoreData={fetchMoreData} />
      ) : (
        <DataNotFound text="No Channels available"/>
      )}
    </main>
  );
});

export default withAuth(AllRooms);