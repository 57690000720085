import React, { Component } from 'react';
import PropTypes from "prop-types";
import { getPlayerConfig } from '../../helpers/BitmovinHelper';
import { PlayerKeyboardControl } from "./PlayerKeyboardControl";
import RelatedMovies from '../../containers/RelatedMovies/RelatedMovies';
import playStart from "../../assets/images/svg/player/play-start.svg";
import {withFirebase} from "../../context/FirebaseContext";
import { withAppConfig } from '../../context/ConfigContext';

class BitmovinPlayer extends Component {
    isCompMounted = true
    showPlayFromBeginningTimeout = null;
    state = {
        isScreenFilled: false,
        bitmovinFullscreen: false,
        showPlayFromBeginning: false,
    }

    componentDidMount() {
        this.initialize();
    }
    componentWillUnmount() {
        try {
            this.onPlayerDestroyed(this.player.getCurrentTime())
        } catch (e) {
            //couldn't get duration!
        }
        this.isCompMounted = false;
        if (this.player) {
            this.player.destroy();
        }
    }

    initialize = async () => {
        //window.addEventListener("beforeunload", this.onPlayerDestroyed);
        const { movieStream, licenceUrl, title, posterUrl, playerStartingTime, enableCast, autoplay, subtitleTracks } = this.props;
        let playerConfiguration = await getPlayerConfig(movieStream, licenceUrl,subtitleTracks, title, posterUrl, playerStartingTime, enableCast, autoplay);
        this.initPlayer(playerConfiguration);
    }

    onPlayerDestroyed = (playerDuration) => {
        playerDuration = playerDuration ? playerDuration : this.player ? this.player.getCurrentTime() : 0;
        this.props.onPlayerDestroyed(playerDuration);
    }

    initPlayer = (playerConfiguration) => {
        import("bitmovin-player").then(async (bitmovin) => {
            const { Player , PlayerEvent } = bitmovin;
            const container = document.getElementById('player');
            this.PlayerEvent = PlayerEvent;
            this.player = new Player(container, playerConfiguration);
            this.player.load(playerConfiguration.source).then((player)=>{
                this.addPlayerEvents();
                new PlayerKeyboardControl({ Player : this.player , PlayerEvent : this.PlayerEvent });
            });
        }).catch((err) => {
            this.props.onError(err);
        });

        document.addEventListener('mousemove', this.mouseMovementHandler, false);

    }

    toggleFillScreen = () => {
        this.setState((prevState) => {
            return {
                isScreenFilled: !prevState.isScreenFilled
            }
        })
    }

    addPlayerEvents() {
        let fillButton = document.getElementsByClassName("bmpui-ui-piptogglebutton");
        if (fillButton) {
            let pip = fillButton.item(0);
            if (pip) {
                pip.addEventListener("click", () => {
                    this.toggleFillScreen();
                })
            }
        }
        this.player.on(this.PlayerEvent.Play, () => { //is not being called in case of autoplay!!
            this.props.onVideoPlay(this.player.getCurrentTime());
        });
        this.player.on(this.PlayerEvent.Paused, () => {
            this.props.onVideoPause(this.player.getCurrentTime(), this.player.getDuration());
        });
        this.player.on(this.PlayerEvent.Error, (err) => {
            this.props.onVideoError(err)
        });
        this.player.on(this.PlayerEvent.PlaybackFinished, () => {
            this.props.onVideoEnd(this.player.getCurrentTime())
        });
        this.player.on(this.PlayerEvent.ViewModeChanged,(mode)=>{
            if(mode.to === "inline"){
                this.setState({
                    bitmovinFullscreen: false,
                });
            }
            if(mode.to === "fullscreen"){
                this.setState({
                    bitmovinFullscreen: true,
                })
            }
        });
    }

    renderSuggestionView() {
        return (
            <div className="mn-media-btm">
                <div className="media-suggestions-wrap show">
                    <div className="media-suggestions d-none d-md-block">
                        <div className="row justify-content-center">
                            <RelatedMovies
                                showMovieSuggestions={this.props.showMovieSuggestions}
                                viewType="PLAYER_SUGGESTIONS"
                                movieId={this.props.movieId}
                                handleMovieClick={this.props.handleMovieClick}
                                isAuthenticated={this.props.isAuthenticated}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onClickRewind = () =>{
        if (this.player.getCurrentTime() !== 0) {
           this.player.seek(0);
        }
    }

    mouseMovementHandler = () => {

        clearTimeout(this.showPlayFromBeginningTimeout);
        this.setState({showPlayFromBeginning: true});
        const subtitleContainer = document.getElementsByClassName('bmpui-ui-subtitle-overlay');
        if(subtitleContainer[0] !== undefined)
        {
        subtitleContainer[0].style.bottom = '100px';

        this.showPlayFromBeginningTimeout = setTimeout(()=>{
            this.setState({showPlayFromBeginning: false});
            subtitleContainer[0].style.bottom = '0';
        },2000)
    }
    }

    render() {
        const { appTranslations : tr } = this.props;
        return (
            <div className="mn-media-player">
                <div id="player" className={`mn-player ${this.state.isScreenFilled ? `mn-player-fill` : ``}`}>
                    {!this.state.bitmovinFullscreen && this.state.showPlayFromBeginning &&
                    <div className="mn-ext-control d-none d-md-block">
                        <button className="btn btn-secondary" onClick={this.onClickRewind}><img width="32" className="mr-2" src={playStart}/>{tr.play_from_beginning}</button>
                    </div>
                    }
                    {(this.props.showMovieSuggestions && this.props.movieId) &&
                        this.renderSuggestionView()}
                </div>
            </div>
        )
    }
}

export default withFirebase(withAppConfig(BitmovinPlayer));
BitmovinPlayer.defaultProps = {
    title: "",
    posterUrl: null,
    playerStartingTime: 0,
    enableCast: true,
    autoplay: true,
    showMovieSuggestions: false,
    isMoviePlayer: false
}
BitmovinPlayer.propTypes = {
    title: PropTypes.string,
    isMoviePlayer: PropTypes.bool,
    movieStream: PropTypes.string.isRequired,
    licenceUrl: PropTypes.string.isRequired,
    posterUrl: PropTypes.string,
    playerStartingTime: PropTypes.number,
    castApplicationID: PropTypes.string,
    enableCast: PropTypes.bool,
    autoplay: PropTypes.bool,
    onVideoPlay: PropTypes.func,
    onVideoPause: PropTypes.func,
    onVideoError: PropTypes.func,
    onVideoEnd: PropTypes.func,
    onError: PropTypes.func,
    onPlayerDestroyed: PropTypes.func,
    showMovieSuggestions: PropTypes.bool,
    movieId: PropTypes.string,
    handleMovieClick: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    subtitleTracks: PropTypes.any
}
