import React, { Component, Fragment } from "react";
import MyInput from "../../components/MyInput";
import { Link } from "react-router-dom";
import Formsy from 'formsy-react';
import Auth from "@aws-amplify/auth";
import defaultLogo from "../../assets/images/muvinow-logo.png";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import { showError, setDocumentTitle, isEmpty } from "../../helpers/GeneralHelpers";
import { handleChange, enableButton, disableButton, disableAlert } from "../../helpers/StateHelper";
import { PARTNER_SIGNUP_PAGE, PARTNER_ACCOUNT, REWARD } from "../../constants/constants";
import { getValidPartnerData, setPartnerTheme } from "../../helpers/PartnerHelpers";
import { getInputFields } from "./constants";
import { withAppConfig } from "../../context/ConfigContext";
import { withFirebase } from "../../context/FirebaseContext";
import withUserSession from "../../hoc/UserSession";
import { TERMS_CONDITIONS } from "../../constants/routes";

class PartnerSignUp extends Component {
    partnerTheme = {}
    resetTheme = true
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            email: "",
            password: "",
            confirmPassword: "",
            canSubmit: false,
            errorMessage: "",
            showErrorAlert: false,
            isChecked: false,
            isValidPartner: false,
            partnerData: {}
        };
        this.disableAlert = disableAlert.bind(this);
        this.showError = showError.bind(this);
        this.handleChange = handleChange.bind(this);
        this.enableButton = enableButton.bind(this);
        this.disableButton = disableButton.bind(this);
        this.setPartnerTheme = setPartnerTheme.bind(this)
    }

    componentDidMount() {
        this.verifyIsPartnerValid();
        setDocumentTitle(PARTNER_SIGNUP_PAGE);
        if (this.props.location.state && this.props.location.state.email && this.props.location.state && this.props.location.state.password) {
            this.setState({ email: this.props.location.state.email, password: this.props.location.state.password });
        }
    }

    componentWillUnmount() {
        if (this.resetTheme) {
            const { Logo = defaultLogo } = this.props.appSettings;
            let partner = { Theme: {} };
            this.setPartnerTheme(Logo, partner, true);
        }
    }

    verifyIsPartnerValid = async () => {
        let partnerObj = await getValidPartnerData(this.props.match.params.name);
        if (partnerObj  && (partnerObj.Status !== "inactive")) {
            const { Logo = defaultLogo } = this.props.appSettings;
            this.setPartnerTheme(Logo, partnerObj);
            this.setState({
                isValidPartner: true,
                partnerData: partnerObj
            })
        } else {
            this.props.history.push("/signup");
        }
    };

    handleSubmit = async () => {
        const { appTranslations : tr } = this.props;

        this.setState({ isLoading: true });
        try {
            await Auth.signUp({
                username: this.state.email.toLowerCase(),
                password: this.state.password,
                attributes: {
                    'custom:AccountType': PARTNER_ACCOUNT,
                    'custom:PartnerName': this.props.match.params.name
                }
            });
            if(this.props.appSettings && this.props.appSettings.EmailLinkVerification === "true" || this.props.appSettings.EmailLinkVerification === true){
                this.onSignupSuccess();
            } else {
                await Auth.signIn(this.state.email.toLowerCase(), this.state.password);
                this.setState({ isLoading: false });
                this.props.updateAuthStatus(true, false);
                this.props.updateUserData();
                this.props.createSession();
                this.resetTheme = true;
                if (this.props.match.params.name === REWARD) { //replace it with generic partner {
                    this.props.history.push(`/${REWARD}`);
                } else {
                    this.props.history.push(`/`);
                }
            }
        } catch (error) {
            let message = error.message;
            if (error.code === 'UserNotConfirmedException') {
                this.props.history.push('/confirm', { email: this.state.email, password: this.state.password });
            } else {
                if (error.code === "InvalidParameterException") {
                    message = tr.error_invalid_password;
                }
                if(error.code === "UsernameExistsException" && error.message === "An account with the given email already exists."){
                    message = tr.error_an_account_with_the_given_email_al || "Email address is invalid";
                }
                this.setState({
                    isLoading: false,
                    showErrorAlert: true,
                    errorMessage: message
                });
                this.disableAlert("showErrorAlert");
            }
        }
    }

    onSignupSuccess = () =>{
        this.props.showAlert("showSuccessAlert","Verification Email has been sent to your Email Address. Please Verify your account by clicking the link.")
        this.props.history.push('/login');
    }
    
    handleCheckbox = () => {
        this.setState((prevState) => {
            return {
                isChecked: !prevState.isChecked
            }
        });
    }

    renderPartnerThemeCustomStyle() {
        let { primary_color } = this.partnerTheme;
        if (!isEmpty(this.partnerTheme)) {
            let styleCode = `.btn-primary, .btn-primary.disabled, .btn-primary:disabled, .btn-primary:hover, .btn-primary:not(:disabled):not(.disabled):active { background:${primary_color}; border-color:${primary_color}; color:#FFF; }
            .btn-primary:focus, .btn-primary.focus, .btn-primary:not(:disabled):not(.disabled):active:focus { box-shadow:none}
            `
            return (
                <style>{styleCode}</style>
            )
        }
    }

    renderForm() {
        const { sign_up, signing_up } = this.props.appTranslations;
        let InputFields = getInputFields(this.props.appTranslations);
        let termsText = this.props.appTranslations.agree_to_our_terms_conditions || `Agree to our $ Terms &amp; Conditions.`;
        termsText = termsText.split('$');

        return (
            <Fragment>
                <h3 className="text-uppercase mb-3">{sign_up}</h3>
                {this.state.showErrorAlert &&
                    <div className="errorContainer">
                        {this.showError(null, this.state.errorMessage)}
                    </div>
                }
                {this.state.isLoading && <span className="loader-spiral mn-white mn-white-transparent"><span className="spiral-inn"></span></span>}
                <Formsy onValidSubmit={this.handleSubmit} onValid={this.enableButton} onInvalid={this.disableButton}>
                    {InputFields && InputFields.map((input, index) => {
                        const { Name, Type, Placeholder, ValidationError, Validations, ValidationErrors } = input;
                        return (
                            <MyInput
                                key={index}
                                id={Name}
                                name={Name}
                                placeholder={Placeholder}
                                validations={Validations}
                                validationError={ValidationError}
                                validationErrors={ValidationErrors}
                                type={Type}
                                required
                                outerClassName="mb-4"
                                handleChange={this.handleChange}
                            />
                        )
                    })}
                    <div className="form-group font-12 font-weight-normal">
                        <div className="form-check-inline">
                            <label className="form-check-label">
                                <input type="checkbox" onClick={this.handleCheckbox} className="form-check-input align-middle" value={this.state.isChecked} />
                                {termsText[0]}  <Link to={TERMS_CONDITIONS}>{termsText[1] ? termsText[1] : ''}</Link>
                            </label>
                        </div>
                    </div>
                    <LoaderButton
                        disabled={!this.state.canSubmit || !this.state.isChecked || !this.state.isValidPartner}
                        type="submit"
                        className="btn btn-primary btn-block text-uppercase"
                        isLoading={this.state.isLoading}
                        text={sign_up}
                        loadingText={`${signing_up || "Signing up"} …`}
                    />
                </Formsy>
            </Fragment>
        );
    }

    handleSignInClick = () => {//this.resetTheme = false;
        this.props.history.push('/login', { isPartner: true, partnerName: this.props.match.params.name })
    }

    render() {
        const { already_have_partner_account, sign_in_now } = this.props.appTranslations;

        return (
            <main className="mn-main">
                {this.renderPartnerThemeCustomStyle()}
                <section className="mn-login-section py-5">
                    <div className="container">
                        <div className="mn-login-content">
                            <div className="mn-login-body">
                                {this.renderForm()}
                            </div>
                            <div className="mn-login-footer mt-4 text-center">
                                <div>{already_have_partner_account} <a href="javascript:void(0)" onClick={this.handleSignInClick}>{sign_in_now}</a></div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}
export default withAppConfig(withFirebase(withUserSession(PartnerSignUp)));
