import React from "react";

const LoaderButton = ({ isLoading, text, loadingText, className = "", disabled = false, icon = null, ...props }) =>
    <button
        className={`${className}`}
        disabled={disabled || isLoading}
        {...props}
        onClick={props.onClick}
    >
        {/* {isLoading && <Glyphicon glyph="refresh" className="spinning" />} */}
        {icon !== null && icon}
        {!isLoading ? text : loadingText}
    </button>;
export default LoaderButton;