import React from 'react';
import PropTypes from "prop-types";

const Loader = (props) => (
    <section className="mn-movies py-4 position-relative" style={{ minHeight: `${props.height}` }}>
        <span className={`loader-spiral ${props.customClass}`}><span className="spiral-inn"></span></span>
    </section>
)

Loader.defaultProps = {
    height: "500px",
    customClass: ""
}

Loader.propTypes = {
    height: PropTypes.string
}

export default Loader;