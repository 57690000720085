import React from "react";
import MoviesView from "./components/MoviesView";
import Loader from "../../components/Loader/Loader";
import { getUrlSortGenre, getSortList } from "./helpers";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";
import GenreSlider from "./components/GenreSlider";
import CardsList from "../../components/CardsList/CardList";
import { listMoviesByFilter } from "../../services/movies/ListMoviesByFilter";
import { getPurchaseData, decideLikePurchasedStatus } from "../../helpers/MoviesHelper";
import { getCurrentUserId, ping, importMoment, setDocumentTitle } from "../../helpers/GeneralHelpers";
import _map from "lodash/map";
import { withAuth } from "../../context/AuthContext";

class Movies extends React.PureComponent {
  MAPING_STATUS_TYPE = "Movies";
  isCompMounted = true;
  constructor(props) {
    super(props);
    this.state = {
      isLoadingScreen: true,
      isLoading: true
    };
    this.decideLikePurchasedStatus = decideLikePurchasedStatus.bind(this);
    this.importMoment = importMoment.bind(this);
  }

  componentDidMount() {
    setDocumentTitle("Movies");
    let { genre, sort } = this.updateUrl();
    this.setState({
      selectedSort: sort,
      selectedGenre: genre,
      isLoadingScreen: false
    }, () => {
      this.initialize();
    });
  }

  componentWillUnmount() {
    this.isCompMounted = false;
  }

  initialize = async () => {
    this.SERVERTIME = await ping();
    if (this.props.isAuthenticated) {
      this.UserId = await getCurrentUserId();
      this.moment = await this.importMoment();
      this.purchaseList = await getPurchaseData(this.UserId);
    }
    this.fetchData();
  }

  updateUrl = () => {
    let obj = getUrlSortGenre();
    this.props.history.replace(`/movies?genre=${obj.genre}&sort=${obj.sort}`);
    return obj;
  };

  handleOnSortClick = (name, value) => {
    this.setState({
      selectedSort: value,
      isLoading: true,
      lastEvaluatedKey: "",
      moviesList: []
    }, () => {
      this.props.history.replace(
        `/movies?genre=${this.state.selectedGenre}&sort=${value}`
      );
      this.fetchData();
    }
    );
  };

  handleOnGenreClick = (name, value) => {
    this.setState({
      selectedGenre: value,
      GenreTitle: name,
      isLoading: true,
      lastEvaluatedKey: "",
      moviesList: []
    }, () => {
      this.props.history.replace(
        `/movies?genre=${value}&sort=${this.state.selectedSort}`
      );
      this.fetchData();
    }
    );
  };

  fetchData = () => {
    listMoviesByFilter(this.getRequestParams(), async (response, err) => {
      if (this.isCompMounted) {
        if (!err) {
          let moviesList = response.list;
          if (this.props.isAuthenticated) {
            moviesList = await _map(moviesList, this.decideLikePurchasedStatus);
          }
          this.setState({
            lastEvaluatedKey: response.LastEvaluatedKey,
            moviesList: moviesList
          });
        }
        this.setState({
          isLoading: false,
          isLoadingMore: false
        })
      }
    });
  }

  handleFetchMore = () => {
    this.setState({
      isLoadingMore: true
    }, () => {
      this.fetchData();
    })
  }

  getRequestParams = () => {
    const { lastEvaluatedKey, selectedGenre, selectedSort, moviesList } = this.state;
    return {
      LastEvaluatedKey: lastEvaluatedKey,
      selectedSort: selectedSort,
      selectedGenre: selectedGenre,
      moviesList: moviesList,
      isAuthenticated: this.props.isAuthenticated,
      subscriptions: this.props.subscriptions,
      HostName: this.props.HostName
    }
  }

  render() {
    const { isLoadingScreen, selectedSort, selectedGenre, isLoading, lastEvaluatedKey, GenreTitle, isLoadingMore, moviesList } = this.state;
    if (isLoadingScreen) {
      return (
        <main className="mn-main">
          <Loader />
        </main>
      );
    }
    return (
      <MoviesView
        genreSlider={<GenreSlider
          initialGenre={selectedGenre}
          handleOnGenreClick={this.handleOnGenreClick}
        />}
        dropdown={
          <CustomDropDown
            dataList={getSortList()}
            initialValue={selectedSort}
            onDropDownItemChanged={this.handleOnSortClick}
          />
        }

        listing={
          <CardsList
            isLoading={isLoading}
            isLoadingMore={isLoadingMore}
            data={moviesList}
            lastEvaluatedKey={lastEvaluatedKey}
            title={GenreTitle}
            fetchMoreData={this.handleFetchMore}
            movieCardContainerClass="mn-movies-grid"
            showCardHoverIcons={true}
            ServerTime={this.SERVERTIME}
            isAuthenticated={this.props.isAuthenticated}
          />
        }
      />
    );
  }
}
export default withAuth(Movies);