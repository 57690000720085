import React, { Component } from "react";
import PropTypes from 'prop-types';
import MovieCard from "../../../../components/MovieCard/MovieCard";

class PromotionalMovies extends Component {

    render() {
        const { promotionMovies, showRentedMovieTag, isOfferAvailed } = this.props;
        return (
            <section className="mn-movies pt-4" >
                <div className="container">
                    <div className="text-white mb-3">
                        <span className="mx-1"><img src="https://i.imgur.com/x4PcLfE.png" width="60" /></span>
                        members also get access to these great movies for only $1 each!
                    </div>
                    <div className="row gutter-10 mn-rewards-grid">
                        {(promotionMovies && promotionMovies.length > 0) &&
                            promotionMovies.map((movie, index) => {
                                return (
                                    <MovieCard
                                        className={isOfferAvailed ? "mb-4" : " faded mb-4"}
                                        key={index}
                                        movie={movie}
                                        movieClick={this.props.handleMovieClick}
                                        showCardHoverIcons={showRentedMovieTag}
                                        {...this.props}
                                    />
                                )
                            })
                        }
                    </div>
                </div >
            </section >
        )
    }
}
export default PromotionalMovies;

PromotionalMovies.propTypes = {
    promotionMovies: PropTypes.array,
    showRentedMovieTag: PropTypes.bool,
    isOfferAvailed: PropTypes.bool,
    handleMovieClick: PropTypes.func,
};