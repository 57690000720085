import React, { Component } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import LoaderButton from "../../../components/LoaderButton/LoaderButton";
import CreditCardNumber from "../../../components/CreditCardNumber/CreditCardNumber";
import { TERMS_CONDITIONS } from "../../../constants/routes";
import { withAppConfig } from "../../../context/ConfigContext";

class SavedCardPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: false
        }
    }

    componentDidUpdate(prevProps) { 
        if (prevProps.disablePayButton !== this.props.disablePayButton) {
            if (!this.props.disablePayButton && this.state.showLoader) {
                this.setState({
                    showLoader: this.props.disablePayButton
                })
            }
        }
        if (prevProps.transactionSubmitted !== this.props.transactionSubmitted) {
            this.onTransactionDone();
        }
    }

    togglePanel = () => {
        this.props.onPanelToggle(this.props.cardId);
    }

    onClickPayment = () => {
        this.setState({
            showLoader: true
        });
        if ( this.props.Type === "collection" || this.props.Type === "screening_room") {//make both same later!! remove checkout params        
            const params = {
                PaymentMethodToken: this.props.cardId
            };
            params["AdyenPaymentType"] = 'scheme'; //remove form here and add it to adyen
            this.props.onClickPaywithCard("savedPayment", params);
          } else {
            let params = this.props.getPaymentParams(this.props.purchaseType); //change it later
            params["PaymentMethodToken"] = this.props.cardId;
            params["AdyenPaymentType"] = 'scheme'; //remove form here and add it to adyen
            this.props.onClickPaywithCard("savedPayment", params);
          }
    }

    onTransactionDone = (isError) => {
        this.setState({
            showLoader: false
        });
    }

    render() {
        const { card, price,Type } = this.props;
        const { pay_with_card = `Pay with Card` , your_payable_amount_is = `Your Payable amount is` , submitting = 'Submitting' , submit ,default : def } = this.props.appTranslations;
        let text = this.props.appTranslations.by_placing_your_order_you_agree_to_our_t || `By placing your order, you agree to our $ terms of use.`;
        text = text ? text.split('$') : [];
        return (
            <div className="card card-dark mb-3">
                <div className={`card-header pointer text-white ${this.props.isPanelSelected ? "" : "collapsed"}`} onClick={this.togglePanel} > {/* data-toggle="collapse" data-target="#payment-1" aria-expanded="false" */}
                    <div className="d-flex justify-content-between">
                        <div className="">
                            {/* <span><i className="la font-18 la-circle-o pay-select mr-3"></i></span> */}
                            <CreditCardNumber
                                cardNumber={card.CardMaskedNumber}
                                cardType={card.CardType}
                            />
                        </div>
                        <div className="">
                            {card.Default && <span className="badge badge-light px-2 py-1 mr-3">{def}</span>}
                            <i className="las la-angle-down"></i>
                        </div>
                    </div>
                </div>
                <Collapse id="payment-1" isOpen={this.props.isPanelSelected} >
                    <div className="card-body pb-0 text-center">
                        <div className="text-white-50">
                            <div className="mb-3">{your_payable_amount_is}
                            <span className="text-white font-weight-bold">{price}</span>
                            </div>
                            <div className="mb-3 font-12">
                                {text[0]} <Link to={TERMS_CONDITIONS}> {text[1] ? text[1] : ''}</Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="text-center">
                            {this.state.showLoader && <span className="loader-spiral mn-white mn-transparent"><span className="spiral-inn"></span></span>}
                            <LoaderButton
                                onClick={this.onClickPayment}
                                className="btn btn-primary ml-2"
                                type={submit}
                                isLoading={this.state.showLoader}
                                loadingText={submitting}
                                text={pay_with_card}
                                disabled={this.props.disablePayButton}
                            />
                        </div>
                    </div>
                </Collapse>
            </div>
        )
    }
}

SavedCardPanel.defaultProps = {
    isPanelSelected: false,
    showLoader: true
}

SavedCardPanel.propTypes = {
    isPanelSelected: PropTypes.bool,
    cardId: PropTypes.string.isRequired,
    showLoader: PropTypes.bool,
    onClickPaywithCard: PropTypes.func.isRequired,
    onPanelToggle: PropTypes.func.isRequired
};
export default withAppConfig(SavedCardPanel);