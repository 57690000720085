import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import Auth from "@aws-amplify/auth";
import { withRouter } from "react-router-dom";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { withAppConfig } from "../../context/ConfigContext";

const UserConfirmation = ({ email, showModal, toggleModal, ...props }) => {
  const [loading, setLoading] = useState(false);

  const onConfirmationClick = () => {
    setLoading(true);
    Auth.resendSignUp(email.toLowerCase())
      .then(res => {
        setLoading(false);
        props.showAlert(
          "showSuccessAlert",
          "Please Visit your Email Address to verify your Muvinow account!"
        );
        if (props.location.pathname !== "/login") {
          props.history.push("/login");
        }
      })
      .catch(err => {
        setLoading(false);
        let message = "Code couldn't be sent!";
        if (err.code === "LimitExceededException") {
          message = err.message;
        }
        props.showAlert("showErrorAlert", message);
      });
    toggleModal();
  };

  if (!showModal) {
    return null;
  }

  return (
    <ConfirmationModal
      showConfirmationModal={showModal}
      toggleModal={toggleModal}
      onConfirmationClick={onConfirmationClick}
      Title={props.Title}
      Loader={
        loading ? (
          <span className="loader-spiral mn-white mn-transparent">
            <span className="spiral-inn" />
          </span>
        ) : null
      }
      Subtitle={props.SubTitle}
      ButtonText="Resend"
    />
  );
};

export default withAppConfig(withRouter(UserConfirmation));

UserConfirmation.defaultProps = {
  Title: "User Signup Confirmation",
  SubTitle:
    "Please verify your account, if you do not receive verification email please click on Resend."
};

UserConfirmation.propTypes = {
  showModal: PropTypes.bool
};
