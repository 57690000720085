import React, { useState, Fragment, useEffect } from "react";
import PaymentCardPanel from "./components/PaymentCardPanel";
import _find from "lodash/find";
import PaymentHeader from "./components/PaymentHeader";
import { CollectionPurchase } from "../../../services/checkout/CollectionPurchase";
import { RoomSubscription } from "../../../services/checkout/RoomSubscription";
import { withAuth } from "../../../context/AuthContext";
import TransactionModal from "./components/TransactionModal";
import SavedCardPanel from "../components/SavedCardPanel";
import { withPaymentCard } from "../../../context/PaymentCardContext";
import FreeCheckoutView from "../components/FreeCheckoutView";
import config from "../../../config/config";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

const PaymentDetails = props => {
  const [isCheckingOut, setCheckingOut] = useState(false);
  const [isPurchaseSuccessful, setSuccess] = useState(false);
  const [selectedPanelId, togglePanel] = useState("formpanel");

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  useEffect(() => {
    togglePanel(props.defaultCardID); //from paymentContext!
  }, []); //props.defaultCardID

  let paymentGateway = _find(props.configurations, ["KEY", "PAYMENT_GATEWAY"]);
  if (paymentGateway && paymentGateway.VALUE) {
    paymentGateway = paymentGateway.VALUE;
  }

  const handleCheckout = (purchaseType, params) => {
    params["RoomID"] = props.ID;
    params["ManifestType"] = props.ManifestType;
    params["Duration"] = props.Duration;
    if (props.checkoutData.Code) {
      params["Voucher"] = props.checkoutData.Code;
    }
    if(props.checkoutData.Price == 0){
      params["PaymentGateway"] = "voucher"
    }
    if (!params.PaymentGateway) {
      let activeGateway = _find(paymentGateway, ["Status", "active"]);
      if (activeGateway) {
        params["PaymentGateway"] = activeGateway.Type.toLowerCase();
      }
    }
    setCheckingOut(true);
    if (props.Type === "collection") {
      params.PurchaseType = "tvod";
      CollectionPurchase(onCheckoutResponse, params);
    } else if (props.Type === "screening_room") {
      RoomSubscription(onCheckoutResponse, params);
    }
  };

  const onCheckoutResponse = (res, err) => {
    setCheckingOut(false);
    if (!err) {
      setSuccess(true);
    } else {
      props.showAlert('showErrorAlert', err)
    }
  };

  const handleFreeCheckout = () => {
    let params = {};
    params["PaymentNonce"] = "XXXXXXX";
    params["Price"] = props.checkoutData.Price;
    handleCheckout(null, params);
  };
console.log("props", props)
  const { ApplicationName = config.applicationName } = props.appSettings;
  let headerTitle = `${ApplicationName} ${props.appTranslations
    .reward_applied || "Reward Applied"}`;
  let headerSubTitle = `The value of your ${ApplicationName} Reward Code was successfully applied.`;
  if (props.checkoutData.Price == 0) {
    return (
      <Fragment>
        <FreeCheckoutView
          headerTitle={headerTitle}
          headerSubTitle={headerSubTitle}
          isLoading={isCheckingOut}
          onClickCheckout={handleFreeCheckout}
        />
        {isPurchaseSuccessful && <TransactionModal {...props} />}
      </Fragment>
    );
  }

  const onPanelToggle = id => {
    togglePanel(id);
  };
  
  let params = {
    onPanelToggle: onPanelToggle,
    data: props.data,
    isCheckingOut: isCheckingOut,
    Type: props.Type,
    checkoutData: props.checkoutData,
    paymentGateway: paymentGateway,
    onClickPaymentForm: handleCheckout,
    cardsList: props.cardsList
  };

  return (
    <section className="mn-pay-method pb-5">
      <div className="container">
        <PaymentHeader paymentGateway={paymentGateway} {...props} />
        {/* <div className="pay-method-body">
            <div className="row no-gutters" style={{ minHeight: "450px" }}> */}
                {/* <div className="col-md-3 bg-gray-700">
                <Nav pills className="flex-column vertical-pills">
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}
                  >
                    <i className="las la-credit-card mr-2 font-18"></i> Credit Card
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                  >
                    <i className="las la-credit-card mr-2 font-18"></i> G Cash
                  </NavLink>
                </NavItem>
              </Nav>
                </div> */}
                <div className="">

                {/* <TabContent activeTab={activeTab}>
                    <TabPane tabId="1"> */}
                      <div id="paymentOptionGroup">
                        {props.cardsList &&
                          props.cardsList.length > 0 &&
                          props.cardsList.map((paymentcard, index) => {
                            return (
                              <SavedCardPanel
                                key={index}
                                isPanelSelected={selectedPanelId === paymentcard.ID}
                                isDefault={paymentcard.Default}
                                card={paymentcard}
                                cardId={paymentcard.ID}
                                price={`$${props.checkoutData.Price}`}
                                onClickPaywithCard={handleCheckout}
                                onPanelToggle={onPanelToggle}
                                Type={props.Type}
                                disablePayButton={isCheckingOut}
                                transactionSubmitted={props.transactionSubmitted}
                              //{...props}
                              />
                            );
                          })}
                        <PaymentCardPanel
                          isPanelSelected={selectedPanelId === "formpanel"}
                          {...params}
                        //{...props}
                        />
                      </div>
                    {/* </TabPane>
                    <TabPane tabId="2">
                      G Cash Payment Forms
                    </TabPane>
                  </TabContent> */}
{/*                   
                </div>
            </div> */}
        </div>
        {isPurchaseSuccessful && <TransactionModal {...props} />}
      </div>
    </section>
  );
};

export default withAuth(withPaymentCard(PaymentDetails));
