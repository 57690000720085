import React, { Component, Fragment } from "react";
import { Collapse } from "reactstrap";
import PropTypes from "prop-types";

class SearchBox extends Component { //implement SearchContext later!
    constructor(props) {
        super(props);
        this.state = { collapse: false };
        this.searchTerm = React.createRef()
    }

    componentDidUpdate(prevProps) {
        if (this.props.searchFlag !== prevProps.searchFlag) {
            this.handleClearSearchValue();
        }
        if (this.props.openSearchIcon !== prevProps.openSearchIcon) {
            if (!this.state.collapse) { //if not opened 
                this.toggle();
            }
        }
    }

    handleClearSearchValue = () => {
        this.searchTerm.current.value = "";
        this.props.clearSearch();
        if (this.state.collapse) {
            this.toggle();
        }
    }

    onClickSearchIcon = async () => {
        await this.toggle();
        this.searchTerm.current.focus();
    }

    submitSearch = (e) => {
        e.preventDefault();
    }

    toggle = async () => {
        await this.setState({ collapse: !this.state.collapse });
    }

    onClickClearSearch = (e) => {
        this.props.clearSearch(e);
        this.toggle();
    }

    render() {
        const { setSearchTerm, searchTerm , appTranslations : tr } = this.props;
        let searchQuery = decodeURIComponent(searchTerm);
        return (
            <Fragment>
                <a onClick={this.onClickSearchIcon} className="nav-link pix-search">
                    <span className="pix-search-off"><i className="la la-search"></i></span>
                    {/* <span className="pix-search-on" onClick={(e) => clearSearch(e)} data-toggle="#search-dropdown" data-toggle="collapse"><i className="la la-times"></i></span> */}
                </a>
                <Collapse className="search-dropdown" isOpen={this.state.collapse}>
                    <form onSubmit={this.submitSearch}>
                        <div className="mn-search-input">
                            <input
                                name="searchTerm"
                                value={searchQuery}
                                onChange={e => { setSearchTerm(encodeURIComponent(e.target.value)) }}
                                type="text"
                                maxLength="60"
                                ref={this.searchTerm}
                                className="form-control form-control-sm"
                                placeholder={tr.search_movies || "Search Movies"} 
                                />
                            <a onClick={this.onClickClearSearch} className="mn-search-close" ><i className="la la-times"></i></a>
                        </div>
                    </form>
                </Collapse>
            </Fragment>
        )
    }
}
export default SearchBox;
SearchBox.propTypes = {
    setSearchTerm: PropTypes.func.isRequired,
    clearSearch: PropTypes.func.isRequired,
    searchTerm: PropTypes.string.isRequired
}