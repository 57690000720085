import React from "react";

const Languages = () => {
  return (
    <div className="mb-3">
      <span className="font-weight-bold mr-2">Languages : </span>en
    </div>
  );
};

export default Languages;
