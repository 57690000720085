import React from "react";
import Wrapper from "./Wrapper";
import Languages from "./Languages";
import Subscribe from "./Subscribe";
import Heading from "../../../../components/RoomCollection/Heading";
import Quality from "../../../../components/RoomCollection/Quality";

const HeroSection = ({ data }) => {
  return (
    <Wrapper PosterUrl={data.PosterUrl} PreviewImage={data.PreviewImage}>
      <div className="col-xl-6">
        <Heading DetailType={"collection"} {...data} />
        <Subscribe {...data} />
        <Quality {...data} />
        {/* <Languages /> */}
        {/* <div className="">
          <div className="fab">
            <span className="fab-action-button" title="Share on">
              <i className="la la-share-alt" />
            </span>
          </div>
        </div> */}
      </div>
    </Wrapper>
  );
};

export default HeroSection;
