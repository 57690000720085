import React, { useState, useEffect, Fragment } from "react";
import _find from "lodash/find";
import Detail from "../Detail/Detail";
import RelatedRooms from "../Related";
import Listing from "../Detail/Listing";
import Loader from "../../../components/Loader/Loader";
import { withAuth } from "../../../context/AuthContext";
import { Switch } from "react-router-dom";
import MyRoute from "../../../components/MyRoute";
import Tabs from "../Detail/components/Tabs";
const TabsList = {
  movies: "movies",
  related: "related"
};

const DetailContainer = props => {
  const [subscribed, setSubscribed] = useState({});
  const [isLoading, setLoading] = useState(true);
  // const [allServiceMovies, setMoviesType] = useState(false);
  const [activeTab, toggleTab] = useState();

  useEffect(() => {
    initializeTab(props.location.pathname, props.match.url);
    initialize(props.match.params.id);
    return () => { };
  }, [props.location.pathname]); //props.location.pathname

  const initialize = id => {
    if (props.isAuthenticated) {
      let result = _find(props.subscriptions, ["ScreeningRoomID", id]);
      if (result) {
        setSubscribed({
          isSubscribed_: true,
          ManifestType: result.ManifestType
        });
      }
    }
    setLoading(false);
  };

  const initializeTab = (location, url) => {
    let initialTab = location.includes("related");
    let prevState = (props.location && props.location.state) ? props.location.state : {}
    if (initialTab) {
      initialTab = TabsList["related"];
    } else {
      if (!location.includes("movies")) {
       props.history.replace(`${url}/movies`, { ...prevState })
      }
      initialTab = TabsList["movies"];
    }
    toggleTab(initialTab);
  };

  // const setServiceMovies = () => {
  //   //setMoviesType(true);
  // };

  const onChangeTab = tab => {
    toggleTab(tab);
  };

  const onRelatedClick = selectedId => {
    setSubscribed({}); //initialze state
    setLoading(true); //to re-render child forcefully
    setTimeout(() => {
      initializeTab(
        props.history.location.pathname,
        props.history.location.pathname
      ); // here both are same
      initialize(selectedId);
    }, 0);
  };

  return (
    <main className="mn-main">
      {isLoading ? (
        <Loader />
      ) : (
          <Fragment>
            <Detail
              isSubscribed={subscribed}
              // setServiceMovies={setServiceMovies}
              {...props}
            />
            {/* <Tabs activeTab={activeTab} onChangeTab={onChangeTab} /> */}
            <section className="py-4 bg-gray-800">
              <div className="container-fluid">
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="">
                    Movies <span className="font-weight-light text-white-70">in this Pack</span>
                  </h5>
                  {/* <div className="">
                    <input type="text" className="form-control form-control-dark" placeholder="Search in Pack" />
                  </div> */}
                </div>
              </div>
            </section>
            <Switch>
              <MyRoute
                path={`${props.match.path}/movies`}
                component={Listing}
                props={{
                  isSubscribed_: subscribed.isSubscribed_,
                  allServiceMovies: props.location && props.location.state && props.location.state.AllServiceMovies === 'active',
                  ...props
                }}
              />
              <MyRoute
                path={`${props.match.path}/related`}
                component={RelatedRooms}
                props={{
                  isSubscribed_: subscribed.isSubscribed_,
                  onRelatedClick: onRelatedClick,
                  ...props
                }}
              />
            </Switch>
          </Fragment>
        )}
    </main>
  );
};

export default withAuth(DetailContainer);
