import { POST } from "../../api/";
import _isEmpty from "lodash/isEmpty";

export function canRedeemMovie(callback, params) {
  return POST("OrdersAPI", "/canRedeem", params)
    .then(res => {
      callback(res.body);
      return res.body;
    })
    .catch(e => {
      let message = "Failed to Redeem Voucher!";
      if (e.response && e.response.data && e.response.data.errorMessage) {
        message = e.response.data.errorMessage.replace(
          `[${e.response.status}] `,
          ""
        );
      }

      callback(null, message);
      return;
    });
}

export function canRedeemCollectionRoom(callback, params) {
  let path = params.Path === 'collection' ? '/can-redeem-collection' : '/can-redeem-subscription';
  delete params.Path;
  return POST("OrdersAPI", path, params)
    .then(res => {
      let result = calulateTotalPrice(params, res.body)
      callback(result);
      return result;
    })
    .catch(e => {
      let message = "Failed to Redeem Voucher!";
      if (e && e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message.replace(
          `[${e.response.data.statusCode}] `,
          ""
        );
      }
      callback(null, message);
      return;
    });
}


const calulateTotalPrice = (params, response) => {
  let voucher = response;
  let price = params.TotalPrice;
  let discount;
  let finalResponse = { ...response };
  if (!_isEmpty(voucher)) {
    if (voucher.DiscountType === "Fixed") {
      if (voucher.DiscountValue > price) {
        discount = price;
      } else {
        discount = voucher.DiscountValue;
      }
    } else if (voucher.DiscountType === "Percentage") {
      discount = price * (voucher.DiscountValue / 100);
    } else if (voucher.DiscountType === "Variable") {
      discount = price - voucher.DiscountValue;
    }
    if (voucher.DiscountType === "Fixed" || voucher.DiscountType === "Percentage") {// because total price is calculated in diff way!!
      finalResponse["Price"] = parseFloat(price - discount).toFixed(2)
    } else {
      finalResponse["Price"] = voucher.DiscountValue;
    }
    finalResponse["Discount"] = discount ? discount.toFixed(2) : discount;
  } else {
    finalResponse["Price"] = price
  }
  return finalResponse;
};