import { POST } from "../../api/";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";
import _map from "lodash/map";
import { getCompressedCdnImageUrl } from "../../helpers/CDNhelpers";

export default async function getRelatedCollections(callback, params) {
  const { ID,HostName } = params;
  let body = {
    query: {
      more_like_this: {
        fields: ["Genre"],
        like: [{ _type: "_doc", _id: ID }],
        min_term_freq: 1,
        max_query_terms: 20,
        min_doc_freq: 1,
        max_doc_freq: 10
      }
    }
  };

  let collections;
  if (!collections || _isEmpty(collections)) {
    collections = await POST("SearchAPI", `/movie-collections/_search`, body)
      .then(res => {
        if (res && res.hits && res.hits.hits) {
          if (params.mycollections && params.isAuthenticated) {
            res.hits.hits = updateStatus(res.hits.hits, params.mycollections);
          }
          res.hits.hits = _map(res.hits.hits, (item) => {
            let isPosterURL = item._source && item._source.PosterUrl;
            if (isPosterURL) {
                item._source["PosterUrl"] = getCompressedCdnImageUrl(isPosterURL, 'FETCH_SEARCH_DATA', HostName);
            }
            return item
          });
          callback(null, res.hits.hits);
          return;
        }
        callback(null, []);
      })
      .catch(e => {
        let message = e;
        if (e.response && e.response.data && e.response.data.errorMessage) {
          message = e.response.data.errorMessage.replace("[403] ", "");
        }
        callback("Error", message);
        return;
      });
  }
  return collections;
}

function updateStatus(collections, mycollections) {
  let result = collections;
  result = result.map(item => {
    let isCPurchased = _find(mycollections, ["CollectionID", item._source.ID]);
    if (isCPurchased) {
      item._source["isCPurchased_"] = true;
    }
    return item;
  });
  return result;
}
