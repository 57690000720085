import React, { useState, useEffect } from "react";
import Detail from "./Detail";
import _find from "lodash/find";
import { withRouter } from "react-router-dom";
import PaymentDetails from "./PaymentDetails";
import { withAppConfig } from "../../../context/ConfigContext";
import { querystring } from "../../../helpers/GeneralHelpers";

const Container = props => {
  const [checkoutData, setCheckoutData] = useState({}); //applied voucher
  const [selectedQuality, setQuality] = useState("");
  const [selectedDuration, setDuration] = useState("");
  const [data, setData] = useState({});


  useEffect(() => { }, []);

  const calculatePrice = (detail, selectedQuality, selectedDuration) => {
    let price = 0;
    let quality = selectedQuality;
    let duration = selectedDuration;
    if (!detail.Plans) {
      return 0;
    }
    detail.Plans.forEach((item) => {
      Object.keys(item.pricelist).forEach((q) => {
        if (quality == q && item.duration == duration) {
          price = item.pricelist[q].price;
        }
      });
    });
    return price;
  }

  const onQualityChange = (value, QualityList) => {
    setQuality(value);
    let price = calculatePrice(data, value, selectedDuration);
    setCheckoutData({ ...checkoutData, Quality: value, Price: price });
  };

  const onDurationChange = (value, DurationList) => {
    setDuration(DurationList);
    let price = calculatePrice(data, selectedQuality, DurationList);
    setCheckoutData({ ...checkoutData, Duration: DurationList, Price: price });
  }

  const initialize = async (value, QualityList, detail) => {
    const state = new URLSearchParams(props.location.search);
    const quality = (state.get("type")) ? state.get("type").toLowerCase() : QualityList[0].Quality;
    setQuality(quality);
    const duration = (state.get("duration")) ? state.get("duration") : QualityList[0].Duration;
    setDuration(duration);
    setData(detail); //detail data

    if (state && state.get("Voucher") && state.get("VoucherData")) {
      setCheckoutData({ ...state.VoucherData, isAutoRedempted: true }); //hanldeAutoRedeem
    } else {
      
      let price = calculatePrice(detail, quality, duration);
      setCheckoutData({ ...checkoutData, Price: price });
    }
  };
  const onChangeVoucher = voucherResponse => {
    setCheckoutData(voucherResponse);
  };

  return (
    <main className="mn-main">
      <Detail
        checkoutData={checkoutData}
        Type={'screening_room'}
        data={data}
        onChangeVoucher={onChangeVoucher}
        selectedQuality={selectedQuality}
        onQualityChange={onQualityChange}
        selectedDuration={selectedDuration}
        onDurationChange={onDurationChange}
        initialize={initialize}
      />
      <PaymentDetails
        checkoutData={checkoutData}
        Type={querystring("method")}
        ManifestType={selectedQuality}
        Duration={selectedDuration}
        ID={props.match.params.id}
        data={data}
        {...props}
      />
    </main>
  );
};

export default withAppConfig(withRouter(Container));
