import React from "react";
import { updatePlayerSession } from "../../../helpers/APIHelpers/PlayerAPI";
import {withFirebase} from "../../../context/FirebaseContext";
import { START_PLAYER_SESSION, RESUME_PLAYER_SESSION, PAUSE_PLAYER_SESSION, STOP_PLAYER_SESSION, CANCEL_PLAYER_SESSION } from "../../../constants/constants";

const PlayerSessionHelper = (WrappedComponent) => {
    class PlayerSession extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                playerSessionId: ""
            }
        }

        onStartSession = async (id, duration, type) => {
            if (duration === 0 || this.state.playerSessionId === "") {
                let playerSessionId = await updatePlayerSession(id, type, START_PLAYER_SESSION, this.state.playerSessionId, duration);
                if (playerSessionId){
                    await this.props.removeFirebaseDisconnect();
                    this.props.setInitialSession(playerSessionId,type);
                    this.setState({
                        playerSessionId: playerSessionId,
                    });
                }
            } else {
                if(duration !== 0){
                    updatePlayerSession(id, type, RESUME_PLAYER_SESSION, this.state.playerSessionId, duration);
                }


            }
        }

        onPauseSession = (id, duration, type, totalDuration) => {
            if (this.state.playerSessionId !== "" && duration !== 0) {
                if (totalDuration && parseInt(duration) !== parseInt(totalDuration)) { //dont send pause request on movieENd
                    updatePlayerSession(id, type, PAUSE_PLAYER_SESSION, this.state.playerSessionId, duration);
                }else if(!totalDuration){ //totalDuration not found!
                    updatePlayerSession(id, type, PAUSE_PLAYER_SESSION, this.state.playerSessionId, duration);
                }
            }
        }

        onEndSession = (id, duration, type) => {
            if (this.state.playerSessionId !== ""){
                updatePlayerSession(id, type, STOP_PLAYER_SESSION, this.state.playerSessionId, duration);
            }

        }

        cancelPlayerSession = (id, duration, type) => {
            if (this.state.playerSessionId !== "") {
                updatePlayerSession(id, type, CANCEL_PLAYER_SESSION, this.state.playerSessionId, duration);
            }
        }

        render() {
            return (
                <WrappedComponent
                    onStartSession={this.onStartSession}
                    onPauseSession={this.onPauseSession}
                    onEndSession={this.onEndSession}
                    onCancelSession={this.cancelPlayerSession}
                    playerSessionId={this.state.playerSessionId}
                    {...this.props}
                />)
        }
    }
    return withFirebase(PlayerSession);
}
export default PlayerSessionHelper;

