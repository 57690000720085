import React, { Fragment, useState } from "react";
import Buttons from "./components/Buttons";
import Wrapper from "./components/Wrapper";
import { withRouter } from "react-router-dom";
import { ROOMDETAIL } from "../../constants/routes";
import RentalTag from "./components/RentalTag";
import SubscribeModal from "../SubscribeModal/SubscribeModal"
import { withAppConfig } from "../../context/ConfigContext";
const RoomsCard = (room) => {
  const { ID } = room.screeningRoom ? room.ScreeningRoom : room;
  const { history } = room
  const [showSubscribeModal, setSubscribeModal] = useState(false)
  const onClick = e => {
    e.preventDefault();
    e.stopPropagation();
    if (room.onRelatedClick) {
      room.onRelatedClick(ID);
    }
    setSubscribeModal(true)
  };
  const onMoreInfoClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(`room-detail/${ID}`);
  }
  const renderSubscribeModal = (room) => {
    return (
      <SubscribeModal
        showSubscribeModal={showSubscribeModal}
        dismissPurchaseModal={() => setSubscribeModal(false)}
        handleBackDropClick={() => setSubscribeModal(false)}
        // isAuthenticated={this.props.isAuthenticated}
        data={room}
      />)
  }
  const formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  let { showHovers = true } = room;
  let priceArray = []
  if(!room.screeningRoom){
  room.Plans.forEach(plan => {
    ['sd', 'hd', '4k'].map((quality, qIndex) => {
      if (plan.pricelist[quality] && plan.pricelist[quality].price !== null) {
        priceArray.push(plan.pricelist[quality].price)
      }
    });
  });
}
  let minimumPrice = Math.min(...priceArray)
  let title = room.screeningRoom ? room.ScreeningRoom.Title : room.Title
  let movieCount = room.screeningRoom ? room.ScreeningRoom.MovieCount : room.MovieCount
  let description = room.screeningRoom ? room.ScreeningRoom.Description : room.Description
  let renderSubscribe = room.screeningRoom ? room.ScreeningRoom : room
  let duration = room.screeningRoom ? room.ScreeningRoom.Duration : null
  return (
    <>
      {/* {room.isSubscribed_ && */}

      <Wrapper
        Tag={room.isSubscribed_}
        classname={room.classname}
        onClick={onMoreInfoClick}
        {...renderSubscribe}
      >
        <div className="text-white-70">
          <h4 className="mb-3 text-white" >{title}</h4>
          <Fragment>
            <div className="mb-3"><span className="font-weight-bold mr-1">{movieCount} {movieCount === 1 ? "Movie" : "Movies"}</span> </div> 
            <div className="mb-3 text-white-50 text-truncate" > {description}</div>
            {room.screeningRoom ? (
              <div className="mb-3">Current Plan: <span className="font-weight-bold mr-1"> {room.appSettings.CurrencySymbol}  {room.Total} / {duration.charAt(0).toUpperCase() + duration.slice(1)}</span> </div>
            ) : null}
            {!room.screeningRoom ? <div className="mb-3">Starting from<span className="font-weight-bold mr-1"> {room.appSettings.CurrencySymbol}{minimumPrice}</span> </div>
              : (room.AutoRenew ?
                <div className="mb-3">Renewal At: <span className="font-weight-bold mr-1">{formatDate(room.ExpiresAt)}</span> </div> : <div className="mb-3">Expiring At: <span className="font-weight-bold mr-1">{formatDate(room.ExpiresAt)}</span> </div>)}
            <Buttons isSubscribed_={room.isSubscribed_} onClick={onClick} ID={ID} history={history} />
            {showSubscribeModal ? renderSubscribeModal(renderSubscribe) : null}

          </Fragment>
        </div>

      </Wrapper>
      {/* || null} */}
    </>

  );
};

export default withAppConfig(withRouter(RoomsCard));

//RoomsCard -> for collection & screening-room
