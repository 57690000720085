import PropTypes from "prop-types";
import React, { useState } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { initSliderSettingsForMoviesGenre } from "../../helpers/MoviesHelper";

const CustomSlickSlider = ({ dataList = [], selectedGenre, handleFilterItemClick, initialIndex = 0 }) => {
    const [showDropDown, setShowDropDown] = useState(false)
    if (!dataList || (dataList && dataList.length <= 0)) {
        return null;
    }
    const onClick = (e, Name, Value) => {
        if (selectedGenre !== Value) {
            handleFilterItemClick(Name, Value);
            setShowDropDown(false)
        }

    };
    var selectedGenreObject = dataList.find(obj => {
        if (obj.Value === selectedGenre) {
            return obj
        }
    })
    const onBtnClick = () => setShowDropDown(!showDropDown)
    return (
        <UncontrolledDropdown isOpen={showDropDown} toggle={onBtnClick} className="ml-4 mn-genre mn-genre-list mn-filter-init row gutter-5" {...initSliderSettingsForMoviesGenre(dataList.length, initialIndex)}>
            <DropdownToggle className="mn-genre-toggle" caret>
                <span className="genre-thumb" style={{ backgroundImage: `url(${!(selectedGenreObject && selectedGenreObject.BackgroudImage) ? "https://i.imgur.com/T0WRrhX.jpg" : selectedGenreObject.BackgroudImage})` }}></span>
                <span>{(selectedGenreObject && selectedGenreObject.Name) ?  selectedGenreObject.Name : "All"}</span>
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-dark" >
                <div className="container-fluid">
                    <div className="pt-4 d-flex flex-wrap">
                        {dataList && dataList.length > 0 ?
                            dataList.map((item, index) => {
                                return (
                                    <button className="btn btn-secondary btn-lg btn-medium mn-genre-toggle mb-4 mr-5" key={index} Use Email AddressUse Email AddressnClick={(e) => onClick(e, item.Name, item.Value)}>
                                        <a className={selectedGenre === item.Value ? "active" : ""}></a>
                                        <span className="genre-thumb" style={{ backgroundImage: `url(${!(item.BackgroudImage) ? "https://i.imgur.com/T0WRrhX.jpg" : item.BackgroudImage})` }}></span>
                                        <span>{(!item.Name ? "" : item.Name)}</span>
                                        <a />
                                    </button>
                                )
                            })
                            : null}
                    </div>
                </div>
            </DropdownMenu>

        </UncontrolledDropdown>
    )
}

CustomSlickSlider.propTypes = {
    dataList: PropTypes.array,
    handleFilterItemClick: PropTypes.func.isRequired,
    selectedGenre: PropTypes.string,
    selectedGenreIndex: PropTypes.any
}

export default CustomSlickSlider;
