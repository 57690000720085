import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import RenderScreen from "../../../components/RenderScreen/RenderScreen";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";
import { convertSecToDuration } from "../../../helpers/GeneralHelpers";
import { calculateProgressBar } from "../../../helpers/MoviesHelper";
import Loader from "../../../components/Loader/Loader";
import { OWNED } from "../../../constants/constants";
import { PgRating } from "../../../components/PgRating/PgRating";
import { withAppConfig } from "../../../context/ConfigContext";
const activeTab = { myPurchases: "1", myList: "2", watchHistory: "3" };

class WatchHistory extends Component {

    handleTitleClick = (movieID) => {
        this.props.history.push(`/movie/${movieID}`);
    }

    renderSearchView = () => {
        const { appTranslations: tr } = this.props;
        return (
            <aside className="col-lg-4 order-lg-2">
                <h6 className="mb-4">Recent Searches</h6>
                {(this.props.searchHistory && this.props.searchHistory.length > 0) ?
                    <ul className="list-unstyled mb-4 lh-15 border-bottom border-dark">
                        {this.props.searchHistory.map((searchItem, index) => {
                            return <li key={index} className="border-top border-dark p-3"><a href="javascript:void(0)" className="link-white-50">{searchItem}</a></li>

                        })}
                    </ul>
                    :
                    <ul className="list-unstyled mb-4 lh-15 border-bottom border-dark">
                        <li className="border-top border-dark p-3"><a href="javascript:void(0)" className="link-white-50">{tr.no_recent_searched_found}</a></li>
                    </ul>
                }
                {(this.props.searchHistory && this.props.searchHistory.length > 0) &&
                    <div className="text-uppercase font-weight-bold mb-5">
                        <a href="javascript:void(0)" onClick={this.props.clearSearchHistory} className="link-white-50">{tr.clear_search_history}</a>
                    </div>
                }
            </aside>
        )
    }

    renderPoster(movie) {
        return (
            <div className="watch-media-figure align-self-start">
                <a onClick={(e) => this.props.handleWatchMovieClick(e, movie.MovieID, movie)} href="javascript:void(0)" className="d-block position-relative">
                    <div className="mn-thumb-overlay">
                        <span className="btn-play"><i className="la la-play"></i></span>
                    </div>
                    <div className="progress mn-progress">
                        <div className="progress-bar bg-primary" style={{ width: `${calculateProgressBar(movie.MoviePlayDuration, movie.TotalDuration)}%` }}></div>
                    </div>
                    <div className="mn-figure">
                        <div className="mn-thumb img-bg img-16x9" style={{ backgroundImage: `url(${movie.MoviePosterURL})` }}></div>
                    </div>
                </a>
            </div>
        )
    }

    renderHeading(movie) {
        return (
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-inline-flex align-items-center mb-3">
                    <h5 className="font-weight-normal mb-0"><a className="link-white" href="javascript:void(0)" onClick={() => this.handleTitleClick(movie.MovieID)}>{movie.MovieTitle ? movie.MovieTitle : ""}</a></h5>
                    {/* <div className="ml-3"> <span className="badge badge-light px-2 py-1 mx-1">UHD</span> </div> //update it later*/}
                    <div className="custom-pg">
                        <div className="mn-points mb-4">
                            <PgRating
                                pgID={movie.MoviePGRating}
                            />
                        </div>
                    </div>
                </div>
                <div className="">
                    {this.props.isremovingWatchedMovie[movie.MovieID] ?
                        <i className="la la-spin fa-spinner"></i>
                        :
                        <a onClick={(e) => this.props.removeWatchedMovie(e, movie.MovieID)} className="mn-close" href="javascript:void(0)"></a>
                    }
                </div>
            </div>
        )
    }

    renderDuration(movie) {
        return (
            <div className="mb-2" >
                <span className="text-white mr-2">Total Duration : </span>
                {convertSecToDuration(movie.MoviePlayDuration ? movie.MoviePlayDuration : 0, false, false, true)}
            </div>
        );
    }

    renderStatus(movie) {
        return (
            movie.TotalDuration ?
                <div className="mb-2">
                    <span
                        className="text-white mr-2">Played : </span>{convertSecToDuration(movie.TotalDuration, false, true)}
                </div> : null
        );
    }

    renderMovieGenre(movie) {
        return (
            (movie.MovieGenre && movie.MovieGenre.length > 0 && Array.isArray(movie.MovieGenre)) &&
            <div className="mb-2">
                <span className="text-white mr-2">Genre : </span>
                {movie.MovieGenre.map((gen, index) => {
                    if (gen) {
                        return gen.charAt(0).toUpperCase() + gen.slice(1) + `${index + 1 !== movie.MovieGenre.length ? ", " : ``}`;
                    }
                })}
            </div>
        )
    }

    showPurchaseStatus(movie) {
        return (
            this.props.showWatchHistoryTags &&
                (movie.isPurchased_ && movie.expiringTime_ !== "") ?
                <div className="mb-2">
                    <span className="text-white mr-2">Purchase Status : </span>{movie.purchaseType_ === OWNED ? "Lifetime" : movie.expiringTime_}
                </div>
                : null
        )
    }

    renderWatchListView() {
        let nonExpiredCount = 0;
        return (
            <section className="mn-movies pt-5">
                <InfiniteScroll
                    dataLength={(this.props.myWatchedList && this.props.myWatchedList.length) ? this.props.myWatchedList.length : 0}
                    next={() => this.props.handleFetchMoreMoviesClick(activeTab.watchHistory)}
                    hasMore={this.props.watchedListLastEvaluatedKey !== "" ? true : false}
                    loader={this.props.isLoadingMore && <Loader height="150px" />}
                >
                    <div className="container-fluid">
                        <div className="row">
                            {this.renderSearchView()}
                            <section className="col-lg-8 pr-lg-5">
                                {this.props.myWatchedList &&
                                    this.props.myWatchedList.length > 0 ?
                                    <Fragment>
                                        {this.props.myWatchedList.map((movie, index) => {
                                            if ((!movie.Status || movie.Status !== "inactive") && !movie["HideExpiredRental_"]) {//&& movie.TotalDuration !== 0
                                                nonExpiredCount++;
                                                return (
                                                    <div key={movie.ID ? movie.ID : index} className="media mn-watch-media flex-column flex-sm-row mb-5">
                                                        {this.renderPoster(movie)}
                                                        <div className="media-body watch-media-body w-100">
                                                            {this.renderHeading(movie)}
                                                            <div className="text-white-50">
                                                                {this.renderStatus(movie)}
                                                                {this.renderDuration(movie)}
                                                                {this.renderMovieGenre(movie)}
                                                                {this.showPurchaseStatus(movie)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                        {nonExpiredCount === 0 &&
                                            <DataNotFound
                                                height="400px"
                                                text="You have no item(s) in Watch History"
                                            />
                                        }
                                    </Fragment>
                                    :
                                    <DataNotFound
                                        height="400px"
                                        text="You have no item(s) in Watch History"
                                    />
                                }
                            </section>
                        </div>
                    </div>
                </InfiniteScroll>
            </section>
        );
    }

    render() {
        return (
            <div>
                <section className="mn-page-head py-4 bg-gray-800">
                    <div className="container-fluid">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <h3 className="text-white text-uppercase font-weight-bold mb-0">watch history</h3>
                            </div>
                        </div>
                    </div>
                </section>

                <RenderScreen
                    isLoading={this.props.isLoadingMyWatchedMovies}
                    isError={this.props.errorMyWatchedMovies}
                >
                    {this.renderWatchListView()}
                </RenderScreen>
            </div>
        )
    }
}
export default withAppConfig(WatchHistory); //THIS WATCH HISTORY IS NOT BEING USED!!

WatchHistory.propTypes = {
    isLoadingMyWatchedMovies: PropTypes.bool.isRequired,
    errorMyWatchedMovies: PropTypes.string.isRequired,
    watchedListLastEvaluatedKey: PropTypes.string.isRequired,
    myWatchedList: PropTypes.array.isRequired,
    searchHistory: PropTypes.array,
    isremovingWatchedMovie: PropTypes.object.isRequired,
    showWatchHistoryTags: PropTypes.bool.isRequired,
    handleMovieClick: PropTypes.func.isRequired,
    handleWatchMovieClick: PropTypes.func.isRequired,
    handleFetchMoreMoviesClick: PropTypes.func.isRequired,
    removeWatchedMovie: PropTypes.func.isRequired,
    isLoadingMore: PropTypes.func,
    clearSearchHistory: PropTypes.func
};