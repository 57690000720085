import { GET } from "../../api/";
import _isEmpty from "lodash/isEmpty";
import _concat from "lodash/concat";
import _filter from "lodash/filter";
import _find from "lodash/find";
import { ping } from "../../helpers/GeneralHelpers";
import moment from "moment";
import { checkIsMovieExpired } from "../../helpers/MoviesHelper";
import { handleImageResizing } from "../../helpers/CDNhelpers";
// import { handleImageResizing } from "../../helpers/CDNhelpers";

export default async function getCollectionMovies(callback, param) {
  const { userID, Limit = 20, LastEvaluatedKey = ``, Items } = param;
  let room; //= getDataFromLocalStorage(`collection-movies-${param.ID}`, userID);
  if (!room || _isEmpty(room)) {
    room = await GET(
      "MoviesAPI",
      `/collection-room/${param.ID}/movies?limit=${Limit}&ExclusiveStartKey=${LastEvaluatedKey} `
    )
      .then(async res => {
        if (res.body) {
          res.body.Items = _concat(Items, res.body.Items);
          if (param.isAuthenticated && param.purchases) {
            let serverTime = await ping();
            res.body.Items = verifyPurchased(
              param.ID,
              res.body.Items,
              param.purchases,
              serverTime
            );
          }
          return res.body;
        }
        return res.body;
      })
      .catch(e => {
        let message = e;
        if (e.response && e.response.data && e.response.data.errorMessage) {
          message = e.response.data.errorMessage.replace("[403] ", "");
        }
        callback(null, message);
        return;
      });
  }
  if (!room) {
    return;
  }
  const { HostName } = param;

  room = await handleImageResizing(room, null, HostName);
  if (!_isEmpty(room)) {
    //storeDataInLocalStore(`collection-movies-${param.ID}`, room, userID);
  }
  callback(room);
}

const verifyPurchased = (id, movies, purchases, serverTime) => {
  let purchasedMovies = _filter(purchases, item => {
    let found =
      item.Type === "Child" &&
      item.ParentID === id &&
      item.Status !== "inactive" &&
      item.PaymentStatus === "COMPLETED";
    if (found) {
      found = !checkIsMovieExpired(moment, item, serverTime); 
      if(found){//not expired
        item["isCPurchased_"] = found;
      }else{
        item["isCExpired_"] = found;
      }
    }
    return found;
  });

  //push movie in purchased collection movies if edited/added after purchase
  movies.forEach(item => {
    let found = _find(purchasedMovies, ["MovieID", item.MovieID]);
    if (!found) {
      purchasedMovies.push(item);
    }
  });
  //purchasedMovies = _uniqBy(purchasedMovies, "MovieID");
  return purchasedMovies;
};

// push purchased movie in movies array if missing/deleted
// purchasedMovies.forEach(item => {
//   let found = _find(movies, ["MovieID", item.MovieID]);
//   if (!found) {
//     movies.push(item);
//   }
// });
