import React, { Component } from "react";
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import LoaderButton from "../../../../components/LoaderButton/LoaderButton";
import MyInput from "../../../../components/MyInput";
import withPaymentHelper from "../../../../hoc/PaymentHelpers";
import { handleChange } from "../../../../helpers/StateHelper";
import { VOUCHER_SUCCESS, STORED_SECTIONS_DATA } from "../../../../constants/constants";
import { getVideoQualityData } from "../../../../helpers/MoviesHelper";
import { checkVoucherResult } from "../../../../helpers/PartnerHelpers";
import { removeItemFromStore } from "../../../../helpers/GeneralHelpers";
const TIERID = "teirId"; const PRICE = "price";

class PurchaseButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            voucherCode: "",
            isVerifyingVoucher: false,
            selectedTeirData: {}
        }
        this.voucherInputRef = ""
        this.handleChange = handleChange.bind(this);
    }

    hanldeReedemVoucherClick = async () => {
        const { movieId, type, data } = this.props;
        const { voucherCode } = this.state;
        if (voucherCode) {
            this.setState({
                isVerifyingVoucher: true
            });
            let qualityArray = getVideoQualityData(data, movieId, type);
            this.props.toggleModalbackDrop();
            let quality = qualityArray.length > 0 ? qualityArray[0] : {};
            this.setState({
                selectedTeirData: quality
            })
            this.props.handleRedeemVoucherClick(voucherCode, movieId, quality, VOUCHER_SUCCESS);
        }
    }

    onVoucherRedeemFailed = (err) => {
        this.setState({
            isVerifyingVoucher: false
        });
        this.props.toggleModalbackDrop();
    }

    onVoucherRedeemSuccess = (response) => {
        this.props.toggleModalbackDrop();
        const { movieId, type } = this.props;
        const { voucherCode, selectedTeirData = {} } = this.state;
        let allowCheckout = checkVoucherResult(response, selectedTeirData[PRICE]);
        if (allowCheckout) {
            this.setState({
                isCheckingOut: true
            });
            let checkoutParams = {
                PurchaseType: type,
                TierID: selectedTeirData[TIERID],
                PaymentGateway: "BrainTree",
                MovieID: movieId,
                Voucher: voucherCode,
                Price: 0, //voucherResponse.TotalPrice
                PaymentNonce: "XXXXXX",
                Quality: selectedTeirData["ManifestDetails"]
            }
            this.props.handleCheckout(checkoutParams, true); //true === show notification alert
        } else {
            this.setState({
                isVerifyingVoucher: false
            });
            this.props.showAlert("showErrorAlert", "Sorry! Your Voucher is not 100% Free");
        }
    }

    handleClickClearVoucher = () => {
        this.setState({
            voucherCode: ""
        });
        this.voucherInputRef.clearInput()
    }

    onCheckoutSuccess = (response, { userId }) => {
        this.props.decideShowParnterOfferStrip();
        this.setState({
            isCheckingOut: false,
            isVerifyingVoucher: false
        });
        this.props.onCheckoutSuccess();
        removeItemFromStore(STORED_SECTIONS_DATA, userId)
    }

    onCheckoutFailed = (err) => {
        this.setState({
            isCheckingOut: false,
            showPurchaseModel: false,
            isVerifyingVoucher: false
        })
    }

    render() {
        const { isVerifyingVoucher, voucherCode, isCheckingOut } = this.state;
        return (
            <div className="modal-footer">
                {(isVerifyingVoucher || isCheckingOut) &&
                    <span className="loader-spiral mn-white mn-transparent">
                        <span className="spiral-inn"></span>
                    </span>
                }
                <div className="row w-100 justify-content-center">
                    <div className="col-8">
                        <Formsy >
                            <MyInput
                                id="voucherCode"
                                name="voucherCode"
                                type="text"
                                innerRef={i => this.voucherInputRef = i}
                                innerClassName="form-control-dark"
                                outerClassName="mb-3"
                                placeholder={`Enter +Rewards voucher Code`}
                                required
                                handleChange={this.handleChange}
                            />
                        </Formsy>
                        <div className="">
                            <LoaderButton
                                isLoading={isVerifyingVoucher}
                                disabled={!voucherCode || isVerifyingVoucher}
                                text="Reedem to Watch"
                                loadingText="Verifying Voucher..."
                                className="btn btn-primary btn-block"
                                onClick={this.hanldeReedemVoucherClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withPaymentHelper(PurchaseButton);
PurchaseButton.defaultProps = {
    data: {}
}

PurchaseButton.propTypes = {
    dismissPurchaseModal: PropTypes.func.isRequired,
    data: PropTypes.object,
    appSettings: PropTypes.object,
    isAuthenticated: PropTypes.bool.isRequired,
    teirId: PropTypes.string.isRequired,
    movieId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    handleRedeemVoucherClick: PropTypes.func,
    history: PropTypes.object,
    toggleModalbackDrop: PropTypes.func,
    handleCheckout: PropTypes.func,
    onCheckoutSuccess: PropTypes.func,
    showAlert: PropTypes.func
};