import React from "react";
import Auth from "@aws-amplify/auth";
import Cache from '@aws-amplify/cache'
import Alert from "../components/Alert/Alert";
import _includes from "lodash/includes";
import _isEmpty from "lodash/isEmpty";
import _uniqBy from "lodash/uniqBy";
import _remove from "lodash/remove";
import API from "@aws-amplify/api";
import {
    SUCCESS,
    STORED_SERVER_TIME,
    STORED_CURRENT_USER,
    NO_EXPIRY,
    STORED_MY_PARTNER_NAME,
    STORED_VOUCHERS
} from "../constants/constants";
import config from "../config/config";
import { SERVICE_UNAVAILABLE } from "../constants/routes";

export async function storeDataInLocalStore(key, value, userId = null, time = config.cache.shortCache, unit = "m") {
    let expiration; let result;
    if (time === NO_EXPIRY) {
        time = config.cache.longCache
    }
    expiration = await getCurrentTime(time, unit);
    result = Cache.setItem(userId !== null ? `${key}-${userId}` : key, JSON.stringify(value), { expires: (expiration !== null && expiration !== undefined) && expiration.valueOf() });
    //if (time !== NO_EXPIRY) { //} //result = Cache.setItem(userId ? `${key}-${userId}` : key, JSON.stringify(value));
    return result;
}

export async function cachePartner(partnerName) {
    if (!getDataFromLocalStorage(STORED_MY_PARTNER_NAME)) {
        return await storeDataInLocalStore(STORED_MY_PARTNER_NAME, partnerName, null, NO_EXPIRY)
    }
    return
}

export function getDataFromLocalStorage(key, userId = null) {
    let data = [];
    let result = Cache.getItem(userId !== null ? `${key}-${userId}` : key);
    try {
        data = JSON.parse(result);
    } catch (e) { }
    return data;
}

export async function importMoment(tempThisCheck = true) { //please pass false while func call!!
    return await import("moment").then((mom) => {
        mom = mom.default;
        if (tempThisCheck) {
            this.moment = mom;
        }
        return mom;
    }).catch(() => {
        return null;
    })
}

export function removeItemFromStore(key, userId = null) {
    Cache.removeItem(userId ? `${key}-${userId}` : key);
}

export async function getCurrentTime(time, unit) {
    let moment = await import("moment").then((moment) => {
        return moment.default;
    }).catch((err) => {

    });
    let current;
    if (moment) {
        current = moment();
        current.add(time, unit);
    }
    return current;
}

export function getCurrentUserId() {
    let currentUserData = new Promise((resolve, reject) => {
        let currentUserData = getDataFromLocalStorage(STORED_CURRENT_USER);
        if (currentUserData) {
            resolve(currentUserData.userId);
        } else {
            Auth.currentAuthenticatedUser().then((res) => {
                let userId = res.username
                if (userId && _includes(userId, "@")) {
                    userId = res.attributes ? res.attributes.sub : res.username;
                }
                resolve(userId);
            }).catch(() => {
                resolve(null)
            });
        }
    });

    return currentUserData.then((userId) => {
        return userId;
    });
}

export function getValueFromObj(key, obj) {
    if (obj && key) {
        if (obj[key] !== undefined) {
            return obj[key];
        }
    }
    return '';
}

export function multiIncludes(text, values) {
    if (text && values) {
        var re = new RegExp(values.join('|'));
        return re.test(text);
    }
    return false;
}

export function isValueFoundInData(data, value) {
    return _includes(data, value);
}

export function clearBrowserCache() {
    Cache.clear();
    localStorage.clear();
}

export function isEmpty(data) {
    return _isEmpty(data);
}

export function uniqueBy(data, key) {
    if (key) {
        let result = _uniqBy(data, key);
        return result
    }
    return data;
}

export function getObjectFromArray(array, key, value) {
    let temp = {};
    if (array) {
        array.forEach(obj => {
            if (obj[key] === value) {
                temp = obj;
            }
        });
    }
    return temp;
}

export function removeObjectFromData(data, iteratee, value) {
    _remove(data, (obj) => obj[iteratee] === value);
    return data;
}

export function jsDateToYYYYMMDD(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [month, day, year].join('-');
}

export function showError(error, message) {
    return (
        <Alert className="text-danger" icon="la la-exclamation-triangle mr-2" text={message} />
    )
}

export function showSuccess(success, message) {
    return (
        <Alert className="text-success" icon="la la-check mr-2" text={message} />
    )
}

export function showAlert(type, message, isWarning) {
    return (
        <Alert
            className={type === SUCCESS ? "text-success" : "text-danger"}
            icon={type === SUCCESS ? "la la-check mr-2" : "la la-exclamation-triangle mr-2"}
            text={message}
            type={type}
            isWarning={isWarning}
        />
    )
}

export function convertSecToDuration(time, showDots = false, showSeconds = false, roundoffSec = false) { //update it later
    if (time === undefined || time === undefined || isNaN(time)) return "__"
    let minutes = Math.floor(time / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);
    let seconds = Math.floor(time % 3600 % 60);
    minutes = minutes % 60;
    hours = hours % 24;
    days = days % 24;
    let totalTime = time;
    if (roundoffSec && seconds > 32) {
        seconds = 0; //so that it doesnt get displayed again!
        minutes = minutes + 1;
    }
    if (showDots) {
        if (days === 0) {
            totalTime = `${hours}:${minutes}:${seconds}`
        } else {
            totalTime = `${days}:${hours}:${minutes}:${seconds}`
        }
    } else {
        totalTime = `${days ? `${days} ${days > 1 ? `days` : `day`} ` : ``} ${hours ? `${hours}${hours > 1 ? `hrs` : `hr`} ` : ``} ${minutes ? `${minutes}${hours > 1 ? `min` : `min`}` : ``}`; //${seconds && `${seconds} sec, }`}
    }
    if (showSeconds) { //2 = spaces in empty totalTime
        totalTime = `${totalTime.length !== 2 ? `${totalTime} ` : ``}${seconds !== 0 ? `${seconds}sec` : ``}`
    }
    return totalTime;
}

export function ping() { //current Servertime 
    let cache = getDataFromLocalStorage(STORED_SERVER_TIME);
    if (cache) {
        return cache
    }
    let apiName = "ConfigAPI";
    let path = "/ping";
    let myInit = { headers: { 'Content-Type': 'application/json' } };
    return API.get(apiName, path, myInit).then(async (res) => {
        if (res.statusCode === 200 || res.StatusCode === 200 || res.statusCode === 202 || res.StatusCode === 202) {
            await storeDataInLocalStore(STORED_SERVER_TIME, res.body.CurrentTime);
            return res.body.CurrentTime ? res.body.CurrentTime : null;
        }
    }).catch((err) => { return null })
}

export function querystring(name, url = window.location.href) {
    name = name.replace(/[[]]/g, "\\$&");
    let condition = "/login?redirect=/";
    if (url.indexOf(condition) >= 0) {
        const regex = new RegExp("[?&]" + name + "(=([^#]*)|#|$)", "i");
        const results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return "";
        }
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    } else {
        const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
        const results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return "";
        }
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
}

export function debounce(a, b, c) {
    var d, e;
    return function () {
        function h() {
            d = null;
            c || (e = a.apply(f, g))
        }
        var f = this, g = arguments;
        return clearTimeout(d),
            d = setTimeout(h, b), c && !d && (e = a.apply(f, g)), e
    }
}

export function setDocumentTitle(title = ``, defaultTitle = config.applicationName) {
    if (document.title !== title) {
        let tabTitle = defaultTitle;
        if (title !== defaultTitle) {
            title = title ? ` - ${title}` : ``;
            tabTitle = `${defaultTitle}${title}`;
        } else {
            tabTitle = `${title}`;
        }
        document.title = tabTitle;
    }
}

export function calculateProgressBar(total, watched) {
    return (watched / total) * 100;
}

export function handlePinChange(name, value, returnKeyType, handleBackKeyPress = false) {
    this.setState({
        [name]: value
    });
    if (name !== "password") {
        let prevKey = name;
        if (handleBackKeyPress) { //getprevkeyfocu
            if (returnKeyType !== "first") {
                if (this.inputs[prevKey].value === "") {
                    let nextFocuskey = getPrevFocusKey(prevKey); //nextFocuskey ==actually previous
                    if (this.inputs[nextFocuskey]) {
                        this.inputs[nextFocuskey].focus();
                    }
                }
            }
        } else {
            this.focusNextField(prevKey, returnKeyType);
        }
    }
}

export function focusNextField(prevKey, returnKeyType) {
    try {
        setTimeout(() => {
            this.inputs[prevKey].type = "password";
        }, 300);
        if (returnKeyType !== "done") {
            if (this.inputs[prevKey].value !== "") {
                let nextFocuskey = getNextFocusKey(prevKey);
                if (this.inputs[nextFocuskey]) {
                    this.inputs[nextFocuskey].select();
                    this.inputs[nextFocuskey].focus();
                }
            }
        }
    } catch (e) { //
    }
}

function getNextFocusKey(prevKey) {
    let lastcharacter = prevKey.substr(-1);
    prevKey = prevKey.slice(0, -1);
    lastcharacter++;
    let nextKey = `${prevKey}${lastcharacter}`;
    return nextKey;
}

function getPrevFocusKey(currentKey) {
    let lastcharacter = currentKey.substr(-1);
    currentKey = currentKey.slice(0, -1);
    lastcharacter--;
    let prevKey = `${currentKey}${lastcharacter}`;
    return prevKey;
}

export function getPageLayout() {
    let isFullPagelayout = false; //isFullPagelayout => pages like login
    switch (window.location.pathname) {
        case "/login":
        case "/signup":
        case "/confirm":
        case SERVICE_UNAVAILABLE:
        case "/forgot-password":
        case "/change-password":
        case "/reset-password":
            isFullPagelayout = true;
            break;
        default:
            isFullPagelayout = false;
    }
    if (window.location.pathname.substr(0, 3) === "/p/") {    //kind --- for getting partnerpage /signup/p/partner-name =>ALSO on GeoBlock
        isFullPagelayout = true;
    }
    return isFullPagelayout;
}

export function checkPricingTiers(purchaseParams) {
    let tiers = purchaseParams;
    let count = 0;
    let zeroPriceTiersFlag = false;
    for (let i in tiers) {
        if (tiers[i].Price > 0) {
            count++;
        }
    }
    if (count > 0) {
        zeroPriceTiersFlag = true;
    }
    return zeroPriceTiersFlag;
}

export async function getReusableVouchers() {
    let vouchers;
    vouchers = await getDataFromLocalStorage(`${STORED_VOUCHERS}`);
    if (!isEmpty(vouchers)) {
        return vouchers;
    } else {
        let vouchersResponse = await API.get("UserAPI", "/savedVouchersList");
        if (vouchersResponse) {
            if (vouchersResponse.statusMessage === "vouchers_list_fetch_successfull" && vouchersResponse.statusCode === 200) {
                vouchers = vouchersResponse.body.Vouchers;
                storeDataInLocalStore(`${STORED_VOUCHERS}`, vouchers, null, NO_EXPIRY);
                return vouchers;
            }
        }

    }

}