import React from 'react';
import { Modal, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { getVideoQualityData } from "../../helpers/MoviesHelper";
import PropTypes from 'prop-types';
import _reverse from "lodash/reverse";
import { withAppConfig } from '../../context/ConfigContext';
import { withAuth } from '../../context/AuthContext';
import { withRouter } from "react-router-dom";
import { SUBSCRIPTION_HISTORY } from '../../constants/routes';

const PRICE = "price"; const TIERID = "teirId"; const CURRENCYCODE = "currencyCode"; const DISPLAYNAME = "displayName";

class CancelSubscriptionModal extends React.Component { //this is later version of SubscribeModal ,, deleted that later!!
    isCompMounted = true;
    selectedOptions = {};

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: this.getInitialTeirId(),
            modalBackDrop: true,
            activeTab: '1'
        }
    }
    

    componentDidUpdate(prevProps) {
        if (this.props.movieId !== prevProps.movieId) {//this.getInitialTeirId()
            let data = getVideoQualityData(this.props.data, this.props.movieId, this.props.type);
            data = _reverse(data);
            let selected = data[0].teirId;
            this.setState({
                selectedOption: selected
            });
        }
    }

    getInitialTeirId = () => { //same used in compdidUpdate

    };

    componentWillUnmount() {
        this.isCompMounted = false;
    }

    

    // ======= CLOSE MODAL AND RELOAD PAGE =======
    confirmCancellation = () => {
        // this.props.history.replace(SUBSCRIPTION_HISTORY);
        this.props.Click()
        this.props.show(false)
        this.Interval = setInterval(() =>{window.location.reload()}, 2000)

    }

    closeModal = () =>
    {
        this.props.show(false)
    }

    renderCancelSubscriptionModal() {
        const { movieId, history, type, data, showLoader, showAlert, isAuthenticated, dismissSubscribeModal, appTranslations: tr } = this.props;
        return this.props.data && (

                <div className="modal-content">
                    <div className="modal-body p-4 p-sm-5 text-white-50">
                        <div className="card-body text-center position-relative" style={{ "minHeight": "150px" }}>
                            <div className="mn-payment-msg">
                                <div className="mb-5 text-warning"><i class="las la-exclamation-circle la-5x"></i></div>
                                <div className="text-white-70">Are you sure you want to cancel <strong>subscription?</strong></div>
                                {/* <Title {...this.props} /> */}
                                {/* <Duration {...props} /> */}
                                <div className="my-5">
                                    {/* <a href="javascript:void(0)" className="btn btn-primary"></a> */}
                                    <a href="javascript:void(0)" className="btn btn-secondary btn-medium ml-3" onClick={this.closeModal}>No</a>
                                    <a href="javascript:void(0)" className="btn btn-danger btn-medium ml-3" onClick={this.confirmCancellation}>Yes</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }

    render() {
        return (
            <Modal
                isOpen={this.props.showCancelSubscriptionModal}
                backdrop={this.state.modalBackDrop}
                toggle={this.props.handleBackDropClick}
                size="lg"
                centered modalClassName="modal-dark" >
                {this.renderCancelSubscriptionModal()}
            </Modal>
        )
    }
}

CancelSubscriptionModal.defaultProps = {
    data: [],
    movieId: "",
    showLoader: false,
}

CancelSubscriptionModal.propTypes = {
    handleSelectedQualityVideo: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    showAlert: PropTypes.func,
    data: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]).isRequired,
    appSettings: PropTypes.object,
    showCancelSubscriptionModal: PropTypes.bool.isRequired,
    handleBackDropClick: PropTypes.func.isRequired,
    dismissCancelSubscriptionModal: PropTypes.func.isRequired
};
export default withRouter(withAppConfig(withAuth(CancelSubscriptionModal)));
