import React, {Component} from "react";
import _round from "lodash/round";
import {
    getReusableVouchers,
    isEmpty
} from "../../helpers/GeneralHelpers";
import {withAppConfig} from "../../context/ConfigContext";
import VoucherModal from "./VoucherPopup";
import Loader from "../../components/Loader/Loader";
import DataNotFound from "../../components/DataNotFound/DataNotFound";
import moment from "moment";

class StoredVouchers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reusableVoucher: [],
            isLoading:true,
            isVoucherPopupOpen:false
        }
    }
    async componentDidMount() {
        let vouchers = await getReusableVouchers();
        this.setState({
            reusableVoucher: vouchers,
            isLoading:false
        });
    }
    handleStoredVoucherClick = (voucherCode) => {
        this.setState( {
            popUpVoucherCode:voucherCode,
            isVoucherPopupOpen: !this.state.isVoucherPopupOpen,
        });

    };

    renderTableHead() {
        const { appTranslations :tr } = this.props;

        return (
            <thead className="text-white">
            <tr>
                <th>{tr.reward_code}</th>
                <th>{tr.amount}</th>
                <th>{tr.balance_remaining}</th>
                <th>{tr.expiry}</th>
                <th>{tr.Details || "Details"}</th>
            </tr>
            </thead>
        );
    }

    renderTableBody() {
        const { appTranslations :tr } = this.props;

        if (!isEmpty(this.state.reusableVoucher)) {
            let voucherList = this.state.reusableVoucher.map( (voucher, index) =>{
                return (
                    // voucher.BalanceAmount > 0 &&
                    <tr key={index}>
                        <td><a className={"text-white"} href="javascript:void(0);" onClick={() => this.handleStoredVoucherClick(voucher.Voucher)}> {voucher.Voucher}</a></td>
                        <td>${_round(voucher.Amount,2) || 0}</td>
                        <td>${_round(voucher.BalanceAmount,2) || 0}</td>
                        <td>{moment(voucher.Expiry).format("DD MMMM YYYY")}</td>
                        <td><button onClick={() => this.handleStoredVoucherClick(voucher.Voucher)} className="btn btn-xs btn-primary ">{tr.view_detail}</button> </td>
                    </tr>
                )
            });
            return (
                <tbody>
                {voucherList}
                </tbody>
            )
        }
    }
    loadingState =()=>{
        this.setState({
            isLoading:false
        });
    };

    render() {
        const { appTranslations :tr } = this.props;
        return (
            <main className="mn-main">
                <section className="mn-page-head py-4">
                    <div className="container">
                        <h3 className="mb-0 text-center">{tr.saved_reward_codes}</h3>
                    </div>
                </section>
                <section className="mn-rewards py-5">
                    <div className="container">
                        <div className="mn-rewards-body">
                            {this.state.isLoading ?
                                <Loader/>
                                :
                                isEmpty(this.state.reusableVoucher) ?
                                    <DataNotFound
                                        height="400px"
                                        text={tr.you_dont_have_any_saved_reward_codes || "You don't have any saved reward codes"}
                                    />
                                    :
                                    <div className="table-responsive">
                                        <table className="table mn-reward-table table-hover"
                                               style={{minWidth: "670px"}}>
                                            {this.renderTableHead()}
                                            {this.renderTableBody()}
                                        </table>
                                    </div>
                            }
                        </div>
                    </div>
                </section>

                {this.state.isVoucherPopupOpen &&
                <VoucherModal
                    isOpen={this.state.isVoucherPopupOpen}
                    handleBackDropClick={this.handleStoredVoucherClick}
                    voucherCode ={this.state.popUpVoucherCode}
                    isAuthenticated={this.props.isAuthenticated}
                    {...this.props}
                />}
            </main>
        );
    }
}

export default withAppConfig(StoredVouchers);