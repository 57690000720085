import React from "react";
import Wrapper from "./Wrapper";
import Languages from "./Languages";
import Subscribe from "./Subscribe";
import Heading from "../../../../components/RoomCollection/Heading";
import Quality from "../../../../components/RoomCollection/Quality";

const HeroSection = ({ data }) => {
  return (
    <Wrapper PosterUrl={data.PosterUrl} PreviewImage={data.PreviewImage} RoomData={data}>
        {/* <Heading DetailType={"screening_room"} {...data} /> */}
        <Quality {...data} />
        <div className="mb-3">
          {data.Description}
        </div>
        <Languages />
        {/* <div className="">
          <div className="fab">
            <span className="fab-action-button" title="Share on">
              <i className="la la-share-alt" />
            </span>
          </div>
        </div> */}
    </Wrapper>
  );
};

export default HeroSection;
