import React, { useState, useEffect } from "react";
import { setDocumentTitle } from "../../../helpers/GeneralHelpers";
import Loader from "../../../components/Loader/Loader";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";
import { SortRentPurchaseData } from "../../../helpers/ResponseHelpers";
import { withAuth } from "../../../context/AuthContext";
import { withAppConfig } from "../../../context/ConfigContext";
import List from "./List";
import Header from "./Header";
const Rental = 0;

const MyRentals = props => {
  const [list, setList] = useState([]);

  useEffect(() => {
    setDocumentTitle(`My Rentals`);
    initialize();
    return () => {};
  }, [props.purchases]);

  const initialize = () => {
    let purchases = SortRentPurchaseData(props.purchases);
    setList(purchases);
  };

  //get it from auth context
  if (props.isLoading) {
    return (
      <section>
        <Loader />
      </section>
    );
  }

  if (list && list.length < 1) {
    return (
      <div>
         <section className="mn-page-head py-4 bg-gray-800">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <h3 className="text-white text-uppercase font-weight-bold mb-0">my rentals</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="mn-movies py-4">
        <div className="container-fluid">
          <DataNotFound text="You have no item(s) in My Rentals" />
        </div>
      </section>
      </div>
    );
  }

  return list.map((section, index) => {
    if (index !== Rental && section && section.length < 1) {
      //dont show section/header for empty owned movies
      return null;
    }

    return (
      <div>
        <section className="mn-page-head py-4 bg-gray-800">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <h3 className="text-white text-uppercase font-weight-bold mb-0">my rentals</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="mn-movies pt-5" key={index}>
        <div className="container-fluid">
          <Header index={index} tr={props.appTranslations} />
          <List index={index} section={section} />
        </div>
      </section>
      </div>
    );
  });
};

export default withAuth(withAppConfig(MyRentals));
