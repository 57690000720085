import { GET } from "../../api/";
import _concat from "lodash/concat";

export async function GetSubscriptionsHistory(callback, { }) {
  let response = await fetchAllSubscriptions();
  if (response) {
    callback(response);
  } else {
    callback(null, "Failed");
  }
  return;
}

function fetchAllSubscriptions(LastEvaluatedKey = ``, data = []) {
  let path = `/my-subscription-history?Limit=${12}&ExclusiveStartKey=${LastEvaluatedKey}`;
  return GET("OrdersAPI", path)
    .then(res => {
      if (res.body && res.body.Items) {
        let result = _concat(data, res.body.Items);
        if (res.body.LastEvaluatedKey) {
          return fetchAllSubscriptions(res.body.LastEvaluatedKey, result);
        } else {
          return result;
        }
      }
    })
    .catch(e => {
      return null;
    });
}
