import React, { Component } from "react";
import Auth from "@aws-amplify/auth";
import API from "@aws-amplify/api";
import { Modal } from "reactstrap";
import ChangePassword from "../ChangePassword/ChangePassword";
import { updateApiWithResponse } from "../../helpers/APIHelpers/MoviesAPI";
import Formsy from 'formsy-react';
import { handleChange, enableButton, disableButton, disableAlert } from "../../helpers/StateHelper";
import { getValueFromObj, jsDateToYYYYMMDD, setDocumentTitle, importMoment } from "../../helpers/GeneralHelpers";
import { renderNotificationMessage } from "../../helpers/ViewHelpers";
import { UPDATE_PROFILE_PHOTO, PROFILE_PAGE, YearsBack } from "../../constants/constants";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import MyInput from "../../components/MyInput";
import MySelect from "../../components/MySelect";
import RenderScreen from "../../components/RenderScreen/RenderScreen";
import placeholder from "../../assets/images/placeholder.png";
import { getInputFields } from "./constanst"
import { withAppConfig } from "../../context/ConfigContext";

class Profile extends Component {
    isCompMounted = true;
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isError: "",
            USERID: "",
            email: "",
            firstname: "",
            phone_number: "",
            lastname: "",
            dob: {},
            gender: "",
            uploadedFileName: "",
            isSubmittingProfile: false,
            showErrorAlert: false,
            showSuccessAlert: false,
            alertMessage: "",
            dataPicker: null,
            profileImage: "",
            avatarEditor: null,
            isUploadingProfile: false,
            showImageEditor: false,
            showPasswordFormModal: false
        };
        this.handleChange = handleChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.enableButton = enableButton.bind(this);
        this.disableButton = disableButton.bind(this);
        this.getValueFromObj = getValueFromObj.bind(this);
        this.renderNotificationMessage = renderNotificationMessage.bind(this);
        this.disableAlert = disableAlert.bind(this);
        this.updateProfilePicture = updateApiWithResponse.bind(this);
    }

    componentDidMount() {
        this.initialize();
    }

    componentWillUnmount() {
        this.isCompMounted = false;
    }

    initialize = () => {
        setDocumentTitle(PROFILE_PAGE);
        this.importProfileEditor();
        import('react-datepicker/dist/react-datepicker.css').catch(() => {  /*error loading date picker css */ });
        importMoment(false).then((moment) => {
            this.moment = moment;
            return import("react-datepicker")
        }).then((DatePicker) => {
            Auth.currentAuthenticatedUser().then((res) => {
                let Image = getValueFromObj('picture', res.attributes);
                let updatedMoment = this.moment().subtract(YearsBack, "y");// initiaze calender __ years back.
                let dateOfBirth = getValueFromObj('birthdate', res.attributes);//"2018-11-19"//
                if (dateOfBirth && dateOfBirth !== '') {
                    dateOfBirth = this.moment(dateOfBirth, ["YYYY/MM/DD", "MM/DD/YYYY", "DD/MM/YYYY"]);
                    let isValid = this.moment(dateOfBirth, "YYYY/MM/DD").isValid();
                    if (isValid) {
                        dateOfBirth = this.moment(dateOfBirth).toDate();
                    } else {
                        dateOfBirth = null
                    }
                } else {
                    dateOfBirth = null
                }
                this.setState({
                    isLoading: false,
                    USERID: res.username,
                    dataPicker: DatePicker.default,
                    email: getValueFromObj('email', res.attributes),
                    phone_number: getValueFromObj('phone_number', res.attributes),
                    gender: getValueFromObj('gender', res.attributes),
                    firstname: getValueFromObj('given_name', res.attributes),
                    lastname: getValueFromObj('family_name', res.attributes),
                    dob: dateOfBirth,
                    defaultDOB: dateOfBirth ? dateOfBirth : updatedMoment.toDate(),
                    profileImage: Image,
                });
            })
        }).catch((err) => {
            this.setState({
                isError: "Something Went Wrong",
                isLoading: false
            })
        })
    }

    importProfileEditor() {
        import('react-avatar-editor').then((avatarEditor) => {
            this.setState({
                avatarEditor: avatarEditor.default
            })
        })
    }


    handleChangeDate(date) {
        this.setState({ "dob": date });
    }

    handleProfileSubmit = async (event, formReset, invalidateForm) => {
        const { appTranslations : tr } = this.props;
        this.setState({
            isSubmittingProfile: true,
        });
        let user = await Auth.currentAuthenticatedUser()

        Auth.updateUserAttributes(user, {
            gender: this.state.gender,
            given_name: this.state.firstname,
            family_name: this.state.lastname,
            birthdate: jsDateToYYYYMMDD(this.state.dob),
        }).then(() => {

            let apiName = 'AuthAPI';
            let path = '/updateprofileinfo';
            let myInit = {
                body: {
                    "FirstName": this.state.firstname,
                    "LastName": this.state.lastname,
                    "Gender": this.state.gender,
                    "DOB": this.state.dob.toISOString(),
                },
                headers: { 'Content-Type': 'application/json' }
            };

            return API.post(apiName, path, myInit)
                .then((res) => {
                    this.props.updateUserData(false,true);
                    this.setState({
                        isSubmittingProfile: false,
                        showSuccessAlert: true,
                        alertMessage: tr.profile_updated_successfully
                    });
                    this.disableAlert("showSuccessAlert");

                })
                .catch((err) => {
                    if (err.constructor === Array || err.constructor === Object || err.constructor === Error) {
                        err = "Something went wrong!";
                    }
                    this.setState({
                        isSubmittingProfile: false,
                        showErrorAlert: true,
                        alertMessage: err
                    });
                    this.disableAlert("showErrorAlert", "alertMessage");
                });
        }).catch((err) => {
        });
    }

    saveProfileData = async (image) => {
        let user = await Auth.currentAuthenticatedUser()
        Auth.updateUserAttributes(user, {
            picture: image
        }).then(() => {
            this.props.updateUserData(false);
         }).catch((err) => {
            this.setState({
                showSuccessAlert: false,
                showErrorAlert: true,
                alertMessage: err.message !== undefined ? `Cognito: ${err.message}` : "Couldn't Save in Cognito"
            });
            this.disableAlert("showErrorAlert");
        });
    }

    updateProfileData = (image) => {
        this.saveProfileData(image);
    }

    onChangeProfile = (e) => {
        let reader = new FileReader();
        const file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                uploadedAvatar: reader.result,
                isUploadingProfile: true,
                showImageEditor: true
            })
        };
        if (file) {
            reader.readAsDataURL(file); //sometimes gives error,-- discuss it
        }
    }

    togglePasswordModal = () => {
        this.setState((prevState) => {
            return {
                showPasswordFormModal: !prevState.showPasswordFormModal
            }
        })
    }

    showAlertMessage = (alertMessage, AlertType) => {
        this.setState({
            alertMessage: alertMessage,
            [AlertType]: true
        });
        this.disableAlert(AlertType)
    }

    renderChangePasswordModal() {
        const { appTranslations : tr } = this.props;
        return (
            <Modal isOpen={this.state.showPasswordFormModal} toggle={this.togglePasswordModal} className="modal-dark modal-dialog-centered" data-backdrop="static" data-keyboard="false">{/*id="changePassword" */}
                <div className="modal-content">
                    <div className="modal-header px-4 px-sm-5">
                        <h5 className="modal-title">{tr.change_password}</h5>
                    </div>
                    <div className="modal-body p-4 p-sm-5">
                        <ChangePassword
                            showAlertMessage={this.showAlertMessage}
                            togglePasswordModal={this.togglePasswordModal}
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={this.togglePasswordModal}>{tr.close}</button> {/* data-dismiss="modal"*/}
                    </div>
                </div>
            </Modal>
        )
    }

    renderProfileImage = () => {
        let profileImageBig = ((this.state.profileImage) && this.state.profileImage !== "") ? this.state.profileImage : placeholder;
        if (!this.state.isUploadingProfile && !this.state.isLoading) {
            return (
                <div className="form-group text-center">
                    <div className="mn-user-photo" style={{ width: '230px', backgroundImage: `url(${placeholder})` }}>
                        <div className="rounded-circle img-bg img-1x1" style={{ width: '230px', backgroundImage: `url(${profileImageBig})` }}></div>
                        {/* <img className="rounded-circle"
                            style={{ width: "130px" }}
                            src={profileImageBig}
                            alt="..."
                        /> */}
                        <label className="btn btn-primary btn-icon rounded-circle mn-user-plus">
                            <i className="la la-plus"></i>
                            <input className="d-none"
                                type="file"
                                accept="image/*"
                                onChange={this.onChangeProfile}
                            />
                        </label>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="form-group text-center">
                    <div className="mn-user-photo">
                        <div className="rounded-circle img-bg img-1x1" style={{ width: '230px', backgroundImage: `url(${placeholder})` }}></div>
                        <span className="rounded-circle loader-spiral mn-white-transparent"><span className="spiral-inn"></span></span>
                    </div>
                </div>
            )
        }
    }

    toggleAvatarModal = (cancel = true) => {
        if (cancel) {
            this.setState((prevstate) => {
                return {
                    showImageEditor: !prevstate.showImageEditor,
                    isUploadingProfile: !prevstate.isUploadingProfile,
                }
            })
        } else {
            this.setState((prevstate) => {
                return {
                    showImageEditor: !prevstate.showImageEditor
                }
            })
        }
    }

    onSubmitCropping = () => {
        const { appTranslations : tr } = this.props;
       let success_message = tr.profile_picture_updated_successfully || "Profile Picture Updated Successfully!";
       let error_message = tr.couldnt_upload_profile_picture || "Couldn't Upload Profile Picture";
        this.toggleAvatarModal(false);
        try {
            const canvas = this.editor.getImage().toDataURL();
            let imageURL;
            fetch(canvas).then(res => res.blob()).then(blob => (imageURL = window.URL.createObjectURL(blob)));
            this.setState({
                profileImage: canvas
            });
            let uploadImage = canvas.replace("data:image/png;base64,", "");
            let header = {image: uploadImage};
            this.updateProfilePicture("AuthAPI", "/uploadProfilePicture", header, canvas, "showSuccessAlert", "showErrorAlert", "alertMessage", success_message, error_message, UPDATE_PROFILE_PHOTO, "isUploadingProfile", "profileImage");
        } catch (e) {
            this.setState({
                isSubmittingProfile: false,
                isUploadingProfile: false,
                showErrorAlert: true,
                alertMessage: "Please use a proper image"
            });
            this.disableAlert("showErrorAlert");
            return [];
        }
    };

    setEditorRef = (editor) => this.editor = editor;

    renderAvatarEditorModal = () => {
        const { appTranslations : tr } = this.props;
        const { avatarEditor: AvatarEditor } = this.state;
        return (
            <Modal isOpen={this.state.showImageEditor} className="modal-dialog-centered modal-dark">
                <div className="modal-content">
                    <div className="modal-header px-4 px-sm-5">
                        <h5 className="modal-title">{tr.position_and_size_your_new_avatar || "Position and size your new avatar"}</h5>
                    </div>
                    <div className="modal-body p-4 p-sm-5 text-white-50">
                        <div className="text-center position-relative" style={{ "minHeight": "100px" }}>
                            {AvatarEditor ?
                                <AvatarEditor
                                    ref={this.setEditorRef}
                                    image={this.state.uploadedAvatar}
                                    width={200}
                                    height={200}
                                    border={50}
                                    borderRadius={100}
                                    color={[255, 255, 255, 0.6]} // RGBA
                                    scale={1.2}
                                    rotate={0}
                                />
                                :
                                <span className="loader-spiral mn-white-transparent"><span className="spiral-inn"></span></span>
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div>
                            <button type="button" className="btn btn-primary" onClick={this.onSubmitCropping}>{tr.save_avatar || "Save Avatar"}</button>
                            <button type="button" className="btn btn-secondary ml-2" onClick={this.toggleAvatarModal}>{tr.cancel || "Cancel"}</button>
                        </div>
                    </div>
                </div>
            </ Modal>
        )
    }

    renderProfile() {
        const { dataPicker: DatePicker } = this.state;
        const { appTranslations : tr } = this.props;
        let InputFields = getInputFields(tr, this.state.phone_number);
        return (
            <section className="mn-edit-pro py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="card card-dark">
                                <div className="card-body p-3 p-md-5">
                                    {this.renderNotificationMessage()}
                                    {this.renderChangePasswordModal()}
                                    {this.renderAvatarEditorModal()}
                                    <Formsy onValidSubmit={this.handleProfileSubmit}>
                                        {/* <h4 className="mb-3 text-center">{tr.edit_profile}</h4> */}
                                        <div className="row align-items-center">
                                            <div className="col-md-5">
                                                {this.renderProfileImage()}
                                            </div>
                                            <div className="col-md-7">
                                                {InputFields && InputFields.map((input, index) => {
                                                    const { Name, Type, Placeholder, ValidationError, Validations, Disabled } = input;
                                                    return (
                                                        <MyInput
                                                            key={index}
                                                            id={Name}
                                                            name={Name}
                                                            type={Type}
                                                            placeholder={Placeholder}
                                                            disabled={Disabled}
                                                            validations={Validations}
                                                            validationError={ValidationError}
                                                            innerClassName="form-control-dark"
                                                            outerClassName="mb-4"
                                                            value={this.state[Name]}
                                                            handleChange={this.handleChange}
                                                        />
                                                    )
                                                })}
                                                <div className="row gutter-7">
                                                    <div className="col-sm-6">
                                                        <div className="form-group mb-4">
                                                            <MySelect
                                                                id="gender"
                                                                name="gender"
                                                                title="Gender"
                                                                innerClassName="form-control-dark"
                                                                outerClassName="mb-4"
                                                                value={this.state.gender}
                                                                handleChange={this.handleChange}
                                                                options={[
                                                                    { value: "", title: tr.select_gender },
                                                                    { value: "Male", title: "Male" },
                                                                    { value: "Female", title: "Female" },
                                                                ]}
                                                            />
                                                        </div>
                                                    </div>
                                                    {DatePicker &&
                                                        <div className="col-sm-6">
                                                            <div className="form-group mb-4">
                                                                <DatePicker
                                                                    id="dob"
                                                                    name="dob"
                                                                    dateFormat="yyyy/MM/dd"
                                                                    calendarClassName="rasta-stripes"
                                                                    scrollableYearDropdown={true}
                                                                    showYearDropdown={true}
                                                                    placeholderText={tr.select_dob}
                                                                    openToDate={this.state.defaultDOB}
                                                                    className="form-control form-control-dark"
                                                                    selected={this.state.dob}
                                                                    onChange={this.handleChangeDate}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="">
                                                    <LoaderButton
                                                        type="submit"
                                                        className="btn btn-primary btn-block text-uppercase"
                                                        isLoading={this.state.isSubmittingProfile}
                                                        text={tr.save}
                                                        loadingText={`${tr.saving} ...`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Formsy>
                                </div>
                                <div className="card-footer">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">

                                        </div>
                                        <div className="col-md-6 text-center">
                                            <div>{tr.change_password}? <a className="btn btn-outline-primary ml-3" onClick={this.togglePasswordModal}>{ tr.click_here || "Click here"}</a></div> {/* data-toggle="modal"*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        )
    }


    render() {
        return (
            <main className="mn-main">
                <section className="mn-page-head py-4">
                    <div className="container">
                        <h3 className="mb-0 text-center text-uppercase">Edit Profile</h3>
                    </div>
                </section>
                <RenderScreen
                    isLoading={this.state.isLoading}
                    isError={this.state.isError}
                >
                    {this.renderProfile()}
                </RenderScreen>
            </main>
        );
    }
}
export default withAppConfig(Profile);
