import _merge from "lodash/merge";

export function handleChange(name, value) {
    console.log(name, value)
    this.setState({ [name]: value });

}

export function enableButton() {
    this.setState({ canSubmit: true });
}

export function disableButton() {
    this.setState({ canSubmit: false });
}

export function updatedNestedState(data, stateName, objectName, value) {
    let newObj = {};
    newObj[objectName] = value;
    let result = _merge(data, newObj);
    this.setState({
        [stateName]: result
    });
}

export function disableAlert(name, message) {
    setTimeout(() => {
        if (this.isCompMounted) {
            this.setState({
                [name]: false,
                [message]: ""
            });
        }
    }, 10000);
}
