import React from 'react'
import { withAppConfig } from '../../../context/ConfigContext'

const TotalBill = (props) => {
    return (
        <div className="pt-3 mt-3 border-top border-dark">
            <div className="row gutter-5">
                <div className="col-6 col-sm-3">
                    <div className="text-white font-weight-normal">{props.appTranslations.total_billed || "Total Billed"}</div>
                </div>
                <div className="col-sm-6 text-center d-none d-sm-block">
                    <div className="font-14 mn-points-2">

                    </div>
                </div>
                <div className="col-6 col-sm-3 text-right">
                    {/* {props.ContractTier && props.ContractTier.CurrencySymbol ? props.ContractTier.CurrencySymbol : ""} */}
                    {props.appSettings.CurrencySymbol}{props.Total || props.Price}
                </div>
            </div>
        </div>
    )
}

export default withAppConfig(TotalBill)
