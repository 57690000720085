import React from "react";
import { Collapse } from "reactstrap";
import Adyen from "../../components/Adyen";
import Stripe from "../../components/Stripe";
import { withAppConfig } from "../../../../context/ConfigContext";
import TotalPrice from "./TotalPrice";

const PaymentCardPanel = props => {
  const onPanelToggle = () => {
    props.onPanelToggle("formpanel");
  };
  return (
    <div className="">
        <div className="card card-dark mb-3" >
      <div
        onClick={onPanelToggle}
        className={`card-header pointer text-white ${
          props.isPanelSelected ? `` : `collapsed`
          } `}
      >
        <div className="d-flex justify-content-between">
          <div className="">
            <span>
              <i className="la font-18 la-circle-o pay-select mr-3" />
            </span>
            <span>{}</span>
          </div>
          <div className="">
            <i className="las la-angle-down" />
          </div>
        </div>
      </div>
      <Collapse isOpen={props.isPanelSelected}>
        <div className="card-body position-relative">
          <TabPanes {...props} />
        </div>
      </Collapse>
      </div>
    </div>
  );
};

const TabPanes = props => {
  let showSaveCard = true;
  if (props.Type === "screening_room" && props.data && props.data.IsTrial === "active") {
    if (!props.cardsList || props.cardsList.length < 1) {
      showSaveCard = false;
    }
  }
  return (
    <div>
      {props.paymentGateway &&
        props.paymentGateway.map((gateway, index) => {
          return (
            <div
              key={index}
              className="text-center"
              style={{ minHeight: "200px" }}
            >
              <div className="bt-block">
                <Loader isCheckingOut={props.isCheckingOut} />
                <div className="payment-head">
                  <div className="mb-3">
                    {props.appTranslations.your_payable_amount_is || "Your Payable Amount is"}
                    <span className="text-white font-weight-bold ml-1">
                    {props.appSettings.CurrencySymbol}{props.checkoutData.Price}
                    </span>
                  </div>
                </div>
                <hr/>
                {gateway.Type && gateway.Type.toLowerCase() === "adyen" ? (
                  <Adyen
                    {...props}
                    price={props.checkoutData.Price}
                    checkoutParams={props.checkoutData}
                  />
                ) : null}

                {/* {gateway.Type && gateway.Type.toLowerCase() === "braintree" ? (
                  <BrainTree
                    showSaveCardOption={showSaveCard}
                    price={props.checkoutData.Price} //update currency later
                    clientToken={props.clientToken}
                    checkoutParams={{}}
                    transactionSubmitted={props.transactionSubmitted}
                    onClickPaymentForm={props.onClickPaymentForm}
                    showFooterText={true}
                    NoToken={true}
                    buttonText={
                      props.appTranslations.pay_with_card || "Pay with Card"
                    }
                    {...props}
                  />
                ) : gateway.Type.toLowerCase() === "stripe" ? (
                  <Stripe
                    checkoutParams={{}}
                    onClickPaymentForm={props.onClickPaymentForm}
                    {...props}
                  />
                ) : null} */}
              </div>
            </div>
          );
        })}
    </div>
  );
};

const Loader = props => {
  if (props.isCheckingOut) {
    return (
      <span className="loader-spiral mn-transparent">
        <span className="spiral-inn" />
      </span>
    );
  }
  return null;
};

export default withAppConfig(PaymentCardPanel);
