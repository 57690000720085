import React, { Component, memo } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { RESUME, WATCHED, PURCHASE_HISTORY, MOVIE_VIDEO, ExpiringStripTime, OWNED } from "../../constants/constants";
import { calculateProgressBar } from "../../helpers/MoviesHelper";
import { WATCH } from "../../constants/routes";
import ComingSoon from '../ComingSoon';
import ComingSoonTag from "./components/ComingSoonTag";
import PurchaseTag from './components/PurchaseTag';
import { convertSecToDuration } from '../../helpers/GeneralHelpers';
import RentalLocks from './components/RentalLocks';
import { withAuth } from '../../context/AuthContext';
import { withAppConfig } from '../../context/ConfigContext';
import SubscribeModal from "../SubscribeModal/SubscribeModal";
import { RemoveMovieFromMyList } from "../../services/user/RemoveMyList";
class MovieCard extends Component {


    constructor(props) {
        super(props);
        this.state = {
            isRemoving: false,
            showSubscribeModal: false
        }
    }

    renderTags = ({ purchaseType_, isExpired_, isPurchased_ }) => {
        return (
            <PurchaseTag
                purchaseType_={purchaseType_}
                isExpired_={isExpired_}
                isPurchased_={isPurchased_}
                isCExpired_={this.props.movie.isCExpired_}
                isCPurchased_={this.props.movie.isCPurchased_}
            />
        )
    }

    movieClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { type, movie } = this.props;
        const movieID = movie.MovieID || movie.ID;
        if (this.props.className !== " faded mb-4") { //no click handler for disabled movies + give beginning space
            if (this.props.movieClick) {
                this.props.movieClick(movieID, type);
            }
            this.props.history.push(`/movie/${movieID}`);
        }
    }

    handleMoviePlayIconClick = (e) => {
        const { ID, MovieID } = this.props.movie;
        let movieID = MovieID || ID;
        e.preventDefault();
        e.stopPropagation();
        let { movie } = this.props;
        if (this.props.isAuthenticated && ((movie.isPurchased_ && !movie.isExpired_) || movie.isSubscribed_ || movie.isCPurchased_)) {
            this.props.history.push(`${WATCH}${MOVIE_VIDEO}/${movieID}`)
        } else {
            this.props.history.push(`/movie/${movieID}`);
        }
    }
    onClickUnLike = e => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            isRemoving: false
        })
        // setDelete(true);
        let params = {
            ID: this.props.movie.ID,
            UserID: this.props.UserID,
            cdnUrl: this.props.HostName
        };
        console.log("params", params)
        RemoveMovieFromMyList(this.onAPIresponse, params);
    };

    onAPIresponse = (err, response) => {
        if (!err) {
            let { appTranslations: tr } = this.props;
            let removed = tr.removed_from_list || "Removed from My List!";
            this.props.showAlert("showSuccessAlert", removed);
            this.props.onDelete(response);
        }
        this.setState({
            isRemoving: false
        })
    };

    handleMovieSubscribeIconClick = (e) => {
        const { ID, MovieID } = this.props.movie;
        let movieID = MovieID || ID;
        e.preventDefault();
        e.stopPropagation();
        let { movie } = this.props;
        if (this.props.isAuthenticated && ((movie.isPurchased_ && !movie.isExpired_) || movie.isSubscribed_ || movie.isCPurchased_)) {
            this.props.history.push(`${WATCH}${MOVIE_VIDEO}/${movieID}`)
        } else {
            this.setState({ showSubscribeModal: true })
        }
    }


    onClickDelete = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClickDelete(this.props.movie.ID)
    }

    renderMyListDeleteIcon = (id) => {
        return (
            this.props.DeleteLike //DeleteLike is a component!!
        )
        return (
            this.props.deleteIcon ?
                <div className="mn-thumb-overlay top">
                    {this.props.isremovingMovieLike[id] ?
                        <i className="la la-spin fa-spinner"></i>
                        :
                        <span onClick={this.onClickDelete} className="btn-del"><i className="la la-trash-o"></i></span>
                    }
                </div>
                :
                null)

    }

    renderHoverDetailRent = (movie) => {
        const { appTranslations: tr } = this.props;
        if (this.props.showHoverDetailandRent) {
            let Text = this.props.showSpinner ? <i className="la la-spin fa-spinner"></i> : (movie.isPurchased_ && !movie.isExpired_) ? tr.watch_now || "Watch Now" : tr.rent || "Rent"
            return (
                <div className="mn-movie-action">
                    <div className="movie-action-inn"> {/*href="#rentModal" data-toggle="modal"*/}
                        {(movie.isAvailableForBuy || movie.isAvailableForRent) &&
                            <div className="mb-2"><button onClick={(e) => this.props.handleRentMovieClick(e, movie)} href="javascript:void(0)" className="btn btn-sm btn-primary ml-3 btn-medium">{Text}</button></div>}
                        <div className="mb-2"><button onClick={this.movieClick} href="javascript:void(0)" className="btn btn-sm btn-secondary ml-3 btn-medium">{tr.detail || "Detail"}</button></div>
                    </div>
                </div>
            )
        }
        return null
    }


    renderHoverPlayIcon = (movieId, movie) => { //not used
        if (this.props.isAuthenticated && this.props.showHoverPlayIcon) {
            if (this.props.type.toLowerCase() !== RESUME || this.props.type.toLowerCase() !== WATCHED || this.props.type.toLowerCase() !== PURCHASE_HISTORY && this.props.type !== "") {
                return (
                    (movie.isPurchased_ && !movie.isExpired_) ?
                        <div className="mn-movie-overlay" onClick={this.handleMoviePlayIconClick}>
                            <span className="btn-play mn-movie-play"><i className="la la-play"></i></span>
                        </div>
                        :
                        null
                )
            }
        }
    }

    renderHover = () => {
        const { type, movie, appTranslations: tr } = this.props;
        const { HVSD, ReleaseYear = ``, MovieReleaseYear, PGRating = ``, MoviePGRating, Genre = [], MovieGenre = [], Title = ``, MovieTitle, PlayDuration = ``, MoviePlayDuration } = movie;
        if (this.props.className === "faded mb-4") {
            return null;
        }
        let genre = MovieGenre.length > 0 ? MovieGenre : Genre;
        let playDuration = (MoviePlayDuration || MoviePlayDuration === 0) ? MoviePlayDuration : PlayDuration;
        let title = MovieTitle || Title || "";
        return (
            <div className="movie-card-upper">
                {/* <div className="upper-dock text-right">
                    <button className="link-white-70"><i className="la la-heart-o"></i></button>
                    {/* Add Class "active" to Button and replace "fa-heart" with "fa-heart-o" when item is added to my list
                </div> */}
                <div className="upper-dock-body d-flex align-items-center justify-content-center h-100">
                    <div className="w-100">
                        <h4 className="mb-4 text-white">{movie.MovieTitle || movie.Title}</h4>
                        <div className="mb-2">
                            <span>{ReleaseYear || MovieReleaseYear}</span>
                            <span className="ml-2">{convertSecToDuration(playDuration)}</span>
                        </div>
                        <div className="mb-2">
                            {genre.map((gen, index) => {
                                if (gen && index < 2) {
                                    return gen.charAt(0).toUpperCase() + gen.slice(1) + `${(index === 0 && genre.length > 1) ? ", " : ``}`;
                                }
                            })}
                        </div>
                        <div className="mb-2">
                            <span></span>
                            {((ReleaseYear && PGRating) || (MoviePGRating && MovieReleaseYear)) && <span className="px-2">|</span>}
                            <span>{PGRating || MoviePGRating}</span>
                        </div>

                        {<RentalLocks
                            isAuthenticated={this.props.isAuthenticated}
                            movie={movie}
                            listing={this.props.listingMovies}
                            isSubscribed_={this.props.isSubscribed_}
                            isCPurchased_={this.props.isCPurchased_}
                            handleMoviePlayIconClick={this.handleMoviePlayIconClick}
                            handleMovieSubscribeIconClick={this.handleMovieSubscribeIconClick}
                        />}
                        <div className="mb-3" onClick={this.movieClick}>
                            <button className="btn btn-outline-primary btn-medium font-weight-bold"> <i className="la la-info-circle mr-1"> </i> {tr.more || "More"}</button>
                        </div>
                        {(HVSD && this.props.ServerTime) &&
                            <ComingSoon
                                HVSD={HVSD}
                                ServerTime={this.props.ServerTime}
                            />}
                        {this.renderTimeStrip(movie)}
                        {this.state.isRemoving ? (
                            <i className="la la-spin fa-spinner" />
                        ) : (
                                this.props.onDelete ?
                                    <div className="pt-2">
                                        <button className="btn btn-danger btn-medium" onClick={this.onClickUnLike}><i class="las la-trash mr-1"></i> Remove</button>
                                    </div> : null
                            )}

                    </div>
                </div>
            </div>
        )
    }

    renderTimeStrip = (movie) => {
        const { appTranslations: tr } = this.props;
        return (
            (movie.isPurchased_ && movie.expiringTime_ !== "" && movie.showExpiringtimeStrip_) &&
            <div className="font-12 text-danger">
                <div className="text-truncate">{movie.purchaseType_ === OWNED ? tr.validity_lifetime || "Validity : Lifetime" : movie.expiringTime_}</div>
            </div>
        )
    }

    renderPurchaseHistoryTimeStrip = () => { ///*replace this isRented condition with following ----movie.showExpiringTimeStrip_ &&*/
        return (this.props.isRented &&
            <div className={(this.props.expiringTimeStrip > ExpiringStripTime) ? "px-2 py-1 bg-gray-800 text-white-50 text-center font-12" : "px-2 py-1 bg-primary text-white text-center font-12 "}>
                <div className="text-truncate">{this.props.rentedDuration}</div>
            </div>
        )
    }

    renderSubscribeModal = () => {
        const activeScreeningRoom = this.props.movie.ScreeningRooms.find((sr) => sr.Status == 'active');
        return (
            <SubscribeModal
                showSubscribeModal={this.state.showSubscribeModal}
                dismissPurchaseModal={() => this.setState({ showSubscribeModal: false })}
                handleBackDropClick={() => this.setState({ showSubscribeModal: false })}
                isAuthenticated={this.props.isAuthenticated}
                handleSelectedQualityVideo={this.handleSelectedQualityVideo}
                data={activeScreeningRoom}
            />)
    }

    ProgressBar(movie, ID) {
        if ((this.props.type.toLowerCase() === RESUME || this.props.type.toLowerCase() === WATCHED) && (movie.MoviePlayDuration && movie.TotalDuration)) {
            return (
                <div className="progress mn-progress">
                    <div className="progress-bar bg-primary" style={{ width: `${calculateProgressBar(movie.MoviePlayDuration, movie.TotalDuration)}%` }}></div>
                </div>
            )
        }
        return null;
    }


    render() {
        const { className } = this.props;
        const { movie, showCardHoverIcons, showComingSoonTag } = this.props;
        const ID = movie.MovieID || movie.ID;
        const PosterURL = movie.MoviePosterURL || movie.PosterURL;
        const crownTag = movie.isAvailableForSubscription || this.props.subscription || this.props.crownTagValue
        if ((!movie.Status || movie.Status !== "inactive") && !movie["HideExpiredRental_"]) {
            return (
                <div className="col-sm-2" title={movie.MovieTitle || movie.Title}>
                    <a className={"mn-movie " + className} onClick={this.movieClick}>
                        <div className="mn-movie-top">
                            <div className="mn-movie-figure">
                                <div className="mn-movie-thumb img-bg img-1x15"
                                    style={{ backgroundImage: `url(${PosterURL})` }}
                                >
                                </div>
                            </div>
                            {this.renderHover()}
                            {!crownTag ? <div className="mn-premium"><i className="las la-crown"></i></div> : null}
                        </div>
                        {this.renderHoverDetailRent(movie, ID)} {/*for reward */}
                        {/* {this.renderTags(movie)}  */}
                        {/* before ----> showCardHoverIcons ? then show !! */}
                        {/* <SubscribedTag isSubscribed_={movie.isSubscribed_} /> */}
                        {showComingSoonTag &&
                            <ComingSoonTag
                                HVSD={movie.HVSD}
                            />}
                        {this.renderMyListDeleteIcon()}
                        {this.ProgressBar(movie, ID)}
                        {this.renderPurchaseHistoryTimeStrip()}
                        {/* {this.renderHoverPlayIcon(ID, movie)} */}

                    </a>
                    {this.state.showSubscribeModal && this.renderSubscribeModal()}
                </div >)
        } else {
            return null
        }
    }
}
MovieCard.defaultProps = {
    type: "",
    isRented: false,
    rentedDuration: "",
    expiringTimeStrip: "",
    showCardHoverIcons: false,
    // showHoverPlayIcon: true, //by default show play Icon
    showHoverDetailandRent: false,
    showComingSoonTag: false, //temporary fix
    className: "",
    showSpinner: false
};
MovieCard.propTypes = {
    ServerTime: PropTypes.string,
    HVSD: PropTypes.string,
}
export default withRouter(withAuth(withAppConfig(MovieCard)));

//this.props.className !== "faded mb-4" renderHover() -> for reward movies


const SubscribedTag = memo((props) => {
    if (props.isSubscribed_) {
        return <span className={`mn-tag rented`}><i className="la la-play-circle mr-2"></i>subscribed</span>
    }
    return null;
})
