import React, { Component } from "react";
import PropTypes from "prop-types";
import { debounce } from "../../helpers/GeneralHelpers";
import { LOGIN } from "../../constants/routes";
import StuffpixHeaderView from "./components/StuffpixHeader/StuffpixHeaderView";
import MuviNowHeaderView from "./components/MuvinowHeader/MuviNowHeaderView";
import config from "../../config/config";
import _isEmpty from "lodash/isEmpty";
import { withAppConfig } from "../../context/ConfigContext";
import { getDataFromLocalStorage, getCurrentUserId, storeDataInLocalStore } from "../../helpers/GeneralHelpers";
class Header extends Component {
    isCompMounted = true;
    sections = [];
    rooms = [];
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: "",
            showCustomHeaderLinks: false,
        };
    }

    componentWillUnmount() {
        this.isCompMounted = false;
    }

    componentDidUpdate(prevProps) {
        
        // if (prevProps.isLoadingCustomSections !== this.props.isLoadingCustomSections) { //check this condtion in shouldComp
        //     this.handleUpdateHeaderLinks();
        // }
    }

    componentDidMount() {
        if(!this.USER_ID)
        { 
            this.fetchScreeningRoom()
        }
        // let sectionsData = getDataFromLocalStorage(STORED_HEADER_SECTIONS);
        // this.setState({
        //     // customSections: sectionsData,
        //     showCustomHeaderLinks: true
        // });
    }

    // handleUpdateHeaderLinks = () => { //call this!!
    //     
    //     let customSections = _filter(sectionsData, ['Type', "custom"]);
    //     this.setState({
    //         customSections: customSections,
    //         showCustomHeaderLinks: true
    //     });
    //     storeDataInLocalStore(STORED_HEADER_SECTIONS, customSections, null, NO_EXPIRY);
    // }

    callDebounce = debounce(searchTerm => {
        this.props.updateSearchState(searchTerm);
    }, 1000);

    setSearchTerm = (searchTerm) => {
        this.setState({
            searchTerm: searchTerm
        })
        this.callDebounce(searchTerm);
    }

    clearSearch = (e) => {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            searchTerm: ""
        })
        this.props.updateSearchState("");
    }

    submitSearch = (e) => {
        e.preventDefault();
    }

    handleLoginClick = () => {
        let pathname = this.props.history.location.pathname;
        if (pathname !== LOGIN) {
            this.props.history.push(`/login?redirect=${this.props.history.location.pathname}${this.props.history.location.search}`);
        }
    }
    fetchScreeningRoom = async () => {
        let user_id = await getCurrentUserId();
        this.sections = await getDataFromLocalStorage("sections", user_id)
        if (!_isEmpty(this.sections)) {
            storeDataInLocalStore("sections", this.sections, user_id);
        }
        if (this.sections != null) {
            this.sections.forEach(element => {
                if (element.Type === "screening_room") {
                    element.Items.forEach(room => {
                        this.rooms.push({
                            title: room.Title,
                        id: room.ID})
                    });
                }
            });
        }
       
    }
    
    render() {
       
        return (
            config.customSettings.isStuffpixEnabled ?
                <StuffpixHeaderView
                    searchTerm={this.state.searchTerm}
                    handleLoginClick={this.handleLoginClick}
                    clearSearch={this.clearSearch}
                    setSearchTerm={this.setSearchTerm}
                    // customSections={this.state.customSections}
                    // showCustomHeaderLinks={this.state.showCustomHeaderLinks}
                    history={this.props.history}
                />
                :
                <MuviNowHeaderView
                    searchTerm={this.state.searchTerm}
                    handleLoginClick={this.handleLoginClick}
                    clearSearch={this.clearSearch}
                    sections = {this.rooms}
                    setSearchTerm={this.setSearchTerm}
                    history={this.props.history}
                />
        );
    }
}
export default withAppConfig(Header);