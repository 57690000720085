import React from "react";
import { NavLink } from 'react-router-dom';
import PropTypes from "prop-types";
import { PRIVACY_POLICY, TERMS_CONDITIONS, CONTACT_US } from "../../constants/routes";
import { withAppConfig } from "../../context/ConfigContext";

const Footer = ({ appSettings }) => {
    const { social = {} } = appSettings;
    const { fb_link, instagram_link, twitter_link, youtube_link } = social;
    return (
        <section className="mn-ft-btm py-3 text-white-50 text-center text-md-left">
            <div className="container-fluid">
                <div className="row align-items-center">
                    {(appSettings && appSettings.AppFooterText) &&
                        <div className="col-md-6">
                            <div className="mn-copyright mb-3 mb-md-0">{appSettings.AppFooterText}</div>
                        </div>
                    }
                    <div className="col-md-6 text-md-right">
                        <NavLink to={TERMS_CONDITIONS} className="link-white-70 mx-2">Terms of Use</NavLink>
                        <NavLink to={PRIVACY_POLICY} className="link-white-70 mx-2">Privacy Policy</NavLink>
                        {/* <ul className="mn-social">
                            {fb_link &&
                                <li><a href={fb_link} target="_blank" className="link-white-50"><i className="fa fa-facebook-f"></i></a></li>
                            }
                            {instagram_link &&
                                <li> <a href={instagram_link} target="_blank" className="link-white-50"><i className="fa fa-instagram"></i></a></li>
                            }
                            {twitter_link &&
                                <li> <a href={twitter_link} target="_blank" className="link-white-50"><i className="fa fa-twitter"></i></a></li>
                            }
                            {youtube_link &&
                                <li> <a href={youtube_link} target="_blank" className="link-white-50"><i className="fa fa-youtube"></i></a></li>
                            }
                        </ul> */}
                    </div>
                </div>
            </div>
        </section>
    )
}
export default withAppConfig(Footer);
Footer.propTypes = {
    appSettings: PropTypes.object.isRequired
}