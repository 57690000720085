import { POST } from "../../api";
import { getTimeZone } from "../PaymentHelpers";
import { getResponse } from "../ResponseHelpers";

export function PostAPI({ apiname, apipath, postParams }, requestType, otherParms = {}, callback, alertCallback) {
    postParams = updateParams(postParams);
    if(!otherParms){
        otherParms={};
    }
    return POST(apiname, apipath, postParams).then(async (res) => {
        let updatedData = getResponse(res, null, requestType);
        if (alertCallback) {
            alertCallback("showSuccessAlert", otherParms.SuccessMessage);
        }
        callback(null, updatedData);
        return updatedData;
    }).catch((error) => {
        let message = otherParms.FailedMessage || error;
        if (error.response && error.response.data && error.response.data.errorType) {
            message = error.response.data.errorMessage.replace("[403] ", "");
        }
        if (alertCallback) {
            alertCallback("showErrorAlert", message);
        }
        callback(message, null);
        return;
    });
}

function updateParams(headers) {
    headers["TimeZone"] = getTimeZone();
    headers["Device"] = "Web";
    headers["AdyenPaymentType"] = "voucher";
    return headers;
}