import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { withAppConfig } from "../../../context/ConfigContext";
import Loader from "../../../components/Loader/Loader";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";
import _groupBy from "lodash/groupBy";
import _isEmpty from "lodash/isEmpty";
import Listing from "./components/Listing";
import moment from "moment"; // removed lazy moment import --> hell fed up
import { withAuth } from "../../../context/AuthContext";
import { GetSubscriptionsHistory } from "../../../services/user/MySubscriptionHistory";

const SubscriptionHistory = props => {
  const [detail, setDetail] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isSubscribed, setSubscribed] = useState(true)

  useEffect(() => {
    initialize();
    return () => { };
  }, []);


  const initialize = async () => {
    let params = {
      UserId: props.UserID
    };
    GetSubscriptionsHistory((response, err) => {
      if (!err) {
        let data = _groupBy(response, item => {
          let createdAt = moment(item.CreatedAt).format("YYYY-MM-DD");
          return createdAt;
        });
        setDetail(data);
      }
      setLoading(false);
    }, params);
  };

  return (
    <main className="mn-main">
      <section className="mn-page-head py-4">
        <div className="container">
          <h3 className="mb-0 text-center text-uppercase">Manage Subscriptions</h3>
        </div>
      </section>
      <section className="mn-notifications py-3">
        <div className="container">
          <div className="mn-purchase-list">
            {isLoading ? (
              <Loader />
            ) : !isLoading && detail && _isEmpty(detail) ? (
              <DataNotFound text="No Subscriptions" />
            ) : isSubscribed && (
              <Listing data={detail} appTranslations={props.appTranslations} />
            ) || <DataNotFound text="No Subscriptions" />}
          </div>

        </div>
      </section>
    </main>
  );
};

export default withAppConfig(withRouter(withAuth(SubscriptionHistory)));
