import React, { Fragment, memo } from "react";
import { withRouter } from "react-router-dom";
import _sortBy from "lodash/sortBy";
import { COLLECTIONDETAIL } from "../../constants/routes";
import { withAppConfig } from "../../context/ConfigContext";

const CollectionCard = props => {
  const { collection } = props;
  const onClick = e => {
    e.preventDefault();
    e.stopPropagation();
    props.history.push(`${COLLECTIONDETAIL}/${props.collection.ID}`);
    if (props.onClick) {
      props.onClick(props.collection.ID);
    }
  };
  return (
    <Wrapper
      // Tag={<RentTag isCPurchased_={collection.isCPurchased_} />}
      PosterUrl={collection.PosterUrl}
      onClick={onClick}
    >
      <div className="">
        <Information {...props} />
        {collection.isCPurchased_ ? (
          <Watch onClick={onClick} />
        ) : (
          <Buttons onClick={onClick} {...props} />
        )}
      </div>
    </Wrapper>
  );
};

export default withRouter(withAppConfig(CollectionCard));

const Watch = memo(props => {
  return (
    <div className="mb-2" onClick={props.onClick}>
      <button className="btn btn-primary btn-large text-uppercase font-weight-bold">
        Watch
      </button>
    </div>
  );
});

const Buttons = memo(props => {
  let lowestPrice = "";
  if (props.collection.Price) {
    let sorted = props.collection.Price;
    sorted = _sortBy(props.collection.Price, ["Price"]);
    if (sorted && sorted.length > 0) {

      lowestPrice = props.appSettings.CurrencySymbol+`${sorted[0].Price}`;
    }
  }

  return (
    <Fragment>
      <div className="mb-2">
        <button
          className="btn btn-primary btn-large text-uppercase font-weight-bold"
          onClick={props.onClick}
        >
          Rent {lowestPrice}
        </button>
      </div>
      <div className="">
        <button
          className="btn btn-secondary btn-large text-uppercase font-weight-bold"
          onClick={props.onClick}
        >
        <i className="la la-info-circle mr-1"> </i> More
        </button>
      </div>
    </Fragment>
  );
});

const Information = memo(({ collection }) => {
  return (
    <Fragment>
      <h4 className="mb-3 text-white font-weight-bold">{collection.Title}</h4>
      <div className="mb-3">{collection.MovieCount} Movies</div>
    </Fragment>
  );
});

const Wrapper = memo(({ PosterUrl, Tag, onClick, children }) => {
  return (
    <div className="col-sm-2" onClick={onClick}>
      <a className="mn-card-item mn-collection-card mb-3">
        {Tag}

        <div className="mn-collection-figure">
          <div className="mn-figure">
            <div
              className="mn-thumb img-bg img-1x15"
              style={{
                backgroundImage: `url(${PosterUrl})`
              }}
            />
          </div>
        </div>
        <div className="mn-figure-overlay">
          <div className="upper-dock-body d-flex align-items-center justify-content-center w-100 h-100">
            {children}
          </div>
        </div>
      </a>
    </div>
  );
});

const RentTag = memo(({ isCPurchased_ }) => {
  if (isCPurchased_) {
    return (
      <span className="mn-collection-card mn-tag">
        <i className="la la-play-circle mr-2" />
        rented
      </span>
    );
  }
  return null;
});
