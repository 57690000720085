import React from 'react';
import PropTypes from "prop-types";

const StuffpixLoader = (props) => (
    <span className="pix-loader"></span>
)

StuffpixLoader.defaultProps = {
    height: "500px",
    customClass: ""
}

StuffpixLoader.propTypes = {
    height: PropTypes.string
}

export default StuffpixLoader;