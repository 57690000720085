import { GET } from "../../api/";
import _concat from "lodash/concat";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";
import { handleImageResizing } from "../../helpers/CDNhelpers";

export default async function getScreeningRooms(callback, params) {
  const { userID, Limit = 10, LastEvaluatedKey = ``, Items } = params;
  let rooms ;//= getDataFromLocalStorage(`screening-rooms`, userID);
  let path = `/screening-rooms?limit=${Limit}&ExclusiveStartKey=${LastEvaluatedKey}`;
  if (!rooms || _isEmpty(rooms)) {
    rooms = await GET("MoviesAPI", path)
      .then(res => {
        if (res.body) {
          res.body.Items = _concat(Items, res.body.Items);
          callback(res.body);
        }
        return res.body;
      })
      .catch(e => {
        let message = e;
        if (e.response && e.response.data && e.response.data.errorMessage) {
          message = e.response.data.errorMessage.replace("[403] ", "");
        }
        callback(null, message);
        return;
      });
  }
  if (!rooms) {
    return;
  }
  const { HostName, subscriptions, isAuthenticated } = params;

  if (subscriptions && isAuthenticated) {
    rooms.Items = updateStatus(rooms.Items, subscriptions);
  }
  rooms.Items = await handleImageResizing(rooms.Items, 'Movies', HostName);
  if (!_isEmpty(rooms)) {
    //storeDataInLocalStore(`screening-rooms`, rooms, userID);
  }
  callback(rooms);
}

function updateStatus(rooms, subscriptions) {
  let updatedRooms = rooms;
  updatedRooms = updatedRooms.map(room => {
    let isSubscribed = _find(subscriptions, ["ScreeningRoomID", room.ID]);
    if (isSubscribed) {
      room["isSubscribed_"] = true;
    }
    return room;
  });
  return updatedRooms;
}
