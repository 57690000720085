import React from "react";
import { REWARD } from "../../constants/constants";
import { PARTNERURL } from "../../constants/routes";
import { Link } from "react-router-dom";
import { withAppConfig } from "../../context/ConfigContext";

function RewardStickyFooter({ showOfferStrip , appTranslations : tr }) {
    if (showOfferStrip && window.location.pathname !== PARTNERURL) {
        let redeemtext = tr.redeem_one_of_the_5_movies_as_special_of;
        let voucher = tr.voucher || "voucher";
        return (
            <div className="alert alert-dark text-center m-0 fixed-bottom">
                <div className="container">
                    {redeemtext}<span className="mx-1"><img src="https://i.imgur.com/x4PcLfE.png" width="60" /></span> {voucher}
                    <Link to={`/${REWARD}`} className="btn btn-sm btn-primary ml-3 btn-medium">{tr.get_offer || "Get Offer"}</Link>
                </div>
            </div>
        )
    }
    return null
}
export default withAppConfig(RewardStickyFooter);