import API from "@aws-amplify/api";
import _map from "lodash/map";
import _filter from "lodash/filter";
import _concat from "lodash/concat";
import { GET } from "../../api/"
import { getDataFromLocalStorage, storeDataInLocalStore, isEmpty } from "../GeneralHelpers";
import { getCompressedCdnImageUrl } from "../CDNhelpers";
import { NOTIFICATIONS_LIST, FETCH_ALL_NOTIFICATIONS, STORED_MY_NOTIFICATIONS, STORED_MY_UNREAD_NOTIFICATIONS, NO_EXPIRY } from "../../constants/constants";
import { getactiveCDNfromConfig } from "../ConfigHelpers";

export function fetchNotificationsList(lastEvaluatedKey) { //not used
    let apiName = 'NotificationsAPI';
    let path = `/notifications?ExclusiveStartKey=${lastEvaluatedKey ? lastEvaluatedKey : ``}`
    let myInit = {
        headers: { 'Content-Type': 'application/json' }
    }
    return API.get(apiName, path, myInit).then(async (res) => {
        let HostName = await getactiveCDNfromConfig();
        if (res.statusCode === 200) {
            let updatedResponse = res;
            if (res && res.body && res.body.Items) {
                let items = _map(res.body.Items, (item) => {
                    if (item.Data) {
                        let isPoster = item.Data.MoviePoster;
                        if (isPoster) {
                            item.Data["MoviePoster"] = getCompressedCdnImageUrl(isPoster, NOTIFICATIONS_LIST, HostName);
                        }
                    }
                    return item
                })
                updatedResponse.body["Items"] = items
            }
            return updatedResponse;
        }
    }).catch(async (err) => {
        return "Error"
    });
}

export function seenNotificationRequest(CreatedAt, notificationId, notificationsData) {
    let apiName = 'NotificationsAPI';
    let path = '/notification/seen';
    let myInit = {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: { CreatedAt: CreatedAt }
    }
    return API.post(apiName, path, myInit).then((res) => {
        if (res.statusCode === 200) {
            return res;
        }
    }).catch((err) => {
        return "Error"
    });
}

export function getNotificationsList(userId) {
    return fetchAllNotifications("NotificationsAPI", "/notifications", "/notifications", [], FETCH_ALL_NOTIFICATIONS, "").then(async (finalData) => {
        let updatedResponse = finalData;
        if (finalData) {
            let HostName = await getactiveCDNfromConfig();
            let items = _map(finalData, (item) => {
                if (item.Data) {
                    let isPoster = item.Data.MoviePoster;
                    if (isPoster) {
                        item.Data["MoviePoster"] = getCompressedCdnImageUrl(isPoster, NOTIFICATIONS_LIST, HostName);
                    }
                }
                return item
            });
            updatedResponse = items
        }
        storeDataInLocalStore(STORED_MY_NOTIFICATIONS, updatedResponse, userId, NO_EXPIRY);
        return updatedResponse;
    })
}

export function fetchAllNotifications(apiname, pathName, apipath, DATA, status, LASTEVALUATEDKEY) { //replace this func later with fetchDataRecursively in MoviesHelper
    //let Limit = 50;
    return fetchAPI(apiname, apipath, DATA).then((DATALIST) => {
        let responseLastEvaluatedKey = !isEmpty(DATALIST.response) ? DATALIST.response.body.LastEvaluatedKey ? DATALIST.response.body.LastEvaluatedKey : null : null;
        if (!responseLastEvaluatedKey || responseLastEvaluatedKey === LASTEVALUATEDKEY) {
            return DATALIST.data
        }
        let path = `/${pathName}?ExclusiveStartKey=${responseLastEvaluatedKey ? responseLastEvaluatedKey : ``}`;
        return fetchAllNotifications(apiname, pathName, path, DATALIST.data, status, responseLastEvaluatedKey);
    })
}

export async function countUnseenNotifications(notificationsList, userId) {
    if (!notificationsList) {
        notificationsList = getDataFromLocalStorage(STORED_MY_NOTIFICATIONS, userId);
    }
    let count = _filter(notificationsList, (notification) => !notification.isSeen);
    await storeDataInLocalStore(STORED_MY_UNREAD_NOTIFICATIONS, count.length, userId, NO_EXPIRY);
    return count.length
}

const fetchAPI = (apiname, apipath, data)=> {
  return GET(apiname, apipath)
    .then(res => {
      let newArray = res.body.Items;
      let concated = _concat(data, newArray);
      return { data: concated, response: res };
    })
    .catch(err => {
      return { data: [], response: [] };
    });
}