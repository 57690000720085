import React, { Component } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { NOTIFICATIONS } from "../../../../constants/routes";
import { jsDateToYYYYMMDD } from "../../../../helpers/GeneralHelpers";
import { withFirebase } from "../../../../context/FirebaseContext";
import { withAppConfig } from "../../../../context/ConfigContext";

class NotificationsBell extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false
        };
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
        this.onNotificationClick();
    }

    onNotificationClick = () => {
        this.props.history.push(NOTIFICATIONS);
    }

    onClickNotificationBell = () => {
        //if (notificationsList && notificationsList.length <= 0) { //unCommentLater
        this.props.history.push(NOTIFICATIONS);
        // }
    }

    renderNotificationsDropdown() {
        let notificationsList = [];
        if (this.props.notificationsList) {
            if (this.props.notificationsList.length >= 3) {
                notificationsList = this.props.notificationsList.slice(0, 3);
            }
        }
        return (
            (notificationsList && notificationsList.length > 0) ?
                notificationsList.map((notification, index) => {
                    return (
                        <a key={index} onClick={this.onNotificationClick} href="javascript:void(0)" className="list-group-item list-group-item-action flex-column align-items-start">
                            <div className="d-flex w-100 font-12">
                                <div className="mn-figure mr-3">
                                    <div className="d-flex rounded-circle" style={{ backgroundImage: `url(${this.profilePic})`, width: "40px" }}></div>
                                </div>
                                <div>
                                    {/* <div className="mb-1"><strong>Ammara</strong> how are You</div>
                                    <div className="grey-text"><small>djhfdjgdjdfj</small></div> */}
                                    <div className="mb-1"><strong>{notification && notification.Data && notification.Data.MovieName}</strong>  {notification.Message}</div> */}
                                    <div className="grey-text"><small>{jsDateToYYYYMMDD(notification.UpdatedAt)}</small></div>
                                </div>
                            </div>
                        </a>
                    )
                }) :
                null
        )
    }

    renderDropDown() {
        const { appTranslations : tr } = this.props;
        return (
            <Dropdown group isOpen={this.state.dropdownOpen} toggle={this.toggle} className="dropdown hd-dropdown not-dropdown">
                <DropdownToggle>
                    <a className="not-icon" >
                        <span className="badge badge-primary">{this.props.unreadNotifications !== 0 && this.props.unreadNotifications}</span>
                        <i className="la la-bell"></i>
                    </a>
                </DropdownToggle>
                <DropdownMenu right className="list-group" >
                    {this.renderNotificationsDropdown()}
                    <DropdownItem onClick={this.toggle} className="list-group-item list-group-item-action flex-column align-items-start">{tr.view_all || "View All"}</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        )
    }

    render() {
        return (
            <a className="not-icon" onClick={this.onNotificationClick}>
                <span className="badge badge-primary">{this.props.unreadNotifications !== 0 && this.props.unreadNotifications}</span>
                <i className="la la-bell"></i>
            </a>
        )
    }
}

export default withFirebase(withAppConfig(NotificationsBell));