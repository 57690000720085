import React from "react";
import { getRoomDuration } from "../../../../helpers/MoviesHelper";

const Header = props => {
  const { appTranslations: tr } = props;
  return (
    <section className="mn-page-head expand-vertical py-4">
      <div className="container">
        <div className="text-center">
          {props.methodType === "screening_room" ? (
            <h3 className="mb-0">
              Complete Subscription
            </h3>
          ) : (
            <h3 className="mb-0">{tr.complete_rental || "Complete Rental"}</h3>
          )}
        </div>
      </div>
    </section>
  );
};

export default Header;
