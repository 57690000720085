import React from "react";
import { Route, Redirect } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";
import { querystring } from "../helpers/GeneralHelpers";

const UnauthenticatedRoute = ({ component: C, props: cProps, ...rest }) => {
    const redirect = querystring("redirect");
    return (
        <Route
            {...rest}
            render={props =>
                !cProps.isAuthenticated
                    ? <ErrorBoundary><C {...props} {...cProps} /></ErrorBoundary>
                    : <Redirect
                        to={redirect === "" || redirect === null ? "/home" : redirect}
                    />}
        />
    );
};
export default UnauthenticatedRoute;