import { POST } from "../../api/";

export function cancelSubscription(callback, params) {
  return POST("OrdersAPI", "/cancel-subscription", params)
    .then(res => {
      callback(null, res.body);
      return res.body;
    })
    .catch(e => {
      let message = "Failed to Cancel!";
      if (e && e.response && e.response.data && e.response.data.errorMessage) {
        message = e.response.data.errorMessage[0];
      }
      callback(message);
      return;
    });
}
