import React from "react";
import { Route, Redirect } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary/ErrorBoundary";

const MyRoute = ({ component: C, props: cProps, ...rest }) =>
    <Route
        {...rest}
        render={props =>
                <ErrorBoundary><C {...props} {...cProps} /></ErrorBoundary>
        }
    />;
export default MyRoute;
/*!cProps.isDeviceLimitReached ?
<ErrorBoundary><C {...props} {...cProps} /></ErrorBoundary>
:
<Redirect to={`/devices?redirect=${props.location.pathname}${props.location.search}`} />*/