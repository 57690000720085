import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Modal } from "reactstrap";
import { withAppConfig } from '../../context/ConfigContext';

class ConfirmationModal extends Component {
    render() {
        const { Icon, Title, Subtitle, appTranslations :tr ,ButtonText, Loader  } = this.props;
        return (
            <Modal isOpen={this.props.showConfirmationModal} backdrop={true} toggle={this.props.toggleModal} size="md" centered modalClassName="modal-dark" >{/*id="ratingModal"*/}
                {Loader}
                <div className="modal-content">
                    <div className="modal-body p-3 p-sm-4">
                        <div className="text-center text-white-70">
                            <div className="mb-5">
                                <i className='las la-exclamation-circle la-5x'></i>
                            </div>
                            <div className="mb-5 font-16">
                                {Title}
                            </div>
                            <div className="mb-5">
                                {Subtitle}
                            </div>
                            <div className="mb-4 text-center">
                                <button type="button" onClick={this.props.onConfirmationClick} className="btn btn-primary btn-medium mx-2">{tr.Confirm || ButtonText}</button>
                                <button type="button" onClick={this.props.toggleModal} className="btn btn-secondary btn-medium mx-2">{tr.cancel || "Cancel"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}
ConfirmationModal.defaultProps = {
    showConfirmationModal: false,
    Title: "Are You Sure ?",
    Subtitle: "To verify your card, an amount of up to 0.01 will be charged. After Verification, the amount will be automatically refunded.",
    ButtonText: "Confirm"
}
ConfirmationModal.propTypes = {
    showConfirmationModal: PropTypes.bool,
    onConfirmationClick: PropTypes.func,
    toggleModal: PropTypes.func,
    Title: PropTypes.string,
    Subtitle: PropTypes.string,
    ButtonText :PropTypes.string
}

export default withAppConfig(ConfirmationModal);