import React from "react";
import { renderContentLayout } from "../../helpers/ViewHelpers";
import RenderScreen from "../../components/RenderScreen/RenderScreen";
import { PostAPI } from "../../helpers/APIHelpers/APIHelpers";
import { withAppConfig } from "../../context/ConfigContext";

class Maccas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: [],
            isError: "",
        }
    }

    componentDidMount() {
        let apiParams = {
            apiname: "ConfigAPI",
            apipath: "/pages",
            postParams: { "PageKey": 'MACCAS_MOVIES' }
        }
        PostAPI(apiParams, "StaticPages", null, this.handleFetchResponse);
    }

    handleFetchResponse = (err, response) => {
        if (err) {
            this.setState({
                isLoading: false,
                isError: "No Data Found"
            })
        } else {
            this.setState({
                isLoading: false,
                data: response,
            })
        }
    }

    render() {
        return (
            <main className="mn-main">
                <RenderScreen
                    isLoading={this.state.isLoading}
                    isError={this.state.isError}
                >
                    {renderContentLayout('MACCAS_MOVIES', this.state.data)}
                </RenderScreen >
            </main>
        )
    }
}
export default withAppConfig(Maccas);