import { Component } from "react";

class ErrorBoundary extends Component {
    isCompMounted = true;
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: "",
            errorInfo: ""
        }
    }

    componentDidCatch(error, info) {
        this.setState({
            hasError: true,
            error: error,
            errorInfo: info
        });
    }

    componentWillUnmount() {
        this.isCompMounted = false;
    }

    render() {
        if (this.state.hasError) {
            return `Some Error has occured ${this.state.error} ...  ${this.state.errorInfo.componentStack} `
        }
        return this.props.children
    }
}
export default ErrorBoundary;