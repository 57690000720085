import React, { Component } from "react";
import PropTypes from 'prop-types';
import MyInput from "../../../../components/MyInput";
import LoaderButton from "../../../../components/LoaderButton/LoaderButton";
import Formsy from 'formsy-react';
import { checkifOfferAvailed, getPartnerPromotionalMovies, checkIsMoviePromotional, getPromotionalMovieTeir, decidePartnerFlow, getAppliedVoucherCode } from "../../../../helpers/PartnerHelpers";
import { getApiData } from "../../../../helpers/APIHelpers/MoviesAPI";
import { getPurchaseData } from "../../../../helpers/MoviesHelper";

class VoucherInput extends Component {
    USER_ID = ""
    constructor(props) {
        super(props);
        this.state = {
            isVoucherRedemptionDecided: true,
            customMoviePrice: null,
            customMovieQuality: null,
            showCustomPurchase: false,
            voucherRedemptionSuccessfull: false
        }
        this.checkifOfferAvailed = checkifOfferAvailed.bind(this);
        this.getPurchaseData = getPurchaseData.bind(this); //helll!!!
        this.getRewardMovies = getApiData.bind(this);
        this.getPartnerPromotionalMovies = getPartnerPromotionalMovies.bind(this);
        this.checkIsMoviePromotional = checkIsMoviePromotional.bind(this);
        this.getPromotionalMovieTeir = getPromotionalMovieTeir.bind(this);
        this.decideAutoVoucherRedemption = decidePartnerFlow.bind(this);
        this.updateMovieTier = this.applyVoucherRedemption.bind(this);
        this.getAppliedVoucherCode = getAppliedVoucherCode.bind(this);
    }

    async applyVoucherRedemption() {
        let voucherCode = await this.getAppliedVoucherCode(this.USER_ID);
        this.props.autoVoucherRedemption(voucherCode);
        this.setState({
            isVoucherRedemptionDecided: true,
            voucherRedemptionSuccessfull: true
        });
    }

    renderView() {
        const { forwardedRef , appTranslations : tr } = this.props;
        return (
            <Formsy onValidSubmit={this.props.handleVoucherClick} onClick={this.props.onVoucherInputClick} >
                <div className="input-group">
                    <MyInput
                        id="voucherCode"
                        name="voucherCode"
                        type="text"
                        innerClassName="form-control-dark"
                        placeholder={tr.enter_reward_code || "Enter Reward Code"}
                        required
                        //disabled={this.state.voucherRedemptionSuccessfull}
                        maxLength="20"
                        innerRef={forwardedRef}
                        outerDiv={false}
                        handleChange={this.props.handleChange}
                    />
                    <span className="input-group-append">
                        {this.state.isVoucherRedemptionDecided ?
                            <LoaderButton
                                type="submit"
                                className="btn btn-primary"
                                isLoading={this.props.isLoadingVoucher}
                                text={"Redeem"}
                                //disabled={this.state.voucherRedemptionSuccessfull}
                                loadingText={tr.verifying || "Verifying …"}
                                onClick={this.props.handleVoucherClick}
                            /> :
                            <LoaderButton
                                type="submit"
                                className="btn btn-secondary"
                                isLoading={true}//{this.props.isLoadingVoucher}
                                text={"Redeem"}
                                loadingText={tr.loading || "Loading…"}
                            />
                        }
                    </span>
                </div>
            </Formsy>
        )
    }

    render() {
        return (
            this.renderView()
        );
    }
}

VoucherInput.propTypes = {
    isLoadingVoucher: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    movieID: PropTypes.string.isRequired,
    autoVoucherRedemption: PropTypes.func.isRequired,
    handleVoucherClick: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    onVoucherInputClick: PropTypes.func.isRequired
};
export default VoucherInput;