import React, { Component } from "react";
import { GeoBlockView } from "../../components/GeoBlock/GeoBlock";
import Loader from "../../components/Loader/Loader";
import { HOME, LOGIN, SIGNUP } from "../../constants/routes";
import Login from "../Login/Hybrid/"
import Signup from "../Signup/Signup";
import PartnerSignUp from "../Signup/PartnerUserSignup";
import { withAppConfig } from "../../context/ConfigContext";
import { getSettingsFromConfig } from "../../helpers/ConfigHelpers";

class GeoBlock extends Component {
    isCompMounted = true;
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: [],
            isError: "",
            isGeoBlocked: false
        }
    }

    componentDidMount() {
        this.checkIsUrlValid();
    }

    componentWillUnmount() {
        this.props.applyGeoBlockStyle(false);
        this.isCompMounted = false;
    }

    checkIsUrlValid() {
        if (this.props.history.location.pathname === LOGIN || this.props.history.location.pathname === SIGNUP || this.props.location.pathname.substr(0, 3) === "/p/") {
            this.checkisGeoBlock();
        } else {
            this.props.history.push(HOME);
        }
    }

    async checkisGeoBlock() {
        let geoBlock = await getSettingsFromConfig("REGION_POLICY", true);
        if (geoBlock == null) {
            geoBlock = [];
            geoBlock[0] = { "isGeoBlocked": false };
        }
        if (!geoBlock[0].isGeoBlocked) {
            this.props.applyGeoBlockStyle(false);
            this.setState({
                isGeoBlocked: false,
                isLoading: false
            });
        } else {
            this.props.applyGeoBlockStyle(true);
            this.setState({
                isGeoBlocked: true,
                isLoading: false
            });
        }
    }

    renderRespectivePage = () => {
        if (this.props.history.location.pathname === LOGIN) {
            return (<Login {...this.props} />);
        } else if (this.props.history.location.pathname === SIGNUP) {
            return (<Signup {...this.props} />);
        } else if (this.props.location.pathname.substr(0, 3) === "/p/") {
            return (<PartnerSignUp {...this.props} />);
        } else {
            return null;
        }
    }

    render() {
        return (
            this.state.isLoading ?
                <main className="mn-main">
                    <section className="mn-login-section py-5">
                        <div className="container">
                            <Loader customClass="mn-no-bg" />
                        </div>
                    </section>
                </main>
                :
                this.state.isGeoBlocked ?
                    < GeoBlockView />
                    :
                    this.renderRespectivePage()
        );
    }
}
export default withAppConfig(GeoBlock);