
import { NOTICATIONS_REFRESH_ALL, NOTICATIONS_REFRESH_COLLECTIONS, NOTICATIONS_REFRESH_SUBSCRIPTIONS, NOTICATIONS_NEW_MOVIE, STORED_EMPTY_MYLIST_FLAG, STORED_MY_LIST, APP_CONFIG, STORED_MY_PURCHASES, STORED_MOVIES_DETAIL, STORED_MOVIES_TYPES, STORED_SECTIONS_DATA, STORED_BANNER_DATA, STORED_MY_WATCHED_LIST, NOTICATIONS_REFRESH_CONFIG, NOTICATIONS_REFRESH_MOVIE, NOTICATIONS_REFRESH_SECTIONS, NOTICATIONS_REFRESH_BANNERS, NOTICATIONS_REFRESH_MYLIST, NOTICATIONS_REFRESH_PURCHASELIST, NOTICATIONS_REFRESH_WATCHLIST, STORED_REFRESH_NOTIFICATION_STATUS, NOTIFICATIONS_REFRESH_FORGET_SESSION, NO_EXPIRY, STORED_SEARCH_HISTORY } from "../constants/constants";
import { removeItemFromStore, storeDataInLocalStore, getDataFromLocalStorage, clearBrowserCache, isEmpty, getCurrentUserId } from "./GeneralHelpers";
import config from "../config/config";
import _isEqual from "lodash/isEqual";

export function updateCacheOnRefreshNotification(key, userId, statusValue) {
    let isNotificationUpdated = lastRefreshValue(key, userId, statusValue);
    if (!isNotificationUpdated) {
        return
    }
    switch (key) {
        case NOTICATIONS_REFRESH_MYLIST:
            removeItemFromStore(STORED_MY_LIST, userId);
            break;
        case NOTICATIONS_REFRESH_WATCHLIST:
            removeItemFromStore(STORED_MY_WATCHED_LIST, userId);
            break;
        case NOTICATIONS_REFRESH_PURCHASELIST:
            removeItemFromStore(STORED_MY_PURCHASES, userId);
            break;
        case NOTICATIONS_REFRESH_CONFIG:
            removeItemFromStore(APP_CONFIG); //CHANGE
            break;
        case NOTICATIONS_REFRESH_MOVIE:
        case NOTICATIONS_NEW_MOVIE:
            clearAllCacheWithPrefixing(STORED_MOVIES_DETAIL);
            clearAllCacheWithPrefixing(STORED_MOVIES_TYPES);
            break;
        case NOTICATIONS_REFRESH_ALL:
            clearAllCache(userId);
            break;
        case NOTICATIONS_REFRESH_SECTIONS:
            clearAllCacheWithPrefixing(STORED_SECTIONS_DATA);
            break;
        case NOTICATIONS_REFRESH_BANNERS:
            clearAllCacheWithPrefixing(STORED_BANNER_DATA);
            break;
        case NOTICATIONS_REFRESH_SUBSCRIPTIONS : 
            removeItemFromStore('mysubscriptions',userId);
            break;
        case NOTICATIONS_REFRESH_COLLECTIONS : 
            removeItemFromStore('mycollections',userId);
            break;
        case NOTIFICATIONS_REFRESH_FORGET_SESSION:
            handleClearSession(statusValue);
            break;
    }
    saveLastRefreshNotificationStatus(key, userId, statusValue);
}


export function clearAllCacheWithPrefixing(status) {
    let removeItems = [];
    for (let i = 0; i < localStorage.length; i++) {
        let prefixLength = config.service.stage + "-" + config.service.service + "-";
        let substringLength = status.length + prefixLength.length;
        if (localStorage.key(i).substring(0, substringLength) === prefixLength + status) {
            removeItems.push(localStorage.key(i));
        }
    }
    removeItems.forEach((res) => {
        localStorage.removeItem(res);
    });
}

function clearAllCache(userId) {
    const clearAuthItems = [STORED_BANNER_DATA, STORED_MY_PURCHASES, STORED_EMPTY_MYLIST_FLAG, STORED_MY_WATCHED_LIST, STORED_MY_LIST];
    const clearUnAuthItems = [APP_CONFIG, STORED_BANNER_DATA];
    clearAllCacheWithPrefixing(STORED_MOVIES_DETAIL);
    clearAllCacheWithPrefixing(STORED_SECTIONS_DATA);
    clearAllCacheWithPrefixing(STORED_MOVIES_TYPES);
    clearAuthItems.forEach((item) => {
        removeItemFromStore(item, userId);
    });
    clearUnAuthItems.forEach((item) => {
        removeItemFromStore(item);
    });
}

function saveLastRefreshNotificationStatus(key, userId, statusValue) {
    storeDataInLocalStore(`${STORED_REFRESH_NOTIFICATION_STATUS}${key}${userId ? `-${userId}` : ``}`, statusValue);
}

export function lastRefreshValue(key, userId, statusValue) {
    let storedKey = getDataFromLocalStorage(`${STORED_REFRESH_NOTIFICATION_STATUS}${key}${userId ? `-${userId}` : ``}`);
    if (_isEqual(storedKey, statusValue)) {
        return false;
    }
    return true;
}

function handleClearSession(payload) {
    if (payload && payload.status === "inactive") {
        clearBrowserCache()
    }
}

export function updateMyListCacheFlag(data, userId) {
    if (isEmpty(data)) {
        storeDataInLocalStore(STORED_EMPTY_MYLIST_FLAG, true, userId, NO_EXPIRY);
    } else {
        removeItemFromStore(STORED_EMPTY_MYLIST_FLAG, userId, NO_EXPIRY);
    }
}

export const storeSearchHistory = (searchTerm,userId) => {
    let searchList = getDataFromLocalStorage(STORED_SEARCH_HISTORY, userId);
    if (searchList) {
    searchList.push(searchTerm);
    } else {
    searchList = [];
    searchList.push(searchTerm);
    }
    storeDataInLocalStore(STORED_SEARCH_HISTORY, searchList, userId, NO_EXPIRY);
}