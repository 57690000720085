import React from 'react';
import { withRouter } from "react-router-dom";
import { Modal } from "reactstrap";
import { getVideoQualityData } from "../../../../helpers/MoviesHelper";
import { EST } from "../../../../constants/constants";
import PropTypes from 'prop-types';
import { withAppConfig } from '../../../../context/ConfigContext';
import { TVOD } from '../../../../constants/constants';
import PurchaseButton from './PurchaseButton';
const DISPLAYNAME = "displayName";

class PurchaseModal extends React.Component {
    isCompMounted = true;
    constructor(props) {
        super(props);
        this.state = {
            modalBackDrop: true
        }
        this.PurchaseButtonRef = ""
    }

    componentWillUnmount() {
        this.isCompMounted = false;
    }

    toggleModalbackDrop = () => {
        this.setState((prevState) => {
            return {
                modalBackDrop: !prevState.modalBackDrop
            }
        })
    }

    renderPurchaseModal = () => {
        const { selectedMovie = {}, selectedMovieId, selectedMovieTeirType } = this.props;
        let qualityArray = getVideoQualityData(selectedMovie, selectedMovieId, selectedMovieTeirType);
        const { RentMaxValidity = ``, RentTvodValidity = ``, Title = `` } = selectedMovie;
        let RentTvodValidityText = selectedMovieTeirType === TVOD ? `${RentTvodValidity} ${RentTvodValidity > 1 ? "Hours" : "Hour"} ` : "";
        let RentMaxValidityText = `${RentMaxValidity} days`;
        let quality = qualityArray.length > 0 ? qualityArray[0] : {}
        const { history, showAlert, isAuthenticated } = this.props;
        return (
            <div className="modal-content" >
                <div className="modal-header flex-column">
                    <h5 className="modal-title">Watch {Title}</h5>
                    <div className="text-white-50">{selectedMovieTeirType === EST ? "Buy Movie For lifetime" : `${RentTvodValidityText} Rental`}</div>
                </div>
                <div className="modal-body text-white-70">
                    <ul className="list-check mt-2 mb-4">
                        <li className="mb-3">
                            <i className="la la-check"></i>
                            You are Eligible to Watch {quality && quality[DISPLAYNAME]} Quality
                        </li>
                        <li className="mb-3">
                            <i className="la la-check"></i>
                            You have {RentMaxValidityText} to start watching this rental
                        </li>
                    </ul>
                    <div className="text-white-50 font-12">Note: Your Rental Period of {RentTvodValidityText} will start, once You start Watching</div>
                </div>
                {<PurchaseButton
                    ref={ref => this.PurchaseButtonRef = ref}
                    isAuthenticated={isAuthenticated}
                    movieId={selectedMovieId}
                    history={history}
                    type={selectedMovieTeirType}
                    decideShowParnterOfferStrip={this.props.decideShowParnterOfferStrip}
                    data={selectedMovie}
                    toggleModalbackDrop={this.toggleModalbackDrop}
                    onCheckoutSuccess={this.props.onCheckoutSuccess}
                    showAlert={showAlert}
                    appSettings={this.props.appSettings}
                />}
            </div>
        )
    }

    render() {
        return (
            <Modal
                isOpen={this.props.showPurchaseModel}
                toggle={this.props.togglePurchaseModal}
                backdrop={this.state.modalBackDrop}
                size="md"
                centered
                modalClassName="modal-dark" >
                {this.renderPurchaseModal()}
            </Modal>
        )
    }
}

PurchaseModal.propTypes = {
    selectedMovie: PropTypes.object,
    isAuthenticated: PropTypes.bool.isRequired,
    showAlert: PropTypes.func,
    selectedMovieId: PropTypes.string,
    selectedMovieTeirType: PropTypes.string,
    appSettings: PropTypes.object,
    showPurchaseModel: PropTypes.bool.isRequired,
    togglePurchaseModal: PropTypes.func,
    onCheckoutSuccess: PropTypes.func.isRequired,
};
export default withRouter(withAppConfig(PurchaseModal));