import React, { useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import PurchaseModal from "../../../../components/RoomCollection/PurchaseModal";
import { withAuth } from "../../../../context/AuthContext";
import { withAppConfig } from "../../../../context/ConfigContext";

const Subscribe = props => {
  const [isOpen, toggleModal] = useState(false);
  return (
    <div className="mb-4">
      {props.SelectedVoucher}
      {props.isSubscribed_ ? (
        <a className="btn btn-success btn-large">
          Subscribed
        </a>
      ) : (
          <Fragment>
            <a
              className="btn btn-primary btn-large"
              onClick={props.onClick}
            >
              Subscribe
          </a>
            <PurchaseModal
              isOpen={isOpen}
              toggleModal={props.onClick}
              ID={props.ID}
              Price={props.Price}
              Type="screening_room"
              Duration={props.Duration}
            />
          </Fragment>
        )}
    </div>
  );
};

export default withAuth(withAppConfig(withRouter(Subscribe)));
