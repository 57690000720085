import React from "react";
import MovieCard from "../../../components/MovieCard/MovieCard";
import { PURCHASE_HISTORY } from "../../../constants/constants";
import DataNotFound from "../../../components/DataNotFound/DataNotFound"; //below Child => for collection
import { withAppConfig } from "../../../context/ConfigContext";

const List = ({ section, index, appTranslations: tr, ...props }) => {
  let nonExpiredCount = 0;
  return (
    <div>
      <div className="row gutter-10 mn-movies-grid">
        {section.map((movie, index) => {
          if (!movie["HideExpiredRental_"] && movie["Type"] !== "Child") {
            nonExpiredCount++;
            return (
              <MovieCard
                // showCardHoverIcons={props.showPurchaseHistoryTags}
                key={movie.ID ? movie.ID : index}
                movie={movie}
                type={PURCHASE_HISTORY}
                className="mb-5"
              />
            );
          }
        })}
      </div>
      {nonExpiredCount === 0 && index === 0 ? (
        <DataNotFound
          height="300px"
          text={tr.no_rented_movies || "No Rented Movies"}
        />
      ) : null}
    </div>
  );
};

export default withAppConfig(List);
