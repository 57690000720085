import React, { Component } from 'react';
import PropTypes from "prop-types";
import config from "../../../config/config";
// import { controls } from "./constants";
import { showError, querystring } from "../../../helpers/GeneralHelpers";
import { disableAlert } from "../../../helpers/StateHelper";
import { renderNotificationMessage } from "../../../helpers/ViewHelpers";
import { TrailerPlayer as TrailerPlayerView } from "./TrailerPlayer/TrailerPlayer";
import PlayerSessionHelper from '../PlayerSession/PlayerSession';
import { withAppConfig } from '../../../context/ConfigContext';
import { UNSUPPORTED_BROWSER } from '../../../constants/routes';

class TrailerPlayer extends Component {
    isCompMounted = true;
    playingTrailerNum = querystring("q");
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            showErrorAlert: false,
            alertMessage: "",
            loadingNextTrailer: false
        }
        this.showError = showError.bind(this);
        this.disableAlert = disableAlert.bind(this);
        this.renderNotificationMessage = renderNotificationMessage.bind(this);
        this.trailerPlayer = null;
    }

    componentDidMount() {
        this.initialize();
    }

    initialize = () => {
        window.addEventListener("beforeunload", this.onPlayerDestroyed);
        // this.importPlyr();
        // if (this.props.isEncryptedStream) {
        //     this.importShaka();
        // }
    }

    componentWillUnmount() {
        this.isCompMounted = false;
        //this.cancelPlayerSession();
    }

    // importPlyr = () => {
    //     import("plyr").then((plyr) => {
    //         let control = `<div className="plyr__title-bar plyr__control"><div className="plyr__title">${this.props.title}</div></div>${controls}`
    //         new plyr("#shaka-player", { controls: control });
    //         plyr.setup("#shaka-player");
    //     }).catch((err) => {
    //         this.handleError("import plyr unsuccessful,Try again!");
    //     })
    // }

    importShaka = () => {
        import('shaka-player').then((shaka) => {
            this.shaka = shaka;
            shaka.polyfill.installAll();
            if (shaka.Player.isBrowserSupported()) {
                this.initPlayer();
            } else {
                this.handleError("Browser not Supported"); //
            }
        }).catch(err => {
            this.handleError(err);
        });
    }

    initPlayer = () => {
        this.loadPlayer(this.props.streamUrl);
    }

    loadPlayer = (streamUrl) => {
        let player = new this.shaka.Player(this.trailerPlayer);
        player.load(streamUrl).then((res) => {
        }).catch((err) => {
            this.handleError(err);
        })
    }

    onErrorEvent(event) {
        this.handleError(event.detail);
    }

    handleError = (err) => {
        const { ApplicationName = config.applicationName, appTranslations : tr } = this.props.appSettings;
        let text =  tr.sorry_this_browser_is_not_supported_to_p || `Sorry! This browser is not supported to play <name> Content.`;
        text = text.replace("<name>",ApplicationName);
        let errorMessage = err;
        if (err) {
            let { code = ``, name = errorMessage } = err;
            errorMessage = `${code} ${name}`;
            if (code === 1023) {
                errorMessage = text;
                this.props.history.push(UNSUPPORTED_BROWSER, { data: this.props.movieData });
                return;
            }
        }
        if (this.isCompMounted) {
            this.setState({
                showErrorAlert: true,
                alertMessage: errorMessage !== null ? errorMessage : "Error Playing Trailer!"
            }, () => {
                this.disableAlert("showErrorAlert");
            });
        }
    }

    onVideoPlay = (currentDuration) => {
        if (this.props.isAuthenticated) {
            this.props.onStartSession(this.props.movieId, currentDuration, this.props.videoType);
        }
    }

    onVideoPause = (currentDuration, totalDuration) => {
        this.props.isAuthenticated && this.props.onPauseSession(this.props.movieId, currentDuration, this.props.videoType, totalDuration);
    }

    onVideoEnd = (currentDuration) => {
        if (this.props.isAuthenticated) {
            this.props.onEndSession(this.props.movieId, currentDuration, this.props.videoType);
        }
    }

    onVideoError = (event) => {
        console.log("event:", event)
    }

    onPlayerDestroyed = (duration) => {
        if (duration && this.props.isAuthenticated) {
            this.props.onCancelSession(this.props.movieId, duration, this.props.videoType);
        }
    }

    handleBackClick = () => {
        this.props.history.push(`/movie/${this.props.match.params.id}`);
    }

    render() {
        return (
            <div className="mn-media">
                <div className="mn-media-data">
                    <div onClick={this.handleBackClick} className="mn-media-top">
                        <button className="media-back"></button>
                    </div>
                    {this.renderNotificationMessage()}
                    {this.state.loadingNextTrailer &&
                        <span className="loader-spiral media-loader">
                            <span className="spiral-inn">
                            </span>
                            <div className="sp-logo">
                                <span><strong><h3>Loading Next Trailer ... </h3></strong></span>
                            </div>
                        </span>
                    }
                    <TrailerPlayerView
                        movieId={this.props.movieId}
                        title={this.props.title}
                        posterUrl={this.props.posterUrl}
                        streamType={this.props.isEncryptedStream ? "DASH" : "PLAIN"}
                        movieData={this.props.movieData}
                        movieStream={this.props.streamUrl}
                        onVideoPlay={this.onVideoPlay}
                        onVideoPause={this.onVideoPause}
                        onVideoError={this.onVideoError}
                        onVideoEnd={this.onVideoEnd}
                        onError={this.handleError}
                        handleBackButtonClick={this.handleBackButtonClick}
                        onPlayerDestroyed={this.onPlayerDestroyed}
                    />
                </div>
            </div>
        )
    }
}

export default PlayerSessionHelper(withAppConfig(TrailerPlayer));
TrailerPlayer.propTypes = {
    movieId: PropTypes.string.isRequired,
    videoType: PropTypes.string.isRequired,
    posterUrl: PropTypes.string,
    title: PropTypes.string,
    movieData: PropTypes.object,
    onStartSession: PropTypes.func,
    onPauseSession: PropTypes.func,
    onEndSession: PropTypes.func,
    onCancelSession: PropTypes.func
}