import React from 'react'
import MyList from './MyList';
import RentalLocks from './RentalLocks';
import { withRouter } from "react-router-dom";


const StuffpixBanner = ({ banner ,...props }) => {
    const handleBannerClick = (e) => {
        e.preventDefault();
        if (banner.MovieID) {
            props.history.push(`/movie/${banner.MovieID}`);
        }
    }
    return (
        <div className="mn-banner-caption py-5" onClick={handleBannerClick}>
        <div className="container-fluid h-100">
            <div className="row h-100 align-items-center">
                <div className="col-xl-3">
                    <div className="mn-banner-recent d-none d-xl-block">
                        <h1 className="font-weight-normal mb-4">
                            {banner.HeadingText ? banner.HeadingText : ""}
                        </h1>
                    </div>
                    <div className="mn-banner-detail">
                        {banner.LogoPath ?
                            <div className="mb-4 text-uppercase"><img className="mn-movie-logo" src={banner.LogoPath} alt="Logo" /></div>
                            :
                            <h2 className="mb-3 text-uppercase">
                                <a className="text-white">{banner.MovieTitle}</a>
                            </h2>
                        }
                        {/* <div className="text-uppercase mb-4"><a href={banner.SubHeadingUrl ? banner.SubHeadingUrl : "#"} className="text-white">{banner.SubHeadingText ? banner.SubHeadingText : ""}{banner.SubHeadingUrl && banner.SubHeadingUrl !== "" ? <i className="la la-angle-right ml-2"></i> : null}</a></div> */}
                        {banner.SubHeadingText &&
                            <div className="mn-syno mb-4">
                                {banner.SubHeadingText}
                                {/* ...
                                <a href="#" className="font-weight-bold ml-2">Read More</a> */}
                            </div>}
                        <div className="mn-tag-line mb-4 text-uppercase">{banner.TagLine || ""}</div>
                        {banner.MovieID &&
                            <div className="text-uppercase">
                                <RentalLocks banner ={banner} />
                                <MyList banner={banner} myList={props.myList} updateMyList={props.updateMyList}/>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default withRouter(StuffpixBanner);
