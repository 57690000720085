import React from "react";
import Quality from "../../../../components/RoomCollection/Quality";
import { concat } from "lodash";
import { withAppConfig } from "../../../../context/ConfigContext";
const ScreeningRoomHeader = ({ PreviewImage, PosterUrl, RoomData, children, toggle, appSettings }, props) => {

    let arr = [], qualityArray = [], priceArray = []
    RoomData.Plans.forEach(plan => {
        ['sd', 'hd', '4k'].map((quality, qIndex) => {
            if (plan.pricelist[quality] && plan.pricelist[quality].price !== null) {
                priceArray.push(plan.pricelist[quality].price)
                arr.push(quality)
            }
        });
    });
    qualityArray = arr.filter((value, index) => arr.indexOf(value) === index)
    let minimumPrice = Math.min(...priceArray)
    return (
        <div className="mn-mDetail-caption d-flex align-items-end py-5">
            <div className="room-detail-body w-100">
                <div className="container xl">
                    <h3 className="mb-4 text-center"> {RoomData.Title} </h3>
                    <div className="row align-items-center text-white-70 room-detail-wrap">
                        <div className="col-md-5 room-detail-left text-center text-md-right">
                            {/* {children} */}
                            {/* <div className="mb-3"> */}
                            <Quality {...RoomData}
                                qualityArray={qualityArray} />
                            {RoomData.Description}
                            {/* </div> */}
                        </div>
                        <div className="col-md-2 room-detail-center d-flex justify-content-center">
                            <div className="mn-figure mn-room-figure mn-md-thumb rounded-circle shadow">
                                <div className="mn-thumb img-bg img-1x1 rounded-circle" style={{ backgroundImage: `url(${RoomData.PosterUrl})` }}></div>
                            </div>
                        </div>
                        <div className="col-md-5 room-detail-right">
                            {!RoomData.isSubscribed_ ?
                                <div className="mb-3">
                                    <small>Starting from <span className="font-weight-bold ml-1 text-white">{(appSettings.CurrencySymbol)}{minimumPrice}</span></small>
                                </div>
                                : null}
                            {!RoomData.isSubscribed_ ?
                                <div className="mb-3">
                                    <button className="btn btn-primary btn-large" onClick={toggle}>Subscribe</button>
                                </div>
                                : <div className="mb-3 text-primary">
                                <i className="las la-check-circle mr-1 font-18"></i> Subscribed
                                </div>}
                            {!RoomData.isSubscribed_ ?
                                <div className="mb-3">
                                    Subscribe to watch <span className="font-weight-bold ml-1 text-white">{RoomData.MovieCount} movies .</span>
                                </div>
                                : <div className="mb-3">
                                    Enjoy<span className="font-weight-bold ml-1 text-white">{RoomData.MovieCount} movies .</span>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default withAppConfig(ScreeningRoomHeader);