import React, {Component} from "react";
import PropTypes from "prop-types";
import { DeviceManagerView } from "./components/DeviceManager";
import {
    ping,
    getCurrentUserId,
    getDataFromLocalStorage,
    querystring,
    removeObjectFromData,
    setDocumentTitle,
    storeDataInLocalStore
} from "../../helpers/GeneralHelpers";
import {closeSession} from "../../helpers/ConfigHelpers";
import {getApiData} from "../../helpers/APIHelpers/MoviesAPI";
import {
    DEVICE_PAGE,
    DEVICES_LIMIT,
    ERROR,
    NO_EXPIRY,
    RESPONSE_BODY,
    STORED_LIST_SESSIONS,
    STORED_USER_SESSION_ID,
    SUCCESS
} from "../../constants/constants";
import {withAppConfig} from "../../context/ConfigContext";
import withUserSession from "../../hoc/UserSession";
import {withFirebase} from "../../context/FirebaseContext";
import _sortBy from 'lodash/sortBy';

class DeviceManager extends Component {
    isCompMounted = true;
    redirect = querystring("redirect");
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isError: "",
            devicesList: [],
            showErrorAlert: false,
            showSuccessAlert: false,
            alertMessage: "",
            deviceLimit: DEVICES_LIMIT,
            currentDeviceKey: "",
        };
        this.getUserDevices = getApiData.bind(this);
    }

    async componentDidMount() {
        setDocumentTitle(DEVICE_PAGE);
        await this.getCurrentDevice();
        const { ConcurrentDevices = DEVICES_LIMIT } = this.props.appSettings;
        this.setState({
            deviceLimit: ConcurrentDevices
        });
        let  playingDevices = await this.props.getPlayingDevices();
        let result = await this.getUserDevices('AuthAPI', '/v2/listsessions', this.state.devicesList, "", "isError", "devicesList", RESPONSE_BODY, "DeviceKey");
        if (result && result.length > 0) {
            await playingDevices.forEach((id)=>{
                result.find((resultItem, index, resultArray) => {
                    if (resultItem.DeviceKey == id) {
                        resultArray[index].isPlaying = true;
                        return true;
                    }
                });
            });
            const currentDeviceKeyIndex = result.findIndex(device => device.DeviceKey === this.state.currentDeviceKey);
            if (currentDeviceKeyIndex > -1) {
                let sortedResult = [...result];
                sortedResult.push(...sortedResult.splice(0, currentDeviceKeyIndex));
                sortedResult = _sortBy(sortedResult,['isPlaying']);
                this.setState({
                    devicesList: sortedResult,
                    isLoading: false,
                })
            } else {
                let currentDevice = await this.getTempCurrentDevice();
                result.unshift(currentDevice)
                this.setState({
                    devicesList: result,
                    isLoading: false,
                    currentDeviceKey: "1232242-3224234"
                })
            }
        } else {
            this.setState({
                isLoading: false
            })
        }
    }

    componentWillUnmount() {
        this.isCompMounted = false;
    }
    
    async getCurrentIP(){
        let IP = '';
        await fetch("https://api.ipify.org?format=json").then(res => res.json()).then((res) =>{
           IP = res.ip;
        }).catch((err) => {

        });
        return IP;
    }

    getTempCurrentDevice = async () => {
        const Bowser = await import("bowser").then(bowser => bowser.default).catch((err) => {
            //errloaading
        })
        const browser = Bowser.getParser(window.navigator.userAgent);
        let today = await ping();
        let ip = await this.getCurrentIP();
        let device = {
            Browser: browser.getBrowserName(),
            BrowserVersion: browser.getBrowserVersion(),
            Device: browser.getPlatformType(),
            DeviceKey: "1232242-3224234",
            DeviceType: browser.getPlatformType(),
            DeviceVendor: "Unknown",
            LastAuth: today,
            OS: browser.getOSName(),
            OSVersion: browser.getOSVersion(),
            UserAgent: browser.getBrowserName(),
            UserIP: ip
        };
        return device;
    }

    getCurrentDevice = async () => {
        let userId = await getCurrentUserId();
        let devicekey = getDataFromLocalStorage(STORED_USER_SESSION_ID, userId);
        if (devicekey) {
            this.setState({
                currentDeviceKey: devicekey
            })
            return devicekey
        }
    }

    removeDevice = (deviceKey) => {
        const { appTranslations :tr } = this.props;
        closeSession(deviceKey, "Forget").then((res) => {
            if (res === SUCCESS) {
                this.updateState(SUCCESS, deviceKey, "showSuccessAlert", tr.device_removed_successfully);
            } else {
                this.updateState(ERROR, deviceKey, "showErrorAlert", tr.couldnt_remove_device || "Some Error Occurred");
            }
        })
    }

    updateState = (status, id, alert, message) => {
        this.props.showAlert(alert, message);
        if (status === SUCCESS) {
            this.updateDevicesList(id);
        }
    }

    updateDevicesList = async (id) => {
        let userId = await getCurrentUserId();
        this.setState((prevState) => {
            let udpatedData = removeObjectFromData(prevState.devicesList, "DeviceKey", id);
            storeDataInLocalStore(STORED_LIST_SESSIONS,udpatedData,userId,NO_EXPIRY)
            return {
                devicesList: udpatedData
            }
        });
    }

    handleProceedClick = (e) => {
        e.preventDefault();
        this.setState({
            isProceeding: true
        })
        this.props.createSession();
    }

    render() {
        return (
            <DeviceManagerView
                onConfirmDeviceRemoveClick={this.removeDevice}
                handleProceedClick={this.handleProceedClick}
                redirectString={this.redirect}
                {...this.state}
                {...this.props}
            />
        );
    }
}

export default withAppConfig(withFirebase(withUserSession(DeviceManager)));
DeviceManager.propTypes = {
    showAlert: PropTypes.func,
    appSettings: PropTypes.object,
    userHasAuthenticated: PropTypes.func
}
