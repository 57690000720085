import React, { Component } from 'react';
import PropTypes from "prop-types";
import Bitmovin from "../../../../components/BitmovinPlayer/BitmovinPlayer";
import { isEmpty } from '../../../../helpers/GeneralHelpers';

class MoviePlayer extends Component {
    renderMovieLoadingView = () => {
        return (
            <div
                className="mn-media-poster img-bg"
                style={{ display: 'block', backgroundImage: `url(${this.props.posterUrl})` }}
            >
                <div className="mn-poster-overlay">
                    <div className="mn-poster-top d-flex justify-content-between">
                        <div><h2>{this.props.title}</h2></div>
                        <div onClick={this.props.handleCloseButtonClick}><span className="font-24 btn"><i className="la la-times"></i></span></div>
                    </div>
                    {this.props.isLoadingStream &&
                        <span className="loader-spiral media-loader"><span className="spiral-inn"></span></span>
                    }
                </div>
            </div>
        )
    }

    render() {
        const { movieData, streamData, playerStartingTime } = this.props;
        let title = (movieData && movieData.Title) ? movieData.Title : "";
        let posterUrl = movieData ? movieData.PreviewImage : '';
        let licenceUrl = (streamData && streamData.length > 0 && streamData[0] && streamData[0].LicenseServer) ? streamData[0].LicenseServer : movieData.Encryption === 0 ? "" : null;
        let movieStream = (streamData && streamData.length > 0 && streamData[0] && streamData[0].URL) ? streamData[0].URL : null;
        const subtitleTracks = (movieData && movieData.Assets && movieData.Assets.Subtitles) ? movieData.Assets.Subtitles.map((track, index) => {
            return { id: `sub${index}`, isDefaultTrack: true, kind: 'subtitle', ...track }
        }) : []
        return (
            this.props.isLoadingStream ?
                this.renderMovieLoadingView()
                :
                !this.props.isLoadingStream && isEmpty(streamData) ?
                    this.renderMovieLoadingView()
                    :
                    <div className="mn-media-data img-bg" style={{ display: 'block', backgroundImage: `url(${posterUrl})` }}>
                        <div className="mn-media-top d-flex w-100 justify-content-between">
                            <div className="media-top-left">
                                <button onClick={this.props.handleBackButtonClick} className="media-back"></button>
                            </div>
                        </div>
                        <Bitmovin
                            title={title}
                            isMoviePlayer={true}
                            movieStream={movieStream}
                            licenceUrl={licenceUrl}
                            posterUrl={posterUrl}
                            playerStartingTime={playerStartingTime}
                            enableCast={true}
                            autoplay={true}
                            onVideoPlay={this.props.onVideoPlay}
                            onVideoPause={this.props.onVideoPause}
                            onVideoError={this.props.onVideoError}
                            onVideoEnd={this.props.onVideoEnd}
                            onError={this.props.onError}
                            onPlayerDestroyed={this.props.onPlayerDestroyed}
                            showMovieSuggestions={this.props.showMovieSuggestions}
                            movieId={this.props.movieId}
                            handleMovieClick={this.props.handleMovieClick}
                            isAuthenticated={this.props.isAuthenticated}
                            playerSessionId={this.props.playerSessionId}
                            subtitleTracks={subtitleTracks}
                        />

                    </div>
        )
    }
}

export { MoviePlayer };
MoviePlayer.defaultProps = {
    title: "",
    posterUrl: null,
    playerStartingTime: 0,
    showMovieSuggestions: false
}
MoviePlayer.propTypes = {
    isLoadingStream: PropTypes.bool.isRequired,
    handleMovieClick: PropTypes.func.isRequired,
    showMovieSuggestions: PropTypes.bool,
    title: PropTypes.string,
    movieId: PropTypes.string.isRequired,
    streamData: PropTypes.array.isRequired,
    movieData: PropTypes.object.isRequired,
    posterUrl: PropTypes.string,
    playerStartingTime: PropTypes.number,
    onVideoPlay: PropTypes.func,
    onVideoPause: PropTypes.func,
    onVideoError: PropTypes.func,
    onVideoEnd: PropTypes.func,
    onError: PropTypes.func,
    onPlayerDestroyed: PropTypes.func,
    handleCloseButtonClick: PropTypes.func,
    handleBackButtonClick: PropTypes.func,
}
