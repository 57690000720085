import React, { Fragment } from "react";
import { getRoomDuration } from "../../helpers/MoviesHelper";

const Heading = props => {
  return (
    <Fragment>
      <h1 className="font-weight-bold mb-4">{props.Title}</h1>
      {props.DetailType === "screening_room" ? (
        <ScreeningText
          count={props.MovieCount}
          Duration={props.Duration}
          allService={props.AllServiceMovies}
        />
      ) : (
        <CollectionText count={props.MovieCount} Duration={props.Duration} />
      )}
      <div className="text-white-50 mb-4">{props.Description}</div>
    </Fragment>
  );
};

export default Heading;

const CollectionText = props => {
  return (
    <div className="text-white-50 mb-4">
      This bundle consists of{" "}
      <strong className="text-white">{props.count} movies</strong> available for{" "}
      <strong className="text-white">{props.Duration} days</strong> .
    </div>
  );
};

const ScreeningText = props => {
  let count = props.allService === "active" ? "all service" : props.count;
  return (
    <div className="text-white-50 mb-4">
      Subscribe for{" "}
      <strong className="text-white">{getRoomDuration(props.Duration)}</strong>{" "}
      to watch <strong className="text-white">{count} movies </strong>.
    </div>
  );
};
