import React from "react";
import config from "../../config/config";
import { withAppConfig } from "../../context/ConfigContext";

const GeoBlock = ({ appTranslations : tr , ...props }) => {
  const { Logo = config.Logo } = props.appSettings;
  return (
    <main className="mn-main">
      <section className="mn-error py-5">
        <div className="container">
          <div className="mn-error-body text-white-50">
            <div className="mb-4">
              <img style={{ height: "30px" }} src={Logo} />
            </div>
            <div className="mb-3 text-uppercase font-36 font-weight-bold text-white">
              {tr.content_not_available_in_your_region ||
                "Content Not available in your region."}
            </div>
            {/* <div className="mb-3 font-18">This content is currently not available in your region</div> */}
          </div>
        </div>
      </section>
    </main>
  );
};

export const GeoBlockView = withAppConfig(GeoBlock);