import React, { useState, useEffect, memo } from "react";
import Loader from "../../../components/Loader/Loader";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";
import getScreeningRoomDetail from "../../../services/movies/GetRoomDetail";
import HeroSection from "./components/HeroSection";

const RoomDetail = ({ HostName, ...props }) => {
  const [detail, setDetail] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    initialize();
    return () => {};
  }, [props.isSubscribed]);

  const initialize = () => {
    let params = {
      HostName: HostName,
      ID: props.match.params.id
    };
    getScreeningRoomDetail((response, err) => {
      if (!err) {
        response["isSubscribed_"] = props.isSubscribed.isSubscribed_;
        response["SubscribedManifestType"] = props.isSubscribed.ManifestType;
        response["Price"] = updatePrice(response.Price);
        setDetail(response);
        if (response.AllServiceMovies === "active") {
         // props.setServiceMovies(true); //have passed this parameter from room card so dont need this call anymore
        }
      }
      setLoading(false);
    }, params);
  };

  if (isLoading) {
    return (
      <section>
        <Loader />
      </section>
    );
  }
  if (!isLoading && detail && detail.length < 1) {
    return (
      <section className="mn-movies py-4">
        <DataNotFound />
      </section>
    );
  }
  return <HeroSection data={detail} />;
};

const MemoDetail = memo(RoomDetail);
export default MemoDetail;

const updatePrice = price => {
  let result = price;
  if (result && result.length > 0) {
    result = price.map(item => {
      return { ...item, Price: parseInt(item.Price) };
    });
  }
  return result;
};
