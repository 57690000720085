import React, { Component, Fragment } from "react";
import { Collapse } from "reactstrap";
import PropTypes from "prop-types";
import { withAppConfig } from "../../../../context/ConfigContext";

class SearchBox extends Component { //implement SearchContext later!
    constructor(props) {
        super(props);
        this.state = { collapse: false };
        this.searchTerm = React.createRef()
    }

    componentDidUpdate(prevProps) {
        if (this.props.searchFlag !== prevProps.searchFlag) {
            this.handleClearSearchValue();
        }
    }

    handleClearSearchValue = () => {
        this.searchTerm.current.value = "";
        this.props.clearSearch();
        if (this.state.collapse) {
            this.toggle();
        }
    }

    onClickSearchIcon = async () => {
        await this.toggle();
        this.searchTerm.current.focus();
    }

    toggle = () => {
        this.setState({ collapse: !this.state.collapse });
    }

    handleClearSearchClick = (e) => {
        this.props.clearSearch(e);
        this.toggle();
    }

    submitSearch = (e) => {
        e.preventDefault();
    }

    render() {
        const { setSearchTerm, searchTerm , appTranslations : tr } = this.props;
        let searchQuery = decodeURIComponent(searchTerm);
        return (
            <Fragment>
                <form className="mn-search-wrap" onSubmit={this.submitSearch}>
                    <div className="mn-search-input">
                        <label className="mn-search-icon left" htmlFor="searchInput"><i className="la la-search"></i></label>
                        <input name="searchTerm"
                            value={searchQuery}
                            id="searchInput"
                            onChange={e => { setSearchTerm(encodeURIComponent(e.target.value)) }}
                            type="text"
                            maxLength="60"
                            ref={this.searchTerm}
                            className="form-control form-control-lg form-control-dark"
                            required
                            autoComplete="off"
                            placeholder={tr.search_movies || "Search Movies"} 
                            />
                        <a
                            onClick={this.handleClearSearchClick}
                            className="mn-search-icon right" >
                            <i className="la la-times"></i>
                        </a>
                    </div>
                </form>
            </Fragment>
        )
    }
}

export default withAppConfig(SearchBox);
SearchBox.propTypes = {
    setSearchTerm: PropTypes.func.isRequired,
    clearSearch: PropTypes.func.isRequired,
    searchTerm: PropTypes.string.isRequired
}