import React from "react";
import PropTypes from "prop-types";
import { withAppConfig } from "../../../context/ConfigContext";

const ComingSoonTag = ({ HVSD, showComingSoonTag , appTranslations : tr }) => {
  let date = HVSD;
  if (HVSD) {
    date = date.slice(0, 10);
    date = `${tr.coming_on} ${date}`;
  } else {
    date = tr.coming_soon;
  }
  return showComingSoonTag ? (
    <span className={`mn-tag rented`}>{date}</span>
  ) : null;
};

export default withAppConfig(ComingSoonTag);

ComingSoonTag.propTypes = {
  showComingSoonTag: PropTypes.bool,
  HVSD: PropTypes.string
};
