import React from 'react';
import { Modal } from "reactstrap";
import { withAppConfig } from '../../../../context/ConfigContext';
import { ROOMDETAIL, COLLECTIONDETAIL } from '../../../../constants/routes';
const TransactionModal = (props) => {
    const handleWatchMovieClick = () => {
        if (props.Type === "collection") {
            props.history.replace(`${COLLECTIONDETAIL}/${props.ID}`);
          } else if (props.Type === "screening_room") {
            props.history.replace(`${ROOMDETAIL}${props.ID}`);
          }
    }; 
    const handleMovieClick = () => {
      if (props.Type === "collection") {
        props.history.replace(`${COLLECTIONDETAIL}/${props.ID}`);
      } else if (props.Type === "screening_room") {
        props.history.replace(`${ROOMDETAIL}${props.ID}`);
      }
    };

    return (
        <Modal
            isOpen={true}
            centered
            modalClassName="modal-dark"
        >
            <div className="modal-content">
                <div className="modal-body p-4 p-sm-5 text-white-50">
                    <div className="card-body text-center position-relative" style={{ "minHeight": "150px" }}>
                        <div className="mn-payment-msg">
                            <div className="mb-3 text-success"><i className="la la-check-circle font-48"></i></div>
                            <h3 className="text-success">Transaction Successfull</h3>
                            <Title {...props}  />
                            <Duration {...props}  />
                                <div className="my-5">
                                    <a href="javascript:void(0)" onClick={handleWatchMovieClick} className="btn btn-primary">{props.appTranslations.start_watching}</a>
                                    <a href="javascript:void(0)" onClick={handleMovieClick} className="btn btn-secondary ml-3 "> {props.appTranslations.not_yet}</a>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </ Modal>
    )
}
const Title = (props) =>{
    if (props.Type === "collection") {
        return(<p>Your <strong className="text-white">{props.data.Title}</strong> Bundle has been rented successfully and ready to watch.</p>)
    } else if (props.Type === 'screening_room'){
        return(<p>Your Subscription of <strong className="text-white"> {props.data.Title}</strong> has been processed successfully.</p>)
    }
    return null;
}

const Duration = (props)=> {
    if (props.Type === "collection") {
        return(<p>You have <strong className="text-white">{props.data.Duration}</strong> days to watch all the movies in the Bundle. </p>)
    } else if (props.Type === 'screening_room'){
        return(<p>You have <strong className="text-white"> {props.data.Duration} subscription</strong> to watch all the movies in the Channel.</p>)
    }
    return null;  
}

export default withAppConfig(TransactionModal)
