import { VOUCHER_SUCCESS, VOUCHER_FAILED, MOVIE_VIDEO, SUCCESS, STORED_MY_PARTNER_NAME, REWARD, STORED_SECTIONS_DATA, STORED_REWARD_PROMO_MOVIES, PARTNER_SECTIONS, OFFERMOVIES_TYPE, PROMOTIONALMOVIES_TYPE, NO_EXPIRY, STORED_REWARD_MOVIES } from "../constants/constants";
import { getCurrentUserId, isEmpty, getDataFromLocalStorage, removeItemFromStore, storeDataInLocalStore } from "./GeneralHelpers";
import { WATCH } from "../constants/routes";
import _find from "lodash/find";
import { getCognitoUserAttributes, getSettingsFromConfig } from "./ConfigHelpers";
import defaultbackgorundImage from "../assets/images/login-bg.jpg";
import { updateCacheOnNewPurchaseItem, getPurchaseData } from "./MoviesHelper";
import config from "../config/config";
import _round from "lodash/round"
const NOTFREEVOUCHERMESSAGE = "Sorry! Your Voucher is not 100% Free";


export function redeemVoucher(voucherCode, movieID, teirId, teirType, totalTierPrice, ManifestDetails, movie) {
    let header = {
        Voucher: voucherCode,
        MovieID: movieID,
        TotalPrice: totalTierPrice,
        Quality: ManifestDetails
    };
    if (voucherCode !== "") {
        this.setState({
            isVerifyingVoucher: true
        })
        this.verifyRedeemVoucher("OrdersAPI", "/canRedeem", header, this.state.voucherCode, "showSuccessAlert", "showErrorAlert", "alertMessage", VOUCHER_SUCCESS, VOUCHER_FAILED, "VERIFY_VOUCHER_PARTNER", "isVerifyingVoucher").then((voucherResponse) => {
            if (isEmpty(voucherResponse)) {//means error
                this.setState({
                    showPurchaseModel: false
                });
            } else {
                let allowCheckout = checkVoucherResult(voucherResponse, totalTierPrice);
                if (allowCheckout) {
                    this.setState({
                        isCheckingOut: true
                    });
                    let checkoutParams = {
                        PurchaseType: teirType,
                        TierID: teirId,
                        PaymentGateway: "BrainTree",
                        MovieID: movieID,
                        Voucher: voucherCode,
                        Price: 0, //voucherResponse.TotalPrice
                        PaymentNonce: "XXXXXX",
                        Quality: ManifestDetails
                    }
                    this.handleCheckout(checkoutParams);
                } else {
                    this.setState({
                        showErrorAlert: false,
                        alertMessage: NOTFREEVOUCHERMESSAGE
                    });
                    this.disableAlert("showErrorAlert", "alertMessage");
                }
            }
        })
    }
}

export function checkVoucherResult(voucherData, moviePrice) {
    let isfullDiscounted;
    if (voucherData.DiscountType === "Variable" && voucherData.DiscountValue === 0) {
        isfullDiscounted = true
    } else if (voucherData.DiscountType === "Percentage" && voucherData.DiscountValue === 100) {
        isfullDiscounted = true
    } else if (voucherData.DiscountType === "Fixed" && (voucherData.DiscountValue >= moviePrice)) {
        isfullDiscounted = true
    }
    return isfullDiscounted;
}

export function handleCheckout(checkoutParams, rewardCheckout = true, freeVoucher = false) { //also used on MovieDetail!! && showSuccessfullPopup=>from reward
    let showErrorAlert = rewardCheckout ? "showErrorAlert" : ""; //dont showalert in othercase
    let showSuccessAlert = rewardCheckout ? "showSuccessAlert" : "";
    this.submitChekoutForm("OrdersAPI", "/checkout", checkoutParams, null, showSuccessAlert, showErrorAlert, "alertMessage", "Your checkout was Successfull", "Couldn't checkout. Try again ", "SUBMIT_FREE_PAYMENT_FORM_PARTNER", "", "transaction").then((res) => {
        if (!isEmpty(res)) { //NOT eroor
            if (rewardCheckout) {
                this.props.decideShowParnterOfferStrip();
            }
            if(res.BalanceAmount){
                this.setState({
                    reusableVoucherBalance:_round( res.BalanceAmount,2)
                })
            }
            getCurrentUserId().then(async (userId) => {
                await updateCacheOnNewPurchaseItem(userId, res);
                this.setState({
                    isCheckingOut: false,
                    showPurchaseModel: false,
                    paymentWasSuccessfull: true,
                    transactionSuccessModalHeader: (rewardCheckout || freeVoucher) ? null : "Free Watch Successfull"
                });
                if (rewardCheckout) {
                    removeItemFromStore(STORED_SECTIONS_DATA, userId)
                }
            });
        } else {
            this.setState({
                isCheckingOut: false,
                showPurchaseModel: false,
            })
        }
    })
}

export function handleWatchMovieClick(e, movieId) {
    this.setState((prevState) => {
        return {
            paymentWasSuccessfull: !prevState.paymentWasSuccessfull
        }
    }, () => {
        this.props.history.push({
            pathname: `${WATCH}${MOVIE_VIDEO}/${movieId}`,
            state: {
                alert: { messageType: SUCCESS, message: " Movie Purchased Successfully! Enjoy Watching!" }
            }
        });
    }
    );
}

export function handleMovieClick(e, movieId) {
    this.setState((prevState) => {
        return {
            paymentWasSuccessfull: !prevState.paymentWasSuccessfull
        }
    }, () => {
        this.props.history.push(`/movie/${movieId}`)
    });
}

export async function decideShowParnterOfferStrip(userId) {
    let updateState = false;
    if (this.props.isAuthenticated) {
        let cognitoPartnerData = await getCognitoUserAttributes(false, false, userId);
        if (cognitoPartnerData && !isEmpty(cognitoPartnerData)) {
            if (cognitoPartnerData['custom:PartnerName'] === REWARD) {
                let isValidPatner = await getValidPartnerData(REWARD);
                if (isValidPatner) {
                    let myPurchaseList = await getPurchaseData(userId);
                    let purchaseMovie = _find(myPurchaseList, function (obj) { return obj.Voucher; });
                    if (!purchaseMovie) {
                        this.setState({
                            // showOfferStrip: true
                            showOfferStrip: false
                        })
                    } else {
                        updateState = true;
                    }
                }
            } else {
                updateState = true;
            }
        }
    } else {
        let partnerName = getDataFromLocalStorage(STORED_MY_PARTNER_NAME);
        if (partnerName === REWARD) {
            if (!this.state.showOfferStrip) {
                this.setState({
                    showOfferStrip: false //true ? false
                })
            }
        }
    }
    if (updateState) {
        removeItemFromStore(STORED_MY_PARTNER_NAME)
        if (this.state.showOfferStrip) {
            this.setState({
                showOfferStrip: false
            })
        }
    }
}

export async function getValidPartnerData(partnerName) {
    let partners = await getSettingsFromConfig("AFFILIATE_PARTNERS")
    let partnerData = _find(partners, ['Slug', partnerName]);
    return partnerData
}

export const setPartnerTheme = (logo, partner, clear = false) => {
    let myLogo = document.getElementById("myAppLogo");
    let myBackground = document.getElementById("my-page-outerContainer");
    if (!isEmpty(partner.Theme)) {
        this.partnerTheme = partner.Theme;
        if (partner.Theme && partner.Theme.Logo) {
            myLogo.src = partner.Theme.Logo ? partner.Theme.Logo : ""
        }
        if (partner.Theme && partner.Theme.Background) {
            myBackground.style.backgroundImage = `url(${partner.Theme.Background})`
        }
        if (partner.Theme && partner.Theme.Favicon) {
            let link = document.createElement('link');
            link.rel = "icon";
            link.type = "image/png";
            link.sizes = "16x16";
            link.id = "myCustomFavicon"
            link.href = partner.Theme.Favicon ? partner.Theme.Favicon : "";
            document.head.appendChild(link);
        }
    } else {
        if (clear) {
            myBackground.style.backgroundImage = `url(${defaultbackgorundImage})`;
            myLogo.src = logo;
            let myCustomFavicon = document.getElementById("myCustomFavicon");
            if (myCustomFavicon) {
                myCustomFavicon.href = config.applicationFavicon; // appendDefaultFavicon
            }
        }
    }
}

export async function verifyShowPartnerStrip() {
    if (this.props.isAuthenticated) {
        if (!this.USER_ID) {
            this.USER_ID = await getCurrentUserId();
        }
        let cognitoPartnerData = await getCognitoUserAttributes(false, false, this.USER_ID);
        if (cognitoPartnerData && !isEmpty(cognitoPartnerData)) {
            if (cognitoPartnerData['custom:PartnerName'] === REWARD) {
                this.setState({
                    showPromotionDiscountMessage: true
                })
            }
        }
    } else { //will not come in this condition -- 
        let partnerName = getDataFromLocalStorage(STORED_MY_PARTNER_NAME)
        if (partnerName === REWARD) {
            this.setState({
                showPromotionDiscountMessage: true
            })
        }
    }
}

export async function getPartnerPromotionalMovies(returnObj = false) {
    let promotionalMovies = getDataFromLocalStorage(STORED_REWARD_PROMO_MOVIES);
    if (!promotionalMovies) {
        let rewardMovies = getDataFromLocalStorage(STORED_REWARD_MOVIES);
        if (!rewardMovies) {
            rewardMovies = await this.getRewardMovies("MoviesAPI", "/rewardPlusSections", "", "", "", "", PARTNER_SECTIONS).then((response) => {
                if (!isEmpty(response)) {
                    storeDataInLocalStore(STORED_REWARD_MOVIES, response);
                }
                return response;
            });
        }
        let promotionalMovies = _find(rewardMovies, ['Type', PROMOTIONALMOVIES_TYPE]);
        await storeDataInLocalStore(STORED_REWARD_PROMO_MOVIES, promotionalMovies, null, NO_EXPIRY);
        return (promotionalMovies && !returnObj) ? promotionalMovies.Items : promotionalMovies ? promotionalMovies : !returnObj ? [] : {}
    }
    return (promotionalMovies && !returnObj) ? promotionalMovies.Items : promotionalMovies ? promotionalMovies : !returnObj ? [] : {}
}

export async function checkifOfferAvailed(userId) {
    let myPurchaseList = await this.getPurchaseData(userId);
    let purchaseMovie = _find(myPurchaseList, function (obj) { return obj.Voucher; });
    if (purchaseMovie) {
        return true;
    }
    return false;
}

export async function checkIsMoviePromotional(movieID) {
    let promotionalMovies = await this.getPartnerPromotionalMovies();
    let isMoviePromotional = false;
    if (promotionalMovies) {
        let movie = _find(promotionalMovies, ["ID", movieID]);
        if (movie) {
            isMoviePromotional = true;
        }
        return isMoviePromotional;
    }
}

export async function getPromotionalMovieTeir() {
    let promotionalMovies = await this.getPartnerPromotionalMovies(true);
    let teirObj = {
        "Price": "",
        "Quality": ""
    }
    if (promotionalMovies) {
        teirObj["Price"] = "$1"; //hardcoded
        teirObj["Quality"] = promotionalMovies.Quality ? promotionalMovies.Quality : "HD"; //default HD
    }
    return teirObj;
}

export function getRewardSelectedTeirId(rentParams, quality) {
    let teir = {};
    if (rentParams && quality) {
        teir = _find(rentParams, (obj) => {
            if (obj.ManifestDetails[0] === quality.toLowerCase()) {
                return obj.ID
            }
        });
    }
    if (!teir) {
        teir = rentParams[0];
    }
    return teir ? teir.ID : quality
}

export async function decidePartnerFlow() {
    if (config.customSettings.isStuffpixEnabled) { //for Muvinow
        return true;
    }
    if (!this.props.isAuthenticated) {
        return true;
    }
    let userId = await getCurrentUserId();
    let partnerName = await getCognitoUserAttributes(false, true, userId);
    this.USER_ID = userId;
    if (partnerName !== REWARD) {
        return true;
    }
    let isOfferAvailed = await this.checkifOfferAvailed();
    if (!isOfferAvailed) {
        return true;
    }
    let isMoviePromotional = await this.checkIsMoviePromotional(this.props.movieID);
    if (!isMoviePromotional) {
        return true;
    }
    this.updateMovieTier();
}

export async function getAppliedVoucherCode(userId) {
    let myPurchaseList = await this.getPurchaseData(userId);
    let purchaseMovie = _find(myPurchaseList, function (obj) { return obj.Voucher; });
    if (purchaseMovie) {
        return purchaseMovie.Voucher
    }
    return "";
}