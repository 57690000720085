import Amplify from "@aws-amplify/core";
import Cache from "@aws-amplify/cache";
import config from "./config/config";

export function initializeAWS() {
  Cache.configure({
    keyPrefix: config.service.stage + "-" + config.service.service + "-",
    itemMaxSize: 1000000, //bytes =>1MB
    capacityInBytes: 5000000 //bytes =>5MB
  });

  Amplify.configure({
    Auth: {
      mandatorySignIn: false,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID,
      oauth: config.oauth,
      authenticationFlowType: config.cognito.authenticationFlowType
    },
    Storage: {
      region: config.s3.REGION,
      bucket: config.s3.BUCKET,
      identityPoolId: config.cognito.IDENTITY_POOL_ID
    },
    API: {
      endpoints: config.apiGateway.endpoints
    }
  });
}
