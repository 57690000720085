import { GET } from "../../api/";
import {
  getDataFromLocalStorage,
  storeDataInLocalStore
} from "../../helpers/GeneralHelpers";
import _concat from "lodash/concat";

async function GetMyCollections(callback, { bypassCache = false, ...params }) {
  const { UserId } = params;
  let cache = getDataFromLocalStorage("mycollections", UserId);
  if (!bypassCache) {
    if (cache) {
      callback(cache);
      return cache;
    }
  }
    let response = await fetchAllCollections();
    if (response) {
      storeDataInLocalStore("mycollections", response, UserId);
      callback(response);
    } else {
      callback(null, "Failed");
    }
    return;
}


function fetchAllCollections(LastEvaluatedKey = ``, data = []) {
  let path = `/my-collections?Limit=12&ExclusiveStartKey=${LastEvaluatedKey}`;
  return GET("OrdersAPI", path)
    .then(res => {
      if (res.body && res.body.Items) {
        let result = _concat(data, res.body.Items);
        if (res.body.LastEvaluatedKey) {
          return fetchAllCollections(res.body.LastEvaluatedKey, result);
        } else {
          return result;
        }
      }
    })
    .catch(e => {
      return null;
    });
}


export default GetMyCollections;
