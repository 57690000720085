import React from "react";
import { PrevArrow, NextArrow } from "../../../components/SlickButtons/";
import _find from "lodash/find";
import {
  SERVICE_UNAVAILABLE,
  REDIRECT_URL,
  CHECKOUT
} from "../../../constants/routes";
import { PURCHASE, QUERYTIERID } from "../../../constants/constants";
import { getLowestQulaity } from "../../../helpers/MoviesHelper";
import config from "../../../config/config";

export function sliderConfig() {
  return {
    dots: true,
    infinite: true,
    lazyLoad: "progressive",
    speed: 500,
    autoplaySpeed: 12000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "mn-banner",
    arrows: true,
    pauseOnHover: true,
    pauseOnDotsHover: true,
    pausedOnFocus: true,
    touchThreshold: 15,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />
  };
}

export function getButtonText(banner) {
  let [rentButtonText, ownButtonText, subscriptionButtonText] = ["RENT", "BUY", "SUBSCRIBE"];
  if (banner.isAvailableForSubscription) {
    subscriptionButtonText = `${subscriptionButtonText}`;
  }

  if (
    config.customSettings.isStuffpixEnabled &&
    banner.isAvailableForRent &&
    banner.RentParams &&
    banner.RentParams.length > 1
  ) {
    rentButtonText = "RENT FROM";
  }

  if (banner.isAvailableForRent)
    rentButtonText = `${rentButtonText} ${getLowestQulaity(banner.RentParams)}`;
  if (
    config.customSettings.isStuffpixEnabled &&
    banner.isAvailableForBuy &&
    banner.BuyParams &&
    banner.BuyParams.length > 1
  ) {
    ownButtonText = "BUY FROM";
  }
  if (banner.isAvailableForBuy)
    ownButtonText = `${ownButtonText} ${getLowestQulaity(banner.BuyParams)}`;

  return [rentButtonText, ownButtonText, subscriptionButtonText];
}

export function handlePurchaseBannerClick(
  movieId,
  type,
  params,
  FreeWatch = false,
  config
) {
  // used in moviesDetail and Home banner
  // e.preventDefault();
  // e.stopPropagation();
  if (!this.props.isAuthenticated) {
    let teirId = params && params[0] ? params[0].ID : "";
    this.props.history.push({
      pathname: `${CHECKOUT}${movieId}`,
      search: `?${PURCHASE}=${type}&${QUERYTIERID}=${teirId}`
    });
  }
  let geoBlock = getGeoBlock(config);
  if (geoBlock[0].isGeoBlocked) {
    if (this.props.isAuthenticated) {
      //just show that static page
      this.props.history.push(`${SERVICE_UNAVAILABLE}`);
    } else {
      // will be handled automatically
      this.props.history.push(`${REDIRECT_URL}${this.props.location.pathname}`);
    }
  } else {
    this.setState({
      selectedPurchaseMovieId: movieId,
      selectedPurchaseMovieType: type,
      showPurchaseModel: true
    });
  }
}

export function getGeoBlock(config) {
  let settings = _find(config, ["KEY", "REGION_POLICY"]);
  if (settings && settings.VALUE && settings.VALUE.length > 0) {
    return settings.VALUE[0].isGeoBlocked;
  }
  return false;
}
