import React, { useState, useEffect, memo } from "react";
import Header from "./components/PageHeader";
import { withRouter } from "react-router-dom";
import { withAppConfig } from "../../../context/ConfigContext";
import DescriptionTable from "./components/DescriptionTable";
import Loader from "../../../components/Loader/Loader";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";
import getScreeningRoomDetail from "../../../services/movies/GetRoomDetail";
import getCollectionDetail from "../../../services/movies/GetCollectionDetail";
import { querystring } from "../../../helpers/GeneralHelpers";

const Detail = props => { 
  const [detail, setDetail] = useState([]);
  const [isLoading, setLoading] = useState(true);
  // let methodType = querystring("method");
  let methodType = props.Type;
  useEffect(() => {
    initialize();
    return () => { };
  }, []);

  const initialize = ()  => {
    let params = {
      ID: props.match.params.id
    };
    if (methodType === "screening_room") {
      getScreeningRoomDetail(onResponse, params);
    } else {
      getCollectionDetail(onResponse, params);
    }
  };

  const onResponse = (response, err) => {
    if (!err) {
      setDetail(response);
      let quality = [];
      let duration = "";

      response.Plans.forEach((item) => {
        Object.keys(item.pricelist).forEach((q) => {
          quality.push({
            Duration: item.duration,
            Quality: q
          });
        });
        if (duration != "") {
          duration = item.duration;
        }
      });
      let selectedQuality = querystring("type");
      if (quality && quality.length > 0) {
        props.initialize(selectedQuality, quality, response);
      }
    }
    setLoading(false);
  };

  if (isLoading) {
    return (
      <section>
        <Loader />
      </section>
    );
  }
  if (!isLoading && detail && detail.length < 1) {
    return (
      <section className="mn-movies py-4">
        <DataNotFound />
      </section>
    );
  }

  let params = {
    data: detail,
    checkoutData: props.checkoutData,
    ID: props.match.params.id,
    Type: methodType,
    onChangeVoucher: props.onChangeVoucher,
    onChangeDuration: props.onDurationChange,
    onPriceCalculated: props.onPriceCalculated,
    ...props
  };
  return (
    <div>
      <Header
        methodType={methodType}
        appTranslations={props.appTranslations}
        {...detail}
      />
      <DescriptionTable onChange={props.onQualityChange} {...params} />
    </div>
  );
};

export default withAppConfig(withRouter(Detail));
