import { GET } from "../../api/";
import _isEmpty from "lodash/isEmpty";
import {
  storeDataInLocalStore,
  getDataFromLocalStorage
} from "../../helpers/GeneralHelpers";
import { handleImageResizing } from "../../helpers/CDNhelpers";

export default async function getScreeningRoomDetail(callback, params) {
  const { userID } = params;
  let room = getDataFromLocalStorage(`screeningRoom-${params.ID}`, userID);
  if (!room || _isEmpty(room)) {
    room = await GET("MoviesAPI", `/screening-room/${params.ID}`)
      .then(res => {
        console.log("res", res)
        return res.body;
      })
      .catch(e => {
        let message = e;
        if (e.response && e.response.data && e.response.data.errorMessage) {
          message = e.response.data.errorMessage.replace("[403] ", "");
        }
        callback(null, message);
        return;
      });
  }
  if (!room) {
    return;
  }
  const { HostName } = params;

  room = await handleImageResizing(room, "FETCH_MOVIE", HostName);
  if (!_isEmpty(room)) {
    storeDataInLocalStore(`screeningRoom-${params.ID}`, room, userID);
  }
  callback(room);
}
