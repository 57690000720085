import { POST } from "../../api";
import { handleImageResizing } from "../../helpers/CDNhelpers";
import _uniqBy from "lodash/uniqBy";
import _concat from "lodash/concat";
import {
  getDataFromLocalStorage,
  storeDataInLocalStore as store
} from "../../helpers/GeneralHelpers";
import { STORED_MOVIES_TYPES, NO_EXPIRY } from "../../constants/constants";
import { checkIsMovieSubscribed } from "../../helpers/MoviesHelper";

export async function listMoviesByFilter(p = {}, callback) {
  let sortDirection = getSortDirection(p.selectedSort);
  let filter = `${p.selectedGenre}${
    p.selectedSort ? `-${p.selectedSort}` : ``
  }-${sortDirection}`;

  let cache = getDataFromLocalStorage(`${STORED_MOVIES_TYPES}-${filter}`);
  let response = cache;
  let finalLastEvaluatedKey = "";
  let errorMessage = null;

  if (cache) {
    if (cache.LastEvaluatedKey) {
      p.moviesList = cache.Items;
      p.LastEvaluatedKey = cache.LastEvaluatedKey;
    } else {
      response = cache.Items;
      finalLastEvaluatedKey = cache.LastEvaluatedKey;
    }
  }
  if (!cache || cache.LastEvaluatedKey) {
    // fetch if no cache or if LastEval
    let header = {
      ExclusiveStartKey: p.LastEvaluatedKey,
      Filters: JSON.stringify([
        { FilterType: "genre", FilterValue: p.selectedGenre }
      ]),
      Limit: 24,
      SortDirection: sortDirection,
      SortType: getSortType(p.selectedSort)
    };

    await POST("MoviesAPI", "/listMoviesByFilter", header)
      .then(async res => {
        let list = res.body.Items;
        list = await handleImageResizing(list, "Movies", p.HostName);
        if (p.moviesList) {
          list = _concat(p.moviesList, list);
        }
        list = _uniqBy(list, "ID");
        if (list && list.length > 0) {
          let cachedata = {
            LastEvaluatedKey: res.body.LastEvaluatedKey,
            Items: list
          };
          await store(`moviesfiltered-${filter}`, cachedata, null, NO_EXPIRY);
        }
        response = list;
        finalLastEvaluatedKey = res.body.LastEvaluatedKey;
        return;
      })
      .catch(e => {
        errorMessage = e;
        if (e.response && e.response.data && e.response.data.errorMessage) {
          errorMessage = e.response.data.errorMessage.replace("[403] ", "");
        }
        return;
      });
  }
  if(p.subscriptions  && p.isAuthenticated){
    response = mapSubscribed(response, p.subscriptions);
  }
  callback(
    { list: response, LastEvaluatedKey: finalLastEvaluatedKey },
    errorMessage
  );
  return;
}

function getSortDirection(sort) {
  if (sort === "az") {
    return "true";
  }
  return "false";
}

function getSortType(sort) {
  switch (sort) {
    case "az":
      return "Title";
    case "za":
      return "Title";
    case "popularity":
      return "Popularity";
    case "releasedate":
      return "ReleaseYear";
    case "recentlyadded":
      return "RecentlyAdded";
  }
}

function mapSubscribed(list, subscriptions) {
  list = list.map(movie => {
    movie["isSubscribed_"] = checkIsMovieSubscribed(subscriptions, movie);
    return movie;
  });
  return list;
}
