import React, { Component } from "react";
import PropTypes from "prop-types";
import { NavLink } from 'react-router-dom';
import { SETTINGS } from "../../../constants/routes";
import { jsDateToYYYYMMDD } from "../../../helpers/GeneralHelpers";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";

class NotificationsView extends Component {

    renderDeleteNotificationsIcon = (notification) => {
        return (
            <div className="">
                {this.props.isremovingNotification[notification.ID] ?
                    <span className="btn-del"><i className="la la-spin fa-spinner"></i></span>
                    :
                    <span onClick={(e) => this.props.handleDeleteNotificationClick(e, notification.ID, notification.CreatedAt)} className="btn-del"><i className="la la-trash-o"></i></span>
                }
            </div>
        )
    }

    renderNotificationHeader() {
        return (
            <div className="d-sm-flex justify-content-between align-items-baseline">
                <div className="mb-4">
                    <h2 className="mb-0">Notifications</h2>
                </div>
                <div className="mb-4">
                    <NavLink to={SETTINGS}>Go to Account Settings</NavLink>
                </div>
            </div>
        )
    }

    renderNotificationList = (dataList) => {
        return (
            dataList.map((notification, index) => {
                return (
                    <a key={index} onClick={(e) => this.props.handleNotificationClick(e, notification.CreatedAt, notification.ID, notification.Data.MovieID, notification.isSeen)} href="javascript:void(0)" className={` ${notification.isSeen ? "disabled" : ""} list-group-item list-group-item-action list-group-item-dark flex-column align-items-start`}>{/*disabled*/}
                        <div className="d-flex justify-content-between w-100 font-12">
                            <div className="d-flex">
                                <div>
                                    <div className="mn-figure mr-3">
                                        <div className="mn-thumb img-1x1 rounded-circle" style={{ backgroundImage: `url(${notification.Data.Poster}`, width: "40px" }}></div>
                                    </div>
                                </div>
                                <div>
                                    <div className="mb-1" onClick={(e) => this.props.handleNotificationClick(e, notification.CreatedAt, notification.ID, notification.Data.MovieID, notification.isSeen, true)}><strong>{notification.Data.MovieName}</strong>: {notification.Message}</div>
                                    <div className="grey-text"><small>{jsDateToYYYYMMDD(notification.UpdatedAt)}</small></div>
                                </div>
                            </div>
                            <div className="ml-1">
                                {this.renderDeleteNotificationsIcon(notification)}
                            </div>
                        </div>
                    </a>
                )
            })
        )
    }

    render() {
        return (
            <section className="mn-notifications py-5">
                <div className="container">
                    {this.renderNotificationHeader()}
                    <div className="mn-not-list">
                        <div className="list-group">
                            {this.props.notificationsData &&
                                this.props.notificationsData.length > 0 ?
                                this.renderNotificationList(this.props.notificationsData)
                                :
                                <DataNotFound
                                    text="No Notifications Found"
                                    height="300px"
                                />
                            }
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default NotificationsView;

NotificationsView.propTypes = {
    notificationsData: PropTypes.array.isRequired
}