import { POST } from "../../api/";
import { storeDataInLocalStore } from "../../helpers/GeneralHelpers";
import { handleImageResizing } from "../../helpers/CDNhelpers";

export function AddMovieToMyList(callback, { ID, UserID, cdnUrl }) {
  let body = { MovieID: ID };

  return POST("UserAPI", "/myList", body)
    .then(async res => {
      if (res.body && res.body.length > 0) {
        res.body =await handleImageResizing(res.body, "MyList", cdnUrl);
      }
      await storeDataInLocalStore("myList", res.body, UserID, "No expiry");
      callback(null, res.body);
      return res.body
    })
    .catch(e => {
      let message = e;
      if (e.response && e.response.data && e.response.data.errorMessage) {
        message = e.response.data.errorMessage.replace("[403] ", "");
      }
      callback(message);
    });
}
