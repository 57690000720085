import { POST } from "../../api/";

export function RoomSubscription(callback, params) {
  return POST("OrdersAPI", "/subscribe", params)
    .then(res => {
      callback(res.body);
      return res.body;
    })
    .catch(e => {
      let message = "Failed to Checkout!";
      if (e && e.response && e.response.data && e.response.data.message) {
        message = e.response.data.message.replace(
          `[${e.response.data.statusCode}] `,
          ""
        );
      }
      callback(null, message);
      return;
    });
}
