import config from "./config/config";

import "./assets/css/bootstrap.min.css";
import "./assets/scss/main.css";
import "./assets/css/developer.css";

if (config.customSettings.isStuffpixEnabled) {
  import("./assets/themes/stuffpix/scss/main.css");
}

// Import This file for RoadShow Theme
if (config.applicationName === 'Roadshow') {
  import("./assets/themes/roadshow/scss/main.css");
}