import React, { Component, Fragment } from "react";
import Formsy from 'formsy-react';
import MyInput from "../../../components/MyInput";
import LoaderButton from "../../../components/LoaderButton/LoaderButton";
import { getInputFields } from "./constants";

class Form extends Component {

    render() {
        const { formTitle, forwardedRef ,appTranslations : tr } = this.props;
        let InputFields = getInputFields(tr);
        return (
            <Fragment >
                <h3 className="text-uppercase mb-3">{formTitle}</h3>
                {this.props.subheadingText && this.props.subheadingText.length > 0
                    ? <div className="form-group font-12 font-weight-normal">
                        {this.props.subheadingText}
                    </div>
                    : ""}
                <Formsy
                    onValidSubmit={this.props.handleSubmitForgotPasswordConfirm}
                    onValid={this.props.enableButton}
                    onInvalid={this.props.disableButton}>
                    {this.props.isLoading && <span className="loader-spiral mn-white mn-white-transparent"><span className="spiral-inn"></span></span>}
                    {InputFields && InputFields.map((input, index) => {
                        const { Name, Type, Placeholder, ValidationError, ValidationErrors, Validations } = input;
                        return (
                            <MyInput
                                key={index}
                                id={Name}
                                name={Name}
                                innerRef={Type !== "password" ? forwardedRef : null}
                                type={Type}
                                placeholder={Placeholder}
                                validations={Validations}
                                validationError={ValidationError}
                                validationErrors={ValidationErrors}
                                outerClassName="mb-4"
                                required
                                handleChange={this.props.handleChange}
                            />
                        )
                    })}
                    <LoaderButton
                        disabled={!this.props.canSubmit}
                        type="submit"
                        className="btn btn-primary btn-block text-uppercase"
                        isLoading={this.props.isLoading}
                        text={tr.change_password}
                        loadingText={tr.submitting}
                    />
                </Formsy>
            </Fragment >
        );
    }
}
export { Form };