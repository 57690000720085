import React, { memo } from "react";
import Slider from "react-slick";
import SectionHeader from "./SectionHeader";
import {  initSliderRooms } from "../../../helpers/MoviesHelper";
import RoomsCard from "../../../components/RoomsCard";

const ScreeningSection =({ Items: ScreeningRooms, ...section }) => {
  return (
    <section className="mn-movies py-4">
      <div className="container-fluid">
        <SectionHeader {...section} />
        <Slider
          {...initSliderRooms(null, ScreeningRooms.length)}
          className="row"
        >
          {ScreeningRooms.map(room => {
            if (room.Status === "active") {
              return <RoomsCard {...room} key={room.ID} Type={section.Type}/>;
            }
          })}
        </Slider>
      </div>
    </section>
  );
};

export default memo(ScreeningSection);
