import React from "react";
import { fetchAdyenFields } from "../../../helpers/APIHelpers/AdyenAPI";
import { Link } from "react-router-dom";
import AcceptedCreditCards from "./AcceptedCreditCards";
import withPaymentHelper from "../../.././hoc/PaymentHelpers";
import { TERMS_CONDITIONS } from "../../../constants/routes";
import config from "../../../config/config";

class Adyen extends React.Component {
    isCompMounted = true;
    constructor(props) {
        super(props);
        this.state = {
            saveCard: true,
            isSubmitting: false,
            isValid: false,
            alertMessage: "",
            updatedData: {},
            showErrorAlert: false,
            paymentArray: {},
            requestParams: {},
            adyenCheckout: null,
        }
        this.initAdyenCheckout = this.initAdyenCheckout.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this)
    }

    componentDidMount = () => {
        const script = document.createElement("script");
        script.src =
            "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.0.0/adyen.js";
        script.async = true;
        script.onload = this.initAdyenCheckout; // Wait until the script is loaded before initiating AdyenCheckout
        document.body.appendChild(script);
    }

    initAdyenCheckout = () => {
        const configuration = {
            locale: "en_US",
            environment: config.adyen.enviroment,
            originKey: config.adyen.originKey,

            paymentMethodsResponse: this.fetchAvailablePaymentMethods(),
            onSubmit: this.handleOnChange,
            showPayButton: true,
            paymentMethodsConfiguration: {
                card: { // Example optional configuration for Cards
                    hasHolderName: true,
                    holderNameRequired: true,
                    //   enableStoreDetails: true,
                    //   hideCVC: false, // Change this to true to hide the CVC field for stored cards
                    //   name: 'Credit or debit card'
                }
            }
        }
        const checkout = new window.AdyenCheckout(configuration);
        const customCard = checkout.create('securedfields', {
            type: 'card',
            styles: {
                base: {
                    color: '#cecece',
                },
                error: {
                    color: '#ff5050'
                },
                validated: {
                    color: 'green'
                },
                placeholder: {
                    color: 'rgba(255,255,255,.5)'
                }
            },
            autoFocus: true,
            ariaLabels: {
                lang: 'en-GB',
                encryptedCardNumber: {
                    label: 'Credit or debit card number field'
                }
            },

        }).mount('#customCard-container');
        this.setState({ adyenCheckout: customCard });
    }

    async fetchAvailablePaymentMethods() {
        let paymentMethods = await fetchAdyenFields()
        if (paymentMethods.statusCode === 200) {
            this.getCreditCardMethod(paymentMethods);
        }
    }
    renderSaveToggle = () => {
        return (
            <div className="form-group mb-4">
                <div className="row gutter-7">
                    <div className="col-12">
                        <label className="mn-switch">
                            <input
                                type="checkbox"
                                checked={this.state.saveCard}
                                onChange={this.onToggleSaveCard}
                            />
                            <span className="mn-switch-slider round" />
                        </label>
                        <span className="ml-2 font-12">Save my card for next payment.</span>
                    </div>
                </div>
            </div>
        );
    };

    onToggleSaveCard = () => {
        this.setState(prevState => {
            return {
                saveCard: !prevState.saveCard
            };
        });
    };
    getCreditCardMethod = (paymentMethods) => {
        let methods = paymentMethods.body.paymentMethods;
        methods.forEach(element => {
            if (element.type === "scheme") {
                this.setState({
                    paymentArray: {
                        ...this.state.paymentArray,
                        brands: element.brands,
                        type: element.type,
                        name: element.name,
                        details: element.details
                    }
                });
            }
        });
    }

    handleOnChange(state, component) {

        let data = state.data
        let params = {};
        if (this.props.checkoutParams) {
            params = this.props.checkoutParams
        }
        params["body"] = { paymentMethod: data }
        params = {
            ...params,
            AdyenEncryptedCardNumber: data.encryptedCardNumber,
            AdyenEncryptedSecurityCode: data.encryptedSecurityCode,
            AdyenEncryptedExpiryMonth: data.encryptedExpiryMonth,
            AdyenEncryptedExpiryYear: data.encryptedExpiryYear,
            holderName: data.encryptedCardHolderName,
            AdyenPaymentType: 'scheme',
            SaveCard: this.state.saveCard
        }
        if (this.props.saveCard) {
            this.savePaymentClicked(params);
        } else {
            this.props.onClickPaymentForm('formPayment', params)
        }

    }

    savePaymentClicked = (params) => {
        // let params = {};
        // params["PaymentNonce"] = payload.nonce;
        // if (this.state.makeDefault) {
        //     params["MakeDefault"] = this.state.makeDefault
        // }

        this.props.savePaymentClicked(params);
    }

    renderOptionalText = () => {
        const { appTranslations: tr } = this.props;
        let text = tr.by_placing_your_order_you_agree_to_our_t || "By placing your order, you agree to our $ terms of use.";
        text = text ? text.split('$') : [];
        if (this.props.showFooterText && text && text.length > 0)
            return (
                <div className="font-12">
                    {text[0]}<Link to={TERMS_CONDITIONS}> {text[1] ? text[1] : ''}</Link>
                </div>
            )
        return null
    }
    render() {
        const { acceptable_credit_card_types = `` } = this.props.appTranslations;
        return (
            <div id="customCard-container">
                <div className="row justify-content-center text-left text-white-50 py-4">
                    <div className="col-lg-6">
                        {/* <div className="form-group">
                            <label>Name On Card</label>
                            <div style={{ height: '39px' }} className="form-control form-control-lg form-control-dark" data-cse="encryptedCardHolderName"></div>
                        </div> */}
                        <div className="form-group mb-4">
                            <label>Card number</label>
                            <div style={{ height: '39px' }} className="form-control form-control-lg form-control-dark" data-cse="encryptedCardNumber"></div>
                        </div>
                        <div className="form-group mb-4">
                            <div className="row justify-content-between">
                                <div className="col-5">
                                    <label>Expiry</label>
                                    <div style={{ height: '39px' }} className="form-control form-control-lg form-control-dark" data-cse="encryptedExpiryDate"></div>
                                </div>
                                <div className="col-4">
                                    <label>CVV/CVC</label>
                                    <div style={{ height: '39px' }} className="form-control form-control-lg form-control-dark" data-cse="encryptedSecurityCode"></div>
                                </div>
                            </div>
                        </div>
                        {this.props.showSaveCardOption && this.renderSaveToggle()}
                        <div className="mb-4">
                            {this.renderOptionalText()}
                        </div>
                        <div className="text-center">
                            <button onClick={() => {
                                this.handleOnChange(this.state.adyenCheckout.state, this.state.adyenCheckout)
                            }} className="btn btn-primary btn-large">{this.props.buttonText || "Pay Now"}
                            </button>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="d-md-flex justify-content-between align-items-center text-white-50 text-center">
                    <div className="mb-3">
                        {acceptable_credit_card_types || "Accepted Credit Card Types"}
                    </div>
                    <div className="mb-3">
                        <AcceptedCreditCards
                        />
                    </div>
                </div>

            </div>
        )
    }
}

export default withPaymentHelper(Adyen);
Adyen.defaultProps = {
    showSaveCardOption: false,
    saveCard: false,
    defaultCardOption: false,
    showFooterText: false,
    customContainerClass: "col-lg-6",
}
