import React, { Component } from "react";
import PropTypes from "prop-types";
import Routes from "./Routes";
import { withRouter } from "react-router-dom";
import { setDocumentTitle, getDataFromLocalStorage, storeDataInLocalStore} from "./helpers/GeneralHelpers";
import { SEARCH } from "./constants/routes";
import { decideShowParnterOfferStrip } from "./helpers/PartnerHelpers";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import SubFooter from "./components/Footer/SubFooter";
import PageSubFooter from "./components/Footer/PageSubFooter";
import { PushNotificationsAlert } from "./push-notification";
import config from "./config/config";
import AppLayout from "./components/AppLayout";
import RewardStickyFooter from "./components/RewardStickyFooter";
import { storeSearchHistory } from "./helpers/CacheHelpers";
import { withFirebase } from "./context/FirebaseContext";
import { withPaymentCard } from "./context/PaymentCardContext";
import { withAppConfig } from "./context/ConfigContext";
import { withAuth } from "./context/AuthContext";
import { fetchFullPurchaseList } from "./services/user/GetPurchaseList";
import { fetchFullWatchList } from "./services/user/GetWatchList";
import GetUserPreferences from "./services/auth/GetUserPreferences";
import { getDefaultLanguage } from "./helpers/ConfigHelpers";
import FirebaseListeners from "./components/Firebase/FirebaseListeners";

const SCROLLHEIGHT = 10;

class App extends Component {
  isCompMounted = true;
  USER_ID = null;
  moment = null;
  SERVERTIME = null;
  constructor(props) {
    super(props);
    this.state = {
      pixScrolled: ``,
      showOfferStrip: false,
      HISTORYINDEX: 0,
      searchTerm: ""
   };
    this.decideShowParnterOfferStrip = decideShowParnterOfferStrip.bind(this);
  }

  componentDidMount() {
    this.initializeApp();
    if(this.props.isAuthenticated){
      this.getUserLanguage();
    }
  }

  async initializeApp() {
    this.toggleSignUpBar();
    this.setPageTitle();
    this.decideShowParnterOfferStrip(this.props.UserID); //move it to PartnerContext
    this.props.setInitialNotificationState();
    if (this.props.isAuthenticated) {
      this.fetchInitialData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
    if (prevProps.isAuthenticated !== this.props.isAuthenticated) {
      this.decideShowParnterOfferStrip(this.props.UserID);
      this.props.setInitialNotificationState();
      this.fetchInitialData();
      if(this.props.isAuthenticated){
        this.getUserLanguage();
      }
    }
    if (prevProps.firebaseForceLogout !== this.props.firebaseForceLogout) {
      if (this.props.firebaseForceLogout) {
        this.props.updateAuthStatus(false)
      }
    }
  }

  componentWillUnmount() {
    this.isCompMounted = false;
  }

  getUserLanguage = async () =>{
    let defaultKey = getDefaultLanguage(this.props.configurations);
    let LanguageCode = getDataFromLocalStorage("userLanguage",this.props.UserID);
    if(!LanguageCode){
      LanguageCode = await GetUserPreferences({ UserID : this.props.UserID }).then((res)=>{
        if(res && res.LanguageCode){
          storeDataInLocalStore("userLanguage",res.LanguageCode.toUpperCase(),this.props.UserID)
          return res.LanguageCode;
        }
        return "";
      });
    }
    if(defaultKey && LanguageCode && LanguageCode !=="N-A" && defaultKey !== LanguageCode){
      this.props.updateAppTranslations(LanguageCode.toUpperCase());
    }
  }

  setPageTitle = () =>{
    const { appSettings } = this.props;
    let applicationName = (appSettings && appSettings.ApplicationName) ? appSettings.ApplicationName : config.applicationName;
    setDocumentTitle(applicationName);
  }

  fetchInitialData = () => {
    this.props.getCardsList();
    fetchFullWatchList( { CdnUrl :this.props.HostName });
    // fetchFullPurchaseList(this.props.HostName);
    fetchFullPurchaseList(this.props.UserID); // check it later!!!
  }

  toggleSignUpBar = () => {
    if (config.customSettings.isStuffpixEnabled) {
      window.onscroll = () => {
        if (window.scrollY > SCROLLHEIGHT && this.state.pixScrolled === ``) {
          this.setState({
            pixScrolled: `pix-scrolled`
          });
        } else if (window.scrollY < SCROLLHEIGHT && this.state.pixScrolled === `pix-scrolled`) {
          this.setState({
            pixScrolled: ``
          });
        }
      }
    }
  }

  updateSearchState = (searchTerm) => {
    if (searchTerm !== "") {
      this.setState((prevState) => {
        return {
          HISTORYINDEX: prevState.HISTORYINDEX + 1
        }
      })
      this.props.history.push(`${SEARCH}?q=${searchTerm}`);
      if (this.props.isAuthenticated) {
        storeSearchHistory(searchTerm,this.props.UserID);
      }
      this.setState({
        searchTerm
      });
    } else {
      if (this.props.location.pathname === SEARCH) {
        this.state.HISTORYINDEX > 0 && this.props.history.go(-1 * this.state.HISTORYINDEX); //: this.props.history.goBack()
      }
      this.setState({
        searchTerm: searchTerm,
        HISTORYINDEX: 0
      });
    }
  }

  render() {
    const childProps = {
      UserID : this.props.UserID,
      HostName : this.props.HostName,
      showAlert: this.props.showAlert,
      appSettings: this.props.appSettings,
      configurations : this.props.configurations,
      isAuthenticated: this.props.isAuthenticated,
      updateUserData: this.props.updateUserData,
      updateAuthStatus: this.props.updateAuthStatus,
      searchTerm: this.state.searchTerm,
      isDeviceLimitReached: this.props.isDeviceLimitReached,
      decideShowParnterOfferStrip: this.decideShowParnterOfferStrip // for Partner Landing Page
    };

    return(
      <AppLayout 
        geoBlockStyle = {this.props.geoBlockStyle}
        pixScrolled={this.state.pixScrolled}
        isAuthenticated ={this.props.isAuthenticated}
        history={this.props.history}
        childProps = {childProps}
        render = {( loginLayout , className, style , noContainer )=>{
              if(noContainer){
                return(
                  <Routes childProps={childProps} />
                )
              }
              return(
                <div id="my-page-outerContainer" className={className} > {/* style={style} */}
                    <RewardStickyFooter showOfferStrip={this.state.showOfferStrip}/>
                    <Header 
                        history={this.props.history}
                        updateSearchState= {this.updateSearchState}
                    />
                    <PushNotificationsAlert />
                    <FirebaseListeners />  {/* is this the right place for it ?? */}
                    <Routes childProps={childProps} />
                    <footer className="mn-footer">
                      {loginLayout ? "" : <SubFooter />}
                      <Footer />
                    </footer>
                </div>
              )
        }}
      />
    )
  }
}
App.propTypes = {
  showAlert: PropTypes.func,
  isLoadingConfig: PropTypes.bool,
  geoBlockStyle: PropTypes.bool,
  appSettings: PropTypes.object
}

const WrappedApp = withRouter(withAppConfig(withAuth(withFirebase(withPaymentCard(App)))));
export default WrappedApp;