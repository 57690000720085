import { GET } from "../../api/";
import _isEmpty from "lodash/isEmpty";
import {
  storeDataInLocalStore,
  getDataFromLocalStorage
} from "../../helpers/GeneralHelpers";
import { handleImageResizing } from "../../helpers/CDNhelpers";
// import { handleImageResizing } from "../../helpers/CDNhelpers";

export default async function getCollectionDetail(callback, params) {
  const { userID } = params;
  let collection = getDataFromLocalStorage(`collection-${params.ID}`, userID);
  if (!collection || _isEmpty(collection)) {
    collection = await GET("MoviesAPI", `/collection-room/${params.ID}`)
      .then(res => {
        console.log("movie", res)
        return res.body;
      })
      .catch(e => {
        let message = e;
        if (e.response && e.response.data && e.response.data.errorMessage) {
          message = e.response.data.errorMessage.replace("[403] ", "");
        }
        callback(null, message);
        return;
      });
  }
  if (!collection) {
    return;
  }
  const { HostName } = params;

  collection = await handleImageResizing(collection, "FETCH_MOVIE", HostName); //FETCH_MOVIE -> DETAIL BG
  if (!_isEmpty(collection)) {
    storeDataInLocalStore(`collection-${params.ID}`, collection, userID);
  }
  callback(collection);
}
