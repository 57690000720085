import React, { useState, useEffect, Fragment } from "react";
import MovieCard from "../../../components/MovieCard/MovieCard";
import getCollectionMovies from "../../../services/movies/GetCollectionMovies";
import Loader from "../../../components/Loader/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import _isEmpty from "lodash/isEmpty";

const Listing = ({ HostName, ...props }) => {
  const [isLoading, setLoading] = useState(true);
  const [Data, setData] = useState([]);
  const [isLoadingMore, setMoreLoading] = useState(false);

  useEffect(() => {
    initialize();
    return () => {};
  }, [props.purchases]);

  const initialize = async () => {
    let params = {
      HostName: HostName,
      Items: Data.Items ? Data.Items : [],
      ID: props.match.params.id,
      Limit: 12,
      isAuthenticated: props.isAuthenticated,
      purchases: props.purchases,
      LastEvaluatedKey: Data.LastEvaluatedKey
    };

    getCollectionMovies((response, err) => {
      if (!err) {
        setData(response);
      }
      setLoading(false);
      setMoreLoading(false);
    }, params);
  };

  const fetchMoreData = () => {
    setMoreLoading(true);
    initialize();
  };

  if (isLoading) {
    return (
      <section>
        <Loader />
      </section>
    );
  }
  return (
    <section className="mn-movies py-3">
      <div className="container-fluid">
        {_isEmpty(Data) ? (
          <div className="d-flex w-100 justify-content-between">
            <div className="">
              <h4 className="text-white-70 mb-3">
                No Movies in this Collection
              </h4>
            </div>
          </div>
        ) : (
          <Fragment>
            <div className="d-flex w-100 justify-content-between">
              <div className="">
                <h4 className="text-white-70 mb-3">
                  Movies in this Collection
                </h4>
              </div>
            </div>
            <InfiniteScroll
              dataLength={Data && Data.Items ? Data.Items.length : 0}
              next={fetchMoreData}
              hasMore={Data && Data.LastEvaluatedKey ? true : false}
              loader={
                isLoadingMore && (
                  <div height="150px">
                    <Loader height="150px" />
                  </div>
                )
              }
            >
              <div className="ov-hidden">
                <div className="row gutter-10 mn-movies-grid ">
                  {Data &&
                    Data.Items.map((movie, index) => {
                      return (
                        <MovieCard
                          key={movie.MovieID || index}
                          movie={movie}
                          className="mb-3"
                          showCardHoverIcons={true} //stupidity,, change later
                        />
                      );
                    })}
                </div>
              </div>
            </InfiniteScroll>
          </Fragment>
        )}
      </div>
    </section>
  );
};

export default Listing;
