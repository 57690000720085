import React from "react";
import PropTypes from "prop-types";

const PurchaseTag = ({isPurchased_ ,isCExpired_, isExpired_, purchaseType_, isCPurchased_ }) => {
    let TagName = purchaseType_ || 'rented';
    if(isCExpired_){
        TagName = 'expired'
    }
    if ((isPurchased_ && !isExpired_ && TagName) || isCPurchased_){
        return(
            <span className={`mn-tag ${TagName}`}><i className="la la-play-circle mr-2"></i>{TagName}</span>
        )
    }
    return null;
};

export default PurchaseTag;

PurchaseTag.propTypes = {
    isPurchased_: PropTypes.bool,
    isExpired_: PropTypes.bool,
    purchaseType_ : PropTypes.string
};
{/* {(movie.expiringTime_ === "New") && <span className={`mn-tag new`}>{movie.expiringTime_}</span>} */}
