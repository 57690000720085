import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../../components/Loader/Loader";
import CollectionCard from "../../../../components/RoomCollection/CollectionCard";

const Listing = ({ Data, loading, fetchMoreData }) => {
  return (
    <InfiniteScroll
      dataLength={Data.Items ? Data.Items.length : 0}
      next={fetchMoreData}
      hasMore={Data.LastEvaluatedKey ? true : false}
      loader={
        loading.isLoadingMore && (
          <div height="150px">
            <Loader height="150px" />
          </div>
        )
      }
    >
      <section className="mn-movies pt-4">
        <div className="container-fluid">
          <div className="row gutter-10 mn-movies-grid">
            {Data.Items.map((collection, index) => {
              return  <CollectionCard key={index} collection={collection} className="mb-3"/>;
            })}
          </div>
        </div>
      </section>
    </InfiniteScroll>
  );
};

export default Listing;