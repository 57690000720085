import firebase from 'firebase/app';
import config from "../../config/config";
import _includes from "lodash/includes";
import 'firebase/database';

class Firebase {
    constructor() {
        firebase.initializeApp(config.notifications);
        if (firebase.messaging.isSupported()) {
            this.messaging = firebase.messaging();
        } else {
            console.log("Firebase Messaging is not supported");//imp console
            this.messaging = null;
        }
        // this.messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;
        this.db = firebase.database(); //just a rough check!!
    }

    getDataBaseReference = (key, userId) => {
        //if (firebase.messaging.isSupported()) {
            if (userId && _includes(userId, "@")) {
                return null;
            }
            return firebase.database().ref(key);
        //}
        //return null;
    }

    async getFirebaseToken() {
        let token;
        if (firebase.messaging.isSupported()) {
            token = await this.messaging.getToken().then((res) => {
                return res
            }).catch(error => null);
        }
        return token;
    }

    firebaseMessaging() {
        if (firebase.messaging.isSupported()) {
            return this.messaging;
        }
        return null;//return this.messaging;
    }
}

export default Firebase;