import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import _find from "lodash/find";
import { withAppConfig } from "../../../context/ConfigContext";
import _round from "lodash/round";
import { getApiData, updateApiWithResponse } from "../../../helpers/APIHelpers/MoviesAPI";
import { getCheckoutMovieData } from "../../../helpers/ResponseHelpers";
import { handleChange, disableAlert } from "../../../helpers/StateHelper";
import { renderNotificationMessage, renderCheckoutSuccessView } from "../../../helpers/ViewHelpers";
import {
    isEmpty,
    setDocumentTitle,
    querystring,
    getObjectFromArray,
    getDataFromLocalStorage,
    getCurrentUserId,
    getReusableVouchers,
    removeItemFromStore
} from "../../../helpers/GeneralHelpers";
import { CHECKOUT, WATCH, HOME } from "../../../constants/routes";
import * as constants from "../../../constants/constants";
import Loader from "../../../components/Loader/Loader";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";
import { verifyShowPartnerStrip, handleCheckout } from "../../../helpers/PartnerHelpers";
import PurchaseDescription from "./components/PurchaseDescription";
import FreeCheckoutView from "../components/FreeCheckoutView";
import PaymentMethod from "./components/PaymentMethod";
import { withPaymentCard } from "../../../context/PaymentCardContext";
import { updateCacheOnNewPurchaseItem } from "../../../helpers/MoviesHelper";
import config from "../../../config/config";

class Checkout extends React.Component {
    PURCHASE_TYPE = querystring(constants.PURCHASE);
    TEIRID = querystring(constants.QUERYTIERID);
    MOVIE_ID = this.props.match.params.id;
    isCompMounted = true;
    constructor(props) {
        super(props);
        this.state = {
            // isLoadingToken: true,
            errorToken: "",
            // clientToken: "",
            isLoadingMovieData: true,
            movieData: [],
            dataErrorMovie: "",
            showVoucher: false,
            discount: 0,
            finalPrice: 0,
            selectedQuality: this.TEIRID,
            voucherData: "",
            isLoadingVoucher: false,
            errorVoucher: "",
            voucherCode: "",
            showErrorAlert: false,
            showSuccessAlert: false,
            alertMessage: "",
            reusableVouchers:[],
            paymentGateway: [],
            reusableVoucherBalance:0,
            storedVoucher:false,
            isPriceZero: false,
            isCheckoutSuccessFull: false,
            paymentWasSuccessfull: false,
            isCheckingout: false,
            purchaseHistoryLastEvaluatedKey: "",
            showPromotionDiscountMessage: false,
            isVoucherAutoRedempted: false,
            disableVoucherChange: false,
        }
        this.handleChange = handleChange.bind(this);
        this.fetchMovieData = getApiData.bind(this);
        this.disableAlert = disableAlert.bind(this);
        this.renderNotificationMessage = renderNotificationMessage.bind(this);
        this.verifyRedeemVoucher = updateApiWithResponse.bind(this);
        this.getObjectFromArray = getObjectFromArray.bind(this);
        this.getDataFromLocalStorage = getDataFromLocalStorage.bind(this);
        this.submitChekoutForm = updateApiWithResponse.bind(this);
        this.getCurrentUserId = getCurrentUserId.bind(this);
        this.renderCheckoutSuccessView = renderCheckoutSuccessView.bind(this);
        this.verifyShowPartnerStrip = verifyShowPartnerStrip.bind(this);
        this.handleCheckout = handleCheckout.bind(this);
        this.voucherInput = "";
    }

    async componentDidMount() {
        let vouchers = await getReusableVouchers();
        this.setState({
            reusableVouchers: vouchers
        });
        this.initialize();
        setDocumentTitle(constants.CHEKCOUT_PAGE);
    }

    componentDidUpdate() {
        setDocumentTitle(this.state.movieData.Title && `Checkout - ${this.state.movieData.Title}`);
    }

    componentWillUnmount() {
        this.isCompMounted = false;
    }

    initialize = async () => {
        this.verifyRequiredParams();
        let data = {};
        data[constants.QUERYTIERID] = this.TEIRID;
        data[constants.PURCHASE] = this.PURCHASE_TYPE;
        this.verifyShowPartnerStrip(); //COMMENT IT LATER!! message not required now!
        let cacheMovie = getDataFromLocalStorage(`${constants.STORED_MOVIES_DETAIL}${this.props.match.params.id}`);
        let movieData;
        if (cacheMovie) {
            movieData = getCheckoutMovieData(cacheMovie, data);
            if (this.isCompMounted) {
                this.setState({
                    isLoadingMovieData: false,
                    dataErrorMovie: "",
                    movieData: movieData
                });
            }
        } else {
            movieData = await this.fetchMovieData("MoviesAPI", `/get/${this.props.match.params.id}`, data, "isLoadingMovieData", "dataErrorMovie", "movieData", constants.CHECKOUTMOVIEDATA);//CHECKOUTMOVIEDATAONCHANGEQUALITY
        }
        this.checkIsTeirIdValid(movieData, this.state.selectedQuality);
        this.handleSelectedVoucherRedemption();
        this.verifyIsPriceZero(movieData, true);
        this.getPaymentGateway();
    };
    
    getPaymentGateway = () =>{
        let GatewayList = _find(this.props.configurations, ["KEY", "PAYMENT_GATEWAY"]);
        GatewayList = (GatewayList && GatewayList.VALUE) ?  GatewayList.VALUE : []

        let activeGateway  = _find(GatewayList,['Status','active']);
        activeGateway = activeGateway ? activeGateway.Type.toLowerCase() : ''

        this.setState({
            paymentGateway: GatewayList,
            activeGateway : activeGateway
        });
    }

    verifyRequiredParams() {
        const { appTranslations : tr } = this.props;
        if (!this.TEIRID || !this.PURCHASE_TYPE || !this.MOVIE_ID) {
            this.props.showAlert("showWarningAlert", tr.invalid_url_please_enter_all_required_pa)
            this.props.history.push(HOME);
            return;
        }
    }

    checkIsTeirIdValid = (movieData, urlTeir) => {
        if (movieData && movieData["SelectedTeirID"]) {
            if (urlTeir !== movieData["SelectedTeirID"]) {
                this.setState({
                    selectedQuality: movieData["SelectedTeirID"]
                })
            }
        }
    };

    calulateTotalPrice = (data) => {
        let voucher = this.state.voucherData;
        let price = data.Price;
        let discount; let result = {};
        if (!isEmpty(voucher)) {
            if (voucher.DiscountType === "Fixed") {
                if (voucher.DiscountValue > price) {
                    discount = price;
                } else {
                    discount = voucher.DiscountValue;
                }
            } else if (voucher.DiscountType === "Percentage") {
                discount = price * (voucher.DiscountValue / 100);
            } else if (voucher.DiscountType === "Variable") {
                discount = price - voucher.DiscountValue;
            }
            if (voucher.DiscountType === "Fixed" || voucher.DiscountType === "Percentage") {// because total price is calculated in diff way!!
                result["price"] = parseFloat((price - discount).toFixed(2));
            } else {
                result["price"] = voucher.DiscountValue;
            }
            result["discount"] = discount.toFixed(2);
        } else {
            result["price"] = price
        }
        this.PRICE = result["price"];
        return result;
    };

    handleQualityChange = (name, value) => {
        if (this.state.isVoucherAutoRedempted) {
            return;
        }
        this.setState({
            [name]: value,
            isFreeWatch: false,
            isLoadingMovieData: true,
            alertMessage: "",
            showErrorAlert: false, //to disbable VoucherAlert -- if any!!
            showSuccessAlert: false
        });
        this.props.history.push({
            pathname: `${CHECKOUT}${this.MOVIE_ID}`,
            search: `?${constants.PURCHASE}=${this.PURCHASE_TYPE}&${constants.QUERYTIERID}=${value}`
        });
        this.TEIRID = value;
        let data = {};
        data[constants.QUERYTIERID] = this.TEIRID;
        data[constants.PURCHASE] = this.PURCHASE_TYPE;
        let cacheMovie = getDataFromLocalStorage(`${constants.STORED_MOVIES_DETAIL}${this.props.match.params.id}`);
        if (cacheMovie) {
            let updatedMovieData = getCheckoutMovieData(cacheMovie, data);
            this.verifyIsPriceZero(updatedMovieData, true);
            this.setState({
                isLoadingMovieData: false,
                dataErrorMovie: "",
                movieData: updatedMovieData
            });
        } else {
            this.fetchMovieData("MoviesAPI", `/get/${this.props.match.params.id}`, data, "isLoadingMovieData", "dataErrorMovie", "movieData", constants.CHECKOUTMOVIEDATAONCHANGEQUALITY).then((res) => {
                if (this.state.dataErrorMovie === "" && !isEmpty(this.state.movieData)) {
                    this.verifyIsPriceZero(res, true);
                }
            })
        }
    };
     handleVoucherClick =async () => {
        let header = {
            Voucher: this.state.voucherCode,
            MovieID: this.props.match.params.id,
            TotalPrice: this.state.movieData.Price,
            Quality: this.state.movieData.ManifestDetails
        };
        if (this.state.voucherCode !== "") { //voucherInputValue if state is empty
            this.setState({
                isLoadingVoucher: true
            });
            const { ApplicationName = config.applicationName } = this.props.appSettings;
            const { appTranslations : tr } = this.props;
            let VoucherSucessMessage= tr.your_name_reward_code_was_applied || "Your <name> Reward Code was applied successfully";
            VoucherSucessMessage = VoucherSucessMessage.replace('<name>',ApplicationName);
            let voucherFailedMessage = tr.this_reward_code_is_not_valid_please_try;
          await  this.verifyRedeemVoucher("OrdersAPI", "/canRedeem", header, this.state.voucherCode, "showSuccessAlert", "showErrorAlert", "alertMessage", VoucherSucessMessage, voucherFailedMessage, constants.VERIFY_VOUCHER, "isLoadingVoucher", "voucherData", "showVoucher").then((res) => {
              if(res.SavedVoucher){
                  this.handleSavedVoucher(res);
              }
              if (this.state.showVoucher) { //suceess
                    if (!isEmpty(res)) {
                        this.setState({
                            appliedVoucherCode: this.state.voucherCode
                        });
                        this.disableChangeVoucher();
                        if (this.voucherInput) {
                            this.voucherInput.setInput(this.state.voucherCode);
                        }
                        this.verifyIsPriceZero();
                    }
                } else {
                    // if (this.state.isVoucherAutoRedempted) {
                    //     this.setState({
                    //         autoRedemptionUnSuccessful: true
                    //     })
                    // }
                }
            });
        }
    };

    autoVoucherRedemption = (voucherCode) => {
        this.setState({
            voucherCode: voucherCode,
            isVoucherAutoRedempted: true
        }, () => {
            this.handleVoucherClick()
        });
    };

    handleSavedVoucher = async (voucherData) => {
         this.setState({
            storedVoucher:true,
            reusableVoucherBalance: (voucherData.DiscountValue < this.state.movieData.Price) ? 0 :_round(voucherData.DiscountValue - this.state.movieData.Price,2)
        });
        removeItemFromStore(constants.STORED_VOUCHERS,this.props.UserID);
    };

    handleSelectedVoucherRedemption = () => { //voucherAlready Applied from PurchasePopup
        const { state } = this.props.location;
        if (state && state.Voucher && state.VoucherData) {
            if(state.VoucherData.SavedVoucher){
                this.handleSavedVoucher(state.VoucherData);
            }
            this.setState({
                disableVoucherChange: true,
                appliedVoucherCode: state.Voucher,
                voucherCode: state.Voucher,
                voucherData: state.VoucherData,
                showVoucher: true,

            }, () => {
                this.verifyIsPriceZero();
                if (!this.voucherInput) {
                    // let inputRefInterval = setInterval(() => { // wait
                    //     if (this.voucherInput) {
                    //         clearInterval(inputRefInterval);
                    //         this.voucherInput.setInput(state.Voucher);
                    //     }
                    // }, 100);
                } else {
                    this.voucherInput.setInput(state.Voucher);
                }
            });

        }
    };

    disableChangeVoucher = () => {
        this.setState({
            disableVoucherChange: true
        });
    };

    verifyIsPriceZero = (data) => {
        let movieData = (data) ? data : this.state.movieData;
        let payment = this.calulateTotalPrice(movieData);
        let zeroType = "isPriceZero";
        if (movieData.Price === 0) {
            zeroType = "isFreeWatch"
        }
        if (payment.price <= 0) {
            this.setState({
                [zeroType]: true,
            })
        } else {
            this.setState({
                [zeroType]: false,
            })
        }
    };

    handleRemoveVoucherClick = () => {
        if (this.voucherInput) {
            this.voucherInput.clearInput();
        }
        this.setState({
            disableVoucherChange: false,
            showVoucher: false,
            voucherCode: "",
            appliedVoucherCode: "",
            voucherData: "",
            reusableVoucherBalance:0
        }, () => {
            this.verifyIsPriceZero(this.state.movieData)
        });
    };

    showPaymentSuccess = () => {
        this.setState({
            showVoucher: false,
            appliedVoucherCode: this.state.voucherCode,
            isCheckoutSuccessFull: true
        })
    };

    getPaymentParams = (type) => {
        let result = {
            PurchaseType: type,
            TierID: this.state.selectedQuality,
            PaymentGateway: this.state.isPriceZero || this.state.isFreeWatch ? "voucher" : this.state.activeGateway,
            MovieID: this.props.match.params.id,
            Quality: this.state.movieData.ManifestDetails
        }
        if (this.state.voucherCode !== "" && this.state.voucherData !== "") {
            result["Voucher"] = this.state.voucherCode
        }
        return result;
    };

    handleWatchMovieClick = () => {
        this.setState((prevState) => {
            return {
                paymentWasSuccessfull: !prevState.paymentWasSuccessfull
            }
        }, () => {
            this.props.history.push({
                pathname: `${WATCH}${constants.MOVIE_VIDEO}/${this.MOVIE_ID}`,
                state: {
                    alert: { messageType: "success", message: " Movie Purchased Successfully! Enjoy Watching!" }
                }
            });
        }
        );
    };

    handleMovieClick = () => {
        this.setState((prevState) => {
            return {
                paymentWasSuccessfull: !prevState.paymentWasSuccessfull
            }
        }, () => {
            this.props.history.push(`/movie/${this.MOVIE_ID}`)
        }
        );
    }

    transactionWasSuccessfull = () => {
        if (this.isCompMounted) {
            this.setState({
                paymentWasSuccessfull: true,
            })
        }
    };

    onPaymentSuccess = () => { //not used
        if (this.props.isAuthenticated) {
            this.props.history.push({
                pathname: `${WATCH}${constants.MOVIE_VIDEO}/${this.MOVIE_ID}`,
                state: {
                    alert: { messageType: "success", message: " Movie Purchased Successfully! Enjoy Watching!" }
                }
            });
        } else {
            this.props.history.push(`/login?redirect=${this.props.location.pathname}${this.props.location.search}`);
        }
    };

    handleContinueClick = () => {
        let params = this.getPaymentParams(this.PURCHASE_TYPE);
        params["PaymentNonce"] = "XXXXXXX";
        params["Price"] = 0;
        params["Voucher"] = this.state.appliedVoucherCode;
        params["Quality"] = this.state.movieData.ManifestDetails;
        this.setState({
            isCheckingout: true,
        });
        let freeVoucher = false;
        if (this.state.isPriceZero) {
            freeVoucher = true
        }
         this.handleCheckout(params, false, freeVoucher);
    };

    handleTitleClick = () => {
        this.props.history.push(`/movie/${this.props.match.params.id}`);
    };

    handlePaymentCheckout = (checkoutType, header) => {
        this.setState({
            isCheckingout: true
        });
        const { appTranslations : tr } = this.props;
        let paymentSuccess = tr.congrats_your_payment_has_been_made_succ
        let paymentFailed = tr.sorry_your_payment_couldnt_be_made_try_a;
        this.submitChekoutForm("OrdersAPI", "/checkout", header, null, "showSuccessAlert", "showErrorAlert", "alertMessage", paymentSuccess,paymentFailed, constants.SUBMIT_PAYMENT_FORM, "", "transaction").then((res) => {
            this.setState({
                isCheckingout: false,
                transactionSubmitted: true
            });
            if (!isEmpty(res)) { //NOT eroor
                getCurrentUserId().then(async (userId) => {
                    if (checkoutType === "formPayment") {
                        this.props.getCardsList(true);
                    }
                    await updateCacheOnNewPurchaseItem(userId, res);
                    this.transactionWasSuccessfull();
                });
            }
        });
    };

    renderPaymentView = () => {
        const { appTranslations : tr } = this.props;
        const { ApplicationName = config.applicationName } = this.props.appSettings;
        let headerTitle = this.state.isFreeWatch ? tr.watch_movie_free || "Watch Movie Free" : `${ApplicationName} ${tr.reward_applied || "Reward Applied"}`;
        let voucherAppliedDescription = `The value of your ${ApplicationName} Reward Code was successfully applied.`
        let headerSubTitle = this.state.isFreeWatch ? `Now You can Watch Free Movie ${this.state.movieData.ManifestDetails ? `in ${this.state.movieData.ManifestDetails} Quality. Enjoy!` : ``}` : voucherAppliedDescription;
        return (
            <Fragment>
                {(this.state.isPriceZero || this.state.isFreeWatch) ?
                    <FreeCheckoutView
                        showSuccessTag={!this.state.isPriceZero}
                        headerTitle={headerTitle}
                        headerSubTitle={headerSubTitle}
                        isLoading={this.state.isCheckingout}
                        onClickCheckout={this.handleContinueClick}
                    />
                    :
                    <PaymentMethod
                        {...this.state}
                        price={this.PRICE}
                        isCheckingout={this.state.isCheckingout}
                        purchaseType={this.PURCHASE_TYPE}
                        movieID={this.props.match.params.id}
                        handleCheckout={this.handlePaymentCheckout} //??
                        transactionWasSuccessfull={this.transactionWasSuccessfull}
                        transactionSubmitted={this.state.transactionSubmitted}
                        getPaymentParams={this.getPaymentParams}
                    />
                    }

                {this.state.movieData && this.renderCheckoutSuccessView(this.state.movieData.Title, this.state.movieData.RentMaxValidity, this.state.movieData.RentTvodValidity, this.props.match.params.id,this.state.movieData.Price,this.state.voucherCode,this.state.reusableVoucherBalance,this.state.storedVoucher,this.props.appTranslations)}
            </Fragment>
        )
    };

    render() {
        const { ApplicationName = config.applicationName } = this.props.appSettings;
        const { appTranslations : tr } = this.props;

        return (
            <main className="mn-main">
                {this.state.isLoadingMovieData ?
                    <Loader />
                    :
                    (this.state.dataErrorMovie !== "" || this.state.errorToken !== "") ?
                        <DataNotFound
                            text="Something Went Wrong"
                        />
                        :
                        <Fragment>
                            <section className="mn-page-head expand-vertical py-4" >
                                <div className="container">
                                    <div className="text-center">
                                        <h3 className="mb-0">{tr.complete_rental || "Complete Rental"}</h3>
                                    </div>
                                </div>
                            </section>
                            {this.renderNotificationMessage()}
                            <PurchaseDescription
                                {...this.state}
                                disableVoucherChange={this.state.disableVoucherChange}
                                purchaseType={this.PURCHASE_TYPE}
                                ApplicationName={ApplicationName}
                                isAuthenticated={this.props.isAuthenticated}
                                forwardedRef={(c) => { this.voucherInput = c; }}
                                movieID={this.props.match.params.id}
                                calulateTotalPrice={this.calulateTotalPrice}
                                handleTitleClick={this.handleTitleClick}
                                autoVoucherRedemption={this.autoVoucherRedemption}
                                handleChange={this.handleChange}
                                handleQualityChange={this.handleQualityChange}
                                handleVoucherClick={this.handleVoucherClick}
                                handleRemoveVoucherClick={this.handleRemoveVoucherClick}
                                reusableVouchers={this.state.reusableVouchers}
                                reusableVoucherBalance={this.state.reusableVoucherBalance}
                                storedVoucherFlag={this.state.storedVoucher}
                                location={this.props.location}
                            />
                            {this.renderPaymentView()}
                        </Fragment>
                }
            </main>
        )
    }
}
export default withAppConfig(withPaymentCard(Checkout));
Checkout.propTypes = {
    showAlert: PropTypes.func,
    appSettings: PropTypes.object
};