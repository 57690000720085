import React from 'react'
import AcceptedCreditCards from '../../components/AcceptedCreditCards';
import _isEmpty from "lodash/isEmpty";

const PaymentHeader = (props) => {
    return (
        !_isEmpty(props.paymentGateway) &&
        props.paymentGateway.map((gateway, index) => {
          if (gateway.Status.toLowerCase() === "active") {
            return (
              <div key={index} className="text-center mb-3">
                <h3 className="mb-4">Payment Method</h3>
                {/* <div className="text-white-50 mb-3">
                  {props.appTranslations.acceptable_credit_card_types}
                </div>
                <AcceptedCreditCards /> */}
              </div>
            );
          }
        })
      );
}
export default PaymentHeader;
