import React, { Component } from "react";
import Auth from "@aws-amplify/auth";
import * as constants from "../constants/constants";
import {
  getDataFromLocalStorage,
  clearBrowserCache,
  storeDataInLocalStore
} from "../helpers/GeneralHelpers";
import { verifyUserDevicesLimit, closeSession } from "../helpers/ConfigHelpers";
import Splash from "../components/MuvinowSplash/Splash";
import StuffpixSplash from "../components/StuffpixSplash/Splash";
import config from "../config/config";
import { getPurchaseList } from "../services/user/GetPurchaseList";
import { ConfigContext } from "./ConfigContext";
import _isEqual from "lodash/isEqual";
import { GetMySubscriptions } from "../services/user/GetMySubscriptions";
import GetMyCollections from "../services/user/GetMyCollections";

const AuthContext = React.createContext(null);

class AuthProvider extends Component {
  static contextType = ConfigContext;
  constructor(props) {
    super(props);
    this.state = {
      isSetupDone: false,
      isAuthenticating: true,
      user: {},
      subscriptions: [],
      mycollections: [],
      getSubscriptions: this.updateSubscriptions,
      updateSubscription: this.updateSubscriptions,
      getCollecions: this.updateCollections,
      updateUserData: this.getCurrentUser,
      updateAuthStatus: this.updateAuthStatus,
      fetchPurchases: this.fetchPurchases,
      handleLogout: this.handleLogout
    };
  }

  componentDidMount() {
    this.initialize();
  }

  initialize() {
    this.getCurrentUser();
  }

  getCurrentUser = (updateAll = true, bypassCache=false) => {
    //updateAll -> false when dont need to fetch purchases, subscription etc
    Auth.currentAuthenticatedUser({bypassCache})
      .then(user => {
        console.log("user", user)
        this.setUserData(user);
        this.updateAuthStatus(true);
        let userId = user.attributes ? user.attributes.sub : user.username;
        console.log("user id", userId)
        if (updateAll) {
          this.fetchSubscriptions(userId);
          this.fetchMyCollections(userId);
          this.fetchPurchases(userId, false);
          //this.checkDeviceLimit(); //remove it when "Device Limit Reached" not required
        }
      })
      .catch(err => {
        this.updateAuthStatus(false);
        this.setState({
          isSetupDone: true
        });
      });
  };

  updateAuthStatus = (authenticated, limit) => {
    if (limit !== null && limit !== undefined) {
      //handles false and true condition
      //this.setDeviceLimit(limit); // "Device Limit Reached" not required
    }
    this.setState({
      isAuthenticating: false,
      isAuthenticated: authenticated
    });
  };

  fetchPurchases = (UserId, bypassCache = true) => {
    if (!UserId) {
      UserId = this.state.UserID;
    }
    let purchaseList = [];
    if (!bypassCache) {
      purchaseList = getDataFromLocalStorage("myPurchases", UserId);
      if (purchaseList) {
        this.setState({
          purchases: purchaseList,
          isSetupDone: true
        });
      }
    }
    let params = {
      CdnUrl: this.context.HostName,
      UserId: UserId,
      bypassCache: true
    };
    getPurchaseList((err, response) => {
      if (response && !_isEqual(response, purchaseList)) {
        this.setState({ purchases: response, isSetupDone: true });
      }
    }, params);
  };

  fetchSubscriptions = (UserId, bypassCache = false) => {
    if (!UserId) {
      UserId = this.state.UserID;
    }
    let params = {
      //CdnUrl: this.context.HostName,
      UserId: UserId,
      bypassCache: bypassCache
    };
    GetMySubscriptions((response, err) => {
      if (!err) {
        this.setState({
          subscriptions: response
        });
      }
      this.setState({
        updatingSubscriptions: false
      });
    }, params);
  };

  fetchMyCollections = (UserId, bypassCache = false) => {
    if (!UserId) {
      UserId = this.state.UserID;
    }
    let params = {
      //CdnUrl: this.context.HostName,
      UserId: UserId,
      bypassCache: bypassCache
    };
    GetMyCollections((res, err) => {
      if (!err) {
        this.setState({
          mycollections: res
        });
      }
      this.setState({
        updatingCollections: false
      });
    }, params);
  };

  updateSubscriptions = subscriptions => {
    if (subscriptions) {
      this.setState({
        subscriptions: subscriptions
      });
    } else {
      this.setState({
        updatingSubscriptions: true
      });
      this.fetchSubscriptions(this.state.UserID, true); //dont return from cache so bypass it
    }
  };

  updateCollections = () => {
    this.setState({
      updatingCollections: true
    });
    this.fetchMyCollections(this.state.UserID, true); //dont return from cache so bypass it
  };

  setUserData = (cUser = {}) => {
    //cUser -> cognitouser
    if (cUser.attributes.identities) {
      let identities = JSON.parse(cUser.attributes.identities);
      cUser.attributes.Provider = identities[0].providerName;
    }
    let userId = cUser.attributes ? cUser.attributes.sub : cUser.username;
    this.setState({
      user: cUser.attributes,
      UserID: userId
    });
    return;
  };

  handleLogout = async event => {
    this.setState({
      isSigningOut: true,
      purchases: [],
      mycollections: [],
      subscriptions: []
    });
    let sessionId = getDataFromLocalStorage(
      constants.STORED_USER_SESSION_ID,
      this.state.UserID
    );
    if (sessionId) {
      await closeSession(sessionId);
    }
    await Auth.signOut();
    this.updateAuthStatus(false);
    let iscurrentUserReward = getDataFromLocalStorage(
      constants.STORED_MY_PARTNER_NAME
    );
    clearBrowserCache();
    if (iscurrentUserReward === constants.REWARD) {
      storeDataInLocalStore(constants.STORED_MY_PARTNER_NAME, constants.REWARD);
    }
    this.setState(prevState => {
      return {
        isSigningOut: !prevState.isSigningOut
      };
    });
  };

  setDeviceLimit = limit => {
    this.setState({
      isDeviceLimitReached: limit
    });
    storeDataInLocalStore("deviceLimitReached", limit, null, "No expiry");
  };

  checkDeviceLimit = () => {
    let deviceLimit = getDataFromLocalStorage(constants.DEVICE_LIMIT);
    if (deviceLimit) {
      this.setState({ isDeviceLimitReached: deviceLimit });
    } else {
      setTimeout(async () => {
        let res = await verifyUserDevicesLimit(this.state.UserID);
        if (res) {
          //get App Settings ?? later
          const { appSettings } = this.props;
          let deviceLimit =
            appSettings && appSettings.ConcurrentDevices
              ? appSettings.ConcurrentDevices
              : constants.DEVICES_LIMIT;
          if (res.length > deviceLimit) {
            this.updateAuthStatus(true, true);
          }
        }
      }, 5000); //listsessions api call can be delayed on page load
    }
  };

  render() {
    return (
      <AuthContext.Provider value={this.state}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const withAuth = Component => props => {
  const AuthConsumer = (
    <AuthContext.Consumer>
      {auth => {
        return auth.isAuthenticating ||
          auth.isSigningOut ||
          !auth.isSetupDone ? (
          config.customSettings.isStuffpixEnabled ? (
            <StuffpixSplash />
          ) : (
            <Splash />
          )
        ) : (
          <Component {...props} {...auth} />
        );
      }}
    </AuthContext.Consumer>
  );
  return AuthConsumer;
};
export { AuthContext, AuthProvider, withAuth };
