import React, { Component, Fragment } from "react";
import _find from "lodash/find";
import _map from "lodash/map";
import { setDocumentTitle, cachePartner, importMoment, getCurrentUserId, ping, removeItemFromStore, storeDataInLocalStore } from "../../../helpers/GeneralHelpers";
import { REWARD, OFFERMOVIES_TYPE, PROMOTIONALMOVIES_TYPE, PARTNER_SECTIONS, MOVIE_VIDEO, TVOD, NOTVALIDPARTNERMESSAGE, STORED_MY_PARTNER_NAME, STORED_REWARD_MOVIES } from "../../../constants/constants";
import RenderScreen from "../../../components/RenderScreen/RenderScreen";
import { getApiData } from "../../../helpers/APIHelpers/MoviesAPI";
import { handleWatchMovieClick, handleMovieClick, getValidPartnerData } from "../../../helpers/PartnerHelpers";
import { getPurchaseData, checkIsMovieExpired, decideLikePurchasedStatus } from "../../../helpers/MoviesHelper";
import { WATCH } from "../../../constants/routes";
import { handleImageResizing } from "../../../helpers/CDNhelpers";
import { getCognitoUserAttributes } from "../../../helpers/ConfigHelpers";
import { withAppConfig } from "../../../context/ConfigContext";
import TransactionModal from "../../../components/TransactionModal/TransactionModal";
import PurchaseModal from "./components/PurchaseModal";
import Header from "./components/Header";
import OfferMovies from "./components/OfferMovies";
import TermsConditions from "./components/TermsConditions";
import PromotionalMovies from "./components/PromotionalMovies";

class RewardPlus extends Component {
    isCompMounted = true;
    USER_ID = ""
    purchaseList = []; // only used for checking purchased Status 
    MAPING_STATUS_TYPE = "" //used for tracking between ID & MovieID
    moment = null;
    SERVERTIME = null;
    currentUserData = {}
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            moviesList: [],
            offerMovies: [],
            promotionMovies: [],
            isError: "",
            showPurchaseModel: false,
            paymentWasSuccessfull: false,
            isMovieLockDecided: false,
            canWatchMovie: false,
            isOfferAvailed: false,
            selectedMovie: {},
            selectedMovieId: "",
            selectedMovieTeirType: "",
            showRentedMovieTag: false
        }
        this.getData = getApiData.bind(this);
        this.importMoment = importMoment.bind(this);
        this.getPurchaseData = getPurchaseData.bind(this);
        this.handleWatchMovieClick = handleWatchMovieClick.bind(this)
        this.handleTransactionMovieClick = handleMovieClick.bind(this);
        this.decideLikePurchasedStatus = decideLikePurchasedStatus.bind(this);
    }

    componentDidMount() {
        this.initialize();
    }

    componentWillUnmount() {
        this.isCompMounted = false;
    }

    initialize = async () => {
        this.fetchMoviesData();
        setDocumentTitle(`${REWARD}`);
        if (this.props.isAuthenticated) {
            if (!this.USER_ID) {
                this.USER_ID = await getCurrentUserId();
            }
            //let patnerName = this.props.location.pathname.slice(1);
            let isPartnerUser = true; let partnerName;
            this.currentUserData = await getCognitoUserAttributes(false, false, this.USER_ID);
            if (this.currentUserData) {
                if (!this.currentUserData['custom:AccountType']) {
                    isPartnerUser = false
                } else {
                    partnerName = this.currentUserData['custom:PartnerName']
                }
            }
            let partnerObj = isPartnerUser ? await getValidPartnerData(partnerName) : null;
            if (!isPartnerUser || (partnerName !== REWARD) || (isPartnerUser && !partnerObj)) {
                removeItemFromStore(STORED_MY_PARTNER_NAME)
                this.setState({
                    showErrorAlert: true,
                    alertMessage: NOTVALIDPARTNERMESSAGE
                }, () => {
                    setTimeout(() => {
                        this.setState((prevState) => {
                            return {
                                showErrorAlert: !prevState.showErrorAlert
                            }
                        }, () => {
                            this.props.history.push("/");
                        })
                    }, 5000);
                });
            } else {
                cachePartner(REWARD);
            }
        } else {
            await cachePartner(REWARD); //only in case of unauth
        }
        this.props.decideShowParnterOfferStrip();
    }

    fetchMoviesData = () => {
        this.getData("MoviesAPI", "/rewardPlusSections", this.state.moviesList, "isLoading", "isError", "moviesList", PARTNER_SECTIONS).then(async (sections) => {
            let offerMovies = _find(sections, ['Type', OFFERMOVIES_TYPE]);
            let promotionalMovies = _find(sections, ['Type', PROMOTIONALMOVIES_TYPE]);
            if (offerMovies) {
                offerMovies = offerMovies.Items;
                offerMovies = await handleImageResizing(offerMovies, PARTNER_SECTIONS)
            }
            if (promotionalMovies) {
                promotionalMovies = promotionalMovies.Items;
                promotionalMovies = await handleImageResizing(promotionalMovies, PARTNER_SECTIONS);
            }
            this.setState({
                offerMovies: offerMovies,
                promotionMovies: promotionalMovies
            });
            storeDataInLocalStore(STORED_REWARD_MOVIES, sections);
            this.decideOfferAvailed(offerMovies, promotionalMovies);
        });
    }

    decideOfferAvailed = async (offerMovies, promotionalMovies) => { //also map rented tags!!
        if (!this.moment) {
            await this.importMoment()
        }
        if (!this.SERVERTIME) {
            this.SERVERTIME = await ping()
        }
        if (this.props.isAuthenticated) {
            if (!this.USER_ID) {
                this.USER_ID = await getCurrentUserId();
            }
            this.MAPING_STATUS_TYPE = PARTNER_SECTIONS;
            this.purchaseList = await this.getPurchaseData(this.USER_ID);
        }
        let updatedOfferMovies = _map(offerMovies, this.decideLikePurchasedStatusOfferMovies);
        let updatedPromotionalMovies = _map(promotionalMovies, this.decideLikePurchasedStatus);
        let anyOnePurchased = _find(updatedOfferMovies, ["isPurchased_", true]);
        this.setState({
            offerMovies: updatedOfferMovies,
            isMovieLockDecided: true,
            isOfferAvailed: (anyOnePurchased) ? true : false,
            promotionalMovies: updatedPromotionalMovies,
            showRentedMovieTag: true
        })
    }

    decideLikePurchasedStatusOfferMovies = (movie) => { //NOTE = use same names everyWhere
        movie["isPurchased_"] = false;
        movie["isExpired_"] = true;
        movie["isComingSoon_"] = false;
        if (!movie.isAvailableForRent && !movie.isAvailableForBuy && movie.HVSD && this.moment) {
            let isComingSoon = this.moment(movie.HVSD).isAfter(this.moment(this.SERVERTIME));
            if (isComingSoon) {
                movie["isComingSoon_"] = true;
            }
        }
        if (this.props.isAuthenticated) {
            let purchaseMovie = _find(this.purchaseList, function (obj) { return ((obj.MovieID === movie.ID) && obj.Voucher); });
            if (purchaseMovie) {
                movie["isPurchased_"] = true;
                let currentMovie = purchaseMovie;
                let expiringStatus = checkIsMovieExpired(this.moment, currentMovie, this.SERVERTIME, true);
                if (expiringStatus <= 0) {
                    movie["isExpired_"] = true;
                } else {
                    movie["isExpired_"] = false;
                }
            }
        }
        return movie;
    }

    handleMovieClick = () => {//
    }

    togglePurchaseModal = () => {
        this.setState((prevState) => {
            return {
                showPurchaseModel: !prevState.showPurchaseModel
            }
        })
    }

    handleRentMovieClick = (e, movie) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.isAuthenticated) {
            if (movie) {
                if (movie.isPurchased_ && !movie.isExpired_) {
                    this.props.history.push(`${WATCH}${MOVIE_VIDEO}/${movie.ID}`);
                } else {
                    this.setState({
                        selectedMovie: movie,
                        selectedMovieId: movie.ID,
                        selectedMovieTeirType: TVOD,
                    }, () => {
                        this.togglePurchaseModal()
                    })
                }
            }
        } else {
            let patnerName = this.props.location.pathname.slice(1);
            this.props.history.push(`/login?redirect=${this.props.location.pathname}`, { isPartner: true, partnerName: patnerName });
        }
    }

    onCheckoutSuccess = () => {
        this.setState({
            showPurchaseModel: false,
            paymentWasSuccessfull: true,
        });
    }

    renderPurchaseModal = () => {
        return (
            <PurchaseModal
                showPurchaseModel={this.state.showPurchaseModel}
                isAuthenticated={this.props.isAuthenticated}
                decideShowParnterOfferStrip={this.props.decideShowParnterOfferStrip}
                selectedMovie={this.state.selectedMovie}
                selectedMovieId={this.state.selectedMovieId}
                togglePurchaseModal={this.togglePurchaseModal}
                selectedMovieTeirType={this.state.selectedMovieTeirType}
                onCheckoutSuccess={this.onCheckoutSuccess}
            />
        )
    }

    renderCheckoutTransactionModal = () => {
        const { appTranslations : tr } =this.props;

        if (this.state.selectedMovie && this.state.paymentWasSuccessfull) {
            const { Title, RentMaxValidity, RentTvodValidity = ``, ID } = this.state.selectedMovie;
            return (
                <TransactionModal
                    Title={Title}
                    MovieID={ID}
                    handleWatchMovieClick={this.handleWatchMovieClick}
                    handleMovieClick={this.handleTransactionMovieClick}
                    RentTvodValidity={`${RentTvodValidity} ${RentTvodValidity > 1 ? tr.hours : tr.hour}`}
                    RentMaxValidity={`${RentMaxValidity} ${tr.days} `}
                />
            )
        }
        return null;
    }

    renderView() {
        const { offerMovies, promotionMovies, isMovieLockDecided, isOfferAvailed, showRentedMovieTag } = this.state;
        const { appSettings, isAuthenticated } = this.props;
        return (
            <Fragment>
                <Header
                    appSettings={appSettings}
                    isAuthenticated={isAuthenticated}
                    currentUser={this.currentUserData}
                    history={this.props.history}
                />
                <OfferMovies
                    ServerTime = {this.SERVERTIME}
                    offerMovies={offerMovies}
                    isMovieLockDecided={isMovieLockDecided}
                    isOfferAvailed={isOfferAvailed}
                    handleMovieClick={this.handleMovieClick}
                    handleRentMovieClick={this.handleRentMovieClick}
                    {...this.props}
                />
                <TermsConditions />
                <PromotionalMovies
                    promotionMovies={promotionMovies}
                    showRentedMovieTag={showRentedMovieTag}
                    isOfferAvailed={isOfferAvailed}
                    handleMovieClick={this.handleMovieClick}
                    {...this.props}
                />
                {this.renderPurchaseModal()}
                {this.renderCheckoutTransactionModal()}
            </Fragment >
        )
    }

    render() {
        return (
            <main className="mn-main">
                <RenderScreen
                    isLoading={this.state.isLoading}
                    isError={this.state.isError} >
                    {this.renderView()}
                </RenderScreen>
            </main>
        )
    }
}
export default withAppConfig(RewardPlus);