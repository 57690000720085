import React, { Component } from "react";
import MyInput from "../../components/MyInput";
import Formsy from 'formsy-react';
import Auth from "@aws-amplify/auth";
import PropTypes from "prop-types";
import { handleChange, enableButton } from "../../helpers/StateHelper";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import { getInputFields } from "./constants";
import { withAppConfig } from "../../context/ConfigContext";

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            canSubmit: false,
            showSuccessAlert: false,
            showErrorAlert: false,
            alertMessage: ""
        };
        this.handleChange = handleChange.bind(this);
        this.enableButton = enableButton.bind(this);
    }

    handleSubmit = async (event, formReset, invalidateForm) => {
        let SUCCESS_MESSAGE = this.props.appTranslations.password_updated_successfully;
        let LIMIT_ERROR_MESSAGE = this.props.appTranslations.error_updating_password_try_again || "Error Updating Password! Try Again";
        let NOT_AUTHORIZED_ERROR_MESSAGE = this.props.appTranslations.current_password_is_not_correct;
        this.setState({
            isLoading: true,
        });
        Auth.currentAuthenticatedUser().then((user) => {
            return Auth.changePassword(user, this.state.oldPassword, this.state.newPassword);
        }).then(() => {
            this.setState({
                isLoading: false
            });
            this.props.showAlertMessage(SUCCESS_MESSAGE, "showSuccessAlert");
            this.props.togglePasswordModal();
        }).catch((e) => {
            this.setState({
                isLoading: false,
            });
            if (e.code === 'LimitExceededException') {
                invalidateForm({ 'oldPassword': e.message });
                this.props.showAlertMessage(LIMIT_ERROR_MESSAGE, "showErrorAlert");
            } else if (e.code === 'NotAuthorizedException') {
                invalidateForm({ 'oldPassword': e.message });
                this.props.showAlertMessage(NOT_AUTHORIZED_ERROR_MESSAGE, "showErrorAlert");
            }
        });
    }

    render() {
        let InputFields = getInputFields(this.props.appTranslations);

        return (
            <Formsy onValidSubmit={this.handleSubmit} onValid={this.enableButton} onInvalid={this.disableButton}>
                {InputFields && InputFields.map((input, index) => {
                    const { Name, Title, Placeholder, ValidationError, ValidationErrors, Validations } = input;
                    return (
                        <MyInput
                            key={index}
                            id={Name}
                            name={Name}
                            title={Title}
                            placeholder={Placeholder}
                            validations={Validations}
                            validationError={ValidationError}
                            validationErrors={ValidationErrors}
                            innerClassName="form-control-dark"
                            outerClassName="mb-4"
                            type="password"
                            required
                            handleChange={this.handleChange}
                        />
                    )
                })}
                <LoaderButton
                    className="btn btn-block btn-primary"
                    disabled={!this.state.canSubmit}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text={this.props.appTranslations.update_password}
                    loadingText={`${this.props.appTranslations.updating || "Updating"}  …`}
                />
            </Formsy>
        );
    }
}
export default withAppConfig(ChangePassword);
ChangePassword.propTypes = {
    showAlertMessage: PropTypes.func.isRequired,
    togglePasswordModal: PropTypes.func.isRequired
}