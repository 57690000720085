import React, { Component } from 'react';
import { renderNotificationMessage } from "../../../helpers/ViewHelpers";
import PropTypes from "prop-types";
import { updateWatchHistoryPlayDuration, updateNewPurchaseValidUntil, addNewWatchItemToLocal } from "../../../helpers/MoviesHelper";
import { showError, isEmpty, getCurrentUserId, getDataFromLocalStorage, getObjectFromArray, removeItemFromStore, importMoment } from "../../../helpers/GeneralHelpers";
import { disableAlert } from "../../../helpers/StateHelper";
import { getDRMType } from "../../../helpers/PlayerHelpers";
import { getApiData, updateApiWithResponse } from "../../../helpers/APIHelpers/MoviesAPI";
import { ORDER_NOT_FOUND, CALLBACK, SUCCESS, STORED_MY_WATCHED_LIST, MOVIE_STREAM, MOVIE_VIDEO, STORED_SERVER_TIME, ERROR } from "../../../constants/constants";
import { WATCH, UNSUPPORTED_BROWSER } from "../../../constants/routes";
import { MoviePlayer as MoviePlayerView } from "./components/MoviePlayer";
import Alert from '../../../components/Alert/Alert';
import PlayerSessionHelper from '../PlayerSession/PlayerSession';
import { withAppConfig } from "../../../context/ConfigContext";
import config from '../../../config/config';
import { withFirebase } from '../../../context/FirebaseContext';
import { fetchFullWatchList } from '../../../services/user/GetWatchList';
const defaultPlayTime = 0;

class MoviePlayer extends Component {
    isCompMounted = true;
    updateLocalPurchaseItemValidity = false;
    USER_ID = ""
    purchaseList = []; // only used for checking purchased Status
    MAPING_STATUS_TYPE = ""; //used for tracking between ID & MovieID
    moment;
    SERVERTIME;
    audioQualities = [];
    subtitleList = [];
    constructor(props) {
        super(props);
        this.state = {
            streamData: [],
            isLoadingStream: true,
            showMovieSuggestions: false,
            isLoadingRelatedMovies: true,
            movieStream: "",
            errorRelatedMovies: "",
            relatedMoviesList: "",
            alertMessage: "",
            showErrorAlert: false,
            showPurchasedMovieAlert: false,
            showRelatedTags: false,
            drmReceived: false,
        };
        this.showError = showError.bind(this);
        this.disableAlert = disableAlert.bind(this);
        this.importMoment = importMoment.bind(this);
        this.fetchMovieStream = updateApiWithResponse.bind(this);
        this.fetchMyWatchedMovies = getApiData.bind(this); //later
        this.renderNotificationMessage = renderNotificationMessage.bind(this);
    }

    componentDidMount() {
        this.displayMoviePurchaseMessage();
        this.initialize(this.props.movieData);
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.state.drmReceived !== prevState.drmReceived) {
            if (this.state.drmReceived) {
                await this.props.setFirebaseOnDisconnect();
            }
        }
    }

    async componentWillUnmount() {
        this.isCompMounted = false;
        if (this.state.drmReceived) {
            await this.props.reducePlayerSessions();
        }
    }

    initialize = (data) => {
        //window.addEventListener("beforeunload", this.cancelPlayerSession);
        this.getMovieStream(data, MOVIE_STREAM).then(async (res) => {
            if (res) {
                this.setState({
                    drmReceived: true,
                });

                if (!this.isCompMounted) {
                    await this.props.reducePlayerSessions();
                }
                this.onStreamDataLoaded(data, res);
                this.importMoment(); //later required!
            }
        })
    }

    onPlayerDestroyed = (duration) => {
        if (duration) {
            updateWatchHistoryPlayDuration(this.props.movieId, this.USER_ID, duration);
            if (this.updateLocalPurchaseItemValidity) {
                removeItemFromStore(STORED_SERVER_TIME)
            }
            this.props.onCancelSession(this.props.movieId, duration, this.props.videoType);
        }
    }

    getMovieStream = async (data, fetchApitype) => {
        let header = { MovieID: this.props.movieId };
        let streamResult = []
        if (!isEmpty(data)) {
            let drmType = await getDRMType();
            if (drmType === null) { //ios other than safari -> dont fetch stream
                const { ApplicationName = config.applicationName } = this.props.appSettings;
                let errorMessage = `Sorry! This browser is not supported to play ${ApplicationName} Content`;
                this.setState({
                    isLoadingStream: false
                })
                this.props.history.push(UNSUPPORTED_BROWSER, { data: this.props.movieData });
                return;
                //this.handleError(errorMessage);
                //return null;
            }
            switch (drmType) {
                case "widevine":
                    streamResult = await this.fetchMovieStream("DRMToken", "/drm/token/v2/widevine/", header, this.props.movieData, "", "showErrorAlert", "alertMessage", "", "Failed Loading Stream!", fetchApitype, "", "movieStream");
                    break;
                case "playready":
                    streamResult = await this.fetchMovieStream("DRMToken", "/drm/token/v2/playready/", header, this.props.movieData, "", "showErrorAlert", "alertMessage", "", "Failed Loading Stream!", fetchApitype, "", "movieStream");
                    break;
                case "fairplay":
                    streamResult = await this.fetchMovieStream("DRMToken", "/drm/token/v2/fairplay/", header, this.props.movieData, "", "showErrorAlert", "alertMessage", "", "Failed Loading Stream!", fetchApitype, "", "movieStream");
                    break;
                default: //but should not be default
                    streamResult = await this.fetchMovieStream("DRMToken", "/drm/token/v2/widevine/", header, this.props.movieData, "", "showErrorAlert", "alertMessage", "", "Failed Loading Stream!", fetchApitype, "", "movieStream")
            }
            let isError = this.handleStreamError(streamResult);
            if (!isError) {
                return null;
            }
        }

        return [streamResult['stream']];
    }

    onStreamDataLoaded = async (movieData, streamData) => {
        if (!this.USER_ID) {
            this.USER_ID = await getCurrentUserId();
        }
        if (streamData && streamData.length > 0) {
            let playerStartingTime = await this.getMovieStartTime(this.USER_ID);
            this.setState({
                playerStartingTime: playerStartingTime,
                isLoadingStream: false,
                streamData: streamData
            })
        } else { // why to do this ? :/
            this.setState({
                isLoadingStream: false,
            })
            addNewWatchItemToLocal(this.USER_ID, this.props.movieData);
            this.handleError(this.state.alertMessage === "" ? "No Stream Data Found!!" : this.state.alertMessage);
        }
    }

    getMovieStartTime = async (userId) => {
        let watchList = getDataFromLocalStorage(STORED_MY_WATCHED_LIST, userId);
        if (!watchList) {
            watchList = await fetchFullWatchList({ CdnUrl: this.props.HostName, UserId: this.USER_ID });//{ CdnUrl : this.props.HostName }
        }
        if (!isEmpty(watchList)) {
            let movie = getObjectFromArray(watchList, "MovieID", this.props.movieId);
            if (movie && !isEmpty(movie)) {
                return (movie.TotalDuration) ? (movie.TotalDuration > movie.MoviePlayDuration) ? movie.MoviePlayDuration : movie.TotalDuration : defaultPlayTime;
            }
        }
        this.updateLocalPurchaseItemValidity = true; //assuming it is being played firstTime
        addNewWatchItemToLocal(userId, this.props.movieData);
        return defaultPlayTime;
    }

    handleStreamError = (message) => {
        if (message === ORDER_NOT_FOUND) {
            setTimeout(() => this.props.history.push(`/movie/${this.props.movieId}`), 3000);
            return null;
        } else if (message === "Player Session Limit Exceeded.") {
            this.setState({
                drmReceived: false
            });
            setTimeout(() => this.props.history.push(`/devices`, { limitexceeded: true }), 3000);
            return null;
        }
        return message;
    }

    onVideoPlay = (duration) => {
        this.showMovieSuggestions(false);
        if (this.updateLocalPurchaseItemValidity) { //Updated --- >!!!
            updateNewPurchaseValidUntil(this.props.movieId, this.USER_ID, this.moment);
        }
        this.props.onStartSession(this.props.movieId, duration, this.props.videoType);
    }

    onVideoPause = (currentDuration, TotalDuration) => {
        this.showMovieSuggestions(true);
        updateWatchHistoryPlayDuration(this.props.movieId, this.USER_ID, currentDuration);
        this.props.onPauseSession(this.props.movieId, currentDuration, this.props.videoType, TotalDuration);
    }

    onVideoEnd = (duration) => {
        this.props.onEndSession(this.props.movieId, duration, this.props.videoType);
    }

    onVideoError = (err) => {
        if (err.code === 3011) { //
            this.props.reducePlayerSessions();
        }
    }

    showMovieSuggestions = (value) => {
        if (this.isCompMounted) {
            this.setState({
                showMovieSuggestions: value,
            })
        }
    }

    displayMoviePurchaseMessage = () => {
        const { appTranslations: tr } = this.props;
        if (this.props.location.state && !isEmpty(this.props.location.state) && this.props.location.state.alert && this.props.location.state.alert.messageShown === undefined) { //else messageAlreadyShown or no need
            let message = tr.movie_purchased_successfully_enjoy_watch;
            this.setState({
                showErrorAlert: false,
                showPurchasedMovieAlert: true
            }, () => {
                this.disableAlert("showPurchasedMovieAlert");
                this.props.history.push({
                    state: {
                        alert: { messageShown: true, messageType: SUCCESS, message: message }
                    }
                });
            })
        }
    }

    handleError = (err) => {
        const { ApplicationName = config.applicationName } = this.props.appSettings;
        const { appTranslations: tr } = this.props;
        let errorMessage = err;
        let text = tr.sorry_this_browser_is_not_supported_to_p || `Sorry! This browser is not supported to play <name> Content.`;
        text = text.replace("<name>", ApplicationName);
        if (err) {
            let { code = ``, name = errorMessage } = err;
            errorMessage = `${code} ${name}`;
            if (code === 1023) {
                errorMessage = text;
                this.props.history.push(UNSUPPORTED_BROWSER, { data: this.props.movieData });
                return null;
            }
            if (code) { //
                this.props.reducePlayerSessions();
            }
        }
        if (this.isCompMounted) {
            this.setState({
                showErrorAlert: true,
                alertMessage: errorMessage !== null ? errorMessage : err
            }, () => {
                this.disableAlert("showErrorAlert");
            });
        }
    }

    handleCloseButtonClick = () => {
        this.props.history.push(`/movie/${this.props.movieId}`);
    }

    handleBackButtonClick = () => {
        this.props.history.push(`/movie/${this.props.movieId}`);
    }

    handleMovieClick = (e, movieID, movie) => {
        e.preventDefault();
        if (movie.isPurchased_ && !movie.isExpired_) {
            this.props.history.push(`${WATCH}${MOVIE_VIDEO}/${movieID}`);
            this.props.fetchMovie(movieID, this.props.videoType, CALLBACK);
        } else {
            this.props.history.push(`/movie/${movieID}`);
        }
    }

    renderPurchaseMovieAlert = () => {
        return (
            this.state.showPurchasedMovieAlert ?
                <Alert
                    className={this.props.location.state.alert.messageType === SUCCESS ? "text-success" : "text-danger"}
                    icon={this.props.location.state.alert.messageType === SUCCESS ? "la la-check mr-2" : "la la-exclamation-triangle mr-2"}
                    text={this.props.location.state.alert.message}
                    type={this.props.location.state.alert.messageType === SUCCESS ? SUCCESS : ERROR}
                />
                :
                null
        )
    }

    render() {
        return (
            <div className="mn-media">
                {this.renderPurchaseMovieAlert()} {/* move these later to View*/}
                <div>
                    {this.renderNotificationMessage()}
                </div>
                <MoviePlayerView
                    isLoadingStream={this.state.isLoadingStream}
                    movieId={this.props.movieId}
                    title={this.props.title}
                    playerStartingTime={this.state.playerStartingTime}
                    posterUrl={this.props.posterUrl}
                    movieData={this.props.movieData}
                    streamData={this.state.streamData}
                    onVideoPlay={this.onVideoPlay}
                    onVideoPause={this.onVideoPause}
                    onVideoError={this.onVideoError}
                    onVideoEnd={this.onVideoEnd}
                    onError={this.handleError}
                    playerSessionId={this.props.playerSessionId}
                    handleBackButtonClick={this.handleBackButtonClick}
                    handleCloseButtonClick={this.handleCloseButtonClick}
                    showMovieSuggestions={this.state.showMovieSuggestions}
                    handleMovieClick={this.handleMovieClick}
                    onPlayerDestroyed={this.onPlayerDestroyed}
                    {...this.props}
                />

            </div>
        )
    }
}

export default PlayerSessionHelper(withAppConfig(withFirebase(MoviePlayer)));

MoviePlayer.propTypes = {
    movieId: PropTypes.string.isRequired,
    videoType: PropTypes.string.isRequired,
    posterUrl: PropTypes.string,
    title: PropTypes.string,
    movieData: PropTypes.object,
    onStartSession: PropTypes.func,
    onPauseSession: PropTypes.func,
    onEndSession: PropTypes.func,
    onCancelSession: PropTypes.func,
}
