import { getSettingsFromConfig } from "../../../helpers/ConfigHelpers";
import { GENERE_SMALL_SLIDER_SIZE } from "../../../constants/constants";
import { getCompressedCdnImageUrl } from "../../../helpers/CDNhelpers";
import { querystring } from "../../../helpers/GeneralHelpers";
import _map from "lodash/map";
import _find from "lodash/find";

export function getSortList() {
  return [
    { name: "Popularity", value: "popularity" },
    { name: "Release Date", value: "releasedate" },
    { name: "Recently Added", value: "recentlyadded" },
    { name: "A - Z", value: "az" },
    { name: "Z - A", value: "za" }
  ];
}

export const getGenreList = async HostName => {
  let genreFilters = await getSettingsFromConfig("APP_GENRE");
  genreFilters = sortConfigByPriority(genreFilters);
  _map(genreFilters, item => {
    let isPoster = item.BackgroudImage;
    if (isPoster) {
      item["BackgroudImage"] = getCompressedCdnImageUrl(
        isPoster,
        GENERE_SMALL_SLIDER_SIZE,
        HostName
      );
    }
    return item;
  });
  return genreFilters;
};

function sortConfigByPriority(data) {
  let dat = [];
  if (data) {
    dat = data.sort((a, b) => {
      return a.Priority - b.Priority;
    });
  }
  return dat;
}

export function getUrlSortGenre(){
  let genre = querystring("genre");
    let sort = querystring("sort");
    let list = getSortList();
    if (sort && !_find(list, ["value", sort])) {
      sort = null;
    }
    if(genre){
      genre = genre.toLowerCase();
    }
    if (!genre && !sort) {
      genre = "all";
      sort = list[0]["value"];
    } else if (!sort) {
      sort = list[0]["value"];
    } else if (!genre) {
      genre = "all";
    }
  return  { genre: genre, sort: sort };
}