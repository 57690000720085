import React, { PureComponent } from 'react';
import PropTypes from "prop-types";
import { importMoment } from '../../helpers/GeneralHelpers';

class ComingSoon extends PureComponent {
    state = {
        comingSoonDate: null
    }

    async componentDidMount() {
        const { HVSD, ServerTime } = this.props;
        if (HVSD && ServerTime) {
            let moment = await importMoment(false);
            let isComingSoon = moment(HVSD).isAfter(moment(ServerTime));
            if (isComingSoon) {
                this.setState({
                    comingSoonDate: moment(HVSD).format('Do MMMM')
                });
            }
        }
    }

    render() {
        const { comingSoonDate } = this.state;
        if (!comingSoonDate) {
            return null;
        }
        return (
            <div className="text-success">{comingSoonDate}</div>
        )
    }
}

ComingSoon.propTypes = {
    HVSD: PropTypes.string,
    ServerTime: PropTypes.string
}

export default ComingSoon;