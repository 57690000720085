import React, { Component, Fragment } from "react";
import LoaderButton from "../../../components/LoaderButton/LoaderButton";
import RenderScreen from "../../../components/RenderScreen/RenderScreen";
import { convertSecToDuration, isEmpty } from "../../../helpers/GeneralHelpers";
import { EXPIRED, OWNED, EST } from "../../../constants/constants";
import WatchIcons from "./WatchIcons";
import PurchaseIcons from "./PurchaseIcons";
import HeroSectionMeta from "./HeroSectionMeta";
import { PgRating } from "../../../components/PgRating/PgRating";
import config from "../../../config/config";
import { FacebookShareButton, FacebookIcon, TwitterIcon, TwitterShareButton } from "react-share";
import { withAppConfig } from "../../../context/ConfigContext";
import { ROOMDETAIL } from "../../../constants/routes";

class MovieDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            copyMessage: 'Copy'
        }

    }
    checkIsValid = (data) => {
        if (data && !isEmpty(data) && data.constructor === Array && data !== "N/A" && data.length !== 0 && data[0] !== null && data[0] !== "N/A") {
            return true
        }
        return false
    }

    getPurchasedStatus = (time = null) => {
        if (this.props.canWatchMovie) {
            if (!this.props.isExpired) {
                return time ? this.props.expiringTime : this.props.purchaseType
            } else if (this.props.isExpired) {
                return time ? this.props.expiringTime : EXPIRED;
            }// else null
        }
    };

    copyToClipboard = () => {
        const { appTranslations: tr } = this.props;
        let textField = document.createElement('textarea');
        textField.innerText = window.location.href;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        this.props.showAlert("showSuccessAlert", tr.copied_link_to_clipboard || "Copied link to clipboard!")
    };

    renderHeaderRentedTag() {
        return (
            (this.props.isAuthenticated && this.props.isMovieLockDecided) &&
            <span className={`badge badge-outline-primary px-2 ml-4 font-12 font-weight-normal align-middle ${this.getPurchasedStatus()}`}>
                {this.getPurchasedStatus() ? this.getPurchasedStatus().toUpperCase() : ``}
            </span>)
    }

    renderSubscribedTag = () => {
        if (this.props.isAuthenticated && this.props.isSubscribed_) {
            return (<span className={`badge badge-outline-primary px-2 ml-4 font-12 font-weight-normal align-middle rented`}>
                SUBSCRIBED
            </span>)
        }
    }

    renderHeader() {
        const { Title } = this.props.movieData ? this.props.movieData : {};
        return (
            <h1 className="font-weight-bold mb-4">
                <span className="align-middle">{Title}</span>
                {this.renderHeaderRentedTag()}
                {this.renderSubscribedTag()}
            </h1>
        )
    }

    renderSubHeadings() {
        const { ReleaseYear, PlayDuration } = this.props.movieData ? this.props.movieData : {};
        let duration = convertSecToDuration(PlayDuration, false, false, true);
        return (
            <div className="mn-points mb-4">
                {ReleaseYear && <span>{ReleaseYear}</span>}
                {duration && duration !== "__" && <span>{duration}</span>}
            </div>)
    }

    renderDecription() {
        const { Description } = this.props.movieData ? this.props.movieData : {};
        return (
            <div className="mb-4">
                <p>{Description && Description}</p>
            </div>
        )
    }

    renderMovieLikeIcon() {
        const { appTranslations: tr } = this.props;

        const { ID } = this.props.movieData ? this.props.movieData : {};
        let applyClassName = this.props.movieLiked ? "btn btn-outline-primary btn-lg btn-large mr-3 mb-3 mb-md-0" : "btn btn-outline-primary btn-lg btn-large mr-3 mb-3 mb-md-0"
        return (
            <LoaderButton
                className={applyClassName}
                onClick={() => this.props.handleMovieLike(ID)}
                type="submit"
                isLoading={this.props.isAddingMovieLike}
                icon={this.props.movieLiked ? <i className="la la-check mr-2"></i> : <i className="la la-plus mr-2"></i>}
                text={this.props.movieLiked ? tr.my_list || "My List" : tr.my_list || "My List"}
                loadingText={tr.please_wait || "Please Wait …"}
            />)
    }

    renderMoviePoster(movie) {
        const { appTranslations: tr } = this.props;

        const { PosterURL } = this.props.movieData ? this.props.movieData : {};
        return (

            <div className="mDetail-left">
                {this.props.movieData.isAvailableForSubscription && this.props.screeningRoom &&
                    <div className="mb-3 d-lg-flex justify-content-between align-items-center">
                        <div className="mb-3 m-lg-0">
                            <div className="text-white-50">
                                From
                        </div>
                            <div className="text-white">
                                {this.props.screeningRoom.Title}
                            </div>
                        </div>
                        <div className="">
                            <a href={ROOMDETAIL + this.props.screeningRoom.ID} className="btn btn-white btn-medium">Explore Pack <i className="las la-arrow-right ml-2"></i></a>
                        </div>
                    </div>
                }
                <div className="mn-md-figure shadow">
                    <div className="mn-figure">
                        {(PosterURL && PosterURL != "") && <div className="mn-thumb img-bg img-1x15 img-fluid" style={{ backgroundImage: `url(${PosterURL}` }}></div>}
                        {!this.props.movieData.isAvailableForSubscription &&
                            <div className="mn-premium"><i className="las la-crown"></i></div>}
                    </div>
                    {/* {tr.validity || "Validity"} : <span className="text-white">{tr.lifetime || "Lifetime"}</span> */}
                        {(this.props.isAuthenticated && this.props.isMovieLockDecided && this.props.canWatchMovie) &&
                            (this.props.purchaseType === EST || this.props.purchaseType === OWNED) ?
                            <div className="mn-movie-info"><i className="la la-info-circle mr-2"></i>{tr.validity_lifetime || "Validity Lifetime"} </div>
                            :
                            !this.props.isExpired ?
                                <div className="mn-movie-info">
                                    <i className="la la-info-circle mr-2"></i>
                                    {this.getPurchasedStatus("time") !== (tr.new || "New") ? `${tr.will_expire_in || "Will expire in"} ` : `${tr.purchase_status || "Purchased Status"} : `}
                                    <strong>{this.getPurchasedStatus("time")}</strong>
                                </div>
                                :
                                null
                        }
                </div>
            </div>
        )
    }

    renderView = () => {
        // console.log("Translations",JSON.stringify(this.props.appTranslations));
        const { PreviewImage, Title } = this.props.movieData ? this.props.movieData : {};
        const { appTranslations: tr } = this.props;

        let url = window.location.href;
        return (
            (isEmpty(this.props.movieData)) ?
                null :
                <Fragment>
                    <section className="mn-mDetail-1">
                        <div className="mn-mDetail-thumb img-bg" style={{ backgroundImage: `url(${PreviewImage}` }}>
                            <div className="mn-mDetail-caption py-5">
                                <div className="container xl">
                                    <div className="media mDetail-media align-items-end">
                                        {this.renderMoviePoster()}
                                        <div className="media-body pl-5 d-flex justify-between align-items-end">
                                            <div className="mn-dt-excerpt w-100 pr-5">
                                                <div className="mn-mDetail-excerpt">
                                                    <div className="mn-excerpt-top">
                                                        <div>
                                                            {this.renderHeader()}
                                                            {this.renderSubHeadings()}
                                                        </div>
                                                        <div className="mb-5 text-white-70">
                                                            <PgRating
                                                                pgID={this.props.movieData.PGRating}
                                                            />
                                                            {this.props.movieData.PGAdviceText &&
                                                                <div className="d-flex align-items-center mb-4">
                                                                    <span
                                                                        className="font-12"> {this.props.movieData.PGAdviceText}
                                                                    </span>
                                                                </div>
                                                            }
                                                            {this.renderDecription()}
                                                            <HeroSectionMeta
                                                                movieData={this.props.movieData}
                                                                checkIsValid={this.checkIsValid}
                                                                comingSoonStatus={this.props.comingSoonStatus}
                                                                purchaseTierQuality={this.props.purchaseTierQuality}
                                                                movieID={this.props.match.params.id}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mn-excerpt-btm">
                                                        <div className="">
                                                            <PurchaseIcons
                                                                isMovieLockDecided={this.props.isMovieLockDecided}
                                                                isSubscribed_={this.props.isSubscribed_}
                                                                canWatchMovie={this.props.canWatchMovie}
                                                                isExpired={this.props.isExpired}
                                                                movieData={this.props.movieData}
                                                                checkIsValid={this.checkIsValid}
                                                                movieID={this.props.match.params.id}
                                                                isAuthenticated={this.props.isAuthenticated}
                                                                handleTrailerClick={this.props.handleTrailerClick}
                                                                handlePurchaseClick={this.props.handlePurchaseClick}
                                                                handleWatchMovieClick={this.props.handleWatchMovieClick}
                                                                history={this.props.history}
                                                                screeningRoom={this.props.screeningRoom}
                                                            />
                                                            {this.renderMovieLikeIcon()}
                                                            {
                                                                <div className="fab mr-3 mb-3 mb-md-0">
                                                                    <span className="fab-action-button" title="Share on"><i className="las la-share"></i></span>
                                                                    <ul className="fab-buttons">
                                                                        <li className="fab-buttons__item" title={tr.facebook || "Facebook"}>
                                                                            <FacebookShareButton
                                                                                url={url}
                                                                                quote={Title}
                                                                                data-tooltip="Facebook"
                                                                            >
                                                                                <FacebookIcon
                                                                                    size={39}
                                                                                    round
                                                                                />
                                                                            </FacebookShareButton>
                                                                        </li>
                                                                        <li className="fab-buttons__item" title={tr.twitter || "Twitter"}>
                                                                            <TwitterShareButton
                                                                                url={url}
                                                                                title={Title}
                                                                                data-tooltip="Twitter"
                                                                            ><TwitterIcon
                                                                                    size={39}
                                                                                    round
                                                                                />
                                                                            </TwitterShareButton>
                                                                        </li>
                                                                        <li className="fab-buttons__item" title={tr.copy_movie_link || "Copy Link"}>
                                                                            <button className="fab-buttons__link" onClick={this.copyToClipboard}>
                                                                                <i className="las la-clipboard"></i>
                                                                            </button>

                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <WatchIcons
                                                isMovieLockDecided={this.props.isMovieLockDecided}
                                                canWatchMovie={this.props.canWatchMovie}
                                                movieData={this.props.movieData}
                                                checkIsValid={this.checkIsValid}
                                                isExpired={this.props.isExpired}
                                                movieID={this.props.match.params.id}
                                                handleTrailerClick={this.props.handleTrailerClick}
                                                handleWatchMovieClick={this.props.handleWatchMovieClick}
                                                isSubscribed_={this.props.isSubscribed_}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Fragment>
        )
    }

    render() {
        return (
            <RenderScreen
                isLoading={this.props.isLoadingMovieData}
                isError={this.props.dataErrorMovie}
                stuffpixLoader={config.customSettings.isStuffpixEnabled}
            >
                {this.renderView()}
            </RenderScreen>

        );
    }
}
const MovieDetailView = withAppConfig(MovieDetail);
export { MovieDetailView };
