import React, { useState, memo } from "react";
import { Modal } from "reactstrap";
import _orderBy from "lodash/orderBy";
import PurchaseContainer from "./components/PurchaseContainer";
import Qualities from "./components/Qualities";
import Header from "./components/Heading";

const RentAndSubscribe = props => {
  const { isOpen, toggleModal, Price, ID, Type } = props;
  return (
    <Modal
      isOpen={isOpen}
      backdrop={isOpen}
      toggle={toggleModal}
      size="sm"
      centered
      modalClassName="modal-dark"
    >
      <div className="modal-content mn-purchase-modal">
        <Header {...props} />
        <Container {...props} />
      </div>
    </Modal>
  );
};

const Container = ({ ID, Price,Type ,Duration }) => {
  let initialQuality = "";
  let sorted = Price;
  if (Price) {
    sorted = Price;
    sorted = _orderBy(sorted, ["Price"],['desc']);
    if (sorted && sorted.length > 0) {
      initialQuality = sorted[0].Name;
    }
  }

  const [selectedQuality, setQuality] = useState(initialQuality);
  
  const onChange = quality => {
    setQuality(quality);
  };

  let params = {
    Type : Type,
    Price : Price,
    ID : ID,
    selectedQuality : selectedQuality
  }
  return (
    <div className="modal-body">
      <Qualities
        Price={sorted}
        onChange={onChange}
        Type = {Type}
        Duration= {Duration}
        selectedQuality={selectedQuality}
      />
      <PurchaseContainer {...params} />
    </div>
  );
};
export default memo(RentAndSubscribe);


