import _orderBy from "lodash/orderBy";
import _map from "lodash/map";
import _uniq from "lodash/uniq";
import _includes from "lodash/includes";
import _isEmpty from "lodash/isEmpty";

export const getSortedData = (data, value) => {
    let sortedArray = data;
    switch (value) {
        // case "AvgRating":
        //     sortedArray = _orderBy(data, ['AvgRating']);
        //     break;
        case "year":
            sortedArray = _orderBy(data, ['ReleaseYear'], ['desc']);
            break;
        case "az":
            sortedArray = _orderBy(data, ['Title']);
            break;
        case "za":
            sortedArray = _orderBy(data, ['Title'], ['desc']);
            break;
        case "recently-added":
            sortedArray = _orderBy(data,["HVSD"],['desc']);
            break;
    }
    return sortedArray;
}

export const  applySorting = (data, sectionType,selectedSort) => {
    if (data && (sectionType === "custom")) {
        let sorteddata = getSortedData(data, selectedSort);
        return sorteddata
    }
    return data;
}

export const getFilteredDataByGenre = (allmoviesList, Value,data) => {
    let dataList = allmoviesList;
    if(!_isEmpty(dataList) && data){
        dataList = data;
    }
    if (Value === "all") {
        return dataList
    } else {
        let filteredArray = [];
        _map(dataList, (item) => {
            if (item.Genre) {
                let genereMatched = _includes(item.Genre, Value);
                genereMatched && filteredArray.push(item);
            }
        });
        filteredArray = _uniq(filteredArray, "ID");
        return filteredArray;
    }
}