import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { FORGOT_PASSWORD, LOGIN } from "../../../constants/routes";

const AlreadyFoundException = props => {
  const onClickSignIn = () => {
    props.history.push(LOGIN);
  };

  const onClickPassword = () => {
    props.history.push(FORGOT_PASSWORD);
  };

  if (props.showWarning) {
    return (
      <Fragment>
        <div className="alert alert-danger" role="alert">
          <p>
            This {props.username.phone_number ? <strong>{'phone number +'+ props.username.phone_number}</strong> : <strong>{'email address +' + props.username.email}</strong>}already exists. &nbsp;
            <a onClick={onClickSignIn} className="text-primary">
              Sign In
            </a>{" "}
            or{" "}
            <a onClick={onClickPassword} className="text-primary">
              Reset your password
            </a>{" "}
            if you do not remember
          </p>
        </div>
      </Fragment>
    );
  }

  return null;
};
export default withRouter(AlreadyFoundException);
