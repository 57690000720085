import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "reactstrap";
import BrainTree from "../../Checkout/components/BrainTree"; //change its directory
import { withPaymentCard } from "../../../context/PaymentCardContext";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import { withAppConfig } from "../../../context/ConfigContext";
import Adyen from "../../Checkout/components/Adyen";

class SaveCardModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showCardModal: false,
            showSaveConfirmation: false,
        };
    }

    componentDidMount() {
        this.setState({
            showCardModal: true
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSavingCard !== this.props.isSavingCard) {
            if (!this.props.isSavingCard) {
                this.toggleCardModal()
            }
        }
    }

    toggleCardModal = (id) => {
        this.setState((prevState) => {
            return {
                showCardModal: !prevState.showCardModal
            }
        });
    }

    onSavePaymentClicked = (params) => {
        this.toggleConfirmationModal();
        this.setState({
            savedParams: params
        });
    }

    toggleConfirmationModal = () => {
        this.setState((prevState) => {
            return {
                showSaveConfirmation: !prevState.showSaveConfirmation
            }
        })
    }

    onConfirmationClick = () => {
        this.toggleConfirmationModal();
        if (this.state.savedParams) {
            this.props.savePaymentCard(this.state.savedParams)
        }
    }

    render() {
        const { add_a_card , enter_your_card_information =``, are_you_sure_you_want_to_save, to_verify_your_card_an_amount_of_up_to_a = ``, close ,save} = this.props.appTranslations;
        return (
            <Modal isOpen={this.state.showCardModal} toggle={this.toggleCardModal} backdrop={true} className="modal-lg modal-dark modal-dialog-centered" id="paymentOption" >
                {this.props.isSavingCard && <span className="loader-spiral mn-white mn-transparent"><span className="spiral-inn"></span></span>}
                <div className="modal-content">
                    <div className="modal-header px-4 px-sm-5 flex-column">
                        <h5 className="modal-title">{add_a_card}</h5>
                        <div className="text-white-70 font-12">{enter_your_card_information}</div>
                    </div>
                    <div className="modal-body p-4 p-sm-5 text-white-50">
                       <Adyen 
                       savePaymentClicked={this.onSavePaymentClicked}
                       defaultCardOption={true}
                       buttonText={save}
                       saveCard={true}
                       customContainerClass="col-lg-12"
                       {...this.props}
                       />
                        {/* <Adyen
                    {...props}
                    price={props.checkoutData.Price}
                    checkoutParams={props.checkoutData}
                  /> */}
                        {/* <BrainTree
                            clientToken={this.props.clientToken}
                            savePaymentClicked={this.onSavePaymentClicked}
                            defaultCardOption={true}
                            buttonText={save}
                            saveCard={true}
                            customContainerClass="col-lg-12"
                            {...this.props}
                        /> */}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={this.toggleCardModal}>{close}</button>
                    </div>
                    <ConfirmationModal
                        showConfirmationModal={this.state.showSaveConfirmation}
                        toggleModal={this.toggleConfirmationModal}
                        backdrop={true}
                        onConfirmationClick={this.onConfirmationClick}
                        Title={are_you_sure_you_want_to_save}
                        // Subtitle={to_verify_your_card_an_amount_of_up_to_a || `To verify your card, an amount of up to ${this.props.appSettings.CurrencySymbol}0.01 will be charged. After Verification, the amount will be automatically refunded.`}
                        Subtitle={(to_verify_your_card_an_amount_of_up_to_a && to_verify_your_card_an_amount_of_up_to_a.replace('{Currency_Symbol}',this.props.appSettings.CurrencySymbol)) ||   `To verify your card, an amount of up to ${this.props.appSettings.CurrencySymbol}0.01 will be charged. After Verification, the amount will be automatically refunded.`}
                    />
                </div>
            </Modal>
        )
    }
}

export default withPaymentCard(withAppConfig(SaveCardModal));
SaveCardModal.propTypes = {
    clientToken: PropTypes.any,
    isSavingCard: PropTypes.bool,
    savePaymentCard: PropTypes.func
}
