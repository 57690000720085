import backgorundImage from "../../assets/images/login-bg.jpg";
import config from "../../config/config";

export const getLayoutClass = (loginLayout,pixScrolled,geoBlockStyle,isAuthenticated) => {
    let className = "main-wrap global-wrap";
    if (config.customSettings.isStuffpixEnabled) {
      className = `${className} ${pixScrolled}`;
    }
    if (loginLayout) {
      if (geoBlockStyle) {
        className = `${className} error-wrap`;
      } else {
        className = `${className} login-wrap`;
      }
    } else {
      if (config.customSettings.isStuffpixEnabled && !isAuthenticated) {
        className = `${className} top-strip`;
      }
    }
    return className;
  }

  export const getBackGroundImageStyle = (loginLayout, geoBlockStyle) => {
    let style = {};
    if (loginLayout && !geoBlockStyle) {
      style = { backgroundImage: `url(${backgorundImage})` }
    }
    return style;
  }