import React, { Component, Fragment } from "react";
import MyInput from "../../components/MyInput";
import Formsy from 'formsy-react';
import { Link } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import Auth from "@aws-amplify/auth";
import { setDocumentTitle, querystring } from "../../helpers/GeneralHelpers";
import { handleChange, enableButton, disableButton } from "../../helpers/StateHelper";
import { FORGOT_PASSWORD_PAGE } from "../../constants/constants";
import { Form as ChangePasswordView } from "./components/ForgotPassword";
import { RESET_PASSWORD } from "../../constants/routes";
import withUserSession from "../../hoc/UserSession";
import { withFirebase } from "../../context/FirebaseContext";
import { withAuth } from "../../context/AuthContext";
import { withAppConfig } from "../../context/ConfigContext";
import config from "../../config/config";
import { isValidPhoneNumber } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'
import UserConfirmation from "../../components/UserConfirmation";
import ResendPassword from "../../services/user/ResendPassword";
import UserNotConfirmed from "./components/UserNotConfirmed";
import loginVector from "../../assets/images/svg/login-boy.svg";
let DEFAULT_PAGE = "CHANGE_PASSWORD";
const RESET_PAGE = "RESET_PASSWORD";


class ForgotPassword extends Component {
    isCompMounted = true;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            pageView: DEFAULT_PAGE,
            email: "",
            phone_number: "",
            inputValue: "Use Mobile",
            codeDeliverySuccess: false,
            codeSent: false,
            canSubmit: false,
            code: "",
            codeDeliveryString: "",
            newPassword: "",
            confirmNewPassword: "",
        };
        this.handleChange = handleChange.bind(this);
        this.enableButton = enableButton.bind(this);
        this.disableButton = disableButton.bind(this);
        this.changePasswordRef = React.createRef();
    }

    componentDidMount() {
        setDocumentTitle(FORGOT_PASSWORD_PAGE);
        this.initiailize();
    }

    componentWillUnmount() {
        this.isCompMounted = false;
    }

    initiailize = () => {
        const { location, appTranslations: tr } = this.props;
        if (location.pathname === RESET_PASSWORD) {
            this.setState({
                pageView: RESET_PAGE
            });
            let email = this.getEmail();
            let code = querystring("code");
            if (!email) {
                this.props.history.push('/login');
            } else {
                let codeDeliveryString = (this.props.location && this.props.location.state && this.props.location.state.displayMessage) ? `${tr.your_previous_password_has_expired_pleas} : ${email}` : ``;
                this.setState({
                    email: email,
                    code: code,
                    codeDeliveryString: codeDeliveryString
                });
                if (code) { //to wait until ref
                    setTimeout(() => {
                        if (this.changePasswordRef && this.changePasswordRef.setInput) {
                            this.changePasswordRef.setInput(code);
                        }
                    }, 100);
                }
            }
        }
    }

    getEmail() {
        alert(1)
        if (this.props.history && this.props.history.location && this.props.history.location.search) {
            let userEmail = this.props.history.location.search.split('&');
            userEmail = (userEmail && userEmail[0]) ? userEmail[0].replace("?email=", '') : null
            return userEmail;
        }
        return null;
    }

    handleSubmitForgotEmail = async (event, formReset, invalidateForm) => {
        const { user_does_not_exist, your_code_has_been_sent_via_email_to_ema } = this.props.appTranslations;
        this.setState({ isLoading: true });

        Auth.forgotPassword(this.state.phone_number ? '+' + this.state.phone_number : this.state.email.toLowerCase()).then((data) => {
            if (data.CodeDeliveryDetails) {
                let text = your_code_has_been_sent_via_email_to_ema || 'Your code has been sent via EMAIL to _EmailAddress';
                text = text.replace("EMAIL", data.CodeDeliveryDetails.DeliveryMedium);
                text = text.replace("_EmailAddress", data.CodeDeliveryDetails.Destination);
                this.setState({
                    codeDeliveryString: text,
                    codeDeliveryStatus: true,
                    isLoading: false,
                    codeSent: true
                })
            }
        }).catch((e) => {
            let message = e.message;
            if (e.code === 'UserNotConfirmedException') {
                //
            } else if (e.code === 'UserNotFoundException' || e.code === 'NotAuthorizedException') {
                if (e.message === "User password cannot be reset in the current state.") {
                    //const { ApplicationName = config.applicationName } = this.props.appSettings;
                    //message = `You cannot reset password in this state. Please login with Gmail or Facebook using ${ApplicationName}`;
                    this.handleResendPassword();
                    return;
                } else {
                    message = user_does_not_exist || "User does not exist";
                    invalidateForm(this.state.phone_number ? { phone_number: message } : { 'email': message })
                }
            } else if (e.code === 'InvalidParameterException') {
                if (e.message === 'Cannot reset password for the user as there is no registered/verified email or phone_number') {
                    this.setState({
                        userConfirmationModal: true
                    });
                    this.setState({
                        codeDeliveryString: message,
                        codeDeliverySuccess: false,
                        isLoading: false
                    });
                    return;
                }
            }
            this.setState({
                codeDeliveryString: message,
                codeDeliverySuccess: false,
                isLoading: false
            });
            this.props.showAlert("showErrorAlert", message);
        });
    }

    handleResendPassword = () => {
        ResendPassword((res, err) => {
            this.setState({
                codeDeliverySuccess: false,
                isLoading: false
            });
            if (!err) {
                this.props.showAlert("showSuccessAlert", 'Forgot Password email/codes has been sent.');
                this.props.history.push('/login')
            }
        }, this.state.phone_number ? { phone_number: this.state.phone_number } : { email: this.state.email })
    }

    handleSubmitForgotPasswordConfirm = async event => {
        this.setState({ isLoading: true });
        Auth.forgotPasswordSubmit(this.state.phone_number ? this.state.phone_number : this.state.email.toLowerCase(), this.state.code, this.state.newPassword).then(async () => {
            this.setState({
                isLoading: false
            });
            this.props.showAlert("showSuccessAlert", this.props.appTranslations.password_updated_successfully || "Updated Successfully")
            if (this.state.pageView === RESET_PAGE) {
                await Auth.signIn(this.state.phone_number ? this.state.phone_number : this.state.email.toLowerCase(), this.state.newPassword);
                this.props.updateAuthStatus(true, false);
                this.props.updateUserData();
                this.props.createSession();
                return
            }
            this.props.history.push('/login');
        }).catch((err) => {
            let message = err.message;
            const { ApplicationName = config.applicationName } = this.props.appSettings;
            if (err.code === 'UserNotFoundException' || err.code === 'NotAuthorizedException') {
                if (err.message === "User password cannot be reset in the current state.") {
                    message = `You cannot reset password in this state. Please login with Gmail or Facebook using ${ApplicationName}`;
                }
            }
            this.setState({
                isLoading: false
            });
            this.props.showAlert("showErrorAlert", message);
        });
    }
    changeLabelText = () => {
        this.setState({ email: '', phone_number: '' });
        this.setState({
            inputValue: this.state.inputValue === "Use Mobile" ? "Use Email" : "Use Mobile"
        })

    }
    validateInput = () => {
        if (this.state.phone_number) {
            return isValidPhoneNumber(this.state.phone_number)
        } else return "isEmail"
    }
    renderForgotEmailForm() {
        const { send, sending, we_will_send_you_verification_code, please_enter_valid_email_address, please_enter_valid_phone_number, submit, email_address, forgot_password } = this.props.appTranslations;

        return (
            <Fragment>
                <h4 className="text-uppercase text-center mb-4">{forgot_password}</h4>
                {this.state.isLoading && <span className="loader-spiral mn-white mn-white-transparent"><span className="spiral-inn"></span></span>}
                <UserNotConfirmed
                    showWarning={this.state.userConfirmationModal}
                    email={this.state.email}
                />
                <Formsy onValidSubmit={this.handleSubmitForgotEmail} onValid={this.enableButton} onInvalid={this.disableButton}>
                    <div className="text-right">
                        <a onClick={this.changeLabelText} className="join-type">
                            {this.state.inputValue}
                        </a>
                    </div>
                    {/* {this.state.inputValue === "Use Email" ?
                        <div className="form-group mb-4">
                            <PhoneInput
                                placeholder="Enter phone number"
                                country='pk'
                                value={this.state.phone_number}
                                onChange={this.setPhoneValue}
                                required
                            />
                        </div>

                        : <MyInput
                            id="email"
                            inputValue={this.state.inputValue}
                            name="email"
                            type="text"
                            outerClassName="mb-4"
                            placeholder={email_address}
                            validations="isEmail"
                            validationError={please_enter_valid_email_address}
                            required
                            handleChange={this.handleChange}
                        />} */}
                    <div className="form-group mb-4">
                    <MyInput
                        id={this.state.inputValue === "Use Email" ? "phone_number" : "email"}
                        name={this.state.inputValue === "Use Email" ? "phone_number" : "email"}
                        type={this.state.inputValue === "Use Email" ? "phone" : "text"}
                        outerClassName="mb-4"
                        placeholder={email_address}
                        // validations= {this.validateInput}
                        // validationError= {this.state.phone_number ? "please eneter valid phone numer" : please_enter_valid_email_address}
                        required
                        handleChange={this.handleChange}
                    />
                    </div>
                    <div className="form-group font-12 font-weight-normal">
                        {we_will_send_you_verification_code}.
                    </div>
                    <LoaderButton
                        disabled={!this.state.canSubmit}
                        type={submit}
                        className="btn btn-primary btn-block text-uppercase"
                        isLoading={this.state.isLoading}
                        text={send}
                        loadingText={`${sending} …`}
                    />
                </Formsy>
            </Fragment>
        );
    }

    toggleConfirmationModal = () => {
        this.setState(prevState => {
            return {
                userConfirmationModal: !prevState.userConfirmationModal
            };
        });
    };

    render() {
        const { ApplicationName = config.applicationName } = this.props.appSettings;
        const { pageView } = this.state;
        const { already_have_an_account, sign_in_now, confirm_forgot_password, reset_password } = this.props.appTranslations;

        let subtitle = `Your ${ApplicationName} Account has not been confirmed. Please Confirm your ${ApplicationName} account by clicking the link from Email and then attempt to Forgot Password. If you do not receive Verification Email, Please click on Resend.`
        return (
            <main className="mn-main">
                {/* <UserConfirmation
                    showModal={this.state.userConfirmationModal}
                    toggleModal={this.toggleConfirmationModal}
                    email={this.state.email}
                    Title = 'User Confirmation'
                    SubTitle = {subtitle}
                /> */}
                <section className="mn-login-section py-5">
                    <h3>{this.state.testEmail}</h3>
                    <div className="container">
                        <div className="mn-login-content">
                            <div className="mn-login-body row no-gutters align-items-stretch">
                                <div className="mn-login-left d-flex flex-column justify-content-center bg-gray-800 px-3 py-4 p-md-5 col-12 col-md-6 ">
                                    {(pageView === DEFAULT_PAGE && this.state.codeSent === false)
                                        ? this.renderForgotEmailForm()
                                        :
                                        <ChangePasswordView
                                            appTranslations={this.props.appTranslations}
                                            formTitle={pageView === DEFAULT_PAGE ? confirm_forgot_password : reset_password}
                                            enableButton={this.enableButton}
                                            handleChange={this.handleChange}
                                            isLoading={this.state.isLoading}
                                            canSubmit={this.state.canSubmit}
                                            subheadingText={this.state.codeDeliveryString}
                                            disableButton={this.disableButton}
                                            handleSubmitForgotPasswordConfirm={this.handleSubmitForgotPasswordConfirm}
                                            forwardedRef={(c) => { this.changePasswordRef = c; }}
                                        />}
                                </div>
                                <div className="mn-login-right d-flex flex-column text-center p-5 col-12 col-md-6">
                                    <div className="mb-4 mt-auto">
                                        <img src={loginVector} style={{ maxWidth: "250px" }} />
                                    </div>
                                    <div className="mt-auto">
                                        <div className="mb-3">{already_have_an_account}</div>
                                        <div className="">
                                            <Link to="/login" className="btn btn-white btn-lg btn-large">{sign_in_now}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}
export default withFirebase(withUserSession(withAppConfig(withAuth(ForgotPassword))));
