import React from "react";

class MyRadioButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: ""
    };
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  handleOptionChange = e => {
    this.props.handleOptionChange(e.target.value);
  };

  render() {
    const outerClassName = this.props.outerClassName;
    const innerClassName = this.props.innerClassName;
    return (
      <div className={outerClassName}>

        <input
          type="radio"
          className={innerClassName}
          name={this.props.id}
          value={this.props.id}
          id={this.props.id}
          checked={this.props.selectedOption === this.props.id}
          onChange={this.handleOptionChange}
        />
        <label for={this.props.id} className={this.props.labelClassName}>
          {this.props.label}
        </label>
      </div>
    );
  }
}
MyRadioButton.defaultProps = {
  labelClassName: "custom-control-label"
};
export default MyRadioButton;
