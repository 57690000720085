import { getCurrentUserId, getDataFromLocalStorage, storeDataInLocalStore } from "../../helpers/GeneralHelpers";
import { TEMPORARY_ORDER_ID } from "../../constants/constants";
import { GET } from "../../api/";
import _isEqual from "lodash/isEqual";
import _differenceWith from "lodash/differenceWith";
import _find from "lodash/find";
import _isEmpty from "lodash/isEmpty";
import _remove from "lodash/remove";
import _uniqBy from "lodash/uniqBy";
import _concat from "lodash/concat";
import { handleImageResizing } from "../../helpers/CDNhelpers";

export function GetWatchList({ CdnUrl, UserId }) { //to fetch data with pagination
    let watchList = [];
    watchList = getDataFromLocalStorage("myWatchList", UserId);
    if (watchList && watchList.length > 0) {
        watchList = _uniqBy(watchList, "MovieID");
        //callback(watchList);
        return watchList;
    }
    let path = `/watchList?ExclusiveStartKey=&Limit=${15}`;
    return GET("UserAPI", path)
        .then(async res => {
            watchList = res.body && res.body.Items ? res.body.Items : [];
            watchList = await handleImageResizing(watchList, "WatchList", CdnUrl);
            return watchList;
            //callback(watchList);
        })
        .catch(err => {
            return [];
            //callback(null, err);
        });
}

export function fetchFullWatchList({ CdnUrl }) {
    let watchedLimit = 50;
    let path = `/watchList?ExclusiveStartKey=&Limit=${watchedLimit}`;
    return fetchDataRecursively("UserAPI", "watchList", path, [], "FETCH_WATCHLIST", "").then((finalApiData) => {
        return getCurrentUserId().then(async (userId) => {
            let storedwatchList = [];
            if (userId) {
                storedwatchList = getDataFromLocalStorage("myWatchList", userId);
                let result = _differenceWith(finalApiData, storedwatchList, _isEqual);
                if (!storedwatchList) {
                    storedwatchList = result;
                } else {
                    let tempOrder = _find(storedwatchList, ["ID", TEMPORARY_ORDER_ID])
                    if (result && !_isEmpty(result)) {
                        result.forEach((newObj) => {
                            if (tempOrder && (newObj.MovieID === tempOrder.MovieID)) {
                                _remove(storedwatchList, (movie) => {
                                    return (movie["ID"] === TEMPORARY_ORDER_ID);
                                });
                            }
                            storedwatchList.unshift(newObj);
                        });
                    }
                }
                storedwatchList = _uniqBy(storedwatchList, 'MovieID');
                if (CdnUrl) {
                    storedwatchList = await handleImageResizing(storedwatchList, "WatchList", CdnUrl);
                }
                if (!_isEmpty(storedwatchList)) {
                    await storeDataInLocalStore("myWatchList", storedwatchList, userId, "No expiry");
                }
            }
            return storedwatchList;
        });
    })
}

export function fetchDataRecursively(apiname, pathName, apipath, DATA) {
    let Limit = 50;
    return fetchAPIData(apiname, apipath, DATA).then((DATALIST) => {
        let responseLastEvaluatedKey = (!_isEmpty(DATALIST.response) && DATALIST.response.body.LastEvaluatedKey) ? DATALIST.response.body.LastEvaluatedKey : null;
        if (!responseLastEvaluatedKey) {
            return DATALIST.data
        }
        let path = `/${pathName}?ExclusiveStartKey=${responseLastEvaluatedKey ? responseLastEvaluatedKey : ``}&Limit=${Limit}`;
        return fetchDataRecursively(apiname, pathName, path, DATALIST.data, responseLastEvaluatedKey)
    })
}

export function fetchAPIData(apiname, apipath, data) {
    return GET(apiname, apipath)
        .then(async res => {
            let concated = _concat(data, res.body.Items);
            return { data: concated, response: res };
        })
        .catch(err => {
            return { data: [], response: [] };
        });
}