import React, { PureComponent } from "react";

class StripeMethod extends PureComponent {
  
  constructor(props) {
    super(props);
    this.state = {
      saveCard: true
    };
  }

  componentDidMount() {
    // Create a Stripe client.
    var stripe = window.Stripe("pk_test_51L09hZECZxPrxkdPToDQ1dv8pOvwOitAqLH19zR7yuF8tnlSCLqk2Y3du6TfnRVMwBNiGyGOPeu2WrKvaPIiHHTh0093ordDIQ");

    // Create an instance of Elements.
    var elements = stripe.elements();

    // Custom styling can be passed to options when creating an Element.
    // (Note that this demo uses a wider set of styles than the guide below.)
    var style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };

    // Create an instance of the card Element.
    var card = elements.create("card", { style: style });

    // Add an instance of the card Element into the `card-element` <div>.
    card.mount("#card-element");

    // Handle real-time validation errors from the card Element.
    card.addEventListener("change", function(event) {
      var displayError = document.getElementById("card-errors");
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
    });

    // Handle form submission.
    var form = document.getElementById("payment-form");
    form.addEventListener("submit", function(event) {
      event.preventDefault();

      stripe.createToken(card).then(function(result) {
        if (result.error) {
          // Inform the user if there was an error.
          var errorElement = document.getElementById("card-errors");
          errorElement.textContent = result.error.message;
        } else {
          // Send the token to your server.
          stripeTokenHandler(result.token);
        }
      });
    });

    // Submit the form with the token ID.
    const stripeTokenHandler = token => {
      // Insert the token ID into the form so it gets submitted to the server
      var form = document.getElementById("payment-form");
      var hiddenInput = document.createElement("input");
      hiddenInput.setAttribute("type", "hidden");
      hiddenInput.setAttribute("name", "stripeToken");
      hiddenInput.setAttribute(
        "class",
        "form-control form-control-dark bt-form-control"
      );
      hiddenInput.setAttribute("value", token.id);
      form.appendChild(hiddenInput);

      // Submit the form
      //form.submit();

      if (
        this.props.Type === "collection" ||
        this.props.Type === "screening_room"
      ) {
        //make both same later!! remove checkout params
        const params = {
          PaymentGateway: "stripe",
          PaymentNonce: token.id,
          SaveCard: this.state.saveCard
        };
        this.props.onClickPaymentForm("formPayment", params);
      } else {
        let header = this.props.checkoutParams;
        header["PaymentNonce"] = token.id;
        header["PaymentGateway"] = "Stripe";
        header["SaveCard"] = this.state.saveCard;
        this.props.onClickPaymentForm("formPayment", header);
      }
    };
  }

  renderSaveToggle = () => {
    return (
      <div className="form-group">
        <div className="row gutter-7">
          <div className="col-12">
            <label className="mn-switch">
              <input
                type="checkbox"
                checked={this.state.saveCard}
                onChange={this.onToggleSaveCard}
              />
              <span className="mn-switch-slider round" />
            </label>
            <span className="ml-2 font-12">Save my card for next payment.</span>
          </div>
        </div>
      </div>
    );
  };

  onToggleSaveCard = () => {
    this.setState(prevState => {
      return {
        saveCard: !prevState.saveCard
      };
    });
  };

  render() {
    return (
      <div className="panel-body text-white-50 text-left">
        <div className="row justify-content-center">
          <div className="payment-head">
            <div className="mb-3">
              <span className="text-white font-weight-bold justify-content-center">
                {/* Stripe! */}
              </span>
            </div>
          </div>
          <form method="post" className="strip-form" id="payment-form">
            <div className="form-row-- mb-3">
              <label htmlFor="card-element">Credit or debit card</label>
              <div id="card-element" />

              <div id="card-errors" role="alert" />
            </div>
            {this.renderSaveToggle()}
            <div className="mb-3 text-center">
              <button className="btn btn-primary">Submit Payment</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default StripeMethod;
