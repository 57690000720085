import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import RenderScreen from "../RenderScreen/RenderScreen";
import MovieCard from "../MovieCard/MovieCard";
import DataNotFound from '../DataNotFound/DataNotFound';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../Loader/Loader';
import { withAppConfig } from '../../context/ConfigContext';

class CardsList extends React.Component {

    render() {
        let message = this.props.notFoundMessage;
        const { isLoading, isError, data, lastEvaluatedKey, tab, isLoadingMore, appTranslations: tr } = this.props;
        if (message === "No Movies Found!") {
            message = `${tr.no_movies_found}!`
        }
        return (
            <RenderScreen
                isLoading={isLoading}
                isError={isError}>
                <InfiniteScroll
                    dataLength={data ? data.length : 0}
                    next={() => this.props.fetchMoreData(tab)}
                    hasMore={lastEvaluatedKey !== "" ? true : false}
                    loader={isLoadingMore && <div height="150px"><Loader height="150px" /></div>}>
                    <section className={this.props.sectionClassName}>
                        <div className="container-fluid">
                            {this.props.title !== "" ?
                                <div className="mb-3">{this.props.title}</div>
                                :
                                null}
                            {data && data.length > 0 ?
                                <div>
                                    <div className={`row mn-movies-grid ${this.props.movieCardContainerClass}`}>
                                        {
                                            data.map((movie, index) => {
                                                return (
                                                    <MovieCard
                                                        key={movie.ID ? movie.ID : index}
                                                        movie={this.props.screen === "SEARCH" ? movie._source : movie}
                                                        movieClick={this.props.handleMovieClick}
                                                        className="mb-30"
                                                        subscription={movie._source ? movie._source.Contract.Subscription : null}
                                                        type={this.props.type}
                                                        {...this.props}
                                                    />
                                                )
                                            })

                                        }
                                    </div>
                                </div>
                                :
                                <DataNotFound
                                    height="400px"
                                    text={message}
                                />
                            }
                        </div>
                    </section>
                </InfiniteScroll>
            </RenderScreen >
        )
    }
}

CardsList.defaultProps = {
    lastEvaluatedKey: "",
    isError: "",
    showResultsStrip: false,
    tab: "",
    type: "",
    screen: "",
    notFoundMessage: "No Movies Found!",
    title: "",
    sectionClassName: "mn-movies py-4 mt-2",
    movieCardContainerClass: ""
}

CardsList.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    title: PropTypes.string,
    notFoundMessage: PropTypes.string,
    isError: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.array
    ])
};

export default withRouter(withAppConfig(CardsList));

