import { authenticatedHeader, customHeader, unAuthenticatedHeader } from "../helpers/ConfigHelpers";

export default {
    applicationName: "LaughOutLoud",
    applicationLogo: "",
    applicationFavicon: "",
    s3: { //Amazon storage Service
        REGION: "eu-central-1", //Region of S3 Bucket
        BUCKET: "dev-lol-service-admin-bucket" //S3 Bucket Name 
    },
    cognito: { //Amazon Authentication and Access Provider Service 
        REGION: "eu-central-1",  //Region of cognito
        USER_POOL_ID: "eu-central-1_ktpyYnEpj", //Id for User pool - (user directory)
        APP_CLIENT_ID: "4uiqdglkmbv471pernaf79v561", // Id for Cognito 
        IDENTITY_POOL_ID: "eu-central-1:00696f11-e02c-48fc-88ed-1bcf9327ce2d" //Id for Identity Pool (provide AWS credentials for users access)
    },
    service: {
        stage: "dev", //development - being used as Prefix in Cache
        service: "lol", //service name - being used as Prefix in Cache
    },
    oauth: {
        domain: "lol.auth.eu-central-1.amazoncognito.com", // Domain name
        scope: [],// Authorized scopes
        redirectSignIn: "https://www.laughoutloud.app", // Callback URL for facebook and Google
        redirectSignOut: "https://www.laughoutloud.app",// Sign out URL for facebook and Google
        responseType: "code",// 'code' for Authorization code grant, // 'token' for Implicit grant
        options: {  // optional, for Cognito hosted ui specified options
            AdvancedSecurityDataCollectionFlag: true // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        }
    },
    notifications: { //id for fcm notifications
        apiKey: "AIzaSyA3akt-DL57vWR1mqaV6j4ZyIjgVfJvjKw",
        authDomain: "laughoutloud-44d6a.firebaseapp.com",
        projectId: "laughoutloud-44d6a",
        storageBucket: "laughoutloud-44d6a.appspot.com",
        messagingSenderId: "248828728527",
        appId: "1:248828728527:web:bcccf3e36987da3dff93ba",
        measurementId: "G-T1SH8RRFP4",
        databaseURL: 'https://laughoutloud-44d6a-default-rtdb.firebaseio.com'     
    },
    playerConfiguration: {
        bitmovinPlayerId: "8234179d-9827-4733-8746-f4605178baf7", //Player key for Bitmovin Player
        chromecastReceiverID: "", //Muvinow chromecast revciever ID
        certificateURL: "" //Certificated Url used in FairPlay DRM configuration for bitmovin Player
    },
    cache: {  //Aws Cache, two generic cache timings long and short
        shortCache: 2, //2min 
        longCache: 5, //5min
    },
    customSettings: {
        isStuffpixEnabled: false
    },
    apiGateway: { //endpoints for different API
        endpoints: [
            {
                name: "BannerAPI",
                endpoint: "https://dm991jhapf.execute-api.eu-central-1.amazonaws.com/dev",
                custom_header: customHeader  //custom Header handles both Authenticated and UnAuthenticated requests
            }
            ,
            {
                name: "MoviesAPI",
                endpoint: "https://mxn29kin82.execute-api.eu-central-1.amazonaws.com/dev",
                custom_header: customHeader
            },
            {
                name: "AuthAPI",
                endpoint: "https://lxjhvayp21.execute-api.eu-central-1.amazonaws.com/dev",
                custom_header: authenticatedHeader  //authenticatedHeader works only in case of Authenticated requests
            },
            {
                name: "ConfigAPI",
                endpoint: "https://83wlmhogc3.execute-api.eu-central-1.amazonaws.com/dev",
                custom_header: customHeader
            },
            {
                name: "UserAPI",
                endpoint: "https://8y7p4rrcja.execute-api.eu-central-1.amazonaws.com/dev",
                custom_header: authenticatedHeader
            },
            {
                name: "OrdersAPI",
                endpoint: "https://m0oqlpazu9.execute-api.eu-central-1.amazonaws.com/dev",
                custom_header: authenticatedHeader
            },
            {
                name: "TicketsAPI",
                endpoint: "https://kmf1quj1g7.execute-api.eu-central-1.amazonaws.com/dev",
                custom_header: authenticatedHeader
            },
            {
                name: "SearchAPI",
                endpoint: "https://search-dev-lol-movies-rooms-46qmdebaf3iyeulzxqc2djjejq.eu-central-1.es.amazonaws.com/",
                custom_header: unAuthenticatedHeader  //unAuthenticatedHeader works only in case of unAuthenticated requests
            },
            {
                name: "DRMToken",
                endpoint: "https://i634ornsvl.execute-api.eu-central-1.amazonaws.com/dev",
                custom_header: authenticatedHeader
            },
            {
                name: "NotificationsAPI",
                endpoint: "https://17kdi9d3cd.execute-api.eu-central-1.amazonaws.com/dev",
                custom_header: authenticatedHeader
            }
        ]
    }
};
