import React, { Component, Fragment, useState, useEffect, useContext } from "react";
import Slider from "react-slick";
import MuvinowBanner from "./MuvinowBanner";
import StuffpixBanner from "./StuffpixBanner";
import config from "../../../config/config";
import { sliderConfig } from "../helpers";
import { importMoment } from "../../../helpers/GeneralHelpers";
import { calculateProgressBar } from "../../../helpers/MoviesHelper";
import SectionHeader from "../../Home/components/SectionHeader";
import { WATCH, MOVIE } from '../../../constants/routes';
import { MOVIE_VIDEO, CALLBACK } from "../../../constants/constants";
import RentalLocks from '../../../components/MovieCard/components/RentalLocks';

function BannerList(props) {
    let moment = null;
    const [autoplay, setAutoPlay] = useState(true)
    const [firstIndexVideoAutoPlay, setFirstIndexVideoAutoPlay] = useState(false)
    let slickRef = null;
    let isPlaying = false;
    let player = null;
    const { movie, showCardHoverIcons, showComingSoonTag } = props;

    useEffect(() => {
        importMoment(false);
        let firstBanner = {};
        if (props.bannerList) {
            firstBanner = props.bannerList[0];
            if (firstBanner.MediaType.toLowerCase() === 'video') {
                setFirstIndexVideoAutoPlay(true)
                setTimeout(() => {
                    if (slickRef) {
                        slickRef.slickPause();
                    }
                }, 500)
            }
        }
    }, []);

    const playPauseVideo = (control, index, list) => {
        let className = "banner-video-" + index;
        let video = document.getElementsByClassName(className);
        if (index === list.length - 1) {
            video = video[1];
        } else {
            video = video[0];
        }
        if (video) {
            if (control === "play") {
                let promise = video.play();
                isPlaying = true;
                video.addEventListener('ended', handleVideoEnd, false);
                if (promise !== undefined) {
                    promise.then(() => {
                    }).catch((err) => {
                    });
                }
            } else {
                slickRef.slickPlay();
                isPlaying = false;
                video.pause();
            }
        } else {
            slickRef.slickPlay();
            isPlaying = false;
        }
    };

    const handleVideoEnd = () => {
        slickRef.slickPlay();
        isPlaying = false;
    };

    const pauseVideo = (nextIndex, list) => { //afterChange
        if ((list[nextIndex].MediaType !== undefined)) {
            if (list[nextIndex].MediaType !== "Image" && list[nextIndex].MediaType !== "image") {
                slickRef.slickPause();
                playPauseVideo("play", nextIndex, list);
            }
        }
    }

    const playVideo = (nextIndex, list) => { //beforeChange
        if (isPlaying) {
            playPauseVideo("pause", nextIndex, list);
        }
    }

    const handlePlayClick = (e) => {
        e.preventDefault();
        let video = document.getElementById("banner-video");
        video.play();
    };

    const initSliderSettings = (list) => {
        let settings = sliderConfig(list);
        let sliderSettings = {
            ...settings,
            autoplay: true, //this.state.autoplay,
            beforeChange: (oldIndex, newIndex) => {
                playVideo(newIndex, list)
            },
            afterChange: (newIndex) => {
                pauseVideo(newIndex, list)
            }
        };
        return sliderSettings;
    };
    /*
    Changes states after the video on first banner completes
     */

    const onFirstBannerVideoEnd = () => {
        if (firstIndexVideoAutoPlay) {
            setFirstIndexVideoAutoPlay(false);
            slickRef.slickPlay();
        }
    };

    const { bannerList, myList } = props;
    return (
        <Fragment>
            <div>
                <Slider id="banner-slick" ref={slickRef1 => {
                    slickRef = slickRef1
                }} {...initSliderSettings(bannerList)} >
                    {bannerList.map((banner, index) => {
                        return (
                            (banner.MediaType && banner.MediaType.toLowerCase() === "image") ?
                                <div key={banner.ID} className="mn-banner-item">
                                    <div className="mn-banner-thumb img-bg"
                                        style={{ backgroundImage: `url(${banner.BannerPath})` }}>
                                        {config.customSettings.isStuffpixEnabled ? (
                                            <StuffpixBanner banner={banner} myList={myList}
                                                updateMyList={props.updateMyList} />
                                        ) : (
                                                <MuvinowBanner banner={banner} myList={myList}
                                                    updateMyList={props.updateMyList} />
                                            )}
                                    </div>
                                </div>
                                :
                                <div key={banner.ID} className="mn-banner-item">
                                    <div className="mn-banner-thumb">
                                        <video
                                            id={"banner-video-" + index}
                                            ref={player1 => {
                                                player = player1;
                                            }}
                                            className={"banner-video " + "banner-video-" + index}
                                            muted
                                            preload="metadata"
                                            poster={banner.PreviewImage && banner.PreviewImage}
                                            autoPlay={firstIndexVideoAutoPlay}
                                            onEnded={onFirstBannerVideoEnd}
                                        >
                                            <source src={banner.BannerPath} type="video/mp4" />
                                        </video>
                                        {config.customSettings.isStuffpixEnabled ? (
                                            <StuffpixBanner banner={banner} myList={myList}
                                                updateMyList={props.updateMyList} />
                                        ) : (
                                                <MuvinowBanner banner={banner} myList={myList}
                                                    updateMyList={props.updateMyList} />
                                            )}
                                    </div>
                                </div>
                        )
                    })}
                </Slider>
                {props.resumeSection && props.resumeSection && props.resumeSection.length > 0 &&
                    <section className="mn-continue-watch">
                        <div className="d-flex justify-content-between">
                            <SectionHeader ID={props.watchedSection.ID} Name={props.watchedSection.Name}
                            Type={props.watchedSection.Type}
                            // {...props.resumeSection} 
                            />
                            {/* <div className=""><h5 className="text-primary mb-4"><span className="font-weight-light">Continue</span> Watching </h5></div>
                          <div><a className="btn btn-sm btn-link">View More <i className="la la-arrow-right ml-2"></i></a></div> */}
                        </div>
                        <div className="mn-watch-content">
                            {props.resumeSection.map((movie, index) => {
                                return (
                                    <a href={`/movie/${movie.MovieID}`} className="mn-watch-item"
                                        key={'continue-watch-' + index}>
                                        <div className="watch-item-top">
                                            <div className="mn-figure mn-watch-figure">
                                                <div className="mn-thumb img-bg img-16x9"
                                                    style={{ backgroundImage: 'url(' + movie.MoviePreviewImage + ')' }}></div>
                                            </div>
                                            <div className="mn-thumb-overlay">
                                                <span className="btn-play">
                                                    <i className="la la-play"></i></span>
                                            </div>
                                            <div className="progress mn-progress">
                                                <div className="progress-bar bg-primary"
                                                    style={{width: `${calculateProgressBar(movie.MoviePlayDuration, movie.TotalDuration)}%` }}
                                                    ></div>
                                            </div>
                                        </div>
                                        <div className="watch-item-caption text-white-70 text-truncate">
                                            {movie.MovieTitle}
                                        </div>
                                    </a>
                                )
                            })}

                        </div>
                    </section>
                }
            </div>
        </Fragment>
    )
}
export default BannerList;
