import React, { Component } from "react";
import PropTypes from 'prop-types';
import { convertSecToDuration } from "../../../helpers/GeneralHelpers";
const relatedMoviesLimit = 5;

class PlayerSuggestions extends Component {

    renderTags = (movie) => {
        return (
            (movie.isPurchased_ && !movie.isExpired_ && movie.purchaseType_) ?
                <span className={`mn-tag ${movie.purchaseType_}`}>
                    <i className="la la-play-circle mr-2"></i>
                    {movie.purchaseType_}
                </span>
                :
                null
        )
    }

    render() {
        const { dataList, showRelatedTags } = this.props;
        return (
            (dataList && dataList.length > 0) ?
                dataList.map((movie, index) => {
                    if (index < relatedMoviesLimit) {
                        return (
                            <div key={index} className="col-2">
                                <div className="sg-media-item" onClick={(e) => this.props.handleMovieClick(e, movie.ID, movie)}>
                                    <a href="javascript:void(0)" className="d-block text-white">
                                        <div className="mn-figure mb-3">
                                            <div className="mn-thumb-overlay">
                                                <span className="btn-play"><i className="la la-play"></i></span>
                                            </div>
                                            <div className="mn-thumb img-bg img-16x9" style={{ backgroundImage: `url(${movie.PreviewImage})` }}></div>
                                            {showRelatedTags && this.renderTags(movie)}
                                        </div>
                                        <div className="mn-excerpt">
                                            <div className="row gutter-5">
                                                <div className="col-9">
                                                    <div className="text-truncate mb-2">{movie.Title}</div>
                                                </div>
                                                <div className="col-3">
                                                    {(movie.PlayDuration || movie.PlayDuration === 0) && <div className="text-white-50 mb-2 text-right">{convertSecToDuration(movie.PlayDuration, true)}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        )
                    }
                })
                :
                null
        );
    }
}

PlayerSuggestions.defaultProps = {
    showRelatedTags: false
}

PlayerSuggestions.propTypes = {
    showRelatedTags: PropTypes.bool.isRequired,
    dataList: PropTypes.array.isRequired,
    handleMovieClick: PropTypes.func.isRequired
};
export default PlayerSuggestions;