import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import resendConfirmationCode from "../../../services/user/ResendConfirmation";
import { withAppConfig } from "../../../context/ConfigContext";
import config from "../../../config/config";

const ConfirmationMessage = props => {
  const [loading, setLoading] = useState(false);
  const onClick = () => {
    setLoading(true);
    let email = props.email;

    if(props && props.location && props.location.state && props.location.state.email){
      email = props.location.state.email;
    }

    resendConfirmationCode(
      (res, err) => {
        let [type, message] = ["showErrorAlert", ""];
        if (err) {
          message = err;
        } else {
          const {
            ApplicationName = config.applicationName
          } = props.appSettings;
          type = "showSuccessAlert";
          message = `Please Visit your Email Address to verify your ${ApplicationName} account!`;
          if (props.location.pathname !== "/login") {
            props.history.push("/login");
          }
        }
        props.showAlert(type, message);
        setLoading(false);
      },
      { email: email }
    );
  };

  let { state } = props.location;
  if (state && state.showConfirmation) {
    return (
      <Fragment>
        <Loader isLoading={loading} />
        <AfterSignUp onClick={onClick} email={state.email} />
      </Fragment>
    );
  }

  if (props.showCofirmation) {
    return (
      <Fragment>
        <Loader isLoading={loading} />
        <NotConfirmed onClick={onClick} />
      </Fragment>
    );
  }

  return null;
};

const AfterSignUp = props => {
  return (
    <div className="alert alert-success" role="alert">
      <p>
        Please check your email <strong>{props.email}</strong> to verify your
        account.
      </p>
      <p>
        Haven't received Email ? &nbsp;
        <a onClick={props.onClick} className="text-primary">
          Resend
        </a>
      </p>
    </div>
  );
};

const NotConfirmed = props => {
  return (
    <div className="alert alert-success" role="alert">
      <p>Please verify your account via Verification Email</p>
      <p>
        Haven't received Email ? &nbsp;
        <a onClick={props.onClick} className="text-primary">
          Resend
        </a>
      </p>
    </div>
  );
};

const Loader = ({ isLoading }) => {
  if (isLoading) {
    return (
      <span className="loader-spiral mn-white mn-transparent">
        <span className="spiral-inn" />
      </span>
    );
  }

  return null;
};
export default withRouter(withAppConfig(ConfirmationMessage));
