import React from "react";
import { Modal } from "reactstrap";
import PropTypes from 'prop-types';
import {withAppConfig} from "../../context/ConfigContext";
import {
  convertSecToDuration,
  ping,
  getCurrentUserId,
  getDataFromLocalStorage, importMoment, isEmpty,
  setDocumentTitle
} from "../../helpers/GeneralHelpers";
import {EST, PURCHASE_LISTING, STORED_MY_PURCHASES} from "../../constants/constants";
import _filter from "lodash/filter"
import {checkIsMovieExpired } from "../../helpers/MoviesHelper";
import Loader from "../../components/Loader/Loader";
import { fetchFullPurchaseList } from "../../services/user/GetPurchaseList";

class VoucherModal extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      data:[]
    }
    this.importMoment = importMoment.bind(this);
  }
  componentDidMount() {
    this.initializeState();
  }

  initializeState() {
    setDocumentTitle(PURCHASE_LISTING);
      if(this.props.isAuthenticated){
      getCurrentUserId().then((userId) => {
        this.CURRENTUSER_ID = userId;
        this.importMoment().then(async (moment) => {
          this.SERVERTIME = await ping();
          this.getPurchasesData(userId);
        })
      });
      }

  }

  getPurchasesData = async (userId) => {
    let purchaseList = getDataFromLocalStorage(STORED_MY_PURCHASES, userId);
    if (isEmpty(purchaseList)) {
      purchaseList = await fetchFullPurchaseList(userId)
    }
    purchaseList.forEach((movie) => {
      movie["isExpired_"] = checkIsMovieExpired(this.moment, movie, this.SERVERTIME);
    });
    //updatedData
    this.filterVoucherSpecificPurchase(purchaseList)
  };

  // fetchPurcashedData() {
  //   let purchaseLimit = 50;
  //   let path = `/purchaseHistory?ExclusiveStartKey=&Limit=${purchaseLimit}`;
  //   return fetchDataRecursively("OrdersAPI", "purchaseHistory", path, [], FETCH_PURCHASELIST, "").then((finalData) => {
  //     storeDataInLocalStore(STORED_MY_PURCHASES, finalData, this.CURRENTUSER_ID,"No expiry");
  //     return finalData;
  //   })
  // }
  
  filterVoucherSpecificPurchase = (purchaseList)=>{
      let filteredPurchases = _filter(purchaseList,["Voucher",this.props.voucherCode]);
      this.setState({data: filteredPurchases});
  };
  
  handleMovieClick = (e,movieID) => {
    e.preventDefault();

    this.props.history.push(`/movie/${movieID}`)
  };
  renderPurchaseList = () => {
    const { appTranslations :tr } = this.props;
    return this.state.data.map((order,index) => {
      return(
      <div key={index} className="mn-purchase-item mb-4">
        <div className="mb-2 text-white-50">{this.moment(order.CreatedAt).format("YYYY-MM-DD")}</div>
        <div className="list-group">
          <div className="list-group-item list-group-item-dark flex-column align-items-start p-3">
            <div className="media font-12">
              <div className="align-self-start mr-3 mn-purchase-thumb">
                <div className="img-bg img-1x15" style={{ backgroundImage: `url(${order.MoviePosterURL})` }}></div>
              </div>
              <div className="media-body">
                <div className="text-white-50 mb-2">{tr.order_id} # {order.ID}</div>
                <h5 className="text-white"><a className="link-white" href="javascript:void(0)" onClick={(e) => this.handleMovieClick(e, order.MovieID)}>{order.MovieTitle}</a><span className="badge px-2 py-1 mx-1 badge-light font-10">{order.ContractTier.DisplayName}</span></h5>
                <div className="mn-points mb-3">
                  {order.MoviePlayDuration ? <span>{convertSecToDuration(order.MoviePlayDuration, false, false, true)}</span> : null}
                  {order.MovieGenre &&
                  <span>{order.MovieGenre ? order.MovieGenre.join(", ") : ""}</span>}
                </div>
                {/*{order.MovieMeta &&*/}
                {/*order.MovieMeta.Actors &&*/}
                {/*<div className="mb-4">*/}
                  {/*<strong className="font-weight-bold mr-2">Starring : </strong>*/}
                  {/*{order.MovieMeta.Actors.join(", ")}*/}
                {/*</div>*/}
                {/*}*/}
                <div>
                  {!order.isExpired_ ?
                      <span className="badge badge-outline-primary px-2 font-12 font-weight-normal">{order.PurchaseType === EST ? tr.owned : tr.rented}</span>
                      :
                      <span className="badge badge-outline-primary px-2 font-12 font-weight-normal ml-1">{(order.ValidUntil === "N-A" && !order.isExpired_) ? "" : order.isExpired_ ? tr.expired : ""}</span>
                  }{/*(item.ValidUntil === "N-A") && "New"*/}
                </div>
                <div className="d-md-flex justify-content-between">
                  <div className="mt-3 text-success"> {(this.moment && order.CreatedAt) && `${this.props.appTranslations.purchased_on}: ${this.moment(order.CreatedAt).format("YYYY MMMM Do, h:mm:ss a")}`}</div>
                  {!order.isExpired_ && <div className="mt-3 text-danger"> {(this.moment && order.ValidUntil) && `${tr.available_until} : ${(order.ValidUntil === "N-A") ? order.RentMaxValidity ? this.moment(order.RentMaxValidity).format("YYYY MMMM Do, h:mm:ss a") : tr.new || "New" : this.moment(order.ValidUntil).format("YYYY MMMM Do, h:mm:ss a")}`}</div>}
                </div>
              </div>
            </div>
            <div className="pt-3 mt-3 border-top border-dark">
              <div className="row gutter-5 text-white font-weight-bold">
                <div className="col-6">
                  <div className="font-weight-normal">{tr.amount_redeemed || "Amount Redeemed" } </div>
                </div>
                <div className="col-6 text-right">${order.Discount}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      );
    })
  };
  render() {
    const { appTranslations :tr } = this.props;
  let purchaseList = this.renderPurchaseList();
    return (
      <Modal
        isOpen={this.props.isOpen}
        //backdrop={this.state.modalBackDrop}
        toggle={this.props.handleBackDropClick}
        size="xl"
        centered
        modalClassName="modal-dark mn-reward-modal"
      >
        <div className="modal-content text-white-50">
          <div className="modal-header">
            <div>
              {tr.purchased_from} <span className="font-weight-bold mx-2 text-white">{this.props.voucherCode}</span> {tr.reward_code}
            </div>
            <button className="close" data-dismiss="modal" onClick={this.props.handleBackDropClick}>&times;</button>
          </div>
          <div className="modal-body">

            {!isEmpty(purchaseList) ?
              purchaseList
            :
                <Loader/>
            }
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={this.props.handleBackDropClick}
            >
              {tr.close || "Close"}
              </button>
          </div>
        </div>
      </Modal>
    );
  }
}
export default withAppConfig(VoucherModal);
VoucherModal.propTypes = {
  voucherCode: PropTypes.string.isRequired,
  isOpen:PropTypes.bool.isRequired,
  handleBackDropClick:PropTypes.func.isRequired
};