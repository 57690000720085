import React, { memo } from "react";
import Slider from "react-slick";
import SectionHeader from "./SectionHeader";
import MovieCard from "../../../components/MovieCard/MovieCard";
import { initSliderSettings } from "../../../helpers/MoviesHelper";
import { validCard } from "../helpers";

const Section = memo(({ Items, serverTime, ...section }) => {
  return (
    <section className="mn-movies py-4">
      <div className="container-fluid">
        <SectionHeader {...section}/>
        <Slider
          {...initSliderSettings(section.Type, Items.length)}
          className="row"
        >
          {Items.map(movie => {
            if (validCard(section, movie)) {
              return (
                <MovieCard
                  type={section.Type}
                  key={movie.ID}
                  movie={movie}
                  ServerTime={serverTime}
                  showCardHoverIcons={true}
                />
              );
            }
          })}
        </Slider>
      </div>
    </section>
  );
});

export default Section;
