import React, {Fragment, useContext} from "react";
import { TVOD, EST, PURCHASE, QUERYTIERID } from "../../../constants/constants";
import { getButtonText, getGeoBlock } from "../helpers";
import { withRouter } from "react-router-dom";
import {
  CHECKOUT,
  SERVICE_UNAVAILABLE,
  REDIRECT_URL
} from "../../../constants/routes";
import { withAppConfig } from "../../../context/ConfigContext";
import {AuthContext, withAuth} from "../../../context/AuthContext";
import {checkIsMovieSubscribed} from "../../../helpers/MoviesHelper";
const PurchaseButtons = ({ banner, isAuthenticated, ...props }) => {
  const UserContext = useContext(AuthContext);

  const onClick = (e, type, params) => {
    // used in moviesDetail and Home banner
    e.preventDefault();
    e.stopPropagation();
    if (!isAuthenticated) {
      let teirId = params && params[0] ? params[0].ID : "";
      props.history.push({
        pathname: `${CHECKOUT}${banner.MovieID}`,
        search: `?${PURCHASE}=${type}&${QUERYTIERID}=${teirId}`
      });
    }
    if (getGeoBlock(props.configurations)) {
      if (isAuthenticated) {
        props.history.push(`${SERVICE_UNAVAILABLE}`);
      } else {
        props.history.push(`${REDIRECT_URL}${props.location.pathname}`);
      }
    } else {
        if (banner.MovieID) {
          props.history.push(`/movie/${banner.MovieID}`);
        }

      //props.onPurchaseClick(type, params);
    }
  };

  let [rentButtonText, ownButtonText, subscriptionButtonText] = getButtonText(banner);
  let buttonClass = "btn btn-primary btn-large font-weight-bold mr-3 mb-3";

  if (banner.isAvailableForSubscription && checkIsMovieSubscribed(UserContext.subscriptions, banner)) {
    return (
      <Fragment>
        <a onClick={()=>{
            props.history.push(`/watch/movie/${banner.MovieID}`);
          }}  className={buttonClass}>
          {"Play Movie"}
        </a>
      </Fragment>
    )
  }


  if (banner.isAvailableForSubscription) {
    return (
        <Fragment>
          <a
              onClick={props.onSubscribeClick}
              className={buttonClass}
          >
            {subscriptionButtonText }
          </a>
        </Fragment>
    )
  }



  return (
    <Fragment>
      {banner.isAvailableForRent ? (
        <a
          onClick={e => {
            onClick(e, TVOD, banner.RentParams);
          }}
          className={buttonClass}
        >
          {rentButtonText}
        </a>
      ) : null}
      {banner.isAvailableForBuy ? (
        <a
          onClick={e => onClick(e, EST, banner.BuyParams)}
          className={buttonClass}
        >
          {ownButtonText}
        </a>
      ) : null}

    </Fragment>
  );
};

export default withRouter(withAppConfig(withAuth(PurchaseButtons)));
