import React, { memo, useState } from "react";
import Form from "./Form";
import { withAppConfig } from "../../../../context/ConfigContext";
import config from "../../../../config/config";
import { canRedeemCollectionRoom } from "../../../../services/checkout/canRedeem";

const VoucherContainer = memo(props => {
  const [verifyingVoucher, setLoading] = useState(false);
  const onSubmitVoucher = voucher => {
    let redeemParams = {
      Voucher: voucher,
      TotalPrice:props.checkoutData.Price,
      Quality: props.selectedQuality,
      Path: props.Type
    };
    if (props.Type === "collection" || props.Type === "screening_room") {
      redeemParams["RoomID"] = props.ID;
    } else {
      redeemParams["MovieID"] = props.ID;
    }
    setLoading(true);
    canRedeemCollectionRoom((response, err) => {
      if (!err) {
        props.onRedeem(response);
        const { ApplicationName = config.applicationName } = props.appSettings;
        props.showAlert(
          "showSuccessAlert",
          `Your ${ApplicationName} Reward Code was applied`
        );
      } else {
        props.showAlert("showErrorAlert", err);
      }
      setLoading(false);
    }, redeemParams);
  };

  return (
    <div className="mn-cart-row row align-items-center voucher-input-row">
      <Description ApplicationName={props.ApplicationName} />
      <div className="mn-cart-cell col-12 col-md-4">
        <div className="dropdown">
          <Form
            onSubmitVoucher={onSubmitVoucher}
            verifyingVoucher={verifyingVoucher}
          />
        </div>
      </div>
    </div>
  );
});

const Description = memo(props => {
  return (
    <div className="mn-cart-cell col-12 col-md-6 text-left text-md-right">
      Using a {props.ApplicationName} Reward code ?
    </div>
  );
});

export default withAppConfig(VoucherContainer);
