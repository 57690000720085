import React, { memo } from "react";
import Slider from "react-slick";
import SectionHeader from "./SectionHeader";
import { initSliderSettings } from "../../../helpers/MoviesHelper";
import CollectionCard from "../../../components/RoomCollection/CollectionCard";

const CollectionsSection = memo(({ Items: collections, ...section }) => {
  return (
    <section className="mn-movies py-4">
      <div className="container-fluid">
        <SectionHeader {...section} />
        <Slider
          {...initSliderSettings(null, collections.length)}
          className="row gutter-10"
        >
          {collections.map((collection,index) => {
            if (collection.Status === "active") {
              return <CollectionCard key={index} collection={collection} />;
            }
          })}
        </Slider>
      </div>
    </section>
  );
});

export default CollectionsSection;
