import React from "react";
import API from "@aws-amplify/api";
import _filter from "lodash/filter";
import _includes from "lodash/includes";
import _find from "lodash/find";
import _sortBy from "lodash/sortBy";
import _orderBy from "lodash/orderBy";
import _uniqBy from "lodash/uniqBy";
import _remove from "lodash/remove";
import {
  storeDataInLocalStore,
  getDataFromLocalStorage,
  getCurrentUserId,
  uniqueBy,
  getObjectFromArray,
  isEmpty,
  removeObjectFromData,
  convertSecToDuration,
  ping
} from "./GeneralHelpers";
import { PrevArrow, NextArrow } from "../components/SlickButtons/";
import {
  REDIRECT_URL,
  CHECKOUT,
  SERVICE_UNAVAILABLE
} from "../constants/routes";
import {
  EST,
  purchaseLimit,
  ExpiringStripTime,
  OWNED,
  RENTED,
  STORED_MY_WATCHED_LIST,
  STORED_MY_PURCHASES,
  FETCH_WATCHLIST,
  FETCH_PURCHASELIST,
  STORED_HEADER_SECTIONS,
  NO_EXPIRY,
  RESUME,
  WATCHED,
  PURCHASE_HISTORY,
  FETCH_ALL_NOTIFICATIONS,
  TVOD,
  TEMPORARY_ORDER_ID,
  STORED_SECTIONS_DATA,
  PURCHASE,
  QUERYTIERID
} from "../constants/constants";
import config from "../config/config";
import { getSettingsFromConfig } from "./ConfigHelpers";
import { fetchFullPurchaseList } from "../services/user/GetPurchaseList";
const QUALITY = "quality";
const PRICE = "price";
const TIERID = "teirId";
const CURRENCYCODE = "currencyCode";
const DISPLAYNAME = "displayName";
const EXPIRED_DURATION = -3600;

export function checkIsMovieLikedPurchased(
  data,
  movieId,
  key,
  checkliked = false
) {
  let movieLiked = false;
  if (checkliked) {
    let isLikedObject = _find(data, [key, movieId]);
    if (isLikedObject) {
      movieLiked = true;
      return movieLiked;
    }
  } else {
    let purchasedObject = _find(data, movie => {
      return (
        movie[key] === movieId &&
        (!movie.Status || movie.Status !== "inactive") &&
        (!movie.PaymentStatus || movie.PaymentStatus === "COMPLETED")
      );
    });
    if (purchasedObject) {
      movieLiked = true;
      return movieLiked;
    }
  }
  return movieLiked;
}

export function getActiveMovieOrderObject(purchaseList, key, movieId) {
  let movieOrders = _filter(purchaseList, movie => {
    return (
      movie[key] === movieId &&
      (!movie.Status || movie.Status !== "inactive") &&
      (!movie.PaymentStatus || movie.PaymentStatus === "COMPLETED")
    );
  });
  if (movieOrders.length === 1) {
    return movieOrders[0];
  } else if (movieOrders.length === 0) {
    return {};
  } else {
    let sortedArray = _orderBy(movieOrders, ["CreatedAt"], ["desc"]); //ValidUntil
    if (sortedArray) {
      //which is obvious but __
      return sortedArray[0];
    }
  }
}

export function checkIsMovieSubscribed(subscriptions, movie) {
  let isSubscribed = _find(subscriptions, subscription => {
    if (
      subscription &&
      subscription.ScreeningRoom &&
      subscription.ScreeningRoom.MovieIDs
    ) {
      let id = movie.MovieID || movie.ID;
      if (_includes(subscription.ScreeningRoom.MovieIDs, id)) {
        return true;
      }
    }
    return false;
  });
  return isSubscribed ? true : false; //not to return undefined.
}

export function isMovieCollectionPurchased(purchases, movie) {
  let isCPurchased = _find(purchases, obj => {
    let id = movie.MovieID || movie.ID;
    if (
      obj.MovieID === id &&
      obj.Type === "Child" &&
      obj.Status !== "inactive" &&
      obj.PaymentStatus === "COMPLETED"
    ) {
      return true;
    }
  });
  return isCPurchased ? true : false;
}

export function getActiveRentals(purchaseList) {
  //remove itt!!
  let activeRentals = _orderBy(purchaseList, ["CreatedAt"], ["desc"]);
  activeRentals = _uniqBy(activeRentals, "MovieID");
  return activeRentals;
}

export function filterFullyEpiredRental(purchaseList) {
  //  removed later
  let mypurchases = _filter(purchaseList, function(o) {
    return !o.HideExpiredRental_;
  });
  return mypurchases;
}

export function checkMoviePurchaseType(movie, purchaseList) {
  if (!purchaseList) {
    let type = movie
      ? movie.ContractTier
        ? movie.ContractTier.Type
          ? movie.ContractTier.Type
          : ""
        : ""
      : "";
    return type === EST ? OWNED : RENTED;
  } else {
    //lets see
  }
}

export function storeMyPurchasedMovies(res, userId) {
  let data = [];
  if (res) {
    res.forEach(arr => {
      if (arr) {
        arr.forEach(a => {
          data.push(a);
        });
      }
    });
    //storeDataInLocalStore(STORED_MY_PURCHASES, data, userId);
  }
}

export async function getPurchaseData(userId, fetchfull = false) {
  //used for checking Purchased Status
  
  let purchaseList = getDataFromLocalStorage(STORED_MY_PURCHASES, userId);
  if (isEmpty(purchaseList) || fetchfull === true) {
    purchaseList = await fetchFullPurchaseList();
  }
  return purchaseList;
}

export function decideLikePurchasedStatus(movie) {
  //NOTE = use same names everyWhere
  movie["isPurchased_"] = false;
  movie["purchaseType_"] = "";
  movie["isExpired_"] = true;
  movie["expiringTime_"] = "Expired";
  movie["expiringTimeStrip_"] = 0; //used for color
  movie["showExpiringtimeStrip_"] = false;
  let ID = movie.MovieID || movie.ID;
  let isPurchased = checkIsMovieLikedPurchased(
    this.purchaseList,
    ID,
    "MovieID"
  );
  if (isPurchased) {
    movie["isPurchased_"] = true;
    movie["showExpiringtimeStrip_"] =
      this.MAPING_STATUS_TYPE === RESUME ||
      this.MAPING_STATUS_TYPE === WATCHED ||
      this.MAPING_STATUS_TYPE === PURCHASE_HISTORY ||
      this.MAPING_STATUS_TYPE === FETCH_PURCHASELIST
        ? true
        : false;
    let currentMovie;
    if (this.MAPING_STATUS_TYPE === FETCH_PURCHASELIST) {
      //Only On My Purchases Screen
      currentMovie = movie; //REMAIN SAME
    } else {
      currentMovie = getActiveMovieOrderObject(
        this.purchaseList,
        "MovieID",
        ID
      ); //getObjectFromArray(this.purchaseList, "MovieID", ID); // ****** changing point **** //
    }
    if (
      this.MAPING_STATUS_TYPE === FETCH_WATCHLIST ||
      this.MAPING_STATUS_TYPE === WATCHED
    ) {
      if (currentMovie["HideExpiredRental_"]) {
        movie["HideExpiredRental_"] = true;
      }
    }
    movie["purchaseType_"] = checkMoviePurchaseType(currentMovie);
    let expiringStatus = checkIsMovieExpired(
      this.moment,
      currentMovie,
      this.SERVERTIME,
      true
    );
    if (expiringStatus === "New") {
      movie["expiringTime_"] = "New";
      movie["isExpired_"] = false;
    } else if (expiringStatus === "---") {
      movie["isExpired_"] = false;
      movie["expiringTime_"] = "";
    } else {
      if (expiringStatus <= 0) {
        movie["expiringTimeStrip_"] = ExpiringStripTime; //full red color
        movie["expiringTime_"] = "Expired";
        movie["isExpired_"] = true;
      } else {
        movie["expiringTimeStrip_"] = expiringStatus;
        movie["expiringTime_"] = expiringStatus
          ? `Expiring in ${convertSecToDuration(expiringStatus)}`
          : "";
        movie["isExpired_"] = false;
      }
    }
  }
  return movie;
}

export function initSliderSettings(name, totalSlides = 6) {
  let numberOfSlides = 6;
  let InfiniteScrollFlag = totalSlides > 6;
  let sliderSettings = {
    slidesToShow: numberOfSlides,
    slidesToScroll: numberOfSlides,
    dots: false,
    arrows: true,
    infinite: InfiniteScrollFlag,
    rows: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      // {
      //     breakpoint: 1601,
      //     settings: {
      //         slidesToShow: numberOfSlides - 2,
      //         slidesToScroll: numberOfSlides - 2,
      //     }
      // },
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: numberOfSlides - 1,
          slidesToScroll: numberOfSlides - 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: numberOfSlides - 2,
          slidesToScroll: numberOfSlides - 2
        }
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: numberOfSlides - 4,
          slidesToScroll: numberOfSlides - 4
        }
      }
    ]
  };
  return sliderSettings;
}

export function initSliderRooms(name, totalSlides = 6) {
  let numberOfSlides = 3;
  let InfiniteScrollFlag = totalSlides > 3;
  let sliderSettings = {
    slidesToShow: numberOfSlides,
    slidesToScroll: numberOfSlides,
    dots: false,
    arrows: true,
    infinite: InfiniteScrollFlag,
    rows: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1601,
        settings: {
          slidesToShow: numberOfSlides - 1,
          slidesToScroll: numberOfSlides - 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: numberOfSlides - 2,
          slidesToScroll: numberOfSlides - 2
        }
      }
    ]
  };
  return sliderSettings;
}

export function initSliderSettingsForMoviesGenre(datalength, initialIndex = 0) {
  //Movies Screen
  let sliderSettings = {
    slidesToShow: 8,
    slidesToScroll: 8,
    initialSlide: initialIndex,
    dots: false,
    infinite: datalength >= 8 ? true : false,
    arrows: true,
    // lazyLoad: "ondemand",
    rows: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1601,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
          infinite: datalength >= 6 ? true : false
        }
      },
      {
        breakpoint: 1281,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: datalength >= 5 ? true : false
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: datalength >= 4 ? true : false
        }
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: datalength >= 2 ? true : false
        }
      }
    ]
  };
  return sliderSettings;
}
//remove it later for movie detail!!
export async function handlePurchaseBannerClick(
  e,
  movieId,
  type,
  params,
  FreeWatch = false
) {
  // used in moviesDetail and Home banner
  e.preventDefault();
  e.stopPropagation();
  if (!this.props.isAuthenticated) {
    let teirId = params && params[0] ? params[0].ID : "";
    this.props.history.push({
      pathname: `${CHECKOUT}${movieId}`,
      search: `?${PURCHASE}=${type}&${QUERYTIERID}=${teirId}`
    });
    // return; i think here should be return
  }
  let geoBlock = await getSettingsFromConfig("REGION_POLICY");
  if (geoBlock[0].isGeoBlocked) {
    if (this.props.isAuthenticated) {
      //just show that static page
      this.props.history.push(`${SERVICE_UNAVAILABLE}`);
    } else {
      // will be handled automatically
      this.props.history.push(`${REDIRECT_URL}${this.props.location.pathname}`);
    }
  } else {
    this.setState({
      selectedPurchaseMovieId: movieId,
      selectedPurchaseMovieType: type,
      showPurchaseModel: true
    });
  }
}

export async function handleSubscribeClick(e, FreeWatch = false) {
  // used in moviesDetail and Home banner
  e.preventDefault();
  e.stopPropagation();
  if (!this.props.isAuthenticated) {
    // let teirId = params && params[0] ? params[0].ID : "";
    // this.props.history.push({
    //   pathname: `${CHECKOUT}${movieId}`,
    //   search: `?${PURCHASE}=${type}&${QUERYTIERID}=${teirId}`
    // });
    // return; i think here should be return
  }
  let geoBlock = await getSettingsFromConfig("REGION_POLICY");
  if (geoBlock[0].isGeoBlocked) {
    if (this.props.isAuthenticated) {
      //just show that static page
      this.props.history.push(`${SERVICE_UNAVAILABLE}`);
    } else {
      // will be handled automatically
      this.props.history.push(`${REDIRECT_URL}${this.props.location.pathname}`);
    }
  } else {
    this.setState({
      showPurchaseModel: true
    });
  }
}

export const getLowestQulaity = data => {
  if (!data) {
    return "";
  }
  let lowest =
    data.length > 0
      ? data[0].Price || data[0].Price === 0
        ? data[0].Price
        : ""
      : null;
  let symbol =
    data.length > 0
      ? data[0].CurrencySymbol
        ? data[0].CurrencySymbol
        : ""
      : "";
  data.forEach(obj => {
    if (obj.Price < lowest) {
      lowest = obj.Price;
      symbol = obj.CurrencySymbol;
    }
  });
  return symbol + lowest;
};

export function handleBackDropClick() {
  this.setState({
    showPurchaseModel: false
  });
}

export async function checkPurchaseStatusRequirements() {
  if (!this.SERVERTIME) {
    this.SERVERTIME = await ping();
  }
  if (!this.USER_ID) {
    this.USER_ID = await getCurrentUserId();
  }
  if (!this.moment) {
    await this.importMoment();
  }
}

export const getVideoQualityData = (movie, movieId, type) => {
  let qualityArray = [];
  if (type) {
    let Params = type === EST ? "BuyParams" : "RentParams";
    if (movie[Params]) {
      //if (type === EST) {
      if (movie[Params].length > 0) {
        movie[Params].forEach(quality => {
          let videoQuality = {};
          const {
            Quality = ``,
            CurrencySymbol = ``,
            DisplayName = ``,
            Price = ``,
            ID = ``,
            ManifestDetails = []
          } = quality;
          videoQuality[QUALITY] = Quality;
          videoQuality[CURRENCYCODE] = CurrencySymbol;
          videoQuality[DISPLAYNAME] = DisplayName;
          videoQuality[PRICE] = Price;
          videoQuality[TIERID] = ID;
          videoQuality["ManifestDetails"] = ManifestDetails[0];
          qualityArray.push(videoQuality);
        });
      }
    }
  }
  let sortedArray = _sortBy(qualityArray, PRICE);
  return sortedArray;
};

export function checkIsMovieFreeWatch(movie, returnObj = false) {
  let isFreeWatch = false;
  if (movie) {
    if (
      movie.isAvailableForRent &&
      movie.RentParams &&
      movie.RentParams.length > 0
    ) {
      let freeVodObj = _find(movie.RentParams, o => {
        return o.FreeWatch === 1;
      });
      if (freeVodObj) {
        isFreeWatch = true;
        freeVodObj["teirType"] = TVOD;
        if (returnObj) {
          returnObj = freeVodObj;
        }
      }
    }
    if (
      !isFreeWatch &&
      movie.isAvailableForBuy &&
      movie.BuyParams &&
      movie.BuyParams.length > 0
    ) {
      let freeVodObj = _find(movie.RentParams, o => {
        return o.FreeWatch === 1;
      });
      if (freeVodObj) {
        isFreeWatch = true;
        freeVodObj["teirType"] = EST;
        if (returnObj) {
          returnObj = freeVodObj;
        }
      }
    }
  }
  return returnObj ? returnObj : isFreeWatch;
}

export function checkIsMovieExpired(
  moment,
  movie,
  rightNowUTC,
  returnDuration = false
) {
  //also check checkMovieExpiryDuration
  if (movie && movie.ValidUntil) {
    if (movie.ValidUntil !== "N-A") {
      try {
        let currentTime = rightNowUTC
          ? moment(rightNowUTC)
          : moment()
              .utc()
              .format();
        let expires = moment(movie.ValidUntil);
        let isExpired = currentTime.isAfter(expires);
        let duration = moment.duration(expires.diff(currentTime));
        let remainingTimeInSec = duration.asSeconds();
        return returnDuration ? remainingTimeInSec : isExpired;
      } catch (e) {
        return returnDuration ? "---" : false;
      }
    } else if (movie.ValidUntil === "N-A") {
      let movieExpired = false;
      if (movie.RentMaxValidity) {
        let newCurrentTime = moment(rightNowUTC);
        let RentMaxValidity = moment(movie.RentMaxValidity);
        movieExpired = newCurrentTime.isAfter(RentMaxValidity);
      }
      return returnDuration ? (movieExpired ? -1 : "New") : movieExpired; //"---" replaced to -1
    }
  }
  return returnDuration ? "---" : false;
}

export function checkMovieExpiryDuration(moment, movie, rightNowUTC) {
  if (movie !== null) {
    if (movie.ValidUntil !== undefined && movie.ValidUntil !== null) {
      if (movie.ValidUntil === "N-A") {
        return "New";
      } else {
        try {
          let expires = moment(movie.ValidUntil);
          let currentTime =
            rightNowUTC !== null
              ? moment(rightNowUTC)
              : moment()
                  .utc()
                  .format();
          let duration = moment.duration(expires.diff(currentTime));
          let remainingTimeInSec = duration.asSeconds();
          return remainingTimeInSec;
        } catch (e) {
          return "---";
        }
      }
    } else {
      //undefined
      return "---";
    }
  }
}

export function showExpiredAlert(moment, movie, rightNowUTC, tr) {
  let duration = checkMovieExpiryDuration(moment, movie, rightNowUTC);
  if (duration <= 0 && duration > EXPIRED_DURATION) {
    this.setState({
      alertMessage:
        tr.your_movie_purchase_has_expired ||
        "Your Movie Purchase has Expired!",
      showErrorAlert: true
    });
    this.disableAlert("showErrorAlert");
  }
}

export function calculateProgressBar(total, watched) {
  return (watched / total) * 100;
}

export function updateWatchHistoryPlayDuration(
  movieId,
  userId,
  watchedDuration
) {
  let watchList = getDataFromLocalStorage(STORED_MY_WATCHED_LIST, userId);
  if (!isEmpty(watchList)) {
    let movie = getObjectFromArray(watchList, "MovieID", movieId);
    if (movie) {
      movie["TotalDuration"] = watchedDuration;
      let udpatedData = removeObjectFromData(watchList, "MovieID", movieId);
      udpatedData.unshift(movie);
      storeDataInLocalStore(
        STORED_MY_WATCHED_LIST,
        udpatedData,
        userId,
        NO_EXPIRY
      );
    }
  }
}

export function removeWatchItemFromLocal(movieId, userId) {
  let watchList = getDataFromLocalStorage(STORED_MY_WATCHED_LIST, userId);
  if (!isEmpty(watchList)) {
    let updatedWatchList = removeObjectFromData(watchList, "MovieID", movieId);
    storeDataInLocalStore(
      STORED_MY_WATCHED_LIST,
      updatedWatchList,
      userId,
      NO_EXPIRY
    );
  }
}

export function localWatchHistoryItems(userId) {
  let Items = [];
  let storedwatchList = getDataFromLocalStorage(STORED_MY_WATCHED_LIST, userId);
  if (storedwatchList && !isEmpty(storedwatchList)) {
    Items = uniqueBy(storedwatchList, "MovieID");
  }
  return Items;
}

export async function updateCacheOnNewPurchaseItem(
  userId,
  orderPurchased,
  LastEvaluatedKey = ``
) {
  let purchaseList = getDataFromLocalStorage(STORED_MY_PURCHASES, userId);
  if (!purchaseList) {
    purchaseList = await fetchFullPurchaseList();
    return purchaseList;
  }
  let path = `/purchaseHistory?ExclusiveStartKey=${LastEvaluatedKey}&Limit=${purchaseLimit}`; //just to get latest purchase
  let headers = { "Content-Type": "application/json" };
  let purchaseData = await API.get("OrdersAPI", path, headers)
    .then(res => {
      if (res.statusCode === 200) {
        LastEvaluatedKey = res.body.LastEvaluatedKey;
        return res.body.Items;
      }
    })
    .catch(() => {
      return [];
    });
  if (!isEmpty(purchaseData)) {
    if (!orderPurchased) {
      orderPurchased = purchaseData[0]; //assuming first element as new Order ---but bad!!
    }
    let purchasedObject = _find(purchaseData, ["ID", orderPurchased.ID]);
    if (purchasedObject) {
      //not found
      purchaseList.unshift(purchasedObject);
      storeDataInLocalStore(
        STORED_MY_PURCHASES,
        purchaseList,
        userId,
        NO_EXPIRY
      );
    } else {
      updateCacheOnNewPurchaseItem(userId, orderPurchased, LastEvaluatedKey);
    }
  }
  return purchaseList;
}

export function updateNewPurchaseValidUntil(movieId, userId, moment) {
  let purchaseList = getDataFromLocalStorage(STORED_MY_PURCHASES, userId);
  if (!isEmpty(purchaseList) && movieId && userId && moment) {
    let order = getActiveMovieOrderObject(purchaseList, "MovieID", movieId);
    if (order) {
      if (order.ValidUntil === "N-A") {
        if (order.Validity) {
          let tempOrder = { ...order };
          let newValidUntil = moment()
            .add(order.Validity, "hours")
            .toISOString();
          _remove(purchaseList, movie => {
            return (
              movie["MovieID"] === movieId &&
              movie.Status === "active" &&
              (movie.PaymentStatus === "COMPLETED" &&
                movie.ValidUntil === "N-A")
            );
          });
          tempOrder["ValidUntil"] = newValidUntil;
          purchaseList.unshift(tempOrder);
          storeDataInLocalStore(
            STORED_MY_PURCHASES,
            purchaseList,
            userId,
            NO_EXPIRY
          );
        }
      }
    }
  }
}

export function getMoviesSortList() {
  let data = [
    { name: "Popularity", value: "Popularity" },
    { name: "Release Date", value: "ReleaseYear" },
    { name: "Recently Added", value: "RecentlyAdded" },
    { name: "A - Z", value: "asceAlphabetic" },
    { name: "Z - A", value: "descAlphabetic" }
  ];
  return data;
}

export function getSectionHeadingStyle(section) {
  let style = {};
  if (
    config.customSettings.isStuffpixEnabled &&
    section &&
    section.ColorCode &&
    typeof section.ColorCode === "string"
  ) {
    style = { color: section.ColorCode };
  }
  return style;
}

export function getTrailerArray(data) {
  const trailerArray =
    data && data.Assets && data.Assets.EncodedTrailer
      ? data.Assets.EncodedTrailer
      : data && data.Assets && data.Assets.Trailer
      ? data.Assets.Trailer
      : [];
  return trailerArray;
}

export function getTrailerStream(data, trailerNumber) {
  const trailerStreamURL =
    data &&
    data.Assets &&
    data.Assets.EncodedTrailer &&
    data.Assets.EncodedTrailer.length > 0
      ? data.Assets.EncodedTrailer[trailerNumber]
      : data &&
        data.Assets &&
        data.Assets.Trailer &&
        data.Assets.Trailer.length > 0
      ? data.Assets.Trailer[trailerNumber]
      : null;
  return trailerStreamURL;
}

export function getSectionsData() {
  let sections = getDataFromLocalStorage(STORED_SECTIONS_DATA);
  if (sections) {
    return sections;
  }
  let init = {
    headers: { "Content-Type": "application/json" },
    body: { Device: "Web" }
  };
  return API.post("MoviesAPI", "/listMovieBySection", init)
    .then(async res => {
      let sections = res.body;
      if (!isEmpty(sections)) {
        let userId = await getCurrentUserId();
        storeDataInLocalStore(
          STORED_SECTIONS_DATA,
          sections,
          userId,
          config.cache.shortCache,
          "m"
        );
        if (config.customSettings.isStuffpixEnabled) {
          await storeDataInLocalStore(
            STORED_HEADER_SECTIONS,
            sections,
            null,
            NO_EXPIRY
          );
        }
        return sections;
      }
    })
    .catch(err => {
      return [];
    });
}

export function addNewWatchItemToLocal(userId, movieData) {
  let newObj = getWatchListObject(movieData);
  let watchList = getDataFromLocalStorage(STORED_MY_WATCHED_LIST, userId);
  if (!watchList) {
    watchList = [];
  }
  watchList.unshift(newObj);
  storeDataInLocalStore(STORED_MY_WATCHED_LIST, watchList, userId, NO_EXPIRY);
}

export function getWatchListObject(movieData = {}) {
  const defaultPlayTime = 0;
  let newObject = {
    ID: TEMPORARY_ORDER_ID,
    MovieGenre: movieData.Genre && movieData.Genre,
    MovieID: movieData.ID && movieData.ID,
    MovieMeta: movieData.Meta && movieData.Meta,
    MoviePlayDuration: movieData.PlayDuration,
    MoviePosterURL: movieData.PosterURL && movieData.PosterURL,
    MovieTitle: movieData.Title && movieData.Title,
    SessionType: "Movie",
    TotalDuration: movieData.TotalDuration
      ? movieData.TotalDuration
      : defaultPlayTime
  };
  return newObject;
}

export function getRoomDuration(duration) {
  switch (duration) {
    case "yearly":
      return "1 year";
    case "monthly":
      return "30 days";
    case "quarterly":
      return "3 months";
  }
}
