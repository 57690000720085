import React from "react";
import PropTypes from "prop-types";
import { withAppConfig } from "../../../context/ConfigContext";

function ViewMore({ onClick, Type , appTranslations }) {
  if (Type !== "rewardplusdiscounted") {
    return (
      <div onClick={onClick}>
        <a className="btn btn-sm btn-link text-lowercase">{appTranslations.view_more || "view more"} <i className="la la-arrow-right ml-2"></i></a>
      </div>
    );
  }
  return null;
}
ViewMore.propTypes = {
  onClick: PropTypes.func.isRequired,
  Type: PropTypes.string
};

export default withAppConfig(ViewMore);
