import React, { Component, Fragment } from "react";
import MyInput from "../../components/MyInput";
import Formsy from 'formsy-react';
import { Link } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import defaultLogo from "../../assets/images/muvinow-logo.png";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import { renderNotificationMessage } from "../../helpers/ViewHelpers";
import { handleChange, enableButton, disableButton, disableAlert } from "../../helpers/StateHelper";
import { setPartnerTheme } from "../../helpers/PartnerHelpers";
import { REWARD, STORED_MY_PARTNER_NAME } from "../../constants/constants";
import { getCognitoUserAttributes } from "../../helpers/ConfigHelpers";
import { storeDataInLocalStore, getDataFromLocalStorage, removeItemFromStore, convertSecToDuration, querystring } from "../../helpers/GeneralHelpers";
import config from "../../config/config";
import { withAppConfig } from "../../context/ConfigContext";
import withUserSession from "../../hoc/UserSession";
const TIMER = 300;

class ConfirmUser extends Component {
    isCompMounted = true;
    ACCOUNT_TYPE
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            email: "",
            password: "",
            confirmationCode: "",
            canSubmit: false,
            showErrorAlert: false,
            showSuccessAlert: false,
            alertMessage: "",
            countDown: TIMER
        };
        this.disableAlert = disableAlert.bind(this);
        this.handleChange = handleChange.bind(this);
        this.enableButton = enableButton.bind(this);
        this.disableButton = disableButton.bind(this);
        this.renderNotificationMessage = renderNotificationMessage.bind(this);
    }

    componentDidMount() {
        const { state } = this.props.location;
        if (state && state.email && state && state.password) {
            this.setState({ email: state.email, password: state.password });
            this.startCountDown();
            this.ACCOUNT_TYPE = state.isPartner; //getDataFromLocalStorage(STORED_MY_PARTNER_NAME);
        } else {
            this.props.history.push('/login');
        }
    }

    componentWillUnmount() {
        this.isCompMounted = false;
        clearInterval(this.timer);
        const { Logo = defaultLogo } = this.props.appSettings;
        if (this.ACCOUNT_TYPE) {
            let partner = { Theme: {} };
            setPartnerTheme(Logo, partner, true); //clearTheme
        }
    }

    autoFillConfirmationCode = () => {
        let confirmationCode = querystring("code");
        if (confirmationCode) {
            this.setState({
                confirmationCode: confirmationCode
            })
        }
    }

    startCountDown = () => {
        this.timer = setInterval(() => {
            if (this.state.countDown !== 0) {
                this.setState((prevState) => {
                    return {
                        countDown: prevState.countDown - 1
                    }
                })
            } else {
                clearInterval(this.timer);
            }
        }, 1000);
    }

    resendCode = () => {
        const { appTranslations : tr } = this.props; 

        Auth.resendSignUp(this.props.location.state.email.toLowerCase()).then((res) => {
            this.setState({
                showSuccessAlert: true,
                alertMessage: tr.code_has_been_sent_successfully,
            });
            this.disableAlert("showSuccessAlert");
            this.setState({
                countDown: TIMER
            }, () => {
                this.startCountDown()
            })
        }).catch((err) => {
            let message = "Code couldn't be sent!";
            if (err.code === "LimitExceededException") {
                message = err.message
            }
            this.setState({
                showErrorAlert: true,
                alertMessage: message,
            });
            this.disableAlert("showErrorAlert");
        })
    }

    handleConfirmationSubmit = async event => {
        const { appTranslations : tr } = this.props; 

        this.setState({ isLoading: true });
        try {
            await Auth.confirmSignUp(this.state.email.toLowerCase(), this.state.confirmationCode);
            await Auth.signIn(this.state.email.toLowerCase(), this.state.password);
            this.props.updateAuthStatus(true, false);
            this.props.updateUserData();
            this.props.createSession();
            if (this.ACCOUNT_TYPE) {
                let partner = await getCognitoUserAttributes(false, true);
                if (partner) {
                    storeDataInLocalStore(STORED_MY_PARTNER_NAME, partner);
                } else {
                    let cachePartner = getDataFromLocalStorage(STORED_MY_PARTNER_NAME); //because normal user may come from partnerlanding page
                    if (cachePartner) {
                        removeItemFromStore(STORED_MY_PARTNER_NAME)
                    }
                }
                if (this.props.location.state.partnerName === REWARD) { //replace it with generic partner
                    this.props.history.push(`/${REWARD}`);
                }
            } else {
                this.props.history.push("/");
            }
        } catch (err) {
            let message = tr.error_invalid_verfication_code_provided_;
            if (err.code === "CodeMismatchException") {
                message = tr.pin_mismatch
            }
            this.setState({
                isLoading: false,
                showErrorAlert: true,
                alertMessage: message //change it later
            });
            this.disableAlert("showErrorAlert");
        }
    }

    renderConfirmationForm() {
        const { ApplicationName = config.applicationName } = this.props.appSettings;
        const { appTranslations : tr } = this.props; 
        let confirmUserMessage = tr.please_visit_your_phone_number_for_a_code || `Please visit your phone number: <email> for a code which you must enter below in order to validate your <applicationname> account. Do not enter a voucher code or coupon code below.`;
        confirmUserMessage = confirmUserMessage.replace("<email>",this.state.email);//removed stronf from email
        confirmUserMessage = confirmUserMessage.replace("<applicationname>",ApplicationName);

        return (
            <Fragment>
                <h3 className="text-uppercase mb-3 mt-3" style={{textAlign: "center"}}>{tr.confirm} {ApplicationName} {tr.account}</h3>
                {this.state.isLoading && <span className="loader-spiral mn-white mn-white-transparent"><span className="spiral-inn"></span></span>}
                <div className="form-group font-12 font-weight-normal ml-4 mr-3">
                    {confirmUserMessage}
                </div>
                <Formsy className="ml-4 mr-4" onValidSubmit={this.handleConfirmationSubmit} onValid={this.enableButton} onInvalid={this.disableButton}>
                    <MyInput
                        id="confirmationCode"
                        name="confirmationCode"
                        outerClassName="mb-4"
                        type="text"
                        placeholder={`${tr.verification_code}`}
                        validations="isNumeric"
                        validationError={`${tr.validation_code_should_be_numeric}.`}
                        required
                        handleChange={this.handleChange}
                    />
                    <LoaderButton
                        disabled={!this.state.canSubmit}
                        type="submit"
                        className="btn btn-primary btn-block text-uppercase"
                        isLoading={this.state.isLoading}
                        text={`${tr.continue} ${tr.sign_up}`}
                        loadingText={`${tr.confirming || "Confirming"} ...`}
                    />
                </Formsy>
            </Fragment >
        );
    }

    render() {
        const { already_have_an_account ,sign_in_now , notice_the_email_with_your_validation_co ,please_enter_verification_code, resend_code_click_here } = this.props.appTranslations;
        return (
            <main className="mn-main">
                <section className="mn-login-section py-5">
                    <div className="container">
                        <div className="mn-login-content" style={{ minWidth: "450px" }}>
                            {this.renderNotificationMessage()}
                            <div className="mn-login-body" >
                                {this.renderConfirmationForm()}
                                <div className="form-group font-12 font-weight-normal mt-2 ml-4 mr-4 mt-2">
                                    <p>{notice_the_email_with_your_validation_co || "*Notice: Your validation code may be in your Message box"}.</p>
                                </div>
                                {this.state.countDown > 0 ?
                                    <div className="mn-login-footer mt-4 text-center">
                                        <p>{please_enter_verification_code} : {convertSecToDuration(this.state.countDown, false, true)}</p>
                                    </div>
                                    :
                                    <div className="mn-login-footer mt-4 text-center">
                                        <div><a onClick={this.resendCode} href="javascript:void(0)">{resend_code_click_here}</a></div>
                                    </div>
                                }
                            </div>
                            <div className="mn-login-footer mt-4 text-center">
                                <div>{already_have_an_account} <Link to="/login">{sign_in_now}</Link></div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}
export default withAppConfig(withUserSession(ConfirmUser));