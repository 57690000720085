import { POST } from "../../api/";
import _isEmpty from "lodash/isEmpty";
import _concat from "lodash/concat";
import { handleImageResizing } from "../../helpers/CDNhelpers";

export default async function getMovies(callback, params) {
  const { Limit = 20, LastEvaluatedKey = ``, Items } = params;
  let body = {
    ExclusiveStartKey: LastEvaluatedKey,
    Limit: Limit
  };
  let movies;
  if (!movies || _isEmpty(movies)) {
    movies = await POST("MoviesAPI", `/list`, body)
      .then(res => {
        if (res.body) {
          res.body.Items = _concat(Items, res.body.Items);
          return res.body;
        }
        return res.body;
      })
      .catch(e => {
        let message = e;
        if (e.response && e.response.data && e.response.data.errorMessage) {
          message = e.response.data.errorMessage.replace("[403] ", "");
        }
        callback(null, message);
        return;
      });
  }
  if (!movies) {
    return;
  }
  const { HostName } = params;

  movies.Items = await handleImageResizing(movies.Items, "Movies", HostName);
  callback(movies);
}
