export const HOME = "/home";
export const MOVIES = "/movies";
export const LIBRARY = "/library";
export const GENRE = "/genre/";
export const MYLIST = "/mylist"
export const MYRENTALS = "/myrentals"
export const WATCHHISTORY = "/watchhistory"
export const MYSUBSCRIPTIONS = "/mysubscriptions"
export const MOVIE = "/movie/";
export const VIDEO = "/video/";
export const WATCH = "/watch/";
export const DEVICES = "/devices";
export const SEARCH = "/search";
export const PROFILE = "/profile";
export const SETTINGS = "/settings";
export const PAYMENT_SETTINGS = "/payment-options";
export const CHANGE_PASSWORD = "/change-password";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const CONFIRM_USER = "/confirm";
export const LOGIN = "/login";
export const SIGNUP = "/signup";
export const PARTNERSIGNUP = "/p/:name";
export const PARTNERURL = `/plus-rewards-offer`;
export const SERVICE_UNAVAILABLE = `/service-unavailable`;
export const MACCASS = "/maccas-movies";
export const UNSUPPORTED_BROWSER = "/unsupported";
export const HELP_SUPPORT = "/help";
export const PRIVACY_POLICY = "/privacy-policy";
export const TERMS_CONDITIONS = "/terms-conditions";
export const CONTACT_US = "/contact";
export const ABOUT_US = "/about";
export const HOW_TO_WATCH = "/how-to-watch";
export const REDIRECT_URL = "/login?redirect=";
export const DEVICES_REDIRECT = "/devices?redirect=";
export const NOTIFICATIONS = "/notifications";
export const PURCHASE_LISTING = "/purchase-listing";
export const SUBSCRIPTION_HISTORY = "/subscription-history";
export const CHECKOUT = "/checkout/";
export const TITLE = "title"; //used for watch movie/trailer routing param;
export const SOCIALSIGNIN = "/social"; //1 related
export const SOCIALSIGNINCONST = "social"; //2 related
export const SIGNOUT = "/signout"; //2 related
export const PREVIEWURL = "preview";
export const STOREDVOUCHERS = "/stored-vouchers";
export const ALLCOLLECTIONS = "/collections";
export const ALLROOMS = '/screening-rooms';
export const ROOMDETAIL = '/room-detail/';
export const COLLECTIONDETAIL = '/collection-detail';
