import { GET } from "../../api/";
import {
  storeDataInLocalStore,
  getDataFromLocalStorage } from "../../helpers/GeneralHelpers";
import { handleImageResizing } from "../../helpers/CDNhelpers";

export async function getMyList(callback, { CdnUrl, UserID, purchases }) { //To do Later -> check no list flag! getLikedData()
  let myList = [];
  //let userId = await getCurrentUserId();
  myList = getDataFromLocalStorage("myList", UserID);
  if (myList) {
    callback(myList);
    return;
  }
  return GET("UserAPI", `/myList`)
    .then(async res => {
      myList = res && res.body && res.body.MyList ? res.body.MyList : [];
      myList = await handleImageResizing(myList, "MyList", CdnUrl);
      await storeDataInLocalStore("myList", myList, UserID, "No expiry");
      callback(myList, null);
    })
    .catch(err => {
      let message = err;
      if (err.response && err.response.data && err.response.data.errorMessage) {
        message = err.response.data.errorMessage.replace("[403] ", "");
      }
      callback(null, message);
    });
}

// getLikedData = (userId, likedListAlreadyUpdated = false) => {
//   return new Promise((resolve, reject) => {
//       let myList = getDataFromLocalStorage(STORED_MY_LIST, userId);
//       let myListEmptyFlag = getDataFromLocalStorage(STORED_EMPTY_MYLIST_FLAG, userId);
//       if (!isEmpty(myList) || myListEmptyFlag) {
//           if (!likedListAlreadyUpdated) {
//               this.setState({
//                   likedList: myList
//               });
//           }
//           resolve(myList)
//       } else {
//           return this.fetchMyListMovies("UserAPI", "/myList", this.state.likedList, "isLoadingMyListMovies", "errorMyListMovies", "likedList", RESPONSE_MYLIST, "ID").then((res) => {
//               if (res !== "error" && res !== []) {
//                   storeDataInLocalStore(STORED_MY_LIST, res, userId, NO_EXPIRY);
//                   resolve(res);
//               } else {
//                   reject([])
//               }
//           })
//       }
//   })
// }