import React ,{ createRef } from "react";
import PropTypes from "prop-types";
import { PostAPI } from "../helpers/APIHelpers/APIHelpers";
import { VERIFY_VOUCHER } from "../constants/constants";
import { getCurrentUserId } from "../helpers/GeneralHelpers";
import { updateCacheOnNewPurchaseItem } from "../helpers/MoviesHelper";
import config from "../config/config";

const withPaymentHelper = (WrappedComponent) => {
    class WrappedPaymentComponent extends React.Component {
        constructor(props) {
            super(props);
            this.verifyRedeemVoucher = PostAPI.bind(this);
            this.WrappedComponentRef = createRef();
            this.submitChekoutForm = PostAPI.bind(this);
        }
        

        handleRedeemVoucherClick = async (voucherCode, movieId, qualityData, SuccessMessage) => {
            const { price, ManifestDetails } = qualityData;
            let redeemParams = {
                Voucher: voucherCode,
                MovieID: movieId,
                TotalPrice: price,
                Quality: ManifestDetails
            };
            const { ApplicationName = config.applicationName } = this.props.appSettings;
            let VoucherSucessMessage = SuccessMessage ? SuccessMessage : `Your ${ApplicationName} Reward Code was applied`;
            let apiParams = {
                apiname: "OrdersAPI",
                apipath: "/canRedeem",
                postParams: redeemParams
            }
            let otherParams = {
                SuccessMessage: VoucherSucessMessage
            }
            this.verifyRedeemVoucher(apiParams, VERIFY_VOUCHER, otherParams, this.handleReedemApiResponse, this.props.showAlert);
        }

        handleReedemApiResponse = (err, response) => {
            if (err) {
                this.WrappedComponentRef.onVoucherRedeemFailed(err);
            } else {
                this.WrappedComponentRef.onVoucherRedeemSuccess(response);
            }
        }

        handleCheckout = (checkoutParams) => {
            let apiParams = {
                apiname: "OrdersAPI",
                apipath: "/checkout",
                postParams: checkoutParams
            }
            this.submitChekoutForm(apiParams, "SUBMIT_FREE_PAYMENT_FORM_PARTNER", null, this.handleCheckoutResponse);
        }
        
        handleCheckoutResponse = (err, response) => {
            if (err) {
                this.WrappedComponentRef.onCheckoutFailed(err);
            } else {
                getCurrentUserId().then(async (userId) => {
                    await updateCacheOnNewPurchaseItem(userId, response);//update it later with checkout api
                    this.props.fetchPurchases();
                    this.WrappedComponentRef.onCheckoutSuccess(response, { userId: userId });
                });
            }
        }
        render() {
            return (
                <WrappedComponent
                    ref={r => this.WrappedComponentRef = r}
                    handleRedeemVoucherClick={this.handleRedeemVoucherClick}
                    handleCheckout={this.handleCheckout}
                    {...this.props}
                />)
        }
    }
    WrappedPaymentComponent.propTypes = {
        showAlert: PropTypes.func.isRequired,
        appSettings: PropTypes.object
    }
    WrappedPaymentComponent.defaultProps = {
        appSettings: {}
    }
    return WrappedPaymentComponent;
    // return forwardRef(function named(props, ref) {
    //     return <WrappedPaymentComponent {...props} forwardedRef={ref} />;
    //   });
}
export default withPaymentHelper;