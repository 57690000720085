import React, { useState, useEffect, memo } from "react";
import { withRouter } from "react-router-dom";
import { withAppConfig } from "../../../context/ConfigContext";
import Loader from "../../../components/Loader/Loader";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";
import CollectionCard from "../../../components/RoomCollection/CollectionCard";
import { withAuth } from "../../../context/AuthContext";

const MyCollections = props => {
  const [list, setList] = useState([]);

  useEffect(() => {
    initialize();
    return () => {};
  }, [props.mycollections]);

  const initialize = () => {
    setList(props.mycollections);
  };

  if (props.updatingCollections) {
    return (
      <section>
        <Loader />
      </section>
    );
  }
  if (list && list.length < 1) {
    return (
      <section className="mn-movies py-4">
        <div className="container-fluid">
          <DataNotFound text="You have no item(s) in My Bundles" />
        </div>
      </section>
    );
  }

  return (
    <section className="mn-movies pt-3">
      <div className="container-fluid">
        <div>
          <div className="row gutter-10 mn-movies-grid">
            {list.map((collection, index) => {
              collection.Collection["isCPurchased_"] = true; //assuming it is purchased -- verify later
              return (
                <CollectionCard
                  key={index}
                  collection={collection.Collection}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default withAppConfig(withRouter(withAuth(MyCollections)));
