import React, { Component } from "react";
import { GeoBlockView } from "../../components/GeoBlock/GeoBlock";
import { withAppConfig } from "../../context/ConfigContext";

class ServiceUnavailable extends Component {

    componentDidMount() {
        this.props.applyGeoBlockStyle(true);
    }

    componentWillUnmount() {
        this.props.applyGeoBlockStyle(false);
    }

    render() {
        return (
            <GeoBlockView />
        );
    }
}
export default withAppConfig(ServiceUnavailable);