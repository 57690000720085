import React, { Component } from 'react';
import { getAppConfigurations, loadAppFavicon, getHostName } from '../helpers/ConfigHelpers';
import config from "../config/config";
import _filter from "lodash/filter";
import _find from "lodash/find";
import { getSectionsData } from '../helpers/MoviesHelper';
import { SUCCESS, WARNING, ERROR } from '../constants/constants';
import Alert from '../components/Alert/Alert';
import StuffpixSplash from "../components/StuffpixSplash/Splash";
import Splash from "../components/MuvinowSplash/Splash";
import { getAppTranslations } from '../services/config/AppTranslations';

const ConfigContext = React.createContext(null);

class ConfigProvider extends Component {
    state = {
        isLoadingConfig: true,
        configurations: [],
        appSettings: {},
        openSearchIcon: false,
        geoBlockStyle: false,
        showSuccessAlert: false,
        showWarningAlert: false,
        showErrorAlert: false,
        showLoader: false,
        alertMessage: "",
        searchFlag: false,
        isLoadingTranslation: true,
        // isLoadingCustomSections: config.customSettings.isStuffpixEnabled ? true : false,
        applyGeoBlockStyle: (value) => {
            this.setState({
                geoBlockStyle: value
            })
        },
        // handleHeaderSectionsUpdate: () => {
        //     this.updateHeaderSections();
        // },
        showAlert: (alertType, alertMessage) => {
            this.showAlert(alertType, alertMessage)
        },
        dismissAlert: (alertType, alertMessage) => {
            this.dismissAlert(alertType, alertMessage)
        },
        hideAlert: (alertType) => {
            this.hideAlert(alertType)
        },
        updateAppTranslations: (key) => this.updateAppTranslations(key),
        clearSearchValue: (value) => {
            if (value) {
                this.setState((prevState) => {
                    return {
                        openSearchIcon: !prevState.openSearchIcon
                    }
                });
            } else {
                this.setState((prevState) => {
                    return {
                        searchFlag: !prevState.searchFlag
                    }
                });
            }
        }
    }

    async componentDidMount() {
        let appConfig = await getAppConfigurations();
        let settings = _find(appConfig, ["KEY", "APP_CONFIGURATIONS"]);
        settings = (settings && settings.VALUE) ? settings.VALUE[0] : {};
        let partners = _find(appConfig, ["KEY", "AFFILIATE_PARTNERS"]);
        partners = (partners && partners.VALUE) ? partners.VALUE[0] : {};
        partners = _filter(partners, (obj) => {
            return obj.Status === "active" //(obj.ShowInFooter === true || obj.ShowInFooter === "true")
        });
        loadAppFavicon(settings, config.applicationFavicon);
        settings["Partners"] = partners;
        let hostname = getHostName(appConfig);
        this.setState({ // to load config on splash!
            isLoadingConfig: false,
            configurations: appConfig,
            appSettings: settings,
            HostName: hostname
        });
        getAppTranslations(null, this.state.appSettings.AppDefaultLanguage).then((res) => {
            this.setState({
                appTranslations: res,
                isLoadingTranslation: false
            });
        });
        // if (window.location.pathname !== HOME && window.location.pathname !== "/") {
        //     if (config.customSettings.isStuffpixEnabled) {
        //         this.updateHeaderSections(true)
        //     }
        // }
    }

    // async updateHeaderSections(loadSections = false) {
    //     if (loadSections) {
    //         await getSectionsData();
    //     }
    //     this.setState({
    //         isLoadingCustomSections: false
    //     }); //now call headerRef !!
    // }

    updateAppTranslations = async (key) => {
        // this.setState({ // to load config on splash!
        //     isLoadingConfig: true
        // });
        let appTranslations = await getAppTranslations(this.state.configurations, key);
        this.setState({
            appTranslations: appTranslations
        });
    }

    showAlert = (alertType, alertMessage) => {
        this.clearPreviousAlert(alertType);
        this.setState({
            alertMessage: alertMessage,
            [alertType]: true
        });
        this.hideAlert(alertType);
    };

    hideAlert = (alertType) => {
        setTimeout(() => {
            this.setState({
                [alertType]: false,
                alertMessage: ""
            });
        }, 10000);
    }

    dismissAlert = () => {
        this.setState({
            showErrorAlert: false,
            showSuccessAlert: false,
            showWarningAlert: false,
            alertMessage: ""
        })
    }

    clearPreviousAlert = (alertType) => {
        if (this.state.showErrorAlert && alertType === "showSuccessAlert") {
            this.setState({
                showErrorAlert: false
            })
        } else if (this.state.showSuccessAlert && alertType === "showErrorAlert") {
            this.setState({
                showSuccessAlert: false
            })
        } else if (alertType === "showWarningAlert") {
            this.setState({
                showErrorAlert: false,
                showSuccessAlert: false
            })
        }
    };

    renderAlert = () => {
        const { showSuccessAlert, showWarningAlert, showErrorAlert, alertMessage } = this.state;
        const type = showSuccessAlert ? SUCCESS : showWarningAlert ? WARNING : ERROR;
        if (showSuccessAlert || showWarningAlert || showErrorAlert) {
            return (
                <Alert
                    className={type === SUCCESS ? "text-success" : "text-danger"}
                    icon={type === SUCCESS ? "la la-check mr-2" : "la la-exclamation-triangle mr-2"}
                    text={alertMessage}
                    type={type}
                />
            )
        }
        return null;
    }

    renderLoader = () => {
        if (this.state.showLoader)
            return (
                <main>
                    <span className="loader-spiral mn-transparent"><span className="spiral-inn"></span></span>
                </main>
            )
        return null;
    };

    render() {
        return (
            <ConfigContext.Provider value={this.state}>
                {this.renderAlert()}
                {this.renderLoader()}
                {this.props.children}
            </ConfigContext.Provider>)
    }
}

const withAppConfig = Component => props => {
    const ConfigConsumer = (<ConfigContext.Consumer>
        {setting => {
            return setting.isLoadingConfig || setting.isLoadingTranslation ? (
                config.customSettings.isStuffpixEnabled ? (
                    <StuffpixSplash />
                ) : (
                        <Splash />
                    )
            ) : (
                    <Component {...props} {...setting} />
                );
        }}
    </ConfigContext.Consumer>
    );
    return ConfigConsumer;
};
export { ConfigContext, ConfigProvider, withAppConfig };
