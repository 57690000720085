import React from 'react';
import { Modal, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { getVideoQualityData } from "../../helpers/MoviesHelper";
import { EST } from "../../constants/constants";
import MyRadioButton from "../MyRadioButton/MyRadioButton";
import PropTypes from 'prop-types';
import _reverse from "lodash/reverse";
import config from '../../config/config';
import SubscribeButton from "./components/SubscribeButton";
import { withAppConfig } from '../../context/ConfigContext';
import { withAuth } from '../../context/AuthContext';
import { withRouter } from "react-router-dom";
import classnames from 'classnames';

const PRICE = "price"; const TIERID = "teirId"; const CURRENCYCODE = "currencyCode"; const DISPLAYNAME = "displayName";

class SubscribeModal extends React.Component { //this is later version of SubscribeModal ,, deleted that later!!
    isCompMounted = true;
    selectedOptions = {};

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: this.getInitialTeirId(),
            modalBackDrop: true,
            activeTab: '1'
        }
    }

    changeTab = (tabIndex) => {
        if (tabIndex !== this.state.activeTab) {
            this.setState({ activeTab: tabIndex })
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.movieId !== prevProps.movieId) {//this.getInitialTeirId()
            let data = getVideoQualityData(this.props.data, this.props.movieId, this.props.type);
            data = _reverse(data);
            let selected = data[0].teirId;
            this.setState({
                selectedOption: selected
            });
        }
    }

    getInitialTeirId = () => { //same used in compdidUpdate

    };

    componentWillUnmount() {
        this.isCompMounted = false;
    }

    handleOptionChange = (value) => {
        this.setState({
            selectedOption: value
        });
    };



    renderSubscribeModal() {
        const { movieId, history, type, data, showLoader, showAlert, dismissSubscribeModal, appTranslations: tr } = this.props;
        return this.props.data && (
            <>
                <div className="modal-header">
                    <h5 className="modal-title font-16 text-uppercase">Subscribe <span className="font-weight-light">to Pack</span>
                    </h5>
                    <span className="mn-close" onClick={this.props.handleBackDropClick}></span>
                </div>
                <div className="modal-body py-5">
                    <div className="mb-5 text-center font-16">Choose your <span
                        className="font-weight-bold">Plan</span> and <span
                            className="font-weight-bold">Video Quality</span> to continue to checkout
                   </div>
                    <div className="row">
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <div className="row justify-content-center">
                                <div className="col-lg-10">
                                    <div className="mn-room mn-room-vertical">
                                        <div className="mn-figure mn-room-figure">
                                            <div className="mn-thumb rounded-circle img-bg img-1x1"
                                                style={{ backgroundImage: `url(${this.props.data.PosterUrl})` }}>

                                            </div>
                                        </div>
                                        <div className="text-white-70">
                                            <h6 className="mb-3 text-white font-weight-light">{this.props.data.Title}</h6>
                                            <div className="mb-3"><span
                                                className="font-weight-bold mr-1">{this.props.data.MovieCount}</span> Movies
                                           </div>
                                            <div className="mb-3 text-white-50">
                                                {this.props.data.Description}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div>
                                <Nav pills className="nav-justified mb-4">
                                    {this.props.data.Plans.map((plan, index) => {
                                        return (<NavItem>
                                            <NavLink
                                                className={classnames({ active: this.state.activeTab === (index + 1).toString() })}
                                                onClick={() => {
                                                    ['sd', 'hd', '4k'].map((quality, qIndex) => {
                                                        if (plan.pricelist[quality] && plan.pricelist[quality].price !== null) {
                                                            if (qIndex === 0) {
                                                                this.toggleSelectedQuality(plan.duration, quality.toUpperCase(), plan.pricelist[quality].price);
                                                            }
                                                        }
                                                    });
                                                    this.changeTab((index + 1).toString());
                                                }}
                                            >
                                                {plan.duration.toUpperCase()}
                                            </NavLink>
                                        </NavItem>)
                                    })}
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>

                                    {this.props.data.Plans.map((plan, index) => {
                                        return (<TabPane tabId={(index + 1).toString()}>
                                            <ul className="rent-list text-white-70">
                                                {['sd', 'hd', '4k'].map((quality, qIndex) => {
                                                    if (plan.pricelist[quality] && plan.pricelist[quality].price !== null) {

                                                        if (Object.keys(this.selectedOptions).length === 0 && index === 0 && qIndex === 0) {
                                                            this.toggleSelectedQuality(plan.duration, quality.toUpperCase(), plan.pricelist[quality].price);
                                                        }
                                                        return (
                                                            <li>
                                                                <div className="custom-control custom-radio">
                                                                    <input type="radio" className="custom-control-input"
                                                                        name={'name-' + index} id={'id-' + index + '-' + qIndex}
                                                                        defaultChecked={qIndex === 0}
                                                                        onClick={(e) => {
                                                                            // e.preventDefault();
                                                                            e.stopPropagation();
                                                                            this.toggleSelectedQuality(plan.duration, quality.toUpperCase(), plan.pricelist[quality].price);
                                                                        }}
                                                                    />
                                                                    <label htmlFor={'id-' + index + '-' + qIndex} className="custom-control-label">{this.props.appSettings.CurrencySymbol} <b>{plan.pricelist[quality].price}</b><span style={{ marginLeft: '.5rem' }} > {quality.toUpperCase()} {"Video Quality"}</span>
                                                                    </label>
                                                                </div>
                                                            </li>)
                                                    }
                                                })}
                                            </ul>
                                        </TabPane>)
                                    })}


                                </TabContent>
                            </div>

                            <div>
                                <form>
                                    <div className="form-group">
                                        <label className="text-white-50">Do you have a reward code?</label>
                                        <div className="input-group">
                                            <input type="text"
                                                className="form-control form-control-lg form-control-dark form-control-dark"
                                                placeholder="Enter Reward Code" name="voucherCode" id="voucherCode"
                                                maxLength="" required />
                                        </div>
                                    </div>
                                </form>
                                <div className="text-center">
                                    <button onClick={this.subscribeOnClick} className="btn btn-outline-primary btn-lg btn-medium">Subscribe</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    toggleSelectedQuality = (duration, quality, price) => {
        this.selectedOptions.SelectedDuration = duration;
        this.selectedOptions.SelectedQuality = quality;
        this.selectedOptions.SelectedPrice = price;
    }

    subscribeOnClick = () => {
        let redirectParams = `?method=screening_room`;
        if (this.selectedOptions.SelectedQuality) {
            redirectParams += `&type=${this.selectedOptions.SelectedQuality}`;
        }
        if (this.selectedOptions.SelectedDuration) {
            redirectParams += `&duration=${this.selectedOptions.SelectedDuration}`;
        }
        this.props.history.push({
            pathname: `/checkout-sc/${this.props.data.ID}`,
            search: redirectParams
        });
    }

    render() {
        return (
            <Modal
                isOpen={this.props.showSubscribeModal}
                backdrop={this.state.modalBackDrop}
                toggle={this.props.handleBackDropClick}
                size="lg"
                centered modalClassName="modal-dark" >
                {this.renderSubscribeModal()}
            </Modal>
        )
    }
}

SubscribeModal.defaultProps = {
    data: [],
    movieId: "",
    showLoader: false,
}

SubscribeModal.propTypes = {
    handleSelectedQualityVideo: PropTypes.func.isRequired,
    showAlert: PropTypes.func,
    data: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]).isRequired,
    appSettings: PropTypes.object,
    showSubscribeModal: PropTypes.bool.isRequired,
    handleBackDropClick: PropTypes.func.isRequired,
    dismissSubscribeModal: PropTypes.func.isRequired
};
export default withRouter(withAppConfig(withAuth(SubscribeModal)));
