import { POST } from "../../api/";
import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";
import {
  checkIsMovieLikedPurchased,
  getActiveMovieOrderObject,
  checkIsMovieExpired
} from "../../helpers/MoviesHelper";
import {
  importMoment,
  storeDataInLocalStore,
  getDataFromLocalStorage
} from "../../helpers/GeneralHelpers";
import { handleImageResizing } from "../../helpers/CDNhelpers";

export async function getHomeBanners(callback, params) {
  const { userID } = params;
  let banners = getDataFromLocalStorage("banners", userID);
  if (!banners || _isEmpty(banners)) {
    banners = await POST("BannerAPI", "/getHomeBanners")
      .then(res => {
        return res.body;
      })
      .catch(e => {
        let message = e;
        if (e.response && e.response.data && e.response.data.errorMessage) {
          message = e.response.data.errorMessage.replace("[403] ", "");
        }
        callback(null, "Error Loading Banner!");
        return;
      });
  }
  if (!banners) {
    return;
  }
  const { HostName, purchases, serverTime, isAuthenticated } = params;

  if (isAuthenticated) {
    let moment = await importMoment(false);
    banners = mapRentalStatus(banners, purchases, serverTime, moment);
  }
  
  //banners = handleImageResizing(banners, null, HostName);
  if (!_isEmpty(banners)) {
    storeDataInLocalStore("banners", banners, userID);
  }
  callback(banners);
}

const mapRentalStatus = (bannerList, purchases, serverTime, moment) => {
  let final = bannerList;
  final = _map(final, decideBannerPurchase);

  function decideBannerPurchase(banner) {
    if (banner.MovieID) {
      banner["isExpired"] = true;
      let isPurchased = checkIsMovieLikedPurchased(
        purchases,
        banner.MovieID,
        "MovieID"
      );
      if (isPurchased) {
        let movie = getActiveMovieOrderObject(
          purchases,
          "MovieID",
          banner.MovieID
        );
        if (!_isEmpty(movie)) {
          let isExpired = checkIsMovieExpired(moment, movie, serverTime);
          banner["isExpired"] = isExpired;
        }
      }
    }
    return banner;
  }
  return final;
};
