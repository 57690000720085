import { POST } from "../../api/";
import _find from "lodash/find";
import _filter from "lodash/filter";
import _findIndex from "lodash/findIndex";
import _orderBy from "lodash/orderBy";
import _uniqBy from "lodash/uniqBy";
import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";
import {
  checkIsMovieLikedPurchased,
  getActiveMovieOrderObject,
  checkMoviePurchaseType,
  checkIsMovieExpired,
  checkIsMovieSubscribed,
  isMovieCollectionPurchased
} from "../../helpers/MoviesHelper";
import { ExpiringStripTime } from "../../constants/constants";
import {
  convertSecToDuration,
  importMoment,
  storeDataInLocalStore,
  getDataFromLocalStorage
} from "../../helpers/GeneralHelpers";
import { handleImageResizing } from "../../helpers/CDNhelpers";

export async function listMovieBySection(callback, params) {
  const { userID } = params;
  let sections = getDataFromLocalStorage("sections", userID);
  if (!sections || _isEmpty(sections)) {
    sections = await POST("MoviesAPI", "/v2/listMovieBySection")
      .then(res => {
        return res.body;
      })
      .catch(e => {
        let message = e;
        if (e.response && e.response.data && e.response.data.errorMessage) {
          message = e.response.data.errorMessage.replace("[403] ", "");
        }
        callback(null, message);
        return;
      });
  }
  if (!sections) {
    return;
  }
  const { HostName, purchases, myList, watchList, serverTime } = params;
  if (params.isAuthenticated) {
    sections = updateUserSections(sections, purchases, myList, watchList);
    let moment = await importMoment(false); // remove false later!
    sections = mapRentalStatus(sections, purchases, serverTime, moment);
    sections = updateRoomsAndCollections(sections, params.subscriptions,params.mycollections); //collections not done yet
    sections = subscribedMoviesStatus(sections, params.subscriptions);
    sections = collectionMoviesStatus(sections,purchases)
  }
  sections = await applyCdn(sections, HostName);
  if (!_isEmpty(sections)) {
    storeDataInLocalStore("sections", sections, userID);
  }
  callback(sections);
}

export async function listMovieByResumedSection(callback, params) {
  const { userID } = params;
 
let sections = await POST("MoviesAPI", "/resume-section")
      .then(res => {
        return res.body;
      })
      .catch(e => {
        let message = e;
        if (e.response && e.response.data && e.response.data.errorMessage) {
          message = e.response.data.errorMessage.replace("[403] ", "");
        }
        callback(null, message);
        return;
      });
  if (!sections) {
    return;
  }
  const { HostName, purchases, myList, watchList, serverTime } = params;
  if (params.isAuthenticated) {
    sections = updateUserSections(sections, purchases, myList, watchList);
    let moment = await importMoment(false); // remove false later!
    sections = mapRentalStatus(sections, purchases, serverTime, moment);
    sections = updateRoomsAndCollections(sections, params.subscriptions,params.mycollections); //collections not done yet
    sections = subscribedMoviesStatus(sections, params.subscriptions);
    sections = collectionMoviesStatus(sections,purchases)
  }
  sections = await applyCdn(sections, HostName);
  
  callback(sections);
}

function updateUserSections(sections, purchases, myList, watchList) {
  let data = sections;
  if (data && data.constructor === Array) {
    let LikedObj = _find(data, ["Type", "mylist"]);
    if (LikedObj) {
      LikedObj["Items"] = typeof myList === Array ? myList.reverse() : myList;
      let LIndex = _findIndex(data, ["Type", "mylist"]);
      data.splice(LIndex, LIndex === -1 ? 0 : 1, LikedObj);
    }
    let watchObj = _find(data, ["Type", "watched"]);
    if (watchObj) {
      watchObj["Items"] = watchList; //.reverse();
      let wIndex = _findIndex(data, ["Type", "watched"]);
      data.splice(wIndex, wIndex === -1 ? 0 : 1, watchObj);
    }
    let activeRentals = getActiveRentals(purchases);
    let rentalObj = _find(data, ["Type", "purchasehistory"]);
    if (rentalObj) {
      if (rentalObj.Items && rentalObj.Items.constructor === Array) {
        activeRentals = filterFullyEpiredRental(activeRentals);
        activeRentals = _filter(activeRentals, function(o) {
          return  o.Type !== "Child";
        });
        rentalObj["Items"] = activeRentals;
        if (activeRentals.length > 20) {
          activeRentals = activeRentals.slice(0, 20);
        }
        let rIndex = _findIndex(data, ["Type", "purchasehistory"]);
        data.splice(rIndex, rIndex === -1 ? 0 : 1, rentalObj);
      }
    }
  }
  return data;
}

function getActiveRentals(purchaseList) {
  let activeRentals = _orderBy(purchaseList, ["CreatedAt"], ["desc"]);
  activeRentals = _uniqBy(activeRentals, "MovieID");
  return activeRentals;
}

function filterFullyEpiredRental(purchaseList) {
  let mypurchases = _filter(purchaseList, function(o) {
    return !o.HideExpiredRental_;
  });
  return mypurchases;
}

const mapRentalStatus = (sections, purchases, serverTime, moment) => {
  let final = [];
  sections.forEach(section => {
    let type = section.Type.toLowerCase(); //used in following mapCallBack
    let updatedSectionItems = _map(
      section.Items,
      decideSecionLikePurchasedStatus
    );

    function decideSecionLikePurchasedStatus(sectionItem) {
      sectionItem["isPurchased_"] = false;
      sectionItem["purchaseType_"] = "";
      sectionItem["isExpired_"] = true;
      sectionItem["expiringTime_"] = "Expired";
      sectionItem["expiringTimeStrip_"] = 0; //used for color
      sectionItem["showExpiringtimeStrip_"] = false;
      let ID = sectionItem.MovieID || sectionItem.ID;
      let isPurchased = checkIsMovieLikedPurchased(purchases, ID, "MovieID");
      if (isPurchased) {
        sectionItem["isPurchased_"] = true;
        sectionItem["showExpiringtimeStrip_"] =
          type === "watched" || type === "purchasehistory";
        let movie = getActiveMovieOrderObject(purchases, "MovieID", ID);
        if (type === "watched" && movie.HideExpiredRental_) {
          sectionItem["HideExpiredRental_"] = true;
        }
        sectionItem["purchaseType_"] = checkMoviePurchaseType(movie);
        if (!sectionItem.Status) {
          sectionItem["Status"] = movie.Status;
        }
        let expiringStatus = checkIsMovieExpired(
          moment,
          movie,
          serverTime,
          true
        );
        if (expiringStatus === "New") {
          sectionItem["expiringTime_"] = "New";
          sectionItem["isExpired_"] = false;
        } else if (expiringStatus === "---") {
          sectionItem["isExpired_"] = false;
          sectionItem["expiringTime_"] = "";
        } else {
          if (expiringStatus <= 0) {
            sectionItem["expiringTimeStrip_"] = ExpiringStripTime; //full red color
            sectionItem["expiringTime_"] = "Expired";
            sectionItem["isExpired_"] = true;
          } else {
            sectionItem["expiringTimeStrip_"] = expiringStatus;
            sectionItem["expiringTime_"] = expiringStatus
              ? `Expiring in ${convertSecToDuration(expiringStatus)}`
              : "";
            sectionItem["isExpired_"] = false;
          }
        }
      }
      return sectionItem;
    }
    if (section.Name && type === "watched") {
      let allExpired = [];
      for (let i in updatedSectionItems) {
        if (updatedSectionItems[i].HideExpiredRental_ === true) {
          allExpired.push(updatedSectionItems[i]);
        }
      }
      if (allExpired.length === updatedSectionItems.length) {
        updatedSectionItems = [];
      }
    }
    section["Items"] = updatedSectionItems;
    final.push(section);
  });
  return final;
};

function applyCdn(sections, HostName) {
  //remove async await Promise.all() later
  let sectionData = _uniqBy(sections, "ID");
  sectionData = sectionData.map(async section => {
    let type = section.Type.toLowerCase();
    if (section.Items && section.Items.length > 0) {
      let compressedSections = await handleImageResizing(
        section.Items,
        "Sections",
        HostName
      );
      let sectionItems =
        type === "watched" || type === "purchasehistory"
          ? compressedSections
          : _uniqBy(compressedSections, "ID");
      section.Items = sectionItems;
    }
    return section;
  });
  return Promise.all(sectionData);
}

function updateRoomsAndCollections(sections, subscriptions, mycollections) {
  let updatedSections = sections;
  updatedSections = updatedSections.map(section => {
    if (section.Type === "screening_room") {
      let items = section.Items;
      items = items.map(room => {
        let isSubscribed = _find(subscriptions, ["ScreeningRoomID", room.ID]);
        if (isSubscribed) {
          room["isSubscribed_"] = true;
        }
        return room;
      });
      section.Items = items;
    }
    if (section.Type === "movie_collections") {
      let items = section.Items;
        items = items.map(collection => {
          let isCPurchased = _find(mycollections, ["CollectionID", collection.ID]);
        if (isCPurchased) {
          collection["isCPurchased_"] = true;
        }
        return collection;
      });
      section.Items = items;
    }
    return section;
  });
  return updatedSections;
}

function subscribedMoviesStatus(sections,subscriptions){
  let updatedSections = sections;
  updatedSections = updatedSections.map(section => {
    if(section && section.Items && section.Type !== 'screening_room' && section.Type !== 'movie_collections'){
      let items = section.Items;
      items = items.map(movie => {
        movie["isSubscribed_"] = checkIsMovieSubscribed(subscriptions,movie);
        return movie;
      });
      section.Items = items;
    }
    return section;
  });
  return updatedSections;
}

function collectionMoviesStatus(sections,purchases){
  let updatedSections = sections;
  updatedSections = updatedSections.map(section => {
    if(section && section.Items && section.Type !== 'screening_room' && section.Type !== 'movie_collections'){
      let items = section.Items;
      items = items.map(movie => {
        movie["isCPurchased_"] = isMovieCollectionPurchased(purchases,movie);
        return movie;
      });
      section.Items = items;
    }
    return section;
  });
  return updatedSections;
}