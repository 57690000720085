import React, { Component , memo, Fragment } from "react";
import { setDocumentTitle, uniqueBy, showError, showSuccess, removeItemFromStore, getCurrentUserId, isValueFoundInData, storeDataInLocalStore, isEmpty, convertSecToDuration, getDataFromLocalStorage, querystring, ping, importMoment } from "../../helpers/GeneralHelpers";
import { disableAlert } from "../../helpers/StateHelper";
import _map from "lodash/map";
import { getPurchaseData, decideLikePurchasedStatus } from "../../helpers/MoviesHelper";
import { STORED_SEARCH_HISTORY, RESPONSE_MYLIST, LIBRARY_PAGE, STORED_MY_LIST, REMOVE_MYLIST_MOVIE, NO_EXPIRY, STORED_MY_PURCHASES, FETCH_PURCHASELIST, MOVIE_VIDEO, CARD_IMAGE_SIZE, LIBRARY_IMAGE_SIZE } from "../../constants/constants";
import { getApiData, deleteApiRequest } from "../../helpers/APIHelpers/MoviesAPI";
import { SortRentPurchaseData } from "../../helpers/ResponseHelpers";
import { renderNotificationMessage } from "../../helpers/ViewHelpers";
import { WATCH } from "../../constants/routes";
import PurchaseHistory from "./components/PurchaseHistory";
// import MyList from "./components/MyList";
import MyList from "./MyList";
import PurchaseListing from "../PurchaseListing/PurchaseListing";
import { fetchFullPurchaseList } from "../../services/user/GetPurchaseList";
import { withAppConfig } from "../../context/ConfigContext";
import Subscriptions from "./Subscriptions";
import MyCollections from "./MyCollections";
const TabsList = { myPurchases: "1", myCollections : "2", subscriptions : "3", myList: "4", watchHistory: "5" };

 class Library extends Component {
    watchListFetchCount = 0
    USER_ID = ""
    purchaseList = []; // only used for checking purchased Status 
    SEARCH_HISTORY = [];
    purchaseTagsAlreadyDecided = true;
    MAPING_STATUS_TYPE = "" //used for tracking between ID & MovieID
    moment = null;
    querystring = "";
    isCompMounted = true;
    SERVERTIME = null;
    constructor(props) {
        super(props);
        this.state = {
            isLoadingMyListMovies: true,
            isLoadingMyWatchedMovies: true,
            isLoadingMyPurchasedMovies: true,
            myMoviesList: [],
            myWatchedList: [],
            myPurchaseList: [],
            errorMyListMovies: "",
            errorMyPurchasedMovies: "",
            activeTab: TabsList.myPurchases,
        }
        this.disableAlert = disableAlert.bind(this);
        this.showError = showError.bind(this);
        this.showSuccess = showSuccess.bind(this);
        this.isValueFoundInData = isValueFoundInData.bind(this);
        //this.fetchMyWatchedMovies = getApiData.bind(this);
        this.fetchMyListMovies = getApiData.bind(this);
        this.removeMovieFromMyList = deleteApiRequest.bind(this);
        // this.removeMovieFromMyWatchedList = deleteApiRequest.bind(this);
        this.renderNotificationMessage = renderNotificationMessage.bind(this);
        this.getCurrentUserId = getCurrentUserId.bind(this);
        // this.localWatchHistoryItems = localWatchHistoryItems.bind(this);
        // this.fetchFullWatchList = fetchFullWatchList.bind(this);
        this.decideLikePurchasedStatus = decideLikePurchasedStatus.bind(this);
        this.getPurchaseData = getPurchaseData.bind(this);
    }

    componentDidMount() {
        this.initializeMoviesState();
        this.getSearchHistory();
    }

    componentDidUpdate() {
        let newQueryString = querystring("q");
        if (newQueryString) {
            if (newQueryString !== this.querystring) {
                this.checkActiveTabOnLoad(this.USER_ID, true);
            }
        }
    }

    checkActiveTabOnLoad = (userId, calledOnUpdate = false) => { //calledOnUpdate= true ? fetch my list otherwise let default 
        let tabType = querystring("q") || TabsList.myPurchases;
        this.querystring = tabType;
        if (tabType !== TabsList.myPurchases || calledOnUpdate) {
            if (tabType === TabsList.myList || tabType === TabsList.watchHistory || tabType === TabsList.subscriptions || tabType === TabsList.myCollections || (tabType === TabsList.myPurchases && calledOnUpdate)) {
                this.onChangeTab(tabType);
            } else {
                this.fetchInitialActiveTabData("", userId); //default case : MyList
            }
        } else {
            this.fetchInitialActiveTabData("", userId); //default case : MyList
        }
    }

    componentWillUnmount() {
        this.isCompMounted = false;
    }

    initializeMoviesState = async () => {
        setDocumentTitle(LIBRARY_PAGE);
        if (!this.moment) {
            this.moment = await importMoment(false); //remove this false later!!
        }
        if (!this.SERVERTIME) {
            this.SERVERTIME = await ping();
        }
        this.USER_ID = await getCurrentUserId();
        this.checkActiveTabOnLoad(this.USER_ID); //this.fetchInitialActiveTabData("", userId);
    }

    getSearchHistory = () => {
        getCurrentUserId().then((userId) => {
            let searchList = getDataFromLocalStorage(STORED_SEARCH_HISTORY, userId);
            if (searchList) {
                let uniqueList = uniqueBy(searchList);
                this.SEARCH_HISTORY = uniqueList.slice(0, 5);
            } else {
                this.SEARCH_HISTORY = [];
            }
        })
    }

    clearSearchHistory = () => {
        getCurrentUserId().then((userId) => {
            removeItemFromStore(STORED_SEARCH_HISTORY, userId);
            this.SEARCH_HISTORY = [];
            this.setState({})
        })
    }

    fetchInitialActiveTabData = async (tab, userId) => {
        if (!this.USER_ID) {
            this.USER_ID = await getCurrentUserId();
        }
        if (this.state.activeTab === TabsList.myPurchases) {
            this.fetchPurchaseHistory()
            // let myList = getDataFromLocalStorage(STORED_MY_LIST, userId);
            // this.MAPING_STATUS_TYPE = RESPONSE_MYLIST;
            // this.purchaseList = await this.getPurchaseData(this.USER_ID);
            // if (isEmpty(myList)) {
            //     myList = await this.fetchMyListMovies("UserAPI", "/myList", this.state.myMoviesList, "isLoadingMyListMovies", "errorMyListMovies", "myMoviesList", RESPONSE_MYLIST, "ID");
            //     storeDataInLocalStore(STORED_MY_LIST, myList, userId, NO_EXPIRY);
            // }
            // let updatedMyListMovies = _map(myList, this.decideLikePurchasedStatus);
            // this.setState({
            //     isLoadingMyListMovies: false,
            //     errorMyListMovies: "",
            //     myMoviesList: updatedMyListMovies,
            //     showMyListTags: true
            // });
        }
    }

    onChangeTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
            this.fetchActiveTabData(tab);
        }
        this.resetCommonState();
    }

    handleFetchMoreMoviesClick = (tab) => {
        this.setState({
            isLoadingMore: true
        })
        this.fetchActiveTabData(tab, true);
    }

    resetCommonState = () => {
        this.setState({
            isLoadingMore: false
        });
    }

    fetchActiveTabData(tab, fetchmore = false) {
        switch (tab) {
            case TabsList.myList:
                if (this.state.myMoviesList.length <= 0) {
                    this.fetchMyListMovies("UserAPI", "/myList", this.state.myMoviesList, "isLoadingMyListMovies", "errorMyListMovies", "myMoviesList", RESPONSE_MYLIST, "ID").then(async (res) => {
                        if (res !== "error") {
                            storeDataInLocalStore(STORED_MY_LIST, res, this.USER_ID, NO_EXPIRY);
                            this.MAPING_STATUS_TYPE = RESPONSE_MYLIST;
                            this.purchaseList = await this.getPurchaseData(this.USER_ID);
                            let updatedMyListMovies = _map(res, this.decideLikePurchasedStatus);
                            this.setState({
                                myMoviesList: updatedMyListMovies,
                                showMyListTags: true
                            })
                        }
                    });
                }
                break;
            case TabsList.watchHistory:
                // console.log("hello->Rentals History")
                // if (this.state.myWatchedList.length <= 0 || fetchmore) {
                //     this.fecthWatchHistory(fetchmore);
                // }
                break;
            case TabsList.myPurchases:
                if (this.state.myPurchaseList.length <= 0 || fetchmore) {
                    this.fetchPurchaseHistory(fetchmore);
                }
                break;
            default:
                if (this.state.myMoviesList.length <= 0) {
                    this.fetchMyListMovies("UserAPI", "/myList", this.state.myMoviesList, "isLoadingMyListMovies", "errorMyListMovies", "myMoviesList", RESPONSE_MYLIST, "ID").then(async (res) => {
                        if (res !== "error") {
                            storeDataInLocalStore(STORED_MY_LIST, res, this.USER_ID, NO_EXPIRY);
                            this.MAPING_STATUS_TYPE = RESPONSE_MYLIST;
                            this.purchaseList = await this.getPurchaseData(this.USER_ID);
                            let updatedMyListMovies = _map(res, this.decideLikePurchasedStatus);
                            this.setState({
                                myMoviesList: updatedMyListMovies,
                                showMyListTags: true
                            })
                        }
                    });
                }
                break;
        }
    }

    fetchPurchaseHistory = async (fetchmore) => {
        if (!this.USER_ID) {
            this.USER_ID = await getCurrentUserId();
        }
        let cacheResults = true;
        let purchaseList = getDataFromLocalStorage(STORED_MY_PURCHASES, this.USER_ID);
        if (!purchaseList || isEmpty(purchaseList)) {
            cacheResults = false;
            purchaseList = await fetchFullPurchaseList();
        }
        if (!this.SERVERTIME) {
            this.SERVERTIME = await ping();
        }
        this.purchaseTagsAlreadyDecided = true;
        this.MAPING_STATUS_TYPE = FETCH_PURCHASELIST;
        this.purchaseList = purchaseList;
        let updatedPurchaseList = _map(purchaseList, this.decideLikePurchasedStatus); //also @ fetchWatchedMovie
        let sortedPurchaseData = SortRentPurchaseData(updatedPurchaseList);
        this.setState({
            isLoadingMyPurchasedMovies: false,
            errorMyPurchasedMovies: "",
            myPurchaseList: sortedPurchaseData,
            showPurchaseHistoryTags: true
        });
        if (cacheResults) {
            fetchFullPurchaseList();  //NOW FETCH ALL PURCHASED MOVIES TO UPDATE CACHE 
        }
    }

    // fecthWatchHistory --> function removed 
    

    removeMyListMovie = (e, id) => {
        const { appTranslations :tr } = this.props;

        e.preventDefault();
        e.stopPropagation();
        //let removingArr = this.state.removingMoviesList;
        //removingArr.unshift(id);
        this.setState({
            //removingMoviesList: removingArr,
            isremovingMovieLike: {
                ...this.state.isremovingMovieLike,
                [id]: true
            }
        });
        let successMessage =tr.removed_from_list || "Removed from my list" ;
        let errorMessage = tr.movie_couldnt_removed_from_my_list || "Movie couldn't removed from My List. Try Again"
        let header = { "MovieID": id };
        this.removeMovieFromMyList("UserAPI", "/myList", header, this.state.myMoviesList, "showSuccessAlert", "showErrorAlert", "alertMessage", successMessage,errorMessage, REMOVE_MYLIST_MOVIE, "", "myMoviesList").then((res) => {
            if (res !== "error") {
                this.setState({
                    isremovingMovieLike: {
                        ...this.state.isremovingMovieLike,
                        [id]: false
                    }
                });
                storeDataInLocalStore(STORED_MY_LIST, res, this.USER_ID, NO_EXPIRY);
            }
        });
    }
    //removeWatchedMovie -> function removed 

    handleMovieClick = () => {// this.props.history.push(`/movie/${movieID}`);
    }

    handleWatchMovieClick = (e, movieID, movie) => {
        e.preventDefault();
        e.stopPropagation();
        if (movie.expiringTime_ === "Expired") {
            this.props.history.push(`/movie/${movieID}`);
        } else {
            this.props.history.push(`${WATCH}${MOVIE_VIDEO}/${movieID}`);
        }
    }

    render() {
        return (
            <main className="mn-main" >
                <Header appTranslations ={this.props.appTranslations} activeTab={this.state.activeTab} onChangeTab={this.onChangeTab} />
               
                {this.renderNotificationMessage()}
               
                {this.state.activeTab === TabsList.myPurchases ?
                    <PurchaseHistory
                        myPurchaseList={this.state.myPurchaseList}
                        isLoadingMyPurchasedMovies={this.state.isLoadingMyPurchasedMovies}
                        errorMyPurchasedMovies={this.state.errorMyPurchasedMovies}
                        showPurchaseHistoryTags={!!this.state.showPurchaseHistoryTags}
                        moment={this.moment}
                        SERVERTIME={this.SERVERTIME}
                        purchaseTagsAlreadyDecided={this.purchaseTagsAlreadyDecided}
                        handleMovieClick={this.handleMovieClick}
                        handleWatchMovieClick={this.handleWatchMovieClick}
                        //purchaseHistoryLastEvaluatedKey={this.state.purchaseHistoryLastEvaluatedKey}
                        {...this.props}
                    />
                    :      
                    this.state.activeTab === TabsList.myCollections 
                    ? <MyCollections />
                    : this.state.activeTab === TabsList.subscriptions ?
                            <Subscriptions {...this.props}/>
                        :
                    this.state.activeTab === TabsList.watchHistory ?
                            <PurchaseListing {...this.props}/>
                        :
                        this.state.activeTab === TabsList.myList &&
                        <MyList
                            myMoviesList={this.state.myMoviesList}
                            isLoadingMyListMovies={this.state.isLoadingMyListMovies}
                            errorMyListMovies={this.state.errorMyListMovies}
                            showMyListTags={!!this.state.showMyListTags}
                            lastEvaluatedKey={this.state.myListLastEvaluatedKey}
                            isremovingMovieLike={this.state.isremovingMovieLike}
                            removeMyListMovie={this.removeMyListMovie}
                            handleMovieClick={this.handleMovieClick}
                            handleWatchMovieClick={this.handleWatchMovieClick}
                            handleFetchMoreMoviesClick={this.handleFetchMoreMoviesClick}
                            {...this.props} />
                }
            </main>
        );
    }
}
export default withAppConfig(Library);

const Header = memo(props => {
    const { appTranslations : tr , activeTab  } = props;
    return (
      <section className="mn-page-head py-4 bg-black">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-around">
            <Item
              onChangeTab={props.onChangeTab}
              currentTab={TabsList.myPurchases}
              Text={tr.my_rentals || "My Rentals"}
              activeTab={activeTab}
            />
            <Item
              onChangeTab={props.onChangeTab}
              currentTab={TabsList.myCollections}
              Text={tr.myCollections || "My Bundles"}
              activeTab={activeTab}
            />
            <Item
              onChangeTab={props.onChangeTab}
              currentTab={TabsList.subscriptions}
              Text={tr.my_screening_rooms || "My Channels"}
              activeTab={activeTab}
            />
            <Item
              onChangeTab={props.onChangeTab}
              currentTab={TabsList.myList}
              Text={tr.my_list || "My List"}
              activeTab={activeTab}
            />
            <Item
              onChangeTab={props.onChangeTab}
              currentTab={TabsList.watchHistory}
              Text={tr.my_history || "My History"}
              activeTab={activeTab}
            />
          </div>
        </div>
      </section>
    );
});

const Item = memo(props => {
    const { currentTab , Text , activeTab } = props ; 
    const onChangeTab = () => {
        props.onChangeTab(currentTab);
    }
    return(
        <div className={activeTab === currentTab ? "display-2" : "display-4"} >
        <a href="javascript:void(0)" onClick={onChangeTab} className={activeTab === currentTab ? "text-primary" : "link-white-50"}>{Text}</a>
    </div>
    )
});







/*<WatchHistory
    myWatchedList={this.state.myWatchedList}
    isLoadingMyWatchedMovies={this.state.isLoadingMyWatchedMovies}
    errorMyWatchedMovies={this.state.errorMyWatchedMovies}
    showWatchHistoryTags={this.state.showWatchHistoryTags}
    watchedListLastEvaluatedKey={this.state.watchedListLastEvaluatedKey}
    isremovingWatchedMovie={!!this.state.isremovingWatchedMovie}
    removeWatchedMovie={this.removeWatchedMovie}
    handleMovieClick={this.handleMovieClick}
    handleWatchMovieClick={this.handleWatchMovieClick}
    handleFetchMoreMoviesClick={this.handleFetchMoreMoviesClick}
    clearSearchHistory={this.clearSearchHistory}
    handleTitleClick={this.handleTitleClick}
    searchHistory={this.SEARCH_HISTORY}
    {...this.props}
/>*/