import React, { useState, useEffect, Fragment, memo } from "react";
import MovieCard from "../../../components/MovieCard/MovieCard";
import getScreeningRoomMovies from "../../../services/movies/GetRoomMovies";
import Loader from "../../../components/Loader/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import getMovies from "../../../services/movies/ListMovies";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";

const Listing = ({ HostName, ...props }) => {
  const [isLoading, setLoading] = useState(true);
  const [Data, setData] = useState({});
  const [isLoadingMore, setMoreLoading] = useState(false);
  const [ListingMovies, setListingMovies] = useState(true)
  useEffect(() => {
    initialize();
    return () => {};
  }, [props.isSubscribed_]); //props.allServiceMovies incase screening room is of allServiceMovies then useEffect will be called again to fetch all movies

  const initialize = () => {
    let params = {
      HostName: HostName,
      Items: Data.Items ? Data.Items : [],
      ID: props.match.params.id,
      isSubscribed_: props.isSubscribed_,
      subscriptions: props.subscriptions,
      isAuthenticated: props.isAuthenticated,
      Limit: 12,
      LastEvaluatedKey: Data.LastEvaluatedKey ? Data.LastEvaluatedKey : ``
    };
    if (props.allServiceMovies) {
      getMovies(onResponse, params);
    } else {
      getScreeningRoomMovies(onResponse, params);
    }
  };

  const onResponse = (response, err) => {
    if (!err) {
      setData(response);
    }
    setLoading(false);
    setMoreLoading(false);
  };

  const fetchMoreData = () => {
    setMoreLoading(true);
    initialize();
  };

  if (isLoading) {
    return (
      <section>
        <Loader />
      </section>
    );
  }

  return (
    <section className="mn-movies py-4 mt-2">
      <div className="container-fluid">
        <Fragment>
          {/* <div className="d-flex w-100 justify-content-between">
            <div className="">
              <h4 className="text-white-70 mb-3">
                Movies in this Screening Room
              </h4>
            </div>
          </div> */}
          {Data.Items && Data.Items.length <= 0 ? (
            <DataNotFound text="No Movies in this screening room" />
          ) : (
            <InfiniteScroll
              dataLength={Data.Items ? Data.Items.length : 0}
              next={fetchMoreData}
              hasMore={Data.LastEvaluatedKey ? true : false}
              loader={
                isLoadingMore && (
                  <div height="150px">
                    <Loader height="150px" />
                  </div>
                )
              }
            >
              <div className="ov-hidden">
                <div className="row mn-movies-grid ">
                  {Data.Items.map((movie, index) => {
                    return (
                      <MovieCard key={index} movie={movie} listingMovies={ListingMovies} className="mb-30" />
                    );
                  })}
                </div>
              </div>
            </InfiniteScroll>
          )}
        </Fragment>
      </div>
    </section>
  );
};

export default memo(Listing);
