import React, { Component } from "react";
import PropTypes from "prop-types";
import { withPaymentCard } from "../../../context/PaymentCardContext";
import RenderScreen from "../../../components/RenderScreen/RenderScreen";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";
import CreditCardNumber from "../../../components/CreditCardNumber/CreditCardNumber";
import LoaderButton from "../../../components/LoaderButton/LoaderButton";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import { withAppConfig } from "../../../context/ConfigContext";
import { Collapse } from "reactstrap";

class CardListing extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showCardModal: false,
            isOpen: false,
            showConfirmationModal: false,
            selectedDeleteItem: ""
        };
        this.toggleCard = this.toggleCard.bind(this)
    }

    componentDidMount() {
      
        this.props.getCardsList(true)
        this.setState({
            showCardModal: true
        });
    }
    onClickDeleteCard = (id) => {
        this.toggleConfirmationModal();
        this.setState({
            selectedDeleteItem: id
        })
    }
   
    toggleConfirmationModal = () => {
        this.setState((prevState) => {
            return {
                showConfirmationModal: !prevState.showConfirmationModal
            }
        })
    }

    onConfirmationClick = () => {
        this.toggleConfirmationModal();
        if (this.state.selectedDeleteItem) {
            this.props.deletePaymentCard(this.state.selectedDeleteItem)
        }
    }
    toggleCard = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }
    renderCardItem = (cardItem) => {
        const { appTranslations :tr } = this.props;
        const { CardMaskedNumber, CardHolderName, CardExpirationYear, CardExpirationMonth, Default, ID, CardType, CardImage } = cardItem;
        return (
            <div key={ID} className="card card-dark mb-3">
                <div className="card-header pointer text-white " onClick={this.toggleCard}> {/*data-toggle="collapse"  data-target={`#payment-${ID}`}*/}
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <CreditCardNumber
                                cardNumber={CardMaskedNumber}
                                cardType={CardType}
                                cardTypefont=""
                            />
                        </div>
                        <div className="d-flex align-items-center">
                            {Default && <span className="badge badge-light px-2 py-1 mr-3">{tr.default}</span>}
                            <i className="las la-angle-down"></i>
                        </div>
                    </div>
                </div>
                <Collapse isOpen = {this.state.isOpen}>    {/*id={`payment-${ID}`} className="collapse" data-parent="#paymentOptionGroup"*/}
                    <div className="card-body pb-0">
                        <div className="row gutter-5">
                            {/* {CardHolderName && <div className="col-md-6 mb-3">
                                <div className="text-white">{tr.name_on_card}</div>
                                <div>{CardHolderName}</div>
                            </div>
                            } */}
                            <div className="col-md-6 mb-3">
                                <div className="text-white">{tr.card_number}</div>
                                <div className="font-14 mn-points-2">
                                    <CreditCardNumber
                                        cardNumber={CardMaskedNumber}
                                        cardType={CardType}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row gutter-5">
                            <div className="col-md-6 mb-3">
                                <div className="text-white">{tr.card_expires}</div>
                                <div>{CardExpirationMonth && CardExpirationMonth} / {CardExpirationYear && CardExpirationYear}</div>
                            </div>
                            {/* <div className="col-md-6 mb-3">
                                <div className="text-white">Card Added on</div>
                                <div>11 / 6 / 2018</div>
                            </div> */}
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="text-right">
                            {!Default && <LoaderButton
                                type="submit"
                                onClick={() => this.props.makeCardDefault(ID)}
                                className="btn btn btn-primary ml-2"
                                isLoading={this.props.isMakingDefault[ID]}//{this.props.isDeletingCard}
                                disabled={this.props.isMakingDefault[ID]}
                                text={tr.make_as_default}
                                loadingText={tr.please_wait}
                            />
                            }
                            {/* <a href="#editPaymentOption" data-toggle="modal" className="btn btn-sm btn-secondary ml-2">Edit</a> */}
                            <LoaderButton
                                type="submit"
                                onClick={() => this.props.deletePaymentCard(ID)}
                                className="btn btn-danger ml-2"
                                isLoading={this.props.isDeletingCard[ID]}
                                disabled={this.props.isDeletingCard[ID]}
                                text={tr.delete}
                                loadingText={tr.deleting}
                            />
                        </div>
                    </div>
                </Collapse>
            </div>
        )
    } 
    
    render() {
        const { appTranslations :tr } = this.props;

        const { errorLoadingCards, isLoadingCards, cardsList } = this.props;
        return (
            <div id="paymentOptionGroup" className="text-white-50">
                <RenderScreen
                    isLoading={isLoadingCards}
                    isError={errorLoadingCards}
                    dataList={cardsList}
                >
                    {(cardsList && cardsList.length > 0) ?
                        cardsList.map((cardItem) => {
                            return (
                                this.renderCardItem(cardItem)
                            )
                        })
                        :
                        <DataNotFound
                            text={tr.no_cards_saved}
                            height="300px"
                        />
                    }
                </RenderScreen>
                <ConfirmationModal
                    showConfirmationModal={this.state.showConfirmationModal}
                    toggleModal={this.toggleConfirmationModal}
                    onConfirmationClick={this.onConfirmationClick}
                    Title = {tr.are_you_sure_you_want_to_delete ||"Are You Sure You want to Delete?"}
            />
            </div>
        )
    }
}

export default withPaymentCard(withAppConfig(CardListing));

CardListing.propTypes = {
    isLoadingCards: PropTypes.bool,
    errorLoadingCards: PropTypes.string,
    cardsList: PropTypes.array,
    isDeletingCard: PropTypes.object,
    isMakingDefault: PropTypes.object,
    deletePaymentCard: PropTypes.func,
    makeCardDefault: PropTypes.func,
}
