import React, { Component } from 'react';
import PropTypes from "prop-types";
import BitmovinPlayer from '../../../../components/BitmovinPlayer/BitmovinPlayer';
import HtmlVideoPlayer from '../../../../components/HtmlVideoPlayer/HtmlVideoPlayer';

class TrailerPlayer extends Component {
    isCompMounted = true;
    constructor(props) {
        super(props);
        this.trailerPlayer = null;
    }

    render() {
        const { movieStream, posterUrl, title, licenceUrl } = this.props;
        return (
            this.props.streamType === "DASH" ?
                <BitmovinPlayer
                    title={title}
                    movieStream={movieStream}
                    licenceUrl={licenceUrl}
                    posterUrl={posterUrl}
                    onVideoPlay={this.props.onVideoPlay}
                    onVideoResume={this.props.onVideoResume}
                    onVideoPause={this.props.onVideoPause}
                    onVideoError={this.props.onVideoError}
                    onVideoEnd={this.props.onVideoEnd}
                    onError={this.props.onError}
                    onPlayerDestroyed={this.props.onPlayerDestroyed}
                />
                :
                <div className="mn-media-player">
                    <HtmlVideoPlayer
                        title={title}
                        autoPlay={false} //becuase ios doesnt support autoplay -> specialy safari
                        onVideoPlay={this.props.onVideoPlay}
                        onVideoResume={this.props.onVideoResume}
                        onVideoPause={this.props.onVideoPause}
                        onVideoError={this.props.onVideoError}
                        onVideoEnd={this.props.onVideoEnd}
                        onError={this.props.onError}
                        posterUrl={posterUrl}
                        videoUrl={movieStream}
                        ref={player => this.trailerPlayer = player}
                    />
                </div>
        )
    }
}

export { TrailerPlayer };

TrailerPlayer.defaultProps = {
    title: "",
    posterUrl: null,
    playerStartingTime: 0,
    streamType: "PLAIN",
    licenceUrl: ""
}
TrailerPlayer.propTypes = {
    title: PropTypes.string,
    streamType: PropTypes.string.isRequired,
    movieId: PropTypes.string.isRequired,
    movieStream: PropTypes.string.isRequired,
    licenceUrl: PropTypes.string,
    movieData: PropTypes.object.isRequired,
    posterUrl: PropTypes.string,
    playerStartingTime: PropTypes.number,
    onVideoPlay: PropTypes.func,
    onVideoResume: PropTypes.func,
    onVideoPause: PropTypes.func,
    onVideoError: PropTypes.func,
    onVideoEnd: PropTypes.func,
    onError: PropTypes.func,
    onPlayerDestroyed: PropTypes.func,
    handleBackButtonClick: PropTypes.func,
}