import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import { LOGOUT, GOOGLE, FACEBOOK, SOCIAL_LOGIN_TYPE } from "../../constants/constants";
import Auth from "@aws-amplify/auth";
import {storeDataInLocalStore} from "../../helpers/GeneralHelpers";

class MySocialLogin extends Component {
    isCompMounted = true;
    constructor(props) {
        super(props);
    }

     googleLogin= async (e)=> {
        e !== null && e.preventDefault();
        const config = Auth.configure();
        await storeDataInLocalStore(SOCIAL_LOGIN_TYPE,"Google");
        const {
            domain,
            redirectSignIn,
            responseType } = config.oauth;
        const clientId = Auth.configure().userPoolWebClientId;
        const url_to_google = 'https://' + domain + '/oauth2/authorize?redirect_uri=' + redirectSignIn + '&response_type=' + responseType + '&client_id=' + clientId + '&identity_provider=Google';
        window.location.assign(url_to_google);
    };

    facebookLogin =async  (e) => {
       await  storeDataInLocalStore(SOCIAL_LOGIN_TYPE,"Facebook");
        e !== null && e.preventDefault();
        const config = Auth.configure();
        const {
            domain,
            redirectSignIn,
            responseType } = config.oauth;
        const clientId = config.userPoolWebClientId;
        this.clientId = clientId;
        const url_to_facebook = 'https://' + domain + '/oauth2/authorize?redirect_uri=' + redirectSignIn + '&response_type=' + responseType + '&client_id=' + clientId + '&identity_provider=Facebook';
        window.location.assign(url_to_facebook);
    }

    componentWillUnmount() {
        this.isCompMounted = false;
    }

    render() {
        const { action, className, loginText, logoutText, type } = this.props;
        return (
            type === FACEBOOK ?
                action === LOGOUT ?
                    <button onClick={this.facebookLogout} className={`dropdown-item ${className}`}>{logoutText}</button>
                    :
                    <button onClick={this.facebookLogin} className={`btn btn-block ${className}`} ><i className="lab la-facebook-f font-16 mr-1"></i> {loginText}</button>
                :
                action === LOGOUT ?
                    <button onClick={this.googleLogout} className={`dropdown-item ${className}`}>{logoutText}</button>
                    :
                    <button onClick={this.googleLogin} className={`btn btn-block ${className}`} ><i className="lab la-google font-16 mr-1"></i> {loginText}</button>
        )
    }
}

MySocialLogin.defaultProps = {
    loginText: "Login",
    logoutText: "Logout",
    className: "",
    type: GOOGLE
}

MySocialLogin.propTypes = {
    action: PropTypes.string.isRequired,
    className: PropTypes.string,
    loginText: PropTypes.string,
    logoutText: PropTypes.string,
    type: PropTypes.string
}

export default withRouter(MySocialLogin);
