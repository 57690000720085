import { getCurrentUserId, getDataFromLocalStorage } from "./GeneralHelpers";
import { updateCacheOnRefreshNotification } from "./CacheHelpers";
import { seenNotificationRequest } from "./APIHelpers/NotificationsAPI";
import { NOTICATIONS_REFRESH_ALL, NOTICATIONS_REFRESH_SUBSCRIPTIONS, NOTIFICATIONS_REFRESH_FORGET_SESSION, NOTICATIONS_REFRESH_CONFIG, NOTICATIONS_REFRESH_MOVIE, NOTICATIONS_REFRESH_SECTIONS, NOTICATIONS_REFRESH_BANNERS,NOTICATIONS_REFRESH_COLLECTIONS, NOTICATIONS_REFRESH_MYLIST, NOTICATIONS_REFRESH_PURCHASELIST, NOTICATIONS_REFRESH_WATCHLIST, STORED_NOTIFICATIONS_STATUS, STORED_NOTIFICATIONS_TOKEN, STORED_USER_SESSION_ID } from "../constants/constants";

const CacheItems = [NOTICATIONS_REFRESH_ALL, NOTICATIONS_REFRESH_CONFIG, NOTICATIONS_REFRESH_MOVIE, NOTICATIONS_REFRESH_SECTIONS, NOTICATIONS_REFRESH_BANNERS, { "user": [NOTICATIONS_REFRESH_SUBSCRIPTIONS, NOTICATIONS_REFRESH_COLLECTIONS, NOTICATIONS_REFRESH_MYLIST, NOTICATIONS_REFRESH_PURCHASELIST, NOTICATIONS_REFRESH_WATCHLIST] }];

export async function initializeDBListeners(firebase) {
    let userId = await getCurrentUserId();
    CacheItems.forEach((item) => {
        if (typeof item === "string") {
            let updatekey = `cache/${item}`;
            this.onChangeListener(firebase, updatekey, item);
        } else if (typeof item === "object") {
            if (userId) {
                item["user"].forEach((userItem) => {
                    let updatekey = `cache/users/${userId}/${userItem}`;
                    this.onChangeListener(firebase, updatekey, userItem, userId);
                });
            }
        }
    });
    let sessionID = getDataFromLocalStorage(STORED_USER_SESSION_ID, userId);
    if (sessionID) {
        let updatekey = `users/${userId}/userSessions/${sessionID}/status`;
        this.onChangeListener(firebase, updatekey, NOTIFICATIONS_REFRESH_FORGET_SESSION, userId)
    }
}

export function onChangeListener(firebase, updatekey, item, userId = null) {
    let databaseRef = firebase.getDataBaseReference(updatekey, userId);
    if (databaseRef) {
        databaseRef.on('value',  (snapshot)=> {
            let notifiedValue = {status : snapshot.val()};
            if (snapshot) {
                if(notifiedValue.status === 'inactive'){
                    this.onForceLogout();
                }
                updateCacheOnRefreshNotification(item, userId, notifiedValue);
            }
        });
    }
}


export function handleSeenNotification(CreatedAt, notificationId, notificationsData) {
    if (CreatedAt) {
        seenNotificationRequest(CreatedAt, notificationId)
    }
}

export async function getUserNotificationToken(firebase) {
    let status = getDataFromLocalStorage(STORED_NOTIFICATIONS_STATUS);
    let token;
    if (!status) {
        token = await firebase.getFirebaseToken();
    } else {
        if (status === "DISABLED") {
            token = ""
        } else {
            let token = getDataFromLocalStorage(STORED_NOTIFICATIONS_TOKEN);
            if (!token) {
                token = await firebase.getFirebaseToken();
            }
        }
    }
    return token;
}