import { GET } from "../../api/";
import { STORED_MY_PGRATING_VALUE } from "../../constants/constants";
import { storeDataInLocalStore } from "../../helpers/GeneralHelpers";

const GetUserPreferences = ({ UserID }) => {
  return GET("AuthAPI", "/getuserpreferences")
    .then(async res => {
      storeDataInLocalStore(
        STORED_MY_PGRATING_VALUE,
        res.body.UserPreferences.ParentalControlSetting,
        UserID
      ); //res

      return res && res.body && res.body.UserPreferences
        ? res.body.UserPreferences
        : {};
    })
    .catch(() => {
      return {};
    });
};
export default GetUserPreferences;
