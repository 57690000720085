import React, { Component } from "react";
import CardsList from "../../../components/CardsList/CardList";
import CustomSlickSlider from "../../../components/CustomSlickSlider/CustomSlickSlider";
import { querystring } from "../../../helpers/GeneralHelpers";
import PropTypes from "prop-types";
import _findIndex from "lodash/findIndex";
import CustomDropDown from "../../../components/CustomDropDown/CustomDropDown";
import { withAppConfig } from "../../../context/ConfigContext";

class Genere extends Component {

    renderMoviesGrid() {
        let type = this.props.match.params.type ? this.props.match.params.type : "";
        const { handleMovieClick, handleFetchMoreClick } = this.props;
        return (
            <CardsList
                {...this.props}
                showCardHoverIcons={this.props.showGenreMovieTags}
                isLoading={this.props.isLoading}
                isError={this.props.isError}
                data={this.props.moviesList}
                isLoadingMore={this.props.isLoadingMore}
                lastEvaluatedKey={this.props.lastEvaluatedKey}
                fetchMoreData={handleFetchMoreClick}
                handleMovieClick={handleMovieClick}
                type={type}
            />
        )
    }

    render() {
        let name = querystring("name");
        let selectedGenreIndex = _findIndex(this.props.genreFilters, (o)=> {
            return o.Value === this.props.selectedGenre;
          });
        const { appTranslations : tr } = this.props;
        return (
            <main className="mn-main">
                <section className="mn-page-head py-3 bg-black">
                    <div className="container-fluid">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="display-4">
                                <span className="text-white font-weight-bold">{name}</span>
                            </div>
                            <div className="font-12">
                                {(this.props.showGenereFiltersSlider && this.props.selectedSort) &&
                                    <div>
                                        <CustomDropDown
                                            dataList={this.props.sortList}
                                            onDropDownItemChanged={this.props.handleOnSortClick}
                                            initialValue={this.props.selectedSort}// === "year" ? "Release Date" : ""}//year->default 
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                {this.props.showGenereFiltersSlider &&
                    <section className="mn-genre border-bottom border-dark py-3">
                        <div className="container-fluid">
                            <div className="mb-3">{tr.genre_in || "Genres in"} {name}</div>
                            {this.props.selectedGenre && 
                            <CustomSlickSlider
                                    dataList={this.props.genreFilters}
                                    initialIndex={selectedGenreIndex}
                                    selectedGenre={this.props.selectedGenre}
                                    handleFilterItemClick={this.props.handleFilterItemClick}
                                />
                            }
                        </div>
                    </section>
                }
                {this.renderMoviesGrid()}
            </main>
        )
    }
}

export default withAppConfig(Genere);
Genere.defaultProps = {
    genreFilters: [],
    showGenereFiltersSlider: false
}

Genere.propTypes = {
    genreFilters: PropTypes.array,
    showGenereFiltersSlider: PropTypes.bool,
    showCardHoverIcons: PropTypes.bool,
    isLoading: PropTypes.bool,
    isError: PropTypes.string,
    moviesList: PropTypes.array,
    isLoadingMore: PropTypes.bool,
    lastEvaluatedKey: PropTypes.string,
    showGenreMovieTags: PropTypes.bool,
    handleFilterItemClick: PropTypes.func.isRequired,
    handleFetchMoreClick: PropTypes.func.isRequired,
    handleMovieClick: PropTypes.func.isRequired,
    match: PropTypes.object,
    selectedSort: PropTypes.string,
    handleOnSortClick: PropTypes.func
}