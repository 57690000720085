export const controls = `
<button type="button" className="plyr__control plyr__control--overlaid" data-plyr="play" aria-pressed="false">
    
</button>
<div className="plyr__controls">
    <div className="plyr__controls-top d-flex">
        <div className="plyr__time plyr__time--current" aria-label="Current time">00:00</div>
        <div className="plyr__progress">
            <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
            <progress className="plyr__progress__buffer" min="0" max="100" value="0">% buffered</progress>
            <span role="tooltip" className="plyr__tooltip">00:00</span>
        </div>
        <div className="plyr__time plyr__time--duration" aria-label="Duration">00:00</div>
    </div>
    <div className="plyr__controls-btm d-flex justify-content-between">
        <div className="d-flex">
            <button type="button" className="plyr__control plyr-play" aria-label="Play, {title}" data-plyr="play">
                <span className="icon--pressed"></span>
                <span className="icon--not-pressed"></span>
            </button>
            <button type="button" className="plyr__control" aria-label="Mute" data-plyr="mute">
                <span className="icon--pressed"></span>
                <span className="icon--not-pressed"></span>
            </button>
            <div className="plyr__volume">
                <input data-plyr="volume" type="range" min="0" max="1" step="0.05" value="1" autoComplete="off" aria-label="Volume">
            </div>
        </div>
        <div className="d-flex">
            <button type="button" className="plyr__control" data-plyr="fullscreen">
                <span className="icon--pressed"></span>
                <span className="icon--not-pressed"></span>
            </button>
        </div>
    </div>
</div>
`;
{/* <button type="button" className="plyr__control" data-plyr="settings">
                <span className="icon--pressed"></span>
                <span className="icon--not-pressed"></span>
            </button>
            <button type="button" className="plyr__control" data-plyr="cast">
                <span className="icon--pressed"></span>
                <span className="icon--not-pressed"></span>
            </button> */}