
export const getInputFields = tr => {
  return [
    {
      Name: "oldPassword",
      Title: "Current Password",
      Placeholder: tr.current_password,
      Validations: "minLength: 6",
      ValidationError: tr.password_length_should_be_6_to_25_charac
    },
    {
      Name: "newPassword",
      Title: "New Password",
      Placeholder: tr.new_password,
      Validations: {
        minLength: 6,
        maxLength: 25
      },
      ValidationErrors: {
        minLength: tr.password_length_should_be_6_to_25_charac,
        maxLength: tr.password_length_should_be_6_to_25_charac
      }
    },
    {
      Name: "confirm-password",
      Title: "Confirm Password",
      Placeholder: tr.confirm_password,
      Validations: "equalsField:newPassword",
      ValidationError: tr.new_password_and_confirm_password_does_n
    }
  ];
};
