import React, { Component } from "react";
import API from "@aws-amplify/api";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import RenderScreen from './../../components/RenderScreen/RenderScreen';
import { MOVIE } from "../../constants/routes";
import { NOTIFICATIONS_PAGE, STORED_MY_NOTIFICATIONS, NO_EXPIRY } from "../../constants/constants";
import { setDocumentTitle, getDataFromLocalStorage, removeObjectFromData, storeDataInLocalStore, isEmpty, getCurrentUserId } from "../../helpers/GeneralHelpers";
import { disableAlert } from "../../helpers/StateHelper";
import { renderNotificationMessage } from "../../helpers/ViewHelpers";
import { seenNotificationRequest, countUnseenNotifications, getNotificationsList } from "../../helpers/APIHelpers/NotificationsAPI";
import NotificationsView from "./components/NotificationsView"
import { withFirebase } from "../../context/FirebaseContext";

class Notifications extends Component {
    isCompMounted = true
    USER_ID = ""
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isViewingNotification: {},
            notificationsData: [],
            isremovingNotification: {},
            isError: "",
            showErrorAlert: false,
            showSuccessAlert: false,
            alertMessage: "",
        }
        this.disableAlert = disableAlert.bind(this);
        this.renderNotificationMessage = renderNotificationMessage.bind(this);
    }

    componentDidMount() {
        this.fetchNotificationsData();
        setDocumentTitle(NOTIFICATIONS_PAGE);
    }

    componentWillUnmount() {
        this.isCompMounted = true;
    }

    async fetchNotificationsData() {
        if (!this.USER_ID) {
            this.USER_ID = await getCurrentUserId();
        }
        let notificationList = getDataFromLocalStorage(STORED_MY_NOTIFICATIONS, this.USER_ID);
        if (!notificationList || isEmpty(notificationList)) {
            notificationList = await getNotificationsList(this.USER_ID).then((res) => {
                let notfications = [];
                if (this.isCompMounted) {
                    if (res && res !== "Error") {
                        notfications = res//res.body ? res.body.Items : [];
                        if (!isEmpty(notfications)) {
                            storeDataInLocalStore(STORED_MY_NOTIFICATIONS, notfications, this.USER_ID, NO_EXPIRY)
                        }
                    } else {
                        notfications = null;
                    }
                }
                return notfications;
            });
        }
        await countUnseenNotifications(notificationList, this.USER_ID);
        this.props.handleOnNotificationsFetched(notificationList, this.USER_ID);
        this.setState({
            isLoading: false,
            isError: notificationList ? "" : "Data Not Found!",
            notificationsData: notificationList ? notificationList : [] //handle explicit null
        });
        if (notificationList.length < 20) { //notifications list -- UPDATE notifications list cache!
            //getNotificationsList()
        }
    }

    updateDataOnSeen = async (data, CreatedAt, notificationId, movieID) => {
        let isSeen = true;
        let notificationList = this.state.notificationsData;
        let match = _find(notificationList, ['CreatedAt', CreatedAt]);
        let seenNotificationIndex = _findIndex(notificationList, ['CreatedAt', CreatedAt]);
        if (seenNotificationIndex !== -1) {
            match["isSeen"] = isSeen;
            notificationList.splice(seenNotificationIndex, 1, match);
            if (this.isCompMounted) {
                this.setState({
                    notificationsData: notificationList,
                    isViewingNotification: {
                        ...this.state.isViewingNotification,
                        [notificationId]: false
                    }
                })
            }
            storeDataInLocalStore(STORED_MY_NOTIFICATIONS, notificationList, this.USER_ID, NO_EXPIRY);
            await countUnseenNotifications(notificationList, this.USER_ID);
            this.props.handleOnNotificationsFetched(notificationList, this.USER_ID);
        }
    }

    handleNotificationClick = (e, CreatedAt, notificationId, movieID, isSeen, isMovieNameClicked = false) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isSeen) {
            if (!this.state.isViewingNotification[notificationId]) {
                this.setState({
                    isViewingNotification: {
                        ...this.state.isViewingNotification,
                        [notificationId]: true
                    }
                })
                seenNotificationRequest(CreatedAt, notificationId).then((res) => {
                    if (res !== "Error" && this.isCompMounted) {
                        this.updateDataOnSeen(res, CreatedAt, notificationId, movieID)
                    }
                });
            }
        }
        if (isMovieNameClicked) {
            this.props.history.push(`${MOVIE}${movieID}`);
        }
    }

    handleDeleteNotificationClick = (e, notificationId, CreatedAt) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            ...this.state.isremovingNotification,
            isremovingNotification: {
                [notificationId]: true
            }
        })
        let apiName = 'NotificationsAPI';
        let path = '/notification/delete';
        let myInit = {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: { CreatedAt: CreatedAt }
        }
        return API.post(apiName, path, myInit).then(async (res) => {
            if (res.statusCode === 200) {
                let udpatedData;
                this.setState((prevState) => {
                    udpatedData = removeObjectFromData(prevState.notificationsData, "CreatedAt", CreatedAt);
                    return {
                        notificationsData: udpatedData,
                        isremovingNotification: {
                            ...prevState.isremovingNotification,
                            [notificationId]: false
                        },
                        showSuccessAlert: true,
                        alertMessage: "Notification Deleted Successfully!"
                    }
                });
                this.disableAlert("showSuccessAlert", "alertMessage");
                await countUnseenNotifications(udpatedData, this.USER_ID);
                storeDataInLocalStore(STORED_MY_NOTIFICATIONS, udpatedData, this.USER_ID, NO_EXPIRY);
                this.props.handleOnNotificationsFetched(udpatedData, this.USER_ID);
            }
        }).catch((err) => {
            this.setState((prevState) => {
                return {
                    isremovingNotification: {
                        ...prevState.isremovingNotification,
                        [notificationId]: false
                    },
                    showErrorAlert: true,
                    alertMessage: "Couldn't Delete Notification!"
                }
            });
            this.disableAlert("showErrorAlert", "alertMessage")
        });
    }

    render() {
        return (
            <main className="mn-main">
                {this.renderNotificationMessage()}
                <RenderScreen
                    isLoading={this.state.isLoading}
                    isError={this.state.isError}
                >
                    <NotificationsView
                        notificationsData={this.state.notificationsData}
                        //handleMovieClick={this.handleMovieClick}
                        handleNotificationClick={this.handleNotificationClick}
                        handleDeleteNotificationClick={this.handleDeleteNotificationClick}
                        isremovingNotification={this.state.isremovingNotification}
                    />
                </RenderScreen>
            </main>
        )
    }
}

export default withFirebase(Notifications);
