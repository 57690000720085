import React, { PureComponent, Fragment } from "react";
import PurchaseButtons from "./PurchaseButtons";
import { withRouter } from "react-router-dom";
import PurchaseModal from "../../../components/PurchaseModal/PurchaseModal";
import TransactionModal from "../../../components/TransactionModal/TransactionModal";
import { withAuth } from "../../../context/AuthContext";
import { checkIsMovieFreeWatch } from "../../../helpers/MoviesHelper";
import SubscribeModal from "../../../components/SubscribeModal/SubscribeModal";

class RentalLocks extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { showPurchaseModel: false, showSubscribeModal: false };
  }

  handleWatchClick = () => {
    if (this.state.paymentWasSuccessfull) {
      this.setState({
        paymentWasSuccessfull: false
      });
    }
    if (this.props.isAuthenticated) {
      this.props.history.push(`/watch/movie/${this.props.banner.MovieID}`);
    } else {
      this.props.history.push(
        `/login?redirect=${this.props.location.pathname}${
        this.props.location.search
        }`
      );
    }
  };

  onPurchaseClick = (type) => {
    this.setState({
      selectedPurchaseMovieId: this.props.banner.MovieID,
      selectedPurchaseMovieType: type,
      showPurchaseModel: true
    });
  };

  onSubscribeClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      showSubscribeModal: true
    });
  };

  handleFreeWatch = e => {
    e.preventDefault();
    e.stopPropagation();
    this.onPurchaseClick("tvod");
  };

  handleSelectedQualityVideo = value => {
    this.setState({
      selectedPurchaseTierId: value
    });
  };

  dismissPurchaseModal = (paymentSucess = true) => {
    this.setState({
      paymentWasSuccessfull: paymentSucess,
      showPurchaseModel: false
    });
  };

  handleBackDropClick = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      showPurchaseModel: false
    });
  };

  handleMovieClick = (e, movieID) => {
    this.setState({
      paymentWasSuccessfull: false
    });
    this.props.history.push(`/movie/${movieID}`);
  };

  renderSubscribeModal = (banner)=>{
    const activeScreeningRoom = banner.ScreeningRooms.find((sr)=> sr.Status == 'active');
    return (
        <SubscribeModal
            showSubscribeModal={this.state.showSubscribeModal}
            dismissPurchaseModal={()=> this.setState({showSubscribeModal: false})}
            handleBackDropClick={()=> this.setState({showSubscribeModal: false})}
            isAuthenticated={this.props.isAuthenticated}
            data={activeScreeningRoom}
        />)
  }

  render() {
    const { banner, isAuthenticated } = this.props;
    let watchClass =
      "btn btn-primary btn-large  mr-3 mb-3";
    let freeWatchClass = "btn btn-primary  mr-3 mb-3";
    if (isAuthenticated && !banner.isExpired) {
      return (
        <a onClick={this.handleWatchClick} className={watchClass}>
          <span className="font-weight-bold">Watch Movie</span>
        </a>
      );
    }
    let isFreeWatch = checkIsMovieFreeWatch(banner, false);
    return (
      <Fragment>
        {isFreeWatch ? (
          <a onClick={this.handleFreeWatch} className={freeWatchClass}>
            FREE WATCH
          </a>
        ) : (
          <PurchaseButtons
            banner={banner}
            onPurchaseClick={this.onPurchaseClick}
            onSubscribeClick={this.onSubscribeClick}
          />
        )}
        {this.state.showPurchaseModel ? (
          <PurchaseModal
            showPurchaseModel={this.state.showPurchaseModel}
            dismissPurchaseModal={this.dismissPurchaseModal}
            handleSelectedQualityVideo={this.handleSelectedQualityVideo}
            data={banner}
            movieId={banner.MovieID}
            type={this.state.selectedPurchaseMovieType}
            handleBackDropClick={this.handleBackDropClick}
          />
        ) : null}
        {this.state.showSubscribeModal? this.renderSubscribeModal(banner): null}

        {
          <SuccessModal
            showModal={this.state.paymentWasSuccessfull}
            banner={banner}
            movieClick={this.handleMovieClick}
            watchClick={this.handleWatchClick}
          />
        }
      </Fragment>
    );
  }
}

export default withRouter(withAuth(RentalLocks));

const SuccessModal = ({ showModal, movieClick, watchClick, banner }) => {
  if (showModal) {
    const {
      MovieTitle,
      RentMaxValidity,
      RentTvodValidity = ``,
      MovieID
    } = banner;
    return (
      <TransactionModal
        Title={MovieTitle}
        ModalHeaderTitle="Free Watch Successfull"
        RentMaxValidity={`${RentMaxValidity} days `}
        MovieID={MovieID}
        handleWatchMovieClick={watchClick}
        handleMovieClick={movieClick}
        RentTvodValidity={`${RentTvodValidity} ${
          RentTvodValidity > 1 ? "Hours" : "Hour"
          }`}
      />
    );
  }
  return null;
};
