import React, { useState, useEffect } from "react";
import _isEmpty from "lodash/isEmpty";
import { withRouter } from "react-router-dom";
import { withAppConfig } from "../../../context/ConfigContext";
import Loader from "../../../components/Loader/Loader";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";
import RoomsCard from "../../../components/RoomsCard";
import { withAuth } from "../../../context/AuthContext";

const MySubscriptions = props => {
  const [detail, setDetail] = useState([]);

  useEffect(() => {
    initialize();    
    return () => {};
  }, [props.subscriptions]); //update the component when subscriptions is updated !!

  const initialize = () => {
    setDetail(props.subscriptions);
  };

  if (props.updatingSubscriptions) {
    return (
      <section>
        <Loader />
      </section>
    );
  }
   
  if (_isEmpty(detail)) {
    return (
      <div>
        <section className="mn-page-head py-4 bg-gray-800">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <h3 className="text-white text-uppercase font-weight-bold mb-0">my Subscriptions</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="mn-movies py-4">
        <div className="container-fluid">
          <DataNotFound text="You have no item(s) in My Subscriptions" />
        </div>
      </section>
      </div>
    
    );
  }
  return (
    <main className="mn-main">
      <section className="mn-page-head py-4 bg-gray-800">
          <div className="container-fluid">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <h3 className="text-white text-uppercase font-weight-bold mb-0">my Subscriptions</h3>
              </div>
            </div>
          </div>
        </section>
      <section className="mn-movies py-5">
          <div className="container-fluid">
            <div className="row">
            {detail.map((room, index) => {
                return (
                  <RoomsCard
                    key={index}
                    isSubscribed_={true} //assuming that mysubscriptions api returns active subscriptions
                    showHovers={false}
                    classname="mb-4"
                    {...room}
                    screeningRoom = {true}
                    
                  />
                );
              })}
            </div>
          </div>
        </section>
      </main>
  );
};

export default withAppConfig(withRouter(withAuth(MySubscriptions)));
