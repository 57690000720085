import React, { Component } from 'react';
import MoviePlayer from "./MoviePlayer/MoviePlayer";
import _isEmpty from "lodash/isEmpty";
import _includes from "lodash/includes";
import TrailerPlayer from "./TrailerPlayer/TrailerPlayer";
import { renderNotificationMessage, renderPinForm } from "../../helpers/ViewHelpers";
import { showError, getDataFromLocalStorage, storeDataInLocalStore, focusNextField, isEmpty, isValueFoundInData, setDocumentTitle, querystring, handlePinChange, getCurrentUserId } from "../../helpers/GeneralHelpers";
import { disableAlert } from "../../helpers/StateHelper";
import { MOVIE_VIDEO, TRAILER_VIDEO, STORED_MOVIES_DETAIL, FETCH_MOVIE_PLAY_VIDEO, WATCH_PAGE, CALLBACK, NO_EXPIRY } from "../../constants/constants";
import { checkisIOS } from "../../helpers/PlayerHelpers";
import { getApiData } from "../../helpers/APIHelpers/MoviesAPI";
import { handleVerifyPinClick, getUserParentalControlSettings, getPGRatingList, getPGRatingValue } from "../../helpers/ConfigHelpers";
import { HOME, WATCH } from "../../constants/routes";
import { getTrailerStream } from '../../helpers/MoviesHelper';
import RenderScreen from '../../components/RenderScreen/RenderScreen';
import Loader from '../../components/Loader/Loader';
import { withAppConfig } from '../../context/ConfigContext';
import {withAuth} from "../../context/AuthContext";
import DataNotFound from '../../components/DataNotFound/DataNotFound';
const PlayingFirstVideo = 1;

class PlayVideo extends Component {
    playedRelated = PlayingFirstVideo;
    videoType = this.props.match.params.type;
    videoId = this.props.match.params.id;
    isCompMounted = true;
    constructor(props) {
        super(props);
        this.state = {
            error: "",
            showErrorAlert: false,
            showWarningAlert: false,
            seekingStartTime: 0,
            isLoadingMovieData: true,
            dataErrorMovie: "",
            movieData: "",
            moviePlayerAllowed: false,
            isEncryptedStream: true,
            isVerifyingWatch: false,
            showPinForm: false,
            alertMessage: "",
            pinDigit1: "",
            pinDigit2: "",
            pinDigit3: "",
            pinDigit4: "",
        }
        this.showError = showError.bind(this);
        this.fetchMovieData = getApiData.bind(this);
        this.disableAlert = disableAlert.bind(this);
        this.checkVideoType = this.checkVideoType.bind(this);
        this.renderNotificationMessage = renderNotificationMessage.bind(this);
        this.handleChange = handlePinChange.bind(this);
        this.focusNextField = focusNextField.bind(this);
        this.renderPinForm = renderPinForm.bind(this);
        this.handleVerifyPinClick = handleVerifyPinClick.bind(this);
        this.inputs = {};
    }

    componentDidMount() {
        this.initialize();
        setDocumentTitle(WATCH_PAGE);
    }

    componentDidUpdate(prevProps) {
        if (this.state.movieData) {
            setDocumentTitle(this.state.movieData.Title ? `${WATCH_PAGE} - ${this.state.movieData.Title}` : WATCH_PAGE);
        }
        if (prevProps.isAuthenticated !== this.props.isAuthenticated && !this.props.isAuthenticated && this.videoType === MOVIE_VIDEO) {
            window.location.reload();
        }
    }

    componentWillUnmount() {
        this.isCompMounted = false;
    }

    initialize = () => {
        let isUrlInvalid = false;
        if (this.videoId && this.videoId !== MOVIE_VIDEO && this.videoId !== TRAILER_VIDEO) {
            if (this.videoType === MOVIE_VIDEO) {
                if (this.props.isAuthenticated) {
                    this.fetchMovie(this.videoId, MOVIE_VIDEO);//play movie
                } else {
                    this.props.history.push(`/login?redirect=${WATCH}${MOVIE_VIDEO}/${this.videoId}`);
                }
            } else {
                if (this.videoType === TRAILER_VIDEO) {
                    this.fetchMovie(this.videoId, MOVIE_VIDEO);//play trialer
                } else {
                    isUrlInvalid = true;
                }
            }
        } else {
            isUrlInvalid = true;
        }
        if (isUrlInvalid) {
            this.props.history.push(HOME);
        }
    }

    fetchMovie = (movieID, type, status) => {
        if (status === CALLBACK) {
            this.playedRelated = this.playedRelated + 1
        }
        this.setState({
            isLoadingMovieData: true
        }, async () => { //need after isloading true => to destroy player!
            let movie = getDataFromLocalStorage(`${STORED_MOVIES_DETAIL}${movieID}`);
            if (!movie) {
                movie = await this.fetchMovieData("MoviesAPI", `/get/${movieID}`, this.state.movieData, "", "dataErrorMovie", "movieData", FETCH_MOVIE_PLAY_VIDEO).then((movie) => {
                    if (!isEmpty(movie)) {
                        storeDataInLocalStore(`${STORED_MOVIES_DETAIL}${this.props.match.params.id}`, movie, null, NO_EXPIRY);
                    } else {
                        this.props.history.goBack(); //Some Error Occurred!!
                        return;
                    }
                    return movie
                });
            }
            if (this.isCompMounted) {
                this.setState({
                    isLoadingMovieData: (this.videoType === MOVIE_VIDEO) ? false : true, //loading false only in bitmovin case else still true
                    dataErrorMovie: "",
                    movieData: movie
                }, () => {
                    this.checkVideoType(movie);
                });
            }
            if (this.videoType === MOVIE_VIDEO) {
                this.verifyUserParentalLockSettings(movie).then((res) => {
                    if (res === "Allow") {
                        this.handleAllowMoiveClick(); //===>Review later
                    }
                })
            }
        })
    }

    checkVideoType = async (movie) => {
        if (this.videoType === TRAILER_VIDEO) {
            let shakaVideoTypes = ["mpd", "m3u8"];//update it later
            const trailerStreamURL = this.getTrailerStream();
            let isEncryptedStream = false;
            shakaVideoTypes.forEach((item) => {
                if (isValueFoundInData(trailerStreamURL, item)) {
                    isEncryptedStream = true
                }
            });
            let isIOS = await checkisIOS();
            if (isIOS) {
                isEncryptedStream = false
            }
            this.setState({
                isEncryptedStream: isEncryptedStream,
                isLoadingMovieData: false //only in shakaPlayercase
            })
        }
    }

    verifyUserParentalLockSettings = async (movie) => {
        const { appTranslations :tr } = this.props;
        let allowPlayer = new Promise(async (resolve, reject) => {
            if (movie && movie.PGRating) {
                this.setState({
                    isVerifyingWatch: true
                });
                let userId = await getCurrentUserId();
                let userParentalControlSettings = await getUserParentalControlSettings(userId); // .then((res) => { // res.body.UserPreferences.ParentalControlSetting ? RES
                let pgRatingList = getPGRatingList();
                let arrayPgRating = pgRatingList.find(function (element) {
                    return element.ID === userParentalControlSettings;
                });
                let indexOfPgRating = pgRatingList.indexOf(arrayPgRating);
                if (userParentalControlSettings && userParentalControlSettings !== "" && userParentalControlSettings !== "N-A" && indexOfPgRating !== (pgRatingList.length - 1)) {
                    let moviepgRatingValue = getPGRatingValue(pgRatingList, movie.PGRating);
                    let userpgRatingValue = getPGRatingValue(pgRatingList, userParentalControlSettings);
                    if (moviepgRatingValue > userpgRatingValue || indexOfPgRating === 0) { //COMPARE HERE!!
                        this.setState({
                            showWarningAlert: true,
                            alertMessage: tr.error_this_movie_is_restricted,
                        });
                        this.disableAlert("showWarningAlert", "alertMessage");
                        this.setState({
                            showPinForm: true
                        })
                    }else{
                        resolve ("Allow");
                    }
                } else {
                    resolve("Allow");
                }
            } else {
                resolve("Allow");
            }
        })
        return allowPlayer;
    }

    handleAllowMoiveClick = () => {
        this.setState({
            isVerifyingWatch: false,
            moviePlayerAllowed: true
        })
    }

    getTrailerStream = (update = false) => {
        let trailerNum = querystring("t");
        let trailerStream;
        if (trailerNum && trailerNum !== 0) {
            trailerStream = getTrailerStream(this.state.movieData, trailerNum);
        } else {
            trailerStream = getTrailerStream(this.state.movieData, 0);
        }
        if (!this.state.isEncryptedStream && update) {
            if (_includes(trailerStream, "mpd")) {
                trailerStream = trailerStream.replace("mpd", "m3u8")
            }
        }
        return trailerStream;
    }

    renderMovieView() {
        let videoType = this.videoType ? this.videoType : "";
        if (videoType === MOVIE_VIDEO) {
            return (
                <MoviePlayer
                    posterUrl={this.state.movieData && this.state.movieData.PreviewImage}
                    title={this.state.movieData && this.state.movieData.Title ? this.state.movieData.Title : ""}
                    videoType={this.videoType ? this.videoType : ""}
                    movieId={this.props.match.params.id}
                    movieData={this.state.movieData}
                    fetchMovie={this.fetchMovie}
                    playedRelated={this.playedRelated}
                    {...this.props}
                />
            )
        }
    }

    handleCloseButtonClick = () => {
        if (this.playedRelated > 1) {
            this.props.history.push(`/movie/${this.props.match.params.id}`);
        } else if (this.props.location.state) {
            this.props.history.push(`/movie/${this.props.match.params.id}`);
        } else {
            this.props.history.goBack();
        }
    }

    renderParentalPinFormView = () => {
        const { appTranslations : tr }  = this.props;
        let posterUrl = this.state.movieData && this.state.movieData.PreviewImage ? this.state.movieData.PreviewImage : null
        let title = this.state.movieData && this.state.movieData.Title ? this.state.movieData.Title : "";
        return (
            <div className="mn-media">
                <div className="mn-media-poster img-bg" style={{ display: 'block', backgroundImage: posterUrl !== null && `url(${posterUrl})` }}>
                    <div className="mn-poster-overlay">
                        {this.renderNotificationMessage()}
                        <div className="mn-poster-top d-flex justify-content-between">
                            <div><h2>{title}</h2></div>
                            <div onClick={this.handleCloseButtonClick}><span className="font-24 btn"><i className="la la-times"></i></span></div>
                        </div>
                        {this.state.showPinForm ?
                            <div className="mn-media-pin">
                                <div className="d-flex justify-content-center text-center">
                                    <div className="media-pin-form">
                                        <h3 className="mb-4">{tr.enter_pin_to_watch}.</h3>
                                        {this.renderPinForm(tr, null, true)}
                                    </div>
                                </div>
                            </div>
                            :
                            <span className="loader-spiral media-loader"><span className="spiral-inn"></span></span>}
                    </div>
                </div>
            </div>
        )
    }

    renderTrailerView = () => {
        let videoType = this.videoType ? this.videoType : "";
        if (videoType === TRAILER_VIDEO) {
            const trailerStreamURL = this.getTrailerStream(true);
            return (
                <TrailerPlayer
                    posterUrl={this.state.movieData && this.state.movieData.PreviewImage ? this.state.movieData.PreviewImage : null}
                    title={this.state.movieData && this.state.movieData.Title ? this.state.movieData.Title : ""}
                    videoType={this.videoType ? this.videoType : ""}
                    movieId={this.props.match.params.id}
                    streamUrl={trailerStreamURL}
                    movieData={this.state.movieData}
                    isEncryptedStream={this.state.isEncryptedStream}
                    {...this.props}
                />
            )
        }
    }

    render() {
        return (
            <main className="mn-main">
                <section className="mn-login-section py-5">
                    <div className="container">
                        <RenderScreen
                            isLoading={this.state.isLoadingMovieData}
                            isError={this.state.dataErrorMovie}>
                            {_isEmpty(this.state.movieData ) ?  //temp check for crash
                                <DataNotFound />
                                :
                            this.videoType === TRAILER_VIDEO ?
                                this.renderTrailerView()
                                :
                                this.state.isVerifyingWatch ?
                                    this.renderParentalPinFormView()
                                    :
                                    this.state.moviePlayerAllowed ?
                                        this.renderMovieView()
                                        :
                                        <Loader /> /*default case */
                            }
                        </RenderScreen>
                    </div>
                </section>
            </main>
        )
    }
}

export default withAppConfig(withAuth(PlayVideo));
