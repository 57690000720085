import React, { useState, useEffect } from "react";
import Loader from "../../../components/Loader/Loader";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";
import getCollectionDetail from "../../../services/movies/GetCollectionDetail";
import HeroSection from "./components/HeroSection";
import _isEmpty from "lodash/isEmpty";

//memo(
const CollectionDetail = ({ HostName, ...props }) => {
  const [detail, setDetail] = useState({});
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    initialize();
    return () => {};
  }, [props.isCPurchased_]);

  const initialize = () => {
    let params = {
      HostName: HostName,
      ID: props.match.params.id
    };
    getCollectionDetail((response, err) => {
      if (!err) {
        response["isCPurchased_"] = props.isCPurchased_.isPurchased;
        response["CPurchasedManifestType"] = props.isCPurchased_.ManifestType;
        response["Price"] = updatePrice(response.Price);
        setDetail(response);
      }
      setLoading(false);
    }, params);
  };

  if (isLoading) {
    return (
      <section>
        <Loader />
      </section>
    );
  }
  if (!isLoading && _isEmpty(detail)) {
    return (
      <section className="mn-movies py-4">
        <DataNotFound />
      </section>
    );
  }

  return <HeroSection data={detail} />;
};

export default CollectionDetail;

const updatePrice = price => {
  let result = price;
  if (result && result.length > 0) {
    result = price.map(item => {
      return { ...item, Price: parseInt(item.Price) };
    });
  }
  return result;
};
