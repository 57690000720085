import _includes from "lodash/includes";

export async function getDRMType() { //must use await while calling this func
    return "fairplay";
    let drmtype = "widevine";
    let os = await getOSName();
    if (os === "android") {
        return drmtype;
    } else if (os === "ios") {
        let ioSBrowser = await getCurrentBrowser();
        if (_includes(ioSBrowser.toLowerCase(), "safari")) {
            return "fairplay";
        } else {
            return null;
        }
    } else {
        let browser = await getCurrentBrowser();
        if (_includes(browser, "chrome") || _includes(browser, "firefox") || _includes(browser, "opera")) {
            drmtype = "widevine";
        } else if (_includes(browser, "edge") || _includes(browser, "explorer")) {
            drmtype = "playready";
        } else if (_includes(browser, "safari")) {
            drmtype = "fairplay";
        } else {
            drmtype = "widevine";
        }
    }
    return drmtype;
}

export async function getCurrentBrowser() { //must use await while calling this func
    let bowser = await importBowser();
    if (bowser) {
        return bowser.getBrowserName().toLowerCase();
    }
    return ""
}

export async function getOSName() {
    let bowser = await importBowser();
    if (bowser) {
        return bowser.getOSName().toLowerCase();
    }
    return ""
}

export async function checkisIOS() {
    let bowser = await importBowser();
    if (bowser && bowser.getOSName().toLowerCase() === "ios") {
        return true;
    }
    return false;
}

export async function checkIsIpad() { //not used 
    let bowser = await importBowser();
    if (bowser && bowser.getOSName().toLowerCase() === "ios") {
        if (bowser.getPlatformType().toLowerCase() === "tablet") {
            return true;
        }
    }
    return false;
}

export async function importBowser() {
    let Bowser = await import("bowser").then(bowser => bowser.default).catch((err) => {
        //err;
    })
    if (!Bowser) {
        return null;
    }
    const bowser = Bowser.getParser(window.navigator.userAgent);
    return bowser;
}