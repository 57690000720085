import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';
import { getTrailerStream, getTrailerArray } from "../../../helpers/MoviesHelper";
import { withAppConfig } from "../../../context/ConfigContext";

class WatchIcons extends Component {

    showMoviePlayIcon() {
        const { appTranslations: tr } = this.props;
        return (
                <a onClick={(e) => this.props.handleWatchMovieClick(e, this.props.movieID)} href="javascript:void(0)" className="btn-watch" style={{ backgroundImage: `url(${this.props.movieData.PreviewImage})`}}>
                    <div className="btn-watch-inn">
                        <div className="mb-2"><i className="la la-play la-2x"></i></div>
                        <div className="font-12">{tr.continue_watching || "Continue Watching"}</div>
                    </div>
                </a>
        )
    }

    renderMultipleTrailers = () => {
        const { appTranslations: tr } = this.props;
        const otherTrailers = getTrailerArray(this.props.movieData);
        return (
            (this.props.checkIsValid(otherTrailers)) &&
            otherTrailers.map((trailer, index) => {
                if (index !== 0 && index <= 2) {
                    return (<div className="btn-group"><a key={index} className="btn btn-outline-light btn-large" href="javascript:void(0)" onClick={(e) => this.props.handleTrailerClick(e, this.props.movieID, index)} ><i className="la la-play mr-2"></i>{tr.trailer || "Trailer"} {index + 1}</a></div>)
                }
            })
        )
    }

    rendeViewMoreTrailers = () => {
        const { appTranslations: tr } = this.props;
        const otherTrailers = getTrailerArray(this.props.movieData);
        return (
            (this.props.checkIsValid(otherTrailers)) &&
                otherTrailers.length > 3 ?
                <div className="mb-5">
                    <div className="btn-group">
                        <a className="btn btn-outline-light dropdown-toggle" data-toggle="dropdown" href="javascript:void(0)">{tr.more || "More"}</a>
                        <div className="dropdown-menu dropdown-menu-right">
                            {otherTrailers.map((trailer, index) => {
                                if (index >= 3) {
                                    return (<a key={index} className="dropdown-item py-2" href="javascript:void(0)" onClick={(e) => this.props.handleTrailerClick(e, this.props.movieID, index)} ><i className="la la-play mr-2"></i>{tr.trailer || "Trailer"} {index + 1}</a>)
                                }
                            })}
                        </div>
                    </div>
                </div>
                :
                null
        )
    }

    renderPlayIcons = () => {
        let firstTrailer = 0;
        const trailerStreamURL = getTrailerStream(this.props.movieData, firstTrailer);
        const { appTranslations: tr } = this.props;
        return (
            ((this.props.canWatchMovie && !this.props.isExpired) || this.props.isSubscribed_) ?
                this.showMoviePlayIcon()
                :
                <Fragment>

                    {trailerStreamURL &&
                        <a onClick={(e) => this.props.handleTrailerClick(e, this.props.movieID)} href="javascript:void(0)" className="btn-watch" style={{ backgroundImage: `url(${this.props.movieData.PreviewImage})`}}>
                            <div className="btn-watch-inn">
                                <div className="mb-2"><i className="la la-play la-2x"></i></div>
                                <div className="font-12">{tr.watch_trailer || "Watch Trailer"}</div>
                            </div>
                        </a>}


                    {this.renderMultipleTrailers()}
                    {this.rendeViewMoreTrailers()}

                </Fragment>
        )
    }

    renderSpinner() {
        return (
            <i className="la la-spin fa-spinner fa-2x"></i>
        )
    }

    render() {
        if (this.props.isSubscribed_) {
            return (
                <div className="mn-dt-play">
                    {this.renderPlayIcons()}
                </div>
            )
        }
      
        return (
            <div className="mn-dt-play">
                {this.props.isMovieLockDecided ?
                    this.renderPlayIcons()
                    :
                    this.renderSpinner()
                }
            </div>
        );
    }
}

WatchIcons.propTypes = {
    isMovieLockDecided: PropTypes.bool.isRequired,
    movieID: PropTypes.string.isRequired,
    isExpired: PropTypes.bool,
    canWatchMovie: PropTypes.bool.isRequired,
    movieData: PropTypes.object.isRequired,
    handleTrailerClick: PropTypes.func.isRequired,
    handleWatchMovieClick: PropTypes.func.isRequired,
    checkIsValid: PropTypes.func.isRequired
};
export default withAppConfig(WatchIcons);
