export const getInputFields = (tr, phone_number) => {
    let obj = []
    obj.push(
        {
            Name: "firstname",
            Type: "text",
            Placeholder: tr.first_name,
            Disabled: false,
            Validations: "isSpecialWords",
            ValidationError: tr.this_is_not_a_valid_name || "This is not a valid name."
        },
        {
            Name: "lastname",
            Type: "text",
            Placeholder: tr.last_name,
            Disabled: false,
            Validations: "isSpecialWords",
            ValidationError: tr.this_is_not_a_valid_name || "This is not a valid name"
        }
    )
    if (phone_number) {
        obj.push({
            Name: "phone_number",
            Type: "phone_number",
            Placeholder: "Enter Phone Number",
            Disabled: true

        })
    }
    else obj.push(
        {
            Name: "email",
            Type: "text",
            Placeholder: "Email Address",
            Disabled: true,
            Validations: "isEmail",
            ValidationError: tr.please_enter_valid_email_address,
        })
    return obj;
}
