import _map from "lodash/map";
import _includes from "lodash/includes";
import { getactiveCDNfromConfig } from "./ConfigHelpers";
import { SECTIONS, FETCH_SORTED_MOVIES, PURCHASE_HISTORY, FETCH_PURCHASELIST, FETCH_WATCHLIST, RESPONSE_MYLIST, NOTIFICATIONS_LIST, CARD_IMAGE_SIZE, NOTIFICATION_IMAGE_SIEZ, GENERE_SMALL_SLIDER_SIZE, GENRE_IMAGE_SIZE, PARTNER_SECTIONS, FETCH_SEARCH_DATA, FETCH_RELATED_MOVIES, FETCH_MOVIE_DATA, PREVIEW_BG_IMAGE_SIZE, FETCH_GENERE, FETCH_MOVIE_PLAY_VIDEO, STORED_BANNER_DATA, UPDATE_MYLIST_MOVIE, REMOVE_MYLIST_MOVIE } from "../constants/constants";


export async function handleImageResizing(response, status,HostName) { //written separate for search!
    let updatedResponse = response;
    if(!HostName){
        HostName = await getactiveCDNfromConfig();
    }
    if (response && response.constructor === Array) {
        updatedResponse = _map(response, (item) => {
            return getUpdatedUrls(item, status, HostName);
        });
    } else if (status === FETCH_MOVIE_DATA || status === FETCH_MOVIE_PLAY_VIDEO) {
        let isPreviewImage = updatedResponse.PreviewImage;
        let isPosterURL = updatedResponse.PosterURL || updatedResponse.PosterUrl;
        if (isPreviewImage) {
            updatedResponse["PreviewImage"] = getCompressedCdnImageUrl(isPreviewImage, status, HostName);
        }
        if (isPosterURL) {
            let key = updatedResponse.PosterURL ? 'PosterURL' : 'PosterUrl';
            updatedResponse[key] = getCompressedCdnImageUrl(isPosterURL, 'FETH_MOVIE_CARD', HostName);
        }
    }
    return updatedResponse;
}

export function getUpdatedUrls(item, status, HostName) {
   
    let isPreviewImage = item.PreviewImage;
    let isPosterURL = item.PosterURL || item.PosterUrl;
    let isMoviePosterURL = item.MoviePosterURL;
    let isMoviePreviewImage = item.MoviePreviewImage;
    let isBannerPath = item.BannerPath;

    if (isPreviewImage) {
        item["PreviewImage"] = getCompressedCdnImageUrl(isPreviewImage, status, HostName);
    } else if (isMoviePreviewImage) {
        item["MoviePreviewImage"] = getCompressedCdnImageUrl(isMoviePreviewImage, status, HostName);
    }
    if (isPosterURL) {
        let key = item.PosterURL ? 'PosterURL' : 'PosterUrl';
        item[key] = getCompressedCdnImageUrl(isPosterURL, status, HostName);
    } else if (isMoviePosterURL) {
        item["MoviePosterURL"] = getCompressedCdnImageUrl(isMoviePosterURL, status, HostName);
    }
    if (isBannerPath) {
        item["BannerPath"] = getCdnImageUrl(isBannerPath, HostName); // just add cdn --> dont compress its size
    }
   
    return item;
}

export function getImageSize(status) {
    let size;
    switch (status) {
        case SECTIONS:
        case "Movies":
        case "Sections":
        case FETCH_SORTED_MOVIES:
        case "PURCHASE_HISTORY_BANNER":
        case PURCHASE_HISTORY:
        case FETCH_PURCHASELIST:
        case FETCH_WATCHLIST:
        case RESPONSE_MYLIST:
        case PARTNER_SECTIONS:
        case FETCH_SEARCH_DATA:
        case FETCH_RELATED_MOVIES:
        case "FETH_MOVIE_CARD":
        case FETCH_GENERE:
        case UPDATE_MYLIST_MOVIE:
        case "UPDATE_MYLIST_MOVIE_BANNER":
        case REMOVE_MYLIST_MOVIE:
        case "PurchaseList":
        case "WatchList":
            size = CARD_IMAGE_SIZE;
            break;
        case NOTIFICATIONS_LIST:
            size = NOTIFICATION_IMAGE_SIEZ;
            break;
        case GENERE_SMALL_SLIDER_SIZE:
            size = GENRE_IMAGE_SIZE;
            break;
        case FETCH_MOVIE_DATA:
        case FETCH_MOVIE_PLAY_VIDEO:
            size = PREVIEW_BG_IMAGE_SIZE;
            break;
    }
    return size;
}

export function getCompressedCdnImageUrl(imageUrl, status, cdnUrl) {
    let size = getImageSize(status);
    if (imageUrl && size) {
        let isAlreadyCompressed = _includes(imageUrl, size);
        if (!isAlreadyCompressed) {
            let cdnImageUrl = getCdnImageUrl(imageUrl, cdnUrl);
            imageUrl = cdnImageUrl.substring(0, cdnImageUrl.lastIndexOf('/')) + size + cdnImageUrl.substring(cdnImageUrl.lastIndexOf('/') + 1);
        }
    }
    return imageUrl;
}

export function getCdnImageUrl(imageUrl, cdnUrl) {
    if (imageUrl) {
        if (_includes(imageUrl, `https://https://`)) { //temporary check for broken images
            imageUrl = imageUrl.replace(`https://`, ``);
        }
        let newImageURL = new URL(imageUrl);
        let cdnURL = cdnUrl && new URL(cdnUrl);
        imageUrl = (cdnURL && (newImageURL.hostname !== cdnURL.hostname)) ? imageUrl.replace(newImageURL.hostname, cdnURL.hostname) : imageUrl
    }
    return imageUrl;
}
