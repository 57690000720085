import React, { useState, useEffect, memo } from "react";
import SectionList from "./SectionList/";
import { setDocumentTitle, ping } from "../../helpers/GeneralHelpers";
import { HOME_PAGE } from "../../constants/constants";
import StuffpixSplash from "../../components/StuffpixSplash/Splash";
import { getMyList } from "../../services/user/GetMyList";
import Banner from "../Banner";
import Splash from "../../components/MuvinowSplash/Splash";
import config from "../../config/config";

const Home = memo(({ isAuthenticated, HostName, ...props }) => {
  const [serverTime, setServerTime] = useState(null);
  const [myList, setMyList] = useState([]); //myList is common to both-> Sections & Banners!
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    initialize();
    return () => {};
  }, []);

  const initialize = async () => {
    setDocumentTitle(HOME_PAGE);
    let time = await ping();
    setServerTime(time);
    if (isAuthenticated) {
      fetchMyList();
    } else {
      setLoading(false);
    }
  };

  const fetchMyList = () => {
    let params = { CdnUrl: HostName , UserID : props.UserID};
    getMyList((list, err) => {
      //check no list flag later!
      if (!err) {
        setMyList(list);
      }
      setLoading(false);
    }, params);
  };

  const updateMyList =(mylist) => {
    setMyList(mylist);
  }

  return isLoading ? (
    config.customSettings.isStuffpixEnabled ? (
      <StuffpixSplash />
    ) : (
      <Splash />
    )
  ) : (
    <main className="mn-main pb-4">
      <Banner serverTime={serverTime} myList={myList} updateMyList={updateMyList} HostName={HostName} />
      <SectionList serverTime={serverTime} myList={myList} />
    </main>
  );
});

export default Home;
