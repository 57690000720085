import React, { PureComponent } from "react";
import CustomSlickSlider from "../../../components/CustomSlickSlider/CustomSlickSlider";
import { getactiveCDNfromConfig } from "../../../helpers/ConfigHelpers";
import { getGenreList } from "../helpers/index";
import _findIndex from "lodash/findIndex";
import { withAppConfig } from "../../../context/ConfigContext";
import find from "lodash/find";

class GenreSlider extends PureComponent {
  state = {
    genreList: []
  };

  async componentDidMount() {
    let HostName = await getactiveCDNfromConfig();
    let list = await getGenreList(HostName);
    const { initialGenre } = this.props;
    this.setState({
      genreList: list,
      selectedGenre: initialGenre
    });
  }

  handleFilterItemClick = (Name, Value) => {
    this.setState({
      selectedGenre: Value
    });
    this.props.handleOnGenreClick(Name,Value);
  };

  render() {
    // let doubled = genreList.map((item) => {item.Name == selectedGenre ? console.log(item) : null});
    const { genreList, selectedGenre } = this.state;
    let selectedGenreIndex = _findIndex(genreList, function(o) {
      return o.Value === selectedGenre;
    });
    return (
      <section>
        <div>
          {genreList && genreList.length > 0 ? (
            <CustomSlickSlider
              dataList={genreList}
              handleFilterItemClick={this.handleFilterItemClick}
              selectedGenre={selectedGenre}
              initialIndex={selectedGenreIndex}
            />
          ) : null}
        </div>
      </section>
    );
  }
}

GenreSlider.propTypes = {};

export default withAppConfig(GenreSlider);