import React from 'react';
import PropTypes from "prop-types";
import { withAppConfig } from "../../context/ConfigContext";
import { HOME } from '../../constants/routes';
import { convertSecToDuration } from '../../helpers/GeneralHelpers';

const DownloadAppMessage = ({ appSettings = {}, downloadAppType = '', data = {} , appTranslations : tr}) => {
    const { AppStoreLink, PlayStoreLink, ApplicationName = ``, Logo } = appSettings;
    const { Title = ``, PlayDuration = ``, ID = ``, ReleaseYear = ``, PreviewImage } = data;
    return (
        <section className="mn-app-download img-bg" style={{ backgroundImage: `url(${PreviewImage})` }}>
            <div className="d-flex justify-content-between flex-column h-100 text-center">
                <div className="app-download-top px-3 py-4">
                    <a href={HOME}><img className="app-download-logo" alt={ApplicationName} src={Logo} /></a>
                </div>
                <div className="app-download-bottom px-3 pb-3 pt-5">
                    <div className="mb-4">
                        <h3 className="font-weight-bold mb-3">{Title}</h3>
                        <div className="mn-points mb-3"><span>{ReleaseYear}</span><span> {convertSecToDuration(PlayDuration)}</span></div>
                        <h5 className="font-weight-bold text-white-70">{tr.watch_muviNow_on_your_phone_or_tablet || "Watch MuviNow on your phone or tablet"}</h5>
                    </div>
                    <div className="">
                        {downloadAppType === "android"
                            ? <a className="btn btn-primary btn-block mb-3" href={PlayStoreLink}>{tr.get_the_free_app || "Get the Free App"}</a>
                            : <a className="btn btn-primary btn-block mb-3" href={AppStoreLink}>{tr.get_the_free_app || "Get the Free App"}</a>}
                        <a className="btn btn-secondary btn-block" href={`movie/${ID}`}>
                            <i className="la la-long-arrow-left mr-2"></i>
                            {tr.back}
                        </a>
                    </div>
                </div>
            </div>
        </section>

    )
}

DownloadAppMessage.propTypes = {
    downloadAppType: PropTypes.string,
    appSettings: PropTypes.object,
    data: PropTypes.object
}

export default withAppConfig(DownloadAppMessage);