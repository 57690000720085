import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../../components/Loader/Loader";
import RoomsCard from "../../../../components/RoomsCard/index";

const Listing = ({ Data, loading, fetchMoreData }) => {
  return (
    <InfiniteScroll
      dataLength={Data.Items ? Data.Items.length : 0}
      next={fetchMoreData}
      hasMore={Data.LastEvaluatedKey ? true : false}
      loader={
        loading.isLoadingMore && (
          <div height="150px">
            <Loader height="150px" />
          </div>
        )
      }
    >
      <section className="mn-movies pt-4">
        <div className="container-fluid">
          <div className="row gutter-10 mn-rooms-grid">
            {Data.Items.map((room, index) => {
              return (
                <RoomsCard key={index} {...room} classname="mb-4" />
              );
            })}
          </div>
        </div>
      </section>
    </InfiniteScroll>
  );
};

export default Listing;
