import React, { Component } from "react";
import PropTypes from 'prop-types';
import _find from "lodash/find";
import Formsy from 'formsy-react';
import LoaderButton from "../../LoaderButton/LoaderButton";
import MyInput from "../../MyInput";
import withPaymentHelper from "../../../hoc/PaymentHelpers";
import { handleChange } from "../../../helpers/StateHelper";
import { PURCHASE, QUERYTIERID, TVOD } from "../../../constants/constants";
import { CHECKOUT, REDIRECT_URL } from "../../../constants/routes";
import { getVideoQualityData } from "../../../helpers/MoviesHelper";
import config from "../../../config/config";
import { checkPricingTiers } from "../../../helpers/GeneralHelpers";

class PurchaseButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            voucherCode: "",
            isVerifyingVoucher: false,
            isProcessingFreeVod: false
        }
        this.voucherInputRef = ""
        this.handleChange = handleChange.bind(this);
    }

    handlePurchaseClick = () => {
        const { voucherCode } = this.state;
        const { movieId, teirId, type, purchaseParams } = this.props;
        let selectedTier = _find(purchaseParams, ["ID", teirId]);
        if (selectedTier && selectedTier.FreeWatch === 1) {
            this.handleFreeVodPurchase(selectedTier);
        } else {
            if (!voucherCode) {
                if (movieId && type && teirId) {
                    this.props.history.push({
                        pathname: `${CHECKOUT}${movieId}`,
                        search: `?${PURCHASE}=${type}&${QUERYTIERID}=${teirId}`
                    });
                }
            } else {
                this.handleVoucherSubmit();
            }
        }
    }

    handleFreeVodPurchase = (freeVodObj = {}) => {
        const { movieId, history, isAuthenticated } = this.props;
        const { Type = TVOD, ManifestDetails = [], ID } = freeVodObj;
        if (!isAuthenticated) {
            history.push(`${REDIRECT_URL}${history.location.pathname}`);
        } else {
            this.setState({
                isProcessingFreeVod: true
            });
            let checkoutParams = {
                PurchaseType: Type,
                TierID: ID,
                PaymentGateway: this.getActiveGateway(),
                MovieID: movieId,
                Price: 0,
                PaymentNonce: "XXXXXX",
                Quality: ManifestDetails[0]
            }
            this.props.handleCheckout(checkoutParams, false);
        }
    }

    getActiveGateway = () =>{
        let GatewayList = _find(this.props.configurations, ["KEY", "PAYMENT_GATEWAY"]);
        GatewayList = (GatewayList && GatewayList.VALUE) ?  GatewayList.VALUE : []
        let activeGateway  = _find(GatewayList,['Status','active']);
        activeGateway = activeGateway ? activeGateway.Type.toLowerCase() : ''
        return activeGateway
    }

    handleVoucherSubmit = () => {
        const { data, movieId, type, teirId } = this.props;
        this.setState({
            isVerifyingVoucher: true
        });
        let qualityArray = getVideoQualityData(data, null, type);
        let quality = _find(qualityArray, ["teirId", teirId]);
        this.props.toggleModalbackDrop();
        this.props.handleRedeemVoucherClick(this.state.voucherCode, movieId, quality); //api call
    }

    onVoucherRedeemFailed = (err) => {
        this.setState({
            isVerifyingVoucher: false
        });
        this.props.toggleModalbackDrop();
    }

    onVoucherRedeemSuccess = (response) => {
        this.setState({
            isVerifyingVoucher: false
        });
        this.props.toggleModalbackDrop();
        const { movieId, teirId, type } = this.props;
        if (movieId && type && teirId) {
            this.props.history.push({
                pathname: `${CHECKOUT}${movieId}`,
                search: `?${PURCHASE}=${type}&${QUERYTIERID}=${teirId}`
            }, { Voucher: this.state.voucherCode, VoucherData: response });
        }
    }

    handleClickClearVoucher = () => {
        this.setState({
            voucherCode: ""
        });
        this.voucherInputRef.clearInput()
    }

    onCheckoutSuccess = (response) => {
        this.setState({
            isProcessingFreeVod: false
        });
        this.props.dismissPurchaseModal();
    }

    onCheckoutFailed = (err) => {
        this.setState({
            isProcessingFreeVod: false
        });
        this.props.dismissPurchaseModal(false);
    }

    render() {
        const { appTranslations : tr  } = this.props;
        const { isVerifyingVoucher, voucherCode, isProcessingFreeVod } = this.state;
        const { ApplicationName = config.applicationName } = this.props.appSettings;
        let noVoucherFlag = checkPricingTiers(this.props.purchaseParams);
        let headtext = tr.using_a_muvinow_reward_code || "Using a MuviNow Reward Code?";
        headtext = headtext.replace('MuviNow',ApplicationName);
        return (
            <div >
                {(isVerifyingVoucher || isProcessingFreeVod) &&
                    <span className="loader-spiral mn-white mn-transparent">
                        <span className="spiral-inn"></span></span>
                }
                {(noVoucherFlag) &&
                    <Formsy onValidSubmit={this.handlePurchaseClick}>
                        <div className="form-group">
                            <label className="text-white-50">{headtext}</label>
                            <div className="input-group">
                                <MyInput
                                    id="voucherCode"
                                    name="voucherCode"
                                    outerDiv={false}
                                    innerRef={i => this.voucherInputRef = i}
                                    type="text"
                                    innerClassName="form-control-dark"
                                    outerClassName="mb-3"
                                    placeholder={tr.enter_reward_code || "Enter Reward Code"}
                                    required
                                    handleChange={this.handleChange}
                                />
                                {voucherCode &&
                                    <span className="input-group-append">
                                        <span className="input-group-btn" onClick={this.handleClickClearVoucher}>
                                            <button className="btn btn-lg btn-secondary"><i className="la la-times"></i></button>
                                        </span>
                                    </span>
                                }
                            </div>
                        </div>
                    </Formsy>
                }
                <div className="text-center">
                    <LoaderButton
                        isLoading={isVerifyingVoucher}
                        disabled={isVerifyingVoucher}
                        className="btn btn-outline-primary btn-lg btn-medium"
                        onClick={this.handlePurchaseClick}
                        loadingText={tr.verifying_reward_code || "Verifying Reward Code..."}
                        text={tr.rent || "Rent"}
                    />
                </div>
            </div>
        )
    }
}
export default withPaymentHelper(PurchaseButton); //dont wrap it with any other hoc or context!! => refs prob!
PurchaseButton.defaultProps = {
    data: {}
}

PurchaseButton.propTypes = {
    dismissPurchaseModal: PropTypes.func.isRequired,
    data: PropTypes.object,
    appSettings: PropTypes.object,
    isAuthenticated: PropTypes.bool.isRequired,
    purchaseParams: PropTypes.array,
    teirId: PropTypes.string.isRequired,
    movieId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    handleRedeemVoucherClick: PropTypes.func,
    history: PropTypes.object,
    toggleModalbackDrop: PropTypes.func,
    handleCheckout: PropTypes.func
};