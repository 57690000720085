import React, { Component } from "react";
import PropTypes from "prop-types"
import { jsDateToYYYYMMDD, multiIncludes } from "../../../helpers/GeneralHelpers";
import { MAC_ICON, MAC, ANDROID, ANDROID_ICON, BROSWER, LAPTOP_ICON } from "./constants";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import { withAppConfig } from "../../../context/ConfigContext";

class Device extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showConfirmationPopup: false,
            isRemoving: {}
        };
    }

    toggleDeleteModal = () => {
        this.setState((prevState) => {
            return {
                showConfirmationPopup: !prevState.showConfirmationPopup
            }
        });
    }

    onErrorRemovingDevice = () => {
        this.setState({
            isRemoving: {
                ...this.state.isRemoving,
                [this.props.deviceId]: false
            }
        })
    }

    onConfirmDeviceRemoveClick = () => {
        this.setState({
            isRemoving: {
                ...this.state.isRemoving,
                [this.props.deviceId]: true
            }
        });
        this.toggleDeleteModal();
        this.props.onConfirmDeviceRemoveClick(this.props.deviceId);
    }

    handleDeviceRemoveClick = (e) => {
        e.preventDefault();
        this.toggleDeleteModal();
    }

    getUserDevicesIcon = (device) => {
        if (device.OS && device.OS.toLowerCase() !== "unknown") {
            if (multiIncludes(device.OS, MAC)) {
                return MAC_ICON
            } else if (multiIncludes(device.OS, ANDROID)) {
                return ANDROID_ICON
            } else {
                return LAPTOP_ICON;
            }
        } else if (device.DeviceType && device.DeviceType.toLowerCase() !== "unknown") {
            if (multiIncludes(device.DeviceType, MAC)) {
                return MAC_ICON
            } else if (multiIncludes(device.DeviceType, ANDROID)) {
                return ANDROID_ICON
            }
        } else if (device.Browser && device.Browser.toLowerCase() !== "unknown") {
            if (multiIncludes(device.Browser, BROSWER)) {
                return LAPTOP_ICON
            }
        } else if (device.UserAgent && device.UserAgent.toLowerCase() !== "unknown") {
            if (multiIncludes(device.UserAgent, MAC)) {
                return MAC_ICON
            }
        } else {
            return LAPTOP_ICON
        }
        return LAPTOP_ICON
    }

    renderDeleteConfirmationModal = () => {
        const { appTranslations :tr } = this.props;
        return (
            <ConfirmationModal
                showConfirmationModal={this.state.showConfirmationPopup}
                toggleModal={this.toggleDeleteModal}
                onConfirmationClick={this.onConfirmDeviceRemoveClick}
                Title={tr.are_you_sure_you_want_to_delete_this_dev}
                Subtitle=""
            />
        )
    }

    render() {
        const { device = {} , appTranslations :tr } = this.props;
        const { Browser = ``, DeviceVendor = ``, UserAgent = ``, LastAuth, OS, Device, UserIP, DeviceKey, isPlaying } = device;
        return (
            <div key={device.DeviceKey} className="list-group-item list-group-item-action list-group-item-dark flex-column align-items-start">
                <div className="d-flex w-100 align-items-lg-center font-12">
                    <div>
                        <span className="btn btn-secondary rounded-circle btn-icon mr-3">
                            <i className={this.getUserDevicesIcon(device)}></i>
                        </span>
                    </div>
                    <div className="flex-grow-1">
                        <div className="row gutter-5">
                            <div className="col-sm-6">
                                <h6 className="mb-1">
                                    {Browser !== "Unknown"
                                        ? Browser
                                        : DeviceVendor !== "Unknown"
                                            ? DeviceVendor
                                            : UserAgent
                                    }
                                    {isPlaying &&
                                    <small className="ml-3 text-primary"><i className="la la-play-circle mr-1"></i> {tr.playing || "Playing"} </small>
                                    }
                                    </h6>
                            </div>
                            <div className="col-sm-6 text-sm-right">
                                <div>{LastAuth ? jsDateToYYYYMMDD(LastAuth) : ""}</div>
                            </div>
                        </div>
                        <div className="row gutter-5">
                            <div className="col-sm-4">
                                <div className="mt-1">{OS !== "Unknown" ? OS : Device !== "Unknown" ? Device : ""}</div>
                            </div>
                            <div className="col-sm-4 text-sm-center">
                                <div className="mt-1">{UserIP ? UserIP : ""}</div>
                            </div>
                            {(this.props.currentDeviceKey === DeviceKey) ?
                                <div className="col-sm-4 text-sm-right">
                                    <div className="mt-1"><a className="link-white" href="javascript:void(0)"><strong>{tr.current_device || "Current Device"}</strong></a></div>
                                </div>
                                :
                                <div className="col-sm-4 text-sm-right">
                                    <div className="mt-1">
                                        <a
                                            onClick={this.handleDeviceRemoveClick}
                                            href="javascript:void(0)">
                                            {this.state.isRemoving[this.props.deviceId]
                                                ? tr.removing || "removing"
                                                : tr.remove_device || "Remove Device"}
                                        </a>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {this.renderDeleteConfirmationModal()}
            </div>
        );
    }
}
const wrapped = withAppConfig(Device);

export { wrapped as DeviceItem };
Device.propTypes = {
    device: PropTypes.object,
    deviceId: PropTypes.string,
    currentDeviceKey: PropTypes.string.isRequired,
    onConfirmDeviceRemoveClick: PropTypes.func.isRequired,
    handleProceedClick: PropTypes.func.isRequired,
}