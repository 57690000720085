import React from "react";
import PropTypes from "prop-types";
import { withAppConfig } from "../../../context/ConfigContext";

const MoviesView = ({ dropdown, genreSlider, listing, appTranslations }) => {
  return (
    <main className="mn-main">
      <section className="mn-page-head py-4 bg-gray-800">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <h3 className="text-white text-uppercase font-weight-bold mb-0">{appTranslations.movies}</h3>
              {genreSlider}
            </div>
            <div className="font-12 d-none d-sm-block">
              <div>{dropdown}</div>
            </div>
          </div>
        </div>
      </section>
      {listing}
    </main>
  );
};

export default withAppConfig(MoviesView);
MoviesView.propTypes = {
  initialSort: PropTypes.string,
  initialGenre: PropTypes.string
};
