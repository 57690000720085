import { propTypes, withFormsy } from 'formsy-react';
import React from 'react';

class MySelect extends React.Component {
    constructor(props) {
        super(props);
        this.changeValue = this.changeValue.bind(this);
    }

    changeValue(event) {
        this.props.setValue(event.currentTarget[this.props.type === 'checkbox' ? 'checked' : 'value']);
        this.props.handleChange(event.currentTarget.id, event.currentTarget.value);
    }

    render() {
        const outerClassName = 'form-group mb-4 ' + (this.props.outerClassName || ' ') + (this.props.showRequired() ? 'required' : this.props.showError() ? 'error' : '');
        const innerClassName = `form-control ${this.props.innerClassName}`;

        const errorMessage = this.props.getErrorMessage();

        const options = this.props.options.map((option, i) => (
            <option key={option.title + option.value} value={option.value}>
                {option.title}
            </option>
        ));

        return (
            this.props.outerDiv ?
                <div className={outerClassName}>
                    {this.props.label && <label htmlFor={this.props.name}>{this.props.label}</label>}
                    <select
                        id={this.props.id}
                        disabled={this.props.disabled}
                        name={this.props.name}
                        className={innerClassName}
                        onChange={this.changeValue}
                        value={this.props.getValue()}>
                        {options}
                    </select>
                    <span className='validation-error'>{errorMessage}</span>
                </div>
                :
                <select
                    id={this.props.id}
                    disabled={this.props.disabled}
                    name={this.props.name}
                    className={innerClassName}
                    onChange={this.changeValue}
                    value={this.props.getValue()}>
                    {options}
                </select> //else only used in checkout 
        );
    }
}

MySelect.defaultProps = {
    outerDiv: true,
    disabled: false
}

MySelect.propTypes = {
    ...propTypes,
};
export default withFormsy(MySelect);