import { GET } from "../../api/";
import _isEmpty from "lodash/isEmpty";
import _concat from "lodash/concat";
import {
  storeDataInLocalStore,
  getDataFromLocalStorage
} from "../../helpers/GeneralHelpers";
import { handleImageResizing } from "../../helpers/CDNhelpers";
import { checkIsMovieSubscribed } from "../../helpers/MoviesHelper";

export default async function getScreeningRoomMovies(callback, params) {
  const { userID, Limit = 20, LastEvaluatedKey = ``, Items } = params;
  let room; //getDataFromLocalStorage(`screeningRoom-movies-${params.ID}`, userID);
  if (!room || _isEmpty(room)) {
    room = await GET(
      "MoviesAPI",
      `/screening-room/${params.ID}/movies?limit=${Limit}&ExclusiveStartKey=${LastEvaluatedKey}`
    )
      .then(res => {
        if (res.body) {
          res.body.Items = _concat(Items, res.body.Items);
          return res.body;
        }
        return res.body;
      })
      .catch(e => {
        let message = e;
        if (e.response && e.response.data && e.response.data.errorMessage) {
          message = e.response.data.errorMessage.replace("[403] ", "");
        }
        callback(null, message);
        return;
      });
  }
  if (!room) {
    return;
  }
  const { HostName, subscriptions, isSubscribed_, isAuthenticated } = params;
  if (isAuthenticated && isSubscribed_ && subscriptions) {
    room.Items = subscribedStatus(room.Items, subscriptions);
  }
  room.Items = await handleImageResizing(room.Items, 'Movies', HostName);
  if (!_isEmpty(room)) {
    //storeDataInLocalStore(`screeningRoom-movies-${params.ID}`, room, userID);
  }
  callback(room);
}

function subscribedStatus(movies, subscriptions) {
  let result = [];
  result = movies.map(movie => {
    movie["isSubscribed_"] = checkIsMovieSubscribed(subscriptions, movie);
    return movie;
  });
  return result;
}
