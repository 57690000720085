import React from 'react';
import PropTypes from 'prop-types';
import Loader from "../Loader/Loader";
import DataNotFound from "../DataNotFound/DataNotFound";
import { isEmpty } from "../../helpers/GeneralHelpers";
import StuffpixLoader from '../StuffpixLoader/StuffpixLoader';

class RenderScreen extends React.Component {
    render() {
        if (!this.props.isLoading && !isEmpty(this.props.isError)) {
            return (
                <DataNotFound height={this.props.notFoundHeight} />
            )
        }
        else if (this.props.isLoading) {
            if (this.props.stuffpixLoader) {
                return (
                    <StuffpixLoader />
                )
            }
            return (
                <Loader height={this.props.loaderHeight} />
            )
        } else {
            return this.props.children
        }
    }
}

RenderScreen.defaultProps = {
    isLoading: false,
    isError: "",
    loaderHeight: "500px",
    notFoundHeight: "500px",
    stuffpixLoader: false
}

RenderScreen.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    notFoundHeight: PropTypes.string,
    loaderHeight: PropTypes.string,
    isError: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.array
    ]).isRequired
};
export default RenderScreen;
