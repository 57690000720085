import React from "react";
import PropTypes from 'prop-types';
import LoaderButton from "../../../components/LoaderButton/LoaderButton";
import { withAppConfig } from "../../../context/ConfigContext";

const FreeCheckoutView = (props) => {
    const { appTranslations: tr } = props;
    const { headerTitle, headerSubTitle, isLoading, onClickCheckout, showSuccessTag } = props;
    return (
        <section className="mn-voucher-method pb-5">
            <div className="container">
                <div className="card card-transparent">
                    <div className="card-body text-center position-relative" style={{ "minHeight": "200px" }}>
                        <div className="mn-payment-msg">
                            <div className="mb-3 text-success"><i className="la la-check-circle font-48"></i></div>
                            <h3 className="text-success">{headerTitle}</h3>
                            <p>{showSuccessTag && <strong>{tr.congrats || "Congrats!"} </strong>}{headerSubTitle} </p>
                            <div className="my-5">
                                <LoaderButton
                                    type="submit"
                                    className="btn btn-primary"
                                    isLoading={isLoading}
                                    text={tr.continue || "Continue"}
                                    loadingText={tr.please_wait || "Please wait …"}
                                    onClick={onClickCheckout}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

FreeCheckoutView.defaultProps = {
    showSuccessTag: true
}

FreeCheckoutView.propTypes = {
    showSuccessTag: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    headerTitle: PropTypes.string.isRequired,
    headerSubTitle: PropTypes.string.isRequired,
    onClickCheckout: PropTypes.func.isRequired
};
export default withAppConfig(FreeCheckoutView);