import React, {Component, Fragment} from "react";
import PropTypes from 'prop-types';
import {TVOD, EST, PURCHASE, QUERYTIERID} from "../../../constants/constants";
import {getPurchaseData, getLowestQulaity} from "../../../helpers/MoviesHelper";
import config from "../../../config/config";
import {
    checkifOfferAvailed,
    checkIsMoviePromotional,
    getPartnerPromotionalMovies,
    getPromotionalMovieTeir,
    getRewardSelectedTeirId,
    decidePartnerFlow
} from "../../../helpers/PartnerHelpers";
import {getApiData} from "../../../helpers/APIHelpers/MoviesAPI";
import {CHECKOUT} from "../../../constants/routes";
import {checkPricingTiers} from "../../../helpers/GeneralHelpers";
import {withAppConfig} from "../../../context/ConfigContext";

class PurchaseIcons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            moviePriceDecided: true,
            customMoviePrice: null,
            customMovieQuality: null,
            showCustomPurchase: false
        }
        this.checkifOfferAvailed = checkifOfferAvailed.bind(this);
        this.getPurchaseData = getPurchaseData.bind(this); //helll!!!
        this.getRewardMovies = getApiData.bind(this);
        this.getPartnerPromotionalMovies = getPartnerPromotionalMovies.bind(this);
        this.checkIsMoviePromotional = checkIsMoviePromotional.bind(this);
        this.getPromotionalMovieTeir = getPromotionalMovieTeir.bind(this);
        this.decidePriceTier = decidePartnerFlow.bind(this);
        this.updateMovieTier = this.updateMovieTier.bind(this);
    }

    updateMovieTier = async () => {
        let teirObj = await this.getPromotionalMovieTeir();
        let selectedTeirId = getRewardSelectedTeirId(this.props.movieData.RentParams, teirObj.Quality);
        this.setState({
            moviePriceDecided: true,
            customMoviePrice: teirObj.Price,
            showCustomPurchase: true,
            customMovieQuality: selectedTeirId
        })
    }

    handleCustomPurchaseClick = () => { //TVOD == HARDCODE
        this.props.history.push({
            pathname: `${CHECKOUT}${this.props.movieID}`,
            search: `?${PURCHASE}=${TVOD}&${QUERYTIERID}=${this.state.customMovieQuality}` //${`&${QUERYFREEWATCH}=${isFreeWatch}`}
        });
    }

    renderCustomBuyIcon() {
        //RENT is HardCoded! ${this.state.customMovieQuality}
        const {appTranslations: tr} = this.props;
        return (
            <a
                onClick={this.handleCustomPurchaseClick}
                className="btn btn-primary btn-lg btn-large mr-3 mb-3 mb-md-0">
                {`${tr.rent || "Rent"} ${this.state.customMoviePrice}`}
            </a>
        )
    }


    // ======================= RENT ICON =======================
    renderRentIcon() {
        const {appTranslations: tr} = this.props;

        let rentButtonText = tr.rent || "Rent";
        if (config.customSettings.isStuffpixEnabled && this.props.movieData.isAvailableForRent && this.props.movieData.RentParams && this.props.movieData.RentParams.length > 1) {
            rentButtonText = tr.rent_from || 'RENT FROM'
        }
        let rentFlag = checkPricingTiers(this.props.movieData.RentParams);
        return (
            this.props.movieData.isAvailableForRent ?
                <a
                    onClick={(e) => {
                        this.props.handlePurchaseClick(e, this.props.movieID, TVOD, this.props.movieData.RentParams)
                    }}
                    className="btn btn-primary btn-lg btn-large mr-3 mb-3 mb-md-0">
                    {rentFlag ?
                        `${rentButtonText} ${this.props.movieData.RentParams && getLowestQulaity(this.props.movieData.RentParams)}`
                        :
                        'Free Watch'
                    }
                </a>

                :
                null
        )
    }

    // ======================= BUY ICON =======================
    renderBuyIcon() {
        const {appTranslations: tr} = this.props;

        let ownButtonText = tr.buy || 'BUY';

        if (config.customSettings.isStuffpixEnabled && this.props.movieData.isAvailableForBuy && this.props.movieData.BuyParams && this.props.movieData.BuyParams.length > 1) {
            ownButtonText = tr.buy_from || 'BUY FROM';
        }
        let buyFlag = checkPricingTiers(this.props.movieData.BuyParams);
        return (
            this.props.movieData.isAvailableForBuy ?
                <a href="javascript:void(0)"
                   className="btn btn-primary btn-lg btn-large mr-3 mb-3 mb-md-0"
                   onClick={(e) => this.props.handlePurchaseClick(e, this.props.movieID, EST, this.props.movieData.BuyParams)}>
                    {buyFlag ?
                        `${ownButtonText} ${this.props.movieData.BuyParams && getLowestQulaity(this.props.movieData.BuyParams)} `
                        :
                        "Free Watch"
                    }
                </a>
                :
                null
        )

    }

    // ======================= SUBSCRIBE ICON =======================
    renderSubscribeIcon() {
        const {appTranslations: tr} = this.props;
        let subscribeButtonText = tr.subscribe || 'Subscribe';
        return (<a href="javascript:void(0)"
                   className="btn btn-primary btn-lg btn-large mr-3 mb-3 mb-md-0"
                   onClick={(e) => this.props.handlePurchaseClick(e)}>
            {subscribeButtonText}
        </a>)
    }

    renderisMovieLocked = () => {
        const {appTranslations: tr} = this.props;

        if (this.props.canWatchMovie && !this.props.isExpired) {
            return (<a
                className="btn btn-primary btn-lg btn-large mr-3 mb-3 mb-md-0"
                onClick={(e) => this.props.handleWatchMovieClick(e, this.props.movieID)}>
                {tr.watch_movie || "Watch Movie"}
            </a>)
        }

        if (this.props.movieData.isAvailableForSubscription) {
            return this.renderSubscribeIcon();
        }

        if (this.state.showCustomPurchase) {
            return this.renderCustomBuyIcon()

        }
        return (<Fragment>
            {this.renderRentIcon()}
            {this.renderBuyIcon()}
        </Fragment>)


    }

    renderSpinner() {
        return (
            <a href="javascript:void(0)" className="btn btn-primary btn-lg btn-large mr-3 mb-3 mb-md-0">
                <i className="la la-spin la-spinner"></i>
            </a>
        )
    }

    render() {
        const {appTranslations: tr} = this.props;
        if (this.props.isSubscribed_) {
            return (

                <a
                    className="btn btn-primary btn-lg btn-large mr-3 mb-3 mb-md-0"
                    onClick={(e) => this.props.handleWatchMovieClick(e, this.props.movieID)}>
                    {tr.watch_movie || "Watch Movie"}
                </a>
            )
        }
        return (
            (this.props.isMovieLockDecided && this.state.moviePriceDecided) ?
                this.renderisMovieLocked()
                :
                this.renderSpinner()
        );
    }
}

PurchaseIcons.propTypes = {
    isMovieLockDecided: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    movieID: PropTypes.string.isRequired,
    canWatchMovie: PropTypes.bool.isRequired,
    isExpired: PropTypes.bool.isRequired,
    movieData: PropTypes.object.isRequired,
    handleTrailerClick: PropTypes.func.isRequired,
    checkIsValid: PropTypes.func.isRequired,
    handlePurchaseClick: PropTypes.func.isRequired,
    handleWatchMovieClick: PropTypes.func.isRequired,
};
export default withAppConfig(PurchaseIcons);
