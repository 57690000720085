import React from "react";
import { NavLink } from 'react-router-dom';
import { PRIVACY_POLICY, TERMS_CONDITIONS, CONTACT_US } from "../../constants/routes";
import { withAppConfig } from "../../context/ConfigContext";

const PageSubFooter = ({ appTranslations : tr }) => {
    return (
        <section className="mn-ft-top py-4 text-center text-sm-left">
            <div className="container-fluid">
                <div className="d-sm-flex">
                    <div className="mr-sm-3">
                        <NavLink to={CONTACT_US} className="link-white">{tr.questions_contact_us}</NavLink>
                    </div>
                    <div className="mr-sm-3">
                        <NavLink to={TERMS_CONDITIONS} className="link-white">{tr.terms_of_use}</NavLink>
                    </div>
                    <div className="mr-sm-3">
                        <NavLink to={PRIVACY_POLICY} className="link-white">{tr.privacy_policy}</NavLink>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default withAppConfig(PageSubFooter);