import React from "react";
import { getRoomDuration } from "../../../../helpers/MoviesHelper";

const Header = props => {
  let subHeading = "";
  if (props.Type === "screening_room") {
    subHeading = `${getRoomDuration(props.Duration)} Access`;
  } else if (props.Type === "collection") {
    subHeading = `${props.Duration} days access to entire bundle`;
  }
  return (
    <div className="modal-header flex-column">
      <h5 className="modal-title font-16">Please Select the Video Quality</h5>
      <div className="text-white-50">{subHeading}</div>
    </div>
  );
};

export default Header;
