import React, { PureComponent } from 'react';
import PropTypes from "prop-types";
import _find from "lodash/find";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class CustomDropDown extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            selectedOption: "Select Your Option"
        }
    }

    componentDidMount() {
        if (this.props.initialValue) {
            let selectedItem = _find(this.props.dataList, ["value", this.props.initialValue]);
            this.setState({
                selectedOption: (selectedItem && selectedItem.name) ? selectedItem.name : ""
            })
        }
    }

    toggleDropdown = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    };

    onChangeItem = (event) => {
        let selectedItem = _find(this.props.dataList, ["name", event.target.innerText]);
        let selectedValue = selectedItem ? selectedItem["value"] : "";
        this.setState({
            selectedOption: event.target.innerText
        });
        if (this.props.onDropDownItemChanged) { //optional Callback
            this.props.onDropDownItemChanged(event.target.innerText, selectedValue);
        }
    };

    render() {
        const { dataList } = this.props;
        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} className="dropdown">
                <DropdownToggle className="btn dropdown-toggle" caret>
                    {this.state.selectedOption}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-dark has-arrow-right" right> {/*alse apply it on header dropdown*/}
                    {dataList && dataList.length > 0 ?
                        dataList.map((item, index) => {
                            return (
                                <DropdownItem className="py-2" key={index} onClick={this.onChangeItem} >
                                    <span value={item.value}>{item.name}</span>
                                </DropdownItem>)
                        })
                        :
                        null
                    }
                </DropdownMenu>
            </Dropdown>
        )
    }
}

CustomDropDown.propTypes = {
    dataList: PropTypes.array.isRequired,
    onDropDownItemChanged: PropTypes.func,
    initialValue: PropTypes.string
}

CustomDropDown.defaultProps = {
    initialValue: "Select Your Option"
}

export default CustomDropDown;