import React from "react";

export const PrevArrow = ({ ...props }) => {
    const { currentSlide, slideCount, ...arrowProps } = props;
    return (
        <button {...arrowProps} type='button' className='slick-prev'><i className='la la-angle-left'></i></button>
    )
}
export const NextArrow = ({ ...props }) => {
    const { currentSlide, slideCount, ...arrowProps } = props;
    return (
        <button {...arrowProps} type='button' className='slick-next'><i className='la la-angle-right'></i></button>
    )
}
