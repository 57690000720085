import React, { Component } from "react";
import PropTypes from "prop-types";
import { Navbar, NavbarBrand, Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom'
import Logo from "../../../../assets/images/muvinow-logo.png";
import * as routes from "../../../../constants/routes";
import config from "../../../../config/config";
import { getSectionHeadingStyle } from "../../../../helpers/MoviesHelper";
import NotificationsBell from "./NotificationsBell";
import SearchBox from "./SearchBox";
import { withAppConfig } from "../../../../context/ConfigContext";
import StuffpixProfileDropDown from "./StuffpixProfileDropDown";
import SignUpStrip from "../../../SignUpStrip/SignUpStrip";
import { getPageLayout } from "../../../../helpers/GeneralHelpers";
import { withAuth } from "../../../../context/AuthContext";

class StuffpixHeaderView extends Component {

    handleCustomSectionItemClick = (event, section) => {
        this.props.history.push(`${routes.GENRE}${section.Type}/${section.ID}?name=${section.Name}&filtered=${true}`);
    }

    renderRightMenu = () => {
        return (
            <div className="mn-header-right col-6 col-xl-2">
                <Nav className="navbar-nav secondary-nav align-items-center">
                    <NavItem className="nav-item mn-search-dropdown hd-dropdown">
                        <SearchBox {...this.props} />
                    </NavItem>
                    {/*<li className="nav-item">/!* dropdown hd-dropdown not-dropdown*!/*/}
                        {/*<NotificationsBell {...this.props} />*/}
                    {/*</li>*/}
                    <StuffpixProfileDropDown {...this.props} />
                </Nav>
            </div>
        )
    }

    // showCustomHeaderItems = (customSections) => {
    //     return customSections.map((link, index) => {
    //         if (link && index <= 6) {
    //             let [spacesfound, linkName, lastWord] = [link.Name ? link.Name.match(" ") : "", link.Name];
    //             if (spacesfound) {
    //                 lastWord = link.Name.split(" ").slice(-1);
    //                 linkName = link.Name.replace(lastWord, "");
    //             }
    //             if (link.InNavigation === true || link.InNavigation === 'true') {
    //                 return (
    //                     <NavItem key={index} className="nav-item" onClick={(e) => this.handleCustomSectionItemClick(e, link)}>
    //                         <a style={getSectionHeadingStyle(link)} className="nav-link"><span className="font-weight-regular mr-1">
    //                             {/* <i className="la la-play font-12 mr-2"></i> */}
    //                             {linkName}</span>{lastWord && lastWord}</a>
    //                     </NavItem>
    //                 )
    //             }
    //         }
    //     })
    // }

    renderStuffpixHeaderLinks = () => {
        const { appTranslations : tr , appSettings } = this.props;

        const menuLinks = [{ to: routes.HOME, name: tr.home || "Home" }, { to: routes.MOVIES, name: tr.movies || "Movies"  }, { to: `${routes.LIBRARY}/?q=1`, name: tr.library || "My Library" }];
        if(appSettings.EnableBundlesMenu ){
            menuLinks.splice(2, 0, { to: routes.ALLCOLLECTIONS, name: tr.collections || "Bundles" });
        }
        if(appSettings.EnableChannelsMenu){
            menuLinks.splice(3, 0, { to: routes.ALLROOMS, name: tr.rooms || "Channels" });
        }
        return (
            <div className="mn-header-center col-12 col-xl-8 collapse" id="primary-nav">
                <Nav navbar className="primary-nav">
                    {menuLinks.map((link, index) => {
                        return (
                            <NavItem key={index} className="nav-item">
                                <NavLink className="nav-link" to={link.to}>
                                <span className="">
                                    {link.name}
                                    </span>
                                </NavLink>
                            </NavItem>
                        )
                    })}
                </Nav>
            </div>
        );
        let { customSections, showCustomHeaderLinks } = this.props;
        return (
            <div className="mn-header-center col-12 col-xl-8 collapse" id="primary-nav">
                <Nav navbar className="primary-nav">
                    <NavItem className="nav-item">
                        <NavLink style={{ color: "#ccc" }} className="nav-link" to={routes.HOME}>
                            <span className="mr-1">
                                {/* <i className="la la-play font-12 mr-2"></i> */}
                                Home
                                </span>
                        </NavLink>
                    </NavItem>
                    {(showCustomHeaderLinks && customSections) &&
                        this.showCustomHeaderItems(customSections)
                    }
                    <NavItem className="nav-item">
                        <NavLink style={{ color: "#67BB8A" }} className="nav-link" to={routes.MOVIES}>
                            <span className="font-weight-regular mr-1">
                                {/* <i className="la la-play font-12 mr-2"></i> */}
                                All</span>PIX
                        </NavLink>
                    </NavItem>
                </Nav>
            </div>
        )
    }

    renderHeaderBrand = () => {
        const { appSettings } = this.props;
        let [BrandAlt, BrandLogo] = [config.applicationName, Logo];
        if (appSettings) {
            BrandAlt = appSettings.ApplicationName && appSettings.ApplicationName
            BrandLogo = appSettings.Logo && appSettings.Logo
        }
        return (
            <div className="mn-header-left col-6 col-xl-2">
                <button type="button" className="navbar-toggler collapsed" data-toggle="collapse" data-target="#primary-nav">
                    <div className="icon-left"></div>
                    <div className="icon-right"></div>
                </button>
                <NavbarBrand className="navbar-brand" href={routes.HOME}>
                    {BrandLogo ?
                        <img alt={BrandAlt} id="myAppLogo" src={BrandLogo} />
                        :
                        <h3>{BrandAlt}</h3>
                    }
                </NavbarBrand>
            </div>
        )
    }

    render() {
        let isFullPageLayout = getPageLayout();
        return (
            <header className="mn-header fixed-top shadow-lg">
                <div className="mn-header-top">
                    {!isFullPageLayout && <SignUpStrip isAuthenticated={this.props.isAuthenticated} />}
                    <div className="container-fluid">
                        <Navbar className="navbar navbar-expand navbar-dark mn-navbar align-items-center justify-content-between">
                            <div className="row w-100 gutter-5 align-items-center">
                                {this.renderHeaderBrand()}
                                {this.renderStuffpixHeaderLinks()}
                                {this.renderRightMenu()}
                            </div>
                        </Navbar>
                    </div>
                </div>
                <div className="mn-overlay collapse fade" id="primary-nav" data-toggle="collapse" data-target="#primary-nav"></div>
                {/* <div className="mn-overlay collapse fade" id="search-dropdown" data-toggle="collapse" data-target="#search-dropdown"></div> */}
            </header>
        );
    }
}

export default withAppConfig(withAuth(StuffpixHeaderView));
StuffpixHeaderView.defaultProps = {
    appSettings: {},
    customSections: []
}
StuffpixHeaderView.propTypes = {
    showCustomHeaderLinks: PropTypes.bool,
    appSettings: PropTypes.object.isRequired,
}