import React, { useState, useEffect } from "react";
import { setDocumentTitle } from "../../../helpers/GeneralHelpers";
import { withAuth } from "../../../context/AuthContext";
import { getMyList } from "../../../services/user/GetMyList";
import MovieCard from "../../../components/MovieCard/MovieCard";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";
import Loader from "../../../components/Loader/Loader";
import DeleteLike from "./Delete";

const MyList = props => {
  const [myList, setMyList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [crownTagValue, setcrownTagValue] = useState(true);
  useEffect(() => {
    initialize();
    return () => { };
  }, []);

  const initialize = () => {
    setDocumentTitle(`My List`);
    fetchMyList();
  };

  const fetchMyList = () => {
    let params = { CdnUrl: props.HostName, UserID: props.UserID };
    getMyList((list, err) => {
      //check no list flag later!
      if (!err) {
        if (list) {
          list = list.reverse();
        }
        setMyList(list);
      }
      setLoading(false);
    }, params);
  };

  const updateList = list => {
    if (list) {
      let rlist = list.reverse();
      setMyList(rlist);
    }
  };

  if (isLoading) {
    return (
      <section>
        <Loader />
      </section>
    );
  }
  if (myList && myList.length < 1) {
   
    return (
      <div>
         <section className="mn-page-head py-4 bg-gray-800">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <h3 className="text-white text-uppercase font-weight-bold mb-0">my list </h3>
            </div>
          </div>
        </div>
      </section> 
        <section className="mn-movies py-4">
          <div className="container-fluid">
            <DataNotFound text="You have no item(s) in My List" />
          </div>
        </section>
        </div>
      
    );
  }
  return (
    <main className="mn-main">
      <section className="mn-page-head py-4 bg-gray-800">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <h3 className="text-white text-uppercase font-weight-bold mb-0">my list </h3>
            </div>
          </div>
        </div>
      </section>
      <section className="mn-movies py-5">
        <div className="container-fluid">
          <div>
            <div className="row gutter-10 mn-movies-grid">
              {myList.map(movie => {
                 console.log("amn1111a", movie)
                return (
                  <MovieCard
                    key={movie.ID}
                    movie={movie}
                    crownTagValue={crownTagValue}
                    className="mb-3"
                    showCardHoverIcons={true}
                    onDelete={updateList}
                    // DeleteLike={
                    //   <DeleteLike ID={movie.ID} onDelete={updateList} />
                    // }
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default withAuth(MyList);
