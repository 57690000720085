import React from "react";
import PropTypes from "prop-types";
import { NavLink } from 'react-router-dom';
import config from "../../config/config";
import { HOW_TO_WATCH, SIGNUP } from "../../constants/routes";
import { withAppConfig } from "../../context/ConfigContext";

const SignUpStrip = (props) => {
    const { appTranslations : tr } = props;
    let newto = tr.new_to_muvinow || `NEW TO ${props.ServiceName}`;
    newto = newto.replace("Muvinow",props.ServiceName);
    return (
        (!props.isAuthenticated && config.customSettings.isStuffpixEnabled) &&
        <div className="join-bar">
            <div className="alert alert-dark text-uppercase px-0 m-0">
                <div className="container-fluid">
                    <div className="row gutter-5 align-items-center">
                        <div className="col-7">
                            <div className="text-truncate" style={{ fontSize: "0.95rem" }}>
                                {newto} {tr.find_out_how_it_works_and_how_to_watch || "FIND OUT HOW IT WORKS AND HOW TO WATCH"}
                                <NavLink to={HOW_TO_WATCH} className="ml-2 font-weight-bold">{tr.here || "HERE"}</NavLink>
                            </div>
                        </div>
                        <div className="col-5 text-right">
                            <NavLink to={SIGNUP} className="btn btn-white">{tr.free_to_join || "Free To Join"}</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
SignUpStrip.defaultProps = {
    ServiceName: "STUFF PIX",
}
SignUpStrip.propTypes = {
    ServiceName: PropTypes.string,
}

export default withAppConfig(SignUpStrip);