import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavItem } from 'reactstrap';
import placeholder from "../../../../assets/images/placeholder.png";
import {SETTINGS, PROFILE, STOREDVOUCHERS} from "../../../../constants/routes";
import { withAuth } from "../../../../context/AuthContext";
import { withAppConfig } from "../../../../context/ConfigContext";

const StuffpixProfileDropDown = (props) => {
    const { isAuthenticated, handleLogout,user = {} , handleLoginClick , appTranslations : tr } = props;
    let profileImage = user.picture ? user.picture : placeholder;
    return (
        isAuthenticated ?
            <UncontrolledDropdown nav inNavbar className="dropdown hd-dropdown user-dropdown" >
                <DropdownToggle nav caret className="p-0">
                    <div className="rounded-circle img-bg img-1x1" style={{ width: '34px', backgroundImage: `url(${profileImage})` }}></div>
                </DropdownToggle>
                <DropdownMenu right>
                    {user.given_name &&
                        <Fragment>
                            <DropdownItem>
                                <h5 className="mb-1">{user.given_name}</h5>
                                {/*<div className="text-black-50 font-12">Member Since: 25-May-2018</div>*/}
                            </DropdownItem>
                            <DropdownItem divider />
                        </Fragment>
                    }
                    {/* <Fragment>
                        <DropdownItem onClick={() => props.history.push(LIBRARY)}>Library</DropdownItem>
                        <DropdownItem divider />
                    </Fragment> */}
                    <DropdownItem onClick={() => props.history.push(PROFILE)}>{tr.edit_profile || "Edit Profile"}</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => props.history.push(SETTINGS)}>{tr.account_settings || "Account Settings"}</DropdownItem>{/*<Link className="text-dark" to={SETTINGS}>Account Settings</Link>*/}
                    <DropdownItem divider />
                    <DropdownItem onClick={() => props.history.push(STOREDVOUCHERS)}>{tr.saved_reward_codes || "Saved Reward Codes"}</DropdownItem>{/*<Link className="text-dark" to={SETTINGS}>Account Settings</Link>*/}
                    <DropdownItem divider />
                    <DropdownItem onClick={handleLogout}>{tr.sign_out || "Sign Out"}</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            :
            <NavItem className="nav-item">
                <a href="javascript:void(0)" className="btn btn-small btn-primary mt-1 text-uppercase" onClick={handleLoginClick}>
                    <span className="d-sm-none"><i className="la la-sign-in"></i></span>
                    <span className="d-none d-sm-block">{tr.login || "Login"}</span>
                </a>
            </NavItem>
    )
}

export default withAuth(withAppConfig(StuffpixProfileDropDown));

StuffpixProfileDropDown.propTypes = {
    handleLogout: PropTypes.func.isRequired,
    handleLoginClick: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    user: PropTypes.object
}