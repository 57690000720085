import config from "../../../config/config";
import _isEmpty from "lodash/isEmpty";

export function getSectionHeadingStyle(section) {
  let style = {};
  if (
    config.customSettings.isStuffpixEnabled &&
    section &&
    section.ColorCode &&
    typeof section.ColorCode === "string"
  ) {
    style = { color: section.ColorCode };
  }
  return style;
}

export function validSection(section) {
  if (
    section.Name &&
    section.Items &&
    section.Items.length > 0 &&
    !_isEmpty(section.Items[0]) &&
    (!config.customSettings.isStuffpixEnabled ||
      (config.customSettings.isStuffpixEnabled &&
        (section.InPage !== "false" && section.InPage !== false)))
  ) {
    return true;
  }
  return false;
}

export function validCard(section, movie) {
  let type = section.Type && section.Type.toLowerCase();
  if (
    !_isEmpty(movie) &&
    (!movie.Status || movie.Status !== "inactive") &&
    ((type !== "watched" && type !== "purhcasehistory") ||
      ((type === "watched" || type === "purchasehistory") &&
        !movie["HideExpiredRental_"]))
  )
    return true;

  return false;
}
