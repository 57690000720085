import Auth from "@aws-amplify/auth";
import API from "@aws-amplify/api";
import _find from "lodash/find";
import { STORED_LIST_SESSIONS, NO_EXPIRY, APP_CONFIG, STORED_MY_PGRATING_VALUE, STORED_COGNITO_USER_DATA, SUCCESS, ERROR, STORED_MY_DEVICE, APP_TRANSLATIONS } from "../constants/constants";
import { storeDataInLocalStore, getDataFromLocalStorage, getObjectFromArray, isEmpty } from "./GeneralHelpers";
const jwtDecode = require('jwt-decode');

export function unAuthenticatedHeader() {
    return { Authorization: "" };
}

export async function authenticatedHeader() {
    return { Authorization: (await Auth.currentSession()).idToken.jwtToken };
}

export async function customHeader() {
    try {
        if (await Auth.currentSession()) {
            return { Authorization: (await Auth.currentSession()).idToken.jwtToken };
        }
    }
    catch (e) {
        unAuthenticatedHeader();
    }
}

export function loadAppFavicon(settings, defaultFavicon) {
    let { Favicon = defaultFavicon } = settings;
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'Fav Icon';
    link.href = Favicon;
    document.getElementsByTagName('head')[0].appendChild(link);
}

export function addMovieMetaDescription(text) {
    let meta = document.createElement('meta');
    meta.name = 'description';
    meta.id = "movieId";
    meta.content = text;
    document.getElementsByTagName('head')[0].appendChild(meta);
}

export function removeMovieMetaDescription() {
    if (document.querySelector('#movieId')) {
        document.querySelector('#movieId').remove()
    }
}

export async function getCognitoUserAttributes(checkIsCustom = false, checkIsReward = false, userId) {
    let partnerName = "";
    let result = checkIsCustom ? false : checkIsReward ? partnerName : {};
    let cache = getDataFromLocalStorage(STORED_COGNITO_USER_DATA, userId);
    if (!isEmpty(cache)) { // rep :(
        if (checkIsCustom) {
            if (cache['custom:AccountType']) {
                result = true
            }
        } else if (!checkIsCustom) {
            if (checkIsReward) {
                if (cache['custom:AccountType']) {
                    partnerName = cache['custom:PartnerName'];
                }
            }
        }
        result = checkIsCustom ? result : checkIsReward ? partnerName : cache
        return result
    }
    return Auth.currentSession().then((congnitoUser) => {
        if (congnitoUser) {
            let tokenjwt = congnitoUser.idToken.jwtToken;
            let decoded = jwtDecode(tokenjwt);
            if (checkIsCustom) {
                if (decoded['custom:AccountType']) {
                    result = true
                }
            } else if (!checkIsCustom) {
                if (checkIsReward) {
                    if (decoded['custom:AccountType']) {
                        partnerName = decoded['custom:PartnerName'];
                    }
                }
            }
            storeDataInLocalStore(STORED_COGNITO_USER_DATA, decoded, decoded.sub, NO_EXPIRY);
            result = checkIsCustom ? result : checkIsReward ? partnerName : decoded;
            saveCurrentDeviceKey(decoded.sub);
            return result;
        } else {
            return result
        }
    }).catch((e) => {
        return result
    });
}

export async function getAppConfigurations() {
    let appConfiguration = [];
    appConfiguration = getDataFromLocalStorage(APP_CONFIG);
    if (!appConfiguration) {
        let apiName = 'ConfigAPI';
        let path = '/config';
        let myInit = {
            headers: { 'Content-Type': 'application/json' }
        };
        appConfiguration = await API.get(apiName, path, myInit).then(async (res) => {
            if (res.statusCode === 200 && res.statusMessage === "config_fetch_success") {
                await storeDataInLocalStore(APP_CONFIG, res.body.Items, null, NO_EXPIRY);
                return res.body.Items
            }
        }).catch((error) => {
            //
        });
    }
    return appConfiguration
}

export async function getSettingsFromConfig(key, returnValue = true) {
    let VALUE = [];
    let appConfiguration = await getAppConfigurations();
    let configObj = _find(appConfiguration, ["KEY", key]);
    if (configObj) {
        if (configObj.VALUE) {
            VALUE = configObj.VALUE
        }
    }
    return returnValue ? VALUE : configObj
}

export async function closeSession(sessionID, closeSessionType) {
    let apiName = 'AuthAPI';
    let path = '/v2/endsession';//closeSession 
    let headerData = {
        SessionID: sessionID
    };
    if (closeSessionType) {
        headerData["Type"] = closeSessionType
    }
    let init = {
        headers: { 'Content-Type': 'application/json' },
        body: headerData,
    };
    return API.post(apiName, path, init).then(() => {
        return SUCCESS;
    }).catch(() => {
        return (ERROR)
    });
}

export function saveCurrentDeviceKey(userId) {
    Auth.currentUserPoolUser().then((cognitoUser) => {
        return cognitoUser.getSession((err) => {
            if (err) { return ""; }
            cognitoUser.getCachedDeviceKeyAndPassword();
            return cognitoUser.getDevice({
                onSuccess: async (result) => {
                    if (result) {
                        if (result.Device) {
                            if (result.Device.DeviceKey) {
                                storeDataInLocalStore(STORED_MY_DEVICE, result.Device.DeviceKey, userId, NO_EXPIRY);
                            }
                        }
                    }
                    return result
                },
                onFailure: (err) => {
                    return ""
                }
            });
        });
    }).catch(() => { return "" })
}

export function verifyUserDevicesLimit(userId) {
    let list = getDataFromLocalStorage(STORED_LIST_SESSIONS, userId);
    if (list) {
        return list
    }
    let apiName = 'AuthAPI';
    let path = '/v2/listsessions';
    let myInit = { headers: { 'Content-Type': 'application/json' } };
    return API.get(apiName, path, myInit).then((res) => {
        if (res.statusCode === 200 || res.StatusCode === 200 || res.statusCode === 202 || res.StatusCode === 202) {
            storeDataInLocalStore(STORED_LIST_SESSIONS, res.body, userId, NO_EXPIRY)
            return res.body;
        }
    }).catch((e) => { });
}

export function getUserParentalControlSettings(userId) {
    let savedRating = getDataFromLocalStorage(STORED_MY_PGRATING_VALUE, userId);
    if (savedRating) {
        return savedRating
    }
    let apiName = 'AuthAPI';
    let path = '/getuserpreferences'; //get PGRating user
    let myInit = {
        headers: { 'Content-Type': 'application/json' }
    }
    return API.get(apiName, path, myInit).then((res) => {
        if (res.statusCode === 200) {
            if (res.body.UserPreferences.ParentalControlSetting) {
                storeDataInLocalStore(STORED_MY_PGRATING_VALUE, res.body.UserPreferences.ParentalControlSetting, userId);
                return res.body.UserPreferences.ParentalControlSetting
            }
            return ""
        }
    }).catch((err) => {
        return "Error" //DEFAULTPARENTALLOCKSETTINGS
    });
}

export function handleVerifyPinClick() {
    if (this.state.pinDigit1 !== "" && this.state.pinDigit2 !== "" && this.state.pinDigit3 !== "" && this.state.pinDigit4 !== "") {
        this.setState({
            isVerifyingPin: true,
        });
        let enteredPin = `${this.state.pinDigit1}${this.state.pinDigit2}${this.state.pinDigit3}${this.state.pinDigit4}`;
        let headers = { "ParentalPin": enteredPin };
        let myInit = {
            body: headers,
            headers: { 'Content-Type': 'application/json' }
        }
        return API.post('AuthAPI', "/parentalPin/verify", myInit).then((res) => {
            if (res.statusCode === 200 || res.StatusCode == 200) {
                if (this.isCompMounted) {
                    this.setState({
                        isVerifyingPin: false,
                        errorVerifyingPin: ""
                    });
                }
                this.handleAllowMoiveClick()
            }
        }).catch((err) => {
            let message = "Error";
            if (err.response !== undefined) {
                if (err.response.data.errorType) {
                    if (err.response.data.errorMessage) {
                        message = err.response.data.errorMessage.replace("[400] ", "");
                    }
                }
            }
            if (this.isCompMounted) {
                this.setState({
                    isVerifyingPin: false,
                    showErrorAlert: true,
                    alertMessage: message
                });
                this.disableAlert("showErrorAlert", "alertMessage");
            }
        });
    }
}

export function getPGRatingValue(pgRatingList, moviePgRating) {
    if (moviePgRating && moviePgRating.constructor === Array) {
        moviePgRating = moviePgRating[0]
    }
    let value = 1;
    if (pgRatingList) {
        pgRatingList.forEach((pgRating) => {
            if (pgRating.ID === moviePgRating) {
                if (pgRating.RatingValue) {
                    value = pgRating.RatingValue
                } else {
                    value = 1;
                }
            }
        })
    }
    return value; //General Audiences
}

export function getPGRatingList() {
    let appConfiguration = [];
    appConfiguration = getDataFromLocalStorage(APP_CONFIG);
    let pgRating = {};
    pgRating = getObjectFromArray(appConfiguration, "KEY", "APP_PG_RATING");
    if (appConfiguration) {
        return ((pgRating && !isEmpty(pgRating)) ? pgRating.VALUE ? pgRating.VALUE : [] : []);
    }
    return []
}

export async function getPGRatingIcon(pgRatingValue) {
    let pgRating = await getSettingsFromConfig("APP_PG_RATING");
    if (pgRating) {
        let selectedItem = _find(pgRating, ['ID', pgRatingValue]);
        return selectedItem;
    }
    return null
}

export async function getactiveCDNfromConfig() { //remove it later
    let CDN_CONFIG = await getSettingsFromConfig("CDN_CONFIGURATION");
    let cdnurl;
    if (CDN_CONFIG && !isEmpty(CDN_CONFIG)) {
        CDN_CONFIG.forEach((obj) => {
            if (obj && obj.Status === "active" && obj.Group === "Image") {
                cdnurl = obj.Host;
            }
        });
    }
    return cdnurl;
}

export function getHostName(appConfiguration) {
    let Host = "";
    let configObj = _find(appConfiguration, ["KEY", "CDN_CONFIGURATION"]);
    if (configObj && configObj.VALUE) {
        configObj.VALUE.forEach((obj) => {
            if (obj && obj.Status === "active" && obj.Group === "Image") {
                Host = obj.Host;
            }
        });
    }
    return Host;
}

export function getDefaultLanguage(appconfig) {
    let key = _find(appconfig, ["KEY", "APP_CONFIGURATIONS"]);
    key = (key && key.VALUE) ? key.VALUE[0] : {};
    key = key.AppDefaultLanguage;
    return key;
}