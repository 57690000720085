import React, { useState, memo } from "react";
import _find from "lodash/find";
import LoaderButton from "../../../components/LoaderButton/LoaderButton";
import { RemoveMovieFromMyList } from "../../../services/user/RemoveMyList";
import { AddMovieToMyList } from "../../../services/user/AddMyList";
import { withAppConfig } from "../../../context/ConfigContext";
import { withAuth } from "../../../context/AuthContext";
import { withRouter } from "react-router-dom";

const MyList = memo(({ banner, UserID, isAuthenticated, myList, ...props }) => {
  const [isLoading, setLoading] = useState("");
  let isLiked = _find(myList, ["ID", banner.MovieID]);
  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    if (isAuthenticated) {
      let params = {
        ID: banner.MovieID,
        UserID: UserID,
        cdnUrl: props.HostName
      };
      setLoading("Please Wait...");
      if (isLiked) {
        RemoveMovieFromMyList(onAPIresponse, params);
      } else {
        AddMovieToMyList(onAPIresponse, params);
      }
    } else {
      props.history.push(`/login?redirect=${props.location.pathname}${props.location.search}`
      );
    }
  };

  const onAPIresponse = (err, response) => {
    if (!err) {
      let added =
        props.appTranslations.added_to_list || "Added to My List!";
      let removed =
        props.appTranslations.removed_from_list ||
        "Removed from My List!";

      let alertText = isLiked ? removed : added;
      props.showAlert("showSuccessAlert", alertText);
      props.updateMyList(response);
    } 
    setLoading("");
  };

  return (
      <LoaderButton
        className={
          isLiked
            ? "btn btn-primary btn-large font-weight-bold mr-3 mb-3" //text-uppercase
            : "btn btn-secondary btn-large font-weight-bold mr-3 mb-3" //text-uppercase
        }
        onClick={handleClick}
        type="submit"
        isLoading={isLoading ? true : false}
        icon={
          isLiked ? (
            <i className="la la-check mr-2" />
          ) : (
            <i className="la la-plus mr-2" />
          )
        }
        text={isLiked ? "My List" : "My List"}
        loadingText="Please Wait …"
      />
  );
});

export default withAuth(withAppConfig(withRouter(MyList)));
