import React from 'react'
import MyList from './MyList';
import RentalLocks from './RentalLocks';
import { withRouter } from "react-router-dom";
import { convertSecToDuration } from "../../../helpers/GeneralHelpers"
const MuvinowBanner = ({ banner, ...props }) => {
    const handleBannerClick = (e) => {
       if( banner.Type !== "promotional"){
           e.preventDefault();
           if (banner.MovieID) {
               props.history.push(`/movie/${banner.MovieID}`);
           }
       }
    }

    return (
        <div className="mn-banner-caption" onClick={handleBannerClick}>
            <div className="container-fluid h-100">
                <div className="row h-100 align-items-end align-items-md-center">
                    <div className="col-lg-4">
                        {(banner.BannerPath && banner.Type === "promotional") ?

                            <div className="mn-banner-recent d-none d-xl-block">
                                <h1 className="font-weight-normal mb-4 text-uppercase">
                                    {banner.HeadingText ? banner.HeadingText : ""}
                                </h1>
                                <div className="text-uppercase mb-4">
                                    <a href={banner.SubHeadingUrl ? banner.SubHeadingUrl : "#"}
                                    className="text-white"
                                    >{banner.SubHeadingText ? banner.SubHeadingText : ""}
                                    {banner.SubHeadingUrl ? <i className="la la-angle-right ml-2"></i> : null}</a></div>
                                {(banner.HeadingText || banner.SubHeadingUrl) && <hr className="bg-dark my-4" />}
                            </div>
                            :
                            <div className="mn-banner-detail">
                                <h2 className="mb-3 text-uppercase"><a className="text-white" onClick={handleBannerClick}>{banner.MovieTitle && banner.MovieTitle}</a></h2>
                                <div className="mb-4 font-16">{banner.MovieReleaseYear} -
                            {banner.MovieGenre.map((gen, index) => {
                                    if (gen && index < 2) {
                                        return gen.charAt(0).toUpperCase() + gen.slice(1) + `${(index === 0 && banner.MovieGenre.length > 1) ? "/" : ``}`;
                                    }
                                })}

                            - {convertSecToDuration(banner.MoviePlayDuration)}</div>
                                <div className="mb-5 text-white-70 lh-2 d-none d-md-block">
                                    {banner.MovieDescription}
                                </div>
                            </div>
                        }
                        <div className="mn-banner-detail">
                            <h5 className="font-weight-normal mb-4">{banner.TagLine ? banner.TagLine : ""}</h5>
                            {/* <h2 className="mb-3 text-uppercase"><a className="text-white" onClick={handleBannerClick}>{banner.MovieTitle && banner.MovieTitle}</a></h2> */}
                            {(banner.LogoPath && banner.LogoPath !== "null") ? <h3 className="mb-4 text-uppercase"><img style={{ height: "70px" }} className="mn-banner-label" src={banner.LogoPath} alt="Central Intelligence" /></h3> : null}
                            {banner.MovieID &&
                                <span>
                                    <RentalLocks banner={banner} />
                                    <MyList banner={banner} myList={props.myList} updateMyList={props.updateMyList} />
                                </span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(MuvinowBanner);
