import React, { Component } from "react";
import _groupBy from "lodash/groupBy";
import { withRouter } from "react-router-dom";
import MySelect from "../../components/MySelect";
import Formsy from "formsy-react";
import {  checkIsMovieExpired } from "../../helpers/MoviesHelper";
import RenderScreen from "../../components/RenderScreen/RenderScreen";
import { setDocumentTitle, isEmpty, ping, convertSecToDuration, getDataFromLocalStorage } from "../../helpers/GeneralHelpers";
import { STORED_MY_PURCHASES, PURCHASE_LISTING, EST } from "../../constants/constants";
import DataNotFound from "../../components/DataNotFound/DataNotFound";
import { withAppConfig } from "../../context/ConfigContext";
import { withAuth } from "../../context/AuthContext";
import moment from "moment";
const activeTab = { today: "today", week: "week", month: "month", all: "all" };

class PurchaseListing extends Component {
    isCompMounted = true;
    SERVERTIME = null;
    moment = moment;
    CURRENTUSER_ID = null;
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            currentActivePurchaseList: {},
            purchaseList: [],
            dataError: "",
            activeTab: activeTab.all
        }
        // this.importMoment = importMoment.bind(this);
    }

    componentDidMount() {
        this.initializeState();
    }

    initializeState() {
        setDocumentTitle(PURCHASE_LISTING);
        if (this.props.isAuthenticated) {
            this.CURRENTUSER_ID = this.props.UserID;
            this.SERVERTIME = this.props.serverTime; //await ping is not written here tp avoid awiat
            this.getPurchasesData(this.props.UserID);
        }
    }

    getPurchasesData = async (userId) => {
        if(!this.SERVERTIME){
             this.SERVERTIME = await ping();   
        }
        let purchaseList = getDataFromLocalStorage(STORED_MY_PURCHASES, userId);
        if (isEmpty(purchaseList)) {
            purchaseList = this.props.purchases; //await fetchFullPurchaseList(userId)
        }
        purchaseList.forEach((movie) => {
            movie["isExpired_"] = checkIsMovieExpired(this.moment, movie, this.SERVERTIME);
        });
        this.sortPurchaseData(purchaseList);//updatedData
    }

    sortPurchaseData(data, tab) {
        let finalPurchaseList = _groupBy(data, (item) => {
            let createdAt = this.moment(item.CreatedAt).format("YYYY-MM-DD");
            return createdAt;
        });
        this.setState({
            purchaseList: finalPurchaseList
        },()=>{
            this.setActiveTabData(finalPurchaseList, this.state.activeTab);
        });
    }

    getTodaysData(data) {
        let firstDate = data !== null ? Object.keys(data)[0] : null;
        let todaysList = {};
        if (firstDate) {
            let frst = this.moment(firstDate, "YYYY-MM-DD");
            let isSame = this.moment(this.SERVERTIME).isSame(frst, "day");
            if (isSame) {
                todaysList[firstDate] = data[firstDate];
            }
        }
        this.setState({
            currentActivePurchaseList: todaysList,
            isLoading: false
        })
    }

    getWeeklyMonthlyData(data, tab) {
        let subtractDays = tab === activeTab.month ? 30 : 7;
        let lastSevenDays = this.moment(this.SERVERTIME).subtract(subtractDays, 'day');
        let sortedList = {};
        Object.keys(data).forEach((item) => {
            if (this.moment(item, "YYYY-MM-DD").isAfter(lastSevenDays, "day")) {
                sortedList[item] = data[item];
            } else {
                //return from here
            }
        });
        this.setState({
            isLoading: false,
            currentActivePurchaseList: sortedList,
        });
    }

    getAllData(data, tab) {
        this.setState({
            isLoading: false,
            currentActivePurchaseList: this.state.purchaseList,
        })
    }

    setActiveTabData(data, tab) {
        switch (tab) {
            case activeTab.today:
                this.getTodaysData(data, tab);
                break;
            case activeTab.week:
            case activeTab.month:
                this.getWeeklyMonthlyData(data, tab);
                break;
            case activeTab.all:
                this.getAllData(data, tab);
                break;
            default:
                this.getTodaysData(data, tab);
                break;
        }
    }

    handleChange = (name, value) => {
        this.setState({
            [name]: value,
            isLoading: true
        });
        this.setActiveTabData(this.state.purchaseList, value)
    }

    handleMovieClick = (e, movieID) => {
        e.preventDefault();
        this.props.history.push(`/movie/${movieID}`)
    }

    renderDatedPurchaseListing() {
        const { appTranslations :tr } = this.props;
        return (
            <div className="mn-purchase-list">
                {!isEmpty(this.state.currentActivePurchaseList) ?
                    Object.keys(this.state.currentActivePurchaseList).map((index) => {
                        return (
                            <div key={index} className="mn-purchase-item mb-4">
                                <div className="mb-2 text-white-50">{this.state.activeTab === activeTab.today ? tr.todays_purchases || "Todays Purchases" : index}</div>
                                <div className="list-group">
                                    {!isEmpty(this.state.currentActivePurchaseList[index]) ?
                                        this.state.currentActivePurchaseList[index].map((item, index) => {
                                            let MoviePosterURL = item.MoviePosterURL ? item.MoviePosterURL : "";//use someplaceholder
                                            if (!item.Status || item.Status !== "inactive") {
                                                return (
                                                    <div key={index} className="list-group-item list-group-item-dark flex-column align-items-start p-3">
                                                        <div className="media font-12">
                                                            <div className="align-self-start mr-3 mn-purchase-thumb">
                                                                <div className="img-bg img-1x15" style={{ backgroundImage: `url(${MoviePosterURL})` }}></div>
                                                            </div>
                                                            <div className="media-body">
                                                                <div className="text-white-50 mb-2">{tr.order_id} # {item.ID}</div>
                                                                <h5 className="text-white"><a onClick={(e) => this.handleMovieClick(e, item.MovieID)} className="link-white" href="javascript:void(0)">{item.MovieTitle && item.MovieTitle}</a>
                                                                    <span className="badge px-2 py-1 mx-1 badge-light font-10">{item.ContractTier && item.ContractTier.DisplayName && item.ContractTier.DisplayName}</span></h5>
                                                                <div className="mn-points mb-3">
                                                                    {item.MoviePlayDuration ? <span>{convertSecToDuration(item.MoviePlayDuration, false, false, true)}</span> : null}
                                                                    {item.MovieGenre &&
                                                                        <span>
                                                                            {item.MovieGenre ? item.MovieGenre.join(", ") : ""}
                                                                        </span>
                                                                    }
                                                                </div>
                                                                {item.Meta &&
                                                                    item.Meta.Actors &&
                                                                    <div className="mb-4">
                                                                        <strong className="font-weight-bold mr-2">{tr.starring} : </strong>
                                                                        {item.Meta.Actors.join(", ")}
                                                                    </div>
                                                                }
                                                                {/* {item.Meta && //item.MethodName
                                                                <div className="mb-4">
                                                                    <strong className="font-weight-bold mr-2">Payment Method : {item.MethodName} </strong>
                                                                </div>} */}
                                                                <div>
                                                                    {!item.isExpired_ ?
                                                                        <span className="badge badge-outline-primary px-2 font-12 font-weight-normal">{item.PurchaseType === EST ? tr.owned : tr.rented}</span>
                                                                        :
                                                                        <span className="badge badge-outline-primary px-2 font-12 font-weight-normal ml-1">{(item.ValidUntil === "N-A" && !item.isExpired_) ? "" : item.isExpired_ ? tr.expired : ""}</span>
                                                                    }{/*(item.ValidUntil === "N-A") && "New"*/}
                                                                    {/* {item.Type === "Child" && <span className="ml-1 badge badge-outline-primary px-2 font-12 font-weight-normal">collection purchase</span>} */}
                                                                </div>
                                                                <div className="d-md-flex justify-content-between">
                                                                    <div className="mt-3 text-success"> {(this.moment && item.CreatedAt) && `${tr.purchased_on} : ${this.moment(item.CreatedAt).format("YYYY MMMM Do, h:mm:ss a")}`}</div>
                                                                    {!item.isExpired_ && <div className="mt-3 text-danger"> {(this.moment && item.ValidUntil) && `${tr.available_until} : ${(item.ValidUntil === "N-A") ? item.RentMaxValidity ? this.moment(item.RentMaxValidity).format("YYYY MMMM Do, h:mm:ss a") : tr.new || "New" : this.moment(item.ValidUntil).format("YYYY MMMM Do, h:mm:ss a")}`}</div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {(item.Voucher) &&
                                                            <div className="pt-3 mt-3 border-top border-dark">
                                                                <div className="row gutter-5">
                                                                    <div className="col-6 col-sm-3">
                                                                        <div className="font-weight-normal">{tr.promotion || "Promotion"}</div>
                                                                    </div>
                                                                    <div className="col-sm-6 text-center d-none d-sm-block">
                                                                        <span>{tr.reward_code} : </span>
                                                                        <label className="badge badge-secondary m-0">{item.Voucher}</label>
                                                                    </div>
                                                                    <div className="col-6 col-sm-3 text-right">
                                                                        {item.ContractTier && `${item.ContractTier.CurrencySymbol ? `-${item.ContractTier.CurrencySymbol}` : ""}`}
                                                                        {(item.Discount || item.Discount === 0) && item.Discount}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="pt-3 mt-3 border-top border-dark">
                                                            <div className="row gutter-5">
                                                                <div className="col-6 col-sm-3">
                                                                    <div className="text-white font-weight-normal">{tr.total_billed || "Total Billed"}</div>
                                                                </div>
                                                                <div className="col-sm-6 text-center d-none d-sm-block">
                                                                    <div className="font-14 mn-points-2">
                                                                        {/* <span><i className="la la-credit-card font-18"></i></span>
                                                                    <span>
                                                                        <i className="la la-circle font-8"></i>
                                                                        <i className="la la-circle font-8"></i>
                                                                        <i className="la la-circle font-8"></i>
                                                                        <i className="la la-circle font-8"></i>
                                                                    </span>
                                                                    <span>
                                                                        <i className="la la-circle font-8"></i>
                                                                        <i className="la la-circle font-8"></i>
                                                                        <i className="la la-circle font-8"></i>
                                                                        <i className="la la-circle font-8"></i>
                                                                    </span>
                                                                    <span>
                                                                        <i className="la la-circle font-8"></i>
                                                                        <i className="la la-circle font-8"></i>
                                                                        <i className="la la-circle font-8"></i>
                                                                        <i className="la la-circle font-8"></i>
                                                                    </span>
                                                                    <span>4563</span> */}
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 col-sm-3 text-right">
                                                                    {item.ContractTier && item.ContractTier.CurrencySymbol ? item.ContractTier.CurrencySymbol : ""}
                                                                    {item.Type === "Child"
                                                                    ? item.Total
                                                                    : item.Voucher ? item.Total : item.ContractTier ? (item.ContractTier.Price || item.ContractTier.Price === 0) ? item.ContractTier.Price : "" : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                        :
                                        <p>{tr.no_purchases_found}</p>
                                    }
                                </div>
                            </div>
                        )
                    })
                    :
                    <DataNotFound
                        height="300px"
                        text={tr.no_purchases_found}
                    />
                }
            </div>
        )
    }

    renderView() {
        const { appTranslations :tr } = this.props;
        let purchaseHistoryOptions = [{ value: activeTab.today, title: tr.today || "Today" }, { value: activeTab.week, title: tr.last_week },
        { value: activeTab.month, title: tr.last_30_day}, { value: activeTab.all, title: tr.all }]
        return (
            <section className="mn-notifications py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <Formsy>
                                <MySelect
                                    id="activeTab"
                                    name="activeTab"
                                    innerClassName="form-control-dark"
                                    handleChange={this.handleChange}
                                    value={this.state.activeTab}
                                    options={purchaseHistoryOptions}
                                />
                            </Formsy>
                        </div>
                    </div>
                    {this.renderDatedPurchaseListing()}
                </div>
            </section>
        )
    }

    render() {
        return (
            <main className="mn-main">
                <section className="mn-page-head py-4">
                    <div className="container">
                        <h3 className="mb-0 text-center text-uppercase">Purchase History</h3>
                    </div>
                </section>
                <RenderScreen
                    isLoading={this.state.isLoading}
                    isError={this.state.dataError} >
                    {this.renderView()}
                </RenderScreen>
            </main>    
    );
    }
}
export default withAppConfig(withAuth(withRouter(PurchaseListing)))