import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { getSectionHeadingStyle as style } from "../helpers/";
import ViewMore from "./ViewMore";
import * as routes from "../../../constants/routes";

const SectionHeader = ({ ID, Name, Type, history, ...section }) => {
  const handleGenereClick = () => {
    if (Type === "screening_room") {
      history.push(routes.ALLROOMS);
    } else if (Type === "movie_collections") {
      history.push(routes.ALLCOLLECTIONS);
    } else {
      history.push(
        `/genre/${Type}/${ID}?name=${Name}${
          section.InNavigation ? `&filtered=${true}` : ``
        }`
      );
    }
  };
  return (
    <div className="d-flex w-100 justify-content-between align-items-center mb-4">
      <div className="">
        <h4
          className="text-white text-uppercase mb-0"
          style={style(section)}
        >
          {Name}
        </h4>
      </div>
      <ViewMore onClick={handleGenereClick} Type={Type} />
    </div>
  );
};

export default withRouter(SectionHeader);

SectionHeader.propTypes = {
  history: PropTypes.object.isRequired,
  Type: PropTypes.string,
  ID: PropTypes.string,
  Name: PropTypes.string
};
