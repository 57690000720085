import React, { memo, useState } from "react";
import Formsy from "formsy-react";
import MyInput from "../../../../components/MyInput";
import LoaderButton from "../../../../components/LoaderButton/LoaderButton";
import { withAppConfig } from "../../../../context/ConfigContext";

const Form = props => {
  const { appTranslations: tr } = props;
  const [codeInput, setCode] = useState("");

  const handleChange = (name, value) => {
    setCode(value);
  };

  const onSubmit = e => {
    e.preventDefault(); //imp!
    props.onSubmitVoucher(codeInput);
  };
  return (
    <Formsy
      onValidSubmit={onSubmit}
      //onClick={props.onVoucherInputClick}
    >
      <div className="input-group">
        <VoucherInput tr={tr} onChange={handleChange} />
        <span className="input-group-append">
          <LoaderButton
            type="submit"
            className="btn btn-primary"
            isLoading={props.verifyingVoucher}
            disabled = {!codeInput}
            text={"Redeem"}
            loadingText={tr.verifying || "Verifying …"}
            onClick={onSubmit}
          />
        </span>
      </div>
    </Formsy>
  );
};

///////////////////////////////////////////////////////

const VoucherInput = memo(props => {
  return (
    <MyInput
      name="voucherCode"
      type="text"
      innerClassName="form-control-dark"
      placeholder={props.tr.enter_reward_code}
      required
      maxLength="20"
      //innerRef={forwardedRef}
      outerDiv={false}
      handleChange={props.onChange}
    />
  );
});

export default withAppConfig(Form);
