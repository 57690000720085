
import React from 'react';
import { Modal } from "reactstrap";
import PropTypes from 'prop-types';
import { withAppConfig } from "../../context/ConfigContext";

class TransactionModal extends React.Component {

    handleWatchMovieClick = (e) => {
        const { MovieID, handleWatchMovieClick } = this.props;
        handleWatchMovieClick(e, MovieID)
    }

    handleMovieClick = (e) => {
        const { MovieID, handleMovieClick } = this.props;
        handleMovieClick(e, MovieID, true)
    }
    
    render() {
        const { Title = ``, ModalHeaderTitle, RentMaxValidity = ``, RentTvodValidity = `` , appTranslations : tr  } = this.props;
        return (
            <Modal
                isOpen={true}
                //className="mn-voucher-method modal-dialog-centered modal-dark pb-5"
                centered
                modalClassName="modal-dark"
            >
                <div className="modal-content">
                    <div className="modal-body p-4 p-sm-5 text-white-50">
                        <div className="card-body text-center position-relative" style={{ "minHeight": "150px" }}>
                            <div className="mn-payment-msg">
                                <div className="mb-3 text-success"><i className="la la-check-circle font-48"></i></div>
                                <h3 className="text-success">{ModalHeaderTitle}</h3>
                                <p>Your Rental of<strong className="text-white"> {Title}</strong> is now ready to watch.</p>
                                {/* <p>You have <strong className="text-white"> {RentMaxValidity}</strong> to start watching this rental, and
                                 <strong className="text-white"> {RentTvodValidity}</strong> to view the rental once started</p> */}
                                <div>
                                    {/* <div className="my-5">
                                        <a href="javascript:void(0)" onClick={this.handleWatchMovieClick} className="btn btn-primary">{tr.start_watching}</a>
                                        <a href="javascript:void(0)" onClick={this.handleMovieClick} className="btn btn-secondary ml-3 "> {tr.not_yet}</a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ Modal>
        )
    }
}

TransactionModal.defaultProps = {
    ModalHeaderTitle: "Transaction Successfull!"
}

TransactionModal.propTypes = {
    Title: PropTypes.string,
    RentMaxValidity: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    RentTvodValidity: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    ModalHeaderTitle: PropTypes.string,
    MovieID: PropTypes.string,
    handleMovieClick: PropTypes.func,
    handleWatchMovieClick: PropTypes.func,
}
export default withAppConfig(TransactionModal);