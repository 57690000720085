import React, { Component } from 'react'
import PropTypes from "prop-types";
import { controls } from "./constants";

class HtmlVideoPlayer extends Component {
    videoPlayer;

    componentDidMount() {
        if (this.props.applyCustomStyling) {
            this.importPlyr();
        }
    }

    importPlyr = () => {
        import("plyr").then((plyr) => {
            let control = `<div className="plyr__title-bar plyr__control"><div className="plyr__title">${this.props.title}</div></div>${controls}`
            new plyr("#video-player", { controls: control });
            plyr.setup("#video-player");
        }).catch((err) => {
            this.onError("import plyr unsuccessful,Try again!");
        })
    }

    onPlay = () => {
        if (this.props.onVideoPlay) {
            this.props.onVideoPlay(this.videoPlayer.currentTime);
        }
    }

    onPause = () => {
        if (this.props.onVideoPause) {
            this.props.onVideoPause(this.videoPlayer.currentTime, this.videoPlayer.duration);
        }
    }

    onEnded = () => {
        if (this.props.onVideoEnd) {
            this.props.onVideoEnd(this.videoPlayer.currentTime);
        }
    }

    onError = (e, error) => {
        if (this.props.onVideoError) {
            this.props.onVideoError(this.videoPlayer.currentTime, error);
        }
    }

    render() {
        const { width, height, autoPlay, posterUrl, videoUrl, size } = this.props;
        return (
            <video
                className="mn-player"
                id="video-player" //crossOrigin="true"
                playsInline
                controls
                autoPlay={autoPlay}
                width={width}
                height={height}
                poster={posterUrl}
                onPlay={this.onPlay}
                onPause={this.onPause}
                onEnded={this.onEnded}
                onError={this.onError}
                ref={player => this.videoPlayer = player}
            >
                <source src={videoUrl} size={size} />
            </video>
        )
    }
}

export default HtmlVideoPlayer;
HtmlVideoPlayer.defaultProps = {
    width: "640",
    height: "400",
    size: "576",
    title: "",
    applyCustomStyling: true,
    autoPlay: true
}
HtmlVideoPlayer.propTypes = {
    onVideoPlay: PropTypes.func,
    onVideoResume: PropTypes.func,
    onVideoPause: PropTypes.func,
    onVideoError: PropTypes.func,
    onVideoEnd: PropTypes.func,
    width: PropTypes.string,
    height: PropTypes.string,
    size: PropTypes.string,
    autoPlay: PropTypes.bool,
    videoUrl: PropTypes.string.isRequired,
    posterUrl: PropTypes.string,
    title: PropTypes.string,
    applyCustomStyling: PropTypes.bool
}