import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';
import MyInput from "../../../components/MyInput";
import Formsy from 'formsy-react';
import LoaderButton from "../../../components/LoaderButton/LoaderButton";
import { getInputFields } from "./contants";
import config from "../../../config/config";

class Contact extends Component {
    inputs = {}

    renderEmailUsLink = () => {
        if (config.customSettings.isStuffpixEnabled) {
            return null
        }
        const { SupportEmail } = this.props.appSettings;
        return (
            SupportEmail &&
            <div className="mt-3 text-center">
                <Fragment>
                    You can also <a href={`mailto:${SupportEmail}`}>
                        Email Us </a> your question
                </Fragment>
            </div>
        )
    }

    render() {
        const { isLoading, userComment, commentErrorMessage, canSubmit, enableButton, disableButton, handleSubmit, handleFormSubmit, handleChange, changeValue ,appTranslations : tr } = this.props;
        let InputFields = getInputFields(tr);
        return (
            <main className="mn-main" >
                <section className="mn-page-head py-4">
                    <div className="container">
                    <h3 className="mb-0 text-center text-uppercase">Contact Us</h3>
                    </div>
                </section>
                <section className="mn-notifications py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="card card-dark w-100 mb-4">
                                    <div className="card-body p-md-5">
                                        <h4 className="mb-4">{tr.get_in_touch || "Get In Touch"}</h4>
                                        {isLoading && <span className="loader-spiral mn-transparent"><span className="spiral-inn"></span></span>}
                                        <Formsy onValidSubmit={handleSubmit} onValid={enableButton} onInvalid={disableButton}>
                                            {InputFields && InputFields.map((input, index) => {
                                                const { Name, Placeholder, Required } = input;
                                                return (
                                                    <MyInput
                                                        key={index}
                                                        id={Name}
                                                        name={Name}
                                                        placeholder={Placeholder}
                                                        required={Required}
                                                        innerClassName="form-control-dark"
                                                        outerClassName="mb-4"
                                                        type="text"
                                                        handleChange={handleChange}
                                                        innerRef={(input) => { this.inputs[Name] = input }}
                                                    />
                                                )
                                            })}
                                            <div className="form-group mb-md-4">
                                                <textarea
                                                    className="form-control form-control-dark"
                                                    onChange={changeValue}
                                                    name="userComment"
                                                    id="userComment"
                                                    placeholder={tr.your_message || "Your Message ..."}
                                                    required
                                                    value={userComment}
                                                    ref={(input) => { this.inputs["userComment"] = input }}
                                                    rows="8">
                                                </textarea>
                                                <div
                                                    className="invalid-feedback"
                                                    style={{ display: (commentErrorMessage && commentErrorMessage.length > 0) ? 'block' : 'none' }}>
                                                    {commentErrorMessage}
                                                </div>
                                            </div>
                                            <LoaderButton
                                                disabled={!canSubmit}
                                                type="submit"
                                                className="btn btn-primary btn-block text-uppercase"
                                                isLoading={isLoading}
                                                text={tr.submit || "Submit"}
                                                onClick={handleFormSubmit}
                                                loadingText={tr.submitting}
                                            />
                                        </Formsy>
                                    </div>
                                </div>
                                {this.renderEmailUsLink()}
                            </div>
                        </div>
                    </div>
                </section>
            </main >
        )
    }
}
Contact.defaultProps = {
    appSettings: {},
}

Contact.propTypes = {
    appSettings: PropTypes.object,
    canSubmit: PropTypes.bool,
    commentErrorMessage: PropTypes.string,
    userComment: PropTypes.string,
    isLoading: PropTypes.bool,
    handleSubmit: PropTypes.func,
    enableButton: PropTypes.func,
    disableButton: PropTypes.func,
    handleFormSubmit: PropTypes.func,
    handleChange: PropTypes.func,
    changeValue: PropTypes.func
};
export { Contact };
