import { propTypes, withFormsy } from 'formsy-react';
import React from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
class MyInput extends React.Component {

    constructor(props) {
        super(props);
        this.changeValue = this.changeValue.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    changeValue(event) {
        // let copyText = document.getElementById(this.props.name);
        // this.props.setValue(copyText[this.props.type === 'checkbox' ? 'checked' : 'value']);
        // this.props.handleChange(copyText.name, copyText.value, this.props.returnKeyType);
        if (this.props.name === "phone_number") {
            this.props.setValue(event);
            this.props.handleChange(this.props.name, event, this.props.returnKeyType);
        }
        else {
            this.props.setValue(event.currentTarget[this.props.type === 'checkbox' ? 'checked' : 'value']);
            this.props.handleChange(event.currentTarget.name, event.currentTarget.value, this.props.returnKeyType);
        }
    }

    handleClick(event) {
        if (this.props.myRef !== "") {
            event.currentTarget.select();
        }
    }

    handleKeyPress = (event) => {
        let copyText = document.getElementById(this.props.name)
        let backKeyCode = 8;
        if (this.props.myRef !== "") {
            if (event.keyCode === backKeyCode) {
                this.props.handleChange(copyText.name, copyText.value, this.props.returnKeyType, true);
            }
        }
    }

    setInput = (value) => {
        this.props.setValue(value);
    }

    clearInput = () => {
        this.props.setValue(this.props.type === 'checkbox' ? '' : '');
    }
    render() {
        const outerClassName = `form-group ${this.props.outerClassName} ${this.props.showRequired() ? 'required' : ''} ${this.props.showError() ? 'error' : ''}`;
        const innerClassName = `form-control form-control-lg form-control-dark ${this.props.innerClassName}`;
        const errorMessage = this.props.getErrorMessage();
        return (
            this.props.outerDiv ?
                this.props.name === "phone_number" ?
                    <div className={outerClassName}>
                        <PhoneInput
                            inputProps={{
                                name: this.props.name,
                                id: this.props.id,
                                autoComplete: "off",
                                type: this.props.type || 'text',
                                autoFocus: true,
                                required: true,


                            }}
                            placeholder={this.props.placeholder || "Enter phone number"}
                            // isValid={}
                            searchNotFound='No entries to show'
                            dropdownStyle={{ color: "black" }}
                            searchStyle={{
                                border: "1px solid grey",
                                height: "25px",
                                borderRadius: "5px",
                                width: "100%",
                                padding: "2px 23px 2px 30px",
                                outline: 0,
                                backgroundColor: "#f5f5f5"
                            }}
                            disabled={this.props.disabled || false}
                            autocompleteSearch={true}
                            jumpCursorToEnd={true}
                            defaultErrorMessage="Enter Valid Phone Number"
                            enableSearch={true}
                            disableSearchIcon={true}
                            onChange={this.changeValue}
                            value={this.props.getValue() || ''}
                            country='pk'
                            onKeyDown={this.handleKeyPress}
                            onClick={this.handleClick}
                        />
                        <div className="invalid-feedback" style={{ display: errorMessage && errorMessage.length > 0 ? 'block' : 'none' }}>
                            {errorMessage}
                        </div>
                    </div>
                    : <div className={outerClassName}>

                        <input
                            type={this.props.type || 'text'}
                            className={innerClassName}
                            onChange={this.changeValue}
                            disabled={this.props.disabled || false}
                            value={this.props.getValue() || ''}
                            placeholder={this.props.placeholder || ''}
                            name={this.props.name}
                            id={this.props.id}
                            ref={this.props.myRef}
                            autoComplete="off"
                        />
                        <div className="invalid-feedback" style={{ display: errorMessage && errorMessage.length > 0 ? 'block' : 'none' }}>
                            {errorMessage}
                        </div>
                    </div>
                : <input
                    type={this.props.type || 'text'}
                    className={innerClassName}
                    onChange={this.changeValue}
                    onClick={this.handleClick}
                    disabled={this.props.disabled || false}
                    value={this.props.getValue() || ''}
                    placeholder={this.props.placeholder || ''}
                    name={this.props.name}
                    id={this.props.id}
                    maxLength={this.props.maxLength}
                    required={this.props.required}
                    ref={this.props.myRef}
                    onKeyDown={this.handleKeyPress}
                    autoComplete="off"
                />
        );
    }
}

MyInput.defaultProps = {
    outerDiv: true,
    maxLength: "",
    required: false,
    myRef: "",
    returnKeyType: "",
    innerClassName: ``
}

MyInput.propTypes = {
    ...propTypes,
};

export default withFormsy(MyInput);
