import { useEffect } from "react";
import { withAuth } from "../../context/AuthContext";
import { withFirebase } from "../../context/FirebaseContext";
import { lastRefreshValue } from "../../helpers/CacheHelpers";

const FirebaseListeners = props => {
  useEffect(() => {
    initialize();
    return () => {};
  }, [props.UserID]);

  const initialize = () => {
    let list = ["orders", "subscriptions",'collections'];
    list.forEach(key => {
      let pathKey = `cache/users/${props.UserID}/${key}`;
      let databaseRef = props.firebase.db.ref(pathKey);
      if (databaseRef) {
        databaseRef.on("value", snapshot => {
          let notifiedValue = { status: snapshot.val() };
          let isUpdated = lastRefreshValue(key, props.UserID, notifiedValue);
          if (snapshot && snapshot.val() && isUpdated) {
            handleEvents(key);
          }
        });
      }
    });
  };
  
  const handleEvents = key =>{
    if(key === 'orders'){
        props.fetchPurchases(props.UserID);
    }
    if(key === 'subscriptions'){
        props.getSubscriptions()
    }
    if(key === 'collections'){
      props.getCollecions();
      props.fetchPurchases(props.UserID);
    }
  }
  return null;
};

export default withAuth(withFirebase(FirebaseListeners));
