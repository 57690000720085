import React, { Component } from "react";
import PropTypes from 'prop-types';
import {getPGRatingIcon} from "../../helpers/ConfigHelpers";

class PgRating extends Component{
    constructor(props){
        super(props);
        this.state = {
            pgRatingData: {}
        }
    }

    componentDidMount() {
        this.initialize(this.props.pgID);
    }

    initialize = async (pgID) => {
        let pgRating = await getPGRatingIcon(pgID);
        let pgRatingData = {};
        if (pgRating) {
            pgRatingData["PGIcon"] = pgRating.Icon;
            pgRatingData["PGDescription"] = pgRating.Name;
        }
        this.setState({
            pgRatingData: pgRatingData
        });
//     storeDataInLocalStore(`${STORED_MOVIES_DETAIL}${movieID}`, movie, null, NO_EXPIRY);
    }
    render() {
        if(!this.props.pgID){
            return null;
        }
        return (
            (this.state.pgRatingData.PGIcon) &&

                    this.state.pgRatingData.PGIcon ?
                        <span className="mr-2">
                            <span className="pg-tag" style={{ width: '24px' }}>
                                <img title={this.state.pgRatingData.PGDescription ? this.state.pgRatingData.PGDescription : ""} style={{ width: '24px' }} src={this.state.pgRatingData.PGIcon} />
                            </span>
                        </span> :
                         null

        )
    }
}
PgRating.propTypes = {
    pgID: ""
};
PgRating.propTypes = {
    pgID: PropTypes.string
};
export {PgRating};