import React from "react";
import { withRouter } from "react-router-dom";
import config from "../../../config/config"
import { withAppConfig } from "../../../context/ConfigContext";
const ProceedPlayerMessage = props => {
  let showButton = props.location.state && props.location.state.limitexceeded;
  // let message = props.appTranslations.concurrent_devices_message || "The concurrent playback limit for your <applicationname> account is set to <count>. If you are unable to play movie due to exceed in playback limit, you can remove playback session from device manager."
  // message = message.replace(`<applicationname>`,config.applicationName);
  // message = message.replace(`<count>`,props.concurrentSessions && props.concurrentSessions);
  return !showButton ? null : (
    <div className="alert alert-dark">
      <div>
        <i className="la la-exclamation-triangle font-18 mr-3" />
          {/* {message} add translation for this message also !!*/}
          To play this movie on this device, you must stop playback sessions on all other playing devices listed below:
      </div>
    </div>
  );
};

export default withRouter(withAppConfig(ProceedPlayerMessage));
