import React, { Component } from "react";

class TermsConditions extends Component {
    render() {
        return (
            <section className="mn-movies py-4 border-bottom border-dark">
                <div className="container">
                    <h4 className="text-white-70 mb-3">
                        Terms & Conditions
                    </h4>
                    <div className="text-white-50 font-12">
                        <p>
                            Offer available to eligible <span className="mx-1"><img src="https://i.imgur.com/x4PcLfE.png" width="60" /></span> Members upon redemption of a voucher code directly received from <a href="https://plusrewards.com.au">plusrewards.com.au</a>. Each MuviNow <span className="mx-1"><img src="https://i.imgur.com/x4PcLfE.png" width="60" /></span> code entitles the eligible customer to one 48-hour new release movie rental to be selected from the provided listing, which must be viewed within 30 days of redemption of the reward code. Offer is limited to the first 10,000 eligible customers, and may be redeemed for one free rental during the Offer Period of 28 November- 31 December 2018. Any unused reward code value is forfeited upon expiration of the redemption window.
                             New release HD titles are excluded from this promotion. Offer not redeemable for cash and not transferable.
                        </p>
                        <p>
                            MuviNow reserves the right to freely curate <span className="mx-1"><img src="https://i.imgur.com/x4PcLfE.png" width="60" /></span> $1 Movie Selections, and all content is subject to change. These titles are offered exclusively to eligible customers under the <span className="mx-1"><img src="https://i.imgur.com/x4PcLfE.png" width="60" /></span> Program, and this offer shall not be extended beyond those individuals who are directly delivered the <span className="mx-1"><img src="https://i.imgur.com/x4PcLfE.png" width="60" /></span> $1 Movie Reward Code. MuviNow full Terms and Conditions apply.
                        </p>
                        <p>
                            Blackkklansman - © 2017 NBCUniversal Studios. All Rights Reserved. A Simple Favor - © 2017 Roadshow Films Pty. Ltd. All Rights Reserved. Incredibles 2 - © 2017 The Walt Disney Coorporation. All Rights Reserved. Christopher Robin - © 2017 The Walt Disney Coorporation. ALL RIGHTS RESERVED. Crazy Rich Asians - © 2017 Roadshow Films Pty. Ltd. All Rights Reserved.
                        </p>
                    </div>
                </div>
            </section>
        )
    }
}
export default TermsConditions;