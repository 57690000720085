import API from "@aws-amplify/api";

export function POST(apiName, path, body) {
  let init = {};
  init["header"] = { headers: { "Content-Type": "application/json" } };
  if (body) {
    init["body"] = body;
  }
  return new Promise((resolve, reject) => {
    return API.post(apiName, path, init)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function GET(apiName, path) {
  let init = {
    headers: { Accept: "application/json" }
  };
  return new Promise((resolve, reject) => {
    return API.get(apiName, path, init)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function DELETE(apiName, path, params) {
  params["headers"] = { "Content-Type": "application/json" };
  return new Promise((resolve, reject) => {
    return API.del(apiName, path, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}
