export const getInputFields = (tr) => [
    {
        Name: "userName",
        Title: "Full Name",
        Placeholder: tr.full_name || "Full Name",
        Required: true
    },
    {
        Name: "userEmail",
        Title: "Email Address",
        Placeholder: tr.email_address,
        Required: true
    },
    {
        Name: "userSubject",
        Title: "Subject",
        Placeholder: tr.subject || "Subject",
        Required: false
    }
];