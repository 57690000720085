import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavItem } from 'reactstrap';
import placeholder from "../../../../assets/images/placeholder.png";
import { SETTINGS, PROFILE, STOREDVOUCHERS, SUBSCRIPTION_HISTORY } from "../../../../constants/routes";
import { withAuth } from "../../../../context/AuthContext";
import { Link } from "react-router-dom";
const ProfileDropDown = (props) => {
    const { isAuthenticated, handleLogout, user, handleLoginClick, appTranslations: tr } = props;
    let profileImage = user.picture ? user.picture : placeholder;
    return (
        isAuthenticated ?
            <UncontrolledDropdown className="dropdown" >
                <DropdownToggle className="p-0">
                    <div className="rounded-circle img-bg img-1x1" style={{ width: '34px', backgroundImage: `url(${profileImage})` }}></div>
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-dark has-arrow-right">
                    {(user.email || user.phone_number)  &&
                        <Fragment>
                            <DropdownItem onClick={() => props.history.push(PROFILE)}>
                                <h5 className="mb-1">{user.given_name} {user.family_name}</h5>
                                <h7 className="mb-1">{user.email ? user.email : user.phone_number}</h7>
                                {/*<div className="text-black-50 font-12">Member Since: 25-May-2018</div>*/}
                            </DropdownItem>
                            <DropdownItem divider />
                        </Fragment>
                    }
                    <DropdownItem onClick={() => props.history.push(PROFILE)} className="text-white mb-0" >{tr.edit_profile || "Edit Profile"}</DropdownItem>
                    <DropdownItem onClick={() => props.history.push(SETTINGS)} className="text-white mb-0" >{tr.account_settings || "Account Settings"}</DropdownItem>{/*<Link className="text-dark" to={SETTINGS}>Account Settings</Link>*/}
                    <DropdownItem onClick={() => props.history.push(SUBSCRIPTION_HISTORY)} className="text-white mb-0" >{tr.manage_subscriptions || "Manage Subscriptions"}</DropdownItem>
                    <DropdownItem onClick={() => props.history.push(STOREDVOUCHERS)} className="text-white mb-0" >{tr.saved_reward_codes || "Saved Reward Codes"}</DropdownItem>{/*<Link className="text-dark" to={SETTINGS}>Account Settings</Link>*/}
                    <DropdownItem onClick={handleLogout}>{tr.sign_out || "Sign Out"}</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            :
            <NavItem className="nav-item">
                <Link to="/signup" className="btn btn-small btn-secondary mr-3 d-none d-md-inline-block">
                    <span className="d-sm-none"><i className="las la-user-plus"></i></span>
                    <span className="d-none d-sm-block">{tr.Register || "Register"}</span>
                </Link>
                <a href="javascript:void(0)" className="btn btn-small btn-primary" onClick={handleLoginClick}>
                    <span className="d-sm-none"><i className="la la-sign-in"></i></span>
                    <span className="d-none d-sm-block">{tr.Sign_in || "Sign in"}</span>
                </a>

            </NavItem>
    )
}

export default withAuth(ProfileDropDown);

ProfileDropDown.propTypes = {
    handleLogout: PropTypes.func.isRequired,
    handleLoginClick: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
}