import { POST } from "../../api/"
import { getDataFromLocalStorage, storeDataInLocalStore } from "../../helpers/GeneralHelpers";
import { getDefaultLanguage } from "../../helpers/ConfigHelpers";
import config from "../../config/config";
import isEmpty from "lodash/isEmpty";
import { JS } from "@aws-amplify/core";

export async function getAppTranslations(configuration,languageKey) {
    return new Promise((resolve,reject)=>{
        let appTranslations = {};
        appTranslations = getDataFromLocalStorage(`appTranslations-${languageKey}`)
        if (appTranslations) {
            resolve(appTranslations);
            return;
        }
        let key = languageKey;
        if(key){
            key = getDefaultLanguage(configuration);
            if(!key){
                key = "EN";
            }
        }else{
            key = "EN";
        }
        // let key  = languageKey;
        POST('ConfigAPI', '/translations', {'KEY': `WEB_APP_${key}`}).then(async (res) => {
            let translations = res && res.body && res.body.VALUE ? res.body.VALUE : {}
            if(!isEmpty(translations)){
                await storeDataInLocalStore(`appTranslations-${key}`, translations, null, "No expiry");
            }
            // return translations;
            resolve(translations)
        }).catch(() => {
            resolve({})

        });
    })

}
