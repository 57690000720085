import React, { Component, Fragment } from "react";
import MyInput from "../../../components/MyInput";
import Formsy from "formsy-react";
import { Link, withRouter } from "react-router-dom";
import Cache from "@aws-amplify/cache";
import Auth from "@aws-amplify/auth";
import {
  handleChange,
  enableButton,
  disableButton
} from "../../../helpers/StateHelper";
import LoaderButton from "../../../components/LoaderButton/LoaderButton";
import { withAppConfig } from "../../../context/ConfigContext";
import withUserSession from "../../../hoc/UserSession";
import MySocialLogin from "../../../components/MySocialLogin/MySocialLogin";
import { FACEBOOK, LOGIN } from "../../../constants/constants";
import config from "../../../config/config";
import ConfirmationMessage from "./ConfirmationMessage";
let loginSuccess = false;

class UniversalLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
      password: "",
      newPassword: "",
      inputValue: "Use Mobile",
      phone_number: "",
      loginError: "",
      canSubmit: false,
      alertMessage: ""
    };
    this.handleChange = handleChange.bind(this);
    this.enableButton = enableButton.bind(this);
    this.disableButton = disableButton.bind(this);
  }

  handleSubmit = async (event, formReset, invalidateForm) => {
    //writtenSeparateforSocialLogin
    this.setState({
      phone_number: event.phone_number ? event.phone_number : null,
      email: event.email ? event.email : null,
      password: event.password,
      isLoading: true,
      loginError: ""
    });
    Auth.signIn(this.state.phone_number ? '+' + this.state.phone_number : this.state.email.toLowerCase(), this.state.password)
      .then(async cognitoUser => {
        if (this.isMigrationFlowTesting) {
          //specialCaseWhenMigrationIsSuccessfull!
          Cache.clear();
          localStorage.clear(); //await Auth.signOut();
          this.isMigrationFlowTesting = false; //so that this condition not runs again
          this.applyMigrationFlow(
            event,
            formReset,
            invalidateForm,
            "USER_SRP_AUTH",
            true
          );
        } else {
          loginSuccess = true;

        }
        return cognitoUser;
      })
      .then(async user => {
        let passwordChallenge = false;
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          passwordChallenge = true;
          this.setState({
            forceSetNewPassword: true,
            cognitoUser: user,
            isLoading: false
          });
          this.props.showAlert(
            "showSuccessAlert",
            "Please Set Your New Password!"
          );
        }

        if (loginSuccess && !passwordChallenge) {
          this.props.updateAuthStatus(true, false);
          this.props.updateUserData();
          this.props.createSession();
        }
      })
      .catch(error => {
        if (this.isMigrationFlowTesting) {
          this.isMigrationFlowTesting = false;
          this.loginFailure = true;
        }
        let { message, setAlertMessage } = this.handleLoginExceptions(
          error,
          event,
          formReset,
          invalidateForm
        );
        if (!this.isMigrationFlowTesting) {
          this.setState({
            isLoading: false
          });
          if (message) {
            this.props.showAlert("showErrorAlert", message);
          }
        }
      });
  };

  handleLoginExceptions = (error, event, formReset, invalidateForm) => {
    let message = "Couldn't Login!";
    let setAlertMessage = true;
    if (error.code === "UserNotConfirmedException") {
      this.setState({
        userConfirmationModal: true
      });
      setAlertMessage = false;
      message = null; //dont show message
      //   this.props.history.push("/confirm", {
      //     email: this.state.email,
      //     password: this.state.password
      //   });
    } else if (error.code === "NotAuthorizedException") {
      if (error.message === "Incorrect username or password.") {
        if (invalidateForm) {
          //invalidateForm({ 'password': "Incorrect password." }) //invalidated passwrd only in this case
          message = "Incorrect password";
        } else {
          message = error.message;
        }
      } else {
        //confirm it later
        message = error.message;
      }
    } else if (error.code === "UserNotFoundException") {
      if (!this.isMigrationFlowTesting && !this.loginFailure) {
        this.isMigrationFlowTesting = true;
        this.applyMigrationFlow(
          event,
          formReset,
          invalidateForm,
          "USER_PASSWORD_AUTH"
        );
      } else {
        if (
          error.message ===
          "UserMigration failed with error User does not exist." ||
          error.message.includes("Exception migrating user")
        ) {
          message = "User Doesn't Exist!";
        } else {
          message = error.message;
        }
      }
    } else if (error.code === "PasswordResetRequiredException") {
      this.props.history.push(
        `/reset-password?email=${this.state.email}&code=`,
        { displayMessage: true }
      );
    }
    return { message, setAlertMessage };
  };

  applyMigrationFlow = (
    event,
    formReset,
    invalidateForm,
    authenticationFlowType,
    migrationSuccessfull = false
  ) => {
    Auth.authenticationFlowType = authenticationFlowType;
    Auth.configure({ authenticationFlowType: authenticationFlowType });
    this.handleSubmit(event, formReset, invalidateForm);
  };

  handleForceChangePassword = async (event, formReset, invalidateForm) => {
    this.setState({
      isLoading: true
    });
    if (this.state.newPassword !== "") {
      await this.state.cognitoUser.completeNewPasswordChallenge(
        this.state.newPassword,
        this.state.cognitoUser.challengeParam.requiredAttributes,
        {
          onSuccess: session => {
            this.props.updateAuthStatus(true, false);
            this.props.updateUserData();
            this.props.createSession();
          },
          onFailure: err => {
            this.setState({
              isLoading: false,
              forceSetNewPassword: false
            });
          }
        }
      );
    }
  };

  toggleConfirmationModal = () => {
    this.setState(prevState => {
      return {
        userConfirmationModal: !prevState.userConfirmationModal
      };
    });
  };
  changeLabelText = () => {
    this.setState({ email: '', phone_number: '' });
    this.setState({
      inputValue: this.state.inputValue === "Use Mobile" ? "Use Email" : "Use Mobile"
    })


  }
  render() {
    const { please_enter_valid_email_address, email_address, new_to, sign_in_with_facebook, sign_in_with_google, forgot_your_password = `Forgot Your Password ?`, password, new_password, sign_in = "Sign In", signing_in, sign_up_now } = this.props.appTranslations;

    return (
      <Fragment>
        {/* <UserConfirmation //confirmation modal
          showModal={this.state.userConfirmationModal}
          toggleModal={this.toggleConfirmationModal}
          email={this.state.email}
        /> */}
        {this.state.isLoading && (
          <span className="loader-spiral mn-white mn-transparent">
            <span className="spiral-inn" />
          </span>
        )}
        <Formsy
          onValidSubmit={
            this.state.forceSetNewPassword
              ? this.handleForceChangePassword
              : this.handleSubmit
          }
          onValid={this.enableButton}
          onInvalid={this.disableButton}
        >
          <h4 className="text-uppercase text-center mb-4">Sign In</h4>
          <ConfirmationMessage
            showCofirmation={this.state.userConfirmationModal}
            email={this.state.email ? this.state.email : this.state.phone_number}
          />
          <div className="text-right">
            <a onClick={this.changeLabelText} className="join-type">
              {this.state.inputValue}
            </a>
          </div>
          <MyInput
            id={this.state.inputValue !== "Use Mobile" ? "phone_number" : "email"}
            name={this.state.inputValue !== "Use Mobile" ? "phone_number" : "email"} type="text"
            placeholder={this.state.inputValue !== "Use Mobile" ? "Enter Phone Number" : email_address || "Email Address"}
            // validations="isEmail"
            // validationError={please_enter_valid_email_address || "Please enter valid email address"}
            required
            outerClassName="mb-3"
            handleChange={this.handleChange}
          />
          <MyInput
            id="password"
            name="password"
            type="password"
            placeholder={password || "Password"}
            validations={{
              minLength: 6,
              maxLength: 25
            }} //validationError="Password length should be greater than 6 characters." //commented because keeps showing on editing
            required
            outerClassName="mb-3"
            handleChange={this.handleChange}
          />
          {this.state.forceSetNewPassword && (
            <MyInput
              id="newPassword"
              name="newPassword"
              type="password"
              placeholder={new_password || "New Password"}
              validations="minLength: 6"
              required
              outerClassName="mb-3"
              handleChange={this.handleChange}
            />
          )}
          {config.applicationName !== "PebbleFlix" &&
            config.applicationName !== "Roadshow" && (
              <div className="form-group font-12 font-weight-normal">
                <Link className="link-white-50" to="/forgot-password">{forgot_your_password || "Forgot Your Password"}?</Link>
              </div>
            )}
          {/* <div className="form-group font-12 font-weight-normal">
                        <div className="form-check-inline">
                            <label className="form-check-label"><input type="checkbox" className="form-check-input align-middle" value="" />Remember Me</label>
                        </div>
                    </div> */}
          <div className="mb-4">
            <LoaderButton
              disabled={!this.state.canSubmit}
              type="submit"
              className="btn btn-primary btn-block text-uppercase"
              isLoading={this.state.isLoading}
              text="Sign In"
              loadingText={`${signing_in || "Signing in"} …`}
            />
          </div>
          <div className="mb-4 or-divider"></div>
          <div className="mb-4 d-flex justify-content-between font-12 text-white-50">
            <div>Continue with social media</div>
            <div><a href="#" className="link-white-50">Need Help?</a></div>
          </div>
          {this.props.showSocialButtons && (
            <Fragment>
              <div className="row gutter-10">
                <div className="col">
                  <MySocialLogin
                    loginText="Facebook"
                    className="btn-blue btn-lg"
                    action={LOGIN}
                    type={FACEBOOK}
                  />

                </div>
                <div className="col">
                  <MySocialLogin
                    loginText="Google"
                    className="btn-red btn-lg"
                    action={LOGIN}
                  />
                </div>
              </div>
            </Fragment>
          )}
        </Formsy>
      </Fragment>
    );
  }
}

export default withRouter(withAppConfig(withUserSession(UniversalLogin)));
