import React, { useState, useEffect, Fragment } from "react";
import _find from "lodash/find";
import Detail from "../Detail/Detail";
import { Switch } from "react-router-dom";
import Listing from "../Detail/Listing";
import Loader from "../../../components/Loader/Loader";
import { withAuth } from "../../../context/AuthContext";
import Tabs from "../Detail/components/Tabs";
import RelatedRooms from "../Related";
import MyRoute from "../../../components/MyRoute";
const TabsList = {
  movies: "movies",
  related: "related"
};

const DetailContainer = props => {
  const [isCPurchased, setPurchased] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [activeTab, toggleTab] = useState();

  useEffect(() => {
    initializeTab(props.location.pathname, props.match.url);
    initialize(props.match.params.id);
    return () => {};
  }, [props.mycollections]);

  const initialize = id => {
    if (props.isAuthenticated) {
      let result = _find(props.mycollections, ["CollectionID", id]);
      if (result) {
        setPurchased({
          isPurchased: true,
          ManifestType: result.ManifestType
        });
      }
    }
    setLoading(false);
  };

  const initializeTab = (location, url) => {
    // get location in param here! very imp!
    let initialTab = location.includes("related");
    if (initialTab) {
      initialTab = TabsList["related"];
    } else {
      if (!location.includes("movies")) {
        props.history.replace(`${url}/movies`);
      }
      initialTab = TabsList["movies"];
    }
    toggleTab(initialTab);
  };

  const onChangeTab = tab => {
    toggleTab(tab);
  };

  const onRelatedClick = selectedId => {
    setPurchased({}); //initialze state
    setLoading(true); //to re-render child forcefully
    setTimeout(() => {
      initializeTab(
        props.history.location.pathname,
        props.history.location.pathname
      ); // here both are same
      initialize(selectedId);
    }, 0);
  };

  return (
    <main className="mn-main">
      {isLoading ? (
        <Loader />
      ) : (
        <Fragment>
          <Detail isCPurchased_={isCPurchased} {...props} />
          <Tabs activeTab={activeTab} onChangeTab={onChangeTab} />
          <Switch>
            <MyRoute
              path={`${props.match.path}/movies`}
              component={Listing}
              props={{ isCPurchased_: isCPurchased.isPurchased, ...props }}
            />
            <MyRoute
              path={`${props.match.path}/related`}
              component={RelatedRooms}
              props={{
                isCPurchased_: isCPurchased.isPurchased,
                onRelatedClick: onRelatedClick,
                ...props
              }}
            />
          </Switch>
        </Fragment>
      )}
    </main>
  );
};

export default withAuth(DetailContainer);
