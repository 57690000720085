import React from "react";
const Rental = 0;

const Header = ({ tr, index }) => {
  return (
    <div className="d-flex w-100 justify-content-between">
      {index !== Rental ? (
        <div className="">
          <h4 className="text-white-70 mb-3">
            {tr.owned_movies || "Owned Movies"}
          </h4>
        </div>
      ) : (
        <div className="">
          <h4 className="text-white-70 mb-3">
            {tr.rented_movies || "Rented Movies"}
          </h4>
        </div>
      )}
    </div>
  );
};

export default Header;
