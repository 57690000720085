import { GET } from "../../api/";

export function getClientToken(callback) {
  return GET("OrdersAPI", "/getClientToken")
    .then(res => {
        callback(res.body.token);
      return res.body.token;
    })
    .catch(e => {
      let message = 'Failed!';
      if (e.errorMessage) {
        message = e.errorMessage[0];
      }
      callback(null, message);
      return;
    });
}
