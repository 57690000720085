import React from "react";
import Formsy from "formsy-react";
import { withAppConfig } from "../../../../context/ConfigContext";
import config from "../../../../config/config";
import MyInput from "../../../MyInput";

const VoucherContainer = props => {
  const { appTranslations: tr } = props;
  // const onRemove = value => {
  //   props.handleChange(null,'');
  // };
  return (
    <Formsy>
      <div className="form-group">
        <Description tr={tr} appSettings={props.appSettings} />
        <div className="input-group">
          <MyInput
            id="voucherCode"
            name="voucherCode"
            outerDiv={false}
            type="text"
            innerClassName="form-control-dark"
            outerClassName="mb-3"
            placeholder={tr.enter_reward_code}
            required
            handleChange={props.handleChange}
          />
          <CancelVoucher
            tr={tr}
            voucher={props.voucher}
            onRemove={props.handleChange}
          />
        </div>
      </div>
    </Formsy>
  );
};

const Description = props => {
  const { ApplicationName = config.applicationName } = props.appSettings;
  let headtext = props.tr.using_a_muvinow_reward_code || "Do you have a reward code?";
  // headtext = headtext.replace("MuviNow", ApplicationName);
  return <label className="text-white-50">{headtext}</label>;
};

const CancelVoucher = props => {
  if (!props.voucher) {
    return null;
  }

  const onClick = e => {
    e.preventDefault();
    props.onRemove(null, "");
  };

  return (
    <span className="input-group-append">
      <span className="input-group-btn" onClick={onClick}>
        <button className="btn btn-secondary">
          <i className="la la-times" />
        </button>
      </span>
    </span>
  );
};

export default withAppConfig(VoucherContainer);
