import config from "../config/config";
import _includes from "lodash/includes";
import { bitmovin_player_ui, bitmovin_player_ui_css } from "../constants/constants";
import { isEmpty } from "./GeneralHelpers";
import { getDRMType, getCurrentBrowser } from "./PlayerHelpers";
import source from "plyr/src/js/source";

export async function getPlayerConfig(movieStream, licenceUrl,subtitleTracks, title, backgroundPoster, playerStartTime = 0, enableCast = false, autoplay = true) {
    let streamType = await getDRMType();
    let sourceConfig = getSourceAndDrm(movieStream, licenceUrl, title, backgroundPoster, playerStartTime, streamType);
    sourceConfig["subtitleTracks"] = subtitleTracks;
    let styleConfig = getStyleConfig();
    let configuration;
    let browser = await getCurrentBrowser();
    if (_includes(browser, "safari")) {
        autoplay = false;
    }
    configuration = {
        key: config.playerConfiguration.bitmovinPlayerId,
        source: sourceConfig,
        style:styleConfig,
        location: {
            ui: bitmovin_player_ui,
            ui_css: bitmovin_player_ui_css,
        },
        cast: {
            enable: enableCast,
            application_id: config.playerConfiguration.chromecastReceiverID,
        },
        playback: {
            autoplay: autoplay,
        }
    };
    return configuration;
}

function getSourceAndDrm(movieStream, licenceUrl, title, backgroundPoster, playerStartTime, streamType) {
    let drm = {};
    let source = {
        poster: backgroundPoster,
        title: title,
        options: { startTime: playerStartTime },
    };
    //let tracks = streamData.length > 0 && streamData[0].tracks ? streamData[0].tracks : null;
    // if (tracks) {
    //     source["tracks"] = [{
    //         "file": tracks,
    //         "kind": 'thumbnails'
    //     }]
    // }
    switch (streamType) {
        case "widevine":
            if (licenceUrl) {
                drm["widevine"] = { "LA_URL": licenceUrl };
            }
            source["dash"] = movieStream;
            break;
        case "playready":
            if (licenceUrl) {
                drm["playready"] = { "LA_URL": licenceUrl };
            }
            source["dash"] = movieStream;
            break;
        case "fairplay":
            if (licenceUrl) {
                drm["fairplay"] = {
                    certificateURL: config.playerConfiguration.certificateURL,
                    LA_URL: licenceUrl,
                    prepareMessage: function (event, session) {
                        return event.message;
                    },
                    prepareContentId: function (contentId) {
                        var link = document.createElement('a');
                        link.href = contentId;
                        return link.hostname;
                    },
                    prepareLicense: function (license) {
                        return new Uint8Array(license);
                    },
                    headers:{
                        'Content-Type': 'application/octet-stream'
                    },
                    useUint16InitData: true,
                    licenseResponseType: 'arraybuffer'
                };
            }
            if (_includes(movieStream, "mpd")) {
                movieStream = movieStream.replace("mpd", "m3u8");
            }
            source["hls"] = movieStream;
            break;
        default:
            if (licenceUrl) {
                drm["widevine"] = { "LA_URL": licenceUrl };
            }
            source["dash"] = movieStream;
            break;
    }
    if (!isEmpty(drm)) {
        source["drm"] = drm;
    }


    return source;
}

function getStyleConfig() {
    return {
        width: '100%',
        aspectratio: '4:3',
        ux: true
    }
}
