import { POST } from "../../api"

export default function ResendPassword(callback, params = {}) {
  return POST("UserAPI", "/resend-password", { Email: params.email })
    .then(res => {
      callback(res);
      return res;
    })
    .catch(err => {
      callback(null, err);
      //return res;
    });
}
