import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { unregister } from "./registerServiceWorker"; // import registerServiceWorker from "./registerServiceWorker";
import { FirebaseProvider } from "./context/FirebaseContext";
import { ConfigProvider } from "./context/ConfigContext";
import { PaymentCardProvider } from "./context/PaymentCardContext";
import { AuthProvider } from "./context/AuthContext";
import { initializeAWS } from "./aws";
import App from "./App";
import "./style";

initializeAWS();

ReactDOM.render(
  <Router>
    <ConfigProvider>
      <AuthProvider>
        <FirebaseProvider>
          <PaymentCardProvider>
            <App />
          </PaymentCardProvider>
        </FirebaseProvider>
      </AuthProvider>
    </ConfigProvider>
  </Router>,
  document.getElementById("root")
);
unregister(); // registerServiceWorker();
