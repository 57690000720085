import { GET } from "../../api/";
import {
  getDataFromLocalStorage,
  storeDataInLocalStore
} from "../../helpers/GeneralHelpers";
import _concat from "lodash/concat";

export async function GetMySubscriptions(
  callback,
  { UserId, bypassCache = false }
) {
  let cache = getDataFromLocalStorage("mysubscriptions", UserId);
  if (!bypassCache) {
    if (cache) {
      callback(cache);
      return cache;
    }
  }
  let response = await fetchAllSubscriptions();
  if (response) {
    storeDataInLocalStore("mysubscriptions", response, UserId);
    callback(response);
  } else {
    callback(null, "Failed");
  }
  return;
}

function fetchAllSubscriptions(LastEvaluatedKey = ``, data = []) {
  let path = `/my-subscriptions?Limit=${12}&ExclusiveStartKey=${LastEvaluatedKey}`;
  return GET("OrdersAPI", path)
    .then(res => {
      if (res.body && res.body.Items) {
        let result = _concat(data, res.body.Items);
        if (res.body.LastEvaluatedKey) {
          return fetchAllSubscriptions(res.body.LastEvaluatedKey, result);
        } else {
          return result;
        }
      }
    })
    .catch(e => {
      return null;
    });
}
