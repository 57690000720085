import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';
import _uniq from "lodash/uniq";
import config from "../../../config/config";
import ISO6391 from 'iso-639-1';
class HeroSectionMeta extends Component {
    movieComingSoon() {
        return (
            this.props.comingSoonStatus ?
                <div className="mn-movie-success mb-3"><i className="la la-info-circle mr-2"></i><span className="text-white">{this.props.comingSoonStatus}</span></div>
                : null
        )
    }

    getdisplayVideoQualities = () => {
        if (this.props.purchaseTierQuality !== "") {
            return this.props.purchaseTierQuality
        }
        let quality = [];
        this.props.movieData.BuyParams && this.props.movieData.BuyParams.length > 0 &&
            this.props.movieData.BuyParams.forEach((obj) => {
                obj.DisplayName && quality.push(obj.DisplayName)
            });
        this.props.movieData.RentParams && this.props.movieData.RentParams.length > 0 &&
            this.props.movieData.RentParams.forEach((obj) => {
                obj.DisplayName && quality.push(obj.DisplayName)
            });
        return _uniq(quality);
    }

    videoQualities() {
        let videoQualities = this.getdisplayVideoQualities();
        return (
            (this.props.movieData.isAvailableForRent || this.props.movieData.isAvailableForBuy) &&
            <div className="mb-3">
                <span className="align-middle mr-1 font-weight-bold">Video Quality : </span>
                {this.props.purchaseTierQuality !== "" ?
                    <span className="badge px-2 py-1 mx-1 badge-light" >{this.props.purchaseTierQuality}</span>
                    :
                    (videoQualities && videoQualities.length > 0) &&
                    videoQualities.map((video, index) => {
                        return (<span key={index} className="badge px-2 py-1 mx-1 badge-light" >{video}</span>)
                    })
                }
            </div>
        )
    }

    movieGenre() {
        return (
            this.props.movieData.Genre && this.props.checkIsValid(this.props.movieData.Genre) &&
            <div className="mb-3">
                <span className="font-weight-bold mr-2">Genre : </span>
                <span className="text-white-50">
                    {this.props.movieData.Genre.map((gen, index) => {
                        if (gen) {
                            return gen.charAt(0).toUpperCase() + gen.slice(1) + `${index + 1 !== this.props.movieData.Genre.length ? ", " : ``}`;
                        }
                    })}
                </span>
            </div>
        )
    }

    getValidMetaDataArray = () => {
        let metaDataArray = [];
        const data = this.props.movieData ? { ...this.props.movieData } : {};
        if (data.Meta && this.props.checkIsValid(data.Meta.Actors)) {
            metaDataArray.push({ Data: data.Meta.Actors, Title: "Starring" })
        }
        if (data.Meta && this.props.checkIsValid(data.Meta.Directors)) {
            metaDataArray.push({ Data: data.Meta.Directors, Title: "Directors" })
        }
        if (data.Meta && this.props.checkIsValid(data.Meta.Writers)) {
            metaDataArray.push({ Data: data.Meta.Writers, Title: "Writers" })
        }
        if (this.props.checkIsValid(data.Studio)) {
            metaDataArray.push({ Data: data.Studio, Title: "Studio" })
        }
        if (!config.customSettings.isStuffpixEnabled) {
            let langNames = []
            if (this.props.checkIsValid(data.Languages)) {
                 data.Languages.forEach(element => {
                    langNames.push(ISO6391.getName(element))
                 });
                metaDataArray.push({ Data: langNames, Title: "Languages" })
            }
        }
        return metaDataArray;
    }

    render() {
        let validMeta = this.getValidMetaDataArray();
        return (
            <Fragment>
                 {!config.customSettings.isStuffpixEnabled && this.videoQualities()}
                {this.movieGenre()}
                {validMeta && validMeta.length > 0 &&
                    validMeta.map((meta, index) => {
                        return (
                            <div className="mb-3" key={index}>
                                <span className="font-weight-bold mr-2">{meta.Title} : </span>
                                {meta.Data.join(", ")}
                            </div>
                        )
                    })
                }
                {this.props.checkIsValid(this.props.movieData.Tags) &&
                    <div className="">
                        <span className="align-middle mr-1 font-weight-bold">Tags : </span>
                        {this.props.movieData.Tags.map((tag, index) => {
                            return (<span key={index} className="badge px-2 py-1 mx-1 badge-secondary">{tag}</span>)
                        })
                        }
                    </div>
                }
                {this.movieComingSoon()}
            </Fragment>
        );
    }
}

HeroSectionMeta.defaultProps = {
    comingSoonStatus: null,
}

HeroSectionMeta.propTypes = {
    comingSoonStatus: PropTypes.string,
    purchaseTierQuality: PropTypes.string,
    movieData: PropTypes.object.isRequired,
    checkIsValid: PropTypes.func.isRequired
};
export default HeroSectionMeta;