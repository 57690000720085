import React, { Component } from "react";
import PropTypes from 'prop-types';
import MovieCard from "../../../../components/MovieCard/MovieCard";
import DataNotFound from "../../../../components/DataNotFound/DataNotFound";

class OfferMovies extends Component {

    render() {
        const { offerMovies, isMovieLockDecided, isOfferAvailed } = this.props;
        return (
            <section className="mn-movies pt-4 border-bottom border-dark">
                <div className="container">
                    <div className="text-center text-white-70 mb-4">
                        Select one of the {(offerMovies && offerMovies.length > 0) ? offerMovies.length : "following"} movies listed below, then follow the instructions to start watching
                </div>
                    <div className="row gutter-10 mn-rewards-grid justify-content-center">
                        {(offerMovies && offerMovies.length > 0) ?
                            offerMovies.map((movie, index) => {
                                return (
                                    <MovieCard
                                        {...this.props}
                                        key={index}
                                        movie={movie}
                                        showComingSoonTag={movie.isComingSoon_}//{(!movie.isAvailableForRent && !movie.isAvailableForBuy)}
                                        className={(!isOfferAvailed) || (isOfferAvailed && movie.isPurchased_) ? "mb-4" : " faded mb-4"}
                                        showHoverDetailandRent={true} //{isMovieLockDecided}
                                        showSpinner={isMovieLockDecided ? false : true}
                                        //showHoverPlayIcon={false}
                                        movieClick={this.props.handleMovieClick}
                                        handleRentMovieClick={this.props.handleRentMovieClick}
                                    />
                                )
                            })
                            :
                            <DataNotFound
                                text="No offer Movies"
                            />
                        }
                    </div>
                </div>
            </section >
        )
    }
}
export default OfferMovies;

OfferMovies.propTypes = {
    offerMovies: PropTypes.array,
    isMovieLockDecided: PropTypes.bool,
    isOfferAvailed: PropTypes.bool,
    handleMovieClick: PropTypes.func,
    handleRentMovieClick: PropTypes.func,
};