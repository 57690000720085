import React from 'react'
import Detail from '../../components/Detail';
import TotalBill from '../../components/TotalBill';
import DateHeading from '../../components/DateHeading';
import Voucher from '../../components/Voucher';
import { checkIsMovieSubscribed } from '../../../../helpers/MoviesHelper'

const Listing = ({ data, ...props }) => {
    return (
        Object.keys(data).map((item, index) => {
            return (
                <div className="mn-purchase-list" key={index}>
                    <div className="mn-purchase-item mb-4">
                        <DateHeading date={item} />
                        <div className="list-group">
                            {
                                data[item].map((room, index) => {
                                    let detail = room.ScreeningRoom;
                                    let PosterUrl = detail.PosterUrl ? detail.PosterUrl : "";//use someplaceholder
                                    if (!detail.Status || detail.Status !== "inactive") {
                                        return (
                                            <div key={index} className="list-group-item list-group-item-dark flex-column align-items-start p-3">
                                                <div className="media font-12">
                                                    <div className="align-self-start mr-3 mn-purchase-thumb">
                                                        <div className="img-bg img-1x15" style={{ backgroundImage: `url(${PosterUrl})` }}></div>
                                                    </div>
                                                    <Detail appTranslations={props.appTranslations} {...room} />
                                                </div>
                                                <Voucher appTranslations={props.appTranslations} {...room} />
                                                <TotalBill appTranslations={props.appTranslations} {...room} />
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            )
        })
    )


}


export default Listing;