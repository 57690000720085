import React, { Component } from "react";
import PropTypes from 'prop-types';
import LoaderButton from "../../../../components/LoaderButton/LoaderButton";
import config from "../../../../config/config";
import { REWARD } from "../../../../constants/constants";

class Header extends Component {

    handleSignUpClick = () => {
        this.props.history.push(`/p/${REWARD}`)
    }

    render() {
        const { ApplicationName = config.applicationName } = this.props.appSettings;
        return (
            <section className="mn-page-head py-4 bg-black">
                <div className="container">
                    <div className="text-center text-white-50">
                        <div className="mb-3"><img src="https://i.imgur.com/x4PcLfE.png" width="150" /></div>
                        <div className="mb-2">
                            <span className="mr-1"><img src="https://i.imgur.com/x4PcLfE.png" width="60" /></span>
                            Members can enjoy one of the following blockbuster movies on a 48 hour rental.
                        </div>
                        {this.props.isAuthenticated ?
                            this.props.currentUser &&
                            <div className="">
                                Logged in as <span className="ml-2 text-success">{this.props.currentUser.email}</span>{/*<a href="#" className="ml-2">Not You?</a>*/}
                            </div>
                            :
                            <div className="">
                                Join {ApplicationName} to redeem your voucher
                                <LoaderButton
                                    className="btn btn-sm btn-primary btn-medium ml-2"
                                    text="Join"
                                    onClick={this.handleSignUpClick}
                                />
                            </div>}
                    </div>
                </div>
            </section>
        )
    }
}
export default Header;
Header.defaultProps = {
    appSettings: {},
}

Header.propTypes = {
    appSettings: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    currentUser: PropTypes.object,
    history: PropTypes.object.isRequired
};