import React, { Component } from 'react';
import _find from "lodash/find";
import _orderBy from "lodash/orderBy";
import { disableAlert } from "../helpers/StateHelper";
import { GETCARDSLIST, STORED_PAYMENT_CARDS, SAVECARDSLIST } from '../constants/constants';
import { getApiData, updateApiWithResponse, deleteApiRequest } from '../helpers/APIHelpers/MoviesAPI';
import { storeDataInLocalStore, getCurrentUserId, getDataFromLocalStorage, removeItemFromStore, isEmpty } from '../helpers/GeneralHelpers';
import API from '@aws-amplify/api';
import { renderNotificationMessage } from '../helpers/ViewHelpers';
const CARD_DELETED_SUCCESSFULLY = "Your Card is Deleted Successfully";
const CARD_DELETED_FAILED = "Sorry! Card couldn't be deleted!";
const PaymentCardContext = React.createContext(null);

class PaymentCardProvider extends Component {
    isCompMounted = true;
    constructor(props) {
        super(props);
        this.getCards = getApiData.bind(this);
        this.saveCard = updateApiWithResponse.bind(this);
        this.deleteCard = deleteApiRequest.bind(this);
        this.disableAlert = disableAlert.bind(this);
        this.renderNotificationMessage = renderNotificationMessage.bind(this);

        this.state = {
            cardsList: [],
            isLoadingCards: true,
            errorLoadingCards: "",
            isSavingCard: false,
            showSuccessAlert: false,
            showErrorAlert: false,
            alertMessage: "",
            errorSavingCard: "",
            isDeletingCard: {},
            errorDeletingCard: "",
            userId: "",
            defaultCardID: "formpanel",
            isMakingDefault: {}, //"id"
            errorMakingDefault: {},
            savePaymentCard: (params) => {
                this.savePaymentCard(params);
            },
            deletePaymentCard: (params) => {
                this.deletePaymentCard(params);
            },
            makeCardDefault: (params) => {
                this.makeCardDefault(params)
            },
            getCardsList: (params) => {
                this.getCardsList(params)
            }
        }
    }

    componentDidMount() {
        if (this.props.isAuthenticated) {
            this.getCardsList();
        }
    }

    getCardsList = async (forceFetch = false) => {
        let userId = this.state.userId;
        if (!this.state.userId) {
            userId = await getCurrentUserId();
        }
        this.setState({
            userId: userId
        });
        let cardsList = getDataFromLocalStorage(STORED_PAYMENT_CARDS, userId);
        if (!cardsList || forceFetch) {
            cardsList = await this.getCards('OrdersAPI', '/cards', this.state.cardsList, "isLoadingCards", "errorLoadingCards", "cardsList", GETCARDSLIST);
            if (cardsList && cardsList.length > 0) {
                storeDataInLocalStore(STORED_PAYMENT_CARDS, cardsList, userId)
            }
        }
        cardsList = _orderBy(cardsList, ['Default'], ['desc']);
        let defaultCard = _find(cardsList, ["Default", true]);
        if (defaultCard) {
            this.setState({
                defaultCardID: defaultCard.ID
            })
        }
        this.setState({
            cardsList: cardsList,
            isLoadingCards: false
        })
    }

    savePaymentCard = async (params) => {
        let userId = this.state.userId;
        if (!this.state.userId) {
            userId = await getCurrentUserId();
            this.setState({
                userId: userId
            })
        }
        this.setState({
            isSavingCard: true
        });
        this.saveCard("OrdersAPI", "/cards/", {PaymentNonce: params['body']}, this.state.cardsList, "showSuccessAlert", "showErrorAlert", "alertMessage", "Card Saved Successfully!", "", SAVECARDSLIST, "isSavingCard", "errorSavingCard").then((response) => {
            if (response && !isEmpty(response)) {
                this.getCardsList(true);
            }
        });
    }

    deletePaymentCard = async (cardId) => {
        let userId = this.state.userId;
        if (!this.state.userId) {
            userId = await getCurrentUserId();
            this.setState({
                userId: userId
            })
        }
        this.setState((prevState) => {
            return {
                isDeletingCard: {
                    ...prevState.isDeletingCard,
                    [cardId]: true
                },
            }
        });
        this.deleteCard("OrdersAPI", `/cards/${cardId}`, null, null, "showSuccessAlert", "showErrorAlert", "alertMessage", CARD_DELETED_SUCCESSFULLY, CARD_DELETED_FAILED, "DELETECREDITCARD", "").then((response) => {
            if (response && !isEmpty(response)) {
                this.getCardsList(true);
            }
            this.setState((prevState) => {
                return {
                    isDeletingCard: {
                        ...prevState.isDeletingCard,
                        [cardId]: false
                    },
                }
            });
        });
    }

    makeCardDefault = async (cardId) => {
        let userId = this.state.userId;
        if (!this.state.userId) {
            userId = await getCurrentUserId();
            this.setState({
                userId: userId
            })
        }
        this.setState((prevState) => {
            return {
                isMakingDefault: {
                    ...prevState.isMakingDefault,
                    [cardId]: true
                },
            }
        });
        let init = { headers: { 'Content-Type': 'application/json' } };
        API.put("OrdersAPI", `/cards/default/${cardId}`, init).then((res) => {
            if (res.statusCode === 200 || res.StatusCode === 200) {
                removeItemFromStore(STORED_PAYMENT_CARDS, userId);
                this.getCardsList();
                this.setNotification("showSuccessAlert", "Card set to default Successfully!")//tr.card_set_to_default_successfully
                this.setState((prevState) => {
                    return {
                        isMakingDefault: {
                            ...prevState.isMakingDefault,
                            [cardId]: false
                        },
                    }
                });
            }
        }).catch(() => {
            this.setNotification("showErrorAlert", " Sorry! Couldn't make card Default") // //tr.sorry_couldnt_make_card_default 
            this.setState((prevState) => {
                return {
                    isMakingDefault: {
                        ...prevState.isMakingDefault,
                        [cardId]: false
                    },
                }
            });
        })
    }

    setNotification = (alertType, alertMessage) => {
        this.setState({
            alertMessage: alertMessage,
            [alertType]: true
        })
        this.disableAlert(alertType, alertMessage)
    }

    render() {
        return (
            <PaymentCardContext.Provider value={this.state} >
                {this.renderNotificationMessage()}
                {this.props.children}
            </PaymentCardContext.Provider >)
    }
}

const withPaymentCard = Component => props => {
    const PaymentConsumer = (
        <PaymentCardContext.Consumer>
            {(payment) => <Component  {...props} {...payment} />}
        </PaymentCardContext.Consumer>
    );
    return PaymentConsumer;
}
export { PaymentCardContext, PaymentCardProvider, withPaymentCard };