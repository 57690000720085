import React from "react";

const Header = () => {
  return (
    <section className="">
      <div className="mn-page-head py-4 bg-black">
        <div className="container-fluid">
          <div className="d-md-flex">
            <div className="text-white-50 font-weight-bold mr-3 mb-2 mb-md-0">
              All Bundles :
            </div>
            <div className="mn-points">
              <span>
                  Rent any great bundle of movies of your choice in low price
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
