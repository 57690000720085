import React, { Component } from "react";
import PropTypes from 'prop-types';
import { checkIsMovieExpired } from "../../../helpers/MoviesHelper";
import RenderScreen from "../../../components/RenderScreen/RenderScreen";
import { convertSecToDuration, isEmpty } from "../../../helpers/GeneralHelpers";
import MovieCard from "../../../components/MovieCard/MovieCard";
import { PURCHASE_HISTORY, ExpiringStripTime } from "../../../constants/constants";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";
import { withAppConfig } from "../../../context/ConfigContext";
const Rental = 0;

class PurchaseHistory extends Component {
    expiringTimeStrip = ExpiringStripTime;

    getRentDuration = (movie) => {
        let duration = checkIsMovieExpired(this.props.moment, movie, this.props.SERVERTIME);
        this.expiringTimeStrip = duration;
        if (duration === "New") {
            return "New"
        } else if (duration <= 0) {
            return "Expired"
        } else {
            return `Expiring: ${convertSecToDuration(duration)}`;
        }
    }
    renderPurchasedListView = () => {
        const { appTranslations : tr } = this.props;
        return (
            this.props.myPurchaseList.map((section, index) => {
                let nonExpiredCount = 0 ;
                return (
                    <section key={index} className={index === Rental ? "mn-movies pt-5" : "mn-movies pb-5"}>
                        <div className="container-fluid">
                            <div className="d-flex w-100 justify-content-between">
                                {index !== Rental ?
                                    !isEmpty(section) ?
                                        <div className=""><h4 className="text-white-70 mb-3">{tr.owned_movies}</h4></div>
                                        :
                                        null
                                    :
                                    <div className=""><h4 className="text-white-70 mb-3">{tr.rented_movies}</h4></div>
                                }
                            </div>
                            {!isEmpty(section) ?
                                <div>
                                <div className="row gutter-10 mn-movies-grid">
                                    {section.map((movie) => {
                                        let rentDuration = (index === Rental && !this.props.purchaseTagsAlreadyDecided) && this.getRentDuration(movie);
                                        if (!movie["HideExpiredRental_"] && movie['Type'] !== 'Child') { //Child => for collection
                                            nonExpiredCount++;
                                            return (
                                                <MovieCard
                                                    {...this.props}
                                                    showCardHoverIcons={this.props.showPurchaseHistoryTags}
                                                    key={movie.ID ? movie.ID : index}
                                                    movie={movie}
                                                    type={PURCHASE_HISTORY}
                                                    movieClick={this.props.handleMovieClick}
                                                    className="mb-5"
                                                    expiringTimeStrip={!this.props.purchaseTagsAlreadyDecided ? this.expiringTimeStrip : ""}
                                                    isRented={(index === Rental && !this.props.purchaseTagsAlreadyDecided) ? true : false}
                                                    rentedDuration={(index === Rental && !this.props.purchaseTagsAlreadyDecided) ? rentDuration : ""} //IP is just for ui test, change it later
                                                />
                                            )
                                        }
                                    })}
                                </div>
                                    {nonExpiredCount === 0 &&
                                    <DataNotFound
                                        height="300px"
                                        text={index === Rental ? tr.no_rented_movies : tr.no_owned_movies}
                                    />}
                                </div>
                                :
                                index !== Rental ?
                                    null
                                    :
                                    <DataNotFound
                                        height="300px"
                                        text={index === Rental ? tr.no_rented_movies : tr.no_owned_movies}
                                    />
                            }
                        </div>
                    </section>
                )
            })
        )
    }

    render() {
        return (
            <RenderScreen
                isLoading={this.props.isLoadingMyPurchasedMovies}
                isError={this.props.errorMyPurchasedMovies}
            >
                {this.renderPurchasedListView()}
            </RenderScreen>
        );
    }
}
export default withAppConfig(PurchaseHistory);
PurchaseHistory.propTypes = {
    isLoadingMyPurchasedMovies: PropTypes.bool.isRequired,
    errorMyPurchasedMovies: PropTypes.string.isRequired,
    SERVERTIME: PropTypes.string.isRequired,
    myPurchaseList: PropTypes.array.isRequired,
    showPurchaseHistoryTags: PropTypes.bool.isRequired,
    purchaseTagsAlreadyDecided: PropTypes.bool.isRequired,
    handleMovieClick: PropTypes.func.isRequired,
    handleWatchMovieClick: PropTypes.func.isRequired,
    moment: PropTypes.func.isRequired
};