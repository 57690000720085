export const getInputFields = (tr) => [
    {
        Name: "code",
        Type: "text",
        Placeholder: tr.verification_code,
        Validations: "isNumeric",
        ValidationError: tr.verification_code_should_be_numeric || "Verification code should be numeric."
    },
    {
        Name: "newPassword",
        Type: "password",
        Placeholder: tr.password,
        Validations: {
            minLength: 6,
            maxLength: 25
        },
        ValidationErrors: {
            minLength: tr.password_length_should_be_6_to_25_charac,
            maxLength: tr.password_length_should_be_6_to_25_charac
        }
    },
    {
        Name: "confirmNewPassword",
        Type: "password",
        Placeholder: tr.confirm_password,
        Validations: "equalsField:newPassword",
        ValidationError: tr.new_password_and_confirm_password_does_n
    }
];