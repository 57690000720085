import Auth from "@aws-amplify/auth";

export default function resendConfirmationCode(callback, { email }) {
  return Auth.resendSignUp(email.toLowerCase())
    .then(res => {
      callback(res);
      return res;
    })
    .catch(err => {
      let message = "Code couldn't be sent!";
      //if (err.code === "LimitExceededException") {
        if(err.message){
          message = err.message;
        }
      //}
      callback(null, message);
      return message;
    });
}
