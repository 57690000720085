import React from "react";
import PropTypes from "prop-types";

const Wrapper = ({ PosterUrl, Tag, onClick, classname, children }) => {
  return (
    <div className="col-sm-4" onClick={onClick}>
      <a className={`mn-room ${classname}`}>
       {Tag ?  <div className="is-room-subscribed"><i className="las la-check-circle mr-2 font-16"></i> Subscribed</div> : null}
        <div className="media">
          <div className="mn-figure mn-room-figure">
            <div className="mn-thumb rounded-circle img-bg img-1x1"
              style={{ backgroundImage: `url(${PosterUrl})` }}
            />
          </div>
          <div className="media-body pl-3">
            {children}
          </div>
        </div>
      </a>
    </div>
  );
};

export default Wrapper;
Wrapper.propTypes = {
  PosterUrl: PropTypes.string//.isRequired
};
