import React from "react";
import { withAppConfig } from "../../context/ConfigContext";
const NotFound = ({ appTranslations : tr }) =>
    <main className="mn-main">
        <section className="mn-login-section py-5">
            <div className="container">
                <div className="mn-login-content">
                    <div className="mn-login-body">
                        <h3>{tr.sorry_page_not_found || "Sorry, page not found!"}</h3>
                    </div>
                </div>
            </div>
        </section>
    </main>
export default withAppConfig(NotFound);