import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withAppConfig } from "../../context/ConfigContext";
import DowloadAppMessage from "../../components/DowloadAppMessage/DowloadAppMessage";
import { HOME } from "../../constants/routes";
import isEmpty from "lodash/isEmpty";
import Loader from "../../components/Loader/Loader";
import { getOSName } from "../../helpers/PlayerHelpers";

class UnSupportedBrowser extends Component {
    isCompMounted = true;
    constructor(props) {
        super(props);
        this.state = {
            currentDeviceOS: ''
        }
    }

    async componentDidMount() {
        if (this.props.location && this.props.location.state && this.props.location.state.data) {
            let osType = await getOSName();
            this.setState({
                currentDeviceOS: osType
            })
        } else {
            this.props.history.push(HOME)
        }
    }

    componentWillUnmount() {
        this.isCompMounted = false;
    }

    render() {
        const data = this.props.location && this.props.location.state && this.props.location.state.data ? this.props.location.state.data : {};
        return (
            <main className="mn-main">
                {(isEmpty(data) || !this.state.currentDeviceOS) ?
                    <Loader />
                    :
                    <DowloadAppMessage
                        data={data}
                        downloadAppType={this.state.currentDeviceOS}
                    />
                }
            </main>
        )
    }
}
export default withAppConfig(UnSupportedBrowser);
UnSupportedBrowser.defaultProps = {
    appSettings: {},
}

UnSupportedBrowser.propTypes = {
    appSettings: PropTypes.object
};
