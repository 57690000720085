import React from 'react'
import { useState } from 'react';

import ScreeningRoomHeader from "./ScreeningRoomHeader";
import SubscribeModal from '../../../../components/SubscribeModal/SubscribeModal';

const Wrapper = ({ PreviewImage, PosterUrl, RoomData, children, history }, props) => {
    // const {
    //     buttonLabel,
    //     className = "modal-dark",
    // } = props;
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);


    // const [activeTab, setActiveTab] = useState('1');


    return (
        <section className="mn-room-detail img-bg" style={{ backgroundImage: `url(${PreviewImage})` }}>
            <ScreeningRoomHeader RoomData={RoomData} toggle={toggle} />

            <SubscribeModal
             showSubscribeModal={modal}
             dismissPurchaseModal={toggle}
             handleBackDropClick={toggle}
             data={RoomData}
            />
        </section>
    )


}

export default Wrapper;
