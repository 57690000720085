import React from "react";
import PropTypes from 'prop-types';
import visaLogo from "../../../assets/images/svg/payment/visa-logo.svg";
import mastercardLogo from "../../../assets/images/svg/payment/mastercard-logo.svg";
import amexLogo from "../../../assets/images/svg/payment/amex-logo.svg";
import config from "../../../config/config";

const AcceptedCreditCards = () => {
    return (
        <div className="d-flex justify-content-center">
            <div className="pay-types-item mx-2">
                <img src={visaLogo} title="Visa" />
            </div>
            <div className="pay-types-item mx-2">
                <img src={mastercardLogo} title="Mastercard" />
            </div>
            {!config.customSettings.isStuffpixEnabled &&
                <div className="pay-types-item mx-1">
                    <img src={amexLogo} title="American Express" />
                </div>
            }
            { /*<div className="col-md-12">
                <div className="row gutter-2 justify-content-center">
                    <div className="col-2 custom-col-8">
                        
                    </div>
                    <div className="col-2 custom-col-8">
                        
                    </div>
                    
                     showAllCards &&
                        <Fragment>
                            <div className="col-2 custom-col-8">
                                <div className="pay-types-item">
                                    <img src={maestroLogo} title="Maestro" />
                                </div>
                            </div>
                            <div className="col-2 custom-col-8">
                                <div className="pay-types-item">
                                    <img src={jcbLogo} title="JCB" />
                                </div>
                            </div>
                            <div className="col-2 custom-col-8">
                                <div className="pay-types-item">
                                    <img src={discoverLogo} title="Discover" />
                                </div>
                            </div>
                            <div className="col-2 custom-col-8">
                                <div className="pay-types-item">
                                    <img src={amexLogo} title="Amex" />
                                </div>
                            </div>
                            <div className="col-2 custom-col-8">
                                <div className="pay-types-item">
                                    <img src={dinersclubLogo} title="Diners" />
                                </div>
                            </div>
                            <div className="col-2 custom-col-8">
                                <div className="pay-types-item">
                                    <img src={unionpayLogo} title="UnionPay" />
                                </div>
                            </div>
                        </Fragment>
                    
                </div>
            </div>*/}
        </div>
    )
}

AcceptedCreditCards.propTypes = {
    //showAllCards: PropTypes.bool
};
export default AcceptedCreditCards;