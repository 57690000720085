import React, { Fragment } from "react";
import { Modal } from "reactstrap";
import { SUCCESS, ERROR, PRIVACY_POLICY, TERMS_CONDITIONS, ABOUT_US, CONTACT_US, REWARD, HOW_TO_WATCH } from "../constants/constants";
import { showAlert } from './GeneralHelpers';
import { Link, NavLink } from "react-router-dom"; //glide
import MyInput from "../components/MyInput";
import Formsy from "formsy-react";
import LoaderButton from "../components/LoaderButton/LoaderButton";
import { STOREDVOUCHERS } from "../constants/routes";

function getPageTitle(type) {
    switch (type) {
        case PRIVACY_POLICY:
            return "Privacy Policy";
        case TERMS_CONDITIONS:
            return "Terms Of Use";
        case CONTACT_US:
            return "Contact Us";
        case ABOUT_US:
            return "About Us";
        case HOW_TO_WATCH:
            return "How To Watch";
        default:
            return "";
    }
}

export function renderContentLayout(type, data) {
    return (
        <main className="mn-main">
            <section className="mn-page-head py-4">
                <div className="container">
                {type &&<h3 className="mb-0 text-center text-uppercase">{getPageTitle(type)}</h3>}
                </div>
            </section>
            <section className="mn-notifications py-5">
                <div className="container">
                    {/* {this.state.lastUpdatedTime !== "" && <small><em>Last update: {jsDateToYYYYMMDD(this.state.lastUpdatedTime)}</em></small>} changed its style */}
                    <div className="text-body py-3" dangerouslySetInnerHTML={{ __html: data }}>
                    </div>
                </div>
            </section>
        </main>
    )
}

export function renderNotificationMessage() {
    if (this.state.showSuccessAlert) {
        return (
            showAlert(SUCCESS, this.state.alertMessage)
        )
    }
    if (this.state.showWarningAlert) {
        return (
            showAlert(ERROR, this.state.alertMessage, true) //isWarning = true
        )
    } else if (this.state.showErrorAlert) {
        return (
            showAlert(ERROR, this.state.alertMessage)
        )
    }
}

export function renderPinForm(tr, title = "Enter Your Pin", watchMovie = false, field1 = "pinDigit1", field2 = "pinDigit2", field3 = "pinDigit3", field4 = "pinDigit4") {
    let applyStyle = watchMovie ? { width: "250px" } : {};
    return (
        <Fragment>
            {!watchMovie && <div className="form-group">{title}</div>}
            <Formsy >
                <div className={`form-group ${watchMovie ? `mb-4` : ``} `}>
                    <div className={`input-group ${!watchMovie ? `input-medium` : ``}`} style={applyStyle}>
                        <MyInput
                            myRef={input => { this.inputs[field1] = input; }}
                            id={field1}
                            name={field1}
                            type="text"
                            maxLength="1"
                            innerClassName="form-control-dark text-center"
                            placeholder="&bull;"
                            validations="isNumeric,isLength:1"
                            validationError={tr.enter_valid_number || "Enter Valid Number"}
                            required
                            outerDiv={false}
                            handleChange={this.handleChange}
                            returnKeyType={"first"}
                        />
                        <MyInput
                            myRef={input => { this.inputs[field2] = input; }}
                            id={field2}
                            name={field2}
                            type="text"
                            maxLength="1"
                            innerClassName="form-control-dark text-center"
                            placeholder="&bull;"
                            required
                            validations="isNumeric,isLength:1"
                            validationError={tr.enter_valid_number || "Enter Valid Number"}
                            outerDiv={false}
                            handleChange={this.handleChange}
                            returnKeyType={"next"}
                        />
                        <MyInput
                            myRef={input => { this.inputs[field3] = input; }}
                            id={field3}
                            name={field3}
                            type="text"
                            maxLength="1"
                            innerClassName="form-control-dark text-center"
                            placeholder="&bull;"
                            required
                            validations="isNumeric,isLength:1"
                            validationError={tr.enter_valid_number || "Enter Valid Number"}
                            outerDiv={false}
                            handleChange={this.handleChange}
                            returnKeyType={"next"}
                        />
                        <MyInput
                            myRef={input => { this.inputs[field4] = input; }}
                            id={field4}
                            name={field4}
                            type="text"
                            maxLength="1"
                            innerClassName="form-control-dark text-center"
                            placeholder="&bull;"
                            required
                            validations="isNumeric,isLength:1"
                            validationError={tr.enter_valid_number || "Enter Valid Number"}
                            outerDiv={false}
                            handleChange={this.handleChange}
                            returnKeyType={"done"}
                        />
                    </div>
                </div>
                {watchMovie &&
                    <div className="form-group">
                        {this.state.isVerifyingPin ?
                            <button type="button" className="btn btn-primary btn-lg btn-block" disabled>{tr.verifying || "Verifying..."}  <i className="la la-spin fa-spinner"></i></button>
                            :
                            <LoaderButton
                                onClick={this.handleVerifyPinClick}
                                className="btn btn-primary btn-lg btn-block"
                                text={tr.watch_movie}
                            />
                        }
                    </div>
                }
            </Formsy>
        </Fragment>
    )
}

export function rewardStickyFooter() {
    return (
        <div className="alert alert-dark text-center m-0 fixed-bottom">
            <div className="container">
                Redeem one of the 5 movies as special offer through <span className="mx-1"><img src="https://i.imgur.com/x4PcLfE.png" width="60" /></span> voucher
                <Link to={`/${REWARD}`} className="btn btn-sm btn-primary ml-3 btn-medium">Get Offer</Link>
            </div>
        </div>
    )
}
export function renderCheckoutSuccessView(title, RentMaxValidity, RentTvodValidity, movieId = this.MOVIE_ID, moviePrice = 0, voucherCode = "", voucherBalance = "", storedVoucherFlag = false, tr) {
    let text = tr.your_rental_of || `Your Rental of <name> is now ready to watch. \\n You have <days> days to start watching this rental, and <hours> Hours to view the rental once started`;
    if (text) {
        text = text.split("\\n");
        text[0] = text[0].replace("<name>", title);
        text[1] = text[1].replace("<days> days ", RentMaxValidity && `${RentMaxValidity} days `);
        text[1] = text[1].replace(`<hours> Hours`, RentTvodValidity && `${RentTvodValidity}`);
    } else {
        text = []
    }

    return (
        <Modal isOpen={this.state.paymentWasSuccessfull} className="mn-voucher-method modal-dialog-centered pb-5 modal-dark" size="lg">
            <div className="modal-content">
                <div className="modal-body p-4 p-sm-5 text-white-50">
                    <div className="card-body text-center position-relative" style={{ "minHeight": "150px" }}>
                        <div className="mn-payment-msg">
                            <div className="mb-3 text-success"><i className="la la-check-circle font-48"></i></div>
                            <h3 className="text-success">{this.state.transactionSuccessModalHeader ? this.state.transactionSuccessModalHeader : tr.transaction_successful}</h3>
                            <p>{text[0]}</p>
                            <p>{text[1]}</p>
                            {storedVoucherFlag &&
                                <div className="py-3">
                                    <div className="mb-3">
                                        {tr.youve_purchased_this_movie_through_rewar || "You've purchased this movie through Reward Code, here is the detail"}:
                                </div>

                                    <div className="table-responsive">
                                        <table className="table mn-reward-table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>{tr.reward_code || "Reward Code"}</th>
                                                    <th>{tr.movie_price || "Movie Price"}</th>
                                                    <th>{tr.balance_remaining || "Balance Remaining"}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{voucherCode}</td>
                                                    <td>${moviePrice}</td>
                                                    <td>${voucherBalance}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="">
                                        {tr.reward_balance_has_been_added_to_your || "Reward Balance has been added to your"} <NavLink to={STOREDVOUCHERS}>{tr.saved_reward_codes || "Saved Reward Codes"}</NavLink>
                                    </div>
                                </div>
                            }
                            <div>
                                <div className="my-5">
                                    <a href="javascript:void(0)" onClick={(e) => this.handleWatchMovieClick(e, movieId)} className="btn btn-primary">{tr.start_watching}</a>
                                    <a href="javascript:void(0)" onClick={(e) => this.handleMovieClick(e, movieId, true)} className="btn btn-secondary ml-3 ">{tr.not_yet}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ Modal>
    )
}

export function getMessage(title, hr, max, tr) {

}

export function showPartnerCheckoutVoucherMessage() {
    return (
        <p className="text-success">
            Special $1 Movies Offer for +Rewards Users
        </p>
    )
}
//Get special discount on promo movies by using + Reward voucher at a price value of $1.Voucher Code is applicable for SD titles Only.</p >