import { GET } from "../../api/";
import _concat from "lodash/concat";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";
import { handleImageResizing } from "../../helpers/CDNhelpers";
import { getDataFromLocalStorage, storeDataInLocalStore } from "../../helpers/GeneralHelpers";

export default async function getCollections(callback, params) {
    const { Limit = 20 , LastEvaluatedKey = `` ,Items } = params;
    let collection //= getDataFromLocalStorage(`collections`, userID);
    if (!collection || _isEmpty(collection)) {
      collection = await GET("MoviesAPI", `/collections?limit=${Limit}&ExclusiveStartKey=${LastEvaluatedKey} `)
        .then(res => {
          if(res.body){
            res.body.Items = _concat(Items,res.body.Items);
            callback(res.body);
            return res.body;
          } 
          return res.body;
        })
        .catch(e => {
          let message = e;
          if (e.response && e.response.data && e.response.data.errorMessage) {
            message = e.response.data.errorMessage.replace("[403] ", "");
          }
          callback(null, message);
          return;
        });
    }
    if (!collection) {
      return;
    }
    const { HostName } = params;
    if(params.mycollections && params.isAuthenticated){
      collection.Items = updateStatus(collection.Items, params.mycollections);
    }
    collection.Items = await handleImageResizing(collection.Items, 'Movies', HostName);
    if (!_isEmpty(collection)) {
       //storeDataInLocalStore(`collections`, collection, userID);
    }
    callback(collection);
  }
  
  function updateStatus(collections, mycollections) {
    let result = collections;
    result = result.map(collection => {
      let isCPurchased = _find(mycollections, ["CollectionID", collection.ID]);
      if (isCPurchased) {
        collection["isCPurchased_"] = true;
      }
      return collection;
    });
    return result;
  }
  