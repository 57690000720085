import React, {useState, useEffect, useContext} from "react";
import {getHomeBanners} from "../../services/movies/GetHomeBanner";
import Loader from "../../components/Loader/Loader";
import BannerList from "./components/BannerList";
import {AuthContext, withAuth} from "../../context/AuthContext";
import {listMovieByResumedSection, listMovieBySection} from "../../services/movies/ListMovieBySection";
import {GetWatchList} from "../../services/user/GetWatchList";

const Banner = ({myList, HostName, serverTime, ...props}) => {
    const [banners, setBanner] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [resumeSection, setResumeSection] = useState([]);
    const UserContext = useContext(AuthContext);
    const [watched, setWatched] = useState([]);


    useEffect(() => {
        initialize();
        return () => {
        };
    }, []);

    const initialize = async () => {
        let params = {
            HostName: HostName,
            purchases: props.purchases,
            serverTime: serverTime,
            userID: props.UserID,
            isAuthenticated: props.isAuthenticated
        };

        getHomeBanners((response, err) => {
            if (!err) {
                setBanner(response);
            }
            setLoading(false);
        }, params);


        let watchList = watched;
        if (UserContext.isAuthenticated) {
            watchList = await GetWatchList({CdnUrl: HostName, UserId: UserContext.UserID});
            setWatched(watchList);
        }
        params = {
            subscriptions: UserContext.subscriptions,
            mycollections: UserContext.mycollections,
            myList: myList,
            watchList: watchList,
            ...params
        };
        //fetchFullWatchList();

        // listMovieByResumedSection((response, err) => {
        //     if (!err) {
        //         if (response[0]) {
        //             setResumeSection(response[0]);
        //         }
        //     }

        // }, params);


        listMovieBySection((response, err) => {
            if (!err) {
                if (response[0]) {
                    setResumeSection(response[0]);
                }
            }

        }, params);

    };
    return (
        <section className="position-relative">
            {isLoading ? (
                <Loader/>
            ) : banners && banners.length > 0 ? (
                <BannerList serverTime={serverTime} HostName={HostName} myList={myList} bannerList={banners}
                            updateMyList={props.updateMyList} resumeSection={watched}
                            watchedSection={resumeSection}/>
            ) : null}
        </section>
    );


};

export default withAuth(Banner);
