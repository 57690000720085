import API from "@aws-amplify/api";
import {START_PLAYER_SESSION, MOVIE_VIDEO, STORED_USER_SESSION_ID} from "../../constants/constants";
import {getCurrentUserId, getDataFromLocalStorage} from "../GeneralHelpers";

export async function updatePlayerSession(movieId, type, state, playSessionId, duration) {
    let videoType = (type === MOVIE_VIDEO) ? "Movie" : "Trailer";
    let user_id = await getCurrentUserId();
    let userSessionID = getDataFromLocalStorage(STORED_USER_SESSION_ID, user_id) || '';
    let apiName = 'UserAPI';
    let path = '/v2/playerSession';
    let init = {
        headers: { 'Content-Type': 'application/json' },
        body: {
            "MovieID": movieId,
            "State": state,
            "PlaySessionID": playSessionId,
            "Duration": duration,
            "UserSessionID": userSessionID,
            "Type": videoType,
        }
    };
    return API.post(apiName, path, init).then((res) => {
        let result = ""
        if (res.statusCode === 200 && state === START_PLAYER_SESSION) {
            result = (res && res.body && res.body.ID) ? res.body.ID : ""
        }
        return result;
    }).catch((error) => {
        return ""
    });
}