import React from "react";
import _find from "lodash/find";
import { withRouter } from "react-router-dom";
import Quality from "../components/Quality";
import TotalPrice from "./TotalPrice";
import { ROOMDETAIL, COLLECTIONDETAIL } from "../../../../constants/routes";
import VoucherContainer from "../../components/voucher";
import AppliedVoucher from "../../components/AppliedVoucher";
import { getRoomDuration } from "../../../../helpers/MoviesHelper";
import Formsy from "formsy-react";
import MySelect from "../../../../components/MySelect";
import {withAppConfig} from "../../../../context/ConfigContext";

// for now currency symbol is hardcoded update it from configuration

const DescriptionTable = props => {
  const onQChange = (id, value) => {
    props.onChange(value, props.data.Price);
  };

  let params = {
    checkoutData: props.checkoutData,
    data: props.data,
    ID: props.ID,
    Type: props.Type,
    selectedQuality: props.selectedQuality,
    selectedDuration: props.selectedDuration
  };
  return (
    <section className="mn-checkout">
      <div className="container">
        <section className="mn-order pb-4">
          <div className="mn-cart-table px-3">
            <div className="mn-cart-body text-white-50">
              <div className="mn-cart-row row align-items-center item-added-row">
                <PreviewTitle {...props} />
                <Quality
                  selectedQuality={props.selectedQuality}
                  onQChange={onQChange}
                  {...props}
                />
                <Duration
                  selectedDuration={props.selectedDuration}
                  {...props} />
                <ActualPrice {...props} />
              </div>
              {props.checkoutData.Code ? (
                <AppliedVoucher {...params} onRemove={props.onChangeVoucher} />
              ) : (
                  <VoucherContainer
                    onRedeem={props.onChangeVoucher}
                    {...params}
                  />
                )}
              <TotalPrice {...props} />
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

const PreviewTitle = props => {
  const { history, ID, Type } = props;
  const onClick = () => {
    history.push(
      `${
      Type === "screening_room" ? `${ROOMDETAIL}` : `${COLLECTIONDETAIL}`
      }/${ID}`
    );
  };

  let width = Type === "screening_room" ? "150px" : "";
  let height = Type === "screening_room" ? `` : `110px`;

  return (
    <div className="mn-cart-cell col-12 col-md-5">
        <a
          href="javascript:void(0)"
          className="link-white"
          onClick={onClick}
        >
        <div className="media">
            <div className="checkout-thumb">
                {props.data.PosterUrl && (
                <div className="mn-figure rounded-circle">
                    <div className="mn-thumb img-bg img-1x1 img-fluid" style={{backgroundImage: `url(${props.data.PosterUrl})`}}></div>
                </div>
                )}
            </div>
            <div className="media-body pl-4 align-self-center">
                <div className="mb-3">{props.data.Title}</div>
                <div className="text-white-50 font-12">Subscribe to Watch <strong>{props.data.MovieCount} Movies</strong></div>
            </div>
        </div>
        </a>
    </div>
  );
};

const Duration = props => {
  let durationList = [];
  let durationSet = new Set();
  props.data.Plans.forEach((item) => {
    durationSet.add(item.duration)
  });

  for (let item of durationSet) {
    durationList.push({
      title: item.toUpperCase(),
      value: item.toLowerCase()
    })
  }


  let firstName = durationList[0].title;
  return (
    <div className="mn-cart-cell col-12 col-md-3">
      <div className="mb-1 text-white">
        { "Duration"}
      </div>
      <div>
        {durationList.length > 1 ? (
          <Formsy>
            <MySelect
              id="selectedDuration"
              name="selectedDuration"
              outerDiv={false}
              disabled={
                props.checkoutData.isAutoRedempted || props.checkoutData.Code
              }
              innerClassName="form-control-dark"
              value={props.selectedDuration}
              handleChange={props.onDurationChange}
              options={durationList}
            />
          </Formsy>
        ) : (
            firstName
          )}
      </div>
    </div>
  );
};

const ActualPrice = props => {
  let price = 0;
  let quality = props.selectedQuality;
  let duration = props.selectedDuration;

  props.data.Plans.forEach((item) => {
    Object.keys(item.pricelist).forEach((q) => {
      if (quality == q && item.duration == duration) {
        price = item.pricelist[q].price;
      }
    });
  });
  return (
    <div className="mn-cart-cell col-12 col-md-2">
      <div className="mb-3 text-white">
        {props.appTranslations.price || "Price"}
      </div>
      <div className="">
        {props.appSettings.CurrencySymbol || "HP"}
        {price}
      </div>
    </div>
  );
};

export default withRouter(withAppConfig(DescriptionTable));
