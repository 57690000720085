import React, { useState, memo, useEffect } from "react";
import LoaderButton from "../../../components/LoaderButton/LoaderButton";
import { cancelSubscription } from "../../../services/checkout/CancelSubscription";
import { withAppConfig } from "../../../context/ConfigContext";
import moment from "moment";
import { withAuth } from "../../../context/AuthContext";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import CancelSubscriptionModal from "../../../components/CancelSubscriptionModal/CancelSubscriptionModal";


const CancelSubscription = ({ ID, ...props }) => {
  const [isSubmitting, setSubmit] = useState(false);
  const [showModal, setModal] = useState(false)

  if (moment().isAfter(props.ExpiresAt) || !props.AutoRenew) {
    return null;
  }

  const onClick = e => {
    // e.preventDefault();
    setSubmit(true);
    cancelSubscription(
      (err, res) => {
        if (!err) {
          let succ =
            props.appTranslations.cancelled_subscription ||
            "Subscription Cancelled Successfully";
          props.showAlert("showSuccessAlert", succ);
          if (props.InAppTransactionID) {
            window.open(
              "https://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/manageSubscriptions",
              "_blank"
            );
          }
          updateSubscriptions();
        } else {
          props.showAlert("showErrorAlert", err);
        }
        setSubmit(false);
      },
      { SubscriptionID: ID }

    );

  };


  const updateSubscriptions = () => {
    let subscriptions = [...props.subscriptions];
    let thisSub = _find(subscriptions, ["ID", ID]);
    thisSub["AutoRenew"] = false;
    let wIndex = _findIndex(subscriptions, ["ID", ID]);
    subscriptions.splice(wIndex, 1, thisSub);
    props.updateSubscription(subscriptions);
  };


  const renderCancelSubscriptionModal = () => {
    return (<CancelSubscriptionModal showCancelSubscriptionModal={showModal} show={setModal} Click={onClick}/>)
  }

  return (
    <div className="mt-3">
      <LoaderButton
        type="submit"
        className="btn btn-sm btn-secondary"
        onClick={() => setModal(true)}
        isLoading={isSubmitting}
        text={
          props.appTranslations.cancel_subscription || "Cancel Subscription"
        }
        loadingText={`${props.appTranslations.please_wait ||
          "Please Wait"} ...`}
      />
      {showModal && renderCancelSubscriptionModal()}
      {/* <button onClick={() => setModal(true)}>Cancel</button> */}
    </div>
  );


};


const memodCancelSubscription = memo(CancelSubscription);
export default withAppConfig(withAuth(memodCancelSubscription));