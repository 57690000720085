import React, { Component } from "react";
import Slider from "react-slick";
import PropTypes from 'prop-types';
import MovieCard from "../../../components/MovieCard/MovieCard";
import { isEmpty } from "../../../helpers/GeneralHelpers";
import { initSliderSettings } from "../../../helpers/MoviesHelper";
import DataNotFound from "../../../components/DataNotFound/DataNotFound";
import config from "../../../config/config";
import { withAppConfig } from "../../../context/ConfigContext";

class MovieDetailSuggestions extends Component {

    renderRelatedToThisMovies = () => {
        const { dataList, showRelatedTags, handleMovieClick ,appTranslations :tr } = this.props;
        return (
            (dataList && dataList.length > 0) ?
                <Slider {...initSliderSettings("",dataList.length)} className="row">
                    {dataList.map((movie, index) => {
                        if (!isEmpty(movie) && (!movie.Status || (movie.Status !== "inactive"))) {
                            return (
                                <MovieCard
                                    {...this.props}
                                    showCardHoverIcons={showRelatedTags}
                                    key={movie.ID ? movie.ID : index} movie={movie}
                                    movieClick={handleMovieClick} />
                            )
                        }
                    })}
                </Slider>
                :
                <DataNotFound height="200px" text={tr.no_related_movies_found} />
        )
    }


    render() {
        const { appTranslations :tr } = this.props;
        return (
            <section className="mn-movies py-5">
                <div className="container-fluid">
                    <div className="d-flex w-100 justify-content-between">
                        <div className=""><h4 className="text-white-70 mb-3">{tr.related_to_this}</h4></div>
                    </div>
                    {this.renderRelatedToThisMovies()}
                </div>
            </section>
        );
    }
}

MovieDetailSuggestions.defaultProps = {
    showRelatedTags: false
}

MovieDetailSuggestions.propTypes = {
    showRelatedTags: PropTypes.bool.isRequired,
    dataList: PropTypes.array.isRequired,
    handleMovieClick: PropTypes.func.isRequired
};
const wrapped = withAppConfig(MovieDetailSuggestions);
export { wrapped as SuggestionsSlider };