import React, { Component } from "react";
import { handleChange, enableButton, disableButton, disableAlert } from "../../../helpers/StateHelper";
import { getUserNotificationToken } from "../../../helpers/NotificationsHelper";
import { setDocumentTitle, getDataFromLocalStorage } from "../../../helpers/GeneralHelpers";
import { renderNotificationMessage } from "../../../helpers/ViewHelpers";
import { LOGIN_PAGE, STORED_MY_PARTNER_NAME } from "../../../constants/constants";
import config from "../../../config/config";
import { withFirebase } from "../../../context/FirebaseContext";
import { withAppConfig } from "../../../context/ConfigContext";
import withUserSession from "../../../hoc/UserSession";
import UniversalLogin from "../components/UniversalLogin";
import loginVector from "../../../assets/images/svg/login-boy.svg";


class Login extends Component {
    isCompMounted = true;
    loginFailure = false
    isMigrationFlowTesting = false;
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            email: "",
            password: "",
            newPassword: "",
            loginError: "",
            canSubmit: false,
            alertMessage: "",
            showSocialButtons: true
        };
        //remove below 4 later
        this.handleChange = handleChange.bind(this);
        this.enableButton = enableButton.bind(this);
        this.disableButton = disableButton.bind(this);
        this.disableAlert = disableAlert.bind(this);
        this.renderNotificationMessage = renderNotificationMessage.bind(this);

    }

    async componentDidMount() {
        this.userNotificationToken = await getUserNotificationToken(this.props.firebase);
        setDocumentTitle(LOGIN_PAGE);
        let cachePartner = getDataFromLocalStorage(STORED_MY_PARTNER_NAME);
        if (this.props.location.state) {
            if (this.props.location.state.isPartner) {
                this.setState({
                    showSocialButtons: false
                })
                this.ACCOUNT_TYPE = true;
            }
        } else if (cachePartner) {
            this.setState({
                showSocialButtons: false
            })
            this.ACCOUNT_TYPE = true;
        }
    }

    componentWillUnmount() {
        this.isCompMounted = false;
    }

    handleSignUpClick = () => {
        let cachePartner = getDataFromLocalStorage(STORED_MY_PARTNER_NAME);
        if (this.props.location.state) {
            if (this.props.location.state.isPartner) {
                this.props.history.push(`/p/${this.props.location.state.partnerName}`)
                return
            }
        } else if (cachePartner) {
            this.props.history.push(`/p/${cachePartner}`)
            return
        }
        this.props.history.push('/signup')
    }
    render() {
        const { SIGN_UP } = this.props.appTranslations;
        return (
            <main className="mn-main">
                <section className="mn-login-section py-5">
                    <div className="container">
                        <div className="mn-login-content">
                            <div className="mn-login-body row no-gutters align-items-stretch">
                                <div className="mn-login-left d-flex flex-column justify-content-center bg-gray-800 px-3 py-4 p-md-5 col-12 col-md-6 ">
                                    {this.state.isLoading && <span className="loader-spiral mn-white mn-white-transparent"><span className="spiral-inn"></span></span>}
                                    <UniversalLogin
                                        {...this.props}
                                        showSocialButtons={this.state.showSocialButtons}
                                    />
                                </div>
                                <div className="mn-login-right d-flex flex-column text-center p-5 col-12 col-md-6">
                                    <div className="mb-4 mt-auto">
                                        <img src={loginVector} style={{ maxWidth: "250px" }} />
                                    </div>
                                    <div className="mt-auto">
                                        
                                        <div className="mb-3">New to {this.props.appSettings.ApplicationName ? this.props.appSettings.ApplicationName : config.applicationName}?</div>
                                        <div className="">
                                            <a href="javascript:void(0)" className="btn btn-white btn-lg btn-large" onClick={this.handleSignUpClick}>{SIGN_UP || "SIGN UP"}</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

const WrappedLogin = withFirebase(withAppConfig(withUserSession(Login)));
export default WrappedLogin;