import React from 'react';
import PropTypes from "prop-types";
import { withAppConfig } from "../../context/ConfigContext";

const DataNotFound = (props) => {
    const { appTranslations : tr } = props;
    let text = !props.text ? tr.data_not_found || "Data Not Found!" :  props.text;
    return(
    <div className="position-relative" style={{ minHeight: `${props.height}` }}>
        <div className="mn-error middle">
            <div className="mb-2 text-white-50"><i className="la la-exclamation-triangle la-4x"></i></div>
            <div className="text-white-50 font-18">{text}</div>
        </div>
    </div>
    )
}

DataNotFound.defaultProps = {
    text: "",
    height: "500px"
}

DataNotFound.propTypes = {
    text: PropTypes.string,
    height: PropTypes.string
}

export default withAppConfig(DataNotFound);