import React, { Fragment } from "react";
import { NavLink } from 'react-router-dom';
import PropTypes from "prop-types";
import { ListGroup, NavItem } from 'reactstrap';
import config from "../../config/config";
import {
    MOVIES,
    LIBRARY,
    DEVICES,
    SETTINGS,
    MYLIST,
    PRIVACY_POLICY,
    TERMS_CONDITIONS,
    CONTACT_US,
    MYRENTALS,
    ABOUT_US,
    PROFILE,
    HOW_TO_WATCH,
    STOREDVOUCHERS,
    WATCHHISTORY
} from "../../constants/routes";
import appStoreBadge from "../../assets/images/svg/app-store-badge.svg";
import googleStoreBadge from "../../assets/images/svg/google-play-badge.svg";
import { withAppConfig } from "../../context/ConfigContext";

const SubFooter = ({ appSettings, appTranslations : tr  }) => {
    const { HelpCenterLink, AppStoreLink, PlayStoreLink, Partners = [], ApplicationName = `` } = appSettings;

    return (
        <section className="mn-ft-top py-4 text-center text-sm-left">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <h6 className="mb-3 text-white-70">{tr.my_library || "My Library"}</h6>
                        <ListGroup className="list-unstyled mb-3 lh-25">
                            <NavItem>
                                <a href={"https://www.laughoutloud.app"+MYLIST} class="link-white-50">{tr.my_list || "My List"}</a>
                                </NavItem>
                            <NavItem><a href={"https://www.laughoutloud.app"+MYRENTALS} className="link-white-50">{tr.my_rentals || "My Rentals"}</a></NavItem>
                            <NavItem><a href={"https://www.laughoutloud.app"+WATCHHISTORY} className="link-white-50">{tr.my_history || "My History"}</a></NavItem>
                            {!config.customSettings.isStuffpixEnabled &&
                                <NavItem><a href={"https://www.laughoutloud.app"+MOVIES} className="link-white-50">{ tr.filtered_movies || "Filtered Movies"}</a></NavItem>
                            }
                        </ListGroup>
                    </div>
                    <div className="col">
                        <h6 className="mb-3 text-white-70">{tr.settings || "Settings"}</h6>
                        <ListGroup className="list-unstyled mb-3 lh-25">
                            <NavItem><a href={"https://www.laughoutloud.app"+PROFILE} className="link-white-50">{tr.my_profile || "My Profile"}</a></NavItem>
                            <NavItem><a href={"https://www.laughoutloud.app"+SETTINGS} className="link-white-50">{tr.account_settings || "Account Settings"}</a></NavItem>
                            {/* <NavItem><NavLink to={PURCHASE_LISTING} className="link-white-50">Rental History</NavLink></NavItem> */}
                            {/*<NavItem><NavLink to={NOTIFICATIONS} className="link-white-50">My Notifications</NavLink></NavItem>*/}
                            <NavItem><a href={"https://www.laughoutloud.app"+DEVICES} className="link-white-50">{tr.manage_devices || "Manage Devices"}</a></NavItem>
                            <NavItem><a href={"https://www.laughoutloud.app"+STOREDVOUCHERS} className="link-white-50">{tr.saved_reward_codes || "Saved Reward Codes"}</a></NavItem>
                        </ListGroup>
                    </div>
                    <div className="col">
                        <h6 className="mb-3 text-white-70">{tr.help_center || "Help Center"}</h6>
                        <ListGroup className="list-unstyled mb-3 lh-25">
                                <NavItem>
                                    <a href={"https://www.laughoutloud.app"+HOW_TO_WATCH} className="link-white-50">{tr.how_to_watch || "How To Watch"}</a>
                                </NavItem>
                            {/* {(config.customSettings.isStuffpixEnabled && FAQLink) &&
                                <NavItem>
                                    <a href={FAQLink} className="link-white-50">Frequently Asked Questions</a>
                                </NavItem>} */}
                            {HelpCenterLink ?
                                config.customSettings.isStuffpixEnabled ?
                                    <NavItem>
                                        <a target="_blank" href={HelpCenterLink} className="link-white-50">{tr.support || "Support"}</a>
                                    </NavItem>
                                    :
                                    config.applicationName === 'Roadshow' ?
                                    <NavItem>
                                        <a target="_blank" href={HelpCenterLink} className="link-white-50">{`${ApplicationName} ${tr.help || "Help"}`}</a>
                                    </NavItem>
                                    :
                                    <NavItem>
                                        <a target="_blank" href={HelpCenterLink} className="link-white-50">{`${ApplicationName} Help`}</a>
                                     </NavItem>
                                :
                                null}
                            <NavItem><a href={"https://www.laughoutloud.app"+CONTACT_US} className="link-white-50">{tr.contact_us || "Contact Us"}</a></NavItem>
                            {(!config.customSettings.isStuffpixEnabled) &&
                                <NavItem> <a href={"https://www.laughoutloud.app"+ABOUT_US} className="link-white-50">{tr.about_us || "About Us"}</a></NavItem>
                            }
                            {/* <NavItem><NavLink to={TERMS_CONDITIONS} className="link-white-50">{tr.terms_of_use || "Terms of Use"}</NavLink></NavItem>
                            <NavItem><NavLink to={PRIVACY_POLICY} className="link-white-50">{tr.privacy_policy || "Privacy Policy"}</NavLink></NavItem> */}
                        </ListGroup>
                    </div>
                    <div className="col">
                        {(AppStoreLink && PlayStoreLink) &&
                            <Fragment>
                                <h6 className="mb-3 text-white-70">{tr.get_mobile_app || "Get Mobile App"}</h6>
                                <ListGroup className="list-unstyled mb-3 pt-3 lh-25">
                                    {AppStoreLink &&
                                        <NavItem className="mb-4"><a target="_blank" href={AppStoreLink}>
                                            <img src={appStoreBadge} style={{ width: "144px" }} /></a>
                                        </NavItem>}
                                    {PlayStoreLink &&
                                        <NavItem><a target="_blank" href={PlayStoreLink}>
                                            <img src={googleStoreBadge} style={{ width: "144px" }} /></a>
                                        </NavItem>}
                                </ListGroup>
                            </Fragment>
                        }
                    </div>
                    {PartnersList(Partners,tr)}
                </div>
            </div>
        </section>
    )
}
export default withAppConfig(SubFooter);
SubFooter.propTypes = {
    appSettings: PropTypes.object.isRequired
}

const PartnersList = (Partners,tr) => {
    let count = 0;
    return (
        (config.customSettings.isStuffpixEnabled && Partners && Array.isArray(Partners) && Partners.length > 0) &&
        <div className="col">
            <Fragment>
                <h5 className="mb-3 text-white-70">{tr.our_partners || "Our Partners"}</h5>
                <ListGroup className="list-unstyled mb-3 lh-25">
                    {Partners.map((partner, index) => {
                        if(partner.ShowOnFooter && partner.ShowOnFooter.toLowerCase()  === 'true'){
                        if (count < 2) {
                            count++;
                            return (
                                <NavItem key={index} className="mb-3">
                                    <a target="_blank" href={partner.Url}>
                                        {(partner.Theme && partner.Theme.Logo) ?
                                            <img src={partner.Theme.Logo} />
                                            :
                                            partner.Name}
                                    </a>
                                </NavItem>
                            );
                        }
                    } })
                    }
                </ListGroup>
            </Fragment>
        </div>
    )
}