import API from "@aws-amplify/api";
import { uniqueBy } from "../GeneralHelpers";
import { getResponse } from "../ResponseHelpers";
import _concat from "lodash/concat";
import { FETCH_GENERE, CHECKOUTMOVIEDATAONCHANGEQUALITY, ORDER_NOT_FOUND, CHECKOUTMOVIEDATA, SECTIONS, FETCH_MOVIE_PLAY_VIDEO, FETCH_SEARCH_DATA, MOVIE_STREAM_RELATED, CLIENT_TOKEN, FETCH_MOVIE_DATA, UPDATE_MYLIST_MOVIE, UPDATE_PROFILE_PHOTO, SUBMIT_PAYMENT_FORM, PURCHASE_HISTORY, REMOVE_MYLIST_MOVIE } from "../../constants/constants";
import { getAppConfigurations } from "../ConfigHelpers";
import { getTimeZone } from "../PaymentHelpers";
import { handleImageResizing } from "../CDNhelpers";
import concat from "lodash/concat";

export function fetchMoviesData(apiname, apipath, data, isloading, error, dataList, status, uniqBy, lastEvaluatedKey = "", headerData = {}, isLoadingMore = false,responseType) {
    let loadingMore = "isLoadingMore";
    let apiName = apiname;
    let path = apipath;
    if(apiName !== "SearchAPI"){
        headerData["Device"] = "Web";
        if (getTimeZone(status) !== "") {
            headerData["TimeZone"] = getTimeZone(status);
        }
    }
    let init = {
        headers: { 'Content-Type': 'application/json' },
        body: headerData,
    };
    return API.post(apiName, path, init)
        .then(async (res) => {
            if (responseType === FETCH_SEARCH_DATA) {
                let newArray = getResponse(res, data, responseType, uniqBy, this.USER_ID);
                if (this.isCompMounted) {
                    this.setState({
                        [isloading]: false,
                        [error]: "",
                        [dataList]: newArray,
                    });
                }
                return newArray;
            }
            if (res.statusCode === 200) {
                let arr = []; let newArray = [];
                if (status === SECTIONS) {
                    arr = await this.checkUnique(res.body);
                } else {
                    newArray = getResponse(res, data, status, uniqBy, this.USER_ID);
                    let concated = isLoadingMore ? newArray ? _concat(data, newArray) : data : newArray;
                    arr = (status !== PURCHASE_HISTORY && status !== "PURCHASE_HISTORY_BANNER") ? uniqueBy(concated, uniqBy) : newArray;
                    await getAppConfigurations(); // important!! --> (will return from cache!! or fetch o.w)
                    arr = await handleImageResizing(arr, status);
                }
                if (this.isCompMounted) {
                    this.setState({
                        [isloading]: false,
                        [error]: "",
                        [dataList]: arr,
                        [lastEvaluatedKey]: lastEvaluatedKey && res.body.LastEvaluatedKey ? res.body.LastEvaluatedKey : "",
                        [loadingMore]: false
                    });
                }
                return (status === FETCH_GENERE) ? res.body : arr;
            } else {
                if (this.isCompMounted) {
                    this.setState({
                        [isloading]: false,
                        [error]: res.statusMessage,
                        [isLoadingMore]: false
                    });
                }
            }
        }).catch((err) => {
            let error = err;
            if (err.message !== undefined) {
                error = err.message
            }
            if (this.isCompMounted) {
                this.setState({
                    [isloading]: false,
                    [error]: error,
                    [isLoadingMore]: false
                })
            }
        });
}

export function getApiData(apiname, apipath, data, isloading, error, dataList, responseType, uniqBy, lastEvaluatedKey, isLoadingMore = false, setState = true, ) {
    let loadingMore = "isLoadingMore";
    let apiName = apiname;
    let path = apipath;
    let init = {
        headers: { Accept: 'application/json' },
    };
    if (responseType === CHECKOUTMOVIEDATA || responseType === CHECKOUTMOVIEDATAONCHANGEQUALITY || responseType === FETCH_MOVIE_DATA || responseType === FETCH_MOVIE_PLAY_VIDEO) {
        init.queryStringParameters = {  // OPTIONAL
            TimeZone: getTimeZone()
        }
    }

    return API.get(apiName, path, init).then(async (res) => {
        if (responseType === FETCH_SEARCH_DATA) {
            let newArray = getResponse(res, data, responseType, uniqBy, this.USER_ID);
            if (this.isCompMounted) {
                this.setState({
                    [isloading]: false,
                    [error]: "",
                    [dataList]: newArray,
                });
            }
            return newArray;
        } else {
            if (res.statusCode === 200 || res.StatusCode === 200) {
                let arr = []; //this func has been changed please check properly
                let newArray = getResponse(res, data, responseType, uniqBy, this.USER_ID);
                let concated = isLoadingMore ? _concat(data, newArray) : newArray;
                arr = (responseType === PURCHASE_HISTORY || responseType === "PURCHASE_HISTORY_BANNER") ? newArray : (uniqBy) ? uniqueBy(concated, uniqBy) : newArray;
                await getAppConfigurations(); // important!! --> (will return from cache!! or fetch o.w)
                arr = await handleImageResizing(arr, responseType);
                if (this.isCompMounted) {
                    if (setState) {
                        this.setState({
                            [isloading]: false,
                            [error]: "",
                            [dataList]: arr,
                            [lastEvaluatedKey]: res.body.LastEvaluatedKey !== undefined ? res.body.LastEvaluatedKey : "",
                            [loadingMore]: false
                        });
                    }
                }
                responseType === MOVIE_STREAM_RELATED && this.getMovieStream(arr, responseType);
                return arr;
            } else {
                if (this.isCompMounted) {
                    this.setState({
                        [isloading]: false,
                        [error]: res.statusMessage ? res.statusMessage : "Some Error Occurred!",
                    })
                }
            }
        }
    }).catch((err) => {
        let message = "Something went Wrong!";
        if (err.response) {
            if (err.response.data.errorType) {
                message = err.response.data.errorMessage;
            }
        }
        if (this.isCompMounted) {
            this.setState({
                [isloading]: false,
                [error]: responseType === CLIENT_TOKEN ? "Check Your Internet Connection" : message,
                showErrorAlert: true,
                alertMessage: message
            });
            if (this.disableAlert) {
                this.disableAlert("showErrorAlert");
            }
        }
        return [];
    });
}

export function updateApiWithResponse(apiname, apipath, headerData, data, showSuccessAlert, showErrorAlert, alertMessage, successMessage, errorMessage, status, isLoading, state2, state3) {
    let apiName = apiname;
    let path = apipath;
    if (getTimeZone(status) !== "") {
        headerData["TimeZone"] = getTimeZone(status);
    }
    headerData["Device"] = "Web";
    let init = {
        headers: { 'Content-Type': 'application/json' },
        body: headerData,
    };
    return API.post(apiName, path, init).then(async (res) => {
        if (res.statusCode === 200 || res.StatusCode === 200 || res.statusCode === 202 || res.StatusCode === 202) {
            let updatedData = getResponse(res, data, status, null, this.USER_ID);
            if (status === UPDATE_PROFILE_PHOTO) {
                let current = new Date();
                this.updateProfileData(`${res.body.FilePath}?rnd=${current.getTime()}`);
            } else {//not for profile
                await getAppConfigurations(); // --> (will return from cache!! or fetch o.w)
                updatedData = await handleImageResizing(updatedData, status);
            }
            if (this.isCompMounted) {
                this.setState({
                    [isLoading]: false,
                    [state2]: (status === UPDATE_MYLIST_MOVIE) ? !data : updatedData, //but return actual response ie. updatedData
                    [state3]: true,//only for showVoucher //free payment
                    [showSuccessAlert]: true,
                    [alertMessage]: successMessage
                });
            }
            status !== SUBMIT_PAYMENT_FORM && this.disableAlert(showSuccessAlert);
            status === MOVIE_STREAM_RELATED && this.loadNextMovie(data, updatedData);
            return updatedData;
        } else {
            if (this.isCompMounted) {
                this.setState({
                    [isLoading]: false,
                    showErrorAlert: true,
                    alertMessage: errorMessage
                });
                this.disableAlert(showErrorAlert);
            }
            return []
        }
    }).catch((error) => {
        let temp; //update it later
        let message = errorMessage;
        if (error.response !== undefined) {
            if (error.response.data.errorType !== undefined) {
                if (error.response.data.errorMessage === "[403] Order Not Found.") {
                    message = ORDER_NOT_FOUND;
                } else if (error.response.data.errorMessage === "[403] Do Not Honor" || error.response.data.errorMessage === "Do Not Honor") {
                    message = "Credit Card information is not Valid";
                } else {
                    message = error.response.data.errorMessage.replace("[403] ", "");
                }
                if(message === "Player Session Limit Exceeded."){
                    temp = "Limit";
                }
            }
        }
        if (this.isCompMounted) {
            this.setState({
                [isLoading]: false,
                [showErrorAlert]: true,
                [alertMessage]: message === "Player Session Limit Exceeded." ? "Your concurrent playback limit is exceeding" : message
            });
            this.disableAlert(showErrorAlert);
        }
        return (message === ORDER_NOT_FOUND) ? ORDER_NOT_FOUND : temp ? message : [];
    });
}

export function deleteApiRequest(apiname, apipath, headerData, data, showSuccessAlert, showErrorAlert, alertMessage, successMessage, errorMessage, status, isLoading, state2, state3) {
    let apiName = apiname;
    let path = apipath;
    let init = { headers: { 'Content-Type': 'application/json' } };
    if (headerData !== null) {
        headerData["Device"] = "Web";
        init["body"] = headerData;
    }
    return API.del(apiName, path, init).then(async (res) => {
        if (res.statusCode === 200 || res.StatusCode === 200 || res.statusCode === 202 || res.StatusCode === 202) {
            let updatedData = getResponse(res, data, status, null, this.USER_ID);
            if (status === "UPDATE_MYLIST_MOVIE_BANNER" || status === UPDATE_MYLIST_MOVIE || status === REMOVE_MYLIST_MOVIE) {
                updatedData = await handleImageResizing(updatedData, status);
            }
            if (this.isCompMounted) {
                this.setState({
                    [isLoading]: false,
                    [state2]: (status === UPDATE_MYLIST_MOVIE) ? !data : updatedData, //but return actual response ie. updatedData,
                    [showSuccessAlert]: true, //UPDATE_MYLIST_MOVIE on detail !! separate case on Banner like i.e UPDATE_MYLIST_MOVIE_BANNER
                    [alertMessage]: successMessage
                });
            }
            if (this.disableAlert) {
                this.disableAlert(showSuccessAlert);
            }
            return updatedData;
        } else {
            if (this.isCompMounted) {
                this.setState({
                    [isLoading]: false,
                    showErrorAlert: true,
                    alertMessage: errorMessage
                });
                if (this.disableAlert) {
                    this.disableAlert(showErrorAlert);
                }
            }
        }
    }).catch((error) => {
        if (this.isCompMounted) {
            this.setState({
                [isLoading]: false,
                [showErrorAlert]: true,
                [alertMessage]: errorMessage
            });
            if (this.disableAlert) {
                this.disableAlert(showErrorAlert);
            }
        }
        return [];
    });
}