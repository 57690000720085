import React, { Component } from 'react';
import Firebase from "../components/Firebase/Firebase";
import { initializeDBListeners, onChangeListener } from '../helpers/NotificationsHelper';
import { askForPermissioToReceiveNotifications, getMessagesStatus } from '../push-notification';
import {
    getCurrentUserId,
    getDataFromLocalStorage, storeDataInLocalStore
} from '../helpers/GeneralHelpers';
import {STORED_MY_NOTIFICATIONS, STORED_MY_UNREAD_NOTIFICATIONS, STORED_USER_SESSION_ID} from '../constants/constants';
import _includes from 'lodash/includes';
import API from "@aws-amplify/api";
const FirebaseContext = React.createContext(null);

class FirebaseProvider extends Component {
    constructor(props) {
        super(props);
        this.getMessagesStatus = getMessagesStatus.bind(this);
        this.onChangeListener = onChangeListener.bind(this);
        this.initializeDBListeners = initializeDBListeners.bind(this);
    }
    state = {
        firebase: new Firebase(),
        notificationsList: [],
        unreadNotifications: 0,
        forceDestroyPlayer: false,
        firebaseForceLogout: false,
        initializeFB: ()=>this.initializeFB(),
        getPlayingDevices: ()=>this.getPlayingDevices(),
        setInitialNotificationState: () => this.setInitialNotificationState(),
        setInitialSession : (id,type) => this.setInitialSession(id,type),
        handleOnNotificationsFetched: (notificationList, userId) => this.handleOnNotificationsFetched(notificationList, userId),
        reducePlayerSessions : ()=>this.reducePlayerSessions(),
        setFirebaseOnDisconnect : () => this.setFirebaseOnDisconnect(),
        removeFirebaseDisconnect : () => this.removeFirebaseDisconnect()
    };

     initializeFB= () => {
        this.initializeDBListeners(this.state.firebase);
    }

    onForceLogout= () => {
         this.setState({
             firebaseForceLogout: !this.state.firebaseForceLogout
         })
    };

    async componentDidMount() {
        let userId = await getCurrentUserId();
        let sessionID = await getDataFromLocalStorage(STORED_USER_SESSION_ID, userId);
        if(!sessionID){
           await this.forceCreateSession();
        }
        this.initializeFB();
        window.addEventListener('online', (event)=> {
            if(_includes(window.location.pathname,"watch/movie")){
                getCurrentUserId().then(user_id => {
                    let referenceUPS = this.state.firebase.db.ref('userPlayerSessions/'+user_id);
                    referenceUPS.once('value').then(snapshot => {
                        const snapShotVal = snapshot.val();
                        if(snapShotVal.concurrentCount === 0){
                            referenceUPS.update({
                                concurrentCount: 1
                            });
                            referenceUPS.onDisconnect().update({
                                concurrentCount: 0
                            });
                        }
                    });
                });
            }
        });
        let messaging = this.state.firebase.firebaseMessaging();
        if (messaging) {
            await this.state.firebase.getFirebaseToken();
            await askForPermissioToReceiveNotifications(messaging);
            this.getMessagesStatus(messaging);
        }
    }

    setInitialNotificationState = async () => {
        let userID = await getCurrentUserId();
        let notificationList = getDataFromLocalStorage(STORED_MY_NOTIFICATIONS, userID);
        let unreadNotifications = getDataFromLocalStorage(STORED_MY_UNREAD_NOTIFICATIONS, userID);
        this.setState({
            unreadNotifications: unreadNotifications,
            notificationsList: notificationList
        })
    };

    updateNotificationCount() {
        this.setState((prevState) => {
            return {
                unreadNotifications: prevState.unreadNotifications + 1
            }
        })
    }

    setInitialSession = async (sessionID,type) => {
        if (type && type !== "trailer") {
            let user_id = await getCurrentUserId();
            let reference = this.state.firebase.db.ref();
            await this.setPlayerSessions(reference, sessionID, user_id);
        }
    };

   setPlayerSessions = async (reference,playerSessionId,userId) => {
       let userSessionID =  await getDataFromLocalStorage(STORED_USER_SESSION_ID, userId);
       if(!userSessionID){
           await this.forceCreateSession();
           userSessionID =  await getDataFromLocalStorage(STORED_USER_SESSION_ID, userId);
       }
       if (userSessionID) {
           let userSessionPath = `users/${userId}/userSessions/${userSessionID}`;
           let playerSessionPath = `users/${userId}/userSessions/${userSessionID}/playerSessions/${playerSessionId}`;
           await reference.child(`${userSessionPath}`).update({
               status : "active"
           });
           await reference.child(`${playerSessionPath}`).update({
               status : "active"
           });
           await reference.child(`${playerSessionPath}`).onDisconnect().update({
               status : "inactive"
           });
       }
   };

   reducePlayerSessions = async () =>{
        let user_id = await getCurrentUserId();
        let reference = this.state.firebase.db.ref();
        let referenceUPS = reference.child('userPlayerSessions/' + user_id);
        await referenceUPS.once('value').then(snapshot => {
            const snapShotVal = snapshot.val();
            if(snapShotVal.concurrentCount > 0){
                referenceUPS.update({
                    concurrentCount: snapShotVal.concurrentCount -1
                });
            }
        })
    };

   removeFirebaseDisconnect = async () =>{
       let user_id = await getCurrentUserId();
       let reference = this.state.firebase.db.ref();
       let referenceUPS = reference.child('userPlayerSessions/' + user_id);
       await referenceUPS.onDisconnect().cancel();
   };

    setFirebaseOnDisconnect = async () => {
        let user_id = await getCurrentUserId();
        let reference = this.state.firebase.db.ref();
        let referenceUPS = reference.child('userPlayerSessions/' + user_id);
        let count =0;
        await referenceUPS.once('value').then(snapshot => {
            const snapShotVal = snapshot.val();
            if(snapShotVal.concurrentCount > 0){
               count = snapShotVal.concurrentCount;
            }
        });
        await referenceUPS.onDisconnect().update({
            concurrentCount : count > 0 ? count - 1 : 0
        });
    };

    forceCreateSession = async  () => {
        let headerData = {};
        if (this.userNotificationToken) {
            headerData = {
                "FCMToken": this.userNotificationToken
            }
        }
        let init = {
            headers: { 'Content-Type': 'application/json' },
            body: headerData,
        };
        return API.post("AuthAPI", '/v2/createsession', init).then(async (res) => {
            if (res.body && res.body.ID) {
                await storeDataInLocalStore(STORED_USER_SESSION_ID, res.body.ID, res.body.UserID)
                return;
            }
        }).catch((error) => {
            //console.log("Error=>",error);
        })
    }

    handleOnNotificationsFetched = (notificationsList, userId) => {
        if (notificationsList) {
            let unreadNotifications = getDataFromLocalStorage(STORED_MY_UNREAD_NOTIFICATIONS, userId);//countUnseenNotifications(notificationsList, userId);
            this.setState({
                unreadNotifications: unreadNotifications,
                notificationsList: notificationsList
            });
        }
    };

    getPlayingDevices = async () => {
        let user_id = await getCurrentUserId();
        let path = `users/${user_id}/userSessions/`;
        let reference = this.state.firebase.db.ref(path);
        let sessionList = [];
        const snapShotUserSessions = await reference.orderByChild("status").equalTo("active").once("value");
        if (snapShotUserSessions.val()) {
            let UserSessionIds = Object.keys(snapShotUserSessions.val());
            await UserSessionIds.forEach(async (id) => {
                let ref = reference.child(`${id}/playerSessions`);
                let snapShotPlayerSessions = await ref.orderByChild("status").equalTo("active").once("value");
                if (snapShotPlayerSessions.val()) {
                    sessionList.push(id);
                }
            });
        }
        return sessionList;
    };

    render() {
        return (
            <FirebaseContext.Provider value={this.state}>
                {this.props.children}
            </FirebaseContext.Provider >)
    }
}

const withFirebase = Component => props => {
    const FirebaseConsumer = (
        <FirebaseContext.Consumer>
            {(firebase) => <Component
                {...props}
                {...firebase}
            />}
        </FirebaseContext.Consumer>
    );
    return FirebaseConsumer;
};
export { FirebaseContext, FirebaseProvider, withFirebase };