export const getInputFields = (tr, inputValue) =>{
    let obj = []
    if(inputValue === "Use Mobile")
    {
     obj.push(
        {
            Name: "email",
            Type: "text",
            Placeholder: tr.email_address,
            Disabled: true,
            Validations: "isEmail",
            ValidationError: tr.please_enter_valid_email_address
        }
     )
    }
    else {
        obj.push({
            Name: "phone_number",
            Type: "phone",
            Placeholder: "Phone Number",
            // Validations: "isEmail",
            // ValidationError: tr.please_enter_valid_email_address  
        })
    } 
    obj.push(
        {
            Name: "password",
            Type: "password",
            Placeholder: tr.password,
            Validations: {
                minLength: 6,
                maxLength: 25
            },
            ValidationErrors: {
                minLength: tr.password_length_should_be_6_to_25_charac,
                maxLength: tr.password_length_should_be_6_to_25_charac
            }
        },
        {
            Name: "confirm-password",
            Type: "password",
            Title: "Confirm Password",
            Placeholder: tr.confirm_password,
            Validations: "equalsField:password",
            ValidationError: tr.new_password_and_confirm_password_does_n
        }
    )
    
    return obj;
}
