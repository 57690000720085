import React from "react";
import API from "@aws-amplify/api";
import { getUserNotificationToken } from "../helpers/NotificationsHelper";
import { storeDataInLocalStore, getDataFromLocalStorage, removeItemFromStore } from "../helpers/GeneralHelpers";
import { STORED_USER_SESSION_ID, STORED_MY_PARTNER_NAME } from "../constants/constants";
import { getCognitoUserAttributes } from "../helpers/ConfigHelpers";
import {withFirebase} from "../context/FirebaseContext";

const withUserSession = (WrappedComponent) => {
    class UserSession extends React.Component {
        constructor(props) {
            super(props);
            this.userNotificationToken = ""
        }

        async componentDidMount() {
            if (this.props.firebase) {
                this.userNotificationToken = await getUserNotificationToken(this.props.firebase);
            }
        }

        getCreateSessionHeader = () => {
            let headerData = {};
            if (this.userNotificationToken) {
                headerData = {
                    "FCMToken": this.userNotificationToken
                }
            }
            let init = {
                headers: { 'Content-Type': 'application/json' },
                body: headerData,
            };
            return init;
        }

        createSession = (forceredirect = false) => {
            let headerData = this.getCreateSessionHeader();
            API.post("AuthAPI", '/v2/createsession', headerData).then((res) => {
                this.onCreateSessionSuccess(res,forceredirect);
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        if (error.message === "Request failed with status code 403") {
                            this.props.updateAuthStatus(true, true);
                            this.props.updateUserData(); //verify
                            if (forceredirect) {
                                this.props.history.push("/devices?redirect=");
                            }
                        }
                    }
                }
            })
        }

        onCreateSessionSuccess = async (response, forceredirect) => {
            await this.storeUserSession(response);
            await this.checkIsPartner();
            this.props.initializeFB();
             //these are removed from here because createsession is added in backgound now
            // this.props.updateAuthStatus(true, false);
            // this.props.updateUserData();
            // if (forceredirect) {
            //     this.props.history.push("/home");
            // }
        }

        storeUserSession = async (response) => {
            if (response) {
                if (response.body && response.body.ID) {
                    await storeDataInLocalStore(STORED_USER_SESSION_ID, response.body.ID, response.body.UserID)
                    return;
                }
            }
        }

        async checkIsPartner() {
            let partner = await getCognitoUserAttributes(false, true);
            if (partner) {
                await storeDataInLocalStore(STORED_MY_PARTNER_NAME, partner);
            } else {
                let cachePartner = getDataFromLocalStorage(STORED_MY_PARTNER_NAME); //because normal user may come from partnerlanding page
                if (cachePartner) {
                    removeItemFromStore(STORED_MY_PARTNER_NAME)
                }
            }
            return partner;
        }

        render() {
            return (
                <WrappedComponent
                    createSession={this.createSession}
                    {...this.props}
                />)
        }
    }
    return withFirebase(UserSession);
}
export default withUserSession;

