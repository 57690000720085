

import React from 'react';
import PropTypes from "prop-types";
import { withAppConfig } from "../../context/ConfigContext";

const Alert = (props) => {
    let  { appTranslations : tr } = props;
    return(
    <div id="snackbar" className="show d-flex">
        <div
            className={`${props.className} font-weight-bold mr-3 flex-grow-1`}
            style={{ minWidth: "70px" }}>
            <i className={`${props.icon}`}>
            </i>{props.type === "success" ? tr.success : props.type === "warning" ? tr.warning : tr.error }</div>
        <div>{props.text}</div>
    </div>)
};

Alert.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string
}
export default withAppConfig(Alert);