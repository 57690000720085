import React, { memo } from "react";
import { withRouter } from "react-router-dom";
import { withAppConfig } from "../../../../context/ConfigContext";
const TabsList = {
  movies: "movies",
  related: "related"
};

const Header = props => {
  const { appTranslations: tr, activeTab } = props;

  return (
    <section className="mn-page-head py-4 bg-black">
      <div className="container-fluid">
        <div className="d-flex align-items-center justify-content-around">
          <MemoItem
            onChangeTab={props.onChangeTab}
            currentTab={TabsList.movies}
            Text={tr.collection_movies || "Movies in this Bundle"}
            activeTab={activeTab}
          />
          <MemoItem
            onChangeTab={props.onChangeTab}
            currentTab={TabsList.related}
            Text={tr.related_collections || "Related Bundles"}
            activeTab={activeTab}
          />
        </div>
      </div>
    </section>
  );
};

const MemoHeader = memo(Header);

export default withAppConfig(MemoHeader);

const Item = props => {
  const { currentTab, Text, activeTab } = props;
  const onChangeTab = () => {
    props.history.push(`${props.match.url}/${currentTab}`);
    props.onChangeTab(currentTab);
  };

  return (
    <div className={activeTab === currentTab ? "display-2" : "display-4"}>
      <a
        onClick={onChangeTab}
        className={activeTab === currentTab ? "text-primary" : "link-white-50"}
      >
        {Text}
      </a>
    </div>
  );
};

const MemoItem = memo(withRouter(Item));
